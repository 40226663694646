export const genderData = {
  buttons: [
    {
      label: 'Female',
      id: 'female',
    },
    {
      label: 'Male',
      id: 'male',
    },
    {
      label: 'Non-binary',
      id: 'non-binary',
    },
    {
      label: 'Prefer not to say',
      id: 'prefer-not-to-say',
    },
  ],
  title: 'What is your gender?',
}
