import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { constructDate } from '../../../../shared/components/DatePicker/formatDate/constructDate'
import { DatePickerPage } from '../../../../shared/pages/DatePickerPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { DatePickerInputRef } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getDatePickerValidationErrorMessage } from '../../../../shared/utils/getDatePickerValidationErrorMessage/getDatePickerValidationErrorMessage'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import {
  DesiredResolutionFlow,
  EntitlementFlow,
  PurchasedDateFlow,
  RepeatPerformanceFlow,
} from '../../pageFlowData'
import { purchasedDateData } from './data'

export const PurchasedDate: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    product: { purchasedDate, type, price, purchaseMethod },
  } = state

  const PurchasedDateInput = useRef<DatePickerInputRef>(null)

  // This should be moved to a data file and imported.
  const title = 'What date did you buy it?'
  const imgSrc =
    'https://media.product.which.co.uk/prod/images/original/gm-198ab41b-c97e-4b7c-a65d-31228fe9c22a-purchased-date.jpg'
  const imgAlt = 'Two people standing in front of a table'

  const [datePickerError, setDatePickerError] = useState('')

  const handleStepChoice = () => {
    const priceAmount = parseInt(price)
    const priceBetween0And100 = priceAmount > 0 && priceAmount < 100
    if (
      priceBetween0And100 ||
      purchaseMethod === 'debitCard' ||
      purchaseMethod === 'chargeCard' ||
      (purchaseMethod === 'creditCard' && priceAmount > 30000)
    ) {
      return { progress: EntitlementFlow.PROGRESS_VALUE, step: EntitlementFlow.STEP }
    }
    if (type === 'product') {
      return { progress: DesiredResolutionFlow.PROGRESS_VALUE, step: DesiredResolutionFlow.STEP }
    }
    if (type === 'service') {
      return { progress: RepeatPerformanceFlow.PROGRESS_VALUE, step: RepeatPerformanceFlow.STEP }
    }
    return { progress: EntitlementFlow.PROGRESS_VALUE, step: EntitlementFlow.STEP }
  }

  /* istanbul ignore next */
  const getValue = () => {
    return cleanText(PurchasedDateInput?.current?.datePickerInput?.value || purchasedDate)
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${PurchasedDateFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    const { progress, step } = handleStepChoice()

    dispatch({ type: 'UPDATE_PURCHASE_DATE', data: value })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  /* istanbul ignore next */
  const handleInputChange = (value: string) => {
    /* istanbul ignore next */
    dispatch({ type: 'UPDATE_PURCHASE_DATE', data: value })
  }

  const handleSubmit = () => {
    setDatePickerError('')
    const dateInputValue: string = getValue()
    const errorMessage = getDatePickerValidationErrorMessage(dateInputValue, true)
    /* istanbul ignore next */
    if (errorMessage) {
      return setDatePickerError(errorMessage)
    }
    handleTracking(dateInputValue)
    handleDispatch(dateInputValue)
  }

  const { infoBoxData } = purchasedDateData

  return (
    <DatePickerPage
      buttonText="Next"
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      imgObj={{
        alt: imgAlt,
        src: imgSrc,
      }}
      infoBoxData={infoBoxData}
      isFocused
      max={constructDate(new Date(), false)}
      newErrorMessage={datePickerError}
      purchasedDate={purchasedDate}
      ref={PurchasedDateInput}
      renderError={datePickerError !== ''}
      testId="purchased-date-page"
      title={title}
    />
  )
}
