import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { ArticleCTAPage } from '../../../../shared/pages/ArticleCTAPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking/tracking'
import { limitedOptionsData } from './data'

export const LimitedOptions: FunctionComponent = () => {
  const { state } = useContext(ToolContext)

  const {
    initial: { entryToolName },
  } = state

  const { bodyCopy, ctaData, standFirst, title, link } = limitedOptionsData
  const { href, headline, text } = link

  const handleTracking = () => {
    generalDataLayer(formatToolName(entryToolName), `onward journey | ${headline} | ${href}`)
  }

  return (
    <div data-testid="limited-options">
      <ArticleCTAPage
        articleHeading={headline}
        articleLink={link}
        articleTitle={text}
        bodyCopy={bodyCopy}
        ctaData={ctaData}
        handleSubmit={handleTracking}
        standFirst={standFirst}
        title={title}
      />
    </div>
  )
}
