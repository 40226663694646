export const limitedOptionsData = {
  damagedData: {
    title: 'You could use our faulty goods tool to make a claim',
    standFirst: '',
    bodyCopy: [
      'If your goods were delivered damaged, you could use our faulty goods tool to ask for a refund or replacement.',
      "Don't let the retailer try to pass blame on to you or the courier for your damaged goods.",
      'Any damage to your goods while they are on their way to you is the responsibility of the retailer to resolve.',
      'The Consumer Rights Act states that the retailer is responsible for goods up until they are delivered to you.',
    ],
    link: {
      href: 'https://www.which.co.uk/tool/faulty-goods',
      headline:
        "Draft a free complaint letter to send to the retailer. It only takes a few minutes.You're entitled to a refund, replacement or repair if goods you bought were faulty or not as described.",
      text: 'Complain about faulty goods',
    },
    ctaData: {
      image: {
        src: 'https://media.product.which.co.uk/prod/images/original/gm-8b0968cf-b7d0-49e7-b5fd-ff38b3887a0d-limitedoptionsfaultygoods.png',
        alt: 'Two people looking at their phones facing away from each other.',
      },
      author: { name: 'Which?', jobTitle: 'Editorial team' },
    },
  },
  legalData: {
    title:
      'Unfortunately you have fewer consumer rights if you gave permission for your parcel to be left somewhere',
    standFirst: '',
    bodyCopy: [
      "If you asked for your parcel to be left in a particular place or with a neighbour and it's subsequently gone missing or is stolen, you may have fewer consumer rights protections.",
      'It is unlikely the retailer would see it as their responsibility to replace it because, legally speaking, you have given the courier permission to leave the goods somewhere – and you effectively take responsibility for them after the courier has delivered them to that specified place.',
      "We recommend people be cautious about asking parcels to be left in a 'safe place' or with neighbours, as it puts them at risk if something goes wrong. It is always safer to have it re-delivered.",
    ],
    link: {
      href: 'https://legalservice.which.co.uk/',
      headline:
        'The Consumer rights website offers free information and advice on a broad range of issues. But if you want tailored advice you can call our experienced legal experts.',
      text: 'Which? Legal Service',
    },
    ctaData: {
      image: {
        src: 'https://media.product.which.co.uk/prod/images/original/gm-8b0968cf-b7d0-49e7-b5fd-ff38b3887a0d-limitedoptionsfaultygoods.png',
        alt: 'Two people looking at their phones facing away from each other.',
      },
      author: { name: 'Which?', jobTitle: 'Editorial team' },
    },
  },
}
