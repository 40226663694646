import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../../shared/state/appContext'
import globalStyles from '../../../../../shared/styles/GlobalStyles.module.scss'
import { trackAppointmentBookingSelection } from '../../../../../shared/utils/tracking/tracking'
import {
  BuyingTopicFlow,
  CarsTopicFlow,
  ContractChoiceFlow,
  EmploymentTopicFlow,
  HolidayTopicFlow,
  HomeTopicFlow,
  LegalTopicFlow,
  LimitedOptionsFlow,
  MedicalTopicFlow,
  OtherTopicFlow,
  WillsTopicFlow,
} from '../../../pageFlowData'
import { legalTopicData } from './data'

export const LegalTopic: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    legal: { legalTopic },
    triage: { type },
    entitlement,
  } = state

  const handleTracking = (id: string, index: number) => {
    trackAppointmentBookingSelection(type, LegalTopicFlow.VIEW_NAME, { id, index })
  }

  const checkEntitlement = (topicName: string) => {
    if (topicName === 'other') {
      return true
    }
    return entitlement.legal[topicName]
  }

  const chooseStep = (id: string) => {
    const isEntitled = checkEntitlement(id)
    dispatch({ type: 'UPDATE_ENTITLEMENT_CHECK', data: isEntitled })
    if (!isEntitled) {
      return LimitedOptionsFlow
    }
    switch (id) {
      case 'cars':
        return CarsTopicFlow
      case 'employment':
        return EmploymentTopicFlow
      case 'dataProtection':
        return ContractChoiceFlow
      case 'wills':
        return WillsTopicFlow
      case 'buying':
        return BuyingTopicFlow
      case 'medical':
        return MedicalTopicFlow
      case 'holiday':
        return HolidayTopicFlow
      case 'home':
        return HomeTopicFlow
      case 'other':
        return OtherTopicFlow
    }
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_LEGAL_TOPIC', data: id })
    const step = chooseStep(id)
    dispatch({ type: 'UPDATE_STEP', data: step?.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: step?.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string, index: number) => {
    handleTracking(id, index)
    handleDispatch(id)
  }

  const { buttons, imgObj, title } = legalTopicData

  return (
    <div className={globalStyles.fullWidthButton}>
      <PillButtonPage
        buttons={buttons}
        defaultValue={legalTopic ? legalTopic : ''}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        title={title}
      />
    </div>
  )
}
