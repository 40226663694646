import { data } from '../PageComponents/WhenPurchased/data'
import type { UserProps } from './../../../shared/types/toolTypes'

export const pageData = (state: {
  emailData: { body: { text: string }[] }
  issue: {
    description: string
    desiredResolution: string
    shortDesc: string[]
  }
  optIns: { singleConsentOptIn: boolean; caseStudy: boolean }
  product: {
    brand: string
    description: string
    model: string
    onFinance: boolean
    price: string
    type: string
  }
  retailer: {
    attemptedResolveItem: boolean
    channel: string
    name: string
    orderNum: string
    whenBought: string
  }
  user: UserProps
}) => {
  const { emailAddress, firstName, lastName } = state.user
  const { attemptedResolveItem, channel, name, orderNum, whenBought } = state.retailer
  const { singleConsentOptIn, caseStudy } = state.optIns
  const { brand, description, model, onFinance, price, type } = state.product
  const { desiredResolution, shortDesc } = state.issue
  const questions: any = {}
  const { body } = state.emailData
  let emailBodyBuilder = ''
  body.forEach((bodyData) => {
    if (bodyData.text) {
      emailBodyBuilder = emailBodyBuilder + `${bodyData.text}<br />`
    }
  })

  // Questions for both digital and product path
  questions['question.1095562'] = type // TF - What did you buy?
  questions['question.1045608'] = description // TF - Faulty Goods Tool - P/D - What is the item you bought?
  questions['question.1095765'] = attemptedResolveItem // TF - Faulty Goods Tool - P/D - Has the seller attempted to repair/replace the item?
  questions['question.1045622'] = desiredResolution // TF - What do you want the seller to do?
  questions['question.1095766'] = name // TF - Which retailer did you buy the item from?
  questions['question.1045605'] = price // TF - How much did you pay for it?
  questions['question.1045610'] = state.issue.description // TF - Tell us more about the problem
  questions['question.1095748'] = orderNum // TF - Faulty Goods Tool - P/D - What is your order number?
  questions['question.1095768'] = caseStudy ? 'Y' : 'N' // TF - Faulty Goods Tool - P/D - Contact Opt-In
  questions['question.1598463'] = singleConsentOptIn ? 'Y' : 'N' // TF - Single Consent opt-in
  questions['question.1105151'] = emailBodyBuilder // TF - Faulty Goods Tool - P/D - Composed Email
  questions['question.1122431'] = 'Y'
  questions['question.1184531'] = 'Y' // TF - Impact survey opt-in

  const whenBoughtLabel = (buttonSet: { id: string; label: string }[]) => {
    return buttonSet.filter((obj) => {
      return obj.id === whenBought
    })[0].label
  }
  // product only questions
  if (type === 'product') {
    questions['question.1045594'] = shortDesc // TF - Faulty Goods Tool - P - What is wrong with the item you bought?
    questions['question.1095753'] = brand // TF - Faulty Goods Tool - P - What brand is the item?
    questions['question.1045606'] = model // TF - Faulty Goods Tool - P - What is the product name/model number?
    questions['question.1095754'] = channel // TF - Faulty Goods Tool - P - Where did you buy the item?
    questions['question.1045593'] = whenBoughtLabel(data.productButtons) // TF - Faulty Goods Tool - P - How long ago did you take ownership of it?
    questions['question.1095757'] = onFinance // TF - Faulty Goods Tool - P - Did you buy the item on finance?
  } else {
    // digital only questions
    questions['question.1095561'] = shortDesc
    questions['question.1095696'] = whenBoughtLabel(data.digitalButtons) // TF - Faulty Goods Tool - D - How long ago did you take ownership of it?
  }

  // return supporter
  return {
    supporter: {
      Email: emailAddress,
      'First name': firstName,
      'Last name': lastName,
      questions,
    },
  }
}
