import type { FunctionComponent } from 'react'
import React, { useContext, useEffect, useRef, useState } from 'react'

import { ToolFeedbackAudienceModule } from '../../pages/ToolFeedbackAudienceModule'
import { ToolContext } from '../../state/appContext'
import type { FormEntryInputRef, SingleInputState } from '../../types/toolTypes'
import { cleanText } from '../../utils/cleanText'
import { pageSubmission } from '../../utils/EngagingNetwork/pageSubmission'
import { generateRandomEmailAddress } from '../../utils/generateRandomEmailAddress'
import { isNotEmpty } from '../../utils/isNotEmpty'
import { toolFeedbackAudienceSurveyData } from '../../utils/toolFeedbackAudienceSurveyData/toolFeedbackAudienceSurveyData'
import { validateHalfPostcode } from '../../utils/validateHalfPostcode'

export const ToolFeedbackAudiencePage: FunctionComponent<Props> = ({
  pageData,
  toolFeedbackAudienceFlowData,
  toolFeedbackThankYouFlowData,
  toolName,
}) => {
  const { dispatch } = useContext(ToolContext)
  const { title, standFirst, questions } = pageData

  const [errorCount, setErrorCount] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')
  const [radiosSelected, setRadiosSelected] = useState<
    { title: string; id: string; groupId: string; isSubmitValid: boolean }[]
  >([])
  const [radiosHaveError, setRadiosHaveError] = useState(true)
  const [submitCount, setSubmitCount] = useState(0)
  const [questionState, setQuestionState] = useState(questions)
  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })

  const ToolFeedbackAudienceInput = useRef<FormEntryInputRef>(null)

  useEffect(() => {
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: toolFeedbackAudienceFlowData.PROGRESS_VALUE })
  }, [dispatch, toolFeedbackAudienceFlowData.PROGRESS_VALUE])

  useEffect(() => {
    if (radiosSelected.length > 0) {
      setQuestionState(validateRadioAnswers(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radiosSelected])

  const validateRadioAnswers = (submitted: boolean) => {
    const radios = questions.filter(
      (question: { typeName: string }) => question.typeName === 'radio'
    )

    const questionData = questions.map((question: { typeName: string; id: string }) => {
      let isSubmitValid = true

      if (question.typeName === 'radio') {
        if (radiosSelected.length === 0) {
          isSubmitValid = false
          setRadiosHaveError(true)
        }
        if (radiosSelected.length > 0) {
          const found = radiosSelected.find((radio) => radio.groupId === question.id)

          if (!found && (submitCount > 0 || submitted) && radiosSelected.length < radios.length) {
            isSubmitValid = false
            setRadiosHaveError(true)
          }
        }
        if (radiosSelected.length === radios.length) {
          setRadiosHaveError(false)
        }
      }
      return { ...question, ...{ isSubmitValid } }
    })

    return questionData
  }

  const selectedRadioAnswers = () => {
    const radioAnswers: { [key: string]: string } = {}

    radiosSelected.forEach((radio) => {
      const { groupId, id } = radio
      radioAnswers[`${groupId}`] = id
    })

    return radioAnswers
  }

  const handleSkip = () => {
    dispatch({
      type: 'UPDATE_STEP',
      data: toolFeedbackThankYouFlowData.STEP,
    })
  }

  const handleRadioChange = (id: string, radioButtonTitle: string, radioGroupId: string) => {
    const copyOfRadiosSelected = radiosSelected.map((selectedRadio) => ({ ...selectedRadio }))
    const objIndex = copyOfRadiosSelected.findIndex(
      (radioGroup) => radioGroup.title === radioButtonTitle
    )

    if (objIndex > -1) {
      copyOfRadiosSelected[objIndex].id = id
    } else {
      copyOfRadiosSelected.push({
        title: radioButtonTitle,
        id,
        groupId: radioGroupId,
        isSubmitValid: false,
      })
    }
    setRadiosSelected([...copyOfRadiosSelected])
  }

  const handleSubmit = async () => {
    setErrorCount(0)
    setErrorMessage('')
    const postcodeValue: string = ToolFeedbackAudienceInput.current?.formEntryInput?.value || ''
    const postcodeValueClean = cleanText(postcodeValue.trim())
    const postcodeHasError = !validateHalfPostcode(postcodeValue) && isNotEmpty(postcodeValue)
    const inputFieldIndex = questions.findIndex(
      (question: { id: string }) => question.id === 'postcode-half'
    )

    setSubmitCount(submitCount + 1)
    setInputData({
      renderError: postcodeHasError,
      value: postcodeValue,
    })

    const questionData = validateRadioAnswers(true)

    questionData[inputFieldIndex] = {
      ...questionData[inputFieldIndex],
      ...{ inputStateValue: postcodeValueClean, isSubmitValid: postcodeHasError },
    }

    setQuestionState(questionData)

    if (!postcodeHasError && !radiosHaveError) {
      const radioData = selectedRadioAnswers()
      const answers = { ...radioData, ...{ postcodeValue: postcodeValueClean } }

      dispatch({ type: 'UPDATE_TOOL_FEEDBACK_AUDIENCE_SURVEY', data: answers })
      const pageDataState = {
        toolFeedbackAudience: answers,
        email: generateRandomEmailAddress('@feedback.co.uk'),
        toolName,
      }
      const structuredData = toolFeedbackAudienceSurveyData(pageDataState)

      const ENResponse = await pageSubmission(structuredData, 97482)

      if (ENResponse && (ENResponse.ok || ENResponse.status === 'SUCCESS')) {
        dispatch({
          type: 'UPDATE_TOOL_FEEDBACK_AUDIENCE_SURVEY_COMPLETE',
          data: true,
        })
        dispatch({
          type: 'UPDATE_STEP',
          data: toolFeedbackThankYouFlowData.STEP,
        })
      } else {
        setErrorCount(errorCount + 1)
      }
    } else {
      setErrorCount(errorCount + 1)
      setErrorMessage('Please complete all fields to continue')
      const scrollTarget = document.getElementById('error-message')
      /* istanbul ignore next */
      scrollTarget && scrollTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return (
    <>
      <ToolFeedbackAudienceModule
        buttonText={'Send details'}
        errorCount={errorCount}
        errorData={inputData}
        errorMessage={errorMessage}
        handleRadioChange={handleRadioChange}
        handleSkip={handleSkip}
        handleSubmit={handleSubmit}
        questions={questionState}
        ref={ToolFeedbackAudienceInput}
        standFirst={standFirst}
        stateValue={radiosSelected}
        title={title}
      />
    </>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  pageData: any
  toolFeedbackAudienceFlowData: any
  toolFeedbackThankYouFlowData: any
  toolName: string
}
