import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'
import { InfoBox, List, ListItem, StandardLink } from '@which/seatbelt'
import { Typography } from '@which/seatbelt'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import globalStyles from '../../../../shared/styles/GlobalStyles.module.scss'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { EntitlementFlow, LimitedOptionsFlow, RepeatPerformanceFlow } from '../../pageFlowData'
import { repeatPerformanceData } from './data'
import styles from './RepeatPerformance.module.scss'

export const RepeatPerformance: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    product: { repeatPerformance },
  } = state

  const handleStepChoice = (id: string) => {
    if (id === 'no') {
      return { progress: LimitedOptionsFlow.PROGRESS_VALUE, step: LimitedOptionsFlow.STEP }
    }

    return { progress: EntitlementFlow.PROGRESS_VALUE, step: EntitlementFlow.STEP }
  }

  const handleTracking = (id: string) => {
    const trackingAnswer = `question | ${RepeatPerformanceFlow.VIEW_NAME} | ${id}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (id: string) => {
    const { progress, step } = handleStepChoice(id)

    dispatch({ type: 'UPDATE_REPEAT_PERFORMANCE', data: id === 'yes' })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleSubmit = (id: string) => {
    handleTracking(id)
    handleDispatch(id)
  }

  const {
    repeatPerformanceButtons,
    imgObj,
    title,
    infoBoxParagraphOne,
    infoBoxParagraphTwo,
    infoBoxParagraphThree,
    infoBoxParagraphFour,
    bulletPoints,
    link: { href, text },
    infoBox,
  } = repeatPerformanceData

  return (
    <>
      <PillButtonPage
        buttons={repeatPerformanceButtons}
        defaultValue={repeatPerformance}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        pillButtonGroupStyle={styles.repeatPerformancePagePillButtons}
        title={title}
      />
      <InfoBox {...infoBox} className={globalStyles.maxWidth}>
        <Typography className={styles.repeatPerformancePageParagraph} tag="p" textStyle="body">
          {infoBoxParagraphOne}
        </Typography>
        <Typography tag="p" textStyle="body">
          {infoBoxParagraphTwo}
        </Typography>
        <Typography tag="p" textStyle="body">
          {infoBoxParagraphThree}
        </Typography>
        <Typography tag="p" textStyle="body">
          {infoBoxParagraphFour}
        </Typography>
        <div className={globalStyles.spacing25}>
          {bulletPoints.map((bulletData) => {
            return (
              <List key={bulletData}>
                <ListItem className={globalStyles.bulletBlack} key={bulletData}>
                  <Typography textStyle="body">{bulletData}</Typography>
                </ListItem>
              </List>
            )
          })}
        </div>
        <StandardLink href={href}>{text}</StandardLink>
      </InfoBox>
    </>
  )
}
