import type { FunctionComponent } from 'react'
import React from 'react'
import {
  ButtonLink,
  ChevronRightIcon,
  Grid,
  GridItem,
  Picture,
  SectionHeader,
  Typography,
} from '@which/seatbelt'

import classNames from 'classnames'

import globalStyles from '../../styles/GlobalStyles.module.scss'
import styles from './CTASection.module.scss'

export const CTASection: FunctionComponent<Props> = ({
  classes,
  header,
  imageSrc,
  imageAlt,
  info,
  buttonLink,
  buttonText,
  title,
  noBottomMargin,
  backgroundColour,
}) => {
  const isPurpleBackground = backgroundColour === '#dadbeb'
  const isGreyBackground = backgroundColour === '#efefef'
  const isYellowBackground = backgroundColour === '#fecc27'

  return (
    <div
      className={classNames({
        [globalStyles.noPrint]: true,
        [globalStyles.purpleBackground]: isPurpleBackground,
        [globalStyles.greyBackground]: isGreyBackground,
        [globalStyles.yellowBackground]: isYellowBackground,
      })}
    >
      <Grid
        className={classNames({
          [styles.CTASectionWrapper]: true,

          [`${classes}`]: classes,
          [styles.CTASectionWrapperNoBottomMargin]: noBottomMargin,
        })}
        data-testid="dynamic-journey-entry"
      >
        {header && (
          <GridItem columnStart={{ medium: 2, large: 2 }} span={{ medium: 10, large: 10 }}>
            {header && <SectionHeader className={styles.CTASectionHeader} text={header} />}
          </GridItem>
        )}
        <GridItem
          className={styles.CTASectionContent}
          columnStart={{ medium: 2, large: 2 }}
          span={{ medium: 7, large: 7 }}
        >
          {title && (
            <Typography className={styles.CTASectionTitle} tag="h1" textStyle="title-600">
              {title}
            </Typography>
          )}
          {info && (
            <Typography className={styles.CTASectionInfo} textStyle="body">
              {info}
            </Typography>
          )}

          {buttonLink && buttonText && (
            <ButtonLink
              appearance="secondary"
              className={styles.CTASectionButton}
              href={buttonLink}
            >
              <ChevronRightIcon />
              {buttonText}
            </ButtonLink>
          )}
        </GridItem>
        {imageSrc && (
          <GridItem columnStart={{ medium: 9, large: 9 }} span={{ medium: 3, large: 3 }}>
            <Picture
              alt={imageAlt}
              aspectRatioMain="four-to-three"
              className={styles.CTASectionPicture}
              lazyLoad={false}
              src={imageSrc}
            />
          </GridItem>
        )}
      </Grid>
    </div>
  )
}

///////// IMPLEMENTATION /////////
export type Props = {
  classes?: string
  header?: string
  title: string
  info: string
  buttonLink: string
  buttonText: string
  imageSrc: string
  imageAlt: string
  noBottomMargin?: boolean
  backgroundColour?: string
}
