import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { CurrencyPage } from '../../../../shared/pages/CurrencyPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryInputRef } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { ClaimValueFlow, WhatHappenedFlow } from '../../pageFlowData'
import { data } from './data'

export const ClaimValue: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    issue: { claimValue },
  } = state

  const ClaimValueInput = useRef<FormEntryInputRef>(null)
  const [ClaimValueInputValid, setClaimValueInputValid] = useState<boolean>(true)

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${ClaimValueFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_CLAIM_VALUE', data: value })
    dispatch({ type: 'UPDATE_STEP', data: WhatHappenedFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: WhatHappenedFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    const value = getCleanValue(ClaimValueInput?.current?.formEntryInput?.value)
    const claimValueHasError = !isNotEmpty(value)

    if (!claimValueHasError) {
      handleTracking(value)
      handleDispatch(value)
      setClaimValueInputValid(true)
      return
    }
    setClaimValueInputValid(false)
  }

  const { imgObj, title } = data

  return (
    <CurrencyPage
      buttonText="Next"
      currencySymbol="£"
      defaultValue={claimValue}
      handleSubmit={handleSubmit}
      id="claim-value"
      imgObj={imgObj}
      infoBoxData={data}
      isFocused
      isSubmitValid={ClaimValueInputValid}
      ref={ClaimValueInput}
      title={title}
    />
  )
}
