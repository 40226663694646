export const ctaDataOption1 = {
  link: {
    href: 'https://www.which.co.uk/consumer-rights/home-and-property',
  },
  title: 'Linked credit and home improvement work',
  description:
    "Linked credit is often used to get building work done. If something goes wrong, you're often covered by the Consumer Rights Act 2015.",
  image: {
    src: 'https://s3-eu-west-1.amazonaws.com/prod-media-which/prod/images/original/gm-8b0968cf-b7d0-49e7-b5fd-ff38b3887a0d-limitedoptionsfaultygoods.png',
    alt: 'Two people looking at their phones facing away from each other.',
  },
  author: { name: 'Which?', jobTitle: 'Editorial team' },
}

export const ctaDataOption2 = {
  link: {
    href: 'https://www.legislation.gov.uk/ukpga/2015/15/part/1/chapter/4/enacted',
  },
  title: 'What is repeat performance and why does it matter?',
  description: "Learn more about your rights when a service you've bought doesn't work out.",
  image: {
    src: 'https://s3-eu-west-1.amazonaws.com/prod-media-which/prod/images/original/gm-8b0968cf-b7d0-49e7-b5fd-ff38b3887a0d-limitedoptionsfaultygoods.png',
    alt: 'Two people looking at their phones facing away from each other.',
  },
}

export const limitedOptionsData = {
  option1: {
    title: 'Your options are limited but Section 75a may help',
    standFirst: '',
    bodyCopy: [
      'Was the purchase worth more than £30,000, but less than £60,260?',
      'If so you may be covered by Section 75a of the Consumer Credit Act, which allows you to make a claim against the finance company who gave you the loan.',
      "If you haven't already, you must try to contact the vendor and resolve it with them. Your next step will be to take your claim to the credit provider.",
    ],
    link: {
      href: 'https://www.which.co.uk/consumer-rights/home-and-property',
      headline: 'Linked credit and home improvement work',
      text: "Linked credit is often used to get building work done. If something goes wrong, you're often covered by the Consumer Rights Act 2015.",
    },
  },
  option2: {
    title:
      "Before you can make an S75 claim, you need to ask the provider to 'repeat perform' IE provide the service again for free.",
    standFirst: '',
    bodyCopy: [
      'You should ask the provider to ‘repeat perform’ in the first instance before making a S75 claim.',
      'If that is unsuccessful or impossible e.g they are no longer trading, then you are usually in a stronger position to make a claim for a refund from your credit card provider.',
      'A credit card company may put pressure on the service provider to carry out a repeat performance before they consider refunding you.',
    ],
    link: {
      href: 'https://www.legislation.gov.uk/ukpga/2015/15/part/1/chapter/4/enacted',
      headline: 'What is repeat performance and why does it matter?',
      text: "Learn more about your rights when a service you've bought doesn't work out.",
    },
  },
}
