import type { Action, State } from '../../state/toolStateTypes'

export const combineReducers = (slices: State) => (state: State, action: Action) =>
  Object.keys(slices).reduce(
    (acc, prop) => ({
      ...acc,
      [prop]: slices[prop](acc[prop], action),
    }),
    state
  )
