export const addressURLs = {
  prod: 'https://7hj996rsx8.execute-api.eu-west-1.amazonaws.com/prod/loqate-api',
  preprod: 'https://n7yhjq9c4j.execute-api.eu-west-1.amazonaws.com/preprod/loqate-api',
  qa: 'https://bnbl8cpbv8.execute-api.eu-west-1.amazonaws.com/qa/loqate-api',
  test: 'https://wdxfhdmtij.execute-api.eu-west-1.amazonaws.com/test/loqate-api',
  dev: 'https://edsg0nq6lj.execute-api.eu-west-1.amazonaws.com/dev/loqate-api',
  localhost: 'http://localhost:3500/loqate-api',
}

export const ADDRESS_API = (): string => {
  if (typeof window === 'undefined') {
    return addressURLs.prod
  } else {
    switch (window.location?.hostname) {
      case 'localhost':
        return addressURLs.localhost
      case 'dev-deliver.components.product.which.co.uk':
      case 'dev.internal.which.co.uk':
        return addressURLs.dev

      case 'test-deliver.components.product.which.co.uk':
      case 'test.internal.which.co.uk':
        return addressURLs.test

      case 'qa-deliver.components.product.which.co.uk':
        return addressURLs.qa

      case 'preprod-deliver.components.product.which.co.uk':
      case 'preprod.internal.which.co.uk':
        return addressURLs.preprod

      default:
        return addressURLs.prod
    }
  }
}
