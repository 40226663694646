import type { ResultsSection, ResultsSectionLinks } from '../../../../shared/types/toolTypes'
import type { myMoneyHealthCheckToolState } from '../../state/myMoneyHealthCheckToolState'

const mapLinks = (links: any, widgetLink?: ResultsSectionLinks) => {
  const linksArray: { text: string; href: string }[] = []
  if (widgetLink) {
    const collectionNum = widgetLink[0]?.data?.components?.collections.length - 1
    const linkData = {
      text: widgetLink[0]?.data?.components?.collections[collectionNum]?.data?.linkText,
      href: widgetLink[0]?.data?.components?.collections[collectionNum]?.data?.linkUrl,
    }

    if (linkData.href) {
      linksArray.push(linkData)
    }
  }

  links &&
    links?.map(({ path, headline }) => {
      const obj = { text: '', href: '' }

      obj.text = headline
      obj.href = path

      linksArray.push(obj)
    })

  return linksArray
}

export const loadLinks = (
  resultsData: ResultsSection,
  {
    financeSituation,
    energyPayment,
    foodShop,
    broadbandContract,
    payingDebts,
    propertyPayment,
    benefits,
  }: typeof myMoneyHealthCheckToolState.myMoneyHealthCheck
) => {
  const emptyObject = {
    blockTitle: '',
    image: { src: '', alt: '' },
    links: [{ text: '', href: '' }],
    savings: '',
  }
  const resultsLinks = {
    energy: emptyObject,
    shopping: emptyObject,
    finance: emptyObject,
    homeOwnership: emptyObject,
    broadband: emptyObject,
  }

  resultsData.components.collections.map(({ type, data }) => {
    const objState = {
      blockTitle: '',
      image: { src: '', alt: '' },
      links: [{ text: '', href: '' }],
      savings: '',
    }
    const sectionData: ResultsSection = data

    switch (type) {
      case 'MMHC Results - Energy Bills Links':
        objState.blockTitle = sectionData.customData?.data?.title
        objState.image.src = sectionData.customData?.data.image.src
        objState.image.alt = sectionData.customData?.data.image.imageAlt

        objState.links = getMoneyLinks(data, energyPayment, financeSituation)
        objState.savings = getSavingTipsText(energyPayment)

        resultsLinks.energy = objState
        break

      case 'MMHC Results - Food Shopping Links':
        objState.blockTitle = sectionData.customData?.data?.title
        objState.image.src = sectionData.customData?.data.image.src
        objState.image.alt = sectionData.customData?.data.image.imageAlt

        objState.links = getMoneyLinks(sectionData, foodShop, financeSituation)
        objState.savings = getSavingTipsText(foodShop)

        resultsLinks.shopping = objState
        break

      case 'MMHC Results - Telecom Services Links':
        objState.blockTitle = sectionData.customData?.data?.title
        objState.image.src = sectionData.customData?.data.image.src
        objState.image.alt = sectionData.customData?.data.image.imageAlt

        objState.links = getMoneyLinks(sectionData, broadbandContract, financeSituation)
        objState.savings = getSavingTipsText(broadbandContract)

        if (benefits === 'yes') {
          const benefitsLinks = getBenefitsLinks(sectionData, broadbandContract, financeSituation)

          objState.links.push(benefitsLinks[0])
        }

        resultsLinks.broadband = objState
        break

      case 'MMHC Results - Housing Links':
        objState.blockTitle = sectionData.customData?.data?.title
        objState.image.src = sectionData.customData?.data.image.src
        objState.image.alt = sectionData.customData?.data.image.imageAlt

        objState.links = getMoneyLinks(sectionData, propertyPayment, financeSituation)
        objState.savings = getSavingTipsText(propertyPayment)

        resultsLinks.homeOwnership = objState
        break

      case 'MMHC Results - Everyday Banking and Debt Links':
        objState.blockTitle = sectionData.customData?.data.title
        objState.image.src = sectionData.customData?.data.image.src
        objState.image.alt = sectionData.customData?.data.image.imageAlt
        objState.links = []

        for (const debtType of payingDebts) {
          const links = getMoneyLinks(sectionData, debtType, financeSituation).slice(0, 3)

          for (const link of links) {
            if (!objState.links.some((x) => x.href === link.href)) {
              objState.links.push(link)
            }
          }
        }
        resultsLinks.finance = objState
        break
    }
  })

  return resultsLinks
}

const getSavingTipsText = (type: string) => {
  let savingTipsStr = ''

  switch (type) {
    case 'prepayment-meter':
      savingTipsStr =
        "Based on your results, you may have received energy vouchers of <strong> £67</strong> to redeem this winter. Make sure you've used them before the scheme ends on 30 June."
      break
    case 'direct-debit':
      savingTipsStr = 'Based on your results, you are paying for your energy in the cheapest way.'
      break
    case 'pay-monthly-quarterly':
      savingTipsStr =
        'Based on your results, you could be paying around <strong>6% more</strong> for energy than if you paid by direct debit. Consider switching methods if you can.'
      break
    case 'convenience-stores':
      savingTipsStr =
        'Based on your results, we suggest you shop at supermarkets if you can. Convenience store shopping could add an <strong>extra £800</strong> to your annual food bill.'
      break
    case 'supermarkets':
      savingTipsStr =
        'Regardless of where you shop, you can make savings with savvy grocery habits. Our guides can help you get started.'
      break
    case 'no':
      savingTipsStr =
        "Based on your results, you could save by switching to a new broadband provider - we've found that more than 7 in 10 do, with savings typically falling <strong> between 13-16%</strong>."
      break
    case 'yes':
      savingTipsStr =
        "Based on your results, you'll probably need to stay in your broadband contract until it ends. Note when it expires, as haggling with your provider can <strong>save 5-7%.</strong>"
      break
    case 'mortgage':
      savingTipsStr =
        "You told us you have a mortgage, so you'll need to keep an eye on when your term is up and fix a new deal."
      break
    case 'rent':
      savingTipsStr =
        'The rising cost of living is putting pressure on renters. Find out what your rights are in our guides.'
      break
    case 'outright':
      savingTipsStr =
        'Based on your results, you could save by changing insurance providers when you renew. Our members <strong>saved an average £103</strong> by switching home insurance provider.'
      break
    default:
      return savingTipsStr
  }

  return savingTipsStr
}

const getMoneyLinks = (data: any, type: string, financeSituation: string) => {
  let links: ResultsSectionLinks

  switch (type) {
    case 'prepayment-meter':
      links = data.components.collections.filter(
        (energy: ResultsSection) =>
          energy.type === 'MMHC Results - Energy Bills Links - Prepayment Meter'
      )
      break
    case 'direct-debit':
      links = data.components.collections.filter(
        (energy: ResultsSection) =>
          energy.type === 'MMHC Results - Energy Bills Links - Direct Debit'
      )
      break
    case 'pay-monthly-quarterly':
      links = data.components.collections.filter(
        (energy: ResultsSection) =>
          energy.type === 'MMHC Results - Energy Bills Links - Pay As You Go'
      )
      break
    case 'convenience-stores':
      links = data.components.collections.filter(
        (food: ResultsSection) =>
          food.type === 'MMHC Results - Food Shopping Links - Convenience Stores'
      )
      break
    case 'supermarkets':
      links = data.components.collections.filter(
        (food: ResultsSection) => food.type === 'MMHC Results - Food Shopping Links - Supermarkets'
      )
      break
    case 'no':
      links = data.components.collections.filter(
        (broadband: ResultsSection) =>
          broadband.type === 'MMHC Results - Telecom Services Links - No Contract'
      )
      break
    case 'yes':
      links = data.components.collections.filter(
        (broadband: ResultsSection) =>
          broadband.type === 'MMHC Results - Telecom Services Links - In Contract'
      )
      break
    case 'mortgage':
      links = data.components.collections.filter(
        (property: ResultsSectionLinks) =>
          property.type === 'MMHC Results - Housing Links - Mortgage'
      )
      break
    case 'rent':
      links = data.components.collections.filter(
        (property: ResultsSectionLinks) => property.type === 'MMHC Results - Housing Links - Rent'
      )
      break
    case 'outright':
      links = data.components.collections.filter(
        (property: ResultsSectionLinks) =>
          property.type === 'MMHC Results - Housing Links - Outright'
      )
      break
    case 'credit-cards':
      links = data.components.collections.filter(
        (debt: ResultsSectionLinks) =>
          debt.type === 'MMHC Results - Everyday Banking and Debt - Credit Cards'
      )
      break
    case 'personal-loans':
      links = data.components.collections.filter(
        (debt: ResultsSectionLinks) =>
          debt.type === 'MMHC Results - Everyday Banking and Debt - Personal Loans'
      )
      break
    case 'buy-now-pay-later':
      links = data.components.collections.filter(
        (debt: ResultsSectionLinks) =>
          debt.type === 'MMHC Results - Everyday Banking and Debt - Buy Now Pay Later Loans'
      )
      break
    case 'no-credit-cards-or-loans':
      links = data.components.collections.filter(
        (debt: ResultsSectionLinks) =>
          debt.type === 'MMHC Results - Everyday Banking and Debt - No Credit Cards or Loans'
      )
      break

    default:
      return []
  }

  return mapLinks(
    linksBasedOnFinanceSituation(links[0].data.customData.data, financeSituation),
    links
  )
}

const getBenefitsLinks = (data: any, type: string, financeSituation: string) => {
  let links: ResultsSectionLinks
  let benefitLinks: ResultsSectionLinks

  switch (type) {
    case 'yes':
      links = data.components.collections.filter(
        (broadband: ResultsSection) =>
          broadband.type === 'MMHC Results - Telecom Services Links - In Contract'
      )

      benefitLinks = links[0].data.components.collections.filter(
        (benefits: ResultsSection) =>
          benefits.type === 'MMHC Results -Telecom Services Links - In Contract - Benefits'
      )
      break
    case 'no':
      links = data.components.collections.filter(
        (broadband: ResultsSection) =>
          broadband.type === 'MMHC Results - Telecom Services Links - No Contract'
      )

      benefitLinks = links[0].data.components.collections.filter(
        (benefits: ResultsSection) =>
          benefits.type === 'MMHC Results -Telecom Services Links - No Contract - Benefits'
      )
      break

    /* istanbul ignore next */
    default:
      return []
  }

  return mapLinks(
    linksBasedOnFinanceSituation(benefitLinks[0].data.customData.data, financeSituation)
  )
}

const linksBasedOnFinanceSituation = (data: any, financeSituation: string) => {
  let linksArray = []

  const { amberLinks, greenLinks, redLinks } = data

  switch (financeSituation) {
    case 'red':
      linksArray = redLinks
      break
    case 'amber':
      linksArray = amberLinks
      break
    case 'green':
      linksArray = greenLinks
      break
  }

  return linksArray
}
