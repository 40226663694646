import { checkLocalStorageExists } from '../checkLocalStorageExists'
import { checkSessionStorageExists } from '../checkSessionStorageExists'
import { getLocalStorageItem } from '../getLocalStorageItem'
import { getSessionStorageItem } from '../getSessionStorageItem'

export const getState = ({ localStorageName, sessionStorageName, defaultState }) => {
  if (typeof window !== 'undefined') {
    if (checkLocalStorageExists(localStorageName)) {
      const localStorage = getLocalStorageItem(localStorageName)
      if (localStorage.initial !== undefined) {
        return getLocalStorageItem(localStorageName)
      }

      window.localStorage.removeItem('MMHC')
    }
    if (checkSessionStorageExists(sessionStorageName)) {
      return getSessionStorageItem(sessionStorageName)
    }
  }

  return defaultState
}
