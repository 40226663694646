import type { FunctionComponent } from 'react'
import React, { useContext, useEffect, useRef, useState } from 'react'

import { EnergyCurrencyPage } from '../../../../shared/pages/EnergyCurrencyPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryInputRef } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { HowMuchFlow, PaymentPredictionFlow } from '../../pageFlowData'
import { howMuchData } from './data'
import styles from './HowMuch.module.scss'

export const HowMuch: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: {
      entryToolName,
      journeyFlow: { isForward },
    },
    energy: { howMuch },
  } = state

  useEffect(() => {
    if (!isForward) {
      howMuchInput?.current?.formEntryInput?.focus()
    }
  }, [isForward])

  const howMuchInput = useRef<FormEntryInputRef>(null)
  const [howMuchInputValid, setHowMuchInputValid] = useState<boolean>(true)

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${HowMuchFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_STEP', data: PaymentPredictionFlow.STEP })
    dispatch({ type: 'UPDATE_HOW_MUCH', data: value })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: PaymentPredictionFlow.PROGRESS_VALUE })
  }

  const handleFocus = () => {
    dispatch({ type: 'UPDATE_INITIALISED', data: true })
  }

  const handleSubmit = () => {
    const value = getCleanValue(howMuchInput?.current?.formEntryInput?.value)
    const howMuchHasError = !isNotEmpty(value)

    if (!howMuchHasError) {
      handleTracking(value)
      handleDispatch(value)
      setHowMuchInputValid(true)
      return
    }
    setHowMuchInputValid(false)
  }

  const { imgObj, pageData } = howMuchData

  return (
    <div className={styles.howMuch} data-testid="how-much-page">
      <EnergyCurrencyPage
        buttonText="Calculate"
        currencySymbol="£"
        defaultValue={howMuch}
        handleFocus={handleFocus}
        handleSubmit={handleSubmit}
        id="how-much"
        imgObj={imgObj}
        isSubmitValid={howMuchInputValid}
        pageData={pageData}
        ref={howMuchInput}
      />
    </div>
  )
}
