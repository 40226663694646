export const deliveryComplaintToolState = {
  order: {
    deliveryProblem: null, // What's the problem with your delivery?
    deliveryPermission: null, // Did you give your permission to leave the parcel in a specific...
    itemOrdered: null, //  What item did you order?
    orderNumber: null, //  What is your order number? (optional)
    deliveryDateExplained: null, // Please explain when you want your order re-delivered...
    whatHappened: null, // What happened to your item?
    whatWentWrong: null, // Please tell us where the item was left and what went wrong?
  },
  retailer: {
    name: null, // What retailer did you place the order with?
  },
}
