export const data = {
  imgObj: {
    alt: 'A person sitting down in a chair looking at their phone',
    src: 'https://media.product.which.co.uk/prod/images/original/60ba22d0cc4e-step7.jpg',
  },
  title: 'What speed in megabits per second (Mbps) are you achieving in reality?',
  placeholderText: 'e.g. 150',
  standFirst:
    'Use our <a class="sb-link-primary" href="https://broadbandtest.which.co.uk/" target="_blank"><span class="sb-link-animation-wrapper">broadband speed test</span></a> to determine your speed',
}
