import type { FunctionComponent } from 'react'
import { useLayoutEffect } from 'react'
import React, { useContext } from 'react'
import { Typography } from '@which/seatbelt'

import classNames from 'classnames'

import { ToolContext } from '../../state/appContext'
import globalStyles from '../../styles/GlobalStyles.module.scss'
import type { ProgressBarProps } from '../../types/toolTypes'
import { checkSessionStorageExists } from '../../utils/checkSessionStorageExists'
import { getSessionStorageItem } from '../../utils/getSessionStorageItem'
import { removeSessionStorage } from '../../utils/removeSessionStorage'
import { BackButton } from '../BackButton'
import styles from './ProgressBar.module.scss'

export const ProgressBar: FunctionComponent<ProgressBarProps> = (props) => {
  const {
    allFlows,
    endFlowStep,
    setIsForward,
    startFlowStep,
    toolName,
    stateWidth,
    startingSteps,
  } = props
  const { state } = useContext(ToolContext)
  const {
    initial: { step },
    toolFeedback: { toolFeedback },
  } = state

  let isAStartingStep = false
  if (startingSteps && startingSteps.length > 0) {
    startingSteps.forEach((startingStep) => {
      if (startingStep === step) {
        isAStartingStep = true
      }
    })
  }

  const progressBarRef = React.useRef<HTMLDivElement>(null)
  const showBackButton =
    step !== 0 &&
    step !== endFlowStep &&
    !toolFeedback &&
    step !== startFlowStep &&
    !isAStartingStep

  const updateProgressBar = ({ back, width, prevWidth }) => {
    // Get the current width of the progress bar
    const getCurrentWidth = () => {
      /* istanbul ignore next */
      if (progressBarRef.current) {
        return parseInt(
          progressBarRef.current.style.width
            ? progressBarRef.current.style.width.replace('%', '')
            : '0'
        )
      }
    }
    const frame = () => {
      if (progressBarRef.current) {
        const currentWidth = getCurrentWidth() || 0
        if ((currentWidth >= width && !back) || (back && prevWidth <= width)) {
          clearInterval(runProgressBar)
        } else {
          back ? prevWidth-- : prevWidth++
          progressBarRef.current.style.width = prevWidth + '%'
        }
      }
    }
    const runProgressBar = setInterval(frame, 10)
  }

  useLayoutEffect(() => {
    let prevWidth = 0
    let isBack = false
    if (checkSessionStorageExists(`${toolName}Progress`)) {
      const sessionProgress = getSessionStorageItem(`${toolName}Progress`)
      if (sessionProgress.length >= 2 && toolName === 'MyMoneyHealthCheck') {
        const sessionProgressLength = sessionProgress.length - 2
        prevWidth = sessionProgress[sessionProgressLength]
      } else {
        if (checkSessionStorageExists(`${toolName}BackProgress`)) {
          prevWidth = parseInt(getSessionStorageItem(`${toolName}BackProgress`))
          removeSessionStorage({ sessionStorageName: `${toolName}BackProgress` })
        } else {
          const sessionProgressLength = sessionProgress.length - 1
          prevWidth = sessionProgress[sessionProgressLength]
        }
      }
      isBack = prevWidth > stateWidth
    }

    updateProgressBar({ back: isBack, width: stateWidth, prevWidth: prevWidth })
  }, [stateWidth, toolName])

  return (
    <div className={classNames(styles.progressBar, globalStyles.spacingProgress)}>
      <div
        className={classNames(styles.progressBarText, {
          [styles.progressBarTextBackButton]: showBackButton,
        })}
      >
        {showBackButton && (
          <BackButton
            allFlows={allFlows}
            className={styles.progressBarBackButton}
            setIsForward={setIsForward}
            toolName={toolName}
          />
        )}
        <Typography className={styles.progressBarHeader} textStyle="body-intro">
          {stateWidth}% complete
        </Typography>
      </div>
      <div className={styles.progressBarBackground}>
        <div
          className={styles.progressBarProgress}
          data-testid="progress-bar"
          id="progress-bar"
          ref={progressBarRef}
        />
      </div>
    </div>
  )
}
