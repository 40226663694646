export const techTopicData = {
  buttons: [
    { label: 'Buying Advice', id: 'buying-advice' },
    { label: 'Email Issues', id: 'email-issues' },
    { label: 'File, Data or Storage', id: 'file-data-storage' },
    { label: 'General Computer Problems', id: 'general-problems' },
    { label: 'Hardware Issues', id: 'hardware-issues' },
    { label: 'Internet & Networking', id: 'internet-networking' },
    { label: 'Printers & Ink', id: 'printers-ink' },
    { label: 'Security & Privacy', id: 'security-privacy' },
    { label: 'Software & Programs', id: 'software-programs' },
  ],
  title: 'Which topic best describes your computing problem?',
  imgObj: {
    alt: 'Two people looking stressed at a computer',
    src: 'https://media.product.which.co.uk/prod/images/original/1fea9f96da2f-have-you-been-affected-by-the-scam.jpg',
  },
}
