import { constructDate } from '../../../../shared/components/DatePicker/formatDate/constructDate'
import type { UserPropsWithAddress } from '../../../../shared/types/toolTypes'
import { formatTextInput } from '../../../../shared/utils/formatInput/formatTextInput'
import type { s75ToolState } from '../../state/s75ToolState'
import type { initialToolState } from './../../../../shared/state/initialToolState'

export const composeEmailBody = ({
  retailer,
  product,
  issue,
  journeyFlow,
  user,
  autoEmailer = false,
}: emailBodyProps) => {
  const { name } = retailer
  const { purchasedDate, description, price, purchaseMethod, type } = product
  const {
    desiredResolution,
    cardProvider,
    sellerContact,
    description: issueDescription,
    claimValue,
  } = issue
  const { branch } = journeyFlow
  const { firstName, lastName, address } = user
  const productTypeText = type === 'product' ? 'item' : 'service'

  const resolution =
    desiredResolution === 'repair'
      ? `for this ${productTypeText} to be repaired`
      : desiredResolution === 'replacement'
        ? `a replacement for this ${productTypeText}`
        : `a refund for this ${productTypeText}` // defaults to refunded

  const formattedPurchaseMethod = () => {
    switch (purchaseMethod) {
      case 'creditCard':
        return 'credit card'
      case 'linkedCreditAgreement':
        return 'linked credit agreement'
      case 'debitCard':
        return 'debit card'
      case 'chargeCard':
        return 'charge card'
      default:
        return ''
    }
  }

  const constructPaymentText =
    purchaseMethod === 'creditCard' && branch === 's75'
      ? `I bought ${description} from ${name}, I paid £${claimValue} using my ${cardProvider} ${formattedPurchaseMethod()}.<br/>\r\n`
      : `I bought ${description} for £${price} from ${name} using my ${cardProvider} ${formattedPurchaseMethod()}.<br/>\r\n`

  let addressArr = [
    { text: `${address?.addressLine1}` },
    { text: `${address?.addressLine2 ? `${address.addressLine2}` : ''}` },
    { text: `${address?.townCity}` },
    { text: `${address?.county ? `${address.county}` : ''}` },
    { text: `${address?.postcode}` },
  ]

  const sellerContactInfo = sellerContact
    ? `The communication I've had with the retailer/provider is: ${formatTextInput(
        sellerContact,
        false
      )}<br/>\r\n`
    : ''

  let body = [
    { text: '<br/>\r\nDear Sir or Madam,<br/>\r\n' },
    { text: `On ${constructDate(new Date(purchasedDate))}, ${constructPaymentText}` },
    {
      text: `I am claiming that ${name} are in breach of contract because ${formatTextInput(
        issueDescription,
        false
      )}<br/>\r\n`,
    },
    { text: `${sellerContactInfo}` },
    {
      text: `${
        branch === 's75'
          ? `I have tried unsuccessfully to resolve this dispute with the retailer. Under Section 75 of the Consumer Credit Act 1974, you are jointly and severally liable for any breaches committed by the retailer. I would now like to under the Chargeback Scheme rules, I would like ${resolution}.`
          : `Under the Chargeback Scheme rules, I would like ${resolution} for this item.`
      }<br/>\r\n`,
    },
    { text: 'I would be grateful if you could respond within 14 days.<br/>\r\n' },
    { text: 'Yours faithfully,<br/>\r\n' },
    { text: `${firstName} ${lastName}` },
  ]

  addressArr = addressArr.filter(String)
  body = autoEmailer ? addressArr.concat(body) : body
  body = body.filter(String)
  return {
    label: 'Preview of complaint email',
    address: addressArr,
    body: body,
  }
}

type emailBodyProps = {
  retailer: typeof s75ToolState.retailer
  product: typeof s75ToolState.product
  issue: typeof s75ToolState.issue
  journeyFlow: typeof initialToolState.initial.journeyFlow
  user: UserPropsWithAddress
  autoEmailer?: boolean
}
