import React from 'react'
import { Button, GridItem } from '@which/seatbelt'

import styles from '../ResultsPage.module.scss'

export const RetakeButton = ({ retakeButtonText, restartClickHandler }: Props) => {
  return (
    <GridItem
      className={styles.restartToolWrapper}
      columnStart={{ medium: 3, large: 5 }}
      span={{ medium: 8, large: 4 }}
    >
      <Button
        appearance="secondary"
        className={styles.restartToolButton}
        data-testid="restart-button"
        onClick={restartClickHandler}
      >
        {retakeButtonText}
      </Button>
    </GridItem>
  )
}

type Props = {
  retakeButtonText: string
  restartClickHandler: () => any
}
