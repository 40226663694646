import { useEffect, useRef } from 'react'

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const usePrevious = <previous extends unknown>(value: previous): previous | undefined => {
  const ref = useRef<previous>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
