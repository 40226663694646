export const followUpOptInData = {
  radioQuestionData: {
    buttons: [
      {
        label: 'Yes',
        id: 'yes',
        isFocused: false,
      },
      {
        label: 'No, I do not wish to be contacted',
        id: 'no',
        isFocused: false,
      },
    ],
    isSubmitValid: true,
    id: 'follow-up-opt-in',
  },
}
