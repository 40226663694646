export const myMoneyHealthCheckToolState = {
  myMoneyHealthCheck: {
    financeSituation: '', // Question name
    propertyPayment: '', // Question name
    benefits: '', // Question name
    energyPayment: '', // Question name
    foodShop: '', // Question name
    broadbandContract: '', // Question name
    payingDebts: [], // Question name
    landingPageCompleted: null, // used for landing page
  },
}
