export const contactDetailsData = {
  formItems: [
    {
      displayText: 'First name',
      id: 'firstName',
      type: 'text',
      isFocused: true,
    },
    {
      displayText: 'Last name',
      id: 'lastName',
      type: 'text',
      isFocused: false,
    },
    {
      displayText: 'Email address',
      id: 'email',
      type: 'email',
      isFocused: false,
    },
    {
      displayText: 'Phone number',
      id: 'phoneNumber',
      type: 'number',
      isFocused: false,
    },
  ],
}
