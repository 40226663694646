export const jobTypeData = {
  buttons: [
    {
      label: 'Plumbing or gas safety job',
      id: 'plumbing',
    },
    {
      label: 'Electrical or technology job',
      id: 'electrical',
    },
    {
      label: 'Outdoor job',
      id: 'outdoor',
    },
    {
      label: 'Kitchen or bathroom fitting job',
      id: 'kitchen',
    },
    {
      label: 'Building job',
      id: 'building',
    },
    {
      label: 'Decorative works',
      id: 'decorative',
    },
    {
      label: 'Odd jobs / handy person jobs',
      id: 'odd',
    },
    {
      label: 'Carpentry job',
      id: 'carpentry',
    },
    {
      label: 'Building/other job involving several traders',
      id: 'other',
    },
    {
      label: 'Energy efficiency job',
      id: 'energy',
    },
    {
      label: 'Other trader/specialist job',
      id: 'specialist',
    },
  ],
  title: 'What job type was carried out?',
  imgObj: {
    alt: 'Two people painting',
    src: 'https://media.product.which.co.uk/prod/images/original/badf98467653-step4.jpg',
  },
}
