import type { FunctionComponent } from 'react'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import { CheckBoxGroupPage } from '../../../../shared/pages/CheckBoxGroupPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { PayingDebtsFlow, ResultsPageFlow } from '../../pageFlowData'
import { payingDebtsData } from './data'

export const PayingDebts: FunctionComponent = () => {
  const [isInputValid, setInputValid] = useState(true)
  const { state, dispatch } = useContext(ToolContext)
  const { buttons, title, imgObj } = payingDebtsData
  const [buttonState, setButtonState] = useState(buttons)
  const {
    initial: { entryToolName },
    myMoneyHealthCheck: { payingDebts, financeSituation },
  } = state

  const [disableBtn, setDisableBtn] = useState(false)

  useEffect(() => {
    dispatch({ type: 'UPDATE_PAYING_DEBTS', data: payingDebts })
  }, [dispatch, payingDebts])

  const disableButtons = useCallback(
    (currentCheckedButtons: string[]) => {
      const buttonId = 'no-credit-cards-or-loans'
      let editedButtons: { label: string; id: string; disabled: boolean }[] = []
      editedButtons = buttons.map((button) => {
        if (!currentCheckedButtons || currentCheckedButtons.length === 0) {
          return { ...button, disabled: false }
        } else if (currentCheckedButtons.includes(buttonId)) {
          return button.id !== buttonId ? { ...button, disabled: true } : button
        } else {
          return button.id === buttonId ? { ...button, disabled: true } : button
        }
      })
      setButtonState(editedButtons)
    },
    [buttons]
  )

  const handleCheckBoxChange = ({ id }: { id?: string }) => {
    const checkboxChange = payingDebts || []
    if (!checkboxChange.includes(id)) {
      checkboxChange.push(id)
    } else {
      checkboxChange.splice(checkboxChange.indexOf(id), 1)
    }
    disableButtons(checkboxChange)
    dispatch({ type: 'UPDATE_PAYING_DEBTS', data: checkboxChange })
  }

  useEffect(() => {
    disableButtons(payingDebts)
  }, [disableButtons, payingDebts])

  const handleAnswerValue = () => payingDebts.join(' | ')

  const handleTracking = () => {
    const trackingAnswer = `question | ${
      PayingDebtsFlow.VIEW_NAME
    } | ${handleAnswerValue()} | ${financeSituation}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = () => {
    dispatch({ type: 'UPDATE_STEP', data: ResultsPageFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: ResultsPageFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    setDisableBtn(true)

    if (payingDebts && payingDebts.length > 0) {
      setInputValid(true)
      handleTracking()
      handleDispatch()
    }
    setDisableBtn(false)
    setInputValid(false)
  }

  return (
    <CheckBoxGroupPage
      btnDisable={disableBtn}
      buttonText="Next"
      buttons={buttonState}
      handleChange={handleCheckBoxChange}
      handleSubmit={handleSubmit}
      imgObj={imgObj}
      infoBoxData={payingDebtsData}
      isInputValid={isInputValid}
      stateValue={payingDebts}
      title={title}
    />
  )
}
