export const parkingTicketToolState = {
  ticket: {
    ticketPurchased: null, //Did you pay for parking?
    ticketNumber: '', // What is the notice number on the parking ticket?
    ticketReason: '', //What is the violation code or reason quoted on the ticket?
    ticketDate: '', //On what date was the ticket issued?
    ticketLocation: {
      postcode: null, //What is the location quoted on the ticket?
      addressLine1: null,
      addressLine2: null,
      townCity: null,
      county: null,
    },
    ticketDiscount: null, //Are you within the time limit to receive an early payment discount?
  },
  issue: {
    whoIssued: null, //Who issued the parking ticket?
    companyName: '', //What is the name of the local authority or company that issued the ticket?
    vehicleReg: '', // What is your vehicle registration number?
    purchaseMethod: '', //How did you pay for the parking?
    supportingEvidence: null, //Do you have evidence to support your appeal?
    description: '', //Please explain why you are contesting the ticket?
  },
}
