import type { FunctionComponent } from 'react'
import React, { useContext, useEffect, useState } from 'react'

import { ToolContext } from '../../state/appContext'
import { pageSubmission } from '../../utils/EngagingNetwork/pageSubmission'
import { generateRandomEmailAddress } from '../../utils/generateRandomEmailAddress'
import { getCleanValue } from '../../utils/getCleanValue'
import { scamSharerFeedbackSurveyPageData } from '../../utils/toolFeedbackSurveyPageData/scamSharerFeedbackSurveyPageData'
import { toolFeedbackSurveyPageData } from '../../utils/toolFeedbackSurveyPageData/toolFeedbackSurveyPageData'
import { SurveyModule } from '../SurveyModule'

export const ToolFeedbackPage: FunctionComponent<Props> = ({
  pageData,
  toolFeedbackAudienceFlowData,
  toolFeedbackFlowData,
  toolName,
}) => {
  const [radiosSelected, setRadiosSelected] = useState<
    { title: string; id?: string; groupId?: string; isValid: boolean }[]
  >([])
  const [textareaData, setTextareaData] = useState<
    { userInput: string; id: string; isValid: boolean; isOptional?: boolean }[]
  >([])
  const [errorCount, setErrorCount] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')
  const { title, standFirst, radioQuestions, textareaQuestions } = pageData
  const { dispatch } = useContext(ToolContext)

  useEffect(() => {
    dispatch({
      type: 'UPDATE_PROGRESS_VALUE',
      data: toolFeedbackFlowData.PROGRESS_VALUE,
    })
  }, [dispatch, toolFeedbackFlowData])

  const handleRadioChange = (id: string, radioGroupTitle: string, radioGroupId: string) => {
    const copyOfRadiosSelected = radiosSelected.map((selectedRadio) => ({ ...selectedRadio }))
    const objIndex = copyOfRadiosSelected.findIndex(
      (radioGroup) => radioGroup.title === radioGroupTitle
    )
    if (objIndex > -1) {
      copyOfRadiosSelected[objIndex].id = id
      copyOfRadiosSelected[objIndex].isValid = true
      copyOfRadiosSelected[objIndex].groupId = radioGroupId
    } else {
      copyOfRadiosSelected.push({
        title: radioGroupTitle,
        id,
        groupId: radioGroupId,
        isValid: true,
      })
    }
    setRadiosSelected([...copyOfRadiosSelected])
  }

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>, error: boolean) => {
    const { id } = e.target
    const value = getCleanValue(e.target.value)
    const copyOfTextareaData = textareaData
    const objIndex = copyOfTextareaData.findIndex((textarea) => textarea.id === id)
    if (objIndex > -1) {
      copyOfTextareaData[objIndex].userInput = value
      copyOfTextareaData[objIndex].isValid = !error
    } else {
      copyOfTextareaData.push({ userInput: value, id, isValid: !error })
    }
    setTextareaData([...copyOfTextareaData])
  }

  const handleValidation = async () => {
    let isValid = true
    const copyOfRadioQuestions = radioQuestions.map((radioQuestion: any) => ({ ...radioQuestion }))
    const copyOfRadiosSelected = radiosSelected.map((selectedRadio) => ({ ...selectedRadio }))
    const copyOfTextareaData = textareaData.map((data) => ({ ...data }))
    const copyOfTextareaQuestions = textareaQuestions.map((question: any) => ({ ...question }))

    const numSelectedRadios = copyOfRadioQuestions.filter((el: { id: string | undefined }) => {
      return copyOfRadiosSelected.some((f) => {
        return f.groupId === el.id && f.isValid
      })
    }).length

    // Check if all inputs are empty (check both arrays are empty)
    if (copyOfRadiosSelected.length === 0) {
      isValid = false
      radioQuestions.forEach((rQ: { title: string }) => {
        copyOfRadiosSelected.push({
          title: rQ.title,
          isValid: false,
        })
      })
    }

    if (copyOfTextareaData.length === 0) {
      textareaQuestions.forEach((textareaInput: { id: string; isOptional: boolean }) => {
        if (textareaInput.isOptional) {
          copyOfTextareaData.push({
            id: textareaInput.id,
            userInput: '',
            isValid: true,
          })
        } else {
          isValid = false
          copyOfTextareaData.push({
            id: textareaInput.id,
            userInput: '',
            isValid: false,
          })
        }
      })
    } else {
      copyOfTextareaData.forEach((textareaInput: { userInput: string }) => {
        if (textareaInput.userInput.length > 2500) {
          isValid = false
        }
      })
    }

    const numValidTextAreas = copyOfTextareaQuestions.filter((el: { id: string }) => {
      return copyOfTextareaData.some((f) => {
        return f.id === el.id && f.isValid
      })
    }).length

    if (numSelectedRadios !== radioQuestions.length) {
      isValid = false
      const missedRadios = copyOfRadioQuestions.filter((el: { id: string | undefined }) => {
        return copyOfRadiosSelected.some((f) => {
          return f.groupId !== el.id
        })
      })
      missedRadios.forEach((missedRadio: { title: string; id: string }) => {
        copyOfRadiosSelected.push({
          title: missedRadio.title,
          groupId: missedRadio.id,
          isValid: false,
        })
      })
    }

    if (numValidTextAreas !== textareaQuestions.length) {
      isValid = false
      const emptyTextAreas = copyOfTextareaQuestions.filter((el: { id: string }) => {
        return copyOfTextareaData.some((f) => {
          return f.id !== el.id
        })
      })
      /* istanbul ignore next */
      emptyTextAreas.forEach((emptyTextArea: { id: string }) => {
        copyOfTextareaData.push({
          id: emptyTextArea.id,
          userInput: '',
          isValid: false,
        })
      })
    }

    setRadiosSelected([...copyOfRadiosSelected])
    setTextareaData([...copyOfTextareaData])

    return isValid
  }

  const handleSubmit = async () => {
    const validationPassed = await handleValidation()
    setErrorCount(0)
    setErrorMessage('')

    if (validationPassed) {
      const answerObject: any = {}
      textareaData.forEach((textArea) => {
        const { id, userInput } = textArea
        answerObject[`${id}`] = userInput
      })
      radiosSelected.forEach((radio) => {
        const { groupId, id } = radio
        const formattedId = id?.substring(0, id?.lastIndexOf('-'))
        answerObject[`${groupId}`] = formattedId
      })

      dispatch({ type: 'UPDATE_TOOL_FEEDBACK_SURVEY', data: answerObject })

      const pageDataState = {
        toolFeedback: answerObject,
        email: generateRandomEmailAddress('@feedback.co.uk'),
        toolName,
      }
      const isScamSharerTool = toolName === 'ScamSharer'
      const structuredData = isScamSharerTool
        ? scamSharerFeedbackSurveyPageData(pageDataState)
        : toolFeedbackSurveyPageData(pageDataState)
      const ENResponse = isScamSharerTool
        ? await pageSubmission(structuredData, 97361)
        : await pageSubmission(structuredData, 112051)

      if (ENResponse && (ENResponse.ok || ENResponse.status === 'SUCCESS')) {
        dispatch({ type: 'UPDATE_TOOL_FEEDBACK_SURVEY_COMPLETE', data: true })
        dispatch({ type: 'UPDATE_STEP', data: toolFeedbackAudienceFlowData.STEP })
      } else {
        setErrorCount(errorCount + 1)
      }
    } else {
      setErrorCount(errorCount + 1)
      setErrorMessage('Please complete all fields to continue')
      const scrollTarget = document.getElementById('error-message')
      /* istanbul ignore next */
      scrollTarget && scrollTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return (
    <>
      <SurveyModule
        errorCount={errorCount}
        errorMessage={errorMessage}
        handleRadioChange={handleRadioChange}
        handleSubmit={handleSubmit}
        handleTextareaChange={handleTextareaChange}
        radioQuestions={radioQuestions}
        standFirst={standFirst}
        stateValue={radiosSelected}
        textareaQuestions={textareaQuestions}
        textareaState={textareaData}
        title={title}
      />
    </>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  pageData: any
  toolFeedbackAudienceFlowData: any
  toolFeedbackFlowData: any
  toolName: string
}
