import React from 'react'

import type { ToolPageQuery } from '../../../generated/frontend'
import { LocalFooter, ToolFramework } from '../../../shared/components'

export const useToolPageComponents = () => {
  return {
    getComponents: (data: { toolPage: ToolPageQuery['toolPage'] } | undefined) => {
      return {
        ToolFramework: ({
          toolName,
          optionsJson,
          showFeedback,
          toolUrl,
          title,
          questionText,
          standfirst,
          buttonText,
        }) => {
          const moneyHealthResults = data ? data.toolPage.moneyHealthCheckResults : null

          const CRFooter =
            !data || !data.toolPage || !data.toolPage.consumerRightsFooter ? null : (
              <LocalFooter data={data.toolPage.consumerRightsFooter} />
            )

          return (
            <ToolFramework
              toolName={toolName}
              optionsJson={optionsJson}
              showFeedback={showFeedback}
              toolUrl={toolUrl}
              title={title}
              questionText={questionText}
              standfirst={standfirst}
              buttonText={buttonText}
              moneyHealthResults={moneyHealthResults}
              CRFooter={CRFooter}
              isLoggedIn={data?.toolPage.isLoggedIn}
            />
          )
        },
      }
    },
  }
}
