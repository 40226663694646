import type { FunctionComponent } from 'react'
import React, { useContext, useState } from 'react'

import { RadioGroupPage } from '../../../../shared/pages/RadioGroupPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { AgeFlow, PostcodeFlow } from '../../pageFlowData'
import { buttonData } from './data'

export const Age: FunctionComponent = () => {
  const [isInputValid, setInputValid] = useState(true)
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    scam: { age },
  } = state
  const [radioSelected, setRadioSelected] = useState(age)

  const handleChange = (id: string) => {
    setRadioSelected(id)
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${AgeFlow.VIEW_NAME} | ${radioSelected}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = () => {
    dispatch({ type: 'UPDATE_COME_ACROSS_SCAM', data: radioSelected })
    dispatch({ type: 'UPDATE_STEP', data: PostcodeFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: PostcodeFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    if (radioSelected) {
      setInputValid(true)
      handleTracking()
      handleDispatch()
    }
    setInputValid(false)
  }

  return (
    <RadioGroupPage
      buttonText="Next"
      buttons={buttonData}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      imgObj={{
        alt: 'A child, Teenager, adult and a senior all standing next to each other',
        src: 'https://media.product.which.co.uk/prod/images/original/e07c22ba41b4-what-is-your-age.jpg',
      }}
      isInputValid={isInputValid}
      standFirst="These details help us understand who scammers are targeting so that we can help prevent scams"
      stateValue={radioSelected}
      title="What is your age?"
    />
  )
}
