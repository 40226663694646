export const ctaData = {
  link: {
    href: 'https://www.which.co.uk/consumer-rights/advice/i-want-to-return-my-goods-what-are-my-rights-ams3G2z9V7lW',
  },
  title: 'I want to return my goods, what are my rights?',
  description: 'We explain online returns, faulty goods rights and store returns policies',
  image: {
    src: 'https://s3-eu-west-1.amazonaws.com/prod-media-which/prod/images/original/gm-8b0968cf-b7d0-49e7-b5fd-ff38b3887a0d-limitedoptionsfaultygoods.png',
    alt: 'Two people looking at their phones facing away from each other.',
  },
  author: { name: 'Which?', jobTitle: 'Editorial team' },
}
