export const surveyModuleSpecificText = (toolID: string) => {
  switch (toolID) {
    case 'FlightDelayCancellationCompensation':
    case 'Section75':
    case 'FaultyGoodsTool':
      return 'claim'
    case 'MisSoldPackagedBankAccount':
    case 'HolidayComplaint':
      return 'complaint/claim'
    case 'ParkingTicket':
      return 'appeal'
    case 'DeliveryComplaint':
    case 'HomeImprovement':
    case 'BroadbandSpeed':
      return 'complaint'
    default:
      return 'claim'
  }
}

export const surveyModuleSpecificClaimText = (toolID: string) => {
  switch (toolID) {
    case 'ParkingTicket':
      return 'appealing your parking ticket'
    case 'DeliveryComplaint':
      return 'making a delivery complaint'
    case 'BroadbandSpeed':
      return 'making a broadband speed complaint'
    case 'HomeImprovement':
      return 'making a home improvement complaint'
    default:
      return 'claiming'
  }
}
