export const data = {
  buttons: [
    {
      label: 'Private parking company',
      id: 'private',
    },
    {
      label: 'Council or local authority',
      id: 'council',
    },
    {
      label: 'Police',
      id: 'police',
    },
  ],
  imgObj: {
    alt: 'A man standing next to a police officer',
    src: 'https://media.product.which.co.uk/prod/images/original/gm-4332ca4e-8895-4c69-9e75-101212a48624-parking-tool-step-1.jpg',
  },
  title: 'Who issued the parking ticket?',
}
