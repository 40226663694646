export const limitedOptionsData = {
  option1: {
    title: 'Please refer to the sellers returns policy to understand your rights.',
    standFirst: '',
    bodyCopy: [''],
    link: {
      href: 'https://www.which.co.uk/consumer-rights/advice/can-i-get-a-refund-on-a-digital-download-aRtaE3n02nm8',
      headline:
        'The Consumer Rights Act gives you rights to claim against the retailer if your digital download is faulty. Read our guide to make sure you’re not left with a dud.',
      text: 'Can I get a refund on a digital download?',
    },
  },
  option2: {
    title: 'Unfortunately, your options at this stage are limited.',
    standFirst: '',
    bodyCopy: [
      'Your rights against the retailer only last for up to six years in England, Wales and Northern Ireland, and five years in Scotland. This is written in The Limitation Act 1980.',
    ],
    link: {
      href: 'https://www.which.co.uk/consumer-rights/advice/i-want-to-return-my-goods-what-are-my-rights-ams3G2z9V7lW',
      headline: 'We explain online returns, faulty goods rights and store returns policies.',
      text: 'I want to return my goods, what are my rights?',
    },
  },
  option3: {
    title: 'Unfortunately, your options at this stage are limited.',
    standFirst: '',
    bodyCopy: [
      "You have fewer rights when you buy from a private seller.  Sellers aren't obliged to disclose any faults, but your item should have been accurately described when you bought it.  If you don't think it was described accurately, you should contact the seller directly.  And if this fails, you can try alternative dispute resolution or the small claims court.",
    ],
    link: {
      href: 'https://www.which.co.uk/consumer-rights/advice/what-are-my-shopping-rights-on-an-online-marketplace-aJYw74W0H5fI',
      headline: 'What are my shopping rights on an online marketplace?',
      text: 'Check out more guidance on your shopping rights on an online marketplace',
    },
  },
  option4: {
    title: 'You may be entitled to a refund.',
    standFirst: '',
    bodyCopy: [
      "You don't have an automatic right to return unwanted items bought in a shop, and most retailers impose time limits for returning non-faulty products. Check the terms and conditions of the store you bought from to see if you can get a refund.",
    ],
    link: {
      href: 'https://www.which.co.uk/consumer-rights/advice/i-want-to-return-my-goods-what-are-my-rights-ams3G2z9V7lW',
      headline: 'We explain online returns, faulty goods rights and store returns policies.',
      text: 'I want to return my goods, what are my rights?',
    },
  },
  option5: {
    title: 'You may be entitled to a refund.',
    standFirst: '',
    bodyCopy: [
      'Online retailers are only obliged to accept refunds made within 14 days of you receiving the goods.  However, many online retailers give customers a longer timeframe to return items.  Check the terms and conditions of the retailer you bought your goods from to see if you can still make a return.',
    ],
    link: {
      href: 'https://www.which.co.uk/consumer-rights/advice/i-want-to-return-something-bought-online-aqsD32m9r5P3',
      headline: 'When you buy goods online you have additional rights to return them.',
      text: 'I want to return something bought online',
    },
  },
}
