import type { Reducer } from 'react'

import { initialSliceReducer, toolFeedbackSliceReducer } from '../../../shared/state/reducers'
import type { Action, State } from '../../../shared/state/toolStateTypes'
import { combineReducers } from '../../../shared/utils/combineReducers'

export const deliverySliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_DELIVERY_PROBLEM':
      return {
        ...state,
        deliveryProblem: data,
      }
    case 'UPDATE_DELIVERY_PERMISSION':
      return {
        ...state,
        deliveryPermission: data,
      }
    case 'UPDATE_ITEM_ORDERED':
      return {
        ...state,
        itemOrdered: data,
      }
    case 'UPDATE_ORDER_NUMBER':
      return {
        ...state,
        orderNumber: data,
      }
    case 'UPDATE_DELIVERY_DATE_EXPLAINED':
      return {
        ...state,
        deliveryDateExplained: data,
      }
    case 'UPDATE_WHAT_HAPPENED':
      return {
        ...state,
        whatHappened: data,
      }
    case 'UPDATE_WHAT_WENT_WRONG':
      return {
        ...state,
        whatWentWrong: data,
      }
    default:
      return state
  }
}

export const retailerSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  if (type === 'UPDATE_RETAILER_NAME') {
    return {
      ...state,
      name: data,
    }
  } else {
    return state
  }
}

export const deliveryComplaintReducer = combineReducers({
  initial: initialSliceReducer,
  order: deliverySliceReducer,
  retailer: retailerSliceReducer,
  toolFeedback: toolFeedbackSliceReducer,
})
