import type { Reducer } from 'react'

import { initialSliceReducer, toolFeedbackSliceReducer } from '../../../shared/state/reducers'
import type { Action, State } from '../../../shared/state/toolStateTypes'
import { combineReducers } from '../../../shared/utils/combineReducers'

export const legalSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_LEGAL_TOPIC':
      return {
        ...state,
        legalTopic: data,
      }

    default:
      return state
  }
}

export const carsSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_CARS_TOPIC':
      return {
        ...state,
        carsTopic: data,
      }

    case 'UPDATE_CARS_QUERY':
      return {
        ...state,
        carsQuery: data,
      }

    default:
      return state
  }
}

export const employmentReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_EMPLOYMENT_TOPIC':
      return {
        ...state,
        employmentTopic: data,
      }

    default:
      return state
  }
}

export const otherReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_OTHER_TOPIC':
      return {
        ...state,
        otherTopic: data,
      }

    default:
      return state
  }
}

export const willsReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_WILLS_TOPIC':
      return {
        ...state,
        willsTopic: data,
      }
    case 'UPDATE_WILLS_QUERY':
      return {
        ...state,
        willsQuery: data,
      }
    case 'UPDATE_WILLS_DETAIL':
      return {
        ...state,
        willsDetail: data,
      }
    case 'UPDATE_WILLS_ESTATE':
      return {
        ...state,
        willsEstate: data,
      }

    default:
      return state
  }
}

export const buyingReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_BUYING_TOPIC':
      return {
        ...state,
        buyingTopic: data,
      }
    case 'UPDATE_BUYING_QUERY':
      return {
        ...state,
        buyingQuery: data,
      }
    default:
      return state
  }
}

export const holidayReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_HOLIDAY_QUERY':
      return {
        ...state,
        holidayQuery: data,
      }
    case 'UPDATE_HOLIDAY_TOPIC':
      return {
        ...state,
        holidayTopic: data,
      }
    case 'UPDATE_HOLIDAY_BOOKING':
      return {
        ...state,
        holidayBooking: data,
      }
    default:
      return state
  }
}

export const homeReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_HOME_TOPIC':
      return {
        ...state,
        homeTopic: data,
      }
    case 'UPDATE_HOME_QUERY':
      return {
        ...state,
        homeQuery: data,
      }
    case 'UPDATE_HOME_DETAIL':
      return {
        ...state,
        homeDetail: data,
      }
    case 'UPDATE_HOME_LOCATION':
      return {
        ...state,
        homeLocation: data,
      }

    default:
      return state
  }
}

export const triageReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_TRIAGE_TYPE':
      return {
        ...state,
        type: data,
      }
    case 'UPDATE_DESCRIBE_CASE':
      return {
        ...state,
        describeCase: data,
      }
    case 'UPDATE_CONTRACT_CHOICE':
      return {
        ...state,
        contractChoice: data,
      }
    case 'UPDATE_TRIAGE_SOLICITOR':
      return {
        ...state,
        solicitor: data,
      }
    case 'UPDATE_TRIAGE_NUMBER':
      return {
        ...state,
        number: data,
      }
    default:
      return state
  }
}

export const moneyReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_MONEY_TOPIC':
      return {
        ...state,
        moneyTopic: data,
      }
    case 'UPDATE_MONEY_QUERY':
      return {
        ...state,
        moneyQuery: data,
      }
    default:
      return state
  }
}

export const techReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_TECH_TOPIC':
      return {
        ...state,
        techTopic: data,
      }
    case 'UPDATE_TECH_QUERY':
      return {
        ...state,
        techQuery: data,
      }
    case 'UPDATE_TECH_DEVICE':
      return {
        ...state,
        techDevice: data,
      }
    case 'UPDATE_TECH_SYSTEM':
      return {
        ...state,
        techSystem: data,
      }
    default:
      return state
  }
}

export const medicalReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload
  if (type === 'UPDATE_MEDICAL_TOPIC') {
    return {
      ...state,
      medicalTopic: data,
    }
  }
  return state
}

export const entitlementReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload
  if (type === 'UPDATE_ENTITLEMENT') {
    return {
      ...state,
      check: true,
      ...data,
    }
  } else if (type === 'UPDATE_ENTITLEMENT_CHECK') {
    return {
      ...state,
      check: data,
    }
  }
  return state
}

export const bookAppointmentReducer = combineReducers({
  initial: initialSliceReducer,
  legal: legalSliceReducer,
  employment: employmentReducer,
  cars: carsSliceReducer,
  other: otherReducer,
  wills: willsReducer,
  buying: buyingReducer,
  holiday: holidayReducer,
  home: homeReducer,
  triage: triageReducer,
  toolFeedback: toolFeedbackSliceReducer,
  money: moneyReducer,
  tech: techReducer,
  medical: medicalReducer,
  entitlement: entitlementReducer,
})
