import React from 'react'

import { ToolFeedbackAudiencePage } from '../../../shared/pages/ToolFeedbackAudiencePage'
import { toolFeedbackAudienceData } from '../../../shared/pages/ToolFeedbackAudiencePage/data'
import { ToolFeedbackPage } from '../../../shared/pages/ToolFeedbackPage'
import { toolFeedbackData } from '../../../shared/pages/ToolFeedbackPage/data/broadbandSpeedData'
import { ToolFeedbackThankYouPage } from '../../../shared/pages/ToolFeedbackThankYouPage'
import { AchievedSpeed } from '../PageComponents/AchievedSpeed'
import { ContactDetails } from '../PageComponents/ContactDetails'
import { GuaranteedSpeed } from '../PageComponents/GuaranteedSpeed'
import { LimitedOptions } from '../PageComponents/LimitedOptions'
import { OtherProvider } from '../PageComponents/OtherProvider'
import { PackageName } from '../PageComponents/PackageName'
import { ProviderContact } from '../PageComponents/ProviderContact'
import { SubmitSuccessful } from '../PageComponents/SubmitSuccessful'
import { SupportingEvidence } from '../PageComponents/SupportingEvidence'
import { WhenPurchased } from '../PageComponents/WhenPurchased'
import { WhichProvider } from '../PageComponents/WhichProvider'
import {
  AchievedSpeedFlow,
  ContactDetailsFlow,
  GuaranteedSpeedFlow,
  LimitedOptionsFlow,
  OtherProviderFlow,
  PackageNameFlow,
  ProviderContactFlow,
  SubmitSuccessfulFlow,
  SupportingEvidenceFlow,
  ToolFeedbackAudienceFlow,
  ToolFeedbackFlow,
  ToolFeedbackThankYouFlow,
  WhenPurchasedFlow,
  WhichProviderFlow,
} from '../pageFlowData'

export const useRenderStep = (step: number) => {
  switch (step) {
    case ProviderContactFlow.STEP:
      return <ProviderContact />

    case WhichProviderFlow.STEP:
      return <WhichProvider />

    case OtherProviderFlow.STEP:
      return <OtherProvider />

    case PackageNameFlow.STEP:
      return <PackageName />

    case WhenPurchasedFlow.STEP:
      return <WhenPurchased />

    case GuaranteedSpeedFlow.STEP:
      return <GuaranteedSpeed />

    case AchievedSpeedFlow.STEP:
      return <AchievedSpeed />

    case SupportingEvidenceFlow.STEP:
      return <SupportingEvidence />

    case ContactDetailsFlow.STEP:
      return <ContactDetails />

    case LimitedOptionsFlow.STEP:
      return <LimitedOptions />

    case SubmitSuccessfulFlow.STEP:
      return <SubmitSuccessful />
    case ToolFeedbackFlow.STEP:
      return (
        <ToolFeedbackPage
          pageData={toolFeedbackData}
          toolFeedbackAudienceFlowData={ToolFeedbackAudienceFlow}
          toolFeedbackFlowData={ToolFeedbackFlow}
          toolName="BroadbandSpeed"
        />
      )
    case ToolFeedbackThankYouFlow.STEP:
      return <ToolFeedbackThankYouPage toolFeedbackThankYouFlowData={ToolFeedbackThankYouFlow} />
    case ToolFeedbackAudienceFlow.STEP:
      return (
        <ToolFeedbackAudiencePage
          pageData={toolFeedbackAudienceData}
          toolFeedbackAudienceFlowData={ToolFeedbackAudienceFlow}
          toolFeedbackThankYouFlowData={ToolFeedbackThankYouFlow}
          toolName="BroadbandSpeed"
        />
      )
    default:
      return <div>BroadbandSpeed Tool</div>
  }
}
