import type { UserPropsWithAddress } from './../../../shared/types/toolTypes'

export const pageData = (state: {
  emailData: { body: { text: string }[] }
  broadband: {
    packageName: string
    guaranteedSpeed: string
    achievedSpeed: string
  }
  issue: {
    whichProvider: string
    otherProvider: string
    whenPurchased: string
    supportingEvidence: string
  }
  optIns: { singleConsentOptIn: boolean }
  user: UserPropsWithAddress
}) => {
  const { address, emailAddress, firstName, lastName } = state.user
  const { packageName, guaranteedSpeed, achievedSpeed } = state.broadband
  const { whichProvider, otherProvider, whenPurchased, supportingEvidence } = state.issue
  const { singleConsentOptIn } = state.optIns
  const questions: any = {}
  const { body } = state.emailData
  let emailBodyBuilder = ''
  body.forEach((bodyData) => {
    /* istanbul ignore next */
    if (bodyData.text) {
      emailBodyBuilder = emailBodyBuilder + `${bodyData.text}<br />`
    }
  })

  questions['question.1387358'] = whichProvider // TF - Who is your broadband provider?
  questions['question.1387359'] = otherProvider // TF - Who is your broadband provider? (Other)
  questions['question.1387360'] = packageName // TF - What is the name of your broadband package?
  questions['question.1387366'] = whenPurchased // TF - When did your broadband contract begin?
  questions['question.1387367'] = guaranteedSpeed // TF - What was your minimum guaranteed line speed in megabits per second (Mbps)?
  questions['question.1387368'] = achievedSpeed // TF - What speed in megabits per second (Mbps) are you achieving in reality? (optional)
  questions['question.1387369'] = supportingEvidence // TF - Have you any further evidence to support your case? (optional)

  questions['question.1598463'] = singleConsentOptIn ? 'Y' : 'N' // single consent opt-in
  questions['question.1387428'] = emailBodyBuilder // TF - Broadband - Completed Email
  questions['question.1387473'] = 'Y' // TF - Broadband - Follow-up Opt-in

  // return supporter
  return {
    supporter: {
      Address: address?.addressLine1,
      'Address 2': address?.addressLine2,
      County: address?.county,
      Email: emailAddress,
      'First name': firstName,
      'Last name': lastName,
      Postcode: address?.postcode,
      'Town or City': address?.townCity,
      questions,
    },
  }
}
