import type { InfoBoxProps } from '../../../../../shared/types/toolTypes'

export const payingDebtsData = {
  buttons: [
    {
      label: 'Credit card(s)',
      id: 'credit-cards',
      disabled: false,
    },
    {
      label: 'Personal loan(s)',
      id: 'personal-loans',
      disabled: false,
    },
    {
      label: 'Buy now pay later loan(s)',
      id: 'buy-now-pay-later',
      disabled: false,
    },
    {
      label: "I'm not paying off any credit cards or loans",
      id: 'no-credit-cards-or-loans',
      disabled: false,
    },
  ],
  title: 'Are you paying off any credit cards, personal loans or buy now pay later loans?',
  imgObj: {
    alt: 'A person holding up a bank in one hand and a piggy bank in another',
    src: 'https://media.product.which.co.uk/prod/images/original/e7472f0db7b7-debt.jpg',
  },
  infoBox: {
    heading: 'What is Buy Now Pay Later?',
    borderColour: 'blue',
  } as InfoBoxProps,
  infoBoxParagraphs: [
    {
      text: 'Buy now, pay later (BNPL) schemes (such as Klarna and Laybuy) offer a form of short-term credit. Typically they allow you to purchase goods, but pay for them either by instalments or at a later date, interest-free.',
    },
  ],
}
