import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { FormEntryItem } from '../../../../shared/components/FormEntryItem'
import { ImageWithQuestion } from '../../../../shared/components/ImageWithQuestion'
import { NextButton } from '../../../../shared/components/NextButton'
import { ToolContext } from '../../../../shared/state/appContext'
import globalStyles from '../../../../shared/styles/GlobalStyles.module.scss'
import type { SingleInputState } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { WhatBrandFlow, WhatsWrongFlow } from '../../pageFlowData'
import { data } from './data'

export const WhatBrand: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    product: { brand },
  } = state

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })
  const WhatBrandInput = useRef<HTMLInputElement>(null)

  const handleTracking = (brandValue) => {
    const trackingAnswer = `question | ${WhatBrandFlow.VIEW_NAME} | ${brandValue}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (brandValue) => {
    dispatch({ type: 'UPDATE_PRODUCT_BRAND', data: brandValue })
    dispatch({ type: 'UPDATE_STEP', data: WhatsWrongFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: WhatsWrongFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const brandValue: string = cleanText(WhatBrandInput.current!.value)
    const brandHasError = !isNotEmpty(brandValue)

    if (!brandHasError) {
      handleTracking(brandValue)
      handleDispatch(brandValue)
      return
    }

    setInputData({
      renderError: brandHasError,
      value: brandValue,
    })
  }

  const { renderError, value: errorValue } = inputData
  const { imgObj, title, placeholderText } = data

  return (
    <>
      <ImageWithQuestion image={imgObj} title={title} />
      <FormEntryItem
        displayText=""
        enterKeyHandler={handleSubmit}
        fieldData={{
          renderError: renderError,
          value: errorValue,
        }}
        id="what-brand"
        isFocused
        placeholderText={placeholderText}
        ref={WhatBrandInput}
        srLabel={title}
        stateValue={brand}
        type="text"
      />
      <div className={globalStyles.buttonWrapper}>
        <NextButton buttonText="Next" onClick={handleSubmit} />
      </div>
    </>
  )
}
