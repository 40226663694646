import type { FunctionComponent } from 'react'
import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import { GridItem } from '@which/seatbelt'

import type { IFrameEmbedProps } from '../../types/toolTypes'
import { IFrameResizer } from '../../utils/IFrameResizer'

export const IframeEmbed: FunctionComponent<IFrameEmbedProps> = ({ content }) => {
  const iframeRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const fragment = document.createRange().createContextualFragment(content)
    iframeRef?.current?.append(fragment)
  }, [content])

  const resizeIframes = useCallback(() => {
    new IFrameResizer('iframe.js-iframe-resizer')
  }, [])

  useLayoutEffect(() => {
    window.addEventListener('load', resizeIframes)

    return () => {
      window.removeEventListener('load', resizeIframes)
    }
  }, [resizeIframes])

  /**
   * Trigger resizeIframes a second time just in case to address flaky resizing
   * due to rendering race conditions and in particular on slower connections
   */
  useEffect(() => {
    setTimeout(resizeIframes, 5000)
  }, [resizeIframes])

  return (
    <GridItem>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </GridItem>
  )
}
