export const dealerOfferedSolutionData = {
  buttons: [
    {
      label: 'Yes',
      id: 'yes',
    },
    {
      label: 'No',
      id: 'no',
    },
  ],
  title: 'Has the dealer offered to repair or replace the car?',
  imgObj: {
    alt: 'Couple looking at a piece of paper with a phone in hand',
    src: 'https://media.product.which.co.uk/prod/images/original/8ffdc4d0425c-step55.jpg',
  },
}
