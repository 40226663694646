export const deliveryProblemsData = {
  buttons: [
    {
      label: 'My delivery is late and I want to complain',
      id: 'lateDelivery',
    },
    {
      label: 'My goods were delivered damaged',
      id: 'damagedGoods',
    },
    {
      label: 'My delivery was left somewhere and is now missing',
      id: 'missingDelivery',
    },
  ],
  imgObj: {
    alt: 'Person on a moped',
    src: 'https://media.product.which.co.uk/prod/images/original/gm-e9b737cb-5c04-4bc8-875a-34faae675016-step1.jpg',
  },
  title: "What's the problem with your delivery?",
}
