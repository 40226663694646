export const expensesDescriptionData = {
  title: 'Describe the out-of-pocket expenses you are claiming for',
  imgObj: {
    src: 'https://media.product.which.co.uk/prod/images/original/gm-9717e00b-f0e6-4b9b-bc98-de081d4c3f2d-holiday-complaint-10.jpg',
    alt: 'Two people, one holding a clock the other holding a giant coin to represent money',
  },
  descriptiveText: [
    "Make sure to say why you think the expenses you're claiming for are reasonable and unavoidable.",
  ],
  placeholderText:
    'Eg. the food provided in the package deal was inedible, so I had to buy food elsewhere.',
}
