import type { FunctionComponent } from 'react'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import { Grid, GridItem } from '@which/seatbelt'

import { ToolWrapper } from '../../shared'
import { PageFade } from '../../shared/components/PageFade'
import { ProgressBar } from '../../shared/components/ProgressBar'
import { ToolContext } from '../../shared/state/appContext'
import styles from '../../shared/styles/GlobalStyles.module.scss'
import type { S75AndChargeBackToolProps } from '../../shared/types/toolTypes'
import { checkSessionStorageExists } from '../../shared/utils/checkSessionStorageExists'
import { formatToolName } from '../../shared/utils/formatToolName'
import { getFilteredPageDataByStep } from '../../shared/utils/getFilteredPageDataByStep'
import { getSessionStorageItem } from '../../shared/utils/getSessionStorageItem'
import { handleScroll } from '../../shared/utils/handleScroll'
import { removeSessionStorage } from '../../shared/utils/removeSessionStorage'
import { setUserJourney } from '../../shared/utils/setUserJourney'
import { storeToolState } from '../../shared/utils/storeToolState'
import {
  generalDataLayer,
  initaliseDataLayer,
  pageViewDataLayer,
} from '../../shared/utils/tracking'
import { useRenderStep } from './hooks/useRenderStep'
import { AllFlows, EntryPointFlow, PurchaseMethodFlow, SubmitSuccessfulFlow } from './pageFlowData'

export const S75AndChargeback: FunctionComponent<S75AndChargeBackToolProps> = ({
  optionsJson,
  toolName,
  CRFooter,
  showFeedback,
  title,
  questionText,
  standfirst,
  buttonText,
  toolUrl,
}) => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: {
      entryToolName,
      initialised,
      journeyFlow: { milestoneHit },
      progressValue,
      step,
      userJourney,
    },
    product,
  } = state
  const [readyToRender, setReadyToRender] = useState(false)
  const [isForward, setIsForward] = useState(true)

  if (!initialised) {
    removeSessionStorage({ sessionStorageName: `${toolName}Progress` })
  }

  let type: string | null | undefined = undefined
  const location = useLocation()
  const useQuery = () => {
    return new URLSearchParams(location?.search)
  }
  const query = useQuery()
  type = query?.get('type') || product?.type

  useEffect(() => {
    if (!initialised) {
      dispatch({ type: 'UPDATE_SHOW_FEEDBACK', data: showFeedback })
    }
  }, [dispatch, initialised, showFeedback])

  useEffect(() => {
    handleScroll()
  }, [step])

  useEffect(() => {
    const firePageView = () => {
      const pageData = getFilteredPageDataByStep(step, AllFlows, [EntryPointFlow])

      const { milestone, viewName } = pageData
      if (initialised === true) {
        if (type || step === 0) {
          pageViewDataLayer(viewName)
        }

        const milestoneArray = milestoneHit

        if (!milestoneArray.includes(viewName) && milestone !== '') {
          milestoneArray.push(viewName)
          generalDataLayer(formatToolName(toolName), milestone)
          dispatch({ type: 'UPDATE_MILESTONE', data: milestoneArray })
          return
        }
      }
    }

    firePageView()
  }, [dispatch, initialised, milestoneHit, step, toolName, type])

  const RenderStep = ({ currentStep }: { currentStep: number }) => {
    useEffect(() => {
      if ((type === null || !type) && currentStep !== 0) {
        dispatch({ type: 'UPDATE_STEP', data: 0 })
        // eslint-disable-next-line react-hooks/exhaustive-deps
        currentStep = 0
      }
    }, [dispatch])

    useEffect(() => {
      if (type !== null && step === PurchaseMethodFlow.STEP) {
        dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: PurchaseMethodFlow.PROGRESS_VALUE })
      }
    }, [])

    return useRenderStep({
      step: currentStep,
      optionsJson,
      title,
      questionText,
      standfirst,
      buttonText,
      toolUrl,
      CRFooter,
    })
  }

  useEffect(() => {
    dispatch({ type: 'UPDATE_INITIALISED', data: true })
  }, [dispatch])

  useEffect(() => {
    if (!entryToolName) {
      dispatch({ type: 'UPDATE_ENTRY_TOOL_NAME', data: toolName })
    }
  }, [dispatch, entryToolName, toolName])

  useEffect(() => {
    if (type) {
      dispatch({
        type: 'UPDATE_PRODUCT_TYPE',
        data: type,
      })
    }
  }, [dispatch, type])

  useEffect(() => {
    if (!((type === null || !type) && step !== 0)) {
      setUserJourney(userJourney, step, dispatch)
    }
  }, [userJourney, step, dispatch, type])

  useEffect(() => {
    if (type !== undefined) {
      setReadyToRender(true)
    }
  }, [type])

  useEffect(() => {
    if (entryToolName !== '') {
      if (checkSessionStorageExists(`${toolName}Progress`)) {
        let sessionProgress = getSessionStorageItem(`${toolName}Progress`)
        let sessionProgressLength = sessionProgress.length - 1
        if (sessionProgressLength > 2 && step === 1) {
          sessionProgress = [0]
          sessionProgressLength = sessionProgress.length - 1
          storeToolState(`${toolName}Progress`, sessionProgress)
        }
        if (sessionProgress[sessionProgressLength] !== progressValue) {
          sessionProgress.push(progressValue)
          storeToolState(`${toolName}Progress`, sessionProgress)
        }
      } else {
        storeToolState(`${entryToolName}Progress`, [progressValue])
      }
    }
  })

  return (
    <>
      <ToolWrapper>
        <Helmet>
          <script>{initaliseDataLayer(formatToolName(toolName))}</script>
          <title>Section 75 &amp; Chargeback - free Which? tool</title>
          <link
            href="https://cdn.jsdelivr.net/npm/@duetds/date-picker@1.4.0/dist/duet/themes/default.css"
            rel="stylesheet"
          />
          <meta content="Section 75 &amp; Chargeback - free Which? tool" name="title" />
          <meta content="summary" name="twitter:card" />
          <meta content="Find out if you are able to file a chargeback claim" name="description" />
          <meta content="website" property="og:type" />
          <meta
            content="https://www.which.co.uk/tool/section-75-and-chargeback"
            property="og:url"
          />
          <meta content="Section 75 &amp; Chargeback - free Which? tool" property="og:title" />
          <meta
            content="Find out if you are able to file a chargeback claim"
            property="og:description"
          />
          <meta
            content="https://www.which.co.uk/tool/section-75-and-chargeback"
            property="twitter:url"
          />
          <meta content="Section 75 &amp; Chargeback - free Which? tool" property="twitter:title" />
          <meta
            content="Find out if you are able to file a chargeback claim"
            property="twitter:description"
          />
        </Helmet>
        {readyToRender && (
          <>
            <ProgressBar
              allFlows={AllFlows}
              endFlowStep={SubmitSuccessfulFlow.STEP}
              setIsForward={setIsForward}
              stateWidth={progressValue}
              toolName={toolName}
            />
            <PageFade
              excludedSteps={[0, 1]}
              isForward={isForward}
              setIsForward={setIsForward}
              stateProgress={progressValue}
              step={step}
              toolName={toolName}
            >
              <RenderStep currentStep={step} />
            </PageFade>
          </>
        )}
      </ToolWrapper>
      {step === SubmitSuccessfulFlow.STEP && (
        <Grid className="w-page-wrapper">
          <GridItem>
            <section className={styles.submitSuccessfulFooter}>{CRFooter}</section>
          </GridItem>
        </Grid>
      )}
    </>
  )
}
