export const whatHappenedWithSocialData = {
  buttons: [
    {
      label: 'I clicked on a link which took me to a dodgy website',
      id: 'website',
    },
    {
      label: 'Someone sent me a message',
      id: 'message',
    },
  ],
  title: 'What happened?',
}
