import { ctaDataOption1, ctaDataOption2, limitedOptionsData } from './data'

export const chooseDataSet = (price: number, repeatPerformance: boolean | null) => {
  if (price > 30000 && repeatPerformance === null) {
    return { articleData: limitedOptionsData.option1, ctaData: ctaDataOption1 }
  }

  if (price <= 30000 && repeatPerformance === false) {
    return { articleData: limitedOptionsData.option2, ctaData: ctaDataOption2 }
  }

  return { articleData: limitedOptionsData.option1, ctaData: ctaDataOption1 }
}
