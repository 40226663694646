export const howMuchTimeSavedData = {
  radioQuestions: [
    {
      title: 'Could you estimate roughly how much time we might have saved you?',
      buttons: [
        {
          label: 'No more than half an hour',
          id: 'no-more-than-half-hour',
          isFocused: false,
        },
        {
          label: 'Between half an hour to an hour',
          id: 'between-half-an-hour-to-an-hour',
          isFocused: false,
        },
        { label: 'One to two hours', id: 'one-to-two-hours', isFocused: false },
        {
          label: 'More than two hours',
          id: 'more-than-two-hours',
          isFocused: false,
        },
        {
          label: 'Dont know / not sure',
          id: 'not-sure-how-much-time',
          isFocused: false,
        },
      ],
      isSubmitValid: true,
      id: 'how-much-time-saved',
    },
    {
      title:
        'Do you feel that using the Which? (insert name) tool reduced any worry or stress you may have been experiencing in making a claim?',
      buttons: [
        {
          label: 'Yes, a little',
          id: 'yes-a-little',
          isFocused: false,
        },
        {
          label: 'Yes, some',
          id: 'yes-some',
          isFocused: false,
        },
        { label: 'Yes, a lot', id: 'yes-a-lot', isFocused: false },
        {
          label: 'No, it made no difference',
          id: 'no-it-made-no-difference',
          isFocused: false,
        },
        {
          label: "No, not applicable - I wasn't experiencing worry / stress",
          id: 'no-not-applicable',
          isFocused: false,
        },
        {
          label: "Don't know  / not sure",
          id: 'not-sure-reduced-stress',
          isFocused: false,
        },
      ],
      isSubmitValid: true,
      id: 'reduced-stress',
    },
  ],
  textAreaQuestion: [
    {
      isFocused: false,
      isSubmitValid: true,
      id: 'how-tool-helped',
      title:
        "You can use this space if you'd like to tell us more about your experience of claiming and how our tool helped or did not help you",
      isInputValid: true,
      defaultValue: '',
      isOptional: true,
    },
  ],
}
