import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import globalStyles from '../../../../shared/styles/GlobalStyles.module.scss'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { GenderFlow, GetInTouchFlow } from '../../pageFlowData'
import { genderData } from './data'

export const Gender: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    victim: { gender },
  } = state

  const handleTracking = (id: string) => {
    const trackingAnswer = `question | ${GenderFlow.VIEW_NAME} | ${id}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_GENDER', data: id })
    dispatch({ type: 'UPDATE_STEP', data: GetInTouchFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: GetInTouchFlow.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string) => {
    handleTracking(id)
    handleDispatch(id)
  }

  const { buttons, title } = genderData

  return (
    <div className={globalStyles.fullWidthButton}>
      <PillButtonPage
        buttons={buttons}
        defaultValue={gender ? gender : ''}
        handleSubmit={handleSubmit}
        imgObj={{
          alt: 'Two people talking while one of them holds a hot drink',
          src: 'https://media.product.which.co.uk/prod/images/original/ad796b033253-what-is-your-gender.jpg',
        }}
        title={title}
      />
    </div>
  )
}
