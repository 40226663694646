export const limitedOptionsData = {
  option1: {
    title: "Sorry, it looks like we can't help you",
    standFirst: '',
    bodyCopy: [
      "We're sorry but for compliance reasons we can't assist you if you have already instructed a solicitor to give you advice in relation to this query.",
      "If you have had a free initial consultation but not signed up to any formal Terms of Business, or have sought advice from a charity or free service such as Citizens Advice Bureau, we may still be able to assist - click the 'Back' button above and select 'No' to the previous question.",
    ],
  },
  option2: {
    title:
      "We are sorry but we are unable to assist you with this as it doesn't fall within our areas of expertise.",
    standFirst: 'You might be able to get assistance from one of the following organisations:',
    bodyCopy: [
      '- Your insurance company, if you have legal expenses cover',
      "- Citizen's Advice Bureaux",
      "- The Law Society's Find a Solicitor website",
    ],
  },
  option3: {
    title:
      'We might be able to assist you with but we need some more information from you before we can confirm',
    standFirst: '',
    bodyCopy: [
      'Please call us on <a style="font-size: 20px;" class="sb-link-primary" href="tel:+02921682969"><span class="sb-link-animation-wrapper">029 2168 2969</span></a> to discuss the matter with us.',
    ],
  },
  option4: {
    title:
      'For queries relating to financial services matters, including loans, investments and insurance, we always suggest an initial consultation with one of our Money Helpline Experts who are best placed to assist.',
    standFirst: '',
    bodyCopy: [
      'Please click <a class="sb-link-primary" href="/tool/book-appointment?type=money"><span class="sb-link-animation-wrapper">here</span></a> to book and arrange an appointment with one of our Experts.',
      'Or call us on <a class="sb-link-primary" href="tel:+02922670000"><span class="sb-link-animation-wrapper">029 2267 0000</span></a> to discuss the matter with us. Opening times are 8.30am to 6pm on Monday to Friday and from 9am to 1pm on Saturday',
    ],
  },
  option5: {
    title:
      'We are sorry but we are unable to assist you with a leasehold queries of this type as it doesn`t fall within our areas of expertise.',
    standFirst: 'You might be able to get assistance from one of the following organisations:',
    bodyCopy: [
      '- Your insurance company, if you have legal expenses cover',
      "- Citizen's Advice Bureaux",
      "- The Law Society's Find a Solicitor website",
      '- The Leasehold Advisory Service',
    ],
  },
  option6: {
    title:
      "We are sorry but we are unable to assist you with this type of query as it doesn't fall within our areas of expertise.",
    standFirst: '',
    bodyCopy: [],
  },
  option7: {
    title:
      "We are sorry but we can only discuss your own will or estate, not somebody else's, even with their permission.",
    standFirst:
      'If the person whose will or estate you want to discuss is still alive, they can contact us and if they have a membership we can assist them directly.',
    bodyCopy: [],
  },
  option8: {
    title: "Your current membership packages doesn't include access to our REPLACESERVICE",
    standFirst: '',
    bodyCopy: [
      'Our experts do offer support in this area, but your current membership package doesn\'t give you access to this service. If you\'d like to upgrade your membership to include this level of support, please speak to our member services team, or <a class="sb-link-primary" href="REPLACELINK"><span class="sb-link-animation-wrapper">click here</span></a> to take out a full REPLACETYPE subscription which gets you access to our REPLACEEXPERT in this and many other areas',
      '<span style="font-weight: bold;">Call Member Services on <a class="sb-link-primary" href="tel:+02922670000"><span class="sb-link-animation-wrapper">029 2267 0000</span></a> - opening times are 8.30am to 6pm on Monday to Friday and from 9am to 1pm on Saturday</span>',
    ],
  },
}
