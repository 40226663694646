export const vehicleDetailsURLs = {
  prod: 'https://7hj996rsx8.execute-api.eu-west-1.amazonaws.com/prod/car-registration-api',
  preprod: 'https://n7yhjq9c4j.execute-api.eu-west-1.amazonaws.com/preprod/car-registration-api',
  qa: 'https://bnbl8cpbv8.execute-api.eu-west-1.amazonaws.com/qa/car-registration-api',
  test: 'https://wdxfhdmtij.execute-api.eu-west-1.amazonaws.com/test/car-registration-api',
  dev: 'https://edsg0nq6lj.execute-api.eu-west-1.amazonaws.com/dev/car-registration-api',
  localhost: 'http://localhost:3500/car-registration-api',
}

export const VEHICLE_DETAILS_API = (): string => {
  if (typeof window === 'undefined') {
    return vehicleDetailsURLs.prod
  } else {
    switch (window.location?.hostname) {
      case 'localhost':
        return vehicleDetailsURLs.localhost
      case 'dev-deliver.components.product.which.co.uk':
      case 'dev.internal.which.co.uk':
        return vehicleDetailsURLs.dev

      case 'test-deliver.components.product.which.co.uk':
      case 'test.internal.which.co.uk':
        return vehicleDetailsURLs.test

      case 'qa-deliver.components.product.which.co.uk':
        return vehicleDetailsURLs.qa

      case 'preprod-deliver.components.product.which.co.uk':
      case 'preprod.internal.which.co.uk':
        return vehicleDetailsURLs.preprod

      default:
        return vehicleDetailsURLs.prod
    }
  }
}
