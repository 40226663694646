import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { TextAreaPage } from '../../../../shared/pages/TextAreaPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryTextAreaRef } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { validateCharCount } from '../../../../shared/utils/validateCharCount'
import { MoreAboutScammerFlow, WhatHappenedFlow } from '../../pageFlowData'
import { data } from './data'

export const MoreAboutScammer: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    scam: { moreAboutScammer },
  } = state

  const [moreAboutScammerTextAreaValid, setMoreAboutScammerTextAreaValid] = useState<boolean>(true)
  const MoreAboutScammerTextAreaRef = useRef<FormEntryTextAreaRef>(null)

  const handleMoreAboutScammerChange = () => {
    const value = getCleanValue(MoreAboutScammerTextAreaRef.current?.formEntryTextarea?.value)
    dispatch({ type: 'UPDATE_MORE_ABOUT_SCAMMER', data: value })

    const isValid: boolean = validateCharCount(value.length, 2500)
    const fieldNotEmpty: boolean = isNotEmpty(value)
    if (fieldNotEmpty && isValid) {
      setMoreAboutScammerTextAreaValid(true)
    }
  }

  const handleDispatch = () => {
    dispatch({ type: 'UPDATE_STEP', data: WhatHappenedFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: WhatHappenedFlow.PROGRESS_VALUE })
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${MoreAboutScammerFlow.VIEW_NAME} | ${moreAboutScammer}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleMoreAboutScammerSubmit = () => {
    const value: string = getCleanValue(
      MoreAboutScammerTextAreaRef.current?.formEntryTextarea?.value
    )
    const isValid: boolean = validateCharCount(value.length, 2500)
    const fieldNotEmpty: boolean = isNotEmpty(value)

    if (fieldNotEmpty && !isValid) {
      setMoreAboutScammerTextAreaValid(false)
      return
    }

    setMoreAboutScammerTextAreaValid(true)
    handleTracking()
    handleDispatch()
  }

  const { imgObj, title } = data

  return (
    <div data-testid="more-about-scammer">
      <TextAreaPage
        buttonText="Next"
        defaultValue={moreAboutScammer}
        handleChange={handleMoreAboutScammerChange}
        handleSubmit={handleMoreAboutScammerSubmit}
        imgObj={imgObj}
        isFocused
        isInputValid={moreAboutScammerTextAreaValid}
        maxChars={2500}
        optional
        ref={MoreAboutScammerTextAreaRef}
        title={title}
      />
    </div>
  )
}
