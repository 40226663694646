import type { FunctionComponent } from 'react'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { useToolPageQuery } from '../../generated/frontend'
import { PageTemplate } from '../../shared'
import { useToolPageComponents } from './hooks/useToolPageComponents'

const ToolFrameworkPage: FunctionComponent = () => {
  const location = useLocation()

  const pageQueryObj = {
    variables: { toolSlug: 'used-car', taxonomyText: '', isMoneyHealthCheck: false },
  }

  if (location.pathname === '/tool/my-money-health-check') {
    pageQueryObj.variables.taxonomyText = 'My Money Health Check Results'
    pageQueryObj.variables.isMoneyHealthCheck = true
  }

  const { data } = useToolPageQuery(pageQueryObj)

  const { getComponents } = useToolPageComponents()

  return <PageTemplate metaTags={[]} components={getComponents(data)} />
}

export default ToolFrameworkPage
