import type { FunctionComponent } from 'react'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

import { ToolWrapper } from '../../../../shared'
import { ImageWithQuestion } from '../../../../shared/components/ImageWithQuestion'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { initaliseDataLayer, pageViewDataLayer } from '../../../../shared/utils/tracking'
import { RebookAppointmentFlow } from '../../pageFlowData'
import { rebookAppointmentData } from './data'
import { getIFrameData } from './getIFrameData'
import styles from './RebookAppointment.module.scss'

type RebookAppointmentType = {
  toolName: string
}
export const RebookAppointment: FunctionComponent<RebookAppointmentType> = ({ toolName }) => {
  useEffect(() => {
    pageViewDataLayer(RebookAppointmentFlow.VIEW_NAME)
  }, [])

  const location = useLocation()
  const useQuery = () => {
    return new URLSearchParams(location?.search)
  }

  const query = useQuery()
  const appointmentId = query?.get('appointmentId') || ''
  const clientId = query?.get('clientId') || ''

  const iFrameResponse = getIFrameData(appointmentId, clientId)
  const { imgObj, title, standFirst } = rebookAppointmentData

  return (
    <ToolWrapper>
      <Helmet>
        <meta content="noindex" name="robots" />
        <script>{initaliseDataLayer(formatToolName(toolName))}</script>
        <title>Reschedule Appointment</title>
        <meta content={'Reschedule your appointmentOnline appointment booking'} name="title" />
        <meta content="summary" name="twitter:card" />
        <meta content={'Reschedule your online appointment booking'} name="description" />
        <meta content="website" property="og:type" />
        <meta content={'https://www.which.co.uk/tool/change-appointment'} property="og:url" />
        <meta content={'Reschedule your appointment'} property="og:title" />
        <meta content={'Reschedule your online appointment booking'} property="og:description" />
        <meta content={'https://www.which.co.uk/tool/change-appointment'} property="twitter:url" />
        <meta content={'Reschedule your appointment'} property="twitter:title" />
        <meta
          content={'Reschedule your online appointment booking'}
          property="twitter:description"
        />
      </Helmet>
      <ImageWithQuestion image={imgObj} title={title} />
      <p className={styles.rebookAppointmentStandFirst} data-testid="typography-body">
        {standFirst}
      </p>
      <iframe
        allow="geolocation"
        className={styles.rebookAppointmentFrame}
        referrerPolicy="no-referrer"
        src={iFrameResponse}
        title="Appointment Choice"
      />
    </ToolWrapper>
  )
}
