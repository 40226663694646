import type { FunctionComponent } from 'react'
import React, { useContext, useState } from 'react'

import { DropdownPage } from '../../../../shared/pages/DropdownPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { ReasonGivenFlow, ScheduledFlightTimesFlow } from '../../pageFlowData'
import { data } from './data'

export const ScheduledFlightTimes: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    flight: { scheduledTimes },
  } = state

  const departureTimeState = scheduledTimes && scheduledTimes.departureTime
  const arrivalTimeState = scheduledTimes && scheduledTimes.arrivalTime

  const [departureTime, setDepartureTime] = useState(departureTimeState)
  const [arrivalTime, setArrivalTime] = useState(arrivalTimeState)
  const [isInputValid, setIsInputValid] = useState<boolean>(true)

  const handleDispatch = () => {
    dispatch({
      type: 'UPDATE_SCHEDULED_FLIGHT_TIMES',
      data: { departureTime: departureTime, arrivalTime: arrivalTime },
    })
    dispatch({ type: 'UPDATE_STEP', data: ReasonGivenFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: ReasonGivenFlow.PROGRESS_VALUE })
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${ScheduledFlightTimesFlow.VIEW_NAME} | Departed: ${departureTime.hours}:${departureTime.minutes} - Arrived: ${arrivalTime.hours}:${arrivalTime.minutes}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const checkForError = () => {
    if (
      !departureTime.hours ||
      departureTime.hours === 'HH:' ||
      !arrivalTime.hours ||
      arrivalTime.hours === 'HH:' ||
      !departureTime.minutes ||
      departureTime.minutes === 'MM:' ||
      !arrivalTime.minutes ||
      arrivalTime.minutes === 'MM:'
    ) {
      setIsInputValid(false)
      return true
    }
    setIsInputValid(true)
    return false
  }

  const handleSubmit = () => {
    if (checkForError()) {
      return
    }

    handleDispatch()
    handleTracking()
  }

  const createOptions = (loopNumber: number, type: string) => {
    const createdOptions: any = []
    for (let i = 0; i <= loopNumber; i++) {
      const number = ('0' + i).slice(-2)
      createdOptions.push({ label: number, value: `${type}${number}` })
    }
    return createdOptions
  }

  const hours = createOptions(23, 'HH:')
  const minutes = createOptions(59, 'MM:')

  const handleDepartureChange = (value: string) => {
    if (value.includes('HH:')) {
      setDepartureTime({ hours: value, minutes: departureTime.minutes })
    }
    if (value.includes('MM:')) {
      setDepartureTime({ hours: departureTime.hours, minutes: value })
    }
  }

  const handleReturnChange = (value: string) => {
    if (value.includes('HH:')) {
      setArrivalTime({ hours: value, minutes: arrivalTime.minutes })
    }
    if (value.includes('MM:')) {
      setArrivalTime({ hours: arrivalTime.hours, minutes: value })
    }
  }

  const firstDropdownDataSet = {
    firstDropdown: {
      options: hours,
      prompt: { label: 'HH', value: 'HH:' },
      defaultValue: `${departureTime.hours}`,
      error: isInputValid ? false : !departureTime.hours || departureTime.hours === 'HH:',
    },
    secondDropdown: {
      options: minutes,
      prompt: { label: 'MM', value: 'MM:' },
      defaultValue: `${departureTime.minutes}`,
      error: isInputValid ? false : !departureTime.minutes || departureTime.minutes === 'MM:',
    },
    handleChange: handleDepartureChange,
    stateValue: departureTimeState,
    label: 'Your departure time',
  }

  const secondDropdownDataSet = {
    firstDropdown: {
      options: hours,
      prompt: { label: 'HH', value: 'HH:' },
      defaultValue: `${arrivalTime.hours}`,
      error: isInputValid ? false : !arrivalTime.hours || arrivalTime.hours === 'HH:',
    },
    secondDropdown: {
      options: minutes,
      prompt: { label: 'MM', value: 'MM:' },
      defaultValue: `${arrivalTime.minutes}`,
      error: isInputValid ? false : !arrivalTime.minutes || arrivalTime.minutes === 'MM:',
    },
    handleChange: handleReturnChange,
    stateValue: arrivalTimeState,
    label: 'Your arrival time',
  }

  const { imgObj, title } = data

  return (
    <>
      <div data-testid="scheduled-flight-times">
        <DropdownPage
          buttonText="Next"
          firstDropdownDataSet={firstDropdownDataSet}
          handleSubmit={handleSubmit}
          imgObj={imgObj}
          isInputValid={isInputValid}
          secondDropdownDataSet={secondDropdownDataSet}
          title={title}
        />
      </div>
    </>
  )
}
