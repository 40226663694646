import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { FormEntryItem } from '../../../../shared/components/FormEntryItem'
import { ImageWithQuestion } from '../../../../shared/components/ImageWithQuestion'
import { NextButton } from '../../../../shared/components/NextButton'
import { ToolContext } from '../../../../shared/state/appContext'
import type { SingleInputState } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { trackPIIAppointmentBookingSubmission } from '../../../../shared/utils/tracking/tracking'
import { validatePhoneNumber } from '../../../../shared/utils/validatePhoneNumber'
import { AppointmentChoiceFlow, BestNumberFlow } from '../../pageFlowData'
import { bestNumberData } from './data'

export const BestNumber: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    triage: { number, type },
  } = state

  const PhoneNumberRef = useRef<HTMLInputElement>(null)
  const [phoneNumberData, setPhoneNumberData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })

  const handleTracking = () => {
    trackPIIAppointmentBookingSubmission(type, BestNumberFlow.VIEW_NAME)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_STEP', data: AppointmentChoiceFlow.STEP })
    dispatch({ type: 'UPDATE_TRIAGE_NUMBER', data: value })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: AppointmentChoiceFlow.PROGRESS_VALUE })
  }

  const getValue = () => {
    return cleanText(PhoneNumberRef?.current?.value || '')
  }

  const validateInputs = () => {
    const value = getValue()
    const phoneNumberHasError = !isNotEmpty(value) || !validatePhoneNumber(value)

    setPhoneNumberData({
      renderError: phoneNumberHasError,
      value: value,
    })

    const isValid = phoneNumberHasError ? false : true

    return { phoneNumberValue: value, isValid: isValid }
  }

  const handleSubmit = () => {
    const validationData = validateInputs()

    setPhoneNumberData({
      renderError: !validationData.isValid,
      value: validationData.phoneNumberValue,
    })

    if (validationData.isValid) {
      handleTracking()
      handleDispatch(validationData.phoneNumberValue)
    }
  }

  const { title, imgObj } = bestNumberData

  return (
    <div data-testid="best-number">
      <ImageWithQuestion image={imgObj} title={title} />
      <FormEntryItem
        displayText=""
        enterKeyHandler={handleSubmit}
        fieldData={phoneNumberData}
        id="phoneNumber"
        isFocused={false}
        placeholderText=""
        ref={PhoneNumberRef}
        srLabel=""
        stateValue={number}
        type="number"
      />
      <NextButton buttonText="Next" onClick={handleSubmit} />
    </div>
  )
}
