const appointmentChoiceData = {
  buttons: [],
  title: 'Book your appointment',
  imgObj: {
    alt: 'Appointment booking',
    src: 'https://media.product.which.co.uk/prod/images/original/ccee6d01d803-carproblemstep1.jpg',
  },
  standFirst:
    'Our appointments are booked in TIMEFRAME hour windows, please select the time you wish that window to start from and we will call you within TIMEFRAME hours of this time',
}
export const appointmentChoiceTechData = {
  ...appointmentChoiceData,
  imgObj: {
    alt: 'Two people sitting down at a table, one on their laptop the other on their phone',
    src: 'https://media.product.which.co.uk/prod/images/original/eeb27e6931fd-step5.jpg',
  },
  standFirst:
    'Our appointments are booked in TIMEFRAME-hour windows, please select the time you wish that window to start from and we will call you within an hour of this time. The appointment will last 30 minutes.',
}
export const appointmentChoiceMoneyData = {
  ...appointmentChoiceData,
  imgObj: {
    alt: 'A person holding up a bank in one hand and a piggy bank in another',
    src: 'https://media.product.which.co.uk/prod/images/original/e7472f0db7b7-debt.jpg',
  },
}
export const appointmentChoiceLegalData = {
  ...appointmentChoiceData,
  imgObj: {
    alt: 'A person holding a mobile phone',
    src: 'https://media.product.which.co.uk/prod/images/original/b06060da427e-flight-delay-step-10.jpg',
  },
}
