export const moneyTopicData = {
  buttons: [
    {
      label: 'Claims Handling',
      id: 'claims-handling',
      tooltip:
        'Select this button for help with insurance claims, claims for poor service by financial institutions and advisers, use of the Financial Services Compensation Scheme, complaints about local Government, social care and NHS funding decisions',
    },
    {
      label: 'Finding a professional adviser',
      id: 'professional-adviser',
      tooltip: 'Select this button for help finding solicitors, accountants and financial advisers',
    },
    {
      label: 'Insurance products',
      id: 'insurance-products',
      tooltip:
        'We can help you understand the types of life insurance policies that are available, how life insurance works and the differences between whole of life, level term and decreasing term life policies, and policies that are payable on death only.',
    },
    {
      label: 'Social care, welfare and benefits, or property and housing',
      id: 'social-care',
      tooltip:
        'Select this button for help with Welfare & Benefits, Social & NHS Care Funding, Council Tax, House Local Tax Banding and documents used by families in social care BUT NOT claims or appeals against Local Authority and NHS decision-making - select Claims Handling for help in that area',
    },
    {
      label: 'Money Management',
      id: 'money-management',
      tooltip:
        'Select this button for help with tax, pensions, investments, savings, borrowing, or if none of the other categories seem right for your query',
    },
  ],
  title: 'Please select which topic you need help with',
  imgObj: {
    alt: 'A person holding up a bank in one hand and a piggy bank in another',
    src: 'https://media.product.which.co.uk/prod/images/original/e7472f0db7b7-debt.jpg',
  },
}
