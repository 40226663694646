import type { FunctionComponent } from 'react'
import React from 'react'

import classNames from 'classnames'

import styles from './SectionWrapper.module.scss'

export const SectionWrapper: FunctionComponent<Props> = ({ children, className }) => (
  <div className={classNames(styles.sectionWrapper, className)}>{children}</div>
)

type Props = {
  className?: string
}
