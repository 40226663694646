export const limitedOptionsData = {
  ctaData: {
    image: {
      src: 'https://media.product.which.co.uk/prod/images/original/gm-8b0968cf-b7d0-49e7-b5fd-ff38b3887a0d-limitedoptionsfaultygoods.png',
      alt: 'Two people looking at their phones facing away from each other.',
    },
    author: { name: 'Which?', jobTitle: 'Editorial team' },
  },
  private: {
    title:
      'Unfortunately you have fewer rights when you buy a car from a private seller, or at auction',
    standFirst: '',
    bodyCopy: [
      "Key protections of the Consumer Rights and Sale of Goods Acts don't apply.",
      'However, your car should have been accurately described when you bought it.',
    ],
    link: {
      href: 'https://www.which.co.uk/reviews/new-and-used-cars/article/how-to-find-a-good-garage-am8d52i5tQoY',
      headline:
        "Finding a good, trustworthy garage that'll charge you a fair price is crucial to keeping your car roadworthy and your bills in check. These tips will help you choose wisely.",
      text: 'How to find a good garage',
    },
  },
  finance: {
    title:
      "Unfortunately, if you bought the car on hire purchase (HP) or personal contract purchase (PCP), it's the finance company who's legally responsible if there are problems with your car",
    standFirst: '',
    bodyCopy: [
      "You can reject it anytime during your agreement as long as you stop using the car after you've discovered the problem. Contact the finance company and tell them you're rejecting the car and ask them to arrange for it to be repaired or replaced.",
      "If you paid using a personal loan, you can only make a claim against the loan company if it was a point of sale loan. If you paid all or part of the cost of your car by credit card, the card company might be jointly responsible for compensating you under <a class='sb-link-primary' href='https://www.legislation.gov.uk/ukpga/1974/39/section/75'><span class='sb-link-animation-wrapper'>Section 75 of the Consumer Credit Act</span></a>, if the car cost more than £100 and less under £30,000. Contact your credit company and tell them you'd like to make a claim under Section 75. They'll let you know if you're eligible, and what to do next.",
    ],
    link: {
      href: 'https://www.which.co.uk/consumer-rights/regulation/section-75-of-the-consumer-credit-act-aZCUb9i8Kwfa',
      headline:
        'If you buy goods or services on your credit card, Section 75 can give you extra protection if things go wrong.',
      text: 'Section 75 of the Consumer Credit Act',
    },
  },
  overSixYears: {
    title: 'Unfortunately, if you bought a car over six years ago you have fewer rights',
    standFirst: '',
    bodyCopy: [
      'Your rights against the retailer can last for you up to six years. This is because there is a six year limit on claims for a breach in a simple consumer contract due to The Limitation Act 1980.',
    ],
    link: {
      href: 'https://www.which.co.uk/reviews/new-and-used-cars/article/how-to-find-a-good-garage-am8d52i5tQoY',
      headline:
        "Finding a good, trustworthy garage that'll charge you a fair price is crucial to keeping your car roadworthy and your bills in check. These tips will help you choose wisely.",
      text: 'How to find a good garage',
    },
  },
}
