import type { FunctionComponent } from 'react'
import React from 'react'
import { Dropdown } from '@which/seatbelt'

import classNames from 'classnames'

import { ErrorMessage } from '../../components/ErrorMessage'
import { ImageWithQuestion } from '../../components/ImageWithQuestion'
import { NextButton } from '../../components/NextButton'
import type { SingleDropDownPageProps } from '../../types/toolTypes'
import styles from './SingleDropDownPage.module.scss'

export const SingleDropDownPage: FunctionComponent<SingleDropDownPageProps> = ({
  buttonText,
  defaultValue,
  handleSubmit,
  id,
  imgObj,
  isInputValid,
  onChangeCallback,
  options,
  title,
}) => {
  return (
    <div data-testid="single-dropdown-page">
      <ImageWithQuestion image={imgObj} title={title} />
      <div className={styles.wrapper}>
        <Dropdown
          callback={onChangeCallback}
          className={styles.wrapperDropDown}
          data-testid="select-input"
          defaultValue={defaultValue?.value}
          id={id}
          options={options}
          variant="regular"
        />
        {!isInputValid && (
          <ErrorMessage
            errorText={'Please select an option to continue'}
            styleClasses={classNames(styles.errorMessage)}
          />
        )}
      </div>
      <NextButton buttonText={buttonText} onClick={handleSubmit} />
    </div>
  )
}
