export const deliveryDateExplainedData = {
  title:
    "Please explain when you want your order re-delivered and why it's important it's delivered on this date",
  imgObj: {
    src: 'https://media.product.which.co.uk/prod/images/original/gm-aef98279-02d4-407e-898d-1483c6efa774-step6.jpg',
    alt: 'A distressed man using a phone and a frustrated woman',
  },
  note: [
    'What you write here will appear in the final letter of claim, so please be as clear as possible, including dates.',
  ],
  descriptiveText: [],
  placeholderText:
    'Eg. I want it delivered on Monday 5 March as I am not at home other days of the week.',
}
