import { constructDate } from './constructDate'

export const formatDate = (dateValue: string) => {
  // need to split it out as we're doing dd/mm/yyyy instead of mm/dd/yyyy
  const dateParts = dateValue.split('/')
  const today = new Date(Date.now())

  if (dateParts.length >= 2 && dateParts[1]) {
    // month is 0-based, that's why we need dataParts[1] - 1
    const newDate = new Date(
      dateParts[2] ? +dateParts[2] : today.getFullYear(),
      parseInt(dateParts[1]) - 1,
      +dateParts[0]
    )
    if (isNaN(newDate.getTime())) {
      return constructDate(today)
    }
    return constructDate(newDate)
  }

  // default today
  return constructDate(today)
}
