import type { Reducer } from 'react'

import { initialSliceReducer, toolFeedbackSliceReducer } from '../../../shared/state/reducers'
import type { Action, State } from '../../../shared/state/toolStateTypes'
import { combineReducers } from './../../../shared/utils/combineReducers'

export const issueSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_SELLER_CONTACT':
      return {
        ...state,
        sellerContact: data,
      }
    case 'UPDATE_DESIRED_RESOLUTION':
      return {
        ...state,
        desiredResolution: data,
      }
    case 'UPDATE_CARD_PROVIDER':
      return {
        ...state,
        cardProvider: data,
      }
    case 'UPDATE_CLAIM_VALUE':
      return {
        ...state,
        claimValue: data,
      }
    case 'UPDATE_ISSUE_DESCRIPTION':
      return {
        ...state,
        description: data,
      }
    default:
      return state
  }
}

export const productSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_PRODUCT_TYPE':
      return {
        ...state,
        type: data,
      }
    case 'UPDATE_PURCHASE_METHOD':
      return {
        ...state,
        purchaseMethod: data,
      }
    case 'UPDATE_PURCHASE_DATE':
      return {
        ...state,
        purchasedDate: data,
      }
    case 'UPDATE_PRODUCT_PRICE':
      return {
        ...state,
        price: data,
      }
    case 'UPDATE_PRODUCT_DESCRIPTION':
      return {
        ...state,
        description: data,
      }
    default:
      return state
  }
}

export const retailerSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_REPEAT_PERFORMANCE':
      return {
        ...state,
        repeatPerformance: data,
      }
    case 'UPDATE_RETAILER_NAME':
      return {
        ...state,
        name: data,
      }
    case 'UPDATE_RETAILER_WHEN_BOUGHT':
      return {
        ...state,
        whenBought: data,
      }
    default:
      return state
  }
}

export const s75Reducer = combineReducers({
  initial: initialSliceReducer,
  product: productSliceReducer,
  issue: issueSliceReducer,
  retailer: retailerSliceReducer,
  toolFeedback: toolFeedbackSliceReducer,
})
