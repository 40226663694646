import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { PageWithText } from '../../../../shared/components/PageWithText'
import { FormEntryPage } from '../../../../shared/pages/FormEntryPage'
import { ToolContext } from '../../../../shared/state/appContext'
import styles from '../../../../shared/styles/GlobalStyles.module.scss'
import type { FormEntryInputRef, SingleInputState } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { CompanyNameFlow, ContractNumberFlow } from '../../pageFlowData'
import { companyNameData } from './data'

export const CompanyName: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    work: { name, date },
  } = state

  const CompanyNameInput = useRef<FormEntryInputRef>(null)

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })

  const getValue = () => {
    return cleanText(CompanyNameInput?.current?.formEntryInput?.value || '')
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${CompanyNameFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_NAME', data: value })
    dispatch({ type: 'UPDATE_STEP', data: ContractNumberFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: ContractNumberFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    const value = getValue()
    const isValid: boolean = isNotEmpty(value)

    setInputData({
      renderError: !isValid,
      value: value,
    })

    if (isValid) {
      handleTracking(value)
      handleDispatch(value)
    }
  }

  const { imgObj, title, placeholderText, bodyCopy, infoTitle, standFirst, bodyCopyBefore } =
    companyNameData

  const before2015 = new Date(date) < new Date('2015-10-01')

  return (
    <PageWithText bodyCopy={before2015 ? bodyCopyBefore : bodyCopy} title={infoTitle}>
      <div className={styles.spacing65Top}>
        <FormEntryPage
          buttonText="Next"
          defaultValue={name}
          fieldData={{
            renderError: inputData.renderError,
            value: inputData.value,
          }}
          handleSubmit={handleSubmit}
          id="company-name"
          imgObj={imgObj}
          isFocused
          optional={false}
          placeholderText={placeholderText}
          ref={CompanyNameInput}
          standFirst={standFirst}
          testId="company-name"
          title={title}
        />
      </div>
    </PageWithText>
  )
}
