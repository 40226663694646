import type { FunctionComponent } from 'react'
import React from 'react'

import { CheckboxGroup } from '../../components/CheckboxGroup'
import { ImageWithQuestion } from '../../components/ImageWithQuestion'
import { NextButton } from '../../components/NextButton'
import type { CheckboxGroupPageProps } from '../../types/toolTypes'
import { RenderInfoBox } from '../../utils/renderInfoBox'
import styles from './CheckBoxGroupPage.module.scss'

export const CheckBoxGroupPage: FunctionComponent<CheckboxGroupPageProps> = ({
  buttons,
  buttonText,
  handleChange,
  handleSubmit,
  imgObj,
  isInputValid,
  stateValue,
  title,
  optionalText,
  infoBoxData,
  btnDisable = false,
}) => {
  const optional = optionalText ? true : false

  return (
    <div data-testid="check-box-group-page">
      <ImageWithQuestion
        image={imgObj}
        optionalTag={optional}
        optionalText={optionalText}
        title={title}
      />
      <CheckboxGroup
        buttons={buttons}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        isSubmitValid={isInputValid}
        stateValue={stateValue}
      />
      <NextButton buttonText={buttonText} disabled={btnDisable} onClick={handleSubmit} />
      {infoBoxData && (
        <div className={styles.checkBoxGroupPageInfoBox}>
          <RenderInfoBox data={infoBoxData} />
        </div>
      )}
    </div>
  )
}
