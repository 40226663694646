import type { FunctionComponent } from 'react'
import React from 'react'

import classNames from 'classnames'

import styles from './ErrorMessage.module.scss'

export const ErrorMessage: FunctionComponent<Props> = ({ errorText, styleClasses }) => {
  return (
    <p
      className={classNames(styleClasses, styles.errorText)}
      data-testid="typography-body"
      id="error-message"
    >
      {errorText}
    </p>
  )
}

type Props = {
  errorText: string
  styleClasses?: string
}
