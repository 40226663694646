import React from 'react'

import { AppointmentChoice } from '../PageComponents/AppointmentChoice'
import { BestNumber } from '../PageComponents/BestNumber'
import { DescribeCase } from '../PageComponents/DescribeCase'
import { FollowUpAppointment } from '../PageComponents/FollowUpAppointment'
import { BuyingQuery } from '../PageComponents/Legal/BuyingQuery'
import { BuyingTopic } from '../PageComponents/Legal/BuyingTopic'
import { CarsLegal } from '../PageComponents/Legal/CarsLegal'
import { CarsQuery } from '../PageComponents/Legal/CarsQuery'
import { CarsTopic } from '../PageComponents/Legal/CarsTopic'
import { ContactDetails } from '../PageComponents/Legal/ContactDetails'
import { ContractChoice } from '../PageComponents/Legal/ContractChoice'
import { EmploymentTopic } from '../PageComponents/Legal/EmploymentTopic'
import { HolidayBooking } from '../PageComponents/Legal/HolidayBooking'
import { HolidayQuery } from '../PageComponents/Legal/HolidayQuery'
import { HolidayTopic } from '../PageComponents/Legal/HolidayTopic'
import { HomeDetail } from '../PageComponents/Legal/HomeDetail'
import { HomeLocation } from '../PageComponents/Legal/HomeLocation'
import { HomeQuery } from '../PageComponents/Legal/HomeQuery'
import { HomeTopic } from '../PageComponents/Legal/HomeTopic'
import { LegalTopic } from '../PageComponents/Legal/LegalTopic'
import { MedicalTopic } from '../PageComponents/Legal/MedicalTopic'
import { OtherTopic } from '../PageComponents/Legal/OtherTopic'
import { Solicitor } from '../PageComponents/Legal/Solicitor'
import { WillsDetail } from '../PageComponents/Legal/WillsDetail'
import { WillsEstate } from '../PageComponents/Legal/WillsEstate'
import { WillsQuery } from '../PageComponents/Legal/WillsQuery'
import { WillsTopic } from '../PageComponents/Legal/WillsTopic'
import { LimitedOptions } from '../PageComponents/LimitedOptions'
import { MoneyQuery } from '../PageComponents/Money/MoneyQuery'
import { MoneyTopic } from '../PageComponents/Money/MoneyTopic'
import { RebookAppointment } from '../PageComponents/RebookAppointment'
import { TechDevice } from '../PageComponents/Tech/TechDevice'
import { TechQuery } from '../PageComponents/Tech/TechQuery'
import { TechSystem } from '../PageComponents/Tech/TechSystem'
import { TechTopic } from '../PageComponents/Tech/TechTopic'
import { Triage } from '../PageComponents/Triage'
import { UnderConstruction } from '../PageComponents/UnderConstruction'
import {
  AppointmentChoiceFlow,
  BestNumberFlow,
  BuyingQueryFlow,
  BuyingTopicFlow,
  CarsLegalFlow,
  CarsQueryFlow,
  CarsTopicFlow,
  ContactDetailsFlow,
  ContractChoiceFlow,
  DescribeCaseFlow,
  EmploymentTopicFlow,
  FollowUpAppointmentFlow,
  HolidayBookingFlow,
  HolidayQueryFlow,
  HolidayTopicFlow,
  HomeDetailFlow,
  HomeLocationFlow,
  HomeQueryFlow,
  HomeTopicFlow,
  LegalTopicFlow,
  LimitedOptionsFlow,
  MedicalTopicFlow,
  MoneyQueryFlow,
  MoneyTopicFlow,
  OtherTopicFlow,
  RebookAppointmentFlow,
  SolicitorFlow,
  SubmitSuccessfulFlow,
  TechDeviceFlow,
  TechQueryFlow,
  TechSystemFlow,
  TechTopicFlow,
  TriageFlow,
  UnderConstructionFlow,
  WillsDetailFlow,
  WillsEstateFlow,
  WillsQueryFlow,
  WillsTopicFlow,
} from '../pageFlowData'

export const useRenderStep = (step: number) => {
  switch (step) {
    case TriageFlow.STEP:
      return <Triage />

    case SolicitorFlow.STEP:
      return <Solicitor />

    case LegalTopicFlow.STEP:
      return <LegalTopic />

    case CarsTopicFlow.STEP:
      return <CarsTopic />

    case CarsQueryFlow.STEP:
      return <CarsQuery />

    case CarsLegalFlow.STEP:
      return <CarsLegal />

    case EmploymentTopicFlow.STEP:
      return <EmploymentTopic />

    case AppointmentChoiceFlow.STEP:
      return <AppointmentChoice />

    case OtherTopicFlow.STEP:
      return <OtherTopic />

    case WillsTopicFlow.STEP:
      return <WillsTopic />

    case WillsQueryFlow.STEP:
      return <WillsQuery />

    case WillsEstateFlow.STEP:
      return <WillsEstate />

    case WillsDetailFlow.STEP:
      return <WillsDetail />

    case BuyingTopicFlow.STEP:
      return <BuyingTopic />

    case BuyingQueryFlow.STEP:
      return <BuyingQuery />

    case HolidayTopicFlow.STEP:
      return <HolidayTopic />

    case HolidayQueryFlow.STEP:
      return <HolidayQuery />

    case HolidayBookingFlow.STEP:
      return <HolidayBooking />

    case HomeTopicFlow.STEP:
      return <HomeTopic />

    case HomeQueryFlow.STEP:
      return <HomeQuery />

    case HomeLocationFlow.STEP:
      return <HomeLocation />

    case HomeDetailFlow.STEP:
      return <HomeDetail />

    case DescribeCaseFlow.STEP:
      return <DescribeCase />

    case ContractChoiceFlow.STEP:
      return <ContractChoice />

    case ContactDetailsFlow.STEP:
      return <ContactDetails />

    case MoneyTopicFlow.STEP:
      return <MoneyTopic />

    case MoneyQueryFlow.STEP:
      return <MoneyQuery />

    case TechTopicFlow.STEP:
      return <TechTopic />

    case TechQueryFlow.STEP:
      return <TechQuery />

    case TechDeviceFlow.STEP:
      return <TechDevice />

    case TechSystemFlow.STEP:
      return <TechSystem />

    case SubmitSuccessfulFlow.STEP:
      return <div>submit successful</div>

    case LimitedOptionsFlow.STEP:
      return <LimitedOptions />

    case UnderConstructionFlow.STEP:
      return <UnderConstruction />

    case BestNumberFlow.STEP:
      return <BestNumber />

    case MedicalTopicFlow.STEP:
      return <MedicalTopic />

    case RebookAppointmentFlow.STEP:
      return <RebookAppointment toolName="BookAppointment" />

    case FollowUpAppointmentFlow.STEP:
      return <FollowUpAppointment toolName="BookAppointment" />

    default:
      return <div>BookAppointment Tool</div>
  }
}
