import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../../shared/state/appContext'
import globalStyles from '../../../../../shared/styles/GlobalStyles.module.scss'
import { trackAppointmentBookingSelection } from '../../../../../shared/utils/tracking/tracking'
import { LimitedOptionsFlow, WillsQueryFlow, WillsTopicFlow } from '../../../pageFlowData'
import { willsTopicData } from './data'
import styles from './WillsTopic.module.scss'

export const WillsTopic: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    triage: { type },
  } = state

  const handleTracking = (id: string, index: number) => {
    trackAppointmentBookingSelection(type, WillsTopicFlow.VIEW_NAME, { id, index })
  }

  const chooseStep = (id: string) => {
    if (id === 'northern-ireland-or-overseas') {
      return LimitedOptionsFlow
    }
    return WillsQueryFlow
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_WILLS_TOPIC', data: id })
    const step = chooseStep(id)
    dispatch({ type: 'UPDATE_STEP', data: step.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: step.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string, index) => {
    handleTracking(id, index)
    handleDispatch(id)
  }

  const { buttons, imgObj, title } = willsTopicData

  return (
    <div className={globalStyles.fullWidthButton}>
      <PillButtonPage
        buttons={buttons}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        infoBoxData={willsTopicData}
        pillButtonGroupStyle={styles.willsTopicPillButtons}
        title={title}
      />
    </div>
  )
}
