import type { FunctionComponent } from 'react'
import React from 'react'

import classNames from 'classnames'

import { DropdownGroup } from '../../components/DropdownGroup'
import { ErrorMessage } from '../../components/ErrorMessage'
import { ImageWithQuestion } from '../../components/ImageWithQuestion'
import { NextButton } from '../../components/NextButton'
import globalStyles from '../../styles/GlobalStyles.module.scss'
import type { DropdownPageProps } from '../../types/toolTypes'
import styles from './DropdownPage.module.scss'

export const DropdownPage: FunctionComponent<DropdownPageProps> = ({
  buttonText,
  handleSubmit,
  imgObj,
  isInputValid,
  title,
  firstDropdownDataSet,
  secondDropdownDataSet,
}) => {
  return (
    <div data-testid="dropdown-page">
      <ImageWithQuestion image={imgObj} pictureClass={styles.dropdownPageImage} title={title} />
      <div
        className={classNames(styles.dropdownPageDropdown, globalStyles.spacing40, {
          [styles.dropdownGroupContainerError]: !isInputValid,
        })}
      >
        <div className={styles.dropdownPageGroup}>
          <DropdownGroup
            firstDropdownOptions={firstDropdownDataSet.firstDropdown}
            handleChange={firstDropdownDataSet.handleChange}
            label={firstDropdownDataSet.label}
            secondDropdownOptions={firstDropdownDataSet.secondDropdown}
          />
        </div>
        <DropdownGroup
          firstDropdownOptions={secondDropdownDataSet.firstDropdown}
          handleChange={secondDropdownDataSet.handleChange}
          label={secondDropdownDataSet.label}
          secondDropdownOptions={secondDropdownDataSet.secondDropdown}
        />
        {!isInputValid && (
          <ErrorMessage
            errorText="Please complete all fields to continue"
            styleClasses={classNames(styles.dropdownPageError)}
          />
        )}
      </div>

      <NextButton buttonText={buttonText} onClick={handleSubmit} />
    </div>
  )
}
