export const data = {
  product: {
    title: 'Has the seller attempted to repair/replace the Item?',
    image:
      'https://media.product.which.co.uk/prod/images/original/gm-6b3d6bb6-1c82-42bb-8fa8-24f9f0004a5a-repairproduct.jpg',
  },
  damagedDevice: {
    title: 'Has the seller attempted to repair/replace the content & device?',
    image:
      'https://media.product.which.co.uk/prod/images/original/gm-d6553213-0151-49c9-9382-464f37e899eb-repaircontent.jpg',
  },
  content: {
    title: 'Has the seller attempted to repair/replace the content?',
    image:
      'https://media.product.which.co.uk/prod/images/original/gm-82952ae8-d701-40ee-8cb4-c0369d8d6018-repaircontendevice.jpg',
  },
  title: 'What item did you order?',
  imageAlt: 'Person in a blue jumper pointing towards a device',
  buttons: [
    {
      label: 'Yes',
      id: 'yes',
    },
    {
      label: 'No',
      id: 'no',
    },
  ],
}
