// A separate object for the infoBox data is used due to the typing of the borderColour attribute
import type { InfoBoxProps } from '../../../../../shared/types/toolTypes'

export const repeatPerformanceInfoBox: InfoBoxProps = {
  heading: 'What is repeat performance?',
  borderColour: 'blue',
}

export const repeatPerformanceData = {
  infoBox: repeatPerformanceInfoBox,
  title: 'Have you asked the provider of the service to offer a repeat performance?',
  imgObj: {
    src: 'https://media.product.which.co.uk/prod/images/original/gm-4d4188ed-4470-46e1-8f93-bd541b4f83be-repeat-performance.jpg',
    alt: 'Two people talking',
  },
  repeatPerformanceButtons: [
    {
      label: 'Yes',
      id: 'yes',
    },
    {
      label: 'No',
      id: 'no',
    },
  ],
  infoBoxParagraphOne:
    "Don't be daunted by the phrase, put simply it means someone carrying out a service a second time without any further cost to you.",
  infoBoxParagraphTwo: 'The Consumer Rights Act 2015 says:',
  infoBoxParagraphThree:
    'The right to require repeat performance is a right to require the trader to perform the service again, to the extent necessary to complete its performance in conformity with the contract.',
  infoBoxParagraphFour: 'If the consumer requires such repeat performance, the trader must:',
  bulletPoints: [
    'Provide it within a reasonable time and without significant inconvenience to the consumer.',
    'Bear any necessary costs incurred in doing so (including in particular the cost of any labour or materials).',
  ],
  link: {
    href: 'https://www.legislation.gov.uk/ukpga/2015/15/part/1/chapter/4/enacted',
    text: 'Learn more about repeat performance.',
  },
}
