import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PreviewLetter } from '../../../../shared/components/PreviewLetter'
import { SubmitSuccessfulPage } from '../../../../shared/pages/SubmitSuccessfulPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { ToolFeedbackFlow, ToolFeedbackThankYouFlow } from '../../pageFlowData'
import { composeEmailBody } from './composeEmailBody'
import { feedbackData } from './data'

export const SubmitSuccessful: FunctionComponent = () => {
  const toolContext = useContext(ToolContext)
  const {
    state: {
      car,
      initial: { emailSent, user },
      toolFeedback: { showFeedback },
    },
    dispatch,
  } = toolContext

  const { whichDealer } = car
  const { body, imgObj, title } = feedbackData

  const data = composeEmailBody({ car, user })

  const standFirstCopy = emailSent
    ? `A copy of your claim letter has been sent to ${user.emailAddress}`
    : 'Due to an error, we were unable to send your email. However, you can get a copy of your complaint letter below'

  const handleStepChoice = (id: string) => {
    if (id === 'feedback-yes') {
      return { step: ToolFeedbackFlow.STEP }
    }

    return {
      step: ToolFeedbackThankYouFlow.STEP,
    }
  }

  const handleSubmit = (id: string) => {
    const { step } = handleStepChoice(id)

    dispatch({ type: 'UPDATE_STEP', data: step })
  }

  return (
    <div data-testid="submit-successful">
      <SubmitSuccessfulPage
        feedbackBody={body}
        feedbackImgObj={imgObj}
        feedbackTitle={title}
        handleSubmit={handleSubmit}
        letterBody={data.body}
        letterTitle="Your complaint email"
        list={[
          'Check all the information in the letter below is correct. <br /> \r\n Send your complaint to the dealer. We suggest you give the dealer 14 days to respond.',
          `Copy and paste the letter below and send it to ${whichDealer}. We suggest you give the dealer 14 days to respond.`,
          "If the dealer doesn't offer to resolve the problem, you can escalate your dispute to an ombudsman.",
        ]}
        listAppearance="bullet"
        showFeedback={showFeedback}
        standFirst={standFirstCopy}
        title="Your next steps"
      >
        <PreviewLetter data={data} />
      </SubmitSuccessfulPage>
    </div>
  )
}
