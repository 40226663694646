import React from 'react'

import { ScamSharerFeedbackThankYouPage } from '../../../shared/pages/ScamSharerFeedbackThankYouPage/ScamSharerFeedbackThankYouPage'
import { ToolFeedbackAudiencePage } from '../../../shared/pages/ToolFeedbackAudiencePage'
import { toolFeedbackAudienceData } from '../../../shared/pages/ToolFeedbackAudiencePage/data'
import { ToolFeedbackPage } from '../../../shared/pages/ToolFeedbackPage'
import { toolFeedbackData } from '../../../shared/pages/ToolFeedbackPage/data/scamSharerData'
import { Age } from '../pageComponents/Age'
import { BeenAffected } from '../pageComponents/BeenAffected'
import { ComeAcrossScam } from '../pageComponents/ComeAcrossScam'
import { ContactDetails } from '../pageComponents/ContactDetails'
import { Gender } from '../pageComponents/Gender'
import { GetInTouch } from '../pageComponents/GetInTouch'
import { HaveContact } from '../pageComponents/HaveContact'
import { HowDidYouPay } from '../pageComponents/HowDidYouPay'
import { HowMuchMoney } from '../pageComponents/HowMuchMoney'
import { LostMoney } from '../pageComponents/LostMoney'
import { MoreAboutScam } from '../pageComponents/MoreAboutScam'
import { MoreAboutScammer } from '../pageComponents/MoreAboutScammer'
import { NameOfScammer } from '../pageComponents/NameOfScammer'
import { Outcome } from '../pageComponents/Outcome'
import { Postcode } from '../pageComponents/Postcode'
import { ReportedTo } from '../pageComponents/ReportedTo'
import { ReportedToOmbudsman } from '../pageComponents/ReportedToOmbudsman'
import { ScammerContact } from '../pageComponents/ScammerContact'
import { ScammerReference } from '../pageComponents/ScammerReference'
import { SubmitSuccessful } from '../pageComponents/SubmitSuccessful'
import { TypeOfScammer } from '../pageComponents/TypeOfScammer'
import { WhatAboutMessage } from '../pageComponents/WhatAboutMessage'
import { WhatBank } from '../pageComponents/WhatBank'
import { WhatHappened } from '../pageComponents/WhatHappened'
import { WhatHappenedWithSocial } from '../pageComponents/WhatHappenedWithSocial'
import { WhatScamEntry } from '../pageComponents/WhatScamEntry'
import { WhoReportedTo } from '../pageComponents/WhoReportedTo'
import { WhoWasScammer } from '../pageComponents/WhoWasScammer'
import {
  AgeFlow,
  BeenAffectedFlow,
  ComeAcrossScamFlow,
  ContactDetailsFlow,
  GenderFlow,
  GetInTouchFlow,
  HaveContactFlow,
  HowDidYouPayFlow,
  HowMuchMoneyFlow,
  LostMoneyFlow,
  MoreAboutScamFlow,
  MoreAboutScammerFlow,
  NameOfScammerFlow,
  OutcomeFlow,
  PostcodeFlow,
  ReportedToFlow,
  ReportedToOmbudsmanFlow,
  ScammerContactFlow,
  ScammerReferenceFlow,
  SubmitSuccessfulFlow,
  ToolFeedbackAudienceFlow,
  ToolFeedbackFlow,
  ToolFeedbackThankYouFlow,
  TypeOfScammerFlow,
  WhatAboutMessageFlow,
  WhatBankFlow,
  WhatHappenedFlow,
  WhatHappenedWithSocialFlow,
  WhatScamEntryFlow,
  WhoReportedToFlow,
  WhoWasScammerFlow,
} from '../pageFlowData'

export const useRenderStep = (step: number) => {
  switch (step) {
    case ComeAcrossScamFlow.STEP:
      return <ComeAcrossScam />

    case MoreAboutScamFlow.STEP:
      return <MoreAboutScam />

    case ScammerContactFlow.STEP:
      return <ScammerContact />

    case MoreAboutScammerFlow.STEP:
      return <MoreAboutScammer />

    case WhatHappenedFlow.STEP:
      return <WhatHappened />

    case WhoWasScammerFlow.STEP:
      return <WhoWasScammer />

    case TypeOfScammerFlow.STEP:
      return <TypeOfScammer />

    case NameOfScammerFlow.STEP:
      return <NameOfScammer />

    case WhatHappenedWithSocialFlow.STEP:
      return <WhatHappenedWithSocial />

    case WhatScamEntryFlow.STEP:
      return <WhatScamEntry />

    case WhatAboutMessageFlow.STEP:
      return <WhatAboutMessage />

    case HaveContactFlow.STEP:
      return <HaveContact />

    case ScammerReferenceFlow.STEP:
      return <ScammerReference />

    case BeenAffectedFlow.STEP:
      return <BeenAffected />

    case LostMoneyFlow.STEP:
      return <LostMoney />

    case HowMuchMoneyFlow.STEP:
      return <HowMuchMoney />

    case HowDidYouPayFlow.STEP:
      return <HowDidYouPay />

    case WhatBankFlow.STEP:
      return <WhatBank />

    case ReportedToFlow.STEP:
      return <ReportedTo />

    case WhoReportedToFlow.STEP:
      return <WhoReportedTo />

    case OutcomeFlow.STEP:
      return <Outcome />

    case ReportedToOmbudsmanFlow.STEP:
      return <ReportedToOmbudsman />

    case AgeFlow.STEP:
      return <Age />

    case PostcodeFlow.STEP:
      return <Postcode />

    case GenderFlow.STEP:
      return <Gender />

    case GetInTouchFlow.STEP:
      return <GetInTouch />

    case ContactDetailsFlow.STEP:
      return <ContactDetails />

    case SubmitSuccessfulFlow.STEP:
      return <SubmitSuccessful />
    case ToolFeedbackFlow.STEP:
      return (
        <ToolFeedbackPage
          pageData={toolFeedbackData}
          toolFeedbackAudienceFlowData={ToolFeedbackAudienceFlow}
          toolFeedbackFlowData={ToolFeedbackFlow}
          toolName="ScamSharer"
        />
      )
    case ToolFeedbackThankYouFlow.STEP:
      return (
        <ScamSharerFeedbackThankYouPage toolFeedbackThankYouFlowData={ToolFeedbackThankYouFlow} />
      )
    case ToolFeedbackAudienceFlow.STEP:
      return (
        <ToolFeedbackAudiencePage
          pageData={toolFeedbackAudienceData}
          toolFeedbackAudienceFlowData={ToolFeedbackAudienceFlow}
          toolFeedbackThankYouFlowData={ToolFeedbackThankYouFlow}
          toolName="ScamSharer"
        />
      )
    default:
      return <div>ScamSharer Tool</div>
  }
}
