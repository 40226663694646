import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { PurchaseMethodFlow, SupportingEvidenceFlow } from '../../pageFlowData'
import { data } from './data'

export const PurchaseMethod: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    issue: { purchaseMethod },
  } = state

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_PURCHASE_METHOD', data: value })
    dispatch({ type: 'UPDATE_STEP', data: SupportingEvidenceFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: SupportingEvidenceFlow.PROGRESS_VALUE })
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${PurchaseMethodFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = (id: string) => {
    handleDispatch(id)
    handleTracking(id)
  }

  const { buttons, imgObj, title } = data

  return (
    <div data-testid="ticket-purchased">
      <PillButtonPage
        buttons={buttons}
        defaultValue={purchaseMethod}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        title={title}
      />
    </div>
  )
}
