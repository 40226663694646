import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import globalStyles from '../../../../shared/styles/GlobalStyles.module.scss'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { RenderInfoBox } from '../../../../shared/utils/renderInfoBox'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { ArriveDepartUKFlow, LimitedOptionsFlow, WhichAirportsFlow } from '../../pageFlowData'
import { data } from './data'

export const ArriveDepartUK: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    flight: { arriveDepartUK },
  } = state

  const handleStepChoice = (value: string) => {
    if (value === 'flight-not-to-or-from-uk') {
      return { progress: LimitedOptionsFlow.PROGRESS_VALUE, step: LimitedOptionsFlow.STEP }
    }

    return { progress: WhichAirportsFlow.PROGRESS_VALUE, step: WhichAirportsFlow.STEP }
  }

  const handleDispatch = (value: string) => {
    const { progress, step } = handleStepChoice(value)

    dispatch({ type: 'UPDATE_UK_AIRPORT', data: value })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${ArriveDepartUKFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = (id: string) => {
    handleDispatch(id)
    handleTracking(id)
  }

  const { buttons, imgObj, title, infoBoxData } = data

  return (
    <>
      <div className={globalStyles.fullWidthButton} data-testid="arrive-depart-uk">
        <PillButtonPage
          buttons={buttons}
          defaultValue={arriveDepartUK}
          fitContent
          handleSubmit={handleSubmit}
          imgObj={imgObj}
          title={title}
        />
      </div>
      <div className={globalStyles.spacing40Top}>
        <RenderInfoBox data={infoBoxData} />
      </div>
    </>
  )
}
