import type { FunctionComponent } from 'react'
import React from 'react'
import { Dropdown, Typography } from '@which/seatbelt'

import classNames from 'classnames'

import globalStyles from '../../../shared/styles/GlobalStyles.module.scss'
import { ErrorMessage } from '../../components/ErrorMessage'
import { ImageWithQuestion } from '../../components/ImageWithQuestion'
import { NextButton } from '../../components/NextButton'
import type { SelectBoxPageProps } from '../../types/toolTypes'
import styles from './SelectBoxPage.module.scss'

export const SelectBoxPage: FunctionComponent<SelectBoxPageProps> = ({
  selectBoxes,
  defaultValue,
  handleSubmit,
  imgObj,
  title,
  buttonText,
  multiple,
  isInputValid,
  extendedText,
}) => {
  const getDefaultValue = (id: string) => {
    if (id === 'select-1') {
      return defaultValue.value1
    } else {
      return defaultValue.value2
    }
  }

  return (
    <div data-testid="select-box-page">
      <ImageWithQuestion image={imgObj} title={title} />
      <div className={styles.wrapper}>
        {extendedText && (
          <div className={globalStyles.maxWidth}>
            {extendedText.info && (
              <Typography className={globalStyles.spacing40}>{extendedText.info}</Typography>
            )}
            {extendedText.selectHeading && <Typography>{extendedText.selectHeading}</Typography>}
            {extendedText.selectSubheading && (
              <Typography className={styles.selectSubheading}>
                {extendedText?.selectSubheading}
              </Typography>
            )}
          </div>
        )}
        {selectBoxes.map(({ id, selectLabel, options, onChangeCallback }) => (
          <div
            className={classNames(styles.selectBoxLabelWrapper, {
              [styles.multiSelectMargin]: multiple,
            })}
            key={id}
          >
            <Typography textStyle="body-intro">{selectLabel}</Typography>
            <div className={styles.selectWrapper}>
              <Dropdown
                callback={onChangeCallback}
                data-testid="select-input"
                defaultValue={getDefaultValue(id)}
                id={id}
                options={options}
                variant="regular"
              />
            </div>
          </div>
        ))}
        {!isInputValid && (
          <ErrorMessage
            errorText={
              multiple
                ? 'Please complete all fields to continue'
                : 'Please select an option to continue'
            }
            styleClasses={classNames(styles.errorMessage)}
          />
        )}
      </div>
      <NextButton buttonText={buttonText} onClick={handleSubmit} />
    </div>
  )
}
