import type { UserPropsWithAddress } from '../../../../shared/types/toolTypes'
import { getBlaize } from '../../../../shared/utils/getBlaize/getBlaize'

export const salesforceMapper = (state: salesforceStateType) => {
  const {
    cars: { carsTopic, carsQuery },
    legal: { legalTopic },
    employment: { employmentTopic },
    other: { otherTopic },
    holiday: { holidayTopic, holidayQuery },
    home: { homeTopic, homeQuery, homeLocation, homeDetail },
    buying: { buyingTopic, buyingQuery },
    money: { moneyTopic, moneyQuery },
    triage: { describeCase, type, number },
    initial: { user },
    wills: { willsTopic, willsQuery },
    tech: { techTopic, techQuery, techSystem, techDevice },
    status = 'New',
  } = state
  // caseArea decision
  let caseArea = ''
  let caseType = ''
  let caseSubType = ''
  let device = ''
  let operatingSystem = ''
  if (legalTopic) {
    switch (legalTopic) {
      case 'buying':
        caseArea = 'Buying or hiring goods or services'
        break
      case 'home':
        caseArea = 'Home and Property'
        break
      case 'holiday':
        caseArea = 'Transport, Travel & Holidays'
        break
      case 'cars':
        caseArea = 'Cars & Motoring'
        break
      case 'employment':
        caseArea = 'Employment'
        break
      case 'wills':
        caseArea = 'Wills & probate'
        break
      case 'dataProtection':
        caseArea = 'Buying or hiring goods or services'
        caseType = 'Data Protection'
        break
      case 'medical':
        caseArea = 'Buying or hiring goods or services'
        caseType = 'Medical negligence & Personal Injury'
        break
      case 'other':
        caseArea = 'Other'
        break
      default:
        break
    }

    // Employment path
    if (employmentTopic) {
      switch (employmentTopic) {
        case 'deduction-of-wages':
          caseType = 'Deduction of wages or payment disputes'
          break
        case 'discrimination':
          caseType = 'Discrimination'
          break
        case 'dismissal-and-redundancy':
          caseType = 'Dismissal & Redundancy'
          break
        case 'employment-contract-rights':
          caseType = 'Employment contract/rights'
          caseSubType = 'Parents/Carers rights'
          break
        case 'employment-tribunal':
          caseType = 'Employment Tribunal procedure'
          break
        case 'appeals-to-employment':
          caseType = 'Employment Tribunal procedure'
          break
        case 'grievances-and-disciplinaries':
          caseType = 'Grievances and disciplinary'
          break
        case 'pension-tax-shares-query':
          caseType = 'Other'
          break
        case 'police-armed':
          caseType = 'Other'
          break
        case 'self-employment-business':
          caseType = 'Other'
          break
        case 'rights-company-director':
          caseType = 'Other'
          break
        case 'civil-court':
          caseType = 'Other'
          break
        case 'defamation':
          caseType = 'Other'
          break
        case 'employee-benefits':
          caseType = 'Other'
          break
        case 'settlement':
          caseType = 'Settlement Agreement service'
          break
        case 'capability':
          caseType = 'Deduction of wages or payment disputes'
          break
      }
      // switch (employmentQuery) {
      //   case 'age':
      //     caseSubType = 'Discrim - Age'
      //     break
      //   case 'disability':
      //     caseSubType = 'Discrim - Disability'
      //     break
      //   case 'gender':
      //     caseSubType = 'Discrim - Gender'
      //     break
      //   case 'martial-civil-status':
      //     caseSubType = 'Discrim - Marital/Civil'
      //     break
      //   case 'pregnancy':
      //     caseSubType = 'Discrim - Preg/Mat'
      //     break
      //   case 'race':
      //     caseSubType = 'Discrim - Race'
      //     break
      //   case 'religion':
      //     caseSubType = 'Discrim - Religion/belief'
      //     break
      //   case 'sex-gender':
      //     caseSubType = 'Discrim - Sex'
      //     break
      //   case 'sexual-orientation':
      //     caseSubType = 'Discrim - Sex orientation'
      //     break
      //   case 'employers-capability':
      //     caseSubType = 'Capability & Performance'
      //     break
      //   case 'unfair-dismissal':
      //     caseSubType = 'Dismissal / UD / WD'
      //     break
      //   case 'redundancy':
      //     caseSubType = 'Performance issues'
      //     break
      //   case 'data-rights':
      //     caseSubType = 'DPA/SARs'
      //     break
      //   case 'fixed-term-rights':
      //     caseSubType = 'Fixed-term rights'
      //     break
      //   case 'flexible-working':
      //     caseSubType = 'Flexible working'
      //     break
      //   case 'holidays':
      //     caseSubType = 'Holidays'
      //     break
      //   case 'part-time-rights':
      //     caseSubType = 'Part-time rights'
      //     break
      //   case 'recruitment':
      //     caseSubType = 'Recruitment'
      //     break
      //   case 'references':
      //     caseSubType = 'References'
      //     break
      //   case 'restrictive-covenants':
      //     caseSubType = 'Restrictive covenants'
      //     break
      //   case 'terms-and-conditions':
      //     caseSubType = 'Terms and conditions'
      //     break
      //   case 'time-off':
      //     caseSubType = 'Time off/rest periods'
      //     break
      //   case 'tupe':
      //     caseSubType = 'TUPE'
      //     break
      //   case 'whistleblowing':
      //     caseSubType = 'Whistleblowing'
      //     break
      //   case 'disciplinary':
      //     caseSubType = 'Disciplinary issues'
      //     break
      //   case 'grievances':
      //     caseSubType = 'Grievances'
      //     break
      //   default:
      //     break
      // }
    } // Other path
    else if (otherTopic) {
      switch (otherTopic) {
        case 'family-law':
          caseType = 'Family Law'
          break
        case 'criminal-offence':
          caseType = 'Criminal Law'
          break
        case 'immigration-visas':
          caseType = 'Immigration'
          break
        case 'query-related-business':
          caseType = 'Business/Commercial Advice'
          break
        case 'other':
          caseType = 'Other'
          break
      }
    } // Holiday path
    else if (holidayTopic) {
      switch (holidayTopic) {
        case 'holiday-accommodation':
          caseType = 'Holiday accommodation'
          break
        case 'issues-with-flight':
          caseType = 'Issues with flights/airlines/airports'
          break
        case 'lost-luggage':
          caseType = 'Lost or damaged luggage'
          break
        case 'package-holidays':
          caseType = 'Package holidays'
          break
        case 'pre-contract-advice':
          caseType = 'Pre-contract/hypothetical advice'
          break
        case 'timeshares':
          caseType = 'Timeshares'
          break
        case 'changes-to-holiday':
          caseType = 'Package holidays'
          break
        case 'passport-issues':
          caseType = 'Package holidays'
          break
        default:
          break
      }
      switch (holidayQuery) {
        case 'flight-delays':
          caseSubType = 'Flight delays & cancellations'
          break
        case 'flight-overbooking':
          caseSubType = 'Flight overbooking & bumping'
          break
        case 'cruise':
          caseSubType = 'Cruise'
          break
        case 'holiday':
          caseSubType = 'Holidays'
          break
        case 'timeshares-post':
          caseSubType = 'Timeshares post 28/2/11'
          break
        case 'timeshares-pre':
          caseSubType = 'Timeshares pre-28/2/11'
          break
        default:
          break
      }
    } // Home path
    else if (homeTopic) {
      switch (homeTopic) {
        case 'dispute-with-trader':
          caseType = 'Disputes with traders over building works, renovations, services provided etc'
          break
        case 'leasehold':
          caseType = 'Leasehold'
          break
        case 'new-build-issues':
          caseType = 'New Build Issues'
          break
        case 'landlords-hmo':
          caseType = 'Other'
          break
        case 'land-registry':
          caseType = 'Other'
          break
        case 'utilities':
          caseType = 'Utilities'
          break
        case 'neighbour-issue':
          let caseTypeId = ''
          let caseAreaId = ''
          switch (homeLocation) {
            case 'england':
              caseAreaId = 'England L&T/Neighbour'
              caseTypeId = 'Neighbour Disputes - ENG'
              break
            case 'wales':
              caseAreaId = 'Wales L&T/Neighbour'
              caseTypeId = 'Neighbour Disputes - WALES'
              break
            case 'scotland':
              caseAreaId = 'Scotland L&T/Neighbour'
              caseTypeId = 'Neighbour disputes SCOTLAND'
              break
          }
          caseArea = caseAreaId
          caseType = caseTypeId
          break
        default:
          break
      }
      let homeId = ''
      switch (homeQuery) {
        case 'adverse-possession':
          caseSubType = 'Adverse possession'
          break
        case 'boundary-issue':
          caseSubType = 'Boundaries'
          break
        case 'cctv':
          caseSubType = 'CCTV'
          break
        case 'high-hedge':
          caseSubType = 'High hedge/trees'
          break
        case 'nuisance':
          caseSubType = 'Nuisance'
          break
        case 'party-walls':
          caseSubType = 'Party wall'
          break
        case 'a-planning-issue':
          caseSubType = 'Planning issue'
          break
        case 'damage-to-property':
          caseSubType = 'Property damage'
          break
        case 'right-to-light':
          caseSubType = 'Right to light'
          break
        case 'trespass':
          caseSubType = 'Trespass'
          break
        case 'rights-of-access':
          caseSubType = ''
          break
        case 'court-tribunal':
          caseSubType = ''
          break
        case 'broadband-phone':
          caseSubType = 'Broadband & phone'
          break
        case 'gas-electric':
          caseSubType = 'Gas & electric'
          break
        case 'tv-licensing':
          caseSubType = 'TV Licensing'
          break
        case 'water-supply-uk':
          caseSubType = ''
          break
        case 'water-supply-ireland':
          caseSubType = 'Water Supply/Sewerage - Northern Ireland'
          break
        case 'wayleave-agreement':
          caseSubType = ''
          break
        case 'issue-with-grid':
          caseSubType = ''
          break
        case 'general-assistance-with-private':
          if (homeLocation === 'scotland') {
            homeId = 'Tenant SCOTLAND- Lodger'
          } else if (homeLocation === 'wales') {
            homeId = 'Tenant WALES- Lodger'
          } else {
            homeId = 'Tenant ENG - Lodger'
          }
          caseType = homeId
          break
        case 'assistance-with-lodger':
          switch (homeLocation) {
            case 'england':
              homeId = 'Landlord ENG - Lodger'
              break
            case 'wales':
              homeId = 'Landlord WALES- Lodger'
              break
            case 'scotland':
              homeId = 'Landlord SCOTLAND- Lodger'
              break
          }
          caseType = homeId
          break
        case 'assistance-with-housing-association-uk':
          caseType = 'Tenant ENG - assistance with tenancy issues'
          break
        case 'assistance-with-housing-association-wales':
          caseType = 'Tenant WALES- assistance with tenancy issues'
          break
        case 'assistance-with-housing-association-scotland':
          caseType = 'Tenant SCOTLAND - assistance with tenancy issues'
          break
        case 'court-procedure-landlord':
          switch (homeLocation) {
            case 'england':
              homeId = 'Landlord ENG- Court procedure'
              break
            case 'wales':
              homeId = 'Landlord WALES - Court procedure'
              break
            case 'scotland':
              homeId = 'Landlord SCOTLAND- Court procedure'
              break
          }
          caseType = homeId
          break
        case 'issue-with-student-accommodation':
          switch (homeLocation) {
            case 'england':
              homeId = 'Tenant ENG - Student accommodation'
              break
            case 'wales':
              homeId = 'Tenant WALES - Student accommodation'
              break
            case 'scotland':
              homeId = 'Tenant SCOTLAND- Student accomodation'
              break
          }
          caseType = homeId
          break
        case 'council-tenancies':
          switch (homeLocation) {
            case 'england':
              homeId = 'Housing Association Tenancies - ENG'
              break
            case 'wales':
              homeId = 'Housing Association Tenancies - WALES'
              break
            case 'scotland':
              homeId = 'Housing Association Tenancies - SCOTLAND'
              break
          }
          caseType = homeId
          break
        case 'assistance-with-tenants':
          switch (homeLocation) {
            case 'england':
              homeId = 'Landlord ENG - Assistance with Tenants'
              break
            case 'wales':
              homeId = 'Landlord WALES- Assistance with Tenants'
              break
            case 'scotland':
              homeId = 'Landlord SCOTLAND- assistance with tenants'
              break
            default:
              homeId = 'Other'
              break
          }
          caseType = homeId
          break
        case 'assistance-with-lodger-landlord':
          switch (homeLocation) {
            case 'england':
              homeId = 'Landlord ENG - Assistance with Tenants'
              break
            case 'wales':
              homeId = 'Landlord WALES- Assistance with Tenants'
              break
            case 'scotland':
              homeId = 'Landlord SCOTLAND- assistance with tenants'
              break
            default:
              homeId = 'Other'
              break
          }
          caseType = homeId
          break
        case 'court-procedure-private':
          switch (homeLocation) {
            case 'england':
              homeId = 'Tenant ENG- Court procedure'
              break
            case 'wales':
              homeId = 'Tenant WALES - Court procedure'
              break
            case 'scotland':
              homeId = 'Tenant SCOTLAND- Court procedure'
              break
            default:
              homeId = 'Other'
              break
          }
          caseType = homeId
          break
        case 'queries-regarding-other':
          switch (homeLocation) {
            case 'england':
              homeId = 'Landlord ENG - Assistance with Tenants'
              break
            case 'wales':
              homeId = 'Landlord WALES- Assistance with Tenants'
              break
            case 'scotland':
              homeId = 'Landlord SCOTLAND- assistance with tenants'
              break
            default:
              homeId = 'Other'
              break
          }
          caseType = homeId
          break
        default:
          break
      }

      switch (homeDetail) {
        case 'breach-of-lease':
          caseSubType = 'Breach of lease'
          break
        case 'commercial-lease-query':
          caseSubType = 'Commercial lease'
          break
        case 'disrepair':
          caseSubType = 'Disrepair'
          break
        case 'freeholder-query-management-company':
          caseSubType = 'Freeholder issue'
          break
        case 'leaseholder-purchase-process':
          caseSubType = 'Freehold purchase'
          break
        case 'ground-rent':
          caseSubType = 'Ground rent'
          break
        case 'lease-extension':
          caseSubType = 'Lease extension'
          break
        case 'leaseholder-vs-leaseholder':
          caseSubType = 'Leaseholder vs leaseholder'
          break
        case 'lease-restrictions':
          caseSubType = 'Lease restrictions, permissions & fees'
          break
        case 'Right to manage':
          caseSubType = 'Right to manage'
          break
        case 'service-charges':
          caseSubType = 'Service charge & maintenance'
          break
        case 'tribunal-court-action':
          caseSubType = 'Tribunal/court action'
          break
        case '2022-welsh-regulations':
          caseSubType = '2022 Welsh Regulations'
          break
        case 'issue-with-agent':
          caseSubType = 'Agent issue'
          break
        case 'deposits':
          caseSubType = 'Deposits'
          break
        case 'eviction-possession':
          caseSubType = 'Eviction/Possession'
          break
        case 'guarantor-issues':
          caseSubType = 'Guarantor'
          break
        case 'rent':
          caseSubType = 'Rent'
          break
        case 'tenancy-agreement':
          caseSubType = 'Tenancy agreement'
          break
        case 'dispute-between-tenants':
          caseSubType = 'Tenant vs tenant issue'
          break
        case 'tenant-vs-tenant':
          caseSubType = 'Tenant vs tenant issue'
          break
        case 'neighbour-planning-permission-uk':
          caseArea = 'England L&T/Neighbour'
          caseType = 'Neighbour Disputes - ENG'
          break
        case 'neighbour-planning-permission-wales':
          caseArea = 'Wales L&T/Neighbour'
          caseType = 'Neighbour Disputes - WALES'
          break
        case 'neighbour-planning-permission-scotland':
          caseArea = 'Scotland L&T/Neighbour'
          caseType = 'Neighbour disputes SCOTLAND'
          break
        case 'own-planning-permission':
          caseType = 'Other'
          break
        default:
          break
      }
    } // Buying path
    else if (buyingTopic) {
      switch (buyingTopic) {
        case 'discrimination':
          caseType = 'Discrimination in consumer contracts'
          break
        case 'issues-with-events':
          caseType = 'Events & Leisure'
          break
        case 'financial-matters':
          caseType = 'Financial matters'
          break
        case 'high-court':
          caseType = 'High Court proceedings eg judicial review'
          break
        case 'uk-insurance':
          caseType = 'Insurance'
          break
        case 'local-council':
          caseType = 'Local council issues'
          break
        case 'pre-contract-questions':
          caseType = 'Pre-contract/hypothetical'
          break
        case 'professional-negligence':
          caseType = 'Professional Negligence - Consumer'
          break
        case 'small-claims':
          caseType = 'Small claim court procedure & enforcement'
          break
        case 'store-purchases':
          caseType = 'Store purchases, online shopping & delivery issues'
          break
        case 'subscriptions':
          caseType = 'Subscriptions, payment & billing issues'
          break
        default:
          break
      }
      switch (buyingQuery) {
        case 'debt-advice':
          caseSubType = 'Debt Advice'
          break
        case 'financial-matters':
          caseSubType = 'Financial matters - Investments/Mortgage/Tax etc'
          break
        case 'personal-loans':
          caseSubType = 'Personal Loans'
          break
        case 'home':
          caseSubType = 'Home'
          break
        case 'motor':
          caseSubType = 'Motor'
          break
        case 'travel':
          caseSubType = 'Travel'
          break
        case 'wedding':
          caseSubType = 'Wedding'
          break
        case 'other':
          caseSubType = 'Other (e.g. pet, legal expenses)'
          break
        case 'delivery-issues':
          caseSubType = 'Delivery issues'
          break
        case 'faulty-goods':
          caseSubType = 'Faulty goods/products & returns'
          break
        case 'online-shopping':
          caseSubType = 'Online shopping'
          break
        case 'private-sale':
          caseSubType = 'Private sale'
          break
        case 'retailers-going-bust':
          caseSubType = 'Retailers going bust'
          break
        default:
          break
      }
    } else if (carsTopic) {
      switch (carsTopic) {
        case 'vehicle':
          caseType = 'Car services/purchases & car hire'
          break
        case 'dvla':
          caseType = 'DVLA Query'
          break
        case 'motoring':
          caseType = 'Motoring offences'
          break
        case 'parking':
          caseType = 'Private or council parking tickets and charges'
          break
        case 'road-traffic':
          caseType = 'Road Traffic Accident'
          break
        case 'pothole':
          caseType = 'Road Traffic Accident'
          caseSubType = 'Pothole Damage'
          break
        case 'motor-insurance':
        case 'other':
          caseType = 'Other'
          break
        default:
          break
      }
      switch (carsQuery) {
        case 'mobile-phone':
          caseSubType = 'Mobile phone offence'
          break
        case 'driving-without-care':
          caseSubType = 'Driving without due care & attention'
          break
        case 'vehicle-repairs':
          caseSubType = 'Vehicle repairs & servicing'
          break
        case 'speeding':
          caseSubType = 'Speeding'
          break
        case 'bus-taxi-lane':
          caseSubType = 'Other Traffic Penalties (eg. Bus Lane Offences, Yellow Box, Red Route)'
          break
        case 'highway-code':
          caseSubType = 'Highway Code Offences e.g driving down one way street/ cycling on pavement'
          break
        case 'drink-drug':
          caseSubType = 'Drink/Drug Driving/Death by Dangerous Driving'
          break
        case 'clean-air':
          caseSubType = 'Clean Air Charge'
          break
        case 'private-ticket':
          caseSubType = 'Private ticket'
          break
        case 'council-ticket':
          caseSubType = 'Council ticket'
          break
        case 'other-traffic':
          caseSubType = 'Other Traffic Penalties (eg. Bus Lane Offences, Yellow Box, Red Route)'
          break
        case 'toll-road':
          caseSubType = 'Toll road charge'
          break
        case 'train-station-fine':
          caseSubType = 'Train Station/Airport Parking Fines'
          break
        default:
          break
      }
    } else if (willsTopic) {
      if (willsTopic === 'northern-ireland-or-overseas') {
        caseType = 'Non-ENG, SCO & WALES queries'
      }
    }

    if (willsTopic === 'england') {
      switch (willsQuery) {
        case 'challenging-a-will':
          caseType = 'Challenging a will/intestacy ENG & WALES'
          break
        case 'estate-planning':
          caseType = 'Estate planning and writing a will ENG & WALES'
          break
        case 'financial-planning':
          caseType = 'Financial Planning'
          break
        case 'powers-of-attorney':
          caseType = 'Powers of attorney ENG & WALES'
          break
        case 'negligence':
          caseType = 'Professional Negligence - W&P'
          break
        case 'advance-directive':
          caseType = 'Advance Decision ENG & WALES'
          break
        case 'estate-administration-probate':
          caseType =
            'Estate administration, Probate issues/dealing with a will or estate ENG & WALES'
          break
        case 'other':
          caseType = 'Other'
          break
      }
    } else if (willsTopic === 'wales') {
      switch (willsQuery) {
        case 'challenging-a-will':
          caseType = 'Challenging a will/intestacy ENG & WALES'
          break
        case 'estate-planning':
          caseType = 'Estate planning and writing a will ENG & WALES'
          break
        case 'financial-planning':
          caseType = 'Financial Planning'
          break
        case 'powers-of-attorney':
          caseType = 'Powers of attorney ENG & WALES'
          break
        case 'negligence':
          caseType = 'Professional Negligence - W&P'
          break
        case 'advance-directive':
          caseType = 'Advance Decision ENG & WALES'
          break
        case 'estate-administration-probate':
          caseType =
            'Estate administration, Probate issues/dealing with a will or estate ENG & WALES'
          break
        case 'other':
          caseType = 'Other'
          break
      }
    } else if (willsTopic === 'scotland') {
      switch (willsQuery) {
        case 'challenging-a-will':
          caseType = 'Challenging a will/intestacy SCOTLAND'
          break
        case 'estate-planning':
          caseType = 'Estate planning and writing a will SCOTLAND'
          break
        case 'financial-planning':
          caseType = 'Financial Planning'
          break
        case 'powers-of-attorney':
          caseType = 'Powers of attorney SCOTLAND'
          break
        case 'negligence':
          caseType = 'Professional Negligence - W&P'
          break
        case 'advance-directive':
          caseType = 'Advance Directive - SCOTLAND'
          break
        case 'other':
          caseType = 'Other'
          break
        case 'estate-administration-confirmation':
          caseType = 'Estate administration, Probate issues/dealing with a will or estate- SCOTLAND'
          break
      }
    }
  } else if (moneyTopic) {
    switch (moneyTopic) {
      case 'claims-handling':
        caseArea = 'Claims Handling'
        break
      case 'scam-fraud':
        caseArea = 'Other'
        caseType = 'Scams & Fraud'
        break
      case 'professional-adviser':
        caseArea = 'Referrals'
        caseType = 'Non-Which? Referrals'
        break
      case 'insurance-products':
        caseArea = 'Insurance'
        break
      case 'social-care':
        caseArea = 'Social Systems'
        break
      case 'money-management':
        caseArea = 'Money Management'
        break
      default:
        break
    }
    switch (moneyQuery) {
      case 'cash-money-exchange':
        caseType = 'Banking Cash & Currency and Travel Money'
        break
      case 'overview-of-debt':
        caseType = 'Borrowing, Credit Referencing and overview of Debt'
        break
      case 'investments':
        caseType = 'Investments'
        break
      case 'mortgages':
        caseType = 'Mortgages'
        break
      case 'pensions':
        caseType = 'Pensions'
        break
      case 'savings':
        caseType = 'Savings'
        break
      case 'equity-release':
        caseType = 'Secured Later Lending - Equity Release and RIOs'
        break
      case 'tax':
        caseType = 'Tax'
        break
      case 'trusts-and-estate':
        caseType = 'Trusts & Estates'
        break
      case 'funeral-plans':
        caseType = 'Funeral Plans'
        break
      case 'cost-of-living':
        caseType = 'Cost of Living'
        break
      case 'savings-clubs':
        caseType = 'Savings Clubs'
        break
      case 'something-else':
        caseType = ''
        break
      case 'property':
        caseType = 'Property & Housing'
        break
      case 'social-care':
        caseType = 'Social Care'
        break
      case 'welfare':
        caseType = 'Welfare & Benefits'
        break
      case 'financial-ombudsman':
        caseType = 'FOS ADR and FSCS criteria'
        break
      case 'scams-fraud':
        caseType = 'Scams & Frauds - FOS'
        break
      case 'insurance-claims':
        caseType = 'Insurance Claims - FOS'
        break
      case 'poor-service':
        caseType = 'Poor Service Claims - FOS'
        break
      case 'local-authority':
        caseType = 'Non-FOS ADR claims'
        break
      case 'financial-services-compensation-scheme':
        caseType = 'Financial Services Compensation Scheme'
        break
      case 'life-insurance':
        caseType = 'Life Insurance'
        break
      case 'protection-products':
        caseType = 'Protection Products'
        break
      case 'general-insurance':
        caseType = 'General Insurance'
        break
    }
  }
  if (techTopic) {
    switch (techTopic) {
      case 'buying-advice':
        caseArea = 'Buying Advice'
        break
      case 'email-issues':
        caseArea = 'Email Issues'
        break
      case 'file-data-storage':
        caseArea = 'File, Data or Storage'
        break
      case 'general-problems':
        caseArea = 'General Computer Problems'
        break
      case 'hardware-issues':
        caseArea = 'Hardware Issues'
        break
      case 'internet-networking':
        caseArea = 'Internet & Networking'
        break
      case 'printers-ink':
        caseArea = 'Printers & Ink'
        break
      case 'security-privacy':
        caseArea = 'Security & Privacy'
        break
      case 'software-programs':
        caseArea = 'Software & Programs'
        break
      case 'usage':
        caseArea = 'Usage'
        break
    }
    switch (techQuery) {
      case 'app-usage':
        caseType = 'App usage'
        break
      case 'businesses':
        caseType = 'Businesses'
        break
      case 'legal-advice':
        caseType = 'Legal advice'
        break
      case 'meter-readings':
        caseType = 'Meter readings'
        break
      case 'online-banking':
        caseType = 'Online banking'
        break
      case 'online-selling-sites':
        caseType = 'Online selling sites'
        break
      case 'social-media':
        caseType = 'Social media'
        break
      case 'software-usage':
        caseType = 'Software usage'
        break
      case 'using-computer':
        caseType = 'Using your computer'
        break
      case 'working-with-files':
        caseType = 'Working with files'
        break
      case 'advanced-programming':
        caseType = 'Advanced programming'
        break
      case 'apps':
        caseType = 'Apps'
        break
      case 'drivers':
        caseType = 'Drivers'
        break
      case 'microsoft-office-2016-above':
        caseType = 'Microsoft Office 2016 above'
        break
      case 'microsoft-office-2016-below':
        caseType = 'Microsoft Office below 2016'
        break
      case 'operating-system':
        caseType = 'Operating system problems'
        break
      case 'password-managers':
        caseType = 'Password managers'
        break
      case 'software-installation':
        caseType = 'Software installation'
        break
      case 'software-removal':
        caseType = 'Software removal'
        break
      case 'updating-operating-system':
        caseType = 'Updating operating system'
        break
      case 'virtualisation':
        caseType = 'Virtualisation'
        break
      case 'web-browser-issues':
        caseType = 'Web browser Issues'
        break
      case 'advice-or-help':
        caseType = 'Advice or help with manufacture security updates'
        break
      case 'antivirus':
        caseType = 'Antivirus and security software'
        break
      case 'online-privacy':
        caseType = 'Online Privacy'
        break
      case 'pop-ups':
        caseType = 'Pop-ups or adverts'
        break
      case 'potential-hack':
        caseType = 'Potential hack'
        break
      case 'potential-virus':
        caseType = 'Potential Virus'
        break
      case 'scams':
        caseType = 'Scams, Incl. Phishing & scam emails'
        break
      case 'Security':
        caseType = 'Security best practices'
        break
      case 'web-browser-hijacked':
        caseType = 'Web browser has been hijacked'
        break
      case 'cartridge-problems':
        caseType = 'Cartridge / ink problems'
        break
      case 'error-messages':
        caseType = 'Error messages'
        break
      case 'misaligned-incorrect-printouts':
        caseType = 'Misaligned or incorrect print-outs'
        break
      case 'paper-jam':
        caseType = 'Paper Jam'
        break
      case 'printer-apps':
        caseType = 'Printer apps'
        break
      case 'printer-drivers':
        caseType = 'Printer drivers'
        break
      case 'printers-10':
        caseType = 'Printers (over 10 yrs old)'
        break
      case 'setting-up-printer':
        caseType = 'Setup printer'
        break
      case 'software-issues':
        caseType = 'Software Issues'
        break
      case 'unable-to-connect':
        caseType = 'unable to connect printer to network/wi-fi'
        break
      case 'unable-to-print':
        caseType = 'Unable to print or scan'
        break
      case 'choosing-broadband':
        caseType = 'Choosing broadband'
        break
      case 'extending-wifi':
        caseType = 'Extending wifi signal'
        break
      case 'home-networking':
        caseType = 'Home networking'
        break
      case 'network-configuration':
        caseType = 'Network configuration'
        break
      case 'router-setup':
        caseType = 'Router / Access point setup'
        break
      case 'router-security':
        caseType = 'Router security'
        break
      case 'slow-internet':
        caseType = 'Slow internet'
        break
      case 'vpn-issues':
        caseType = 'VPN issues'
        break
      case 'audio':
        caseType = 'Audio Equipment Battery Issues'
        break
      case 'cameras':
        caseType = 'Cameras'
        break
      case 'cordless-phones':
        caseType = 'Cordless Phones'
        break
      case 'desktops':
        caseType = "Desktop or All-In-One PC's"
        break
      case 'dvd-recorders':
        caseType = 'DVD/Video Recorders'
        break
      case 'e-book':
        caseType = 'E-Book Readers'
        break
      case 'games-consoles':
        caseType = 'Games Consoles'
        break
      case 'hardware-faults':
        caseType = 'Hardware faults'
        break
      case 'laptop-issues':
        caseType = 'Laptop issues'
        break
      case 'motor-vehicle-software':
        caseType = 'Motor Vehicle Software'
        break
      case 'printer-issues':
        caseType = 'Printer issues'
        break
      case 'smartphones':
        caseType = 'Smartphones'
        break
      case 'smart-tech':
        caseType = 'Smart Tech'
        break
      case 'smart-tv-issues':
        caseType = 'Smart TV issues'
        break
      case 'tablet-issues':
        caseType = 'Tablet issues'
        break
      case 'tv-boxes':
        caseType = 'TV Boxes'
        break
      case 'accessibility':
        caseType = 'Accessibility help'
        break
      case 'connection-problems':
        caseType = 'Connection problems'
        break
      case 'device-not-powering':
        caseType = 'Device not powering on or booting up'
        break
      case 'external-drive':
        caseType = 'External drive connection issues'
        break
      case 'help-new-device':
        caseType = 'Help setting up new device'
        break
      case 'internal-drive':
        caseType = 'Internal drive connection issues'
        break
      case 'keyboard-mouse':
        caseType = 'Keyboard, mouse or trackpad issues'
        break
      case 'locked-out':
        caseType = 'Locked out of PC'
        break
      case 'microphone':
        caseType = 'Microphone or Webcam issues'
        break
      case 'no-sound':
        caseType = 'No Sound'
        break
      case 'performance-issues':
        caseType = 'Performance issues'
        break
      case 'physical-damage':
        caseType = 'Physical damage to the device'
        break
      case 'screen-display':
        caseType = 'Screen & Display issues'
        break
      case 'slow-computers':
        caseType = 'Slow computers'
        break
      case 'usb-stick':
        caseType = 'USB stick connection issues'
        break
      case 'backing-up-external-drive':
        caseType = 'Backing up to external drive'
        break
      case 'cloud-storage':
        caseType = 'Cloud storage'
        break
      case 'data-loss':
        caseType = 'Data loss'
        break
      case 'data-transfer':
        caseType = 'Data transfer'
        break
      case 'network-storage':
        caseType = 'Network attached storage / Share'
        break
      case 'recover-data':
        caseType = 'Recover data'
        break
      case 'removing-files':
        caseType = 'Removing files'
        break
      case 'storage-issues':
        caseType = 'storage issues/freeing up space'
        break
      case 'upgrade-drive':
        caseType = 'Upgrade drive'
        break
      case 'cant-access-email':
        caseType = "Can't access email account"
        break
      case 'cant-send-email':
        caseType = "Can't Send or receive emails"
        break
      case 'contact-or-calendar':
        caseType = 'Contact or Calendar'
        break
      case 'email-account':
        caseType = 'Email Account'
        break
      case 'possible-email-breach':
        caseType = 'Possible email account breach'
        break
      case 'problems-with-email':
        caseType = 'Problems with email program'
        break
      case 'reset-password':
        caseType = 'Reset password (for email account)'
        break
      case 'spam':
        caseType = 'Spam'
        break
      case 'sync-issues':
        caseType = 'Sync Issues'
        break
      case 'all-in-one':
        caseType = 'All-in-one Computers'
        break
      case 'anti-virus':
        caseType = 'Anti Virus Software'
        break
      case 'audio-equipment':
        caseType = 'Audio Equipment'
        break
      case 'broadband':
        caseType = 'Broadband'
        break
      case 'desktop':
        caseType = 'Desktop'
        break
      case 'ebook-readers':
        caseType = 'E-Book Readers'
        break
      case 'games-console':
        caseType = 'Games Consoles'
        break
      case 'laptops':
        caseType = 'Laptops'
        break
      case 'mobile':
        caseType = 'Mobile'
        break
      case 'networking':
        caseType = 'Networking Equipment'
        break
      case 'peripherals':
        caseType = 'Peripherals'
        break
      case 'printers-scanners':
        caseType = 'Printers & Scanners'
        break
      case 'smart-tv':
        caseType = 'Smart TVs'
        break
      case 'storage-device':
        caseType = 'Storage Devices'
        break
      case 'tablet':
        caseType = 'Tablet'
        break
    }
    switch (techSystem) {
      case 'android-11':
        operatingSystem = 'Android 11'
        break
      case 'android-12':
        operatingSystem = 'Android 12'
        break
      case 'android-13':
        operatingSystem = 'Android 13'
        break
      case 'android-14':
        operatingSystem = 'Android 14'
        break
      case 'apple-15':
        operatingSystem = 'Apple iOS 15'
        break
      case 'apple-16':
        operatingSystem = 'Apple iOS 16'
        break
      case 'apple-17':
        operatingSystem = 'Apple iOS 17'
        break
      case 'chrome':
        operatingSystem = 'Chrome OS'
        break
      case 'linux':
        operatingSystem = 'Linux'
        break
      case 'mac-ventura':
        operatingSystem = 'MacOS Ventura'
        break
      case 'mac-sonoma':
        operatingSystem = 'MacOS Sonoma'
        break
      case 'mac-monterey':
        operatingSystem = 'MacOS Monterey'
        break
      case 'mac-big-sur':
        operatingSystem = 'MacOS Big Sur'
        break
      case 'windows-10':
        operatingSystem = 'Windows 10'
        break
      case 'windows-11':
        operatingSystem = 'Windows 11'
        break
      case 'other':
        operatingSystem = 'Other'
        break
    }
    switch (techDevice) {
      case 'chromebooks':
        device = 'Chromebooks'
        operatingSystem = 'Chrome OS'
        break
      case 'desktop':
        device = 'Desktop'
        break
      case 'laptop':
        device = 'Laptop'
        break
      case 'mobiles':
        device = 'Mobiles'
        break
      case 'printer':
        device = 'Printer'
        operatingSystem = 'Other'
        break
      case 'smart-tech':
        device = 'Smart Tech (Amazon Alexa etc)'
        operatingSystem = 'Other'
        break
      case 'smart-tv':
        device = 'Smart TV'
        operatingSystem = 'Other'
        break
      case 'tablet':
        device = 'Tablet'
        break
    }
  }

  const buildConflictNotes = () => {
    if (user.firstName) {
      return `${user?.firstName} ${user?.lastName} 
Address:
${user?.address?.postcode}
${user?.address?.addressLine1}
${user?.address?.addressLine2}
${user?.address?.townCity}
${user?.address?.county}
${user?.date ? 'date of death: ' + user?.date : ''}`
    }
    return ''
  }
  const sessionId = getBlaize()

  const chooseType = () => {
    switch (type) {
      case 'legal':
        return 'legal'
      case 'tech':
        return 'tech support'
      case 'money':
        return 'money'
    }
  }
  const bodyInfo = chooseType()
  const body = {
    subject: bodyInfo,
    description: describeCase,
    conflictNotes: buildConflictNotes(),
    caseArea,
    caseType,
    caseSubType,
    sessionId,
    status,
    operatingSystem,
    device,
    contactNumber: number,
  }

  // remove any empty object
  return Object.entries(body).reduce((acc, [k, v]) => (v ? { ...acc, [k]: v } : acc), {})
}

export type salesforceStateType = {
  cars: { carsTopic: string; carsQuery: string }
  legal: { legalTopic: string }
  employment: { employmentTopic: string }
  other: {
    otherTopic: string
  }
  holiday: {
    holidayTopic: string
    holidayQuery: string
  }
  home: {
    homeTopic: string
    homeQuery: string
    homeLocation: string
    homeDetail: string
  }
  buying: { buyingTopic: string; buyingQuery: string }
  money: { moneyTopic: string; moneyQuery: string }
  triage: { describeCase: string; type: string; number: string }
  initial: { user: UserPropsWithAddress }
  wills: { willsTopic: string; willsQuery: string }
  tech: { techTopic: string; techQuery: string; techSystem: string; techDevice: string }
  status?: string
}
