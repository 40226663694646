import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { BroadbandContractFlow, PayingDebtsFlow } from '../../pageFlowData'
import styles from './Broadband.module.scss'
import { data } from './data'

export const BroadbandContract: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    myMoneyHealthCheck: { financeSituation },
  } = state

  const handleTracking = (id: string) => {
    const trackingAnswer = `question | ${BroadbandContractFlow.VIEW_NAME} | ${id} | ${financeSituation}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_BROADBAND_CONTRACT', data: id })
    dispatch({ type: 'UPDATE_STEP', data: PayingDebtsFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: PayingDebtsFlow.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string) => {
    handleTracking(id)
    handleDispatch(id)
  }

  const { buttons, imgObj, title } = data

  return (
    <PillButtonPage
      buttons={buttons}
      defaultValue=""
      handleSubmit={handleSubmit}
      imgObj={imgObj}
      infoBoxData={data}
      pillButtonGroupStyle={styles.broadbandPagePillButtons}
      title={title}
    />
  )
}
