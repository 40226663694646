// A separate object for the infoBox data is used due to the typing of the borderColour attribute
import type { InfoBoxProps } from '../../../../../shared/types/toolTypes'
import styles from '../../../../../shared/utils/renderInfoBox/renderInfoBox.module.scss'

export const infoBox: InfoBoxProps = {
  heading: 'What is an early payment discount?',
  borderColour: 'blue',
}

export const data = {
  buttons: [
    {
      label: 'Yes',
      id: 'yes',
    },
    {
      label: 'No',
      id: 'no',
    },
  ],
  infoBox,
  infoBoxParagraphs: [
    {
      classname: `${styles.renderInfoBoxParagraphs}`,
      text: 'Local authority tickets: If you agree to pay the fine, you’re entitled to a 50% discount on the full cost as long as you pay it within the first 14 days. For postal parking tickets a 21-day early payment discount applies. But, if you pay the parking ticket, the council will close the case and you’ll be unable to appeal.',
    },
    {
      classname: `${styles.renderInfoBoxParagraphs}`,
      text: 'Private tickets: If you do decide to pay the ticket within 14 days, the parking company must offer you a discount of at least 40%.',
    },
    {
      text: 'Read more on early payment discounts',
      link: 'https://www.which.co.uk/consumer-rights/advice/how-to-appeal-a-parking-ticket-asBzj3e9xtIj',
    },
  ],
  imgObj: {
    alt: 'A person sitting down looking at their phone, with someone standing next to them',
    src: 'https://media.product.which.co.uk/prod/images/original/gm-5ee7caa1-dac4-4f17-8647-d56fdf8c05ed-ticket-discount.jpg',
  },
  title: 'Are you within the time limit to receive an early payment discount?',
}
