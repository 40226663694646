import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { TextAreaPage } from '../../../../shared/pages/TextAreaPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryTextAreaRef } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { validateCharCount } from '../../../../shared/utils/validateCharCount'
import { GetInTouchFlow, SellerContactFlow } from '../../pageFlowData'
import { sellerContactData } from './data'
import styles from './SellerContact.module.scss'

export const SellerContact: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    issue: { sellerContact },
  } = state

  const [issueDescriptionIsInputValid, setIssueDescriptionIsInputValid] = useState<boolean>(true)
  const SellerContactInputRef = useRef<FormEntryTextAreaRef>(null)

  const handleIssueDescriptionChange = () => {
    const value = getCleanValue(SellerContactInputRef.current?.formEntryTextarea?.value)
    dispatch({ type: 'UPDATE_SELLER_CONTACT', data: value })
  }

  const handleDispatch = () => {
    dispatch({ type: 'UPDATE_STEP', data: GetInTouchFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: GetInTouchFlow.PROGRESS_VALUE })
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${SellerContactFlow.VIEW_NAME} | PII`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleIssueDescriptionSubmit = () => {
    const value: string = getCleanValue(SellerContactInputRef.current?.formEntryTextarea?.value)
    const isValid: boolean = validateCharCount(value.length, 2500)
    if (!isValid) {
      setIssueDescriptionIsInputValid(false)
      return
    }

    setIssueDescriptionIsInputValid(true)
    handleTracking()
    handleDispatch()
  }

  const { paragraphs, imgObj, placeholderText, title } = sellerContactData

  return (
    <div className={styles.sellerContact} data-testid="seller-contact">
      <TextAreaPage
        buttonText="Next"
        defaultValue={sellerContact}
        descriptiveText={paragraphs}
        handleChange={handleIssueDescriptionChange}
        handleSubmit={handleIssueDescriptionSubmit}
        imgObj={imgObj}
        isFocused
        isInputValid={issueDescriptionIsInputValid}
        maxChars={2500}
        optional
        placeholderText={placeholderText}
        ref={SellerContactInputRef}
        title={title}
      />
    </div>
  )
}
