export const pageDataReasonNot = (state: {
  answers: {
    reasonNot: string
    reasonNotOther: string
    regardingProblem: string
  }
  user: {
    toolID: string
    userEmail: string
  }
}) => {
  const { reasonNot, reasonNotOther, regardingProblem } = state.answers
  const { toolID, userEmail } = state.user

  const questions: any = {}
  questions['question.1349123'] = toolID // TF - Impact Survey - Tool Name
  questions['question.1349096'] = regardingProblem // TF - Impact Survey - Claim made?
  questions['question.1349126'] = reasonNot // TF - Impact Survey - More on your claim experience
  questions['question.1349128'] = reasonNotOther // TF - Impact Survey - Why not claimed? - Other reason

  // return supporter
  return {
    supporter: {
      Email: userEmail,
      questions,
    },
  }
}
