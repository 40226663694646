import React from 'react'

import { ToolFeedbackAudiencePage } from '../../../shared/pages/ToolFeedbackAudiencePage'
import { toolFeedbackAudienceData } from '../../../shared/pages/ToolFeedbackAudiencePage/data'
import { ToolFeedbackPage } from '../../../shared/pages/ToolFeedbackPage'
import { toolFeedbackData } from '../../../shared/pages/ToolFeedbackPage/data/deliveryComplaintData'
import { ToolFeedbackThankYouPage } from '../../../shared/pages/ToolFeedbackThankYouPage'
import { ContactDetails } from '../PageComponents/ContactDetails'
import { DeliveryDateExplained } from '../PageComponents/DeliveryDateExplained'
import { DeliveryPermission } from '../PageComponents/DeliveryPermission'
import { DeliveryProblem } from '../PageComponents/DeliveryProblem'
import { ItemOrdered } from '../PageComponents/ItemOrdered'
import { LimitedOptions } from '../PageComponents/LimitedOptions'
import { OrderNumber } from '../PageComponents/OrderNumber'
import { SubmitSuccessful } from '../PageComponents/SubmitSuccessful'
import { WhatHappened } from '../PageComponents/WhatHappened'
import { WhatWentWrong } from '../PageComponents/WhatWentWrong'
import { WhichRetailer } from '../PageComponents/WhichRetailer'
import {
  ContactDetailsFlow,
  DeliveryDateExplainedFlow,
  DeliveryPermissionFlow,
  DeliveryProblemFlow,
  ItemOrderedFlow,
  LimitedOptionsFlow,
  OrderNumberFlow,
  SubmitSuccessfulFlow,
  ToolFeedbackAudienceFlow,
  ToolFeedbackFlow,
  ToolFeedbackThankYouFlow,
  WhatHappenedFlow,
  WhatWentWrongFlow,
  WhichRetailerFlow,
} from '../pageFlowData'

export const useRenderStep = (step: number, CRFooter: React.ReactNode) => {
  switch (step) {
    case DeliveryProblemFlow.STEP:
      return <DeliveryProblem />
    case DeliveryPermissionFlow.STEP:
      return <DeliveryPermission />
    case WhichRetailerFlow.STEP:
      return <WhichRetailer />
    case ItemOrderedFlow.STEP:
      return <ItemOrdered />
    case OrderNumberFlow.STEP:
      return <OrderNumber />
    case DeliveryDateExplainedFlow.STEP:
      return <DeliveryDateExplained />
    case WhatHappenedFlow.STEP:
      return <WhatHappened />
    case WhatWentWrongFlow.STEP:
      return <WhatWentWrong />
    case ContactDetailsFlow.STEP:
      return <ContactDetails />
    case SubmitSuccessfulFlow.STEP:
      return <SubmitSuccessful CRFooter={CRFooter} />
    case ToolFeedbackFlow.STEP:
      return (
        <ToolFeedbackPage
          pageData={toolFeedbackData}
          toolFeedbackAudienceFlowData={ToolFeedbackAudienceFlow}
          toolFeedbackFlowData={ToolFeedbackFlow}
          toolName="DeliveryComplaint"
        />
      )
    case ToolFeedbackThankYouFlow.STEP:
      return <ToolFeedbackThankYouPage toolFeedbackThankYouFlowData={ToolFeedbackThankYouFlow} />
    case ToolFeedbackAudienceFlow.STEP:
      return (
        <ToolFeedbackAudiencePage
          pageData={toolFeedbackAudienceData}
          toolFeedbackAudienceFlowData={ToolFeedbackAudienceFlow}
          toolFeedbackThankYouFlowData={ToolFeedbackThankYouFlow}
          toolName="DeliveryComplaint"
        />
      )
    case LimitedOptionsFlow.STEP:
      return <LimitedOptions />

    default:
      return <div>DeliveryComplaint Tool {CRFooter}</div>
  }
}
