const shared = {
  imgObj: {
    alt: 'Person driving a car',
    src: 'https://media.product.which.co.uk/prod/images/original/ccee6d01d803-carproblemstep1.jpg',
  },
  title: 'Which of the following best matches your query?',
}

export const offencesData = {
  buttons: [
    {
      label: 'Mobile phone/seatbelt offences',
      id: 'mobile-phone',
    },
    {
      label: 'Driving without due care & attention',
      id: 'driving-without-care',
    },
    {
      label: 'Driving without insurance, tax, MOT',
      id: 'vehicle-repairs',
    },
    {
      label: 'Speeding',
      id: 'speeding',
    },
    {
      label: 'Bus/taxi lanes',
      id: 'bus-taxi-lane',
    },
    {
      label: 'Highway code offences e.g. driving down one way street/cycling on pavement',
      id: 'highway-code',
    },
    {
      label: 'Drink/Drug driving/Death by dangerous driving',
      id: 'drink-drug',
    },
    {
      label: 'Driving without a licence',
      id: 'no-licence',
    },
  ],
  ...shared,
}

export const ticketsData = {
  buttons: [
    {
      label: 'Clean air, congestion or emissions charges',
      id: 'clean-air',
    },
    {
      label: 'Private parking ticket',
      id: 'private-ticket',
    },
    {
      label: 'Council parking ticket',
      id: 'council-ticket',
    },
    {
      label: 'TFL issued penalties',
      id: 'tfl-penalty',
    },
    {
      label: 'Other traffic penalties e.g. bus lane offences, yellow box, red route, no stop zones',
      id: 'other-traffic',
    },
    {
      label:
        'Breach of street parking restrictions, e.g. red/yellow lines, resident, blue badge, timed parking restrictions etc',
      id: 'breach-street-parking',
    },
    {
      label: 'Toll road charge',
      id: 'toll-road',
    },
    {
      label: 'Train station/Airport parking fines',
      id: 'train-station-fine',
    },
  ],
  ...shared,
}
