const sharedValues = {
  isSubmitValid: true,
}

export const toolFeedbackAudienceData = {
  title: 'Thank you for your feedback',
  standFirst:
    'Would you mind answering just four more questions? Your answers will help us understand our audience better.',
  questions: [
    {
      typeName: <const>'radio',
      ...sharedValues,
      title: 'What is your age?',
      optional: false,
      id: 'age',
      buttons: [
        { label: '12-17 years old', id: 'age-12-17', isFocused: false },
        { label: '18-24 years old', id: 'age-18-24', isFocused: false },
        { label: '25-34 years old', id: 'age-25-34', isFocused: false },
        { label: '35-44 years old', id: 'age-35-44', isFocused: false },
        { label: '45-54 years old', id: 'age-45-54', isFocused: false },
        { label: '55-64 years old', id: 'age-55-64', isFocused: false },
        { label: '65-74 years old', id: 'age-65-74', isFocused: false },
        { label: '75 years or older', id: 'age-75-plus', isFocused: false },
        { label: 'Prefer not to say', id: 'prefer-not-age', isFocused: false },
      ],
    },
    {
      typeName: <const>'radio',
      ...sharedValues,
      title: 'What is your gender?',
      optional: false,
      id: 'gender',
      buttons: [
        { label: 'Female', id: 'female', isFocused: false },
        { label: 'Male', id: 'male', isFocused: false },
        { label: 'Non-binary', id: 'non-binary', isFocused: false },
        { label: 'Prefer not to say', id: 'prefer-not-gender', isFocused: false },
      ],
    },
    {
      typeName: <const>'formEntry',
      displayText: 'What is the first half of your postcode?',
      id: 'postcode-half',
      optional: true,
      srLabel: 'postcode-half',
      type: 'text',
      inputStateValue: '',
      ...sharedValues,
    },
    {
      typeName: <const>'radio',
      ...sharedValues,
      title: 'Which of these best describes your ethnic group?',
      optional: false,
      id: 'ethnicity',
      buttons: [
        { label: 'Asian or Asian British', id: 'asian-british', isFocused: false },
        {
          label: 'Black, Black British, Caribbean or African',
          id: 'black-british-caribbean-african',
          isFocused: false,
        },
        { label: 'Mixed or multiple ethnic groups', id: 'mixed-multiple-ethnic', isFocused: false },
        { label: 'White', id: 'white', isFocused: false },
        { label: 'Other ethnic group', id: 'other', isFocused: false },
        { label: 'Prefer not to say', id: 'prefer-not-ethnic', isFocused: false },
      ],
    },
  ],
}
