import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { PageWithText } from '../../../../shared/components/PageWithText'
import { FormEntryPage } from '../../../../shared/pages/FormEntryPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryInputRef, SingleInputState } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { HowMuchFlow, WhichDealerFlow } from '../../pageFlowData'
import { whichDealerData } from './data'
import styles from './WhichDealer.module.scss'

export const WhichDealer: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    car: { whichDealer, dealerOfferedSolution },
  } = state

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })
  const WhichDealerInput = useRef<FormEntryInputRef>(null)

  const getValue = () => {
    return cleanText(WhichDealerInput?.current?.formEntryInput?.value || '')
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${WhichDealerFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_WHICH_DEALER', data: value })
    dispatch({ type: 'UPDATE_STEP', data: HowMuchFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: HowMuchFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    const value = getValue()
    const isValid: boolean = isNotEmpty(value)

    setInputData({
      renderError: !isValid,
      value: value,
    })

    if (isValid) {
      handleTracking(value)
      handleDispatch(value)
    }
  }

  const chooseData = () => {
    if (dealerOfferedSolution === 'yes') {
      return whichDealerData.dealerOfferedSolution
    } else if (dealerOfferedSolution === 'no') {
      return whichDealerData.dealerDidNotOfferSolution
    }
    return whichDealerData.lessThan30Days
  }

  const { bodyCopy, title, standFirst } = chooseData()
  const { renderError, value: errorValue } = inputData
  const { imgObj, standFirst: formStandFirst, title: formTitle } = whichDealerData

  return (
    <PageWithText bodyCopy={bodyCopy} standFirst={standFirst} title={title}>
      <div className={styles.whichDealerPage}>
        <FormEntryPage
          buttonText="Next"
          defaultValue={whichDealer}
          fieldData={{
            renderError: renderError,
            value: errorValue,
          }}
          handleSubmit={handleSubmit}
          id="which-dealer"
          imgObj={imgObj}
          optional={false}
          placeholderText=""
          ref={WhichDealerInput}
          standFirst={formStandFirst}
          testId="which-dealer"
          title={formTitle}
        />
      </div>
    </PageWithText>
  )
}
