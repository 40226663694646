import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { FormEntryPage } from '../../../../shared/pages/FormEntryPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryInputRef, SingleInputState } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { WhatWorkFlow, WorkBeganFlow } from '../../pageFlowData'
import { whatWorkData } from './data'

export const WhatWork: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    work: { work: workCarriedOut },
  } = state

  const WhatWorkInput = useRef<FormEntryInputRef>(null)

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })

  const getValue = () => {
    return cleanText(WhatWorkInput?.current?.formEntryInput?.value || '')
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${WhatWorkFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_WORK', data: value })
    dispatch({ type: 'UPDATE_STEP', data: WorkBeganFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: WorkBeganFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    const value = getValue()
    setInputData({
      renderError: false,
      value: value,
    })

    handleTracking(value)
    handleDispatch(value)
  }

  const { imgObj, title, placeholderText } = whatWorkData

  return (
    <FormEntryPage
      buttonText="Next"
      defaultValue={workCarriedOut}
      fieldData={{
        renderError: inputData.renderError,
        value: inputData.value,
      }}
      handleSubmit={handleSubmit}
      id="what-work"
      imgObj={imgObj}
      isFocused
      optional={true}
      placeholderText={placeholderText}
      ref={WhatWorkInput}
      testId="what-work"
      title={title}
    />
  )
}
