export const sellerContactData = {
  imgObj: {
    src: 'https://media.product.which.co.uk/prod/images/original/gm-f1967533-031b-4cd0-befd-141264303638-seller-contact.jpg',
    alt: 'Two people talking to someone behind a desk',
  },
  placeholderText:
    "Eg. 'I emailed them on the 1st October 2021 to ask for a refund, but they never replied'.",
  title:
    'Please describe any contact you have had with the retailer/provider to try to resolve things',
  paragraphs: [
    'What you write here will appear in the final letter of claim, so please be as clear as possible including dates.',
    'Please tell us what contact you have had with the retailer/provider to try to resolve this already:',
  ],
}
