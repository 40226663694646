export const scamSharerFeedbackSurveyPageData = (state: {
  toolFeedback: {
    purposeOfTool: string
    hadInfoRequired: string
    comfortableSharingInfo: string
    amountQuestionsAppropriate: string
    understoodInfoRequired: string
    answersAccurate: string
    reportingBenefitsClear: string
    ratingReasons: string
    suggestedChanges: string
  }
  email: string
  toolName: string
}) => {
  const {
    purposeOfTool,
    hadInfoRequired,
    comfortableSharingInfo,
    amountQuestionsAppropriate,
    understoodInfoRequired,
    answersAccurate,
    reportingBenefitsClear,
    ratingReasons,
    suggestedChanges,
  } = state.toolFeedback
  const { toolName, email } = state

  const questions: any = {}
  questions['question.874976'] = purposeOfTool
  questions['question.874977'] = hadInfoRequired
  questions['question.874989'] = comfortableSharingInfo
  questions['question.874987'] = amountQuestionsAppropriate
  questions['question.874988'] = understoodInfoRequired
  questions['question.874990'] = answersAccurate
  questions['question.874991'] = reportingBenefitsClear
  questions['question.874992'] = ratingReasons
  questions['question.1182650'] = suggestedChanges

  questions['question.1182651'] = toolName

  return {
    supporter: {
      Email: email,
      questions,
    },
  }
}
