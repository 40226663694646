export const deliveryPermissionData = {
  deliveryPermissionButtons: [
    {
      label: 'Yes',
      id: 'yes',
    },
    {
      label: 'No',
      id: 'no',
    },
  ],
  title:
    'Did you give your permission to leave the parcel in a specific location or with a neighbour?',
  image: {
    imgSrc:
      'https://media.product.which.co.uk/prod/images/original/gm-cc32d181-0367-4189-84e4-b882001e3a4a-deliverycomplaintstep2.jpg',
    imgAlt: 'Person holding a phone displaying a map',
  },
}
