import type { hasAnErrorProps } from '../../types/toolTypes'
import { isNotEmpty } from '../../utils/isNotEmpty/isNotEmpty'
import { validateEmail } from '../../utils/validateEmail/validateEmail'
import { validateHalfPostcode } from '../../utils/validateHalfPostcode/validateHalfPostcode'
import { validateNumber } from '../../utils/validateNumber/validateNumber'
import { validatePhoneNumber } from '../../utils/validatePhoneNumber/validatePhoneNumber'
import { validatePostcode } from '../../utils/validatePostcode/validatePostcode'

export const hasAnError = ({
  value,
  id,
  optional,
  setError,
  setErrorMessage,
  errorMessageText,
}: hasAnErrorProps) => {
  const isEmpty = !isNotEmpty(value)
  if (!optional) {
    if (isEmpty) {
      setError(isEmpty)
      if (id === 'addressSearch') {
        setErrorMessage('Please enter a valid postcode')
      } else {
        setErrorMessage('Please fill out this field to continue')
      }
    } else if ((id === 'firstName' || id === 'lastName') && !/^[a-z'-]+$/i.test(value)) {
      setError(true)
      setErrorMessage('Please only use letters, hyphens or spaces')
    } else if (id === 'email' && !validateEmail(value)) {
      setError(true)
      setErrorMessage('Please enter a valid email address')
    } else if (id === 'phoneNumber' && !validatePhoneNumber(value)) {
      setError(true)
      setErrorMessage('Please enter a valid phone number')
    } else if ((id === 'postcode' || id === 'addressSearch') && !validatePostcode(value)) {
      setError(true)
      setErrorMessage('Please enter a valid postcode')
    } else if (id === 'vehicle-reg' && value.length > 10) {
      setError(true)
      setErrorMessage('Please enter a valid vehicle registration')
    } else if (id === 'reg-number' && value === 'reg-not-found') {
      setError(true)
      setErrorMessage('Registration number not found, please try another')
    } else if (id === 'reg-number' && value === 'reg-invalid') {
      setError(true)
      setErrorMessage('Please enter a valid UK vehicle registration number')
    } else if (id === 'number' && !validateNumber(value)) {
      setError(true)
      setErrorMessage('Please enter a valid number')
    } else {
      setError(false)
    }
    if (errorMessageText) {
      setError(true)
      setErrorMessage(errorMessageText)
    }
  } else {
    setError(false)
    if (id === 'postcode-half' && !isEmpty && !validateHalfPostcode(value)) {
      setError(true)
      setErrorMessage('Please add between 1 and 4 characters')
    } else if (id === 'phoneNumber' && !isEmpty && !validatePhoneNumber(value)) {
      setError(true)
      setErrorMessage('Please enter a valid phone number')
    }
  }
}
