export const reducedStressData = {
  radioData: {
    buttons: [
      {
        label: 'Yes, a little',
        id: 'yes-a-little',
        isFocused: false,
      },
      {
        label: 'Yes, some',
        id: 'yes-some',
        isFocused: false,
      },
      { label: 'Yes, a lot', id: 'yes-a-lot', isFocused: false },
      { label: 'No, it made no difference', id: 'no-difference', isFocused: false },
      {
        label: "No, not applicable - I wasn't experiencing worry / stress",
        id: 'not-applicable',
        isFocused: false,
      },
      { label: "Don't know / not sure", id: 'not-sure', isFocused: false },
    ],
    isSubmitValid: true,
    id: 'reduced-stress',
  },
  textareaData: { id: 'more-about-experience', isInputValid: true, isOptional: true },
}
