import type { InfoBoxProps } from '../../../../../shared/types/toolTypes'

export const data = {
  buttons: [
    {
      label: 'Yes',
      id: 'yes',
    },
    {
      label: 'No',
      id: 'no',
    },
  ],
  title: 'Do you receive benefits and financial support based on your income?',
  infoBox: {
    heading: 'What are income related benefits?',
    borderColour: 'blue',
  } as InfoBoxProps,
  infoBoxParagraphs: [
    {
      text: 'Income related benefits include Income Support, Pension Credit, Housing Benefit, Council Tax Benefit, Job Seekers Allowance (income based element) and Employment Support Allowance (income based element).',
    },
  ],
  imgObj: {
    alt: 'Two people talking over a counter',
    src: 'https://media.product.which.co.uk/prod/images/original/0c7b5c796f7e-benefits.jpg',
  },
}
