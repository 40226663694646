export const toolFeedbackData = {
  title: 'Your feedback on the scam sharer tool',
  standFirst: 'Your feedback will help us improve the experience for others.',
  radioQuestions: [
    {
      buttons: [
        { label: 'Strongly agree', id: 'strongly-agree-1', isFocused: false },
        { label: 'Agree', id: 'agree-1', isFocused: false },
        { label: 'Neither agree nor disagree', id: 'neither-1', isFocused: false },
        { label: 'Disagree', id: 'disagree-1', isFocused: false },
        { label: 'Strongly disagree', id: 'strongly-disagree-1', isFocused: false },
      ],
      isSubmitValid: true,
      id: 'purposeOfTool',
      title: 'The purpose of the tool was clear',
    },
    {
      buttons: [
        { label: 'Strongly agree', id: 'strongly-agree-2', isFocused: false },
        { label: 'Agree', id: 'agree-2', isFocused: false },
        { label: 'Neither agree nor disagree', id: 'neither-2', isFocused: false },
        { label: 'Disagree', id: 'disagree-2', isFocused: false },
        { label: 'Strongly disagree', id: 'strongly-disagree-2', isFocused: false },
      ],
      isSubmitValid: true,
      id: 'hadInfoRequired',
      title: 'I had the information that was required to answer all the questions to hand',
    },
    {
      buttons: [
        { label: 'Strongly agree', id: 'strongly-agree-3', isFocused: false },
        { label: 'Agree', id: 'agree-3', isFocused: false },
        { label: 'Neither agree nor disagree', id: 'neither-3', isFocused: false },
        { label: 'Disagree', id: 'disagree-3', isFocused: false },
        { label: 'Strongly disagree', id: 'strongly-disagree-3', isFocused: false },
      ],
      isSubmitValid: true,
      id: 'comfortableSharingInfo',
      title: 'I felt comfortable sharing information about my scam experience using this tool',
    },
    {
      buttons: [
        { label: 'Strongly agree', id: 'strongly-agree-4', isFocused: false },
        { label: 'Agree', id: 'agree-4', isFocused: false },
        { label: 'Neither agree nor disagree', id: 'neither-4', isFocused: false },
        { label: 'Disagree', id: 'disagree-4', isFocused: false },
        { label: 'Strongly disagree', id: 'strongly-disagree-4', isFocused: false },
      ],
      isSubmitValid: true,
      id: 'amountQuestionsAppropriate',
      title: 'The amount of questions I was asked felt appropriate',
    },
    {
      buttons: [
        { label: 'Strongly agree', id: 'strongly-agree-5', isFocused: false },
        { label: 'Agree', id: 'agree-5', isFocused: false },
        { label: 'Neither agree nor disagree', id: 'neither-5', isFocused: false },
        { label: 'Disagree', id: 'disagree-5', isFocused: false },
        { label: 'Strongly disagree', id: 'strongly-disagree-5', isFocused: false },
      ],
      isSubmitValid: true,
      id: 'understoodInfoRequired',
      title: 'I understood what information I was asked to provide',
    },
    {
      buttons: [
        { label: 'Strongly agree', id: 'strongly-agree-6', isFocused: false },
        { label: 'Agree', id: 'agree-6', isFocused: false },
        { label: 'Neither agree nor disagree', id: 'neither-6', isFocused: false },
        { label: 'Disagree', id: 'disagree-6', isFocused: false },
        { label: 'Strongly disagree', id: 'strongly-disagree-6', isFocused: false },
      ],
      isSubmitValid: true,
      id: 'answersAccurate',
      title: 'I feel confident that the answers I provided were accurate',
    },
    {
      buttons: [
        { label: 'Strongly agree', id: 'strongly-agree-7', isFocused: false },
        { label: 'Agree', id: 'agree-7', isFocused: false },
        { label: 'Neither agree nor disagree', id: 'neither-7', isFocused: false },
        { label: 'Disagree', id: 'disagree-7', isFocused: false },
        { label: 'Strongly disagree', id: 'strongly-disagree-7', isFocused: false },
      ],
      isSubmitValid: true,
      id: 'reportingBenefitsClear',
      title: 'The benefits of reporting the scam using this tool were clear',
    },
  ],
  textareaQuestions: [
    {
      title: 'Please provide us with the reasons for your ratings above',
      id: 'ratingReasons',
      defaultValue: '',
      placeholderText: '',
      isInputValid: true,
      isOptional: true,
    },
    {
      title: 'If you could change anything about this tool, what would it be?',
      id: 'suggestedChanges',
      defaultValue: '',
      placeholderText: '',
      isInputValid: true,
      isOptional: true,
    },
  ],
}
