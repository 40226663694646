import type { FunctionComponent } from 'react'
import React from 'react'
import { Button } from '@which/seatbelt'

import globalStyles from '../../../shared/styles/GlobalStyles.module.scss'
import { ArticleCTA } from '../../components/ArticleCTA'
import { PageWithText } from '../../components/PageWithText'
import type { ArticleCTAPageProps } from '../../types/toolTypes'
import styles from './ArticleCTAPage.module.scss'

export const ArticleCTAPage: FunctionComponent<ArticleCTAPageProps> = ({
  articleHeading,
  articleLink,
  articleTitle,
  bodyCopy,
  ctaData,
  handleSubmit,
  standFirst,
  title,
  buttonText,
  handleButtonSubmit,
}) => {
  return (
    <div data-testid="article-cta-page">
      <PageWithText bodyCopy={bodyCopy} standFirst={standFirst} title={title} />
      {ctaData && articleLink && (
        <div className={(globalStyles.spacing15Top, globalStyles.maxWidth)}>
          <ArticleCTA
            {...ctaData}
            handleSubmit={handleSubmit}
            headline={articleHeading}
            link={articleLink}
            title={articleTitle}
          />
        </div>
      )}

      {buttonText && handleButtonSubmit && (
        <Button
          appearance="primary"
          className={styles.articleCTAPageButton}
          data-testid="article-cta-button"
          id={buttonText}
          // @ts-expect-error - needs investigation and fix
          onClick={(event: MouseEvent) => {
            event.preventDefault()
            handleButtonSubmit()
          }}
        >
          {buttonText}
        </Button>
      )}
    </div>
  )
}
