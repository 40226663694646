import type { FunctionComponent } from 'react'
import React, { useState } from 'react'
import { Button, TooltipWithButton } from '@which/seatbelt'

import classnames from 'classnames'

import type { PillButtonProps } from '../../types/toolTypes'
import styles from './PillButtonGroup.module.scss'

export const PillButtonGroup: FunctionComponent<PillButtonProps> = ({
  buttons,
  className,
  defaultValue,
  handleSubmit,
  fitContent,
}) => {
  const [currentClicked, setCurrentClicked] = useState('')
  return (
    <div
      className={classnames(styles.pillButtonGroup, className, {
        [styles.horizontalElements]: buttons?.length === 2 || buttons?.length === 3,
        [styles.pillButtonGroupFitContent]: fitContent,
      })}
    >
      {buttons.map((button, index) => {
        const { label, id, tooltip, tooltipTitle } = button
        return (
          <Button
            appearance="secondary"
            className={classnames(styles.pillButtonGroupItem, {
              [styles.pillButtonGroupItemSelected]: currentClicked === label || defaultValue === id,
              [styles.pillButtonGroupItemHasTooltip]: tooltip,
            })}
            data-testid="tool-pillbuttons"
            id={id}
            key={label}
            // @ts-expect-error - needs investigation and fix
            onClick={(event: MouseEvent) => {
              const target = event.target as Element
              event.preventDefault()
              setCurrentClicked(label)
              handleSubmit(target.id, index)
            }}
          >
            {label}
            {tooltip && (
              <div className={classnames(styles.pillButtonGroupItemTooltip)}>
                <TooltipWithButton
                  ariaLabel=""
                  contents={tooltip}
                  title={tooltipTitle || 'Explanation'}
                />
              </div>
            )}
          </Button>
        )
      })}
    </div>
  )
}
