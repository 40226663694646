import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { AttemptedRepairFlow, EntitlementFlow } from '../../pageFlowData'
import { data } from './data'

export const AttemptedRepair: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    issue,
    product,
    retailer,
  } = state

  const handleTracking = (id: string) => {
    const trackingAnswer = `question | ${AttemptedRepairFlow.VIEW_NAME} | ${id}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_RETAILER_ATTEMPTEDRESOLVEITEM', data: id === 'yes' })
    dispatch({ type: 'UPDATE_STEP', data: EntitlementFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: EntitlementFlow.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string) => {
    handleTracking(id)
    handleDispatch(id)
  }

  const chooseId = () => {
    const resolveItemValue = retailer.attemptedResolveItem
    /* attemptedResolveItem starts off as null, so we must do the below check, 
     otherwise the 'no' field will always be selected on first load */
    return resolveItemValue ? 'yes' : resolveItemValue !== null ? 'no' : ''
  }

  const { imageAlt } = data
  const { title, image } =
    product.type !== 'product'
      ? issue.shortDesc.includes('damagedDevice')
        ? data.damagedDevice
        : data.content
      : data.product

  return (
    <PillButtonPage
      buttons={data.buttons}
      defaultValue={chooseId()}
      handleSubmit={handleSubmit}
      imgObj={{
        alt: imageAlt,
        src: image,
      }}
      title={title}
    />
  )
}
