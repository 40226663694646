import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PriceRiseCalculationPage } from '../../../../shared/pages/PriceRiseCalculationPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { AllFlows } from '../../pageFlowData'
import { ctaBlockData } from './data'

export const PaymentPrediction: FunctionComponent = () => {
  const { state } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    priceRise: {
      howMuch,
      cpiAmount,
      providerSelected: { multiplier },
    },
  } = state
  const currentMonthlyPayment = parseFloat(howMuch)
  const totalIncreaseDecimal = parseFloat(cpiAmount) / 100 + parseFloat(multiplier) / 100
  const newMonthlyPayment = currentMonthlyPayment + currentMonthlyPayment * totalIncreaseDecimal
  const differencePerMonth = newMonthlyPayment - currentMonthlyPayment
  const differencePerYear = differencePerMonth * 12

  const resultData = [
    {
      text: 'New monthly price',
      amount: newMonthlyPayment.toFixed(2),
    },
    {
      text: 'Difference per month',
      amount: differencePerMonth.toFixed(2),
    },
    {
      text: 'Difference per year',
      amount: differencePerYear.toFixed(2),
    },
  ]

  return (
    <div data-testid="payment-prediction-page">
      <PriceRiseCalculationPage
        backButtonData={{
          toolName: entryToolName,
          allFlows: AllFlows,
          text: 'Recalculate',
        }}
        ctaBlockData={ctaBlockData}
        imgObj={{
          alt: '',
          src: 'https://media.product.which.co.uk/prod/images/original/132cc7879a64-price-rise-calc-tool-img.jpg',
        }}
        resultData={resultData}
        standfirst={
          "Based on your current payment, it's likely that you'll pay the following from April 2024:"
        }
        terms="This is an estimate based on our current knowledge of broadband and mobile provider annual price rise policies. Look out for communications from your provider which will confirm any actual price rise. Please check your own contract for any particular deals or discounts which may apply to you."
        title="Your results"
      />
    </div>
  )
}
