export const whatProductData = {
  productData: {
    title: 'What product did you purchase?',
    placeHolder: 'e.g. TV, fridge freezer',
    imgObj: {
      alt: 'A phone with a picture of a fridge',
      src: 'https://media.product.which.co.uk/prod/images/original/gm-5b91a550-62d7-4e5f-9f95-8a4bee1588d4-what-product-2.jpg',
    },
  },
  serviceData: {
    title: 'What service did you purchase?',
    placeHolder: 'e.g. Carpet fitting',
    imgObj: {
      alt: 'Two people plastering a wall',
      src: 'https://media.product.which.co.uk/prod/images/original/gm-d4fec00c-9d87-40b3-8711-e40319f8569f-380x200.jpg',
    },
  },
}
