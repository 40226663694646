export const whatProblemData = {
  title: 'What problems did you experience with the work on your property?',
  imgObj: {
    src: 'https://media.product.which.co.uk/prod/images/original/0e1e850db056-step9.jpg',
    alt: 'Two people standing looking shocked over a broken table',
  },
  note: [''],
  descriptiveText: [
    'In a few words, please tell us how the work did not fulfil what they promised and therefore breached their contract with you, the buyer. What you write here will appear in the final letter of claim, so please be as clear as possible, including dates.',
  ],
  placeholderText: '',
}
