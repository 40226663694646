import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { BenefitsFlow, PropertyPaymentFlow } from '../../pageFlowData'
import { data } from './data'
import styles from './PropertyPayment.module.scss'

export const PropertyPayment: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    myMoneyHealthCheck: { propertyPayment, financeSituation },
  } = state

  const handleTracking = (id: string) => {
    const trackingAnswer = `question | ${PropertyPaymentFlow.VIEW_NAME} | ${id} | ${financeSituation}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_PROPERTY_PAYMENT', data: id })
    dispatch({ type: 'UPDATE_STEP', data: BenefitsFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: BenefitsFlow.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string) => {
    handleTracking(id)
    handleDispatch(id)
  }

  const { buttons, imgObj, title } = data

  return (
    <PillButtonPage
      buttons={buttons}
      defaultValue={propertyPayment ? propertyPayment : ''}
      handleSubmit={handleSubmit}
      imgObj={imgObj}
      infoBoxData={data}
      pillButtonGroupStyle={styles.propertyPaymentPagePillButtons}
      title={title}
    />
  )
}
