export const companyContactData = {
  packageTitle: 'Did you contact the holiday provider?',
  nonPackageTitle: 'Did you complain about the holiday already?',
  imgObj: {
    alt: 'A distressed man using a phone and a frustrated woman',
    src: 'https://media.product.which.co.uk/prod/images/original/gm-de1e348d-2481-4ebb-8ca4-0025b537ea55-holiday-complaint7.jpg',
  },
  note: [
    'What you write here will appear in the final letter of the claim, so please be as clear as possible, including dates.',
  ],
  packageNote:
    'Please note: package holiday laws offer greater protections for consumers who lodge complaints while they are on holiday, so complaining afterwards may be less successful.',
  placeholderText:
    'Eg. I emailed them on the 1st October 2021 complaining about the poor state of my hotel room.',
  packageDescriptiveText: [
    'Did you complain about the holiday already? Please tell us what contact you have had with the provider detailing the problems you experienced:',
  ],
  nonPackageDescriptiveText: [
    'Did you complain to the service provider already? Please tell us what contact you have had with the service provider detailing the problems you experienced:',
  ],
}
