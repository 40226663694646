import { ADDRESS_API } from './env'

export const retrieveAddress = ({
  addressId,
  setAddressSearchError,
  setShowAddressFields,
  setShowAddressSearchFields,
}: retrieveAddressType) => {
  const Field1Format = ''
  const url = encodeURI(`${ADDRESS_API()}/Retrieve/${addressId}/${Field1Format}`)
  const http = new XMLHttpRequest()
  http.open('GET', url, true)
  http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
  http.onreadystatechange = () => {
    if (http.readyState === 4 && http.status === 200) {
      const response = JSON.parse(http.responseText).data
      if (response.Items.length === 1 && typeof response.Items[0].Error != 'undefined') {
        setAddressSearchError('Something went wrong')
        setShowAddressFields(false)
      } else {
        if (response.Items.length === 0) {
          setAddressSearchError('Sorry, there were no results')
          setShowAddressFields(false)
        } else {
          const [addressItems] = response.Items
          const { Line1, Province, PostalCode, City, Street, ProvinceName, Company } = addressItems
          const addressLine1 = document.getElementById('addressLine1-input') as HTMLInputElement
          const addressLine2 = document.getElementById('addressLine2-input') as HTMLInputElement
          const townCity = document.getElementById('townCity-input') as HTMLInputElement
          const county = document.getElementById('county-input') as HTMLInputElement
          const postcode = document.getElementById('postcode-input') as HTMLInputElement
          setShowAddressFields(true)
          setShowAddressSearchFields(false)
          addressLine1.value = Company ? `${Company}, ${Line1}` : Line1
          addressLine2.value = Street
          townCity.value = City
          county.value = Province || ProvinceName
          postcode.value = PostalCode
        }
      }
    }
  }

  http.send()
}

type retrieveAddressType = {
  addressId: string
  setAddressSearchError: (addressError: string) => any
  setShowAddressFields: (showAddressFieldsBool: boolean) => any
  setShowAddressSearchFields: (showAddressSearchBool: boolean) => any
}
