import type { FunctionComponent } from 'react'
import React from 'react'
import { Button, GridItem, Picture, Typography } from '@which/seatbelt'

import styles from '../ResultsPage.module.scss'

export const DownloadCard: FunctionComponent<Props> = ({
  downloadButtonText,
  downloadImage,
  downloadStandfirst,
  downloadTitle,
  openModalHandler,
}) => (
  <GridItem
    className={styles.downloadCard}
    columnStart={{ medium: 7, large: 7 }}
    span={{ medium: 5, large: 5 }}
  >
    <div className={styles.boxedCard}>
      <Typography
        className="sb-padding-bottom-4"
        data-testid="generic-card-title"
        tag="h4"
        textStyle="title-400"
      >
        {downloadTitle}
      </Typography>

      <Picture className={styles.downloadImage} src={downloadImage} />

      <Typography className={styles.downloadText} textStyle="body">
        {downloadStandfirst}
      </Typography>

      <Button
        appearance="primary"
        className={styles.downloadButton}
        data-testid="download-pdf-button"
        onClick={openModalHandler}
      >
        {downloadButtonText}
      </Button>
    </div>
  </GridItem>
)

type Props = {
  downloadTitle: string
  downloadImage: string
  downloadStandfirst: string
  downloadButtonText: string
  openModalHandler: () => any
}
