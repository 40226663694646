import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { ImpactSurveyPage } from '../../../../shared/pages/ImpactSurveyPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { RadiosSelected } from '../../../../shared/types/toolTypes'
import { NoWhichToolFlow } from '../../pageFlowData'
import {
  broadbandData,
  deliveryData,
  faultyGoodsData,
  flightData,
  holidayData,
  homeImprovementData,
  packageBankAccountData,
  parkingData,
  section75Data,
} from './data'

export const WhatWasOutcome: FunctionComponent = () => {
  const { dispatch, state } = useContext(ToolContext)
  const {
    answers: { whatWasOutcome, letterSuccess },
    user: { toolID },
  } = state

  const mergeStateArrays = [...(whatWasOutcome || []), ...(letterSuccess || [])]
  const [radiosSelected, setRadiosSelected] = useState<RadiosSelected>(mergeStateArrays)
  const textInputRef = useRef<HTMLInputElement>(null)

  const chooseDataset = () => {
    switch (toolID) {
      case 'FaultyGoodsTool':
        return faultyGoodsData.radioQuestions
      case 'Section75':
        return section75Data.radioQuestions
      case 'HolidayComplaint':
        return holidayData.radioQuestions
      case 'ParkingTicket':
        return parkingData.radioQuestions
      case 'DeliveryComplaint':
        return deliveryData.radioQuestions
      case 'FlightDelayCancellationCompensation':
        return flightData.radioQuestions
      case 'MisSoldPackagedBankAccount':
        return packageBankAccountData.radioQuestions
      case 'BroadbandSpeed':
        return broadbandData.radioQuestions
      case 'HomeImprovement':
        return homeImprovementData.radioQuestions
      default:
        return faultyGoodsData.radioQuestions
    }
  }

  const chosenDataSet = chooseDataset()

  const radioQuestions = chosenDataSet.map((question) => {
    const buttons = question.buttons.map((button: any) => {
      if (button.textbox) {
        return {
          ...button,
          textbox: { ...button.textbox, reference: textInputRef },
        }
      }
      return button
    })

    return { ...question, buttons: buttons }
  })

  const handleDispatch = (letterSuccessAnswer, whatWasOutcomeAnswer) => {
    dispatch({ type: 'UPDATE_LETTER_SUCCESS', data: [letterSuccessAnswer] })
    dispatch({ type: 'UPDATE_WHAT_WAS_OUTCOME', data: [whatWasOutcomeAnswer] })
    dispatch({ type: 'UPDATE_STEP', data: NoWhichToolFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: NoWhichToolFlow.PROGRESS_VALUE })
  }

  const handleSubmit = (validationPassed: boolean) => {
    // All radio buttons have been selected
    if (validationPassed) {
      const optionalTextInput = textInputRef.current?.value || ''

      const radios: any = []
      radiosSelected.forEach((radio) => {
        const { groupId } = radio
        let newRadio = radio
        if (groupId !== 'letter-contribution') {
          newRadio = { ...newRadio, textboxAnswer: optionalTextInput }
        }
        radios.push(newRadio)
      })

      const letterPosition = radios[0].groupId === 'letter-contribution'
      const letterSuccessAnswer = letterPosition ? radios[0] : radios[1]
      const whatWasOutcomeAnswer = letterPosition ? radios[1] : radios[0]

      handleDispatch(letterSuccessAnswer, whatWasOutcomeAnswer)
    }
  }

  return (
    <>
      <ImpactSurveyPage
        handleSubmit={handleSubmit}
        nextButtonText="Next"
        radioQuestions={radioQuestions}
        radiosSelected={radiosSelected}
        setRadiosSelected={setRadiosSelected}
      />
    </>
  )
}
