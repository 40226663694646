import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import globalStyles from '../../../../shared/styles/GlobalStyles.module.scss'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { ClaimCompensationFlow, WhatLikeToDoFlow } from '../../pageFlowData'
import { data } from './data'

export const WhatToDo: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    claim: { whatLikeToDo },
  } = state

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_WHAT_LIKE_TO_DO', data: value })
    dispatch({ type: 'UPDATE_STEP', data: ClaimCompensationFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: ClaimCompensationFlow.PROGRESS_VALUE })
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${WhatLikeToDoFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = (id: string) => {
    handleDispatch(id)
    handleTracking(id)
  }

  const { buttons, imgObj, title } = data

  return (
    <div className={globalStyles.fullWidthButton} data-testid="what-to-do">
      <PillButtonPage
        buttons={buttons}
        defaultValue={whatLikeToDo}
        fitContent
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        title={title}
      />
    </div>
  )
}
