import type { InfoBoxProps } from '../../../../../shared/types/toolTypes'

// A separate object for the infoBox data is used due to the typing of the borderColour attribute
export const infoBoxData: InfoBoxProps = {
  heading: 'Your personal data',
  borderColour: 'blue',
}

export const lostMoneyData = {
  infoBox: infoBoxData,
  buttons: [
    {
      label: 'Yes',
      id: 'yes',
    },
    {
      label: 'No',
      id: 'no',
    },
  ],
}
