import type { Reducer } from 'react'

import { initialSliceReducer } from '../../../shared/state/reducers'
import type { Action, State } from '../../../shared/state/toolStateTypes'
import { combineReducers } from '../../../shared/utils/combineReducers'

export const answersSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_REGARDING_PROBLEM':
      return {
        ...state,
        regardingProblem: data,
      }
    case 'UPDATE_WAS_SUCCESSFUL':
      return {
        ...state,
        wasSuccessful: data,
      }
    case 'UPDATE_WHAT_WAS_OUTCOME':
      return {
        ...state,
        whatWasOutcome: data,
      }
    case 'UPDATE_OUTCOME_AMOUNT':
      return {
        ...state,
        outcomeAmount: data,
      }
    case 'UPDATE_LETTER_SUCCESS':
      return {
        ...state,
        letterSuccess: data,
      }
    case 'UPDATE_REASON_UNSUCCESSFUL':
      return {
        ...state,
        reasonUnsuccessful: data,
      }
    case 'UPDATE_WHAT_NOW':
      return {
        ...state,
        whatNow: data,
      }
    case 'UPDATE_NO_WHICH_TOOL':
      return {
        ...state,
        ifNoWhichTool: data,
      }
    case 'UPDATE_TOOL_EASIER':
      return {
        ...state,
        toolEasier: data,
      }
    case 'UPDATE_SAVED_TIME':
      return {
        ...state,
        savedTime: data,
      }
    case 'UPDATE_HOW_MUCH_TIME_SAVED':
      return {
        ...state,
        howMuchTimeSaved: data,
      }
    case 'UPDATE_REDUCED_STRESS':
      return {
        ...state,
        reducedStress: data,
      }
    case 'UPDATE_MORE_ABOUT_EXPERIENCE':
      return {
        ...state,
        moreAboutExperience: data,
      }
    case 'UPDATE_REASON_NOT':
      return {
        ...state,
        reasonNot: data,
      }
    default:
      return state
  }
}

export const userSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_USER_EMAIL':
      return {
        ...state,
        userEmail: data,
      }
    case 'UPDATE_TOOL_ID':
      return {
        ...state,
        toolID: data,
      }
    case 'UPDATE_FOLLOW_UP':
      return {
        ...state,
        followUp: data,
      }
    default:
      return state
  }
}

export const impactSurveyReducer = combineReducers({
  initial: initialSliceReducer,
  user: userSliceReducer,
  answers: answersSliceReducer,
})
