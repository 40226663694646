import type { ElementType, FunctionComponent } from 'react'
import React from 'react'
import { ButtonLink, Image, Typography } from '@which/seatbelt'
import { dynamicDatalayerPush } from '@which/shared'

import classNames from 'classnames'
import DOMPurify from 'dompurify'

import { formatToolName } from '../../utils/formatToolName'
import { faultyGoodsData, s75Data } from './data'
import styles from './TFToolEntryPoint.module.scss'

export const TFToolEntryPoint: FunctionComponent<TFToolEntryProps> = ({
  title,
  button_text,
  standfirst,
  question_text,
  tool_name,
  tool_url,
  image,
}) => {
  const toolName = formatToolName(tool_name)
  const entryPointData = getEntryPointData(tool_name)

  const handleClick = (event) => {
    dynamicDatalayerPush({
      eventCategory: 'problem resolution tools',
      eventAction: toolName,
      eventLabel: `question | product-type | ${event.currentTarget.dataset.type}`,
      eventValue: 0,
      eventInteractive: false,
      event: 'tEvent',
    })
  }

  const renderStandfirst = () => {
    const standfirstEl = document.createElement('div')
    standfirstEl.innerHTML = standfirst as string
    const links = standfirstEl.querySelectorAll('a')

    links.forEach((link) => {
      const spanEl = document.createElement('span')
      spanEl.textContent = link.textContent
      spanEl.classList.add('sb-link-animation-wrapper')
      link.textContent = ''
      link.appendChild(spanEl)
      link.classList.add('sb-link-primary')
    })

    return Array.from(standfirstEl.children).map((child) => {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(child.outerHTML, { ADD_ATTR: ['target'] }),
          }}
          key={child.textContent}
        />
      )
    })
  }

  return (
    <div
      className={classNames(styles.TFToolEntryPoint, {
        [styles.TFToolEntryPointQuestionVariant]: question_text,
      })}
      data-testid="tf-tool-entry-point"
    >
      {question_text && (
        <Typography className={styles.TFToolEntryPointBadge} textStyle="very-small-print">
          make a complaint
        </Typography>
      )}
      <Typography className={styles.TFToolEntryPointTitle} textStyle="title-400">
        {title}
      </Typography>

      <div className={styles.TFToolEntryPointBodyWrapper}>
        <div className={styles.TFToolEntryPointContent}>
          {renderStandfirst()}
          {!question_text && (
            <ButtonLink data-testid="tf-tool-link" href={tool_url}>
              {button_text}
            </ButtonLink>
          )}
        </div>
        {!question_text && image?.src && (
          <Image alt={image.alt} className={styles.image} src={image.src} />
        )}
      </div>

      {question_text && (
        <>
          <Typography className={styles.TFToolEntryPointQuestion} textStyle="body-intro">
            {question_text}
          </Typography>

          <ul className={styles.optionList} data-testid="tf-tool-answer-items" id="tool-choice">
            {entryPointData.map(
              ({ icon: TFIcon, label, example, type, url }: TFToolEntryOption) => {
                return (
                  <li className={styles.optionListItem} key={type}>
                    <a
                      className={styles.optionListItemBox}
                      data-testid="option-link"
                      data-type={type}
                      href={url}
                      onClick={handleClick}
                    >
                      <TFIcon
                        aria-hidden
                        className={styles.optionListItemBoxIcon}
                        data-testid="tficon"
                      />
                      <p className={styles.optionListItemBoxLabel}>{label}</p>
                      <p className={styles.optionListItemBoxExample}>{example}</p>
                    </a>
                  </li>
                )
              }
            )}
          </ul>
        </>
      )}
    </div>
  )
}

///////// IMPLEMENTATION /////////

const getEntryPointData = (toolName: string): TFToolEntryOption[] | [] => {
  if (toolName === 'FaultyGoodsTool') {
    return faultyGoodsData
  }

  if (toolName === 'Section75') {
    return s75Data
  }

  return []
}

type TFToolEntryProps = {
  datalayer: boolean
  title: string
  button_text: string
  standfirst?: string
  question_text?: string
  tool_name: string
  tool_url: string
  image?: { src: string; alt: string }
}

type TFToolEntryOption = {
  icon: ElementType
  label: string
  example: string
  type: string
  url: string
}
