import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../../shared/state/appContext'
import globalStyles from '../../../../../shared/styles/GlobalStyles.module.scss'
import { trackAppointmentBookingSelection } from '../../../../../shared/utils/tracking/tracking'
import {
  DescribeCaseFlow,
  LimitedOptionsFlow,
  TechDeviceFlow,
  TechQueryFlow,
} from '../../../pageFlowData'
import { techQueryData } from './data'

export const TechQuery: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    tech: { techQuery, techTopic },
    triage: { type },
  } = state

  const handleTracking = (id: string, index: number) => {
    trackAppointmentBookingSelection(type, TechQueryFlow.VIEW_NAME, { id, index })
  }

  const limitedOptionsIds = [
    'audio',
    'internal-drive',
    'physical-damage',
    'paper-jam',
    'printers-10',
    'advanced-programming',
    'apps',
    'microsoft-office-2016-below',
    'operating-system',
    'cordless-phones',
    'games-console',
    'virtualisation',
    'upgrade-drive',
  ]

  const chooseStep = (id: string) => {
    if (techTopic === 'hardware-issues' || limitedOptionsIds.includes(id)) {
      return LimitedOptionsFlow
    } else if (techTopic === 'buying-advice') {
      return DescribeCaseFlow
    }
    return TechDeviceFlow
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_TECH_QUERY', data: id })
    const step = chooseStep(id)
    dispatch({ type: 'UPDATE_STEP', data: step.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: step.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string, index: number) => {
    handleTracking(id, index)
    handleDispatch(id)
  }

  const chooseDataSet = () => {
    switch (techTopic) {
      case 'buying-advice':
        return techQueryData.option1.buttons
      case 'email-issues':
        return techQueryData.option2.buttons
      case 'file-data-storage':
        return techQueryData.option3.buttons
      case 'general-problems':
        return techQueryData.option4.buttons
      case 'hardware-issues':
        return techQueryData.option5.buttons
      case 'internet-networking':
        return techQueryData.option6.buttons
      case 'printers-ink':
        return techQueryData.option7.buttons
      case 'security-privacy':
        return techQueryData.option8.buttons
      case 'software-programs':
        return techQueryData.option9.buttons
      default:
        return techQueryData.option1.buttons
    }
  }

  const { imgObj, title } = techQueryData
  const buttons = chooseDataSet()

  return (
    <div className={globalStyles.fullWidthButton}>
      <PillButtonPage
        buttons={buttons}
        defaultValue={techQuery}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        title={title}
      />
    </div>
  )
}
