import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PreviewLetter } from '../../../../shared/components/PreviewLetter'
import { SubmitSuccessfulPage } from '../../../../shared/pages/SubmitSuccessfulPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { ToolFeedbackFlow, ToolFeedbackThankYouFlow } from '../../pageFlowData'
import { composeEmailBody } from './composeEmailBody'
import { feedbackData } from './data'

export const SubmitSuccessful: FunctionComponent = () => {
  const toolContext = useContext(ToolContext)
  const {
    state: {
      work,
      initial: { emailSent, user },
      toolFeedback: { showFeedback },
    },
    dispatch,
  } = toolContext

  const { body, imgObj, title } = feedbackData

  const data = composeEmailBody({ work, user })

  const standFirstCopy = emailSent
    ? `A copy of your claim letter has been sent to ${user.emailAddress}`
    : 'Due to an error, we were unable to send your email. However, you can get a copy of your complaint letter below'

  const handleStepChoice = (id: string) => {
    if (id === 'feedback-yes') {
      return { step: ToolFeedbackFlow.STEP }
    }

    return { step: ToolFeedbackThankYouFlow.STEP }
  }

  const handleDispatch = (id: string) => {
    const { step } = handleStepChoice(id)

    dispatch({ type: 'UPDATE_STEP', data: step })
  }

  const handleSubmit = (id: string) => {
    handleDispatch(id)
  }

  const { card } = work

  const paidWithCard =
    card === 'yes'
      ? 'If paid with a credit or debit card remember to check your rights under the <a class="sb-link-primary" href="https://www.which.co.uk/consumer-rights/regulation/section-75-of-the-consumer-credit-act-aZCUb9i8Kwfa" target="_blank"><span class="sb-link-animation-wrapper">Section 75</span></a> and the <a class="sb-link-primary" href="https://www.which.co.uk/consumer-rights/advice/how-do-i-use-chargeback-abZ2d4z3nT8q" target="_blank"><span class="sb-link-animation-wrapper">Chargeback scheme</span></a>, if you are unsatisfied with the resolution offered by the trader.'
      : ''

  return (
    <div data-testid="submit-successful">
      <SubmitSuccessfulPage
        feedbackBody={body}
        feedbackImgObj={imgObj}
        feedbackTitle={title}
        handleSubmit={handleSubmit}
        letterBody={data.body}
        letterTitle="Your complaint email"
        list={[
          'Check the information in the letter is correct.',
          'Gather copies of photographs and documentation to support your evidence.',
          'Send the letter along with the above documentation to the trader you booked with by post or email.',
          'Check if the builder/company is signed up to a voluntary professional body e.g. master builders/checkatrade.',
          paidWithCard,
          'If you have remaining questions about your legal rights in regards to your issue, Which? has a <a class="sb-link-primary" href="https://legalservice.which.co.uk/" target="_blank"><span class="sb-link-animation-wrapper">dedicated legal service who can advise you.</span></a>',
        ].filter((n) => n)}
        listAppearance="bullet"
        showFeedback={showFeedback}
        standFirst={standFirstCopy}
        title="Your next steps"
      >
        <PreviewLetter data={data} />
      </SubmitSuccessfulPage>
    </div>
  )
}
