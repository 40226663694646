export const misSoldPackagedBankAccountToolState = {
  account: {
    monthlyFee: null, // Have you ever paid a monthly fee for your bank account?
    accountOpen: null, // Is the account in question still open?
    nameOfBank: '', // What's the name of the bank or building society the account is, or was, with?
    nameOfAccount: '', // What's the name of the account?
    dateOpened: '', //On what date did you open the account?
  },
  claim: {
    howAccountSold: '', // How was the account sold to you?
    ledToPackagedBankAccount: [], // What led you to having a packaged bank account?
    whyMisSold: '', // In your own words please describe why you think you were mis-sold the account
    whatLikeToDo: '', // What would you like to do?
    claimCompensation: null, // Do you want to claim compensation for being mis-sold the account?
  },
}
