import { constructDate } from '../../../../shared/components/DatePicker/formatDate/constructDate'
import type { UserPropsWithAddress } from '../../../../shared/types/toolTypes'
import { formatTextInput } from '../../../../shared/utils/formatInput/formatTextInput'
import type { misSoldPackagedBankAccountToolState } from '../../state/misSoldPackagedBankAccountToolState'

export const composeEmailBody = ({
  account,
  claim,
  user,
  autoEmailer,
}: {
  account: typeof misSoldPackagedBankAccountToolState.account
  claim: typeof misSoldPackagedBankAccountToolState.claim
  user: UserPropsWithAddress
  autoEmailer?: boolean
}) => {
  const { nameOfBank, nameOfAccount, dateOpened } = account
  const { whyMisSold, claimCompensation, whatLikeToDo, howAccountSold } = claim
  const { firstName, lastName, address } = user

  let addressArr = [
    { text: `${address?.addressLine1}` },
    {
      text: `${address?.addressLine2 ? `${address.addressLine2}` : ''}`,
    },
    { text: `${address?.townCity}` },
    { text: `${address?.county ? `${address.county}` : ''}` },
    { text: `${address?.postcode}<br />\r\n` },
  ]

  const date = constructDate(new Date(dateOpened))

  const claimCompensationYes =
    claimCompensation === 'yes'
      ? `If my complaint is upheld I require a full refund of all the fees I have paid for this account from ${date}. Included are copies of my statements showing the fees I have paid since the date I opened the account.<br />\r\n`
      : ''

  const decidedWhatToDo = () => {
    switch (whatLikeToDo) {
      case 'close-account':
        return "I'm still a customer and would like to close my account."
      case 'nothing':
        return "I don't want to close or switch my account. I would just like to be compensated for being mis-sold."
      default:
        return "I'm still a customer and would like to be switched to a standard account."
    }
  }
  const decideHowAccountSold = () => {
    switch (howAccountSold) {
      case 'over-the-counter':
        return 'I was sold this account over the counter.'
      case 'via-the-internet':
        return 'I was sold this account via the internet.'
      case 'over-the-phone':
        return 'I was sold this account over the phone.'
      case 'by-post':
        return 'I was sold this account by post.'
      case 'during-a-meeting':
        return 'I was sold this account during a meeting.'
      case 'filled-in-a-leaflet':
        return 'I was sold this account by filling in a leaflet.'
      default:
        return ''
    }
  }

  let body = [
    { text: 'Dear Sir or Madam,<br />\r\n' },
    {
      text: `REFERENCE: ${user.lastName} / ${nameOfBank}<br />\r\n`,
    },
    {
      text: `I have held a ${nameOfAccount} with ${nameOfBank}, and have done so since ${date}. ${decideHowAccountSold()}<br />\r\n`,
    },
    {
      text: `I believe I was mis-sold this account. I was signed up without being told. ${
        whyMisSold && formatTextInput(whyMisSold, true)
      } I wish for you to investigate my complaint following your normal complaints procedure.<br />\r\n`,
    },
    { text: `${claimCompensationYes}` },
    { text: `${decidedWhatToDo()}<br />\r\n` },
    {
      text: 'I hope to reach a resolution within eight weeks of the date of this letter or I will be forced to escalate my complaint to the Financial Ombudsman Service.<br />\r\n',
    },
    { text: 'Yours faithfully,<br />\r\n' },
    { text: `${firstName} ${lastName}` },
  ]

  addressArr = addressArr.filter(String)
  body = autoEmailer ? addressArr.concat(body) : body
  body = body.filter(String)

  return {
    label: 'Preview of complaint email',
    address: addressArr,
    body: body,
  }
}
