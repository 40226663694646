import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PreviewLetter } from '../../../../shared/components/PreviewLetter'
import { SubmitSuccessfulPage } from '../../../../shared/pages/SubmitSuccessfulPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { ToolFeedbackFlow, ToolFeedbackThankYouFlow } from '../../pageFlowData'
import { composeEmailBody } from './composeEmailBody'
import { feedbackData } from './data'

export const SubmitSuccessful: FunctionComponent = () => {
  const toolContext = useContext(ToolContext)
  const {
    state: {
      issue,
      broadband,
      initial: { emailSent, user },
      toolFeedback: { showFeedback },
    },
    dispatch,
  } = toolContext

  const { whichProvider } = issue
  const { body, imgObj, title } = feedbackData

  const data = composeEmailBody({ issue, broadband, user })

  const standFirstCopy = emailSent
    ? `A copy of your claim letter has been sent to ${user.emailAddress}`
    : 'Due to an error, we were unable to send your email. However, you can get a copy of your complaint letter below'

  const handleStepChoice = (id: string) => {
    if (id === 'feedback-yes') {
      return { step: ToolFeedbackFlow.STEP }
    }

    return { step: ToolFeedbackThankYouFlow.STEP }
  }

  const handleDispatch = (id: string) => {
    const { step } = handleStepChoice(id)

    dispatch({ type: 'UPDATE_STEP', data: step })
  }

  const handleSubmit = (id: string) => {
    handleDispatch(id)
  }

  return (
    <div data-testid="submit-successful">
      <SubmitSuccessfulPage
        feedbackBody={body}
        feedbackImgObj={imgObj}
        feedbackTitle={title}
        handleSubmit={handleSubmit}
        letterBody={data.body}
        letterTitle="Your complaint email"
        list={[
          'Check all the information in the letter below is correct.',
          `Copy and paste the letter below and send it to ${whichProvider}. We suggest you give them 14 days to respond.`,
          'Make sure to keep records of all verbal or written communication as this can help your case should you need to take it further.',
          'If your provider can\'t or won\'t help after eight weeks, ask your provider to supply a deadlock letter that you can use to refer them to ADR. You can find Ofcom\'s list of which provider is with which ADR scheme <a class="sb-link-primary" href="https://www.ofcom.org.uk/phones-telecoms-and-internet/advice-for-consumers/problems/adr-schemes" target="_blank"><span class="sb-link-animation-wrapper">here</span>.</a>',
          'You could also consider switching to a provider that offers a better service - it\'s easier than you might think. For more information, read our guide on <a class="sb-link-primary" href="https://www.which.co.uk/reviews/broadband/article/how-to-switch-broadband-provider-a1VrH1b61tEU" target="_blank"><span class="sb-link-animation-wrapper">how to switch broadband provider</span>.</a>',
        ]}
        listAppearance="bullet"
        showFeedback={showFeedback}
        standFirst={standFirstCopy}
        title="Your next steps"
      >
        <PreviewLetter data={data} />
      </SubmitSuccessfulPage>
    </div>
  )
}
