export const limitedOptionsData = {
  title:
    'Unfortunately we can’t help you make an appeal if you’ve been issued a Fixed Penalty Notice or Traffic Offence Report',
  standFirst: '',
  bodyCopy: [
    'A Fixed Penalty Notice (FPN) or Traffic Offence Report (TOR) is issued by police traffic wardens to enforce more serious parking rules such as parking on a Red Route.',
    'As these tickets are issued through the criminal justice system, you will not be able to use this tool to appeal.',
    'Read our advice guide below for more information.',
  ],
  link: {
    href: 'https://www.which.co.uk/consumer-rights/advice/how-to-appeal-a-parking-ticket-asBzj3e9xtIj',
    headline:
      'Follow our straightforward steps to appeal against an unfair parking charge if it’s been issued by a council or private company.',
    text: 'How to appeal a parking ticket',
  },
  ctaData: {
    image: {
      src: 'https://media.product.which.co.uk/prod/images/original/gm-8b0968cf-b7d0-49e7-b5fd-ff38b3887a0d-limitedoptionsfaultygoods.png',
      alt: 'Two people looking at their phones facing away from each other.',
    },
    author: { name: 'Which?', jobTitle: 'Editorial team' },
  },
}
