import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'

import { FormEntryPage } from '../../pages/FormEntryPage'
import type { FormEntryInputRef, OrderNumberObj } from '../../types/toolTypes'
import { pageViewDataLayer } from '../../utils/tracking'

export const OrderNumber = forwardRef(
  (
    {
      props: {
        buttonText,
        defaultValue,
        fieldData,
        handleSubmit,
        id,
        imgObj,
        isFocused,
        isFullWidth,
        optional,
        placeholderText,
        testId,
        title,
      },
    }: OrderNumberObj,
    ref
  ) => {
    const orderNumberRef = useRef<FormEntryInputRef>(null)

    useImperativeHandle(ref, () => ({
      orderNumberRef: orderNumberRef.current,
    }))

    useEffect(() => {
      pageViewDataLayer('order-number')
    }, [])

    return (
      <FormEntryPage
        buttonText={buttonText}
        defaultValue={defaultValue}
        fieldData={fieldData}
        handleSubmit={handleSubmit}
        id={id}
        imgObj={imgObj}
        isFocused={isFocused}
        isFullWidth={isFullWidth}
        optional={optional}
        placeholderText={placeholderText}
        ref={orderNumberRef}
        testId={testId}
        title={title}
      />
    )
  }
)
