import React, { forwardRef, useLayoutEffect, useState } from 'react'
import { Label, Typography } from '@which/seatbelt'

import classNames from 'classnames'

import globalStyles from '../../styles/GlobalStyles.module.scss'
import type { FormEntryProps } from '../../types'
import { cleanText } from '../../utils/cleanText'
import { handleKeyPress } from '../../utils/keyDownHandler/handleKeyPress'
import { ErrorMessage } from '../ErrorMessage'
import styles from './FormEntryItem.module.scss'
import { hasAnError } from './hasAnError'

export const FormEntryItem = forwardRef<HTMLInputElement, FormEntryProps>(
  (
    {
      alignEntryItemLeft = false,
      className,
      displayText,
      enterKeyHandler,
      fieldData = { renderError: false, value: '', errorMessageText: '' },
      id,
      isFullWidth = true,
      isFocused = false,
      optional,
      placeholderText,
      srLabel,
      stateValue,
      type,
      margin = true,
      name,
      handleOnChange,
      manualEntry,
    }: FormEntryProps,
    ref
  ) => {
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('false')

    useLayoutEffect(() => {
      const { renderError, value, errorMessageText } = fieldData
      if (renderError) {
        hasAnError({
          value,
          id,
          optional: optional || false,
          setError,
          setErrorMessage,
          errorMessageText,
        })
      } else {
        if (!manualEntry) {
          setError(false)
        }
      }
    }, [fieldData, id, optional, errorMessage, error, setError, manualEntry])

    return (
      <div
        className={classNames(styles.formEntryItemWrapper, className, {
          [globalStyles.spacing40]: margin,
          [styles.formEntryItemWrapperLeft]: alignEntryItemLeft,
          [styles.formEntryItemWrapperError]: error,
        })}
        data-testid={`${id}-wrapper`}
      >
        {displayText && (
          <Typography
            className={classNames(styles.formEntryItemText, {
              [styles.formEntryItemTextLeft]: alignEntryItemLeft,
            })}
            data-testid={id}
            id={id}
            tag={alignEntryItemLeft ? 'h3' : 'p'}
            textStyle={alignEntryItemLeft ? 'title-400' : 'body-intro'}
          >
            {displayText}
            {optional && (
              <span className={classNames(styles.formEntryItemOptional)}> (optional)</span>
            )}
          </Typography>
        )}

        {srLabel && <Label className={'sr-only'} htmlFor={`${id}-input`} labelText={srLabel} />}
        <input
          autoFocus={isFocused}
          className={classNames(styles.formEntryItemInput, {
            [styles.formEntryItemInputFullWidth]: isFullWidth,
            [styles.formEntryItemInputError]: error,
          })}
          data-testid={`${id}-input`}
          defaultValue={stateValue}
          id={`${id}-input`}
          name={name || `${id}-input`}
          onBlur={(e) => (e.target.value = cleanText(e.target.value).trim())}
          onKeyDown={enterKeyHandler ? (e) => handleKeyPress(e, enterKeyHandler) : undefined}
          placeholder={placeholderText}
          ref={ref}
          required={!optional}
          type={type}
          {...(handleOnChange && { onChange: (e) => handleOnChange(e) })}
        />
        {error && (
          <ErrorMessage
            errorText={errorMessage}
            styleClasses={classNames(styles.formEntryItemError)}
          />
        )}
      </div>
    )
  }
)
