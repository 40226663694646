export const techSystemData = {
  buttons: [
    { label: 'Android 11', id: 'android-11' },
    { label: 'Android 12', id: 'android-12' },
    { label: 'Android 13', id: 'android-13' },
    { label: 'Android 14', id: 'android-14' },
    { label: 'Apple iOS 15', id: 'apple-15' },
    { label: 'Apple iOS 16', id: 'apple-16' },
    { label: 'Apple iOS 17', id: 'apple-17' },
    { label: 'Chrome OS', id: 'chrome' },
    { label: 'Linux', id: 'linux' },
    { label: 'MacOS Ventura', id: 'mac-ventura' },
    { label: 'MacOS Monterey', id: 'mac-monterey' },
    { label: 'MacOS Big Sur', id: 'mac-big-sur' },
    { label: 'MacOS Sonoma', id: 'mac-sonoma' },
    { label: 'Windows 10', id: 'windows-10' },
    { label: 'Windows 11', id: 'windows-11' },
  ],
  title: 'Please select your operating system',
  imgObj: {
    alt: 'Two people looking stressed at a computer',
    src: 'https://media.product.which.co.uk/prod/images/original/1fea9f96da2f-have-you-been-affected-by-the-scam.jpg',
  },
  standFirst:
    'If your operating system is not listed and is older than the options available, then we cannot offer support as it falls out of our scope',
  laptopOrDesktopButtons: [
    { label: 'Linux', id: 'linux' },
    { label: 'MacOS Ventura', id: 'mac-ventura' },
    { label: 'MacOS Monterey', id: 'mac-monterey' },
    { label: 'MacOS Big Sur', id: 'mac-big-sur' },
    { label: 'MacOS Sonoma', id: 'mac-sonoma' },
    { label: 'Windows 10', id: 'windows-10' },
    { label: 'Windows 11', id: 'windows-11' },
  ],
  mobileButtons: [
    { label: 'Android 11', id: 'android-11' },
    { label: 'Android 12', id: 'android-12' },
    { label: 'Android 13', id: 'android-13' },
    { label: 'Android 14', id: 'android-14' },
    { label: 'Apple iOS 15', id: 'apple-15' },
    { label: 'Apple iOS 16', id: 'apple-16' },
    { label: 'Apple iOS 17', id: 'apple-17' },
  ],
  tabletButtons: [
    { label: 'Android 11', id: 'android-11' },
    { label: 'Android 12', id: 'android-12' },
    { label: 'Android 13', id: 'android-13' },
    { label: 'Android 14', id: 'android-14' },
    { label: 'Apple iOS 15', id: 'apple-15' },
    { label: 'Apple iOS 16', id: 'apple-16' },
    { label: 'Apple iOS 17', id: 'apple-17' },
    { label: 'Chrome OS', id: 'chrome' },
    { label: 'Linux', id: 'linux' },
    { label: 'Windows 10', id: 'windows-10' },
    { label: 'Windows 11', id: 'windows-11' },
  ],
}
