export const toolFeedbackSurveyPageData = (state: {
  toolFeedback: {
    contactedSeller: string
    betterUnderstanding: string
    stressAboutClaim: string
    easyToUse: string
    suggestedChanges: string
  }
  email: string
  toolName: string
}) => {
  const { contactedSeller, betterUnderstanding, stressAboutClaim, easyToUse, suggestedChanges } =
    state.toolFeedback
  const { toolName, email } = state

  const questions: any = {}
  // Tool Fedback Survey Questions
  questions['question.1211298'] = contactedSeller
  questions['question.1182581'] = betterUnderstanding
  questions['question.1211301'] = stressAboutClaim
  questions['question.1211302'] = easyToUse
  questions['question.1182650'] = suggestedChanges

  questions['question.1182651'] = toolName

  return {
    supporter: {
      Email: email,
      questions,
    },
  }
}
