export const whatHappenedData = {
  title: 'What problems did you experience on the holiday?',
  imgObj: {
    src: 'https://media.product.which.co.uk/prod/images/original/gm-c13ec987-324f-4da1-93da-64d64e6a743a-holiday-complaint-6.jpg',
    alt: 'Two people stressed around a desk',
  },
  note: [
    'What you write here will appear in the final letter of the claim, so please be as clear as possible, including dates.',
  ],
  descriptiveText: [
    'Please tell us how the holiday company did not fulfil what they promised (and therefore breached their contract with you)',
  ],
  placeholderText:
    'Eg. the holiday company promised the accommodation would be a 5 star hotel but it was not.',
}
