export const legalTopicData = {
  buttons: [
    {
      label: 'Buying or hiring goods or services',
      id: 'buying',
    },
    {
      label: 'Home and property',
      id: 'home',
    },
    {
      label: 'Holidays, Travel and Transport',
      id: 'holiday',
    },
    {
      label: 'Cars, Vehicles and Motoring issues',
      id: 'cars',
    },
    {
      label: 'Employment',
      id: 'employment',
    },
    {
      label: 'Wills, Estate Planning, Powers of Attorney and Probate',
      id: 'wills',
    },
    {
      label: 'Data protection',
      id: 'dataProtection',
    },
    {
      label: 'Medical negligence & personal injury',
      id: 'medical',
    },
    {
      label: 'Other',
      id: 'other',
    },
  ],
  title: 'Please choose the topic that best matches your legal issue',
  imgObj: {
    alt: 'Couple looking at a piece of paper with a phone in hand',
    src: 'https://media.product.which.co.uk/prod/images/original/8ffdc4d0425c-step55.jpg',
  },
}
