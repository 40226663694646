import type { FunctionComponent } from 'react'
import React, { useContext, useState } from 'react'

import { RadioGroupPage } from '../../../../shared/pages/RadioGroupPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { ComeAcrossScamFlow, MoreAboutScamFlow, ScammerContactFlow } from '../../pageFlowData'
import { buttonData } from './data'

export const ComeAcrossScam: FunctionComponent = () => {
  const [isInputValid, setInputValid] = useState(true)
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    scam: { comeAcrossScam },
  } = state
  const [radioSelected, setRadioSelected] = useState(comeAcrossScam)

  const handleChange = (id: string) => {
    setRadioSelected(id)
  }

  const handleStepChoice = () => {
    if (radioSelected === 'other') {
      return { progress: MoreAboutScamFlow.PROGRESS_VALUE, step: MoreAboutScamFlow.STEP }
    }

    return { progress: ScammerContactFlow.PROGRESS_VALUE, step: ScammerContactFlow.STEP }
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${ComeAcrossScamFlow.VIEW_NAME} | ${radioSelected}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = () => {
    const { progress, step } = handleStepChoice()

    dispatch({ type: 'UPDATE_COME_ACROSS_SCAM', data: radioSelected })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleSubmit = () => {
    if (radioSelected) {
      setInputValid(true)
      handleTracking()
      handleDispatch()
    }
    setInputValid(false)
  }

  return (
    <RadioGroupPage
      buttonText="Next"
      buttons={buttonData}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      imgObj={{
        alt: 'Two people sitting down at a desk, one of their laptop one on their phone',
        src: 'https://media.product.which.co.uk/prod/images/original/218f3f321206-how-did-you-come-across-the-scam.jpg',
      }}
      isInputValid={isInputValid}
      stateValue={radioSelected}
      title="How did you come across the scam?"
    />
  )
}
