import type { Reducer } from 'react'

import type { Action, State } from '../toolStateTypes'

export const initialSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_INITIALISED':
      return {
        ...state,
        initialised: data,
      }
    case 'UPDATE_ENTRY_TOOL_NAME':
      return {
        ...state,
        entryToolName: data,
      }
    case 'UPDATE_STEP':
      return {
        ...state,
        step: data,
      }
    case 'UPDATE_USER_JOURNEY':
      return {
        ...state,
        userJourney: data,
      }
    case 'UPDATE_PROGRESS_VALUE':
      return {
        ...state,
        progressValue: data,
      }
    case 'UPDATE_EMAIL_SENT':
      return {
        ...state,
        emailSent: data,
      }
    case 'UPDATE_START_LETTER':
      return {
        ...state,
        journeyFlow: {
          ...state.journeyFlow,
          startLetter: data,
        },
      }
    case 'UPDATE_MILESTONE':
      return {
        ...state,
        journeyFlow: {
          ...state.journeyFlow,
          milestoneHit: data,
        },
      }
    case 'UPDATE_WRITELETTER_STEP':
      return {
        ...state,
        journeyFlow: {
          ...state.journeyFlow,
          writeLetter: {
            ...state.journeyFlow.writeLetter,
            currentStep: data,
          },
        },
      }
    case 'UPDATE_WRITELETTER_USERJOURNEY':
      return {
        ...state,
        journeyFlow: {
          ...state.journeyFlow,
          writeLetter: {
            ...state.journeyFlow.writeLetter,
            userJourney: data,
          },
        },
      }
    case 'UPDATE_BRANCH':
      return {
        ...state,
        journeyFlow: {
          ...state.journeyFlow,
          branch: data,
        },
      }
    case 'UPDATE_ISFORWARD':
      return {
        ...state,
        journeyFlow: {
          ...state.journeyFlow,
          isForward: data,
        },
      }
    case 'UPDATE_USER':
      return {
        ...state,
        user: data,
      }
    case 'UPDATE_CASESTUDY_OPTIN':
      return {
        ...state,
        optIns: {
          ...state.optIns,
          caseStudy: data,
        },
      }
    case 'UPDATE_SINGLE_CONSENT_OPTIN':
      return {
        ...state,
        optIns: {
          ...state.optIns,
          singleConsentOptIn: data,
        },
      }
    default:
      return state
  }
}
