import type { FunctionComponent } from 'react'
import { useState } from 'react'
import React, { useContext } from 'react'

import { SingleDropDownPage } from '../../../../shared/pages/SingleDropDownPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { AccountStillOpenFlow, HowAccountSoldFlow } from '../../pageFlowData'
import { howAccountSoldData } from './data'

export const HowAccountSold: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    claim: { howAccountSold },
  } = state

  const [isInputValid, setIsInputValid] = useState<boolean>(true)
  const [selectValue, setSelectValue] = useState(howAccountSold || '')

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_HOW_ACCOUNT_SOLD', data: value })
    dispatch({ type: 'UPDATE_STEP', data: AccountStillOpenFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: AccountStillOpenFlow.PROGRESS_VALUE })
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${HowAccountSoldFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = () => {
    if (!selectValue) {
      setIsInputValid(false)
      return
    }

    setIsInputValid(true)
    handleDispatch(selectValue)
    handleTracking(selectValue)
  }

  const handleSelectChange = (selectedOption: string) => {
    setSelectValue(selectedOption)
    setIsInputValid(true)
  }

  const { question, imgObj, id, options } = howAccountSoldData

  return (
    <div data-testid="how-account-sold">
      <SingleDropDownPage
        buttonText="Next"
        defaultValue={howAccountSold}
        handleSubmit={handleSubmit}
        id={id}
        imgObj={imgObj}
        isInputValid={isInputValid}
        onChangeCallback={handleSelectChange}
        options={options}
        title={question}
      />
    </div>
  )
}
