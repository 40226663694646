import type { FunctionComponent } from 'react'
import { useRef, useState } from 'react'
import React, { useContext } from 'react'

import { CurrencyPage } from '../../../../shared/pages/CurrencyPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { SingleInputState } from '../../../../shared/types'
import type { CurrencyEntryInputRef } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { HowDidYouPayFlow, HowMuchMoneyFlow } from '../../pageFlowData'
import { howMuchMoneyData } from './data'

export const HowMuchMoney: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    victim: { howMuchMoney },
  } = state

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })

  const HowMuchMoneyInput = useRef<CurrencyEntryInputRef>(null)
  const [selectBoxValue, setSelectBoxValue] = useState(
    howMuchMoney?.currency || howMuchMoneyData.options[0].value
  )
  const [currencyInputValid, setCurrencyInputValid] = useState<boolean>(true)
  const [currencyInvalidText, setCurrencyInvalidText] = useState<string>(
    'Please fill out this field to continue'
  )

  const handleDispatch = (selectValue: string, currencyValue: string, otherTextValue?: string) => {
    dispatch({
      type: 'UPDATE_HOW_MUCH_MONEY',
      data: { currency: selectValue, amount: currencyValue, other: otherTextValue || '' },
    })
    dispatch({ type: 'UPDATE_STEP', data: HowDidYouPayFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: HowDidYouPayFlow.PROGRESS_VALUE })
  }

  const handleTracking = (selectValue: string, currencyValue: string, otherTextValue?: string) => {
    const trackingAnswerForRadios = `question | ${HowMuchMoneyFlow.VIEW_NAME} | ${
      selectBoxValue !== 'other' ? selectValue : otherTextValue
    }`
    const trackingAnswerForCurrency = `question | ${HowMuchMoneyFlow.VIEW_NAME} | ${currencyValue}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswerForRadios, false)
    generalDataLayer(formatToolName(entryToolName), trackingAnswerForCurrency, false)
  }

  const handleSubmit = () => {
    const currencyValue = getCleanValue(HowMuchMoneyInput?.current?.formEntryInput?.value)
    const currencyValueAsDecimal = Number(currencyValue)
    const currencyValueAsDecimalHasError = currencyValueAsDecimal > 0 ? false : true
    const currencyHasError = !isNotEmpty(currencyValue)
    const otherTextValue = getCleanValue(HowMuchMoneyInput?.current?.otherInput?.value || '')
    const otherTextHasError = !isNotEmpty(otherTextValue) && selectBoxValue === 'other'

    setCurrencyInputValid(true)

    setInputData({
      renderError: otherTextHasError,
      value: otherTextValue,
    })

    if (!currencyHasError && currencyValueAsDecimalHasError) {
      setCurrencyInvalidText('Add a value greater than 0')
    }

    if (currencyHasError || currencyValueAsDecimalHasError) {
      setCurrencyInputValid(false)
    }

    if (
      !selectBoxValue ||
      currencyHasError ||
      otherTextHasError ||
      currencyValueAsDecimalHasError
    ) {
      return
    }

    setCurrencyInputValid(true)
    handleDispatch(selectBoxValue, currencyValue, otherTextValue)
    handleTracking(selectBoxValue, currencyValue, otherTextValue)
  }

  const handleSelectChange = (selectedOption: string) => {
    setSelectBoxValue(selectedOption)
  }

  const { title, imgObj, id, options } = howMuchMoneyData

  return (
    <div data-testid="how-account-sold">
      <CurrencyPage
        buttonText="Next"
        currencySymbol=""
        defaultValue={howMuchMoney?.amount || ''}
        dropdown={{
          options: options,
          selectBoxValue: selectBoxValue,
          defaultValue: howMuchMoney?.currency || '',
          id: id,
          handleSelectChange: handleSelectChange,
          fieldData: inputData,
          otherStateValue: howMuchMoney?.other || '',
        }}
        errorText={currencyInvalidText}
        handleSubmit={handleSubmit}
        id={id}
        imgObj={imgObj}
        isFocused
        isSubmitValid={currencyInputValid}
        ref={HowMuchMoneyInput}
        title={title}
      />
    </div>
  )
}
