import type { Reducer } from 'react'

import { initialSliceReducer, toolFeedbackSliceReducer } from '../../../shared/state/reducers'
import type { Action, State } from '../../../shared/state/toolStateTypes'
import { combineReducers } from '../../../shared/utils/combineReducers'

export const carSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_VEHICLE_DETAILS':
      return {
        ...state,
        vehicleDetails: data,
      }
    case 'UPDATE_WHERE_BOUGHT':
      return {
        ...state,
        whereBought: data,
      }
    case 'UPDATE_WHEN_PURCHASED':
      return {
        ...state,
        whenPurchased: data,
      }
    case 'UPDATE_ON_FINANCE':
      return {
        ...state,
        onFinance: data,
      }
    case 'UPDATE_WHICH_DEALER':
      return {
        ...state,
        whichDealer: data,
      }
    case 'UPDATE_HOW_MUCH':
      return {
        ...state,
        howMuch: data,
      }
    case 'UPDATE_WHATS_WRONG':
      return {
        ...state,
        whatsWrong: data,
      }
    case 'UPDATE_LIKE_TO_DO':
      return {
        ...state,
        likeToDo: data,
      }
    case 'UPDATE_DEALER_OFFERED_SOLUTION':
      return {
        ...state,
        dealerOfferedSolution: data,
      }
    default:
      return state
  }
}

export const carProblemReducer = combineReducers({
  initial: initialSliceReducer,
  car: carSliceReducer,
  toolFeedback: toolFeedbackSliceReducer,
})
