import type { FunctionComponent } from 'react'
import React, { useContext, useEffect, useRef, useState } from 'react'

import { PriceRiseProviderPage } from '../../../../shared/pages/PriceRiseProviderPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryInputRef, ProviderDataProps } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { HowMuchFlow, PaymentPredictionFlow } from '../../pageFlowData'
import { howMuchData } from './data'

export const HowMuch: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: {
      entryToolName,
      journeyFlow: { isForward },
    },
    priceRise: { howMuch, providersList, providerSelected },
  } = state

  useEffect(() => {
    if (!isForward) {
      howMuchInput?.current?.formEntryInput?.focus()
    }
  }, [isForward])

  const howMuchInput = useRef<FormEntryInputRef>(null)
  const [howMuchInputValid, setHowMuchInputValid] = useState<boolean>(true)
  const [providerInputValid, setProviderInputValid] = useState<boolean>(true)
  const [providerData, setProviderData] = useState<ProviderDataProps>(providerSelected)
  const [providerMessage, setProviderMessage] = useState('')
  const { imgObj, pageData } = howMuchData
  const { rpiMessage, adhocMessage, fixedPriceMessage, fixedPrice2024Message } = pageData

  const handleTracking = (howMuchValue: string, provider: ProviderDataProps) => {
    const trackingAnswer = `question | ${HowMuchFlow.VIEW_NAME} | ${howMuchValue} | ${provider.provider}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (howMuchValue: string, provider: ProviderDataProps) => {
    dispatch({ type: 'UPDATE_STEP', data: PaymentPredictionFlow.STEP })
    dispatch({ type: 'UPDATE_HOW_MUCH', data: howMuchValue })
    dispatch({ type: 'UPDATE_PROVIDER_SELECTED', data: provider })
  }

  const handleFocus = () => {
    dispatch({ type: 'UPDATE_INITIALISED', data: true })
  }

  const handleProviderChange = (value: string) => {
    const providerObj = providersList.find((item: ProviderDataProps) => item.provider === value)
    setProviderData(providerObj)
    setProviderInputValid(!!providerObj)

    const { multiplier } = providerObj

    switch (multiplier) {
      case 'rpi':
        setProviderMessage(rpiMessage)
        break
      case 'adhoc':
        setProviderMessage(adhocMessage)
        break
      case 'fpc':
        setProviderMessage(fixedPriceMessage)
        break
      case 'fpc2024':
        setProviderMessage(fixedPrice2024Message)
        break
      default:
        setProviderMessage('')
    }
  }

  const handleSubmit = () => {
    const howMuchValue = getCleanValue(howMuchInput?.current?.formEntryInput?.value)
    const howMuchHasError = !isNotEmpty(howMuchValue)
    const providerDataHasError = !providerData.provider

    setHowMuchInputValid(!howMuchHasError)
    setProviderInputValid(!providerDataHasError)

    if (!howMuchHasError && !providerDataHasError) {
      handleTracking(howMuchValue, providerData)
      handleDispatch(howMuchValue, providerData)
    }
  }

  const providerOptions = providersList?.length
    ? providersList.map(({ provider }) => ({
        label: provider,
        value: provider,
      }))
    : []

  return (
    <div data-testid="how-much-page">
      <PriceRiseProviderPage
        buttonText="Calculate"
        currencySymbol="£"
        defaultDropdownValue={providerSelected.provider}
        defaultValue={howMuch}
        handleFocus={handleFocus}
        handleProviderChange={handleProviderChange}
        handleSubmit={handleSubmit}
        howMuchId="how-much"
        imgObj={imgObj}
        isHowMuchValid={howMuchInputValid}
        isProviderValid={providerInputValid}
        message={providerMessage}
        pageData={pageData}
        providerId="provider"
        providerOptions={providerOptions}
        ref={howMuchInput}
      />
    </div>
  )
}
