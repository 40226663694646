import type { FunctionComponent } from 'react'
import React from 'react'
import { Typography } from '@which/seatbelt'

import classNames from 'classnames'

import globalStyles from '../../styles/GlobalStyles.module.scss'
import type { ImpactSurveyProps } from '../../types/toolTypes'
import { getCleanValue } from '../../utils/getCleanValue'
import { SurveyModule } from '../SurveyModule'
import { surveyInfo } from './data'
import styles from './ImpactSurveyPage.module.scss'

export const ImpactSurveyPage: FunctionComponent<ImpactSurveyProps> = ({
  handleSubmit,
  title = '',
  standFirst = '',
  radioQuestions,
  textareaQuestions = [],
  radiosSelected,
  setRadiosSelected,
  textareaData = [],
  setTextareaData,
  nextButtonText,
  errorCount,
}) => {
  const handleRadioChange = (id: string, radioGroupTitle: string, radioGroupId: string) => {
    const copyOfRadiosSelected = radiosSelected.map((selectedRadio) => ({ ...selectedRadio }))
    const objIndex = copyOfRadiosSelected.findIndex(
      (radioGroup) => radioGroup.title === radioGroupTitle
    )
    if (objIndex > -1) {
      copyOfRadiosSelected[objIndex].id = id
      copyOfRadiosSelected[objIndex].isValid = true
      copyOfRadiosSelected[objIndex].groupId = radioGroupId
    } else {
      copyOfRadiosSelected.push({
        title: radioGroupTitle,
        id,
        groupId: radioGroupId,
        isValid: true,
      })
    }
    setRadiosSelected([...copyOfRadiosSelected])
  }

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>, error: boolean) => {
    const { id } = e.target
    const value = getCleanValue(e.target.value)

    if (setTextareaData) {
      const copyOfTextareaData = textareaData
      const dataIndex = copyOfTextareaData.findIndex((textarea) => textarea.id === id)
      const questionIndex = textareaQuestions.findIndex((question) => question.id === id)

      if (dataIndex > -1) {
        copyOfTextareaData[dataIndex].userInput = value
        copyOfTextareaData[dataIndex].isValid =
          textareaQuestions[questionIndex].isOptional && value.length < 2500 ? true : !error
      } else {
        copyOfTextareaData.push({
          userInput: value,
          id,
          isValid: !error,
        })
      }

      setTextareaData([...copyOfTextareaData])
    }
  }

  const handleValidation = () => {
    let isValid = true
    const copyOfRadioQuestions = radioQuestions.map((radioQuestion: any) => ({ ...radioQuestion }))
    const copyOfRadiosSelected = radiosSelected.map((selectedRadio) => ({ ...selectedRadio }))
    const copyOfTextareaData = textareaData?.map((data) => ({ ...data }))

    const numSelectedRadios = copyOfRadioQuestions.filter((el: { id: string | undefined }) => {
      return copyOfRadiosSelected.some((f) => {
        return f.groupId === el.id && f.isValid
      })
    }).length

    // Check if all inputs are empty (check both arrays are empty)
    if (copyOfRadiosSelected.length === 0) {
      isValid = false
      radioQuestions.forEach((rQ: { title: string }) => {
        copyOfRadiosSelected.push({
          title: rQ.title,
          isValid: false,
        })
      })
    }
    if (textareaQuestions && textareaQuestions.length > 0) {
      if (copyOfTextareaData?.length === 0) {
        textareaQuestions.forEach((textareaInput: { id: string; isOptional?: boolean }) => {
          if (textareaInput.isOptional) {
            copyOfTextareaData?.push({
              id: textareaInput.id,
              userInput: '',
              isValid: true,
            })
          } else {
            isValid = false
            copyOfTextareaData?.push({
              id: textareaInput.id,
              userInput: '',
              isValid: false,
            })
          }
        })
      } else {
        let numInvalidTextAreas = 0

        copyOfTextareaData?.forEach((textarea) => {
          if (textarea.userInput.length > 2500) {
            isValid = false
          }

          if (!textarea.isValid) {
            numInvalidTextAreas++
            /* istanbul ignore next */
            if (!copyOfTextareaData.some((e) => e.id === textarea.id)) {
              copyOfTextareaData.push({
                id: textarea.id,
                userInput: '',
                isValid: false,
              })
            }
          }
        })

        if (numInvalidTextAreas) {
          isValid = false
        }
      }
    }

    if (numSelectedRadios !== radioQuestions.length) {
      isValid = false
      const missedRadios = copyOfRadioQuestions.filter((el: { id: string | undefined }) => {
        return copyOfRadiosSelected.some((f) => {
          return f.groupId !== el.id
        })
      })
      missedRadios.forEach((missedRadio: { title: string; id: string }) => {
        copyOfRadiosSelected.push({
          title: missedRadio.title,
          groupId: missedRadio.id,
          isValid: false,
        })
      })
    }

    setRadiosSelected([...copyOfRadiosSelected])
    if (setTextareaData && copyOfTextareaData) {
      setTextareaData([...copyOfTextareaData])
    }

    return isValid
  }

  return (
    <>
      <SurveyModule
        errorCount={errorCount}
        handleRadioChange={handleRadioChange}
        handleSubmit={() => {
          const validationPassed = handleValidation()
          handleSubmit(validationPassed)
        }}
        handleTextareaChange={handleTextareaChange}
        nextButtonText={nextButtonText}
        radioQuestions={radioQuestions}
        standFirst={standFirst}
        stateValue={radiosSelected}
        textareaQuestions={textareaQuestions}
        textareaState={textareaData}
        title={title}
      />
      <Typography
        className={classNames(globalStyles.spacing40Top, styles.impactSurveyPageLink)}
        dangerouslySetInnerHTML={{ __html: surveyInfo }}
        textStyle="body"
      />
    </>
  )
}
