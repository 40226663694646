import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { BookingReferenceFlow, ExpenseValueFlow, HasExpensesFlow } from '../../pageFlowData'
import { hasExpensesData } from './data'
import styles from './HasExpenses.module.scss'

export const HasExpenses: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    expenses: { hasExpenses },
  } = state

  const handleStepChoice = (value) => {
    if (value === 'yes') {
      return { progress: ExpenseValueFlow.PROGRESS_VALUE, step: ExpenseValueFlow.STEP }
    }

    return { progress: BookingReferenceFlow.PROGRESS_VALUE, step: BookingReferenceFlow.STEP }
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${HasExpensesFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    const { progress, step } = handleStepChoice(value)

    dispatch({ type: 'UPDATE_HAS_EXPENSES', data: value })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleSubmit = (value: string) => {
    handleTracking(value)
    handleDispatch(value)
  }

  const { image, title, hasExpensesButtons } = hasExpensesData

  return (
    <PillButtonPage
      buttons={hasExpensesButtons}
      defaultValue={hasExpenses}
      handleSubmit={handleSubmit}
      imgObj={image}
      infoBoxData={hasExpensesData}
      pillButtonGroupStyle={styles.hasExpensesPagePillButtons}
      title={title}
    />
  )
}
