export const data = {
  imgObj: {
    alt: 'A person holding a giant clock',
    src: 'https://media.product.which.co.uk/prod/images/original/719475ec927c-mis-sold-step-10.jpg',
  },
  title: 'On what date did you open the account?',
  noteTop: [
    "Don't worry if you can't remember the exact date. Just be as accurate as possible. This can help the bank locate your records.",
  ],
}
