import React from 'react'

import { FollowUpOptIn } from '../PageComponents/FollowUpOptIn'
import { HowMuchTimeSaved } from '../PageComponents/HowMuchTimeSaved/'
import { IfNoWhichTool } from '../PageComponents/IfNoWhichTool'
import { ReasonNot } from '../PageComponents/ReasonNot'
import { ReasonUnsuccessful } from '../PageComponents/ReasonUnsuccessful'
import { ReducedStress } from '../PageComponents/ReducedStress'
import { RegardingProblem } from '../PageComponents/RegardingProblem'
import { SubmitSuccessful } from '../PageComponents/SubmitSuccessful'
import { ThankYou } from '../PageComponents/ThankYou'
import { WasSuccessful } from '../PageComponents/WasSuccessful'
import { WhatWasOutcome } from '../PageComponents/WhatWasOutcome'
import {
  FollowUpFlow,
  HowMuchTimeSavedFlow,
  NoWhichToolFlow,
  ReasonNotFlow,
  ReasonUnsuccessfulFlow,
  ReducedStressFlow,
  RegardingProblemFlow,
  SubmitSuccessfulFlow,
  ThankYouFlow,
  WasSuccessfulFlow,
  WhatWasOutcomeFlow,
} from '../pageFlowData'

export const useRenderStep = (step: number) => {
  switch (step) {
    case RegardingProblemFlow.STEP:
      return <RegardingProblem />
    case WasSuccessfulFlow.STEP:
      return <WasSuccessful />
    case WhatWasOutcomeFlow.STEP:
      return <WhatWasOutcome />
    case ReasonUnsuccessfulFlow.STEP:
      return <ReasonUnsuccessful />
    case NoWhichToolFlow.STEP:
      return <IfNoWhichTool />
    case HowMuchTimeSavedFlow.STEP:
      return <HowMuchTimeSaved />
    case ReducedStressFlow.STEP:
      return <ReducedStress />
    case FollowUpFlow.STEP:
      return <FollowUpOptIn />
    case ReasonNotFlow.STEP:
      return <ReasonNot />
    case ThankYouFlow.STEP:
      return <ThankYou />
    case SubmitSuccessfulFlow.STEP:
      return <SubmitSuccessful />
    default:
      return <RegardingProblem />
  }
}
