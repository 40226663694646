import type { UserPropsWithAddress } from '../../../../shared/types/toolTypes'
import type { deliveryComplaintToolState } from '../../state/deliveryComplaintToolState'

export const composeEmailBody = ({
  order,
  retailer,
  user,
  autoEmailer,
}: {
  order: typeof deliveryComplaintToolState.order
  retailer: typeof deliveryComplaintToolState.retailer
  user: UserPropsWithAddress
  autoEmailer?: boolean
}) => {
  const { deliveryProblem, itemOrdered, orderNumber, deliveryDateExplained, whatWentWrong } = order

  const { name: retailerName } = retailer

  const { firstName, lastName, address } = user

  const lateDelivery = deliveryProblem === 'lateDelivery'

  const issue = lateDelivery
    ? 'I have not yet received my delivery and our originally agreed delivery date is now overdue.'
    : `I have not yet received my delivery and have been informed that the courier left it in a place that they thought was safe without my express permission to do so.
`

  const consumerAct = lateDelivery
    ? `The Consumer Rights Act makes it clear that I have a contract with you as the retailer and it is your responsibility to ensure I receive my order. It is your responsibility to ensure the goods are delivered to me. I would like you to organise a re-delivery at this time ${deliveryDateExplained}.`
    : `Under the Consumer Rights Act and the Consumer Contracts Regulations, the goods remain your responsibility until they are received by me and I would request that you now make arrangements for the item to be delivered to me without delay and would request that you confirm that you will do this on receipt of this complaint. ${whatWentWrong}
`

  let addressArr = [
    { text: `${address?.addressLine1}` },
    {
      text: `${address?.addressLine2 ? `${address.addressLine2}` : ''}`,
    },
    { text: `${address?.townCity}` },
    { text: `${address?.county ? `${address.county}` : ''}` },
    { text: `${address?.postcode}<br />\r\n` },
  ]

  let body = [
    { text: `Dear ${retailerName},<br />\r\n` },
    { text: orderNumber ? `Order number: ${orderNumber}<br />\r\n` : '' },
    {
      text: `I placed an order with ${retailerName} for a ${itemOrdered}, which has still not been delivered to me.<br />\r\n`,
    },
    { text: `${issue}<br />\r\n` },
    { text: `${consumerAct}<br />\r\n` },
    {
      text: 'If you are unable to do so I will be entitled to consider our contract at an end and will expect you to refund me the full purchase price and any delivery charges.<br />\r\n',
    },
    { text: 'Yours faithfully,<br />\r\n' },
    { text: `${firstName} ${lastName}` },
  ]

  addressArr = addressArr.filter(String)
  body = autoEmailer ? addressArr.concat(body) : body
  body = body.filter(String)

  return {
    label: 'Preview of complaint email',
    address: addressArr,
    body: body,
  }
}
