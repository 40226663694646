import type { PageFlowProps } from '../../../shared/types/toolTypes'

export const myMoneyHealthCheckIntroFlow: PageFlowProps = {
  PROGRESS_VALUE: -10,
  STEP: 0,
  VIEW_NAME: 'intro',
  REDUCERS: [],
  MILESTONE: '',
}

export const FinanceSituationFlow: PageFlowProps = {
  PROGRESS_VALUE: 0,
  STEP: 1,
  VIEW_NAME: 'finance-situation',
  REDUCERS: ['UPDATE_FINANCE_SITUATION'],
  MILESTONE: 'milestone | start',
}

export const PropertyPaymentFlow: PageFlowProps = {
  PROGRESS_VALUE: 15,
  STEP: 2,
  VIEW_NAME: 'property-payment',
  REDUCERS: ['UPDATE_PROPERTY_PAYMENT'],
  MILESTONE: '',
}

export const BenefitsFlow: PageFlowProps = {
  PROGRESS_VALUE: 30,
  STEP: 3,
  VIEW_NAME: 'benefits',
  REDUCERS: ['UPDATE_BENEFITS'],
  MILESTONE: '',
}

export const EnergyPaymentFlow: PageFlowProps = {
  PROGRESS_VALUE: 45,
  STEP: 4,
  VIEW_NAME: 'energy-payment',
  REDUCERS: ['UPDATE_ENERGY_PAYMENT'],
  MILESTONE: '',
}

export const FoodShopFlow: PageFlowProps = {
  PROGRESS_VALUE: 60,
  STEP: 5,
  VIEW_NAME: 'food-shop',
  REDUCERS: ['UPDATE_FOOD_SHOP'],
  MILESTONE: '',
}

export const BroadbandContractFlow: PageFlowProps = {
  PROGRESS_VALUE: 75,
  STEP: 6,
  VIEW_NAME: 'broadband-contract',
  REDUCERS: ['UPDATE_BROADBAND_CONTRACT'],
  MILESTONE: '',
}

export const PayingDebtsFlow: PageFlowProps = {
  PROGRESS_VALUE: 85,
  STEP: 7,
  VIEW_NAME: 'paying-debts',
  REDUCERS: ['UPDATE_PAYING_DEBTS'],
  MILESTONE: '',
}

export const ResultsPageFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 8,
  VIEW_NAME: 'journey-complete',
  REDUCERS: ['UPDATE_RESULTS_PAGE'],
  MILESTONE: 'milestone | end',
}

export const AllFlows = [
  myMoneyHealthCheckIntroFlow,
  FinanceSituationFlow,
  PropertyPaymentFlow,
  BenefitsFlow,
  EnergyPaymentFlow,
  FoodShopFlow,
  BroadbandContractFlow,
  PayingDebtsFlow,
  ResultsPageFlow,
]
