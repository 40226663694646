export const radioButtonData = [
  { label: 'The number was blocked', id: 'number-blocked', isFocused: true },
  { label: "I can't remember", id: 'cant-remember' },
  {
    label: 'They called from the number of the company/organisation they were impersonating',
    id: 'impersonated-number',
  },
  {
    label: "A random number I didn't recognise",
    id: 'unrecognised-number',
  },
]

export const haveContactPillButtonData = {
  online: {
    title: 'Do you have a link to the website or social media page?',
    imgObj: {
      alt: 'Person sat at a desk looking at their PC',
      src: 'https://media.product.which.co.uk/prod/images/original/1aa2564ce727-do-you-have-the-link-to-the-website-.jpg',
    },
  },
  email: {
    title: 'Do you have the email address that the email was sent from?',
    imgObj: {
      alt: 'Man in a suit looking at a smartphone',
      src: 'https://media.product.which.co.uk/prod/images/original/6e9bcced1015-do-you-have-the-email-address-that-the-email-was-sent-from-.jpg',
    },
  },
  textMessage: {
    title: 'Do you have the number the text came from?',
    imgObj: {
      alt: 'Couple looking at a piece of a paper and a smartphone',
      src: 'https://media.product.which.co.uk/prod/images/original/1246b6c04159-do-you-have-the-number-the-text-came-from-.jpg',
    },
  },
  whatsapp: {
    title: 'Do you have the number the WhatsApp came from?',
    imgObj: {
      alt: 'Couple looking at a piece of a paper and a smartphone',
      src: 'https://media.product.which.co.uk/prod/images/original/1246b6c04159-do-you-have-the-number-the-text-came-from-.jpg',
    },
  },
  social: {
    title: 'Do you have the website address?',
    imgObj: {
      alt: 'Person sat at a desk looking at their PC',
      src: 'https://media.product.which.co.uk/prod/images/original/965c15f51ffa-do-you-have-the-website-address-.jpg',
    },
  },
}
