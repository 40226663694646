import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { ContactForm } from '../../../../shared/components/ContactForm'
import { ImageWithQuestion } from '../../../../shared/components/ImageWithQuestion'
import { NextButton } from '../../../../shared/components/NextButton'
import { ToolContext } from '../../../../shared/state/appContext'
import type { addressType, ContactRefProps } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { scrollToInvalidInput } from '../../../../shared/utils/scrollToInvalidInput'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { validatePostcode } from '../../../../shared/utils/validatePostcode'
import { TicketDiscountFlow, TicketLocationFlow } from '../../pageFlowData'
import { data } from './data'

export const TicketLocation: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    ticket: { ticketLocation },
  } = state

  const [inputsData, setInputsData] = useState<any>({
    postcodeData: { renderError: false, value: '' },
    addressLine1Data: { renderError: false, value: '' },
    addressLine2Data: { renderError: false, value: '' },
    townCityData: { renderError: false, value: '' },
    countyData: { renderError: false, value: '' },
  })

  const AddressFormRefs = useRef<ContactRefProps>(null)

  const currentInputsList: HTMLInputElement[] = []

  for (const input in AddressFormRefs.current) {
    currentInputsList.push(AddressFormRefs.current[input])
  }

  const [hasSubmitted, setHasSubmitted] = useState(false)

  const handleDispatch = (addressData: addressType) => {
    dispatch({ type: 'UPDATE_TICKET_LOCATION', data: addressData })
    dispatch({ type: 'UPDATE_STEP', data: TicketDiscountFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: TicketDiscountFlow.PROGRESS_VALUE })
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${TicketLocationFlow.VIEW_NAME} | PII`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDropdownChange = () => {
    setInputsData({
      postcodeData: { renderError: false, value: inputsData.postcodeData.value },
      addressLine1Data: { renderError: false, value: inputsData.addressLine1Data.value },
      addressLine2Data: { renderError: false, value: inputsData.addressLine2Data.value },
      townCityData: { renderError: false, value: inputsData.townCityData.value },
      countyData: { renderError: false, value: inputsData.countyData.value },
    })
  }

  const handleSubmit = () => {
    setHasSubmitted(true)
    const postcodeValue: string = AddressFormRefs.current?.postcode?.value || ''
    const postcodeHasError = !isNotEmpty(postcodeValue) || !validatePostcode(postcodeValue)
    const addressLine1Value: string = AddressFormRefs.current?.addressLine1?.value || ''
    const addressLine1HasError = !isNotEmpty(addressLine1Value)

    const addressLine2Value: string = AddressFormRefs.current?.addressLine2?.value || ''
    const addressLine2HasError = !isNotEmpty(addressLine2Value)

    const townCityValue: string = AddressFormRefs.current?.townCity?.value || ''
    const townCityHasError = !isNotEmpty(townCityValue)

    const countyValue: string = AddressFormRefs.current?.county?.value || ''
    const countyHasError = !isNotEmpty(countyValue)

    setInputsData({
      postcodeData: { renderError: postcodeHasError, value: postcodeValue },
      addressLine1Data: { renderError: addressLine1HasError, value: addressLine1Value },
      addressLine2Data: { renderError: addressLine2HasError, value: addressLine2Value },
      townCityData: { renderError: townCityHasError, value: townCityValue },
      countyData: { renderError: countyHasError, value: countyValue },
    })

    if (!postcodeHasError && !addressLine1HasError && !townCityHasError) {
      const addressData = {
        postcode: postcodeValue,
        addressLine1: addressLine1Value,
        addressLine2: addressLine2Value,
        townCity: townCityValue,
        county: countyValue,
      }
      handleDispatch(addressData)
      handleTracking()
    } else {
      scrollToInvalidInput(currentInputsList)
    }
  }

  const formItems = data.ticketLocationData.map((x) => ({
    ...x,
    enterKeyHandler: handleSubmit,
  }))

  const { imgObj, title } = data

  return (
    <div data-testid="ticket-location">
      <ImageWithQuestion image={imgObj} title={title} />
      <ContactForm
        addressItems={formItems}
        handleDropdownChange={handleDropdownChange}
        hasSubmittedOnce={hasSubmitted}
        ref={AddressFormRefs}
        stateValue={{ address: ticketLocation }}
        submitValid={inputsData}
      />
      <NextButton buttonText="Next" onClick={handleSubmit} />
    </div>
  )
}
