import type { FunctionComponent } from 'react'
import { useRef } from 'react'
import React, { useContext, useState } from 'react'

import { ImpactSurveyPage } from '../../../../shared/pages/ImpactSurveyPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { ImpactRadioData, RadiosSelected } from '../../../../shared/types/toolTypes'
import { NoWhichToolFlow } from '../../pageFlowData'
import {
  broadbandData,
  deliveryData,
  faultyGoodsData,
  flightData,
  holidayData,
  homeImprovementData,
  packageBankAccountData,
  parkingData,
  section75Data,
} from './data'

export const ReasonUnsuccessful: FunctionComponent = () => {
  const { dispatch, state } = useContext(ToolContext)
  const {
    answers: { reasonUnsuccessful, whatNow },
    user: { toolID },
  } = state

  const mergeStateArrays = [...(reasonUnsuccessful || []), ...(whatNow || [])]
  const [radiosSelected, setRadiosSelected] = useState<RadiosSelected>(mergeStateArrays)
  const textInputRef = useRef<HTMLInputElement | null>(null)

  const chooseDataset = () => {
    switch (toolID) {
      case 'FaultyGoodsTool':
        return faultyGoodsData.radioQuestions
      case 'Section75':
        return section75Data.radioQuestions
      case 'HolidayComplaint':
        return holidayData.radioQuestions
      case 'ParkingTicket':
        return parkingData.radioQuestions
      case 'DeliveryComplaint':
        return deliveryData.radioQuestions
      case 'FlightDelayCancellationCompensation':
        return flightData.radioQuestions
      case 'MisSoldPackagedBankAccount':
        return packageBankAccountData.radioQuestions
      case 'BroadbandSpeed':
        return broadbandData.radioQuestions
      case 'HomeImprovement':
        return homeImprovementData.radioQuestions
      default:
        return faultyGoodsData.radioQuestions
    }
  }

  const chosenDataSet = chooseDataset()

  const radioQuestions = chosenDataSet.map((question) => {
    const buttons = question.buttons.map((button: any) => {
      if (button.textbox) {
        return {
          ...button,
          textbox: { ...button.textbox, reference: textInputRef },
        }
      }
      return button
    })

    return { ...question, buttons: buttons }
  })

  const handleDispatch = (reasonUnsuccessfulValue) => {
    dispatch({ type: 'UPDATE_REASON_UNSUCCESSFUL', data: reasonUnsuccessfulValue })
    dispatch({
      type: 'UPDATE_WHAT_NOW',
      data: [radiosSelected.find((o) => o.groupId === 'what-now')],
    })
    dispatch({ type: 'UPDATE_STEP', data: NoWhichToolFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: NoWhichToolFlow.PROGRESS_VALUE })
  }

  const handleSubmit = (validationPassed: boolean) => {
    if (validationPassed) {
      const optionalTextInputValue = textInputRef.current?.value || ''
      let reasonUnsuccessfulValue = [
        radiosSelected.find((o) => o.groupId === 'reason-unsuccessful'),
      ]

      if (optionalTextInputValue) {
        reasonUnsuccessfulValue = [
          {
            ...reasonUnsuccessfulValue[0],
            textboxAnswer: optionalTextInputValue,
          } as ImpactRadioData,
        ]
      }

      handleDispatch(reasonUnsuccessfulValue)
    }
  }

  return (
    <ImpactSurveyPage
      handleSubmit={handleSubmit}
      nextButtonText="Next"
      radioQuestions={radioQuestions}
      radiosSelected={radiosSelected}
      setRadiosSelected={setRadiosSelected}
    />
  )
}
