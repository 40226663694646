import type { Reducer } from 'react'

import { initialSliceReducer, toolFeedbackSliceReducer } from '../../../shared/state/reducers'
import type { Action, State } from '../../../shared/state/toolStateTypes'
import { combineReducers } from '../../../shared/utils/combineReducers'

export const broadbandSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_PACKAGE_NAME':
      return {
        ...state,
        packageName: data,
      }

    case 'UPDATE_GUARANTEED_SPEED':
      return {
        ...state,
        guaranteedSpeed: data,
      }

    case 'UPDATE_ACHIEVED_SPEED':
      return {
        ...state,
        achievedSpeed: data,
      }

    default:
      return state
  }
}

export const issueSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_WHICH_PROVIDER':
      return {
        ...state,
        whichProvider: data,
      }

    case 'UPDATE_OTHER_PROVIDER':
      return {
        ...state,
        otherProvider: data,
      }

    case 'UPDATE_WHEN_PURCHASED':
      return {
        ...state,
        whenPurchased: data,
      }

    case 'UPDATE_SUPPORTING_EVIDENCE':
      return {
        ...state,
        supportingEvidence: data,
      }

    default:
      return state
  }
}

export const broadbandSpeedReducer = combineReducers({
  initial: initialSliceReducer,
  broadband: broadbandSliceReducer,

  issue: issueSliceReducer,

  toolFeedback: toolFeedbackSliceReducer,
})
