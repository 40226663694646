import { initialToolState } from '../../../shared/state'
import { myMoneyHealthCheckToolState } from '../state/myMoneyHealthCheckToolState'

export const myMoneyHealthCheckState = {
  initial: {
    ...initialToolState.initial,
    step: 8,
    journeyFlow: { startLetter: true, milestoneHit: [''] },
  },
  myMoneyHealthCheck: {
    benefits: 'no',
    broadbandContract: 'yes',
    energyPayment: 'prepayment-meter',
    financeSituation: 'red',
    foodShop: 'supermarkets',
    payingDebts: ['credit-cards'],
    propertyPayment: 'rent',
    landingPageCompleted: true,
  },
}

export const myMoneyHealthCheckStateDefault = {
  initial: {
    ...initialToolState.initial,
    journeyFlow: { startLetter: true, milestoneHit: [''] },
  },
  myMoneyHealthCheck: {
    benefits: '',
    broadbandContract: '',
    energyPayment: '',
    financeSituation: '',
    foodShop: '',
    payingDebts: [''],
    propertyPayment: '',
    landingPageCompleted: true,
  },
}

export const myMoneyHealthCheckStateLandingFalse = {
  ...myMoneyHealthCheckStateDefault,
  myMoneyHealthCheck: {
    ...myMoneyHealthCheckStateDefault.myMoneyHealthCheck,
    landingPageCompleted: false,
  },
}

export const undefinedState = {
  initial: {
    ...initialToolState.initial,
    journeyFlow: { startLetter: true, milestoneHit: [''] },
  },
  ...myMoneyHealthCheckToolState,
  myMoneyHealthCheck: {
    ...myMoneyHealthCheckToolState.myMoneyHealthCheck,
    landingPageCompleted: undefined,
  },
}
