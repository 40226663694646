const difference = (countWords: number, maxCount: number) => Math.abs(countWords - maxCount)

export const wordCount = (currentString: string, maxCount: number) => {
  const countWords = currentString
    .replace(/\n/g, ' ')
    .split(' ')
    .filter((n) => {
      return n !== ''
    }).length
  const remainingCount: number = maxCount - countWords
  const count: number = difference(countWords, maxCount)
  const wordText = count === 1 ? 'word' : 'words'

  const countString =
    remainingCount < 0 ? `${count} ${wordText} too many` : `${count} ${wordText} remaining`

  return `You have ${countString}`
}
