import type { FunctionComponent, ReactElement } from 'react'
import React from 'react'
import { Typography } from '@which/seatbelt'

import classNames from 'classnames'

import globalStyles from '../../styles/GlobalStyles.module.scss'
import styles from './PageWithText.module.scss'

export const PageWithText: FunctionComponent<Props> = ({
  title,
  standFirst,
  bodyCopy,
  children,
  className,
  titleClassName,
}) => {
  const renderTitle = (): null | ReactElement => {
    if (!title || title === '') {
      return null
    }

    return (
      <Typography className={titleClassName} tag="h1" textStyle="title-650">
        {title}
      </Typography>
    )
  }

  const renderStandfirst = (): null | ReactElement => {
    if (!standFirst || standFirst === '') {
      return null
    }

    return (
      <Typography className={styles.pageWithTextStandFirst} tag="p" textStyle="standfirst">
        {standFirst}
      </Typography>
    )
  }

  const renderBodyCopy = (): null | ReactElement => {
    if (!bodyCopy) {
      return null
    }

    if (bodyCopy.every((copy) => copy === '')) {
      return null
    }

    const bodyCopyToRender = bodyCopy.filter((copy) => copy !== '')

    return (
      <div className={styles.pageWithTextBodyCopy} data-testid="page-with-text-body">
        {bodyCopyToRender.map((copy, i) => (
          <Typography
            dangerouslySetInnerHTML={{ __html: copy }}
            key={`body-copy-${i}`}
            tag="p"
            textStyle="body"
          />
        ))}
      </div>
    )
  }

  return (
    <div
      className={classNames(styles.pageWithText, globalStyles.spacing35, className)}
      data-testid="page-with-text"
    >
      {renderTitle()}
      <div className={classNames(globalStyles.maxWidth)}>
        {renderStandfirst()}
        {renderBodyCopy()}
      </div>
      {children}
    </div>
  )
}

type Props = {
  title: string
  standFirst?: string
  bodyCopy?: string[]
  children?: any
  className?: string
  titleClassName?: string
}
