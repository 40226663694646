import type { FunctionComponent } from 'react'
import React from 'react'
import { Author, Picture, Typography } from '@which/seatbelt'

import classnames from 'classnames'

import type { ArticleCTAProps } from '../../types/toolTypes'
import styles from './ArticleCTA.module.scss'

export const ArticleCTA: FunctionComponent<ArticleCTAProps> = ({
  author,
  className,
  handleSubmit,
  headline,
  image,
  link,
  title,
}) => {
  return (
    <div>
      <a
        className={classnames(styles.articleCta, className)}
        data-testid="article-cta"
        href={link.href}
        onClick={handleSubmit}
      >
        <div className={styles.ctaText}>
          <Typography className={styles.articleCtaTitle} tag="h3" textStyle="title-400">
            <span className={styles.articleCtalineAnimation}>{title}</span>
          </Typography>
          <Typography className={styles.articleCtaDescription} tag="p" textStyle="body">
            {headline}
          </Typography>
          {author && <Author {...author} />}
        </div>
        <div className={styles.articleCtaPicture}>
          {image && (
            <Picture {...image} imageClassName={styles.articleCtaImageClassName} lazyLoad={false} />
          )}
        </div>
      </a>
    </div>
  )
}
