import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../../shared/state/appContext'
import { trackAppointmentBookingSelection } from '../../../../../shared/utils/tracking/tracking'
import { LegalTopicFlow, LimitedOptionsFlow, SolicitorFlow } from '../../../pageFlowData'
import { solicitorData } from './data'
import styles from './Solicitor.module.scss'

export const Solicitor: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    triage: { type },
  } = state

  const handleTracking = (id: string, index: number) => {
    trackAppointmentBookingSelection(type, SolicitorFlow.VIEW_NAME, { id, index })
  }

  const chooseStep = (id: string) => {
    if (id === 'yes') {
      return LimitedOptionsFlow
    }
    return LegalTopicFlow
  }

  const handleDispatch = (id: string) => {
    const step = chooseStep(id)
    dispatch({ type: 'UPDATE_STEP', data: step.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: step.PROGRESS_VALUE })
    dispatch({ type: 'UPDATE_TRIAGE_SOLICITOR', data: id })
  }

  const handleSubmit = (id: string, index: number) => {
    handleTracking(id, index)
    handleDispatch(id)
  }

  const { buttons, imgObj, title } = solicitorData

  return (
    <div>
      <PillButtonPage
        buttons={buttons}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        infoBoxData={solicitorData}
        pillButtonGroupStyle={styles.solicitorPillButtons}
        title={title}
      />
    </div>
  )
}
