export const reportedToData = {
  lostMoneyButtons: [
    {
      label: 'My bank/credit card provider where the money was taken from',
      id: 'my-bank',
    },
    {
      label: 'The bank that received my money',
      id: 'bank-received-money',
    },
    {
      label: 'Paypal',
      id: 'paypal',
    },
    {
      label: 'The police/Action Fraud',
      id: 'police',
    },
    {
      label: 'Financial Conduct Authority (FCA)',
      id: 'fca',
    },
    {
      label: 'National Cyber Security Centre (NCSC)',
      id: 'cyber-secruity',
    },
    {
      label: 'The organisation/company the scammer was impersonating',
      id: 'organisation-company',
    },
    {
      label: 'Cyber Helpline',
      id: 'cyber-helpline',
    },
    {
      label: "I haven't reported the scam to anyone",
      id: 'not-reported',
    },
    {
      label: 'Other',
      id: 'other',
    },
  ],
  notLostMoneyButtons: [
    {
      label: 'My bank',
      id: 'my-bank',
    },
    {
      label: 'The company/organisation the scammer was impersonating',
      id: 'organisation-company',
    },
    {
      label: 'Financial Conduct Authority (FCA)',
      id: 'fca',
    },
    {
      label: 'Action Fraud',
      id: 'action-fraud',
    },
    {
      label: 'Trading standards',
      id: 'trading-standards',
    },
    {
      label: 'National Cyber Security Centre (NCSC)',
      id: 'cyber-secruity',
    },
    {
      label: "I haven't reported the scam to anyone",
      id: 'not-reported',
    },
    {
      label: 'Other',
      id: 'other',
    },
  ],
  title: 'Who have you reported the scam to?',
  optionalText: '(Check all that apply)',
  imgObj: {
    alt: 'A police officer and a man in a suit standing next to each other',
    src: 'https://media.product.which.co.uk/prod/images/original/b40f4cbe8c61-who-have-you-reported-the-scam-to.jpg',
  },
}
