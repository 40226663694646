import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'

import { ContactForm } from '../../../../shared/components/ContactForm'
import { ErrorMessage } from '../../../../shared/components/ErrorMessage'
import { ImageWithQuestion } from '../../../../shared/components/ImageWithQuestion'
import { NextButton } from '../../../../shared/components/NextButton'
import type { ContactDetailsPageProps, ContactRefProps } from '../../../../shared/types/toolTypes'
import { FormEntryItem } from '../../../components/FormEntryItem'
import { RenderInfoBox } from '../../../utils/renderInfoBox'
import styles from './ContactDetails.module.scss'

export const ContactDetailsPage = forwardRef(
  (
    {
      addressItems,
      buttonText,
      errorCount,
      formItems,
      handleSubmit,
      imgObj,
      inputsData,
      optins,
      testId,
      title,
      user,
      btnDisable = false,
      phoneNumberData,
      phoneNumberRef,
      contactOptin,
      showTerms = false,
      infoBoxData,
    }: ContactDetailsPageProps,
    ref
  ) => {
    const ContactFormRefs = useRef<ContactRefProps>(null)
    const [hasSubmitted, setHasSubmitted] = useState(false)

    useImperativeHandle(ref, () => ({
      firstName: ContactFormRefs.current?.firstName,
      lastName: ContactFormRefs.current?.lastName,
      emailAddress: ContactFormRefs.current?.emailAddress,
      phoneNumber: ContactFormRefs.current?.phoneNumber,
      addressLine1: ContactFormRefs.current?.addressLine1,
      addressLine2: ContactFormRefs.current?.addressLine2,
      townCity: ContactFormRefs.current?.townCity,
      county: ContactFormRefs.current?.county,
      postcode: ContactFormRefs.current?.postcode,
      date: ContactFormRefs.current?.date,
    }))

    const handleNextClick = (e: any) => {
      setHasSubmitted(true)
      handleSubmit(e)
    }

    return (
      <div data-testid={testId}>
        <ImageWithQuestion image={imgObj} title={title} />
        {infoBoxData && <RenderInfoBox data={infoBoxData} />}
        <ContactForm
          addressItems={addressItems}
          formItems={formItems}
          hasSubmittedOnce={hasSubmitted}
          optins={optins}
          ref={ContactFormRefs}
          showTerms={showTerms}
          stateValue={user}
          submitValid={inputsData}
        />
        {phoneNumberData && phoneNumberRef && contactOptin && (
          <FormEntryItem
            displayText="Can we take the best phone number to contact you? This will only be stored as part of a follow up to this survey, your phone number will not be used for anything else."
            enterKeyHandler={handleSubmit}
            fieldData={phoneNumberData}
            id="phoneNumber"
            isFocused={false}
            optional
            placeholderText=""
            ref={phoneNumberRef}
            srLabel="Can we take the best phone number to contact you? This will only be stored as part of a follow up to this survey, your phone number will not be used for anything else."
            stateValue=""
            type="number"
          />
        )}
        <NextButton
          buttonText={buttonText}
          disabled={btnDisable}
          onClick={(e) => handleNextClick(e)}
        />
        {errorCount && errorCount > 0 && (
          <span className={styles.contactDetailsError}>
            <ErrorMessage errorText="Something went wrong, please try again" />
          </span>
        )}
      </div>
    )
  }
)
