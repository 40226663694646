export const whereBoughtData = {
  buttons: [
    {
      label: 'A car dealer',
      id: 'dealer',
    },
    {
      label: 'A private seller, or at auction',
      id: 'private',
    },
  ],
  title: 'Where did you buy your car from?',
  imgObj: {
    alt: 'Two people paying a sales assistant at a desk',
    src: 'https://media.product.which.co.uk/prod/images/original/2dead4dbfe06-cp-step3.jpg',
  },
}
