import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { ContactDetailsFlow, UsedCardFlow } from '../../pageFlowData'
import { usedCardData } from './data'
import styles from './UsedCard.module.scss'

export const UsedCard: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    work: { card },
  } = state

  const handleTracking = (id: string) => {
    const trackingAnswer = `question | ${UsedCardFlow.VIEW_NAME} | ${id}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_CARD', data: id })
    dispatch({ type: 'UPDATE_STEP', data: ContactDetailsFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: ContactDetailsFlow.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string) => {
    handleTracking(id)
    handleDispatch(id)
  }

  const { buttons, imgObj, title } = usedCardData

  return (
    <div>
      <PillButtonPage
        buttons={buttons}
        defaultValue={card ? card : ''}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        infoBoxData={usedCardData}
        pillButtonGroupStyle={styles.usedCardPillButtons}
        title={title}
      />
    </div>
  )
}
