import type { Reducer } from 'react'

import { initialSliceReducer, toolFeedbackSliceReducer } from '../../../shared/state/reducers'
import type { Action, State } from '../../../shared/state/toolStateTypes'
import { combineReducers } from '../../../shared/utils/combineReducers'

export const ticketSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_TICKET_PURCHASED':
      return {
        ...state,
        ticketPurchased: data,
      }
    case 'UPDATE_TICKET_NUMBER':
      return {
        ...state,
        ticketNumber: data,
      }
    case 'UPDATE_TICKET_REASON':
      return {
        ...state,
        ticketReason: data,
      }
    case 'UPDATE_TICKET_DATE':
      return {
        ...state,
        ticketDate: data,
      }
    case 'UPDATE_TICKET_LOCATION':
      return {
        ...state,
        ticketLocation: data,
      }
    case 'UPDATE_TICKET_DISCOUNT':
      return {
        ...state,
        ticketDiscount: data,
      }
    default:
      return state
  }
}

export const issueSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_WHO_ISSUED':
      return {
        ...state,
        whoIssued: data,
      }
    case 'UPDATE_COMPANY_NAME':
      return {
        ...state,
        companyName: data,
      }
    case 'UPDATE_VEHICLE_REG':
      return {
        ...state,
        vehicleReg: data,
      }
    case 'UPDATE_PURCHASE_METHOD':
      return {
        ...state,
        purchaseMethod: data,
      }
    case 'UPDATE_SUPPORTING_EVIDENCE':
      return {
        ...state,
        supportingEvidence: data,
      }
    case 'UPDATE_ISSUE_DESCRIPTION':
      return {
        ...state,
        description: data,
      }
    default:
      return state
  }
}

export const parkingTicketReducer = combineReducers({
  initial: initialSliceReducer,
  ticket: ticketSliceReducer,
  issue: issueSliceReducer,
  toolFeedback: toolFeedbackSliceReducer,
})
