import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../../shared/state/appContext'
import globalStyles from '../../../../../shared/styles/GlobalStyles.module.scss'
import { trackAppointmentBookingSelection } from '../../../../../shared/utils/tracking/tracking'
import {
  CarsLegalFlow,
  CarsQueryFlow,
  CarsTopicFlow,
  ContractChoiceFlow,
  DescribeCaseFlow,
  LimitedOptionsFlow,
} from '../../../pageFlowData'
import { carsTopicData } from './data'

export const CarsTopic: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    cars: { carsTopic },
    triage: { type },
  } = state

  const handleTracking = (id: string, index: number) => {
    trackAppointmentBookingSelection(type, CarsTopicFlow.VIEW_NAME, { id, index })
  }

  const handleStep = (id: string) => {
    switch (id) {
      case 'pothole':
        return DescribeCaseFlow
      case 'vehicle':
        return ContractChoiceFlow
      case 'road-traffic':
        return CarsLegalFlow
      case 'dvla':
      case 'other':
      case 'motor-insurance':
        return LimitedOptionsFlow
      default:
        return CarsQueryFlow
    }
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_CARS_TOPIC', data: id })
    const step = handleStep(id)
    dispatch({ type: 'UPDATE_STEP', data: step.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: step.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string, index: number) => {
    handleTracking(id, index)
    handleDispatch(id)
  }

  const { buttons, imgObj, title } = carsTopicData

  return (
    <div className={globalStyles.fullWidthButton}>
      <PillButtonPage
        buttons={buttons}
        defaultValue={carsTopic ? carsTopic : ''}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        title={title}
      />
    </div>
  )
}
