import type { FunctionComponent } from 'react'
import React, { useContext, useState } from 'react'

import { ImpactSurveyPage } from '../../../../shared/pages/ImpactSurveyPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { RadiosSelected } from '../../../../shared/types/toolTypes'
import { surveyModuleSpecificText } from '../../../../shared/utils/surveyModuleSpecificText/surveyModuleSpecificText'
import { NoWhichToolFlow, ReasonUnsuccessfulFlow, WhatWasOutcomeFlow } from '../../pageFlowData'
import { wasSuccessfulData } from './data'

export const WasSuccessful: FunctionComponent = () => {
  const { dispatch, state } = useContext(ToolContext)
  const {
    answers: { wasSuccessful },
    user: { toolID },
  } = state
  const [radiosSelected, setRadiosSelected] = useState<RadiosSelected>(wasSuccessful)

  const { radioQuestionData } = wasSuccessfulData

  const toolSpecificText = surveyModuleSpecificText(toolID)
  const title = `Was your ${toolSpecificText} successful?`
  const radioQuestion = [{ ...radioQuestionData, title: title }]

  const handleStepChoice = (answerId) => {
    if (answerId === 'yes') {
      return { progress: WhatWasOutcomeFlow.PROGRESS_VALUE, step: WhatWasOutcomeFlow.STEP }
    } else if (answerId === 'no') {
      return { progress: ReasonUnsuccessfulFlow.PROGRESS_VALUE, step: ReasonUnsuccessfulFlow.STEP }
    } else {
      return { progress: NoWhichToolFlow.PROGRESS_VALUE, step: NoWhichToolFlow.STEP }
    }
  }

  const handleDispatch = (answerId: string) => {
    const { progress, step } = handleStepChoice(answerId)

    dispatch({ type: 'UPDATE_WAS_SUCCESSFUL', data: radiosSelected })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleSubmit = (validationPassed: boolean) => {
    if (validationPassed) {
      let answerId: string | undefined = ''
      radiosSelected.forEach((radio) => {
        const { id } = radio
        answerId = id
      })

      handleDispatch(answerId)
    }
  }

  return (
    <>
      <ImpactSurveyPage
        handleSubmit={handleSubmit}
        nextButtonText="Next"
        radioQuestions={radioQuestion}
        radiosSelected={radiosSelected || []}
        setRadiosSelected={setRadiosSelected}
      />
    </>
  )
}
