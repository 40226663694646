export const scamSharerToolState = {
  scam: {
    comeAcrossScam: '', // Seen or been affected by a scam? Help us protect others
    moreAboutScam: '', // Can you tell us more about the scam?
    scammerContact: '', // Did the scammer contact you in more than one way? E.g Telephone call & Email
    moreAboutScammer: '', // Can you tell us more about the different ways the scammer contacted you?
    whatHappened: '', // Can you share a short description of what happened?
    whoWasScammer: '', // What was the nature of the call?
    typeOfScammer: '', // Who did the person claim to be?
    nameOfScammer: '', // What was the name of the company/organisation
    socialWhatHappened: '', // Question name
    whatScamEntry: '', // Question name
    whatAboutMessage: '', // Question name
    haveContact: '', // Question name
    scammerReference: '', // Question name
  },
  victim: {
    beenAffected: null, // Have you been affected by the scam?
    lostMoney: null, // Did you lose any money?
    howMuchMoney: { currency: '', amount: '', other: null }, // Approximately how much money was taken?
    howDidYouPay: null, // How did you pay?
    whatBank: null, // Which bank/credit card provider was the money taken from?
    reportedTo: [], // Who have you reported the scam to? (Check all that apply)
    whoReportedTo: '', // Who have you reported the scam to?
    outcome: '', // What was the outcome?
    reportedToOmbudsman: '', // Have you reported to the Financial Ombudsman Service (FOS)?
    age: '', // What is your age?
    postcode: '', // What is the first half of your postcode?
    gender: '', // What is your gender?
    getInTouch: '', // Would you be happy for a member of the Which? team to get in touch?
  },
}
