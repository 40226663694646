import type { FunctionComponent } from 'react'
import React, { useContext, useState } from 'react'

import { ImpactSurveyPage } from '../../../../shared/pages/ImpactSurveyPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { RadiosSelected } from '../../../../shared/types/toolTypes'
import { FollowUpFlow } from '../../pageFlowData'
import { getStepData } from '../../utils/getStepData'
import { howMuchTimeSavedData } from './data'

export const HowMuchTimeSaved: FunctionComponent = () => {
  const { dispatch, state } = useContext(ToolContext)
  const {
    answers: { howMuchTimeSaved, reducedStress, moreAboutExperience },
    user: { toolID },
  } = state

  const mergeStateArrays = [...(howMuchTimeSaved || []), ...(reducedStress || [])]

  const [radiosSelected, setRadiosSelected] = useState<RadiosSelected>(mergeStateArrays)

  const [textAreaTextData, setTextAreaTextData] =
    useState<{ id: string; userInput: string; isValid: boolean }[]>(moreAboutExperience)

  const { radioQuestions, textAreaQuestion } = howMuchTimeSavedData
  const { toolName, radioExperience, textareaExperience } = getStepData(toolID)

  radioQuestions[1].title = `Do you feel that using the Which? ${toolName} tool reduced any worry or stress you may have been experiencing in making ${radioExperience}?`

  if (textAreaTextData?.length > 0) {
    textAreaQuestion[0].defaultValue = textAreaTextData[0].userInput
  }

  textAreaQuestion[0].title = `You can use this space if you'd like to tell us more about your experience of ${textareaExperience} and how our tool helped or did not help you`

  const handleDispatch = () => {
    dispatch({
      type: 'UPDATE_HOW_MUCH_TIME_SAVED',
      data: [radiosSelected.find((o) => o.groupId === 'how-much-time-saved')],
    })
    dispatch({
      type: 'UPDATE_REDUCED_STRESS',
      data: [radiosSelected.find((o) => o.groupId === 'reduced-stress')],
    })
    dispatch({ type: 'UPDATE_MORE_ABOUT_EXPERIENCE', data: textAreaTextData })
    dispatch({ type: 'UPDATE_STEP', data: FollowUpFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: FollowUpFlow.PROGRESS_VALUE })
  }

  const handleSubmit = async (validationPassed: boolean) => {
    if (validationPassed) {
      handleDispatch()
    }
  }

  return (
    <ImpactSurveyPage
      handleSubmit={handleSubmit}
      nextButtonText="Next"
      radioQuestions={radioQuestions}
      radiosSelected={radiosSelected}
      setRadiosSelected={setRadiosSelected}
      setTextareaData={setTextAreaTextData}
      textareaData={textAreaTextData}
      textareaQuestions={textAreaQuestion}
    />
  )
}
