export const broadbandSpeedToolState = {
  broadband: {
    packageName: null, // What is the name of your broadband package?
    guaranteedSpeed: null, // What was your minimum guaranteed line speed in megabits per second (Mbps)?
    achievedSpeed: null, // What speed in megabits per second (Mbps) are you achieving in reality?
  },

  issue: {
    whichProvider: '', // Who is your broadband provider?
    otherProvider: null, // Who is your broadband provider?
    whenPurchased: '', // When did your broadband contract begin?
    supportingEvidence: null, // Have you any further evidence to support your case?
  },
}
