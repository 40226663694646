import type { Reducer } from 'react'

import { initialSliceReducer, toolFeedbackSliceReducer } from '../../../shared/state/reducers'
import type { Action, State } from '../../../shared/state/toolStateTypes'
import { combineReducers } from '../../../shared/utils/combineReducers'

export const accountSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_MONTHLY_FEE':
      return {
        ...state,
        monthlyFee: data,
      }
    case 'UPDATE_ACCOUNT_STILL_OPEN':
      return {
        ...state,
        accountOpen: data,
      }
    case 'UPDATE_NAME_OF_BANK':
      return {
        ...state,
        nameOfBank: data,
      }
    case 'UPDATE_NAME_OF_ACCOUNT':
      return {
        ...state,
        nameOfAccount: data,
      }
    case 'UPDATE_DATE_OPENED_ACCOUNT':
      return {
        ...state,
        dateOpened: data,
      }
    default:
      return state
  }
}

export const claimSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_HOW_ACCOUNT_SOLD':
      return {
        ...state,
        howAccountSold: data,
      }
    case 'UPDATE_LED_YOU_TO_PACKAGED_BANK_ACCOUNT':
      return {
        ...state,
        ledToPackagedBankAccount: data,
      }
    case 'UPDATE_WHY_MIS_SOLD_ACCOUNT':
      return {
        ...state,
        whyMisSold: data,
      }
    case 'UPDATE_WHAT_LIKE_TO_DO':
      return {
        ...state,
        whatLikeToDo: data,
      }
    case 'UPDATE_CLAIM_COMPENSATION':
      return {
        ...state,
        claimCompensation: data,
      }
    default:
      return state
  }
}

export const misSoldPackagedBankAccountReducer = combineReducers({
  initial: initialSliceReducer,
  account: accountSliceReducer,
  claim: claimSliceReducer,
  toolFeedback: toolFeedbackSliceReducer,
})
