export const holidayTopicData = {
  buttons: [
    {
      label: 'Holiday accommodation',
      id: 'holiday-accommodation',
    },
    {
      label: 'Issues with flights/airlines/airports',
      id: 'issues-with-flight',
    },
    {
      label: 'Lost or damaged luggage',
      id: 'lost-luggage',
    },
    {
      label: 'Package holidays',
      id: 'package-holidays',
    },
    {
      label: 'Pre-contract advice',
      id: 'pre-contract-advice',
    },
    {
      label: 'Timeshares',
      id: 'timeshares',
    },
    {
      label: 'Changes to holiday itineraries',
      id: 'changes-to-holiday',
    },
    {
      label: 'Passports and visa issues',
      id: 'passport-issues',
    },
    {
      label: 'Holiday car hire',
      id: 'vehicle',
    },
    {
      label: 'Travel Insurance',
      id: 'uk-insurance',
    },
  ],
  title: 'Please select which of the following best matches your query',
  imgObj: {
    alt: 'Two women sitting on luggage',
    src: 'https://media.product.which.co.uk/prod/images/original/4c1aa7a3cdf8-flight-tool-a.jpg',
  },
}
