import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { ContactDetailsFlow, LikeToDoFlow } from '../../pageFlowData'
import { likeToDoData } from './data'

export const LikeToDo: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    car: { likeToDo, whenPurchased },
  } = state

  const msInDay = 24 * 60 * 60 * 1000
  const date1 = new Date()
  const date2 = new Date(whenPurchased)
  const days = Math.round(Math.abs(date2.getTime() - date1.getTime()) / msInDay)

  const buttonsData = days < 30 ? likeToDoData.buttonsUnder30 : likeToDoData.buttons

  const handleTracking = (id: string) => {
    const trackingAnswer = `question | ${LikeToDoFlow.VIEW_NAME} | ${id}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_LIKE_TO_DO', data: id })
    dispatch({ type: 'UPDATE_STEP', data: ContactDetailsFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: ContactDetailsFlow.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string) => {
    handleTracking(id)
    handleDispatch(id)
  }

  const { imgObj, title } = likeToDoData

  return (
    <PillButtonPage
      buttons={buttonsData}
      defaultValue={likeToDo ? likeToDo : ''}
      handleSubmit={handleSubmit}
      imgObj={imgObj}
      title={title}
    />
  )
}
