import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { Dropdown } from '@which/seatbelt'

import classNames from 'classnames'

import { CurrencyInput } from '../../components/CurrencyInput'
import { FormEntryItem } from '../../components/FormEntryItem'
import { ImageWithQuestion } from '../../components/ImageWithQuestion'
import { NextButton } from '../../components/NextButton'
import globalStyles from '../../styles/GlobalStyles.module.scss'
import type { CurrencyPageProps } from '../../types/toolTypes'
import { RenderInfoBox } from '../../utils/renderInfoBox'
import styles from './CurrencyPage.module.scss'

export const CurrencyPage = forwardRef(
  (
    {
      buttonText,
      currencySymbol,
      defaultValue,
      handleSubmit,
      id,
      imgObj,
      isFocused,
      isSubmitValid,
      title,
      infoBoxData,
      dropdown,
      errorText,
    }: CurrencyPageProps,
    ref
  ) => {
    const currencyInputRef = useRef<HTMLInputElement>(null)
    const otherInputRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(ref, () => ({
      formEntryInput: currencyInputRef.current,
      otherInput: otherInputRef.current,
    }))

    return (
      <div data-testid="currency-page">
        <ImageWithQuestion image={imgObj} title={title} />
        <div
          className={classNames(styles.currencyPageWrapper, {
            [styles.currencyPageWrapperOther]: dropdown?.selectBoxValue === 'other',
          })}
        >
          {dropdown && dropdown.options && (
            <Dropdown
              callback={dropdown.handleSelectChange}
              className={styles.currencyPageDropDown}
              data-testid="select-input"
              defaultValue={dropdown.defaultValue}
              id={dropdown.id}
              options={dropdown.options}
              variant="regular"
            />
          )}
          {dropdown?.selectBoxValue === 'other' && (
            <div className={styles.currencyPageOtherField}>
              <FormEntryItem
                displayText=""
                enterKeyHandler={handleSubmit}
                fieldData={{
                  renderError: dropdown.fieldData.renderError,
                  value: dropdown.fieldData.value,
                }}
                id="other"
                isFocused
                placeholderText="e.g US Dollar"
                ref={otherInputRef}
                srLabel="What is the other currency?"
                stateValue={dropdown.otherStateValue}
                type="text"
              />
            </div>
          )}
          <div
            className={classNames(styles.currencyPageCurrency, {
              [styles.currencyPageCurrencyInput]: dropdown,
            })}
          >
            <CurrencyInput
              currencySymbol={currencySymbol}
              errorText={errorText}
              handleSubmit={handleSubmit}
              id={id}
              isFocused={isFocused}
              isSubmitValid={isSubmitValid}
              ref={currencyInputRef}
              srLabel={title}
              stateValue={defaultValue}
            />
          </div>
        </div>
        <div className={classNames(globalStyles.buttonWrapper, globalStyles.spacing65)}>
          <NextButton buttonText={buttonText} onClick={handleSubmit} />
        </div>
        {infoBoxData && <RenderInfoBox data={infoBoxData} />}
      </div>
    )
  }
)
