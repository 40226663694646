import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { ContactDetailsFlow, GetInTouchFlow } from '../../pageFlowData'
import { getInTouchData } from './data'

export const GetInTouch: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: {
      entryToolName,
      optIns: { caseStudy },
    },
  } = state

  const handleTracking = (id: string) => {
    const trackingAnswer = `question | ${GetInTouchFlow.VIEW_NAME} | ${id}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = (id: string) => {
    dispatch({ type: 'UPDATE_CASESTUDY_OPTIN', data: id })
    handleTracking(id)
    handleDispatch()
  }

  const handleDispatch = () => {
    dispatch({ type: 'UPDATE_STEP', data: ContactDetailsFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: ContactDetailsFlow.PROGRESS_VALUE })
  }

  const { buttons, standFirst, title, image } = getInTouchData

  return (
    <PillButtonPage
      buttons={buttons}
      defaultValue={caseStudy}
      handleSubmit={handleSubmit}
      imgObj={image}
      standFirst={standFirst}
      title={title}
    />
  )
}
