import type { FunctionComponent } from 'react'
import React, { useEffect, useRef } from 'react'
import { Typography } from '@which/seatbelt'

import classNames from 'classnames'

import { BackButton } from '../../components/BackButton'
import { ImageWithQuestion } from '../../components/ImageWithQuestion'
import globalStyles from '../../styles/GlobalStyles.module.scss'
import type { CalculationPageProps } from '../../types/toolTypes'
import styles from './CalculationPage.module.scss'

export const CalculationPage: FunctionComponent<CalculationPageProps> = ({
  imgObj,
  title,
  standfirst,
  terms,
  resultData,
  backButtonData: { allFlows, toolName, text },
}) => {
  const titleRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    titleRef?.current?.focus()
  }, [])

  return (
    <div className={styles.calculationPage} data-testid="calculation-page">
      <ImageWithQuestion
        bottomMargin={globalStyles.spacing20}
        image={imgObj}
        title={title}
        titleRef={titleRef}
        titleTag="h2"
      />
      <Typography className={styles.calculationPageStandfirst}>{standfirst}</Typography>
      <div className={styles.calculationPageResultGrid}>
        {resultData.map((result) => (
          <div
            className={classNames(styles.calculationPageResult, {
              [styles.calculationPageResultTotal]: result.total,
            })}
            data-testid={result.total ? 'result-box-total' : 'result-box'}
            key={result.text}
          >
            <Typography>{result.text}</Typography>
            <span
              className={styles.calculationPageResultAmountWrapper}
              data-testid="typography-body"
            >
              <span className={styles.calculationPageResultAmountWrapperAmount}>
                £{result.amount}
              </span>
              {!result.total && (
                <span className={styles.calculationPageResultPerMonth}>per month</span>
              )}
            </span>
          </div>
        ))}
      </div>
      <div className={classNames(globalStyles.buttonWrapper, globalStyles.spacing40Top)}>
        <BackButton allFlows={allFlows} primary text={text} toolName={toolName} />
      </div>
      <Typography
        className={styles.calculationPageTerms}
        data-testid="terms"
        textStyle="very-small-print"
      >
        {terms}
      </Typography>
    </div>
  )
}
