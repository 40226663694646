import type { FunctionComponent } from 'react'
import React from 'react'
import type { NewsletterSource } from '@which/glide-ts-types'
import { Grid, GridItem, Picture } from '@which/seatbelt'

import classnames from 'classnames'

import { MoneyNewsletterSignUp } from '../MoneyNewsletterSignUp'
import styles from './MoneyNewsletterSignUpWithImage.module.scss'

export const MoneyNewsletterSignUpWithImage: FunctionComponent<Props> = ({
  className,
  source,
  image,
}) => {
  if (!source) {
    return null
  }
  return (
    <Grid
      className={classnames('w-page-wrapper', styles.newsletterSignUpWithImage)}
      data-testid="newsletter-signup-with-image"
    >
      <GridItem
        className={styles.newsletterWrapper}
        columnStart={{ medium: 1, large: 2 }}
        span={{ medium: 6, large: 4 }}
      >
        <MoneyNewsletterSignUp
          className={classnames(className, styles.newsletterSignup)}
          source={source}
        />
      </GridItem>
      <GridItem
        className={styles.imageGridItem}
        columnStart={{ medium: 7, large: 6 }}
        span={{ medium: 6, large: 7 }}
      >
        {image && (
          <div className={styles.imageWrapper}>
            <Picture
              aspectRatioMain="one-to-one"
              imageClassName={styles.image}
              {...image}
              lazyLoad={false}
            />
          </div>
        )}
      </GridItem>
    </Grid>
  )
}

type Props = {
  className?: string
  source: NewsletterSource | undefined
  image: {
    alt?: string
    caption?: string
    dimensions?: {
      height?: string
      width?: string
    }
    id?: string
    sources?: {
      media?: string
      sizes: string
      srcset: string
      type?: string
    }[]
    src: string
  }
}
