export const contactDetailsData = {
  formItems: [
    {
      displayText: 'First name',
      id: 'firstName',
      type: 'text',
      isFocused: true,
    },
    {
      displayText: 'Last name',
      id: 'lastName',
      type: 'text',
      isFocused: false,
    },
    {
      displayText: 'Email address',
      id: 'email',
      type: 'email',
      isFocused: false,
    },
  ],
  imgObj: {
    alt: 'A woman working on a laptop',
    src: 'https://media.product.which.co.uk/prod/images/original/gm-54c58a52-9955-4c5a-a4a7-90a039cbd182-parking-ticket-13.jpg',
  },
  title: 'Enter your contact details',
  buttonText: 'Send appeal email to me',
  singleConsentOptIn: {
    label:
      'Keep me updated about products and services from the Which? Group and the work it does to make life simpler, safer and fairer for UK consumers.',
    name: 'acceptedSingleConsentOptIn',
  },
}
