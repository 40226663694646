export const mockStateRed = {
  energyPayment: 'prepayment-meter',
  broadbandContract: 'yes',
  financeSituation: 'red',
  foodShop: 'convenience-stores',
  propertyPayment: 'rent',
  payingDebts: ['credit-cards', 'personal-loans', 'buy-now-pay-later'],
}

export const mockStateAmber = {
  energyPayment: 'direct-debit',
  broadbandContract: 'yes',
  financeSituation: 'amber',
  foodShop: 'supermarkets',
  propertyPayment: 'mortgage',
  payingDebts: ['credit-cards'],
}

export const mockStateGreen = {
  energyPayment: 'pay-monthly-quarterly',
  broadbandContract: 'no',
  financeSituation: 'green',
  foodShop: 'supermarkets',
  propertyPayment: 'outright',
  payingDebts: ['no-credit-cards-or-loans'],
}

export const mockStateBenefits = {
  energyPayment: 'pay-monthly-quarterly',
  broadbandContract: 'yes',
  financeSituation: 'green',
  foodShop: 'supermarkets',
  propertyPayment: 'outright',
  payingDebts: ['no-credit-cards-or-loans'],
  benefits: 'yes',
}

export const mockStateTypeNonExistent = {
  energyPayment: 'pay-monthly-quarterly',
  broadbandContract: 'no1',
  financeSituation: 'green',
  foodShop: 'supermarkets-123',
  propertyPayment: 'outright',
  payingDebts: ['no-credit-cards-or-loans'],
}

export const chromeInfo = [
  "Click the 'Save as PDF' button below",
  "Select 'Save as PDF' from the 'Destination' dropdown menu",
  'Select any settings you require',
  "Click 'Save' and choose where you would like to save your PDF",
  "Click the second 'Save' button",
]

export const defaultInfo = [
  "Click the 'Save as PDF' button below",
  "Select 'Save as PDF' from the 'Destination' dropdown menu",
  'Select any settings you require',
  "Click 'Save' and choose where you would like to save your PDF",
  "Click the second 'Save' button",
]

export const edgeInfo = [
  "Click the 'Save as PDF' button below",
  'Select any settings you require',
  "Select 'Save as PDF' from the 'Printer' dropdown menu",
  "Click 'Save' and choose where you would like to save your PDF",
  "Click the second 'Save' button",
]

export const safariInfo = [
  "Click the 'Save as PDF' button below",
  'Select any settings you require',
  "Select 'Save as PDF' from the 'PDF' dropdown menu in the bottom-left corner",
  'Choose where you would like to save your PDF',
  "Click the 'Save' button",
]
export const mockEmptyState = {
  financeSituation: '',
  propertyPayment: '',
  benefits: '',
  energyPayment: '',
  foodShop: '',
  landingPageCompleted: null,
  broadbandContract: '',
  payingDebts: [],
}

export const dataLayerResultsRevisitPush = [
  {
    event: 'tEvent',
    eventAction: 'my money health check',
    eventCategory: 'problem resolution tools',
    eventInteractive: true,
    eventLabel: 'milestone | revisit | green',
    eventValue: 0,
  },
]

export const mockPageDataProps = {
  contentItems: [
    {
      type: 'MMHC Results - Take action on your bills',
      data: {
        components: {
          collections: [
            {
              type: 'MMHC Results - Energy Bills Links',
              data: {
                components: {
                  collections: [
                    {
                      type: 'MMHC Results - Energy Bills Links - Prepayment Meter',
                      data: {
                        components: {
                          collections: [
                            {
                              type: 'link',
                              data: {
                                linkText: 'Tips on cutting your energy bills',
                                linkUrl:
                                  'https://www.which.co.uk/reviews/cutting-your-energy-bills/article/guides',
                              },
                            },
                          ],
                        },
                        customData: {
                          name: 'MMHC Results - Energy Bills Links - Prepayment Meter',
                          data: {
                            redLinks: [
                              {
                                path: '/reviews/cutting-your-energy-bills/article/is-a-prepayment-energy-meter-right-for-you-atK7y6R4AKLx',
                                headline: 'Is a prepayment energy meter right for you?',
                              },
                              {
                                path: '/reviews/cutting-your-energy-bills/article/how-to-save-on-your-energy-bill/help-if-you-re-struggling-to-pay-your-energy-bill-a4oxG4c2sVcd',
                                headline: "Help if you're struggling to pay your energy bill",
                              },
                            ],
                            amberLinks: [
                              {
                                path: '/reviews/cutting-your-energy-bills/article/is-a-prepayment-energy-meter-right-for-you-atK7y6R4AKLx',
                                headline: 'Is a prepayment energy meter right for you?',
                              },
                              {
                                path: '/reviews/cutting-your-energy-bills/article/save-electricity/saving-energy-in-the-kitchen-awsZs7i1fmZa',
                                headline: 'Saving energy in the kitchen',
                              },
                            ],
                            greenLinks: [
                              {
                                path: '/reviews/cutting-your-energy-bills/article/is-a-prepayment-energy-meter-right-for-you-atK7y6R4AKLx',
                                headline: 'Is a prepayment energy meter right for you?',
                              },
                              {
                                path: '/reviews/cutting-your-energy-bills/article/how-to-save-on-your-energy-bill/10-ways-to-save-on-energy-bills-aYSJ91l6Xkdw',
                                headline: '10 ways to save on energy bills',
                              },
                              {
                                path: '/reviews/cutting-your-energy-bills/article/save-electricity/saving-energy-in-the-kitchen-awsZs7i1fmZa',
                                headline: 'Saving energy in the kitchen',
                              },
                            ],
                          },
                        },
                      },
                    },
                    {
                      type: 'MMHC Results - Energy Bills Links - Direct Debit',
                      data: {
                        components: {
                          collections: [
                            {
                              type: 'link',
                              data: {
                                linkText: 'Tips on cutting your energy bills',
                                linkUrl:
                                  'https://www.which.co.uk/reviews/cutting-your-energy-bills/article/guides',
                              },
                            },
                          ],
                        },
                        customData: {
                          name: 'MMHC Results - Energy Bills Links - Direct Debit',
                          data: {
                            redLinks: [
                              {
                                path: '/news/article/explained-why-are-energy-direct-debits-so-high-aRdzL6m2lAcw',
                                headline:
                                  'Explained: why are energy direct debits so high? And what you can do about it',
                              },
                              {
                                path: '/reviews/cutting-your-energy-bills/article/how-to-save-on-your-energy-bill/help-if-you-re-struggling-to-pay-your-energy-bill-a4oxG4c2sVcd',
                                headline: "Help if you're struggling to pay your energy bill",
                              },
                            ],
                            amberLinks: [
                              {
                                path: '/news/article/explained-why-are-energy-direct-debits-so-high-aRdzL6m2lAcw',
                                headline:
                                  'Explained: why are energy direct debits so high? And what you can do about it',
                              },
                              {
                                path: '/reviews/cutting-your-energy-bills/article/save-electricity/saving-energy-in-the-kitchen-awsZs7i1fmZa',
                                headline: 'Saving energy in the kitchen',
                              },
                            ],
                            greenLinks: [
                              {
                                path: '/news/article/explained-why-are-energy-direct-debits-so-high-aRdzL6m2lAcw',
                                headline:
                                  'Explained: why are energy direct debits so high? And what you can do about it',
                              },
                              {
                                path: '/reviews/cutting-your-energy-bills/article/how-to-save-on-your-energy-bill/10-ways-to-save-on-energy-bills-aYSJ91l6Xkdw',
                                headline: '10 ways to save on energy bills',
                              },
                            ],
                          },
                        },
                      },
                    },
                    {
                      type: 'MMHC Results - Energy Bills Links - Pay As You Go',
                      data: {
                        components: {
                          collections: [
                            {
                              type: 'link',
                              data: {
                                linkText: 'Tips on cutting your energy bills',
                                linkUrl:
                                  'https://www.which.co.uk/reviews/cutting-your-energy-bills/article/guides',
                              },
                            },
                          ],
                        },
                        customData: {
                          name: 'MMHC Results - Energy Bills Links - Pay As You Go',
                          data: {
                            redLinks: [
                              {
                                path: '/reviews/cutting-your-energy-bills/article/how-to-save-on-your-energy-bill/help-if-you-re-struggling-to-pay-your-energy-bill-a4oxG4c2sVcd',
                                headline: "Help if you're struggling to pay your energy bill",
                              },
                              {
                                path: '/reviews/cutting-your-energy-bills/article/save-electricity/saving-energy-in-the-kitchen-awsZs7i1fmZa',
                                headline: 'Saving energy in the kitchen',
                              },
                            ],
                            amberLinks: [
                              {
                                path: '/reviews/cutting-your-energy-bills/article/how-to-save-on-your-energy-bill/10-ways-to-save-on-energy-bills-aYSJ91l6Xkdw',
                                headline: '10 ways to save on energy bills',
                              },
                              {
                                path: '/reviews/cutting-your-energy-bills/article/save-electricity/saving-energy-in-the-kitchen-awsZs7i1fmZa',
                                headline: 'Saving energy in the kitchen',
                              },
                            ],
                            greenLinks: [
                              {
                                path: '/reviews/cutting-your-energy-bills/article/how-to-save-on-your-energy-bill/10-ways-to-save-on-energy-bills-aYSJ91l6Xkdw',
                                headline: '10 ways to save on energy bills',
                              },
                              {
                                path: '/reviews/cutting-your-energy-bills/article/save-electricity/saving-energy-in-the-kitchen-awsZs7i1fmZa',
                                headline: 'Saving energy in the kitchen',
                              },
                            ],
                          },
                        },
                      },
                    },
                  ],
                },
                customData: {
                  name: 'MMHC Results - Energy Bills Links',
                  data: {
                    title: 'Energy bills',
                    image: {
                      src: 'https://media.product.which.co.uk/prod/images/original/d310802be9b4-energy.jpg',
                      imageAlt: 'A woman looking at a thermostat',
                    },
                  },
                },
              },
            },
            {
              type: 'MMHC Results - Food Shopping Links',
              data: {
                components: {
                  collections: [
                    {
                      type: 'MMHC Results - Food Shopping Links - Supermarkets',
                      data: {
                        components: {
                          collections: [
                            {
                              type: 'link',
                              data: {
                                linkText: 'All shopping food and drink news',
                                linkUrl:
                                  'https://www.which.co.uk/news/home-garden/shopping-food-drink',
                              },
                            },
                          ],
                        },
                        customData: {
                          name: 'MMHC Results - Food Shopping Links - Supermarkets',
                          data: {
                            redLinks: [
                              {
                                path: '/news/article/best-cheap-supermarket-food-and-drink-the-own-labels-that-beat-the-big-brands-aH9P81U5lkhr',
                                headline:
                                  'Best cheap supermarket food and drink: the own-labels that beat the big brands',
                              },
                              {
                                path: '/reviews/supermarkets/article/supermarket-price-comparison-a0yCY2W1X4t8',
                                headline: 'Supermarket price comparison',
                              },
                            ],
                            amberLinks: [
                              {
                                path: '/news/article/best-cheap-supermarket-food-and-drink-the-own-labels-that-beat-the-big-brands-aH9P81U5lkhr',
                                headline:
                                  'Best cheap supermarket food and drink: the own-labels that beat the big brands',
                              },
                              {
                                path: '/reviews/supermarkets/article/supermarket-price-comparison-a0yCY2W1X4t8',
                                headline: 'Supermarket price comparison',
                              },
                            ],
                            greenLinks: [
                              {
                                path: '/news/article/best-cheap-supermarket-food-and-drink-the-own-labels-that-beat-the-big-brands-aH9P81U5lkhr',
                                headline:
                                  'Best cheap supermarket food and drink: the own-labels that beat the big brands',
                              },
                              {
                                path: '/reviews/supermarkets/article/supermarket-price-comparison-a0yCY2W1X4t8',
                                headline: 'Supermarket price comparison',
                              },
                            ],
                          },
                        },
                      },
                    },
                    {
                      type: 'MMHC Results - Food Shopping Links - Convenience Stores',
                      data: {
                        components: {
                          collections: [
                            {
                              type: 'link',
                              data: {
                                linkText: 'All shopping food and drink news',
                                linkUrl:
                                  'https://www.which.co.uk/news/home-garden/shopping-food-drink',
                              },
                            },
                          ],
                        },
                        customData: {
                          name: 'MMHC Results - Food Shopping Links - Convenience Stores',
                          data: {
                            redLinks: [
                              {
                                path: '/news/article/best-cheap-supermarket-food-and-drink-the-own-labels-that-beat-the-big-brands-aH9P81U5lkhr',
                                headline:
                                  'Best cheap supermarket food and drink: the own-labels that beat the big brands',
                              },
                              {
                                path: '/news/article/the-cost-of-convenience-how-much-extra-will-you-pay-at-sainsburys-local-and-tesco-express-aiO0q8V4oDiT',
                                headline:
                                  "The cost of convenience: how much extra will you pay at Sainsbury's Local and Tesco Express?",
                              },
                            ],
                            amberLinks: [
                              {
                                path: '/news/article/best-cheap-supermarket-food-and-drink-the-own-labels-that-beat-the-big-brands-aH9P81U5lkhr',
                                headline:
                                  'Best cheap supermarket food and drink: the own-labels that beat the big brands',
                              },
                              {
                                path: '/news/article/the-cost-of-convenience-how-much-extra-will-you-pay-at-sainsburys-local-and-tesco-express-aiO0q8V4oDiT',
                                headline:
                                  "The cost of convenience: how much extra will you pay at Sainsbury's Local and Tesco Express?",
                              },
                            ],
                            greenLinks: [
                              {
                                path: '/news/article/best-cheap-supermarket-food-and-drink-the-own-labels-that-beat-the-big-brands-aH9P81U5lkhr',
                                headline:
                                  'Best cheap supermarket food and drink: the own-labels that beat the big brands',
                              },
                              {
                                path: '/news/article/the-cost-of-convenience-how-much-extra-will-you-pay-at-sainsburys-local-and-tesco-express-aiO0q8V4oDiT',
                                headline:
                                  "The cost of convenience: how much extra will you pay at Sainsbury's Local and Tesco Express?",
                              },
                            ],
                          },
                        },
                      },
                    },
                  ],
                },
                customData: {
                  name: 'MMHC Results - Food Shopping Links',
                  data: {
                    title: 'Food shopping',
                    image: {
                      src: 'https://media.product.which.co.uk/prod/images/original/f689a00c06cb-food.jpg',
                      imageAlt: 'A girl passing a leek to a woman',
                    },
                  },
                },
              },
            },
            {
              type: 'MMHC Results - Telecom Services Links',
              data: {
                components: {
                  collections: [
                    {
                      type: 'MMHC Results - Telecom Services Links - In Contract',
                      data: {
                        components: {
                          collections: [
                            {
                              type: 'MMHC Results -Telecom Services Links - In Contract - Benefits',
                              data: {
                                components: {
                                  collections: [],
                                },
                                customData: {
                                  name: 'MMHC Results -Telecom Services Links - In Contract - Benefits',
                                  data: {
                                    redLinks: [
                                      {
                                        path: '/money/investing/learn-how-investing-works/five-tips-for-diversifying-your-portfolio-aiuO78q5GvmK',
                                        headline: 'Five tips for diversifying your portfolio',
                                      },
                                    ],
                                    amberLinks: [
                                      {
                                        path: '/money/investing/learn-how-investing-works/five-tips-for-diversifying-your-portfolio-aiuO78q5GvmK',
                                        headline: 'Five tips for diversifying your portfolio',
                                      },
                                    ],
                                    greenLinks: [
                                      {
                                        path: '/money/investing/learn-how-investing-works/five-tips-for-diversifying-your-portfolio-aiuO78q5GvmK',
                                        headline: 'Five tips for diversifying your portfolio',
                                      },
                                    ],
                                  },
                                },
                              },
                            },
                            {
                              type: 'link',
                              data: {
                                linkText: 'All Which? broadband guides',
                                linkUrl: 'https://www.which.co.uk/reviews/broadband/article/guides',
                              },
                            },
                          ],
                        },
                        customData: {
                          name: 'MMHC Results - Telecom Services Links - In Contract',
                          data: {
                            redLinks: [
                              {
                                path: '/news/article/ease-the-squeeze-how-to-save-on-mobile-broadband-and-tv-atv5a9U1zhQm',
                                headline:
                                  'Ease the squeeze: how to save on mobile, broadband and TV',
                              },
                            ],
                            amberLinks: [
                              {
                                path: '/news/article/sky-and-now-launch-cheap-broadband-deals-with-new-social-tariffs-avgBq9i7JA8z',
                                headline:
                                  'Sky and Now launch cheap broadband deals with new social tariffs',
                              },
                            ],
                            greenLinks: [
                              {
                                path: '/news/article/best-and-worst-broadband-providers-for-2022-big-names-come-up-short-a5bv91B3rwWZ',
                                headline: 'Best and worst broadband providers for 2022',
                              },
                            ],
                          },
                        },
                      },
                    },
                    {
                      type: 'MMHC Results - Telecom Services Links - No Contract',
                      data: {
                        components: {
                          collections: [
                            {
                              type: 'MMHC Results -Telecom Services Links - No Contract - Benefits',
                              data: {
                                components: {
                                  collections: [],
                                },
                                customData: {
                                  name: 'MMHC Results -Telecom Services Links - No Contract - Benefits',
                                  data: {
                                    redLinks: [
                                      {
                                        path: '/money/investing/learn-how-investing-works/five-tips-for-diversifying-your-portfolio-aiuO78q5GvmK',
                                        headline: '21 money-saving tips for parents',
                                      },
                                    ],
                                    amberLinks: [
                                      {
                                        path: '/money/investing/learn-how-investing-works/five-tips-for-diversifying-your-portfolio-aiuO78q5GvmK',
                                        headline: 'Five tips for diversifying your portfolio',
                                      },
                                    ],
                                    greenLinks: [
                                      {
                                        path: '/money/investing/learn-how-investing-works/five-tips-for-diversifying-your-portfolio-aiuO78q5GvmK',
                                        headline: '21 money-saving tips for parents',
                                      },
                                    ],
                                  },
                                },
                              },
                            },
                            {
                              type: 'link',
                              data: {
                                linkText: 'All Which? broadband guides',
                                linkUrl: 'https://www.which.co.uk/reviews/broadband/article/guides',
                              },
                            },
                          ],
                        },
                        customData: {
                          name: 'MMHC Results - Telecom Services Links - No Contract',
                          data: {
                            redLinks: [
                              {
                                path: '/reviews/broadband-deals/article/how-to-haggle-for-the-best-broadband-deal-adbp14r5WJRT',
                                headline: 'How to haggle for the best broadband deal',
                              },
                            ],
                            amberLinks: [
                              {
                                path: '/reviews/broadband-deals/article/how-to-haggle-for-the-best-broadband-deal-adbp14r5WJRT',
                                headline: 'How to haggle for the best broadband deal',
                              },
                            ],
                            greenLinks: [
                              {
                                path: '/reviews/broadband-deals/article/how-to-haggle-for-the-best-broadband-deal-adbp14r5WJRT',
                                headline: 'How to haggle for the best broadband deal',
                              },
                            ],
                          },
                        },
                      },
                    },
                  ],
                },
                customData: {
                  name: 'MMHC Results - Telecom Services Links',
                  data: {
                    title: 'Telecom services',
                    image: {
                      src: 'https://media.product.which.co.uk/prod/images/original/50789fabcfa0-telecom.jpg',
                      imageAlt: 'A man wearing a hat holding a coffee cup looking at his phone',
                    },
                  },
                },
              },
            },
          ],
        },
        customData: {
          name: 'MMHC Results - Take action on your bills',
          data: {
            title: '1. Take action on your bills',
            standfirst:
              "You've said that you <dynamicEnergyText>, are <dynamicBroadbandText> fixed broadband contract and you do most of your shopping at a <dynamicShoppingText>. Based on your responses, our tool has picked out a selection of money-saving guides written by our experts:",
            image: '',
            imageAlt: '',
            backgroundColour: '',
          },
        },
      },
    },
    {
      type: 'MMHC Results - Take action on your finances',
      data: {
        components: {
          collections: [
            {
              type: 'MMHC Results - Housing Links',
              data: {
                components: {
                  collections: [
                    {
                      type: 'MMHC Results - Housing Links - Rent',
                      data: {
                        components: {
                          collections: [
                            {
                              type: 'link',
                              data: {
                                linkText: 'All mortgages & property news',
                                linkUrl: 'https://www.which.co.uk/news/money/mortgages-property',
                              },
                            },
                          ],
                        },
                        customData: {
                          name: 'MMHC Results - Housing Links - Rent',
                          data: {
                            redLinks: [
                              {
                                path: '/consumer-rights/advice/what-are-my-tenants-rights-when-renting-from-a-private-landlord-aP75m3j7IxEP',
                                headline:
                                  'What are my tenants rights when renting from a private landlord?',
                              },
                            ],
                            amberLinks: [
                              {
                                path: '/consumer-rights/advice/what-are-my-tenants-rights-when-renting-from-a-private-landlord-aP75m3j7IxEP',
                                headline:
                                  'What are my tenants rights when renting from a private landlord?',
                              },
                            ],
                            greenLinks: [
                              {
                                path: '/consumer-rights/advice/what-are-my-tenants-rights-when-renting-from-a-private-landlord-aP75m3j7IxEP',
                                headline:
                                  'What are my tenants rights when renting from a private landlord?',
                              },
                            ],
                          },
                        },
                      },
                    },
                    {
                      type: 'MMHC Results - Housing Links - Mortgage',
                      data: {
                        components: {
                          collections: [
                            {
                              type: 'link',
                              data: {
                                linkText: "What to do if you can't pay your mortgage",
                                linkUrl:
                                  'https://www.which.co.uk/money/mortgages-and-property/mortgages/what-to-do-if-you-cant-pay-your-mortgage-a8vbl6b8nbsc',
                              },
                            },
                          ],
                        },
                        customData: {
                          name: 'MMHC Results - Housing Links - Mortgage',
                          data: {
                            redLinks: [
                              {
                                path: '/news/article/will-your-bank-offer-you-the-best-rate-when-remortgaging-a5QED1x1AP2U',
                                headline:
                                  'Will your bank offer you the best rate when remortgaging?',
                              },
                            ],
                            amberLinks: [
                              {
                                path: '/news/article/will-your-bank-offer-you-the-best-rate-when-remortgaging-a5QED1x1AP2U',
                                headline:
                                  'Will your bank offer you the best rate when remortgaging?',
                              },
                            ],
                            greenLinks: [
                              {
                                path: '/news/article/revealed-the-best-mortgage-lenders-of-2021-aJtLN7p9OqF3',
                                headline: 'Revealed: the best mortgage lenders of 2021',
                              },
                              {
                                path: '/news/article/will-your-bank-offer-you-the-best-rate-when-remortgaging-a5QED1x1AP2U',
                                headline:
                                  'Will your bank offer you the best rate when remortgaging?',
                              },
                            ],
                          },
                        },
                      },
                    },
                    {
                      type: 'MMHC Results - Housing Links - Outright',
                      data: {
                        components: {
                          collections: [
                            {
                              type: 'link',
                              data: {
                                linkText: 'All mortgages & property news',
                                linkUrl: 'https://www.which.co.uk/news/money/mortgages-property',
                              },
                            },
                          ],
                        },
                        customData: {
                          name: 'MMHC Results - Housing Links - Outright',
                          data: {
                            redLinks: '',
                            amberLinks: [
                              {
                                path: '/news/article/how-to-make-big-savings-with-an-energy-efficient-appliance-aGDk52y321BL',
                                headline:
                                  'How to make big savings with an energy efficient appliance',
                              },
                            ],
                            greenLinks: [
                              {
                                path: '/news/article/how-to-make-big-savings-with-an-energy-efficient-appliance-aGDk52y321BL',
                                headline:
                                  'How to make big savings with an energy efficient appliance',
                              },
                              {
                                path: '/reviews/ground-and-air-source-heat-pumps/article/air-source-heat-pumps-explained-aVFm93X849ir',
                                headline: 'Air source heat pumps explained',
                              },
                              {
                                path: '/reviews/solar-panels/article/solar-panels/is-solar-pv-a-good-investment-atl1I8D2qisv',
                                headline: 'Is solar PV a good investment?',
                              },
                            ],
                          },
                        },
                      },
                    },
                  ],
                },

                customData: {
                  name: 'MMHC Results - Housing Links',
                  data: {
                    title: 'Housing',
                    image: {
                      src: 'https://media.product.which.co.uk/prod/images/original/a20cfa3447c5-housing.jpg',
                      imageAlt: 'A woman with her dog at home',
                    },
                  },
                },
              },
            },
            {
              type: 'MMHC Results - Everyday Banking and Debt Links',
              data: {
                components: {
                  collections: [
                    {
                      type: 'MMHC Results - Everyday Banking and Debt - Credit Cards',
                      data: {
                        components: {
                          collections: [],
                        },
                        customData: {
                          name: 'MMHC Results - Everyday Banking and Debt - Credit Cards',
                          data: {
                            redLinks: [
                              {
                                path: '/money/investing/investment-platforms/investment-platforms-reviewed/bestinvest-investment-platform-review-a5foa9v5Wb3B',
                                headline: 'Bestinvest investment platform review',
                              },
                            ],
                            amberLinks: [
                              {
                                path: '/money/credit-cards-and-loans/credit-cards/best-credit-card-deals/best-low-interest-credit-cards-aJDhb8s5ecTg',
                                headline: 'Best low-interest credit cards',
                              },
                              {
                                path: '/money/credit-cards-and-loans/credit-cards/best-credit-card-deals/best-0-balance-transfer-credit-cards-aJ8xp2w07RnJ',
                                headline: 'Best 0% balance transfer credit cards',
                              },
                              {
                                path: '/money/credit-cards-and-loans/debt-and-how-to-deal-with-it/free-debt-advice-contacts-aI2730Y4NgsI',
                                headline: 'Free debt advice contacts',
                              },
                            ],
                            greenLinks: [
                              {
                                path: '/money/credit-cards-and-loans/credit-cards/best-credit-card-deals/best-cash-back-credit-cards-ag4sK9A0kLdN',
                                headline: 'Best cashback credit cards',
                              },
                              {
                                path: '/money/credit-cards-and-loans/credit-cards/credit-card-companies/best-and-worst-credit-card-providers-auShf5E80Xlj',
                                headline: 'Best and worst credit card providers',
                              },
                            ],
                          },
                        },
                      },
                    },
                    {
                      type: 'MMHC Results - Everyday Banking and Debt - Personal Loans',
                      data: {
                        components: {
                          collections: [
                            {
                              type: 'link',
                              data: {
                                linkText: 'All borrowing news',
                                linkUrl: 'https://www.which.co.uk/news/money/credit-cards-loans',
                              },
                            },
                          ],
                        },
                        customData: {
                          name: 'MMHC Results - Everyday Banking and Debt - Personal Loans',
                          data: {
                            redLinks: [
                              {
                                path: '/money/credit-cards-and-loans/loans/best-personal-loan-deals-aajF70J62NWK',
                                headline: 'Best personal loan deals',
                              },
                              {
                                path: '/money/credit-cards-and-loans/loans/personal-loans-explained-alYJ16x99B6B',
                                headline: 'Personal loans explained',
                              },
                            ],
                            amberLinks: [
                              {
                                path: '/money/credit-cards-and-loans/loans/best-personal-loan-deals-aajF70J62NWK',
                                headline: 'Best personal loan deals',
                              },
                              {
                                path: '/money/credit-cards-and-loans/loans/personal-loans-explained-alYJ16x99B6B',
                                headline: 'Personal loans explained',
                              },
                              {
                                path: '/money/credit-cards-and-loans/debt-and-how-to-deal-with-it/10-tips-on-paying-off-your-debts-aLXVX7O57301',
                                headline: 'How to pay off your debts',
                              },
                            ],
                            greenLinks: [
                              {
                                path: '/money/credit-cards-and-loans/loans/best-personal-loan-deals-aajF70J62NWK',
                                headline: 'Best personal loan deals',
                              },
                              {
                                path: '/money/credit-cards-and-loans/loans/personal-loans-explained-alYJ16x99B6B',
                                headline: 'Personal loans explained',
                              },
                              {
                                path: '/money/credit-cards-and-loans/debt-and-how-to-deal-with-it/10-tips-on-paying-off-your-debts-aLXVX7O57301',
                                headline: 'How to pay off your debts',
                              },
                            ],
                          },
                        },
                      },
                    },
                    {
                      type: 'MMHC Results - Everyday Banking and Debt - Buy Now Pay Later Loans',
                      data: {
                        components: {
                          collections: [
                            {
                              type: 'link',
                              data: {
                                linkText: 'All borrowing news',
                                linkUrl: 'https://www.which.co.uk/news/money/credit-cards-loans',
                              },
                            },
                          ],
                        },
                        customData: {
                          name: 'MMHC Results - Everyday Banking and Debt - Buy Now Pay Later Loans',
                          data: {
                            redLinks: [
                              {
                                path: '/money/credit-cards-and-loans/credit-cards/buy-now-pay-later-schemes-explained-aNDNk9V8syUt',
                                headline: "'Buy now, pay later' schemes explained",
                              },
                            ],
                            amberLinks: [
                              {
                                path: '/money/credit-cards-and-loans/credit-cards/buy-now-pay-later-schemes-explained-aNDNk9V8syUt',
                                headline: "'Buy now, pay later' schemes explained",
                              },
                            ],
                            greenLinks: [
                              {
                                path: '/money/credit-cards-and-loans/credit-cards/buy-now-pay-later-schemes-explained-aNDNk9V8syUt',
                                headline: "'Buy now, pay later' schemes explained",
                              },
                            ],
                          },
                        },
                      },
                    },
                    {
                      type: 'MMHC Results - Everyday Banking and Debt - No Credit Cards or Loans',
                      data: {
                        components: {
                          collections: [
                            {
                              type: 'link',
                              data: {
                                linkText: 'All borrowing news',
                                linkUrl: 'https://www.which.co.uk/news/money/credit-cards-loans',
                              },
                            },
                            {
                              type: 'link',
                              data: {
                                linkText: 'All savings news',
                                linkUrl: 'https://www.which.co.uk/news/money/savings-isas',
                              },
                            },
                          ],
                        },
                        customData: {
                          name: 'MMHC Results - Everyday Banking and Debt - No Credit Cards or Loans',
                          data: {
                            redLinks: [
                              {
                                path: '/money/savings-and-isas/savings-accounts/how-to-find-the-best-savings-account-aSnUL6L8WLo6',
                                headline: 'How to find the best savings account',
                              },
                            ],
                            amberLinks: [
                              {
                                path: '/money/savings-and-isas/savings-accounts/how-to-find-the-best-savings-account-aSnUL6L8WLo6',
                                headline: 'How to find the best savings account',
                              },
                              {
                                path: '/money/savings-and-isas/savings-accounts/how-to-switch-your-savings-account-a4zFM5b7YG9F',
                                headline: 'How to switch your savings account',
                              },
                              {
                                path: '/money/credit-cards-and-loans/credit-cards/should-i-get-a-credit-card-apAyx3W8SpwU',
                                headline: 'Should I get a credit card?',
                              },
                            ],
                            greenLinks: [
                              {
                                path: '/money/savings-and-isas/savings-accounts/how-to-find-the-best-savings-account-aSnUL6L8WLo6',
                                headline: 'How to find the best savings account',
                              },
                              {
                                path: '/money/savings-and-isas/savings-accounts/how-to-switch-your-savings-account-a4zFM5b7YG9F',
                                headline: 'How to switch your savings account',
                              },
                            ],
                          },
                        },
                      },
                    },
                  ],
                },
                customData: {
                  name: 'MMHC Results - Everyday Banking and Debt Links',
                  data: {
                    title: 'Everyday banking and debt',
                    image: {
                      src: 'https://media.product.which.co.uk/prod/images/original/2bd90b1fcd2a-loans.jpg',
                      imageAlt: 'A woman sitting down at a desk with a computer and a lot of files',
                    },
                  },
                },
              },
            },
          ],
        },
        customData: {
          name: 'MMHC Results - Take action on your finances',
          data: {
            title: '2. Take action on your finances',
            standfirst:
              "You've said that you <dynamicPropertyText>, and are <dynamicPayingDebtsText>. Based on your responses, our tool has picked out a selection of money-saving guides written by our experts:",
            image: '',
            imageAlt: '',
            backgroundColour: '',
          },
        },
      },
    },
    {
      type: 'COLSection',
      data: {
        backgroundColour: 'test',
        buttonLink: 'test',
        buttonText: 'test',
        imageAlt: 'test',
        imageSrc: 'image',
        standfirst: 'test',
        title: 'Cost of living help test',
      },
    },
    {
      type: 'Money Helpline',
      data: {
        title: 'Make your money go further',
        standfirst:
          '<div data-testid="content"><pre>Find the best deals, avoid scams and protect your savings and investments with expert guidance from Which? Money magazine and Which? Money Helpline*.<br><br></pre><div data-testid="content"><pre><strong>Join Which? Money for £4.99 a month or save 18% by paying £49 a year</strong></pre></div></div>',
        buttonText: 'Join Which? Money',
        buttonLink: 'https://signup.which.co.uk/wlp-money',
        bottomText:
          '<pre>*Other Which? subscriptions include the helpline. Need to check if you already have access? Call 029 2267 7000. You can also join by calling 029 2267 0005. Mon-Fri: 08:30-18:00 &amp; Sat: 09:00-13:00.<br>Cancel any time and we’ll take no more payments.<br>Annual saving is compared to the cost of 12 monthly payments.</pre>',
        image: {
          src: 'https://nonprod-media.glide.product.which.co.uk/dev/images/original/6d21771583c9-screenshot-2023-02-15-at-083906.png',
          alt: '',
        },
        backgroundColour: '#DADBEB',
      },
    },
    {
      type: 'MMHC Results - Changes needed',
      data: {
        components: {
          collections: [
            {
              type: '',
              data: {
                linkUrl: '',
                img: {
                  alt: '',
                  src: '',
                },
                title: 'We want affordable food for all',
                body: '',
              },
            },
          ],
        },
        customData: {
          data: {
            backgroundColour: '',
            image: '',
            imageAlt: '',
            standfirst: 'Campaign section standfirst',
            title: 'Change is needed',
          },
          name: '',
        },
      },
    },
    {
      type: 'MMHC Results - Quick Tips',
      data: {
        components: {
          collections: [
            {
              type: '',
              data: {
                icon: '',
                title: '',
                body: '',
              },
            },
          ],
        },
        customData: {
          data: {
            title: 'Quick tips to cut your bills fast',
          },
        },
      },
    },
    {
      type: 'Podcast',
      data: {
        title: 'The Which? Money Podcast',
        standfirst: 'Podcast standfirst text',
        buttonText: 'See more Which? podcasts',
        buttonLink: 'https://www.which.co.uk/news/tag/podcasts',
        podcastUrl: 'https://player.captivate.fm/show/d716add8-8556-46aa-8d6e-fda13e8ef77d/latest/',
      },
    },
  ],
  header: {
    title: 'This is a test',
    amberRouteIntro: 'This is a test amber route intro',
    greenRouteIntro: 'This is a test green route intro',
    redRouteIntro: 'This is a test red route intro',
  },
}

export const mockPageDataNoColPodProps = {
  header: {
    title: 'This is a test',
    amberRouteIntro: 'This is a test amber route intro',
    greenRouteIntro: 'This is a test green route intro',
    redRouteIntro: 'This is a test red route intro',
  },
}

export const mockSectionLinks = {
  billsSectionLinks: {
    blockTitle: 'Bills block title',
    link: {
      href: 'href',
      src: 'src',
    },
  },
  billsSectionTitle: 'bills title',
  financeSectionLinks: {
    blockTitle: 'Finance block title',
    link: {
      href: 'href',
      src: 'src',
    },
  },
  financeSectionTitle: 'finance title',
}
