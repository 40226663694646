export const entitlementData = {
  option1: {
    title:
      'Based on what you told us, you may be eligible to submit a Section 75 claim to your card provider.',
    standfirst:
      "If it's been under 30 days since you made the purchase, you should ask the retailer directly for a refund before submitting an S75 claim – your statutory rights entitle you to this.",
    bodyCopy: [
      "And if it's been between 30 days and 6 months since you made the purchase, you similarly have statutory rights for a replacement or repair from the retailer, so should contact them first.",
      "If you'd like to continue with the S75 claim, please give us a bit more information.",
    ],
    imageTitle: 'Who is your card provider?',
    imageURL:
      'https://media.product.which.co.uk/prod/images/original/gm-fd858caf-64e6-4a2a-8598-fb7320607ee6-resolution.jpg',
    journeyFlowBranch: 's75',
  },
  option2: {
    title:
      'Based on what you told us, you may be eligible to submit a Section 75 claim to your card provider.',
    standfirst:
      'Successful S75 claims are typically in cases where: the seller refused to provide the service again for free, or within a reasonable timeframe.',
    bodyCopy: [
      "Or where they provided it again but it still didn't match what you'd agreed to pay for.",
      "If you'd like to continue with a claim, please give us a bit more information.",
    ],
    imageTitle: 'Who is your card provider?',
    imageURL:
      'https://media.product.which.co.uk/prod/images/original/gm-fd858caf-64e6-4a2a-8598-fb7320607ee6-resolution.jpg',
    journeyFlowBranch: 's75',
  },
  option3: {
    title:
      'Based on what you told us you may be eligible to submit a chargeback claim to your card provider.',
    standfirst:
      'This means you can try to get your provider to reverse the charge and return the money to your account.',
    bodyCopy: [
      "Many card companies have their own online forms for chargeback claims, which you can often access from your online banking. We'd recommend you try this first to save yourself time.",
      "If you'd like to continue with this chargeback claim, please give us a bit more information.",
    ],
    imageTitle: 'Who is your card provider?',
    imageURL:
      'https://media.product.which.co.uk/prod/images/original/gm-fd858caf-64e6-4a2a-8598-fb7320607ee6-resolution.jpg',
    journeyFlowBranch: 'chargeback',
  },
  option4: {
    title:
      'Based on what you told us you may be eligible to submit a chargeback claim to your card provider.',
    standfirst:
      'This means you can try to get your provider to reverse the charge and return the money to your account.',
    bodyCopy: [
      'Chargeback claims are usually only successful if submitted within 120 days of the purchase.',
      "But in certain cases they may still be successful if it's been longer than this.",
      "If you'd like to continue with a claim, please give us a bit more information.",
    ],
    imageTitle: 'Who is your card provider?',
    imageURL:
      'https://media.product.which.co.uk/prod/images/original/gm-fd858caf-64e6-4a2a-8598-fb7320607ee6-resolution.jpg',
    journeyFlowBranch: 'chargeback',
  },
}
