import React from 'react'

import { ToolFeedbackAudiencePage } from '../../../shared/pages/ToolFeedbackAudiencePage'
import { toolFeedbackAudienceData } from '../../../shared/pages/ToolFeedbackAudiencePage/data'
import { ToolFeedbackPage } from '../../../shared/pages/ToolFeedbackPage'
import { toolFeedbackData } from '../../../shared/pages/ToolFeedbackPage/data/misSoldPackagedBankAccountComplaintData'
import { ToolFeedbackThankYouPage } from '../../../shared/pages/ToolFeedbackThankYouPage'
import { AccountStillOpen } from '../PageComponents/AccountStillOpen'
import { ClaimCompensation } from '../PageComponents/ClaimCompensation'
import { ContactDetails } from '../PageComponents/ContactDetails'
import { DateOpenedAccount } from '../PageComponents/DateOpenedAccount'
import { HowAccountSold } from '../PageComponents/HowAccountSold'
import { LedToPackagedBankAccount } from '../PageComponents/LedToPackagedBankAccount'
import { LimitedOptions } from '../PageComponents/LimitedOptions'
import { MonthlyFee } from '../PageComponents/MonthlyFee'
import { NameOfAccount } from '../PageComponents/NameOfAccount'
import { NameOfBank } from '../PageComponents/NameOfBank'
import { SubmitSuccessful } from '../PageComponents/SubmitSuccessful'
import { WhatToDo } from '../PageComponents/WhatToDo'
import { WhyMisSoldAccount } from '../PageComponents/WhyMisSoldAccount'
import {
  AccountStillOpenFlow,
  ClaimCompensationFlow,
  ContactDetailsFlow,
  DateOpenedAccountFlow,
  HowAccountSoldFlow,
  LedYouToPackagedBankAccountFlow,
  LimitedOptionsFlow,
  MonthlyFeeFlow,
  NameOfAccountFlow,
  NameOfBankFlow,
  SubmitSuccessfulFlow,
  ToolFeedbackAudienceFlow,
  ToolFeedbackFlow,
  ToolFeedbackThankYouFlow,
  WhatLikeToDoFlow,
  WhyMisSoldAccountFlow,
} from '../pageFlowData'

export const useRenderStep = (step: number, CRFooter: React.ReactNode) => {
  switch (step) {
    case MonthlyFeeFlow.STEP:
      return <MonthlyFee />
    case LedYouToPackagedBankAccountFlow.STEP:
      return <LedToPackagedBankAccount />
    case WhyMisSoldAccountFlow.STEP:
      return <WhyMisSoldAccount />
    case HowAccountSoldFlow.STEP:
      return <HowAccountSold />
    case AccountStillOpenFlow.STEP:
      return <AccountStillOpen />
    case WhatLikeToDoFlow.STEP:
      return <WhatToDo />
    case ClaimCompensationFlow.STEP:
      return <ClaimCompensation />
    case NameOfBankFlow.STEP:
      return <NameOfBank />
    case NameOfAccountFlow.STEP:
      return <NameOfAccount />
    case DateOpenedAccountFlow.STEP:
      return <DateOpenedAccount />
    case ContactDetailsFlow.STEP:
      return <ContactDetails />
    case SubmitSuccessfulFlow.STEP:
      return <SubmitSuccessful CRFooter={CRFooter} />
    case ToolFeedbackFlow.STEP:
      return (
        <ToolFeedbackPage
          pageData={toolFeedbackData}
          toolFeedbackAudienceFlowData={ToolFeedbackAudienceFlow}
          toolFeedbackFlowData={ToolFeedbackFlow}
          toolName="MisSoldPackagedBankAccount"
        />
      )
    case ToolFeedbackThankYouFlow.STEP:
      return <ToolFeedbackThankYouPage toolFeedbackThankYouFlowData={ToolFeedbackThankYouFlow} />
    case ToolFeedbackAudienceFlow.STEP:
      return (
        <ToolFeedbackAudiencePage
          pageData={toolFeedbackAudienceData}
          toolFeedbackAudienceFlowData={ToolFeedbackAudienceFlow}
          toolFeedbackThankYouFlowData={ToolFeedbackThankYouFlow}
          toolName="MisSoldPackagedBankAccount"
        />
      )
    case LimitedOptionsFlow.STEP:
      return <LimitedOptions />

    default:
      return <div>MisSoldPackagedBankAccount Tool {CRFooter}</div>
  }
}
