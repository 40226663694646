import type { FunctionComponent } from 'react'
import React from 'react'
import { ButtonLink, Typography } from '@which/seatbelt'

import styles from '../ResultsPage.module.scss'

export const EmailSent: FunctionComponent<Props> = ({ emailAddress, firstName }) => (
  <div className={styles.boxedCard}>
    <Typography
      className="sb-padding-bottom-3"
      data-testid="generic-card-title"
      tag="h4"
      textStyle="title-400"
    >
      Thanks {firstName}. We've now sent a copy of your results to {emailAddress}.
    </Typography>

    <Typography className="sb-padding-bottom-3" textStyle="body">
      We're all feeling the pinch of the cost of living. Get the latest advice on how to manage day
      to day spending and where you can start making savings straight away with Which?s expert
      research and recent updates.
    </Typography>

    <ButtonLink
      className={styles.downloadButton}
      href={'https://www.which.co.uk/topic/cost-of-living'}
      rel="noopener noreferrer"
      target="_blank"
    >
      Get the latest money saving tips
    </ButtonLink>
  </div>
)

type Props = {
  emailAddress: string
  firstName: string
}
