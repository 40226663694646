export const ctaBlockData = {
  title: 'Considering switching?',
  standfirst:
    "See how much you could save - we'll tell you if a provider you're interested in will hike prices annually.",
  cta1: {
    label: 'Broadband deals',
    url: 'https://broadband.which.co.uk/?utm_source=campaigns&utm_medium=referral&utm_campaign=pricerisecalculator',
  },
  cta2: {
    label: 'Mobile deals',
    url: 'https://mobilephones.which.co.uk/?utm_source=campaigns&utm_medium=referral&utm_campaign=pricerisecalculator',
  },
  campaignPromo:
    "Join our campaign to end unpredictable mid-contract price rises: <a class='sb-link-primary' href='https://www.which.co.uk/campaigns/the-right-to-connect?utm_source=campaigns&utm_medium=referral&utm_campaign=pricerisecalculator' target='_blank'><span class='sb-link-animation-wrapper'>sign our petition</span></a> today.",
}
