import type { PageFlowProps } from '../../../shared/types/toolTypes'

export const EntryPointFlow: PageFlowProps = {
  MILESTONE: '',
  PROGRESS_VALUE: 0,
  REDUCERS: [''],
  STEP: 0,
  VIEW_NAME: 'product-type',
}

export const PurchaseMethodFlow: PageFlowProps = {
  PROGRESS_VALUE: 10,
  STEP: 1,
  VIEW_NAME: 'purchase-method',
  REDUCERS: ['UPDATE_PURCHASE_METHOD'],
  MILESTONE: 'milestone | start',
}

export const PurchasePriceFlow: PageFlowProps = {
  PROGRESS_VALUE: 20,
  STEP: 2,
  VIEW_NAME: 'purchase-price',
  REDUCERS: ['UPDATE_PRODUCT_PRICE'],
  MILESTONE: '',
}

export const PurchasedDateFlow: PageFlowProps = {
  PROGRESS_VALUE: 30,
  STEP: 3,
  VIEW_NAME: 'purchased-date',
  REDUCERS: ['UPDATE_PURCHASE_DATE'],
  MILESTONE: '',
}

// product route
export const DesiredResolutionFlow: PageFlowProps = {
  PROGRESS_VALUE: 40,
  STEP: 4,
  VIEW_NAME: 'desired-resolution',
  REDUCERS: ['UPDATE_DESIRED_RESOLUTION'],
  MILESTONE: '',
}

//service route
export const RepeatPerformanceFlow: PageFlowProps = {
  PROGRESS_VALUE: 45,
  STEP: 5,
  VIEW_NAME: 'repeat-performance',
  REDUCERS: ['UPDATE_REPEAT_PERFORMANCE'],
  MILESTONE: '',
}

export const EntitlementFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 6,
  VIEW_NAME: 'display-entitlement',
  REDUCERS: ['UPDATE_CARD_PROVIDER'],
  MILESTONE: 'milestone | next steps | write letter',
}

export const WhatProductFlow: PageFlowProps = {
  PROGRESS_VALUE: 60,
  STEP: 7,
  VIEW_NAME: 'what-product',
  REDUCERS: ['UPDATE_PRODUCT_DESCRIPTION'],
  MILESTONE: 'milestone | write letter',
}

export const WherePurchasedFlow: PageFlowProps = {
  PROGRESS_VALUE: 70,
  STEP: 8,
  VIEW_NAME: 'where-purchased',
  REDUCERS: ['UPDATE_RETAILER_NAME'],
  MILESTONE: '',
}

export const ClaimValueFlow: PageFlowProps = {
  PROGRESS_VALUE: 80,
  STEP: 9,
  VIEW_NAME: 'claim-value',
  REDUCERS: ['UPDATE_CLAIM_VALUE'],
  MILESTONE: '',
}

export const WhatHappenedFlow: PageFlowProps = {
  PROGRESS_VALUE: 85,
  STEP: 10,
  VIEW_NAME: 'what-happened',
  REDUCERS: ['UPDATE_ISSUE_DESCRIPTION'],
  MILESTONE: '',
}

export const LimitedOptionsFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 11,
  VIEW_NAME: 'limited-options',
  REDUCERS: [''],
  MILESTONE: 'milestone | next steps | end',
}

export const SellerContactFlow: PageFlowProps = {
  PROGRESS_VALUE: 90,
  STEP: 12,
  VIEW_NAME: 'seller-contact',
  REDUCERS: ['UPDATE_SELLER_CONTACT'],
  MILESTONE: '',
}

export const GetInTouchFlow: PageFlowProps = {
  PROGRESS_VALUE: 93,
  STEP: 13,
  VIEW_NAME: 'get-in-touch',
  REDUCERS: ['UPDATE_CASESTUDY_OPTIN'],
  MILESTONE: '',
}

export const ContactDetailsFlow: PageFlowProps = {
  PROGRESS_VALUE: 95,
  STEP: 14,
  VIEW_NAME: 'contact-details',
  REDUCERS: [''],
  MILESTONE: 'milestone | personal details',
}

export const SubmitSuccessfulFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 15,
  VIEW_NAME: 'journey-complete',
  REDUCERS: [''],
  MILESTONE: 'milestone | end | letter sent',
}

export const ToolFeedbackFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 16,
  VIEW_NAME: 'feedback-form',
  REDUCERS: [''],
  MILESTONE: 'milestone | feedback start',
}

export const ToolFeedbackAudienceFlow: PageFlowProps = {
  PROGRESS_VALUE: 90,
  STEP: 17,
  VIEW_NAME: 'feedback-audience-form',
  REDUCERS: [''],
  MILESTONE: 'milestone | feedback audience',
}

export const ToolFeedbackThankYouFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 18,
  VIEW_NAME: 'feedback-form',
  REDUCERS: [''],
  MILESTONE: '',
}

export const AllFlows = [
  EntryPointFlow,
  PurchaseMethodFlow,
  PurchasePriceFlow,
  PurchasedDateFlow,
  DesiredResolutionFlow,
  RepeatPerformanceFlow,
  EntitlementFlow,
  WhatProductFlow,
  WherePurchasedFlow,
  ClaimValueFlow,
  WhatHappenedFlow,
  LimitedOptionsFlow,
  SellerContactFlow,
  ContactDetailsFlow,
  SubmitSuccessfulFlow,
  ToolFeedbackFlow,
  ToolFeedbackAudienceFlow,
  ToolFeedbackThankYouFlow,
]
