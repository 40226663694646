import { limitedOptionsData } from './LimitedOptionsData'

export const chooseDataSet = (chooseDataSetProps: Props) => {
  const { channel, shortDesc, type, whenBought } = chooseDataSetProps
  if (type === 'product' && whenBought !== 'moreThan6Years') {
    if (channel === 'privateSeller') {
      return limitedOptionsData.option3 // C
    } else if (
      channel === 'inStore' &&
      shortDesc.includes('changedMind') &&
      whenBought !== 'moreThan6Years'
    ) {
      return limitedOptionsData.option4 // F
    } else {
      return limitedOptionsData.option5 // G
    }
  } else if (type === 'digital' && shortDesc.includes('changedMind')) {
    return limitedOptionsData.option1 // A
  }
  return limitedOptionsData.option2 //B & D
}

export type Props = {
  channel: string
  shortDesc: string[]
  type: string
  whenBought: string
}
