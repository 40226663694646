import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { BenefitsFlow, EnergyPaymentFlow } from '../../pageFlowData'
import styles from './Benefits.module.scss'
import { data } from './data'

export const Benefits: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    myMoneyHealthCheck: { benefits, financeSituation },
  } = state

  const handleTracking = (id: string) => {
    const trackingAnswer = `question | ${BenefitsFlow.VIEW_NAME} | ${id} | ${financeSituation}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_BENEFITS', data: id })
    dispatch({ type: 'UPDATE_STEP', data: EnergyPaymentFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: EnergyPaymentFlow.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string) => {
    handleTracking(id)
    handleDispatch(id)
  }

  const { buttons, imgObj, title } = data

  return (
    <PillButtonPage
      buttons={buttons}
      defaultValue={benefits ? benefits : ''}
      handleSubmit={handleSubmit}
      imgObj={imgObj}
      infoBoxData={data}
      pillButtonGroupStyle={styles.benefitsPagePillButtons}
      title={title}
    />
  )
}
