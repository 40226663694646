import type { UserPropsWithAddress } from './../../../shared/types/toolTypes'

export const pageData = (state: {
  emailData: { body: { text: string; className?: string }[] }
  order: {
    deliveryProblem: string
    deliveryPermission: string
    itemOrdered: string
    orderNumber: string
    deliveryDateExplained: string
    whatHappened: string
    whatWentWrong: string
  }
  retailer: {
    name: string
  }
  optIns: {
    singleConsentOptIn: boolean
  }
  user: UserPropsWithAddress
}) => {
  const { name } = state.retailer
  const {
    deliveryProblem,
    deliveryPermission,
    itemOrdered,
    orderNumber,
    deliveryDateExplained,
    whatHappened,
    whatWentWrong,
  } = state.order
  const { address, emailAddress, firstName, lastName } = state.user
  const { singleConsentOptIn } = state.optIns

  const { body } = state.emailData

  let emailBodyBuilder = ''
  body.forEach((bodyData) => {
    if (bodyData.text) {
      emailBodyBuilder = emailBodyBuilder + `${bodyData.text}<br />`
    }
  })

  const questions: any = {}
  // Questions for delivery complaint
  questions['question.1271353'] = deliveryProblem // TF - Delivery - What's the problem with your delivery?
  questions['question.1271354'] = deliveryPermission // TF - Delivery - Permission to leave in specific location or with a neighbour?
  questions['question.1271379'] = itemOrdered // TF - Delivery - What item did you order?
  questions['question.1271380'] = orderNumber // TF - Delivery - What is your order number?
  questions['question.1271381'] = deliveryDateExplained // TF - Delivery - Please explain when and why you want your order re-delivered
  questions['question.1271382'] = whatHappened // TF - Delivery - What happened to your item?
  questions['question.1271383'] = whatWentWrong // TF - Delivery - Please tell us where the item was left and what went wrong?
  questions['question.1271378'] = name // TF - Delivery - What retailer did you place the order with?
  questions['question.1271451'] = emailBodyBuilder // TF - Composed Letter
  questions['question.1598463'] = singleConsentOptIn ? 'Y' : 'N' // TF - Single Consent opt-in
  questions['question.1184531'] = 'Y' // TF - Impact survey opt-in

  // return supporter
  return {
    supporter: {
      Address: address?.addressLine1,
      'Address 2': address?.addressLine2,
      County: address?.county,
      Email: emailAddress,
      'First name': firstName,
      'Last name': lastName,
      Postcode: address?.postcode,
      'Town or City': address?.townCity,
      questions,
    },
  }
}
