export const likeToDoData = {
  buttons: [
    {
      label: 'Replace car',
      id: 'replace',
    },
    {
      label: 'Repair car',
      id: 'repair',
    },
  ],
  title: 'What would you like to do?',
  imgObj: {
    alt: 'two people holding documents',
    src: 'https://media.product.which.co.uk/prod/images/original/8f95d1a7f0dc-cp-step9.jpg',
  },
  buttonsUnder30: [
    {
      label: 'Full refund',
      id: 'refund',
    },
    {
      label: 'Replace car',
      id: 'replace',
    },
    {
      label: 'Repair car',
      id: 'repair',
    },
  ],
}
