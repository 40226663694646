export const feedbackData = {
  imgObj: {
    src: 'https://media.product.which.co.uk/prod/images/original/gm-df96b9c5-d989-4ce3-bf8f-af0de38dc7e9-feedbackentry.jpg',
    alt: 'A man and a woman holding a large gold star',
  },
  title: 'Please could you spare two minutes to share your thoughts on using this tool?',
  body: 'Your feedback will help us improve the experience for others.',
}

import { SubmitSuccessfulFlow } from '../../../pageFlowData'

export const submitSuccessfulMilestone = [
  {
    event: 'pageview',
    page: SubmitSuccessfulFlow.VIEW_NAME,
  },
  {
    eventCategory: 'problem resolution tools',
    eventAction: 'flight delay and cancellation compensation',
    eventLabel: 'milestone | submit successful',
    eventValue: 0,
    eventInteractive: true,
    event: 'tEvent',
  },
]
