import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../../shared/state/appContext'
import globalStyles from '../../../../../shared/styles/GlobalStyles.module.scss'
import { trackAppointmentBookingSelection } from '../../../../../shared/utils/tracking/tracking'
import {
  ContactDetailsFlow,
  ContractChoiceFlow,
  EmploymentTopicFlow,
  LimitedOptionsFlow,
} from '../../../pageFlowData'
import { employmentTopicData } from './data'

export const EmploymentTopic: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    employment: { employmentTopic },
    triage: { type },
  } = state

  const handleTracking = (id: string, index: number) => {
    trackAppointmentBookingSelection(type, EmploymentTopicFlow.VIEW_NAME, { id, index })
  }

  const chooseStep = (id: string) => {
    switch (id) {
      case 'appeals-to-employment':
      case 'police-armed':
      case 'self-employment-business':
      case 'civil-court':
      case 'pension-tax-shares-query':
        return LimitedOptionsFlow
      case 'deduction-of-wages':
      case 'capability':
      case 'settlement':
      case 'employment-tribunal':
        return ContractChoiceFlow
      default:
        return ContactDetailsFlow
    }
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_EMPLOYMENT_TOPIC', data: id })
    const step = chooseStep(id)
    dispatch({ type: 'UPDATE_STEP', data: step.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: step.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string, index: number) => {
    handleTracking(id, index)
    handleDispatch(id)
  }

  const { buttons, imgObj, title } = employmentTopicData

  return (
    <div className={globalStyles.fullWidthButton}>
      <PillButtonPage
        buttons={buttons}
        defaultValue={employmentTopic}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        title={title}
      />
    </div>
  )
}
