import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { CurrencyPage } from '../../../../shared/pages/CurrencyPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryInputRef } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { ExpensesDetailsFlow, ExpenseValueFlow } from '../../pageFlowData'
import { data } from './data'

export const ExpensesValue: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    expenses: { value },
  } = state

  const ExpensesValueInput = useRef<FormEntryInputRef>(null)
  const [expensesValueInputValid, setExpensesValueInputValid] = useState<boolean>(true)

  const handleTracking = (answerValue: string) => {
    const trackingAnswer = `question | ${ExpenseValueFlow.VIEW_NAME} | ${answerValue}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (answerValue: string) => {
    dispatch({ type: 'UPDATE_EXPENSES_VALUE', data: answerValue })
    dispatch({ type: 'UPDATE_STEP', data: ExpensesDetailsFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: ExpensesDetailsFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    const answerValue = getCleanValue(ExpensesValueInput?.current?.formEntryInput?.value)
    const expensesValueHasError = !isNotEmpty(answerValue)

    if (!expensesValueHasError) {
      handleTracking(answerValue)
      handleDispatch(answerValue)
      setExpensesValueInputValid(true)
      return
    }
    setExpensesValueInputValid(false)
  }

  const { imgObj, title } = data

  return (
    <div data-testid="expenses-value-page">
      <CurrencyPage
        buttonText="Next"
        currencySymbol="£"
        defaultValue={value}
        handleSubmit={handleSubmit}
        id="expenses-value"
        imgObj={imgObj}
        isFocused
        isSubmitValid={expensesValueInputValid}
        ref={ExpensesValueInput}
        title={title}
      />
    </div>
  )
}
