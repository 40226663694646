export const techDeviceData = {
  buttons: [
    { label: 'Chromebooks', id: 'chromebooks' },
    { label: 'Desktop', id: 'desktop' },
    { label: 'Laptop', id: 'laptop' },
    { label: 'Mobiles', id: 'mobiles' },
    { label: 'Printer', id: 'printer' },
    { label: 'Smart Tech (Amazon Alexa etc)', id: 'smart-tech' },
    { label: 'Smart TV', id: 'smart-tv' },
    { label: 'Tablet', id: 'tablet' },
    { label: 'Other', id: 'Other' },
  ],
  title: 'Please select your device',
  imgObj: {
    alt: 'Two people looking stressed at a computer',
    src: 'https://media.product.which.co.uk/prod/images/original/1fea9f96da2f-have-you-been-affected-by-the-scam.jpg',
  },
}
