export const whatWentWrongData = {
  title: 'Please tell us where the item was left and what went wrong?',
  imgObj: {
    src: 'https://media.product.which.co.uk/prod/images/original/gm-f389fd0e-4679-457c-b1f9-f94faa0a0f91-delivery-complaint-step-8.jpg',
    alt: 'Two people standing next to some packing boxes',
  },
  descriptiveText: [
    'What you write here will appear in the final letter of claim, so please be as clear as possible, including dates.',
  ],
  placeholderText:
    'Eg. The courier said they left it in my shed but it was not there when I returned home.',
}
