import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { FormEntryPage } from '../../../../shared/pages/FormEntryPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryInputRef, SingleInputState } from '../../../../shared/types/toolTypes'
import type { vehicleDetailsObj } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getVehicleDetails } from '../../../../shared/utils/getVehicleDetails'
import { validRegistration } from '../../../../shared/utils/getVehicleDetails/validRegistration'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { CorrectCarFlow, RegNumberFlow } from '../../pageFlowData'
import { data } from './data'

export const RegNumber: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    car: { vehicleDetails },
  } = state

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })
  const RegNumberInput = useRef<FormEntryInputRef>(null)

  const getValue = () => {
    return cleanText(RegNumberInput?.current?.formEntryInput?.value || '')
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${RegNumberFlow.VIEW_NAME} | PII`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (vehicleData: vehicleDetailsObj) => {
    dispatch({ type: 'UPDATE_STEP', data: CorrectCarFlow.STEP })
    dispatch({ type: 'UPDATE_VEHICLE_DETAILS', data: { ...vehicleData } })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: CorrectCarFlow.PROGRESS_VALUE })
  }

  const checkData = () => {
    const value = getValue()
    const isValid: boolean = isNotEmpty(value)
    const isValidRegistration: boolean = validRegistration(value)
    const vehicleData = getVehicleDetails(value)

    if (isValid && isValidRegistration) {
      vehicleData.then((vehicleDetailsData: vehicleDetailsObj) => {
        if (!vehicleDetailsData.error) {
          handleSubmit(vehicleDetailsData)
        } else {
          setInputData({
            renderError: true,
            value: 'reg-not-found',
          })
        }
      })
    }
    if (isValid && !isValidRegistration) {
      setInputData({
        renderError: true,
        value: 'reg-invalid',
      })
    } else {
      setInputData({
        renderError: !isValid,
        value: value,
      })
    }
  }

  const handleSubmit = (vehicleInfo: vehicleDetailsObj) => {
    handleTracking()
    handleDispatch(vehicleInfo)
  }

  const { renderError, value: errorValue } = inputData
  const { imgObj, title } = data

  return (
    <FormEntryPage
      buttonText="Next"
      defaultValue={vehicleDetails?.vehicleReg}
      fieldData={{
        renderError: renderError,
        value: errorValue,
      }}
      handleSubmit={checkData}
      id="reg-number"
      imgObj={imgObj}
      isFocused
      optional={false}
      placeholderText=""
      ref={RegNumberInput}
      testId="reg-number"
      title={title}
    />
  )
}
