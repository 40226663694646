export const toolFeedbackAudienceSurveyData = (state: {
  toolFeedbackAudience: {
    age?: string
    gender?: string
    postcodeValue?: string
    ethnicity?: string
  }
  email: string
}) => {
  const { age, gender, postcodeValue, ethnicity } = state.toolFeedbackAudience

  const questions: any = {}
  questions['question.1183969'] = age
  questions['question.1183970'] = gender
  questions['question.1183971'] = postcodeValue
  questions['question.1183972'] = ethnicity

  // return supporter
  return {
    supporter: {
      Email: state.email,
      questions,
    },
  }
}
