import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import globalStyles from '../../../../shared/styles/GlobalStyles.module.scss'
import { trackAppointmentBookingTriageSelection } from '../../../../shared/utils/tracking/tracking'
import { MoneyTopicFlow, SolicitorFlow, TechTopicFlow } from '../../pageFlowData'
import { triageData } from './data'

export const Triage: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    cars: { triage },
  } = state

  const handleTracking = (id: string, index: number) => {
    trackAppointmentBookingTriageSelection({ id, index })
  }

  const handleStep = (id: string) => {
    switch (id) {
      case 'legal':
        return SolicitorFlow
      case 'money':
        return MoneyTopicFlow
      case 'tech':
        return TechTopicFlow
    }
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_TRIAGE_TYPE', data: id })
    const step = handleStep(id)
    dispatch({ type: 'UPDATE_STEP', data: step?.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: step?.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string, index: number) => {
    handleTracking(id, index)
    handleDispatch(id)
  }

  const { buttons, imgObj, title } = triageData

  return (
    <div className={globalStyles.fullWidthButton}>
      <PillButtonPage
        buttons={buttons}
        defaultValue={triage ? triage : ''}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        title={title}
      />
    </div>
  )
}
