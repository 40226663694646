import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { ClaimCompensationFlow, NameOfBankFlow } from '../../pageFlowData'
import { data } from './data'

export const ClaimCompensation: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    claim: { claimCompensation },
  } = state

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_CLAIM_COMPENSATION', data: value })
    dispatch({ type: 'UPDATE_STEP', data: NameOfBankFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: NameOfBankFlow.PROGRESS_VALUE })
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${ClaimCompensationFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = (id: string) => {
    handleDispatch(id)
    handleTracking(id)
  }

  const { buttons, imgObj, title } = data

  return (
    <div data-testid="claim-compensation">
      <PillButtonPage
        buttons={buttons}
        defaultValue={claimCompensation}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        title={title}
      />
    </div>
  )
}
