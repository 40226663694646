import { environment } from '../../../../shared/utils/fetchEnv/env'
import { getBlaize } from '../../../../shared/utils/getBlaize'

type CustomerNotEntitledResponse = {
  isEntitled: false
}
type CustomerEntitledResponse = {
  isEntitled: true
  anyExpertAppointmentUrl: string
  sameExpertAppointmentUrl: string
}
type FollowUpAppointmentResponse = CustomerEntitledResponse | CustomerNotEntitledResponse

export const getIFrameData = async (
  appointmentId: string
): Promise<FollowUpAppointmentResponse> => {
  const blaize = getBlaize()
  if (!appointmentId || !blaize) {
    throw new Error('Incorrect appointment id passed in')
  }

  const iFrameApi = fetch(
    `https://${environment()}-appointment-booking-rebooking-service.api.product.which.co.uk/rebooking-service/follow-up-appointment?appointmentId=${appointmentId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: blaize,
      },
    }
  )
    .then((responseData) => {
      if (responseData.ok) {
        return responseData.json()
      } else {
        return { error: 'Sorry, something went wrong' }
      }
    })
    .catch(() => {
      return { error: 'Sorry, something went wrong' }
    })

  const iFrameData = await iFrameApi
  return iFrameData
}
