export const contactDetailsData = {
  formItems: [
    {
      displayText: 'First name',
      id: 'firstName',
      type: 'text',
      isFocused: true,
    },
    {
      displayText: 'Last name',
      id: 'lastName',
      type: 'text',
      isFocused: false,
    },
    {
      displayText: 'Email address',
      id: 'email',
      type: 'email',
      isFocused: false,
    },
  ],
  addressItems: [
    {
      displayText: 'Address line 1',
      id: 'addressLine1',
      type: 'text',
      isFocused: false,
    },
    {
      displayText: 'Address line 2',
      id: 'addressLine2',
      type: 'text',
      isFocused: false,
      optional: true,
    },
    {
      displayText: 'Town/City',
      id: 'townCity',
      type: 'text',
      isFocused: false,
    },
    {
      displayText: 'County',
      id: 'county',
      type: 'text',
      isFocused: false,
      optional: true,
    },
    {
      displayText: 'Postcode',
      id: 'postcode',
      type: 'text',
      isFocused: false,
    },
  ],
  imgObj: {
    alt: 'A woman working on a laptop',
    src: 'https://media.product.which.co.uk/prod/images/original/751737671d87-flight-delay-contact-details.jpg',
  },
  title: 'Enter your contact details',
  buttonText: 'Send complaint email to me',
  singleConsentOptIn: {
    label:
      'Keep me updated about products and services from the Which? Group and the work it does to make life simpler, safer and fairer for UK consumers.',
    name: 'acceptedSingleConsent',
  },
  contactUpdates: {
    label:
      'Are you happy for a member of the Which? team to contact you to discuss your experience in a bit more detail?',
    name: 'contactUpdate',
    explanation:
      'In order to understand the problems consumers are facing across the UK, our team may want to follow up with you to hear more. <a class="sb-link-primary" href="https://www.which.co.uk/campaigns/transform-travel" target="_blank"><span class="sb-link-animation-wrapper">Let\'s stop the chaos, sign our petition</span></a>',
  },
}
