export const broadbandProviders = [
  '1310',
  '1pBroadband',
  '6G Internet',
  'AAISP',
  'Air Broadband',
  'Air Fibre',
  'Airband',
  'Airwave Communications',
  'Alncom',
  'Angus WISP',
  'Aquiss',
  'ASDA Mobile',
  'Ashford Fibre',
  'B4RK',
  'B4RN',
  'B4SH',
  'Back of Beyond Broadband',
  'Badenoch Broadband',
  'Ballscoigne',
  'Beacon Broadband',
  'Beacons Telecom',
  'Beeline Broadband',
  'BeFibre',
  'BitStreme',
  'BleMobile',
  'Bluebox',
  'Bluewave Internet',
  'bOnline',
  'BorderLink (GoFibre)',
  'Boundless Networks',
  'Box Broadband',
  'BrawBand',
  'Breathe',
  'BreezTel',
  'Briskona',
  'Broadway Broadband',
  'Brsk',
  'BT',
  'BT Wifi',
  'Buckminster Broadband',
  'Call Flow',
  'Call27',
  'Cambridge Fibre',
  'Cerberus Networks',
  'CIX',
  'Clear Picture',
  'CloudScape Connect',
  'Community Fibre',
  'Connect Fibre',
  'Connexin',
  'Cornwall Broadband',
  'Country Connect',
  'Countryside Broadband',
  'County Broadband',
  'Cromarty Firth Wireless Broadband',
  'Cuckoo',
  'Derbyshire Broadband',
  'Direct Save Telecom',
  'Distant Voice',
  'Drimnin Community Broadband',
  'Ecom',
  'EE',
  'Essex WiFi',
  'Exascale',
  'FACTCO',
  'FastNet',
  'Fibrecast',
  'Fibreconnect',
  'FibreHop',
  'FibreNest',
  'Fibrus',
  'File Sanctuary',
  'FirstMile',
  'Fram Broadband',
  'Freecom.net',
  'Freedomsat',
  'Freeola',
  'G.Network',
  'Giant',
  'giffgaff',
  'Gigabeam',
  'Gigabit Networks',
  'Gigaclear',
  'Giganet',
  'Go Internet',
  'Gradwell',
  'Grain',
  'Grayshott Gigabit',
  'Green Grass Broadband',
  'Green ISP',
  'HERBS Broadband',
  'Herefordshire Community Network',
  'Hey Broadband',
  'Highland Community Broadband',
  'Highland Wireless',
  'Hive Telecom',
  'Home Telecom',
  'Hyperoptic',
  'I Need Broadband',
  'iD Mobile',
  'iDNET',
  'iNTELLECT COMMUNICATIONS',
  'InternetTY',
  'iTALK',
  'ITSwisp',
  'Jibba Jabba',
  'John Lewis',
  'Juice Broadband',
  'Jurassic Fibre',
  'Karma Computing',
  'KCOM',
  'Kencomp',
  'Kijoma',
  'Kinetic Telecom',
  'KONNECT',
  'Lawyers-Online',
  'Lebara',
  'Leetline',
  'Legend Fibre',
  'Lightning Fibre',
  'Lightspeed Broadband',
  'Link Broadband',
  'Lit Fibre',
  'Locheilnet',
  'London Web',
  'Loop Scorpio',
  'Lothian Broadband',
  'Lycamobile',
  'Marykirk',
  'Moorsweb',
  'Multidata',
  'N3',
  'No One',
  'NotSpot',
  'NOW',
  'O2',
  'Octaplus',
  'Ogi',
  'Onebill',
  'Onestream',
  'OptaNet',
  'Optify',
  'Origin Broadband',
  'Pine Media',
  'Plusnet',
  'Pop Telecom',
  'Pulse8',
  'Pure Broadband',
  'Purefibre',
  'Quantum Fibre Broadband',
  'Quickline',
  'Rade Systems',
  'RadioFibre',
  'Rapid Rural',
  'RedRaw Internet',
  'Reeth Rural Radio Net',
  'RELIALINK',
  'RunFibre',
  'Rymote',
  'SAQ',
  'Save9',
  'Scot-Tel Gould',
  'Seethelight',
  'Shell Energy',
  'Sky Broadband',
  'Skyenet',
  'Smarty',
  'Speednet Scotland',
  'Spitfire',
  'Squirrel Internet',
  'SSE',
  'Starlink',
  'Stix Internet (Northern Fibre)',
  'Stream Networks',
  'Structured Communications',
  'Superhero Broadband',
  'Swish Fibre',
  'SWS Broadband',
  'Symmetris',
  'Talkmobile',
  'TalkTalk',
  'TalkTalk Business',
  'Technological',
  'Telcom (ClearFibre, WeFibre)',
  'Tesco Mobile',
  'The 4th Utility',
  'ThinkingWISP',
  'Three',
  'toob',
  'Total Web Solutions',
  'Tove Valley Broadband',
  'Transmitair',
  'Trooli',
  'Truespeed',
  'TxRx Communications',
  'Unchained ISP',
  'uno',
  'Upp',
  'Userve Internet',
  'Utility Warehouse',
  'Vectone Mobile',
  'Velocity 1',
  'VFast',
  'Village Networks',
  'virair',
  'Virgin Media',
  'Vispa',
  'Vodafone',
  'VoiceHost',
  'VoiP Unlimited',
  'Voneus',
  'VOXI',
  'W3Z',
  'webmate (MacAce)',
  'WeLink Communications UK',
  'Wessex Internet',
  'WestWiFi (WestFibre)',
  'WightFibre',
  'Wildanet',
  'Wisper Broadband',
  'WiSpire',
  'xln telecom',
  'Yayzi Broadband',
  'YouFibre',
  'Your Coop',
  'Zen Internet',
  'Zone Broadband',
  'Zoom Internet',
  'ZYBRE',
  'Zzoomm',
]
