import React, { forwardRef, useEffect, useState } from 'react'
import { Label, Typography } from '@which/seatbelt'

import classNames from 'classnames'

import globalStyles from '../../styles/GlobalStyles.module.scss'
import type { TextAreaProps } from '../../types/toolTypes'
import { charCount } from '../../utils/charCount'
import { cleanText } from '../../utils/cleanText'
import { isNotEmpty } from '../../utils/isNotEmpty'
import { validateCharCount } from '../../utils/validateCharCount'
import { validateWordCount } from '../../utils/validateWordCount'
import { wordCount } from '../../utils/wordCount'
import { ErrorMessage } from '../ErrorMessage'
import styles from './TextArea.module.scss'

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      defaultValue,
      handleChange,
      handleSubmit,
      isFocused = false,
      isSubmitValid = true,
      maxChars = 2500,
      srLabel,
      placeholderText,
      id = 'tool-textarea',
      className,
      maxWords,
    }: TextAreaProps,
    ref
  ) => {
    const [value, setValue] = useState(defaultValue || '')
    const [error, setError] = useState(false)
    const [characterCountError, setCharacterCountError] = useState(false)
    const [wordCountError, setWordCountError] = useState(false)
    const [keyMap, setKeyMap] = useState(new Map())

    const handleKeyDown = (event: React.KeyboardEvent) => {
      const keyPressed: string = event.key
      if (keyPressed === 'Alt') {
        keyMap.set(keyPressed, event.type === 'keydown')
      }
      if (keyPressed === 'Enter') {
        keyMap.set(keyPressed, event.type === 'keydown')
      }

      if (keyMap.get('Alt') && keyMap.get('Enter')) {
        setKeyMap(new Map())
        handleSubmit()
      }
    }

    const handleKeyUp = (event: React.KeyboardEvent) => {
      const keyPressed: string = event.key
      if (keyPressed === 'Alt') {
        setKeyMap(keyMap.set(keyPressed, event.type === 'keydown'))
      }
      if (keyPressed === 'Enter') {
        setKeyMap(keyMap.set(keyPressed, event.type === 'keydown'))
      }
    }

    useEffect(() => {
      if (!isSubmitValid) {
        setError(true)
      } else {
        setError(false)
      }
    }, [isSubmitValid])

    const renderCharCount = () => {
      if (maxWords) {
        return wordCount(value, maxWords)
      }
      return charCount(value.length, maxChars)
    }

    return (
      <div className={classNames(globalStyles.maxWidth, globalStyles.spacing40, className)}>
        {srLabel && <Label className={'sr-only'} htmlFor="textarea" labelText={srLabel} />}
        <textarea
          autoFocus={isFocused}
          className={classNames(styles.textArea, globalStyles.maxWidth, className, {
            [styles.textAreaBorderError]: characterCountError || wordCountError || error,
          })}
          data-testid={id}
          id={id}
          onBlur={(e) => (e.target.value = cleanText(e.target.value).trim())}
          onChange={(e) => {
            //below clears out a carriage return if there is only 1 char and it's a carriage return
            const array = e.target.value.split(/\r?\n/)
            if (e.target.value.length === 1 && array.length > 1) {
              setValue(e.target.value.replace(/[\n\r]/g, ''))
            } else {
              setValue(e.target.value)
            }
            if (!maxWords) {
              setCharacterCountError(!validateCharCount(e.target.value.length, maxChars))
            }
            setWordCountError(!validateWordCount(e.target.value, maxWords))
            const validationError = !maxWords
              ? !validateCharCount(e.target.value.length, maxChars)
              : !validateWordCount(e.target.value, maxWords) || !isNotEmpty(e.target.value)
            handleChange(e, validationError)
          }}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          placeholder={placeholderText}
          ref={ref}
          value={value}
        />
        {error && !characterCountError && !wordCountError && (
          <ErrorMessage errorText="Please fill out this field to continue" />
        )}

        <Typography
          className={classNames({
            [styles.textAreaError]: characterCountError || wordCountError,
          })}
          data-testid="tool-count-text"
        >
          {renderCharCount()}
        </Typography>
      </div>
    )
  }
)
