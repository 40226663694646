export const reasonGivenData = {
  cancelledTitle: 'What reason were you given for the cancellation?',
  delayedTitle: 'What reason were you given for the delay?',
  imgObj: {
    alt: 'Two people at a customer service desk',
    src: 'https://media.product.which.co.uk/prod/images/original/ae2ceff582fb-flight-delay-step-13.jpg',
  },
  note: [
    'What you write here will appear in the final letter of claim, so please be as clear as possible, including dates.',
  ],
  placeholderText: 'Eg. The airline told me that...',
}
