// A separate object for the infoBox data is used due to the typing of the borderColour attribute
import type { InfoBoxProps } from '../../../../../shared/types/toolTypes'
import styles from '../../../../../shared/utils/renderInfoBox/renderInfoBox.module.scss'

export const infoBox: InfoBoxProps = {
  heading: 'Methods of payment',
  borderColour: 'blue',
}

export const purchaseMethodData = {
  purchaseMethodButtons: [
    {
      label: 'Credit card',
      id: 'creditCard',
    },
    {
      label: 'Linked credit agreement',
      id: 'linkedCreditAgreement',
    },
    {
      label: 'Debit card',
      id: 'debitCard',
    },
    {
      label: 'Charge card',
      id: 'chargeCard',
    },
  ],
  infoBox,
  infoBoxParagraphs: [
    {
      classname: `${styles.renderInfoBoxParagraphs}`,
      text: 'Credit card: if you paid partly using your credit card (even if it was only a few £s) please select Credit Card. This makes you eligible for an S75 claim.',
    },
    {
      classname: `${styles.renderInfoBoxParagraphs}`,
      text: 'Debit card: if you paid using a debit card, you may be eligible to make a chargeback claim.',
    },
    {
      classname: `${styles.renderInfoBoxParagraphs}`,
      text: 'Linked credit agreement: this is a loan granted via the seller for specific goods/services. A linked credit agreement often refers to things such as loans from fitters/manufacturers for kitchens or for building work.',
    },
    {
      classname: `${styles.renderInfoBoxParagraphs}`,
      text: 'Charge card: similar to a credit card, a charge card allows you to buy items and pay later, but, unlike with a credit card, the balance of a charge card must be paid off in full at the end of each month. Typically charge cards do not have credit limits, also.',
    },
    {
      classname: `${styles.renderInfoBoxParagraphs}`,
      text: "Other payment methods: if you paid through an online payment system you will not be able to submit a claim using this tool. Similarly, if you paid using a 'buy now, pay later' scheme, you won't be able to make a claim using this tool.",
    },
  ],
}
