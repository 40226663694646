import type { FunctionComponent } from 'react'
import React from 'react'

export const CopyIcon: FunctionComponent = () => {
  return (
    <svg
      data-testid="copy-svg"
      height="16px"
      key="copy-svg"
      version="1.1"
      viewBox="0 0 13 16"
      width="13px"
      xmlns="https://www.w3.org/2000/svg"
    >
      <defs>
        <polygon id="path-1" points="0 0 10.5956 0 10.5956 13.2635 0 13.2635"></polygon>
      </defs>
      <g fill="none" fillRule="evenodd" id="New_components" stroke="none" strokeWidth="1">
        <g id="--Clipboard" transform="translate(-50.000000, -72.000000)">
          <g id="CTA" transform="translate(30.000000, 60.000000)">
            <g id="Group-14" transform="translate(20.000000, 13.000000)">
              <g id="Group-3" transform="translate(0.000000, 1.423900)">
                <mask fill="white" id="mask-2">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <g id="Clip-2"></g>
                <path
                  d="M7.1386,0 L1.6876,0 C0.7556,0 -0.0004,0.755 -0.0004,1.687 L-0.0004,11.576 C-0.0004,12.508 0.7556,13.264 1.6876,13.264 L8.9076,13.264 C9.8396,13.264 10.5956,12.508 10.5956,11.576 L10.5956,3.372 C10.5956,3.005 10.4466,2.652 10.1826,2.396 L8.1236,0.399 C7.8596,0.143 7.5066,0 7.1386,0"
                  fill="#1866C8"
                  id="Fill-1"
                  mask="url(#mask-2)"
                ></path>
              </g>
              <line
                id="Stroke-4"
                stroke="#FFFFFF"
                strokeLinecap="round"
                strokeWidth="0.892"
                x1="2.6889"
                x2="6.0059"
                y1="5.9424"
                y2="5.9424"
              ></line>
              <line
                id="Stroke-6"
                stroke="#FFFFFF"
                strokeLinecap="round"
                strokeWidth="0.892"
                x1="2.6889"
                x2="7.8519"
                y1="8.5694"
                y2="8.5694"
              ></line>
              <line
                id="Stroke-8"
                stroke="#FFFFFF"
                strokeLinecap="round"
                strokeWidth="0.892"
                x1="2.6889"
                x2="7.8519"
                y1="11.1973"
                y2="11.1973"
              ></line>
              <path
                d="M7.0492,1.0586 L7.0492,3.9286 C7.0492,4.3786 7.4142,4.7456 7.8652,4.7456 L10.7582,4.7456"
                id="Stroke-10"
                stroke="#FFFFFF"
              ></path>
              <path
                d="M1.0146,0 L9.7766,0 C10.9866,0 11.9676,1.013 11.9676,2.262 L11.9676,13.616"
                id="Stroke-12"
                stroke="#1866C8"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
