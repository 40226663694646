import type { InfoBoxProps } from '../../../../../../shared/types/toolTypes'

export const contactDetailsData = {
  formItems: [
    {
      displayText: 'First name',
      id: 'firstName',
      type: 'text',
      isFocused: true,
    },
    {
      displayText: 'Last name',
      id: 'lastName',
      type: 'text',
      isFocused: false,
    },
  ],
  addressItems: [
    {
      displayText: 'Address line 1',
      id: 'addressLine1',
      type: 'text',
      isFocused: false,
    },
    {
      displayText: 'Address line 2',
      id: 'addressLine2',
      type: 'text',
      isFocused: false,
      optional: true,
    },
    {
      displayText: 'Town/City',
      id: 'townCity',
      type: 'text',
      isFocused: false,
    },
    {
      displayText: 'County',
      id: 'county',
      type: 'text',
      isFocused: false,
      optional: true,
    },
    {
      displayText: 'Postcode',
      id: 'postcode',
      type: 'text',
      isFocused: false,
    },
  ],
  imgObj: {
    alt: 'A woman working on a laptop',
    src: 'https://media.product.which.co.uk/prod/images/original/994efe759b33-contactdetails.jpg',
  },
  infoBox: {
    heading: 'Why do we need this?',
    borderColour: 'blue',
  } as InfoBoxProps,
  infoBoxParagraphs: [
    {
      text: "We need to capture the name of the person you need advice against for regulatory reasons. We won't contact any individual you name",
    },
  ],
}

export const buyingIndividualData = {
  ...contactDetailsData,
  title: 'Please provide the info below for the individual you entered an agreement with',
}

export const willsCompanyData = {
  ...contactDetailsData,
  formItems: [
    {
      displayText: 'Name of company',
      id: 'firstName',
      type: 'text',
      isFocused: true,
    },
  ],
  title: 'Please provide the info below for the Company you entered an agreement with',
  infoBoxParagraphs: [
    {
      text: "We need to capture the name of the company you need advice against for regulatory reasons. We won't contact any company you name",
    },
  ],
}

export const buyingCompanyData = {
  ...contactDetailsData,
  ...willsCompanyData,
  title: 'Please provide the info below for the company you need advice regarding',
}

export const tenantData = {
  ...contactDetailsData,
  title: 'Please provide the name of the tenant and the address of the rental property',
}

export const landlordData = {
  ...contactDetailsData,
  title: 'Please provide the name and address of your landlord or housing association',
}

export const neighbourData = {
  ...contactDetailsData,
  title: 'Please provide the details of the neighbour you are seeking advice in relation to',
  standFirst:
    "Why do we need this? We need to carry out a conflict check. We won't contact your neighbour.",
}

export const medicalIndividualData = {
  ...contactDetailsData,
  title: 'Please provide the info below for the individual you are looking to claim against',
  standFirst: '',
}

export const medicalCompanyData = {
  ...contactDetailsData,
  formItems: [
    {
      displayText: 'Name of company',
      id: 'firstName',
      type: 'text',
      isFocused: true,
    },
  ],
  title:
    'Please provide the info below for the company, organsation or healthcare provider you are looking to claim against',
  standFirst: '',
  infoBoxParagraphs: [
    {
      text: "We need to capture the name of the company you need advice against for regulatory reasons. We won't contact any company you name",
    },
  ],
}

export const carsData = {
  ...contactDetailsData,
  title:
    'Please provide the information below for the other party involved in the accident, if relevant.',
  standFirst:
    "Why do we need this? We need to carry out a conflict check. We won't contact any individual you name",
}

export const employmentData = {
  ...contactDetailsData,
  title:
    'Please provide the name and address of your employer or any other party you are wishing to claim against, if relevant.',
  standFirst:
    "Why do we need this? We need to capture the name of the person you need advice against for regulatory reasons. We won't contact any individual you name",
}

export const willsData = {
  ...contactDetailsData,
  title: 'Please provide the name and date of death of the deceased',
  standFirst:
    "Why do we need this? To check we haven't been advising anyone else in relation to the same issue",
  formItems: [
    ...contactDetailsData.formItems,
    {
      displayText: 'Date of death',
      id: 'date',
      type: 'text',
      isFocused: false,
    },
  ],
  infoBoxParagraphs: [
    {
      text: 'We need to capture this information for regulatory reasons',
    },
  ],
}

export const willsEstateData = {
  ...contactDetailsData,
  title:
    'Please provide the name and date of birth of the donor (the subject) of the power of attorney',
  standFirst:
    "We need this to check that we haven't already been advising somebody else in relation to this",
  formItems: [
    ...contactDetailsData.formItems,
    {
      displayText: 'Date of birth',
      id: 'date',
      type: 'text',
      isFocused: false,
    },
  ],
  infoBoxParagraphs: [
    {
      text: 'We need to capture this information for regulatory reasons',
    },
  ],
}

export const willsNegligenceData = {
  ...contactDetailsData,
  title:
    'Please provide the name and address of the individual or firm you are seeking advice against',
  standFirst:
    "We need this to check that we haven't already been advising somebody else in relation to this",
  infoBoxParagraphs: [
    {
      text: 'We need to capture this information for regulatory reasons',
    },
  ],
}
