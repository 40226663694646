import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { BeenAffectedFlow, LostMoneyFlow, ReportedToFlow } from '../../pageFlowData'
import { beenAffectedData } from './data'

export const BeenAffected: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    victim: { beenAffected },
  } = state

  const handleStepChoice = (value: string) => {
    if (value === 'yes') {
      return { progress: LostMoneyFlow.PROGRESS_VALUE, step: LostMoneyFlow.STEP }
    }

    return { progress: ReportedToFlow.PROGRESS_VALUE, step: ReportedToFlow.STEP }
  }

  const handleDispatch = (value: string) => {
    const { progress, step } = handleStepChoice(value)

    dispatch({ type: 'UPDATE_BEEN_AFFECTED', data: value })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${BeenAffectedFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = (id: string) => {
    handleDispatch(id)
    handleTracking(id)
  }

  const { buttons } = beenAffectedData

  return (
    <div data-testid="been-affected">
      <PillButtonPage
        buttons={buttons}
        defaultValue={beenAffected}
        handleSubmit={handleSubmit}
        imgObj={{
          alt: 'Two people looking stressed at a computer',
          src: 'https://media.product.which.co.uk/prod/images/original/1fea9f96da2f-have-you-been-affected-by-the-scam.jpg',
        }}
        title="Have you been affected by the scam?"
      />
    </div>
  )
}
