import type { PageFlowProps } from '../../../shared/types/toolTypes'

export const MonthlyFeeFlow: PageFlowProps = {
  PROGRESS_VALUE: 0,
  STEP: 1,
  VIEW_NAME: 'monthly-fee',
  REDUCERS: ['UPDATE_MONTHLY_FEE'],
  MILESTONE: 'milestone | start',
}

export const LedYouToPackagedBankAccountFlow: PageFlowProps = {
  PROGRESS_VALUE: 10,
  STEP: 2,
  VIEW_NAME: 'led-you-to-packaged-bank-account',
  REDUCERS: ['UPDATE_LED_YOU_TO_PACKAGED_BANK_ACCOUNT'],
  MILESTONE: '',
}

export const WhyMisSoldAccountFlow: PageFlowProps = {
  PROGRESS_VALUE: 20,
  STEP: 3,
  VIEW_NAME: 'why-mis-sold-account',
  REDUCERS: ['UPDATE_WHY_MIS_SOLD_ACCOUNT'],
  MILESTONE: '',
}

export const HowAccountSoldFlow: PageFlowProps = {
  PROGRESS_VALUE: 30,
  STEP: 4,
  VIEW_NAME: 'how-account-sold',
  REDUCERS: ['UPDATE_HOW_ACCOUNT_SOLD'],
  MILESTONE: '',
}

export const AccountStillOpenFlow: PageFlowProps = {
  PROGRESS_VALUE: 40,
  STEP: 5,
  VIEW_NAME: 'account-still-open',
  REDUCERS: ['UPDATE_ACCOUNT_STILL_OPEN'],
  MILESTONE: '',
}

export const WhatLikeToDoFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 6,
  VIEW_NAME: 'what-like-to-do',
  REDUCERS: ['UPDATE_WHAT_LIKE_TO_DO'],
  MILESTONE: '',
}

export const ClaimCompensationFlow: PageFlowProps = {
  PROGRESS_VALUE: 60,
  STEP: 7,
  VIEW_NAME: 'claim-compensation',
  REDUCERS: ['UPDATE_CLAIM_COMPENSATION'],
  MILESTONE: '',
}

export const NameOfBankFlow: PageFlowProps = {
  PROGRESS_VALUE: 70,
  STEP: 8,
  VIEW_NAME: 'name-of-bank',
  REDUCERS: ['UPDATE_NAME_OF_BANK'],
  MILESTONE: '',
}

export const NameOfAccountFlow: PageFlowProps = {
  PROGRESS_VALUE: 80,
  STEP: 9,
  VIEW_NAME: 'name-of-account',
  REDUCERS: ['UPDATE_NAME_OF_ACCOUNT'],
  MILESTONE: '',
}

export const DateOpenedAccountFlow: PageFlowProps = {
  PROGRESS_VALUE: 85,
  STEP: 10,
  VIEW_NAME: 'date-opened-account',
  REDUCERS: ['UPDATE_DATE_OPENED_ACCOUNT'],
  MILESTONE: '',
}

export const ContactDetailsFlow: PageFlowProps = {
  PROGRESS_VALUE: 90,
  STEP: 11,
  VIEW_NAME: 'contact-details',
  REDUCERS: [''],
  MILESTONE: 'milestone | personal details',
}

export const SubmitSuccessfulFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 12,
  VIEW_NAME: 'journey-complete',
  REDUCERS: ['UPDATE_EMAIL_SENT'],
  MILESTONE: 'milestone | end | letter sent',
}

export const ToolFeedbackFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 13,
  VIEW_NAME: 'feedback-form',
  REDUCERS: [''],
  MILESTONE: 'milestone | feedback start',
}

export const ToolFeedbackAudienceFlow: PageFlowProps = {
  PROGRESS_VALUE: 90,
  STEP: 14,
  VIEW_NAME: 'feedback-audience-form',
  REDUCERS: [''],
  MILESTONE: '',
}

export const ToolFeedbackThankYouFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 15,
  VIEW_NAME: 'feedback-form-thankyou',
  REDUCERS: [''],
  MILESTONE: '',
}

export const LimitedOptionsFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 16,
  VIEW_NAME: 'limited-options',
  REDUCERS: [''],
  MILESTONE: 'milestone | next steps | end',
}

export const AllFlows = [
  MonthlyFeeFlow,
  LedYouToPackagedBankAccountFlow,
  WhyMisSoldAccountFlow,
  HowAccountSoldFlow,
  AccountStillOpenFlow,
  WhatLikeToDoFlow,
  ClaimCompensationFlow,
  NameOfBankFlow,
  NameOfAccountFlow,
  DateOpenedAccountFlow,
  ContactDetailsFlow,
  SubmitSuccessfulFlow,
  ToolFeedbackFlow,
  ToolFeedbackAudienceFlow,
  ToolFeedbackThankYouFlow,
  LimitedOptionsFlow,
]
