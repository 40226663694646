import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../../shared/state/appContext'
import globalStyles from '../../../../../shared/styles/GlobalStyles.module.scss'
import { trackAppointmentBookingSelection } from '../../../../../shared/utils/tracking/tracking'
import { ContactDetailsFlow, ContractChoiceFlow } from '../../../pageFlowData'
import styles from './ContractChoice.module.scss'
import { buyingEmploymentData, contractChoiceData, willsData } from './data'

export const ContractChoice: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    wills: { willsTopic },
    buying: { buyingTopic },
    triage: { contractChoice, type },
    employment: { employmentTopic },
  } = state

  const handleTracking = (id: string, index: number) => {
    trackAppointmentBookingSelection(type, ContractChoiceFlow.VIEW_NAME, { id, index })
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_CONTRACT_CHOICE', data: id })
    dispatch({ type: 'UPDATE_STEP', data: ContactDetailsFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: ContactDetailsFlow.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string, index: number) => {
    handleTracking(id, index)
    handleDispatch(id)
  }

  const chooseData = () => {
    if (willsTopic) {
      return willsData
    } else if (buyingTopic || employmentTopic) {
      return buyingEmploymentData
    }
    return contractChoiceData
  }

  const dataset = chooseData()

  const { buttons, imgObj, title, standFirst } = dataset

  return (
    <div className={globalStyles.fullWidthButton}>
      <PillButtonPage
        buttons={buttons}
        defaultValue={contractChoice}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        infoBoxData={dataset}
        pillButtonGroupStyle={styles.contractChoicePillButtons}
        standFirst={standFirst}
        title={title}
      />
    </div>
  )
}
