export const data = {
  buttons: [
    {
      label: 'Repair',
      id: 'repair',
    },
    {
      label: 'Replacement',
      id: 'replacement',
    },
    {
      label: 'Refund',
      id: 'refund',
    },
  ],
  imgObj: {
    alt: 'Person and child standing near a TV talking to someone in a suit',
    src: 'https://media.product.which.co.uk/prod/images/original/gm-b4802563-2f70-4df8-ac40-71981c2907a7-desired-resolution.jpg',
  },
  title: 'What do you want the seller to do?',
}
