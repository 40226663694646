import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { AccountStillOpenFlow, NameOfBankFlow, WhatLikeToDoFlow } from '../../pageFlowData'
import { data } from './data'

export const AccountStillOpen: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    account: { accountOpen },
  } = state

  const handleStepChoice = (value: string) => {
    if (value === 'no') {
      return { progress: NameOfBankFlow.PROGRESS_VALUE, step: NameOfBankFlow.STEP }
    }

    return { progress: WhatLikeToDoFlow.PROGRESS_VALUE, step: WhatLikeToDoFlow.STEP }
  }

  const handleDispatch = (value: string) => {
    const { progress, step } = handleStepChoice(value)

    dispatch({ type: 'UPDATE_ACCOUNT_STILL_OPEN', data: value })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${AccountStillOpenFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = (id: string) => {
    handleDispatch(id)
    handleTracking(id)
  }

  const { buttons, imgObj, title } = data

  return (
    <>
      <div data-testid="account-still-open">
        <PillButtonPage
          buttons={buttons}
          defaultValue={accountOpen}
          handleSubmit={handleSubmit}
          imgObj={imgObj}
          title={title}
        />
      </div>
    </>
  )
}
