import type { FunctionComponent } from 'react'
import React, { useContext, useState } from 'react'

import { ImpactSurveyPage } from '../../../../shared/pages/ImpactSurveyPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { RadiosSelected } from '../../../../shared/types/toolTypes'
import { surveyModuleSpecificText } from '../../../../shared/utils/surveyModuleSpecificText/surveyModuleSpecificText'
import { HowMuchTimeSavedFlow, ReducedStressFlow } from '../../pageFlowData'
import { getStepData } from '../../utils/getStepData'
import { claimEaseOfUse, timeSaved, toolAvailableData } from './data'

export const IfNoWhichTool: FunctionComponent = () => {
  const { dispatch, state } = useContext(ToolContext)
  const {
    answers: { ifNoWhichTool, toolEasier, savedTime },
    user: { toolID },
  } = state

  const mergeStateArrays = [...(ifNoWhichTool || []), ...(toolEasier || []), ...(savedTime || [])]

  const [radiosSelected, setRadiosSelected] = useState<RadiosSelected>(mergeStateArrays)

  const toolSpecificText = surveyModuleSpecificText(toolID)
  const { toolName, radioExperience } = getStepData(toolID)

  const handleStepChoice = (timeSavedObj) => {
    if (
      timeSavedObj[0]?.id === 'yes-a-little' ||
      timeSavedObj[0]?.id === 'yes-some' ||
      timeSavedObj[0]?.id === 'yes-a-lot'
    ) {
      return { progress: HowMuchTimeSavedFlow.PROGRESS_VALUE, step: HowMuchTimeSavedFlow.STEP }
    }
    return { progress: ReducedStressFlow.PROGRESS_VALUE, step: ReducedStressFlow.STEP }
  }

  const handleDispatch = () => {
    const timeSavedObj = [radiosSelected.find((o) => o.groupId === 'time-saved')]
    const { progress, step } = handleStepChoice(timeSavedObj)

    dispatch({
      type: 'UPDATE_NO_WHICH_TOOL',
      data: [radiosSelected.find((o) => o.groupId === 'which-tool-available')],
    })
    dispatch({
      type: 'UPDATE_TOOL_EASIER',
      data: [radiosSelected.find((o) => o.groupId === 'claim-ease-of-use')],
    })
    dispatch({ type: 'UPDATE_SAVED_TIME', data: timeSavedObj })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleSubmit = async (validationPassed: boolean) => {
    if (validationPassed) {
      handleDispatch()
    }
  }

  const toolAvailableTiltle = `If the Which? ${toolName} tool had not been available, do you think you would still have made ${radioExperience}?`
  const claimEaseOfUseTiltle = `Do you feel that using the Which? ${toolName} tool made it easier for you to make ${radioExperience} than it might have been otherwise?`

  const radioQuestions = [
    {
      ...toolAvailableData.radioQuestionData,
      title: toolAvailableTiltle,
    },
    {
      ...claimEaseOfUse.radioQuestionData,
      title: claimEaseOfUseTiltle,
    },
    {
      ...timeSaved.radioQuestionData,
      title: `Do you feel that using the Which? ${toolName} tool saved you any time in making your ${toolSpecificText}?`,
    },
  ]

  return (
    <ImpactSurveyPage
      handleSubmit={handleSubmit}
      nextButtonText="Next"
      radioQuestions={radioQuestions}
      radiosSelected={radiosSelected}
      setRadiosSelected={setRadiosSelected}
    />
  )
}
