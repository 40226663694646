import type { FunctionComponent } from 'react'
import React from 'react'
import { Picture, Typography } from '@which/seatbelt'

import classNames from 'classnames'

import globalStyles from '../../styles/GlobalStyles.module.scss'
import type { ImageWithQuestionProps } from '../../types/toolTypes'
import styles from './ImageWithQuestion.module.scss'

export const ImageWithQuestion: FunctionComponent<ImageWithQuestionProps> = ({
  image,
  optionalTag,
  optionalText = '(optional)',
  title,
  titleStyle = 'title-600',
  titleTag = 'p',
  bottomMargin = globalStyles.spacing40,
  pictureClass = '',
  titleRef,
  className,
}) => {
  return (
    <div
      className={classNames(
        bottomMargin,
        styles.imageWithQuestion,
        globalStyles.maxWidth,
        className
      )}
      data-testid="tool-image-and-question"
    >
      <Picture
        {...image}
        aspectRatioMain="two-to-one"
        className={classNames(globalStyles.spacing30, styles.pictureContainer, pictureClass)}
        lazyLoad={false}
      />
      <div className="sb-text-align-center">
        <Typography
          dangerouslySetInnerHTML={{ __html: title }}
          forwardRef={titleRef}
          tabIndex={-1}
          tag={titleTag}
          textStyle={titleStyle}
        ></Typography>
        {optionalTag && (
          <Typography className={styles.imageWithQuestionOptional} textStyle="title-300">
            {optionalText}
          </Typography>
        )}
      </div>
    </div>
  )
}
