import type { InfoBoxProps } from '../../../../../../shared/types/toolTypes'

export const willsTopicData = {
  buttons: [
    {
      label: 'England',
      id: 'england',
    },
    {
      label: 'Wales',
      id: 'wales',
    },
    {
      label: 'Scotland',
      id: 'scotland',
    },
    {
      label: 'Northern Ireland or overseas',
      id: 'northern-ireland-or-overseas',
    },
  ],
  title: "Please select which country's laws are relevant to your query?",
  imgObj: {
    alt: 'two people holding documents',
    src: 'https://media.product.which.co.uk/prod/images/original/8f95d1a7f0dc-cp-step9.jpg',
  },
  infoBox: {
    heading: 'What does this mean?',
    borderColour: 'blue',
  } as InfoBoxProps,
  infoBoxParagraphs: [
    {
      text: 'The law is not always the same in different parts of the UK. Please select the country which is relevant to your query - this is not necessarily the county you live in. For example, if you live in Scotland but need assistance with a probate matter in England, select England.',
    },
  ],
}
