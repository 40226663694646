import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { ComplaintTypeFlow, LimitedOptionsFlow, WorkTraderFlow } from '../../pageFlowData'
import { complaintTypeData } from './data'

export const ComplaintType: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    work: { complaint },
  } = state

  const handleStepChoice = (id: string) => {
    if (id === 'goods-supplied') {
      return { progress: LimitedOptionsFlow.PROGRESS_VALUE, step: LimitedOptionsFlow.STEP }
    }
    return { progress: WorkTraderFlow.PROGRESS_VALUE, step: WorkTraderFlow.STEP }
  }

  const handleTracking = (id: string) => {
    const trackingAnswer = `question | ${ComplaintTypeFlow.VIEW_NAME} | ${id}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (id: string) => {
    const { progress, step } = handleStepChoice(id)

    dispatch({ type: 'UPDATE_COMPLAINT', data: id })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleSubmit = (id: string) => {
    handleTracking(id)
    handleDispatch(id)
  }

  const { buttons, imgObj, title } = complaintTypeData

  return (
    <div>
      <PillButtonPage
        buttons={buttons}
        defaultValue={complaint ? complaint : ''}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        title={title}
      />
    </div>
  )
}
