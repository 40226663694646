export const scammerData = {
  telephone: {
    title: 'What was the nature of the call?',
    imgObj: {
      alt: 'A person looking angry at their phone',
      src: 'https://media.product.which.co.uk/prod/images/original/11dec439b65d-what-was-the-nature-of-the-call-optional.jpg',
    },
  },
  online: {
    title: 'Can you tell us more about what type of scam this was?',
    imgObj: {
      alt: 'A person sitting down at a desk typing on a computer',
      src: 'https://media.product.which.co.uk/prod/images/original/f4cda2c4bdfe-can-you-tell-us-more-about-what-type-of-scam-this-was-optional.jpg',
    },
  },
  house: {
    title: 'Who did the person claim to be?',
    imgObj: {
      alt: 'A cleaner and a man with a laptop standing next to each other',
      src: 'https://media.product.which.co.uk/prod/images/original/6806c3aa36e9-who-did-the-person-claim-to-be-optional.jpg',
    },
  },
  social: {
    title: 'Which social media platform?',
    imgObj: {
      alt: 'A person who is browsing on social media',
      src: 'https://media.product.which.co.uk/prod/images/original/1fe27e4c77e4-which-social-media-platform-optional.jpg',
    },
  },
}
