import type { FunctionComponent } from 'react'
import React, { useContext, useEffect } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import {
  DealerOfferedSolutionFlow,
  LimitedOptionsFlow,
  OnFinanceFlow,
  WhichDealerFlow,
} from '../../pageFlowData'
import { onFinanceData } from './data'

export const OnFinance: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    car: { onFinance, whenPurchased },
  } = state

  useEffect(() => {
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: OnFinanceFlow.PROGRESS_VALUE })
  }, [dispatch])

  const handleStepChoice = (id: string) => {
    if (id === 'yes') {
      return { progress: LimitedOptionsFlow.PROGRESS_VALUE, step: LimitedOptionsFlow.STEP }
    }

    const lessThan30DaysSincePurchase =
      new Date(whenPurchased).getTime() > new Date().getTime() - 30 * 24 * 60 * 60 * 1000
    if (lessThan30DaysSincePurchase) {
      return { progress: WhichDealerFlow.PROGRESS_VALUE, step: WhichDealerFlow.STEP }
    }

    return {
      progress: DealerOfferedSolutionFlow.PROGRESS_VALUE,
      step: DealerOfferedSolutionFlow.STEP,
    }
  }

  const handleTracking = (id: string) => {
    const trackingAnswer = `question | ${OnFinanceFlow.VIEW_NAME} | ${id}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (id: string) => {
    const { progress, step } = handleStepChoice(id)

    dispatch({ type: 'UPDATE_ON_FINANCE', data: id })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleSubmit = (id: string) => {
    handleTracking(id)
    handleDispatch(id)
  }

  const { buttons, imgObj, standFirst, title } = onFinanceData

  return (
    <PillButtonPage
      buttons={buttons}
      defaultValue={onFinance ? onFinance : ''}
      handleSubmit={handleSubmit}
      imgObj={imgObj}
      standFirst={standFirst}
      title={title}
    />
  )
}
