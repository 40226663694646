export const toolFeedbackData = {
  title: 'Your feedback on the flight delay and cancellation compensation tool',
  standFirst: 'Your feedback will help us improve the experience for others.',
  radioQuestions: [
    {
      buttons: [
        { label: 'Yes, more than once', id: 'yes-more-than-once-1', isFocused: false },
        { label: 'Yes, once', id: 'yes-once-1', isFocused: false },
        { label: 'No', id: 'no-1', isFocused: false },
        { label: 'Not sure', id: 'not-sure-1', isFocused: false },
      ],
      isSubmitValid: true,
      id: 'contactedSeller',
      title:
        'Before using the tool today, had you already contacted the airline regarding your problem?',
    },
    {
      buttons: [
        { label: 'Yes', id: 'yes-2', isFocused: false },
        { label: 'No', id: 'no-2', isFocused: false },
        { label: 'Not sure', id: 'not-sure-2', isFocused: false },
      ],
      isSubmitValid: true,
      id: 'betterUnderstanding',
      title: 'Has using the tool helped you better understand your consumer rights?',
    },
    {
      buttons: [
        { label: 'No, not at all', id: 'no-not-at-all', isFocused: false },
        {
          label: 'Yes, a little less worried/stressed',
          id: 'yes-little-less-stress-3',
          isFocused: false,
        },
        {
          label: 'Yes, somewhat less worried/stressed',
          id: 'yes-somewhat-less-stress-3',
          isFocused: false,
        },
        {
          label: 'Yes, a lot less worried/stressed',
          id: 'yes-a-lot-less-stress-3',
          isFocused: false,
        },
        { label: 'Not sure', id: 'not-sure-3', isFocused: false },
        {
          label: 'Not applicable, I have not been feeling worried or stressed',
          id: 'not-applicable-3',
          isFocused: false,
        },
      ],
      isSubmitValid: true,
      id: 'stressAboutClaim',
      title:
        'Has using the tool helped you feel any less worried or stressed about making a claim?',
    },
    {
      buttons: [
        { label: 'Yes', id: 'yes-4', isFocused: false },
        { label: 'No', id: 'no-4', isFocused: false },
        { label: 'Not sure', id: 'not-sure-4', isFocused: false },
      ],
      isSubmitValid: true,
      id: 'easyToUse',
      title: 'Did you find the tool easy to use?',
    },
  ],
  textareaQuestions: [
    {
      title: 'What, if anything, would improve this tool?',
      id: 'suggestedChanges',
      defaultValue: '',
      placeholderText: '',
      isInputValid: true,
      isOptional: true,
    },
  ],
}
