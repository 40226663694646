import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'
import { InfoBox, Typography } from '@which/seatbelt'

import classnames from 'classnames'

import { PageWithText } from '../../../../shared/components/PageWithText'
import { FormEntryPage } from '../../../../shared/pages/FormEntryPage'
import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import globalStyles from '../../../../shared/styles/GlobalStyles.module.scss'
import type { FormEntryInputRef, SingleInputState } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { handleTextReplace } from '../../../../shared/utils/handleTextReplace'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import {
  EntitlementFlow,
  PurchasePriceFlow,
  WhichRetailerFlow,
  WriteLetterFlow,
} from '../../pageFlowData'
import { chooseDataSet } from './chooseDataSet'
import { entitlementData } from './data'
import styles from './Entitlement.module.scss'

export const Entitlement: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    issue,
    product,
    retailer: { attemptedResolveItem, channel, name, whenBought },
  } = state

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })
  const EntitlementTextInput = useRef<FormEntryInputRef>(null)

  const { brand, description, model, onFinance, type } = product
  const { shortDesc } = issue
  const modelText = model ? `${brand} ${model} ${description}` : `${brand || ''} ${description}`

  const data = chooseDataSet({
    type,
    onFinance,
    channel,
    whenBought,
    attemptedResolveItem,
    shortDesc,
  })

  const getValue = () => cleanText(EntitlementTextInput?.current?.formEntryInput?.value || '')

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${EntitlementFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_WRITELETTER_STEP', data: PurchasePriceFlow.WRITE_LETTER_STEP })
    dispatch({ type: 'UPDATE_RETAILER_NAME', data: value })
    dispatch({ type: 'UPDATE_STEP', data: WriteLetterFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: WriteLetterFlow.PROGRESS_VALUE })
  }

  const handleNextButtonSubmit = () => {
    const value = getValue()
    const brandHasError = !isNotEmpty(value)

    if (!brandHasError) {
      handleTracking(value)
      handleDispatch(value)
      return
    }

    setInputData({
      renderError: brandHasError,
      value: value,
    })
  }

  const handlePillButtonSubmit = (id: string) => {
    handleTracking(id)

    dispatch({ type: 'UPDATE_ISSUE_DESIREDRESOLUTION', data: id })
    dispatch({ type: 'UPDATE_WRITELETTER_STEP', data: WhichRetailerFlow.WRITE_LETTER_STEP })
    dispatch({ type: 'UPDATE_STEP', data: WriteLetterFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: WriteLetterFlow.PROGRESS_VALUE })
  }

  const { bodyCopy, standfirst, buttons, imageURL, imageTitle, textField, title } = data
  const { renderError, value: errorValue } = inputData
  const { infoBox, paragraphOne, paragraphTwo } = entitlementData

  return (
    <div data-testid="entitlement">
      <PageWithText
        bodyCopy={bodyCopy}
        className={styles.entitlementPage}
        standFirst={standfirst}
        title={title}
      >
        {buttons.length > 0 && (
          <PillButtonPage
            buttons={buttons}
            defaultValue={issue.desiredResolution}
            handleSubmit={handlePillButtonSubmit}
            imgObj={{
              alt: 'A person repairing a computer',
              src: imageURL,
            }}
            pillButtonGroupStyle={styles.entitlementPagePillButtons}
            title={imageTitle}
          />
        )}
        {textField && (
          <FormEntryPage
            buttonText="Next"
            defaultValue={name}
            displayText=""
            fieldData={{
              renderError: renderError,
              value: errorValue,
            }}
            handleSubmit={handleNextButtonSubmit}
            id="which-retailer"
            imgObj={{
              alt: 'A person repairing a computer',
              src: imageURL,
            }}
            isFocused
            placeholderText={textField}
            ref={EntitlementTextInput}
            testId="which-retailer"
            title={imageTitle}
            type="text"
          />
        )}
        <InfoBox
          {...infoBox}
          className={classnames(globalStyles.maxWidth, globalStyles.spacing40Top)}
        >
          <Typography className={styles.entitlementPageParagraph} tag="p" textStyle="body">
            {handleTextReplace(paragraphOne, '<<PRODUCT>>', modelText)}
          </Typography>
          <Typography tag="p" textStyle="body">
            {handleTextReplace(paragraphTwo, '<<PRODUCT>>', modelText)}
          </Typography>
        </InfoBox>
      </PageWithText>
    </div>
  )
}
