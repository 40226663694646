export const moneyNewsLetterImage = {
  alt: 'The money newsletter being displayed on a tablet',
  src: 'https://media.product.which.co.uk/prod/images/original/fd070e209eed-money-newsletter.png',
}

// COPY ////////////////

const energyText = (energyState: string) => {
  let energyCopy = ''
  switch (energyState) {
    case 'prepayment-meter':
      energyCopy = 'pay for your energy by a prepayment meter'
      break
    case 'direct-debit':
      energyCopy = 'pay for your energy by direct debit'
      break
    case 'pay-monthly-quarterly':
      energyCopy = 'pay for your energy by what you use monthly or quarterly'
      break
    default:
      energyCopy = 'have a prepayment meter'
      break
  }
  return energyCopy
}

const propertyText = (propertyState: string) => {
  let propertyCopy = ''
  switch (propertyState) {
    case 'rent':
      propertyCopy = 'are <strong>renting</strong>'
      break
    case 'mortgage':
      propertyCopy = 'are <strong>paying a mortgage</strong>'
      break
    case 'outright':
      propertyCopy = '<strong>own your house outright</strong>'
      break
    default:
      propertyCopy = 'are <strong>renting</strong>'
      break
  }
  return propertyCopy
}

const payingDebtsText = (payingDebtsState: string[]) => {
  const payingDebtsCopy: string[] = []

  if (payingDebtsState) {
    payingDebtsState.forEach((id) => {
      if (id === 'credit-cards') {
        payingDebtsCopy.push('paying off a credit card balance')
      }
      if (id === 'personal-loans') {
        payingDebtsCopy.push('paying off a personal loan')
      }
      if (id === 'buy-now-pay-later') {
        payingDebtsCopy.push('paying off a buy now pay later loan')
      }
      if (id === 'no-credit-cards-or-loans') {
        payingDebtsCopy.push('not paying off any credit cards or loans')
      }
    })

    const formattedCopy = payingDebtsCopy.reduce(
      // eslint-disable-next-line max-params
      (text, value, i, array) => text + (i < array.length - 1 ? ', ' : ' and ') + value
    )
    return formattedCopy
  } else {
    return null
  }
}

const broadbandPrefixText = (broadbandState: string) => {
  const broadbandText = '<strong>fixed broadband contract</strong>'

  const broadbandStateText =
    broadbandState === 'yes' ? `on a ${broadbandText}` : `not on a ${broadbandText}`

  return broadbandStateText
}

const shoppingText = (shoppingState: string) => {
  const shoppingTextBase = 'do most of your shopping at a'

  const shoppingStateText =
    shoppingState === 'supermarkets'
      ? `<strong> ${shoppingTextBase} supermarket</strong>`
      : `<strong> ${shoppingTextBase} convenience store</strong>`

  return shoppingStateText
}

export const resultsPageTitle = 'Your cost-saving advice'
export const resultPageStandfirst = (financeSituation: string) => {
  // @ts-expect-error - needs investigation and fix
  return `${resultPageStandfirstText(
    financeSituation
  )}. Based on your answers, our tool has listed some of our latest expert recommendations that could help.`
}

export const houseHoldBillsStandfirst = (
  standfirst: string,
  { energyPayment, broadbandContract, foodShop }
) => {
  let text = standfirst

  text = text.replace('<dynamicEnergyText>', `<strong>${energyText(energyPayment)}</strong>`)
  text = text.replace('<dynamicBroadbandText>', `${broadbandPrefixText(broadbandContract)}`)
  text = text.replace('<dynamicShoppingText>', `<strong>${shoppingText(foodShop)}</strong>`)

  return `${text}`
}

export const householdFinancesStandfirst = (
  standfirst: string,
  { propertyPayment, payingDebts }
) => {
  let text = standfirst
  text = text.replace('<dynamicPropertyText>', `${propertyText(propertyPayment)}`)
  text = text.replace(
    '<dynamicPayingDebtsText>',
    `<strong>${payingDebtsText(payingDebts)}</strong>`
  )

  return `${text}`
}

export const nextStepsSection = {
  title: 'Your next steps:',
  email: {
    buttonText: 'Email me my actions',
    title: 'Email me my actions',
    unsubText: 'No spam and you can unsubscribe at any time.',
  },
  download: {
    buttonText: 'Download my actions',
    image:
      'https://media.product.which.co.uk/prod/images/original/aa49f6e27199-download-mmhc-results.jpg',
    standfirst: 'Take your results away with you',
    title: 'Download my actions',
  },
  retake: {
    buttonText: 'Retake the My Money Health Check',
  },
}
