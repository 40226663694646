import type { InfoBoxProps } from '../../../../../shared/types/toolTypes'

const infoBox: InfoBoxProps = {
  heading: 'What flights apply?',
  borderColour: 'blue',
}

export const data = {
  buttons: [
    {
      label: 'The flight departed from a UK airport',
      id: 'flight-departed-uk',
    },
    {
      label: 'The flight arrived at a UK airport',
      id: 'flight-arrived-uk',
    },
    {
      label: "The flight wasn't to or from the UK",
      id: 'flight-not-to-or-from-uk',
    },
  ],
  imgObj: {
    alt: 'Person checking in baggage',
    src: 'https://media.product.which.co.uk/prod/images/original/b06f685d80c2-flight-delay-cancellation-step-2.jpg',
  },
  title: 'Did your flight arrive or depart from a UK airport?',
  infoBoxData: {
    title: 'What flights apply?',
    infoBox: infoBox,
    infoBoxParagraphs: [
      {
        text: 'Please note if you were flying into the UK from outside of Europe, it will need to have been on a UK or EU-based airline for your compensation claim to be valid.',
      },
    ],
  },
}
