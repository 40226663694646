import type { DataLayerObject } from '@which/shared'

import { TriageFlow } from '../../../tools/BookAppointment/pageFlowData'
import { getSessionStorageItem } from '../getSessionStorageItem'

declare global {
  interface Window {
    dataLayer: DataLayerObject[]
  }
}

export const initaliseDataLayer = (toolName: string) => {
  return `
    window.dataLayer = window.dataLayer || []

    window.dataLayer.push({
      'toolSite':'consumer-rights',
      'vertical':'consumer-rights',
      'prtToolName': '${toolName}',
      'content_type':'${
        toolName === 'book appointment' ? 'administration tools' : 'problem resolution tools'
      }',
      'topicName':'Tools',
      'subtopicName':'${toolName}',
    });
  `
}

export const pageViewDataLayer = (pageName: string) => {
  window.dataLayer.push({
    page: pageName,
    event: 'pageview',
  })
}

export const generalDataLayer = (
  toolName: string,
  eventLabel: string,
  eventInteractive = true,
  appointmentBooking?: string
  // eslint-disable-next-line max-params
) => {
  const dataPush = {
    eventCategory:
      toolName === 'book appointment' ? 'administration tools' : 'problem resolution tools',
    eventAction: toolName,
    eventLabel: eventLabel,
    eventValue: 0,
    eventInteractive: eventInteractive,
    event: 'tEvent',
  }
  if (appointmentBooking) {
    dataPush['item_spec'] = appointmentBooking
  }
  window.dataLayer.push(dataPush)
}

type AppointmentType = 'tech' | 'legal' | 'money'

type Selection = {
  id: string
  index: number
}

const parseLabel = (label: string) => label.replaceAll('-', ' ')

const pushAppointmentBookingQuestionToDataLayer = (
  appointmentType: AppointmentType | undefined,
  eventLabel: string
) => {
  window.dataLayer.push({
    eventCategory: 'administration tools',
    eventAction: 'book appointment',
    eventLabel: parseLabel(eventLabel),
    eventValue: 0,
    eventInteractive: false,
    event: 'tEvent',
    item_spec: appointmentType,
  })
}

export const trackPIIAppointmentBookingSubmission = (
  appointmentType: AppointmentType,
  questionId: string
) => {
  const step = (getSessionStorageItem('BookAppointmentProgress') as number[]).length

  const eventLabel = `question | ${questionId} | PII | ${step} | PII text`

  pushAppointmentBookingQuestionToDataLayer(appointmentType, eventLabel)
}

export const trackAppointmentBookingSelection = (
  appointmentType: AppointmentType,
  questionId: string,
  selection: Selection
) => {
  const step = (getSessionStorageItem('BookAppointmentProgress') as number[]).length

  const eventLabel = `question | ${questionId} | ${selection.id} | ${step} | ${selection.index + 1}`

  pushAppointmentBookingQuestionToDataLayer(appointmentType, eventLabel)
}

export const trackAppointmentBookingTriageSelection = (selection: Selection) => {
  const eventLabel = `question | ${TriageFlow.VIEW_NAME} | ${selection.id} | 0 | ${
    selection.index + 1
  }`

  pushAppointmentBookingQuestionToDataLayer(undefined, eventLabel)
}

export const initialiseAppointmentBookedTracking = ({
  caseType,
  caseArea,
  subject,
  status,
}: {
  caseType: string
  caseArea: string
  subject: string
  status: string
}) => {
  const handleAppointmentBooked = (e) => {
    if (e.data?.event === 'eventsaved') {
      window.dataLayer.push({
        event: 'appointment_success',
        item_text: caseType,
        item_parent_text: caseArea,
        item_grandparent_text: subject,
        item_spec: status,
        utagid: 'AB121DP01',
      })
    }
  }

  window.addEventListener('message', handleAppointmentBooked)

  return () => window.removeEventListener('message', handleAppointmentBooked)
}
