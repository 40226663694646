import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../../shared/state/appContext'
import globalStyles from '../../../../../shared/styles/GlobalStyles.module.scss'
import { trackAppointmentBookingSelection } from '../../../../../shared/utils/tracking/tracking'
import {
  ContractChoiceFlow,
  DescribeCaseFlow,
  HolidayBookingFlow,
  HolidayQueryFlow,
  HolidayTopicFlow,
  LimitedOptionsFlow,
} from '../../../pageFlowData'
import { holidayTopicData } from './data'

export const HolidayTopic: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    holiday: { holidayTopic },
    triage: { type },
  } = state

  const handleTracking = (id: string, index: number) => {
    trackAppointmentBookingSelection(type, HolidayTopicFlow.VIEW_NAME, { id, index })
  }

  const handleChoiceDispatch = (id: string) => {
    switch (id) {
      case 'vehicle':
        dispatch({ type: 'UPDATE_CARS_TOPIC', data: id })
        dispatch({ type: 'UPDATE_LEGAL_TOPIC', data: 'cars' })
        break
      case 'uk-insurance':
        dispatch({ type: 'UPDATE_BUYING_TOPIC', data: id })
        dispatch({ type: 'UPDATE_LEGAL_TOPIC', data: 'buying' })
        break
      default:
        dispatch({ type: 'UPDATE_HOLIDAY_TOPIC', data: id })
        dispatch({ type: 'UPDATE_LEGAL_TOPIC', data: 'holiday' })
        break
    }
  }

  const handleStep = (id: string) => {
    switch (id) {
      case 'timeshares':
        return HolidayQueryFlow
      case 'passport-issues':
        return LimitedOptionsFlow
      case 'holiday-accommodation':
        return ContractChoiceFlow
      case 'uk-insurance':
      case 'issues-with-flight':
      case 'lost-luggage':
        return DescribeCaseFlow
      default:
        return HolidayBookingFlow
    }
  }

  const handleDispatch = (id: string) => {
    handleChoiceDispatch(id)
    const step = handleStep(id)
    dispatch({ type: 'UPDATE_STEP', data: step.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: step.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string, index: number) => {
    handleTracking(id, index)
    handleDispatch(id)
  }

  const { buttons, imgObj, title } = holidayTopicData

  return (
    <div className={globalStyles.fullWidthButton}>
      <PillButtonPage
        buttons={buttons}
        defaultValue={holidayTopic ? holidayTopic : ''}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        title={title}
      />
    </div>
  )
}
