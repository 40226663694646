import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { FormEntryPage } from '../../../../shared/pages/FormEntryPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryInputRef, SingleInputState } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { BeenAffectedFlow, ScammerReferenceFlow } from '../../pageFlowData'
import { scammerReferenceData } from './data'

export const ScammerReference: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    scam: { scammerReference, comeAcrossScam },
  } = state

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })
  const scammerReferenceInput = useRef<FormEntryInputRef>(null)

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${ScammerReferenceFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_STEP', data: BeenAffectedFlow.STEP })
    dispatch({ type: 'UPDATE_SCAMMER_REFERENCE', data: value })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: BeenAffectedFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    const value = getCleanValue(scammerReferenceInput?.current?.formEntryInput?.value)

    setInputData({
      renderError: false,
      value: value,
    })

    handleTracking(value)
    handleDispatch(value)
  }

  const { telephone, online, email, textMessage, whatsapp, social } = scammerReferenceData

  const chooseTitle = () => {
    switch (comeAcrossScam) {
      case 'telephone':
        return telephone
      case 'online':
        return online
      case 'email':
        return email
      case 'text-message':
        return textMessage
      case 'whatsapp':
        return whatsapp
      case 'social-media':
        return social
      default:
        return telephone
    }
  }

  const { imgObj, title } = chooseTitle()
  const { renderError, value: errorValue } = inputData

  return (
    <FormEntryPage
      buttonText="Next"
      defaultValue={scammerReference}
      fieldData={{
        renderError: renderError,
        value: errorValue,
      }}
      handleSubmit={handleSubmit}
      id="scammer-reference"
      imgObj={imgObj}
      isFocused={true}
      optional
      placeholderText=""
      ref={scammerReferenceInput}
      testId="scammer-reference"
      title={title}
    />
  )
}
