export const bankData = [
  {
    label: 'Acorn Account',
    value: 'acorn-account',
  },
  {
    label: 'Adam & Company',
    value: 'adam-&-company',
  },
  {
    label: 'AI Rayan Bank',
    value: 'ai-rayan-bank',
  },
  {
    label: 'AIB (NI)',
    value: 'aib-(ni)',
  },
  {
    label: 'Allied Irish Bank (GB)',
    value: 'allied-irish-bank-(gb)',
  },
  {
    label: 'Arbuthnot Latham & Co. Limited',
    value: 'arbuthnot-latham-&-co.-limited',
  },
  {
    label: 'Bank of Ireland UK PLC',
    value: 'bank-of-ireland-uk-plc',
  },
  {
    label: 'Bank of Scotland',
    value: 'bank-of-scotland',
  },
  {
    label: 'Bank of China',
    value: 'bank-of-china',
  },
  {
    label: 'Barclays',
    value: 'barclays',
  },
  {
    label: 'Barclays Private',
    value: 'barclays-private',
  },
  {
    label: 'C. Hoare & Co.',
    value: 'c.-hoare-&-co.',
  },
  {
    label: 'Cashplus Bank',
    value: 'cashplus-bank',
  },
  {
    label: 'Cater Allen',
    value: 'cater-allen',
  },
  {
    label: 'CitiBank',
    value: 'citibank',
  },
  {
    label: 'CardOneMoney',
    value: 'cardonemoney',
  },
  {
    label: 'Chase',
    value: 'chase',
  },
  {
    label: 'Clydesdale Bank Plc',
    value: 'clydesdale-bank-plc',
  },
  {
    label: 'Co-operative Bank',
    value: 'co-operative-bank',
  },
  {
    label: 'Coutts',
    value: 'coutts',
  },
  {
    label: 'Coventry Building Society',
    value: 'coventry-building-society',
  },
  {
    label: 'Cumberland Building Society',
    value: 'cumberland-building-society',
  },
  {
    label: 'Cynergy Bank',
    value: 'cynergy-bank',
  },
  {
    label: 'Danske Bank (Northern Bank Limited)',
    value: 'danske-bank-(northern-bank-limited)',
  },
  {
    label: 'Eccount',
    value: 'eccount',
  },
  {
    label: 'First Direct',
    value: 'first-direct',
  },
  {
    label: 'Habib Bank Zurich plc',
    value: 'habib-bank-zurich-plc',
  },
  {
    label: 'Halifax',
    value: 'halifax',
  },
  {
    label: 'Hampden & Co',
    value: 'hampden-&-co',
  },
  {
    label: 'Handelsbanken',
    value: 'handelsbanken',
  },
  {
    label: 'HSBC CIIOM',
    value: 'hsbc-ciiom',
  },
  {
    label: 'HSBC Private Bank',
    value: 'hsbc-private-bank',
  },
  {
    label: 'HSBC UK Bank plc',
    value: 'hsbc-uk-bank-plc',
  },
  {
    label: 'Investec Bank plc',
    value: 'investec-bank-plc',
  },
  {
    label: 'Isle of Man Bank',
    value: 'isle-of-man-bank',
  },
  {
    label: 'Lloyds Bank',
    value: 'lloyds-bank',
  },
  {
    label: 'Lloyds International',
    value: 'lloyds-international',
  },
  {
    label: 'Lloyds Private Bank',
    value: 'lloyds-private-bank',
  },
  {
    label: 'Metro Bank',
    value: 'metro-bank',
  },
  {
    label: 'Monzo',
    value: 'monzo',
  },
  {
    label: 'Nationwide Building Society',
    value: 'nationwide-building-society',
  },
  {
    label: 'NatWest',
    value: 'natwest',
  },
  {
    label: 'Natwest International',
    value: 'natwest-international',
  },
  {
    label: 'Reliance Bank',
    value: 'reliance-bank',
  },
  {
    label: 'Royal Bank of Scotland',
    value: 'royal-bank-of-scotland',
  },
  {
    label: 'Santander',
    value: 'santander',
  },
  {
    label: 'smile',
    value: 'smile',
  },
  {
    label: 'Starling Bank',
    value: 'starling-bank',
  },
  {
    label: 'SBI UK',
    value: 'sbi-uk',
  },
  {
    label: 'thinkmoney',
    value: 'thinkmoney',
  },
  {
    label: 'Triodos Bank',
    value: 'triodos-bank',
  },
  {
    label: 'TSB Bank',
    value: 'tsb-bank',
  },
  {
    label: 'Ulster Bank',
    value: 'ulster-bank',
  },
  {
    label: 'Unity Trust Bank',
    value: 'unity-trust-bank',
  },
  {
    label: 'Virgin Money',
    value: 'virgin-money',
  },
  {
    label: 'Weatherbys Bank Ltd',
    value: 'weatherbys-bank-ltd',
  },
  {
    label: 'Yorkshire Bank',
    value: 'yorkshire-bank',
  },
]
