import type { FunctionComponent } from 'react'
import React, { useContext, useEffect } from 'react'

import { ToolWrapper } from '../../shared'
import { PageFade } from '../../shared/components/PageFade'
import { ToolContext } from '../../shared/state/appContext'
import type { EnergyCostCalculatorToolProps } from '../../shared/types/toolTypes'
import { formatToolName } from '../../shared/utils/formatToolName'
import { getFilteredPageDataByStep } from '../../shared/utils/getFilteredPageDataByStep/getFilteredPageDataByStep'
import { parseJson } from '../../shared/utils/parseJson/parseJson'
import { setUserJourney } from '../../shared/utils/setUserJourney'
import { generalDataLayer } from '../../shared/utils/tracking'
import styles from './EnergyCostCalculator.module.scss'
import { useRenderStep } from './hooks/useRenderStep'
import { AllFlows, HowMuchFlow } from './pageFlowData'

export const EnergyCostCalculator: FunctionComponent<EnergyCostCalculatorToolProps> = ({
  toolName,
  optionsJson,
}) => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName, initialised, journeyFlow, step, userJourney },
    energy: { multiplier },
  } = state

  const multiplierOptions = parseJson(optionsJson)

  useEffect(() => {
    if (!multiplier && multiplierOptions) {
      dispatch({ type: 'UPDATE_MULTIPLIER', data: multiplierOptions })
    }
  }, [dispatch, multiplierOptions, multiplier])

  useEffect(() => {
    if (!entryToolName) {
      dispatch({ type: 'UPDATE_ENTRY_TOOL_NAME', data: toolName })
    }
  }, [dispatch, entryToolName, toolName])

  useEffect(() => {
    setUserJourney(userJourney, step, dispatch)
  }, [step, dispatch, userJourney])

  useEffect(() => {
    const firePageView = () => {
      const pageData = getFilteredPageDataByStep(step, AllFlows, [HowMuchFlow])

      const { milestone, viewName } = pageData
      if (initialised === true) {
        const milestoneArray = journeyFlow.milestoneHit

        if (!milestoneArray.includes(viewName) && milestone !== '') {
          milestoneArray.push(viewName)
          generalDataLayer(formatToolName(toolName), milestone)
          dispatch({ type: 'UPDATE_MILESTONE', data: milestoneArray })
          return
        }
      }
    }

    firePageView()
  }, [dispatch, initialised, journeyFlow.milestoneHit, journeyFlow.startLetter, step, toolName])

  return (
    <section className={styles.container}>
      <ToolWrapper width="article">
        <PageFade stateProgress={0} step={step} toolName={toolName}>
          {useRenderStep(step, optionsJson)}
        </PageFade>
      </ToolWrapper>
    </section>
  )
}
