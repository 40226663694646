import { constructDate } from '../../../../shared/components/DatePicker/formatDate/constructDate'
import type { UserProps } from '../../../../shared/types/toolTypes'
import type { carProblemToolState } from '../../state/carProblemToolState'

export const composeEmailBody = ({
  car,
  user,
}: {
  car: typeof carProblemToolState.car
  user: UserProps
}) => {
  const { vehicleDetails, whenPurchased, whichDealer, howMuch, whatsWrong, likeToDo } = car
  const { vehicleReg, vehicleMake, vehicleModel } = vehicleDetails

  const { firstName, lastName } = user

  const likeToDoFormatted = likeToDo === 'replace' ? 'replacement' : likeToDo

  let body = [
    { text: `Dear ${whichDealer},<br />\r\n` },
    {
      text: `I'm writing to you about the ${vehicleMake} ${vehicleModel} (${vehicleReg}) I bought from you for £${howMuch} on ${constructDate(
        new Date(whenPurchased)
      )}.<br />\r\n`,
    },
    { text: `The vehicle has ${whatsWrong}.<br />\r\n` },
    {
      text: `The Consumer Rights Act 2015 makes it an implied term of the contract I have with ${whichDealer} that goods be as described, fit for purpose and of satisfactory quality.<br />\r\n`,
    },
    {
      text: `As you are in breach of contract and I've owned the vehicle for less than 6 years, I am within my statutory rights to ask for a ${likeToDoFormatted} at no further cost to me.<br />\r\n`,
    },
    {
      text: 'I look forward to hearing from you within 14 days with details of how you plan to resolve this matter.<br />\r\n',
    },
    { text: 'Yours sincerely,<br />\r\n' },
    { text: `${firstName} ${lastName}` },
  ]

  body = body.filter(String)

  return {
    label: 'Preview of complaint email',
    body,
  }
}
