import type { FunctionComponent } from 'react'
import React from 'react'
import { Typography } from '@which/seatbelt'

import classNames from 'classnames'

import globalStyles from '../../../shared/styles/GlobalStyles.module.scss'
import { PageWithText } from '../../components/PageWithText'
import type { TextInfoBoxPageProps } from '../../types/toolTypes'
import { RenderInfoBox } from '../../utils/renderInfoBox'

export const TextInfoBoxPage: FunctionComponent<TextInfoBoxPageProps> = ({
  bodyCopy,
  handleSubmit,
  infoBoxData,
  linkData,
  standFirst,
  title,
}) => {
  return (
    <div data-testid="text-infobox-page">
      <PageWithText bodyCopy={bodyCopy} standFirst={standFirst} title={title}>
        <Typography className={classNames(globalStyles.maxWidth)}>
          {linkData && (
            <>
              {linkData.text && `${linkData.text} `}
              <a
                className="sb-link-primary"
                data-testid="textInfoBox-link"
                href={linkData.href}
                {...(handleSubmit && { onClick: handleSubmit })}
              >
                <span className="sb-link-animation-wrapper">{linkData.linkText}</span>
              </a>
            </>
          )}
        </Typography>
      </PageWithText>
      <RenderInfoBox data={infoBoxData} />
    </div>
  )
}
