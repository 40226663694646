import type { FunctionComponent } from 'react'
import { useLayoutEffect } from 'react'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'

import { ToolWrapper } from '../../shared'
import { PageFade } from '../../shared/components/PageFade'
import { ProgressBar } from '../../shared/components/ProgressBar'
import { initialToolState } from '../../shared/state'
import { ToolContext } from '../../shared/state/appContext'
import globalStyles from '../../shared/styles/GlobalStyles.module.scss'
import type { MyMoneyHealthCheckToolProps } from '../../shared/types/toolTypes'
import { checkSessionStorageExists } from '../../shared/utils/checkSessionStorageExists'
import { formatToolName } from '../../shared/utils/formatToolName'
import { getFilteredPageDataByStep } from '../../shared/utils/getFilteredPageDataByStep'
import { getFilteredPageDataByView } from '../../shared/utils/getFilteredPageDataByView'
import { getSessionStorageItem } from '../../shared/utils/getSessionStorageItem'
import { handleScroll } from '../../shared/utils/handleScroll'
import { setUserJourney } from '../../shared/utils/setUserJourney'
import { storeToolState } from '../../shared/utils/storeToolState'
import {
  generalDataLayer,
  initaliseDataLayer,
  pageViewDataLayer,
} from '../../shared/utils/tracking'
import { useRenderStep } from './hooks/useRenderStep'
import {
  AllFlows,
  FinanceSituationFlow,
  myMoneyHealthCheckIntroFlow,
  ResultsPageFlow,
} from './pageFlowData'
import { myMoneyHealthCheckToolState } from './state/myMoneyHealthCheckToolState'

export const MyMoneyHealthCheck: FunctionComponent<MyMoneyHealthCheckToolProps> = ({
  optionsJson,
  toolName,
  CRFooter,
  showFeedback,
  title,
  questionText,
  standfirst,
  buttonText,
  toolUrl,
  moneyHealthResults,
}) => {
  const { state, dispatch } = useContext(ToolContext)
  const [readyToRender, setReadyToRender] = useState(false)
  const {
    initial: {
      entryToolName,
      initialised,
      journeyFlow: { milestoneHit },
      progressValue,
      step,
      userJourney,
    },
    myMoneyHealthCheck: { financeSituation, landingPageCompleted },
  } = state
  const historyPending = useRef(false)
  const history = useHistory()

  useEffect(() => {
    if (!entryToolName) {
      dispatch({ type: 'UPDATE_ENTRY_TOOL_NAME', data: toolName })
    }
  }, [dispatch, entryToolName, toolName])

  const isResultPage = step === ResultsPageFlow.STEP

  let shouldNotShowLandingPage: string | null | undefined = undefined
  const location = useLocation()
  const useQuery = () => {
    return new URLSearchParams(location?.search)
  }
  const query = useQuery()
  shouldNotShowLandingPage = query?.get('type') || landingPageCompleted
  const stepToRender = shouldNotShowLandingPage ? step : 0

  const getSearchParams = useCallback(() => {
    return history.location.search
  }, [history.location.search])

  useEffect(() => {
    if ((shouldNotShowLandingPage === null || !shouldNotShowLandingPage) && step !== 0) {
      dispatch({ type: 'UPDATE_STEP', data: 0 })
    }
  }, [dispatch, shouldNotShowLandingPage, step])

  useEffect(() => {
    if (!initialised) {
      dispatch({ type: 'UPDATE_SHOW_FEEDBACK', data: showFeedback })
    }
  }, [dispatch, initialised, showFeedback])

  useEffect(() => {
    handleScroll()
  }, [step])

  useEffect(() => {
    dispatch({ type: 'UPDATE_INITIALISED', data: true })
  }, [dispatch])

  useLayoutEffect(() => {
    setUserJourney(userJourney, stepToRender, dispatch)
  }, [dispatch, stepToRender, userJourney])

  useEffect(() => {
    if (!entryToolName) {
      dispatch({ type: 'UPDATE_ENTRY_TOOL_NAME', data: toolName })
    }
  }, [dispatch, entryToolName, toolName])

  useEffect(() => {
    const firePageView = () => {
      const pageData = getFilteredPageDataByStep(step, AllFlows, [FinanceSituationFlow])

      let { milestone } = pageData
      const { viewName } = pageData
      if (initialised === true) {
        const previousViewNameEvent = getSessionStorageItem(`${toolName}ViewName`)

        if (viewName !== previousViewNameEvent) {
          pageViewDataLayer(viewName)
          sessionStorage.setItem(`${toolName}ViewName`, JSON.stringify(viewName))
        }

        const milestoneArray = milestoneHit

        if (!milestoneArray.includes(viewName) && milestone !== '') {
          milestoneArray.push(viewName)
          if (step === ResultsPageFlow.STEP) {
            milestone = `${milestone} | ${financeSituation}`
          }
          generalDataLayer(formatToolName(toolName), milestone)
          dispatch({ type: 'UPDATE_MILESTONE', data: milestoneArray })
          return
        }
      }
    }

    firePageView()
  }, [dispatch, financeSituation, initialised, milestoneHit, step, toolName])

  /*
    TODO: come up with a better way of doing this.
    Journey path probably won't work for every tool
  */
  const isLocationCorrect = useCallback((hashValue: string, journeyPath: string) => {
    switch (hashValue) {
      case 'intro':
        return journeyPath === '[0]'
      case 'finance-situation':
        return journeyPath === '[0,1]'
      case 'property-payment':
        return journeyPath === '[0,1,2]'
      case 'benefits':
        return journeyPath === '[0,1,2,3]'
      case 'energy-payment':
        return journeyPath === '[0,1,2,3,4]'
      case 'food-shop':
        return journeyPath === '[0,1,2,3,4,5]'
      case 'broadband-contract':
        return journeyPath === '[0,1,2,3,4,5,6]'
      case 'paying-debts':
        return journeyPath === '[0,1,2,3,4,5,6,7]'
      case 'journey-complete':
        return journeyPath === '[0,1,2,3,4,5,6,7,8]'
    }
  }, [])

  const handleHistory = useCallback(
    ({ historyType, toolState, tool, viewName }) => {
      const { progressValue: progressWidth } = toolState.initial
      storeToolState(`${tool}ToolState`, toolState)

      if (checkSessionStorageExists(`${tool}Progress`)) {
        const sessionProgress = getSessionStorageItem(`${tool}Progress`)
        const sessionProgressLength = sessionProgress.length - 1
        const last2Array = [sessionProgress[sessionProgressLength]]
        last2Array.push(progressWidth)
        storeToolState(`${tool}Progress`, last2Array)
      } else {
        storeToolState(`${tool}Progress`, [progressWidth])
      }

      switch (historyType) {
        case 'replace':
          history.replace(viewName, toolState)
          break
        case 'push':
          history.push(viewName, toolState)
          break
      }
    },
    [history]
  )

  // First load initial history setup
  useEffect(() => {
    if (entryToolName !== '') {
      const pageData = getFilteredPageDataByView(history.location.hash.replace('#', ''), AllFlows)
      if (history.location.hash === '' || pageData.length === 0) {
        // Prevents other history actions until this history action has fired
        historyPending.current = true
        const stepData = getFilteredPageDataByStep(stepToRender, AllFlows, [FinanceSituationFlow])

        handleHistory({
          historyType: 'replace',
          toolState: { ...state, initial: { ...state.initial, step: stepToRender } },
          tool: entryToolName,
          viewName: `${getSearchParams()}#${stepData.viewName}`,
        })
      }
    }
  }, [
    entryToolName,
    getSearchParams,
    handleHistory,
    history,
    isLocationCorrect,
    state,
    step,
    stepToRender,
  ])

  // Clear everything when invalid hash
  useEffect(() => {
    if (entryToolName !== '') {
      const correctLocation = isLocationCorrect(
        history.location.hash.replace('#', ''),
        JSON.stringify(userJourney)
      )
      const historyLocationState = history.location.state as { initial: { userJourney: number[] } }

      if (
        (!correctLocation && historyLocationState?.initial.userJourney === userJourney) ||
        historyLocationState === undefined
      ) {
        // Prevents other history actions until this history action has fired
        historyPending.current = true

        handleHistory({
          historyType: 'replace',
          toolState: {
            ...initialToolState,
            initial: { ...initialToolState.initial, step: 0 },
            ...myMoneyHealthCheckToolState,
          },
          tool: entryToolName,
          viewName: `${getSearchParams()}#${myMoneyHealthCheckIntroFlow.VIEW_NAME}`,
        })
      }
    }
  }, [
    entryToolName,
    getSearchParams,
    handleHistory,
    history,
    isLocationCorrect,
    state,
    step,
    userJourney,
  ])

  // Browser Refresh
  useEffect(() => {
    if (entryToolName !== '') {
      const historyLocationState = history.location.state as { initial: { userJourney: number[] } }
      if (
        userJourney.length === historyLocationState?.initial.userJourney.length &&
        history.action === 'POP'
      ) {
        const stepData = getFilteredPageDataByStep(step, AllFlows, [FinanceSituationFlow])
        handleHistory({
          historyType: 'replace',
          toolState: { ...state },
          tool: entryToolName,
          viewName: `${getSearchParams()}#${stepData.viewName}`,
        })
      }
    }
  })

  // Browser Back & Forward
  useEffect(() => {
    if (entryToolName !== '') {
      const historyLocationState = history.location.state as {
        initial: { step: number; userJourney: number[] }
      }
      const { step: historyLocationStep } = historyLocationState.initial
      if (
        history.location.state !== undefined &&
        history.location.hash !== '' &&
        userJourney.length !== historyLocationState.initial.userJourney.length &&
        history.action === 'POP' &&
        historyLocationStep !== step
      ) {
        localStorage.removeItem('MMHC')
        // Prevents instantly going forward on back click before state has updated.
        historyPending.current = true
        const stepData = getFilteredPageDataByStep(historyLocationStep, AllFlows, [
          FinanceSituationFlow,
        ])

        handleHistory({
          historyType: 'replace',
          toolState: { ...historyLocationState },
          tool: entryToolName,
          viewName: `${getSearchParams()}#${stepData.viewName}`,
        })
      }
    }
  }, [
    entryToolName,
    getSearchParams,
    handleHistory,
    history,
    history.action,
    history.location.hash,
    history.location.state,
    state.step,
    step,
    userJourney.length,
  ])

  // Push forward journey
  useEffect(() => {
    if (entryToolName !== '') {
      const historyLocationState = history.location.state as {
        initial: { userJourney: number[]; step: number }
      }
      if (userJourney.length > historyLocationState?.initial.userJourney.length) {
        const { step: historyLocationStep } = historyLocationState.initial
        if (historyLocationStep !== step && !historyPending.current) {
          const stepData = getFilteredPageDataByStep(step, AllFlows, [myMoneyHealthCheckIntroFlow])

          handleHistory({
            historyType: 'push',
            toolState: { ...state },
            tool: entryToolName,
            viewName: `${getSearchParams()}#${stepData.viewName}`,
          })
        }
      }
    }
  }, [
    entryToolName,
    getSearchParams,
    handleHistory,
    history,
    historyPending,
    isLocationCorrect,
    state,
    step,
    userJourney,
    userJourney.length,
  ])

  useEffect(() => {
    if (!((shouldNotShowLandingPage === null || !shouldNotShowLandingPage) && step !== 0)) {
      setUserJourney(userJourney, step, dispatch)
    }
  }, [userJourney, step, dispatch, shouldNotShowLandingPage])

  useEffect(() => {
    if (shouldNotShowLandingPage !== undefined) {
      setReadyToRender(true)
    }
  }, [shouldNotShowLandingPage])

  const RenderStep = ({ renderStep }: { renderStep: number }) => {
    return useRenderStep({
      step: renderStep,
      optionsJson,
      title,
      questionText,
      standfirst,
      buttonText,
      toolUrl,
      CRFooter,
      moneyHealthResults,
    })
  }

  return (
    <>
      <ToolWrapper {...(isResultPage && { width: 'full', noBottomPadding: true })}>
        <Helmet>
          <script>{initaliseDataLayer(formatToolName(toolName))}</script>
          <title>My Money Health Check - free Which? tool</title>
          <meta content="My Money Health Check - free Which? tool" name="title" />
          <meta
            content="Answer a few simple questions about your personal spending habits and our free tool will show you where you can cut your costs"
            name="description"
          />

          <meta content="website" property="og:type" />
          <meta content="https://www.which.co.uk/tool/my-money-health-check" property="og:url" />
          <meta content="My Money Health Check - free Which? tool" property="og:title" />
          <meta
            content="Answer a few simple questions about your personal spending habits and our free tool will show you where you can cut your costs"
            property="og:description"
          />
          <meta
            content="https://media.product.which.co.uk/prod/images/original/58bbe1afd636-social.jpg"
            property="og:image"
          />

          <meta content="summary_large_image" property="twitter:card" />
          <meta
            content="https://www.which.co.uk/tool/my-money-health-check"
            property="twitter:url"
          />
          <meta content="My Money Health Check - free Which? tool" property="twitter:title" />
          <meta
            content="Answer a few simple questions about your personal spending habits and our free tool will show you where you can cut your costs"
            property="twitter:description"
          />
          <meta
            content="https://media.product.which.co.uk/prod/images/original/58bbe1afd636-social.jpg"
            property="twitter:image"
          />
        </Helmet>
        {readyToRender && (
          <>
            {step !== 0 && (
              <div className={globalStyles.noPrint}>
                <ProgressBar
                  allFlows={AllFlows}
                  endFlowStep={ResultsPageFlow.STEP}
                  startFlowStep={0}
                  stateWidth={progressValue}
                  toolName={toolName}
                />
              </div>
            )}
            <PageFade
              excludedSteps={[0, 1]}
              fullWidth={isResultPage}
              stateProgress={progressValue}
              step={stepToRender}
              toolName={entryToolName}
            >
              <RenderStep renderStep={step} />
            </PageFade>
          </>
        )}
      </ToolWrapper>
    </>
  )
}
