import { environment } from '../../../../shared/utils/fetchEnv/env'
import type { salesforceStateType } from './salesforceMapper'
import { salesforceMapper } from './salesforceMapper'

export const fetchIframe = async (state: salesforceStateType, type: string) => {
  if (!state) {
    throw new Error('Incorrect data passed in')
  }

  const mapUrl = () => {
    switch (type) {
      case 'legal':
        return 'legal-appointment'
      case 'tech':
        return 'tech-support-appointment'
      case 'money':
        return 'money-appointment'
    }
  }

  const body = salesforceMapper(state)
  const iFrameApi = fetch(
    `https://${environment()}-appointment-booking-case-service.api.product.which.co.uk/case-service/${mapUrl()}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  )
    .then((responseData) => {
      if (responseData.ok) {
        return responseData.json()
      } else {
        return { error: 'Sorry, something went wrong' }
      }
    })
    .catch((err) => {
      return err
    })
  const iFrameData = await iFrameApi
  return iFrameData
}
