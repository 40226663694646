import styles from '../../../../../shared/utils/renderInfoBox/renderInfoBox.module.scss'
import type { InfoBoxProps } from './../../../../../shared/types/toolTypes'

export const onFinanceData = {
  onFinanceButtons: [
    {
      label: 'Credit card',
      id: 'creditCard',
    },
    {
      label: 'Linked credit agreement',
      id: 'linkedCreditAgreement',
    },
    {
      label: 'Debit card',
      id: 'debitCard',
    },
    {
      label: 'Charge card',
      id: 'chargeCard',
    },
  ],
  title: 'How did you make your purchase?',
  infoBox: {
    heading: 'Methods of payment',
    borderColour: 'blue',
  } as InfoBoxProps,
  infoBoxParagraphs: [
    {
      classname: `${styles.renderInfoBoxParagraphs}`,
      text: 'If you paid partly using your credit card (even if it was only a few £s) please select Credit Card. This makes you eligible for an S75 claim.',
    },
    {
      classname: `${styles.renderInfoBoxParagraphs}`,
      text: "What is a 'linked credit agreement'? This is a loan granted via the seller for specific goods/services. A linked credit agreement often refers to things such as loans from fitters/manufacturers for kitchens or for building work.",
    },
    {
      text: 'What is a charge card? Similar to a credit card, a charge card allows you to buy items and pay later, but, unlike with a credit card, the balance of a charge card must be paid off in full at the end of each month. Typically charge cards do not have credit limits, also.',
    },
  ],
  imgObj: {
    alt: 'An elderly couple talking to a person in a blue suit',
    src: 'https://media.product.which.co.uk/prod/images/original/gm-be27c20e-2c61-47a2-96bd-3060c50cf348-purchasemethod.jpg',
  },
}
