export const articleCTAData = {
  headline:
    'Dealing with a financial complaint can be a tricky and confusing business. Here we talk you through taking your financial complaint to the Financial Ombudsman Service.',
  image: {
    alt: 'woman and man using phone',
    src: 'https://media.product.which.co.uk/prod/images/original/gm-6d3d325a-2c3e-4755-bc54-4b4c6881c244-articlectaimage.png',
  },
  link: {
    href: 'https://www.which.co.uk/consumer-rights/advice/when-to-take-a-complaint-to-the-ombudsman-aQjKG8x2kXiT',
    text: '',
  },
  title: 'When to take a complaint to the ombudsman',
}
