import type { Reducer } from 'react'

import { initialSliceReducer, toolFeedbackSliceReducer } from '../../../shared/state/reducers'
import type { Action, State } from '../../../shared/state/toolStateTypes'
import { combineReducers } from '../../../shared/utils/combineReducers'

export const workSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_COMPLAINT':
      return {
        ...state,
        complaint: data,
      }

    case 'UPDATE_TRADER':
      return {
        ...state,
        trader: data,
      }

    case 'UPDATE_JOB':
      return {
        ...state,
        job: data,
      }

    case 'UPDATE_WORK':
      return {
        ...state,
        work: data,
      }

    case 'UPDATE_DATE':
      return {
        ...state,
        date: data,
      }

    case 'UPDATE_NAME':
      return {
        ...state,
        name: data,
      }

    case 'UPDATE_NUMBER':
      return {
        ...state,
        number: data,
      }

    case 'UPDATE_PROBLEM':
      return {
        ...state,
        problem: data,
      }

    case 'UPDATE_CARD':
      return {
        ...state,
        card: data,
      }

    default:
      return state
  }
}

export const homeImprovementReducer = combineReducers({
  initial: initialSliceReducer,
  work: workSliceReducer,

  toolFeedback: toolFeedbackSliceReducer,
})
