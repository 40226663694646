export const otherTopicData = {
  buttons: [
    {
      label: 'A family law matter - divorce and separation, child custody etc',
      id: 'family-law',
    },
    {
      label: 'You have been charged with a criminal offence (not related to motoring)',
      id: 'criminal-offence',
    },
    {
      label: 'Immigration applications/visa status',
      id: 'immigration-visas',
    },
    {
      label: 'A query in relation to your business',
      id: 'query-related-business',
    },
    {
      label: 'Other',
      id: 'other',
    },
  ],
  title: 'Please select which of the following is the closest match to your query?',
  imgObj: {
    alt: 'two people holding documents',
    src: 'https://media.product.which.co.uk/prod/images/original/8f95d1a7f0dc-cp-step9.jpg',
  },
}
