export const hoursDifferenceData = {
  scenarioA: {
    question: 'How late were you arriving at your destination airport?',
    imgObj: {
      alt: 'Two women sitting on luggage',
      src: 'https://media.product.which.co.uk/prod/images/original/4c1aa7a3cdf8-flight-tool-a.jpg',
    },
    selectData: [
      {
        id: 'select-1',
        options: [
          {
            label: 'Please select an option',
            value: '',
          },
          {
            label: '1 hour',
            value: '1-hour-late-arriving',
          },
          {
            label: '2 hours',
            value: '2-hours-late-arriving',
          },
          {
            label: '3 hours',
            value: '3-hours-late-arriving',
          },
          {
            label: '4 or more hours',
            value: '4-or-more-hours-late-arriving',
          },
        ],
      },
    ],
  },
  scenarioB: {
    question: 'How much earlier did you leave than your scheduled departure time?',
    imgObj: {
      alt: 'A woman standing next to her luggage',
      src: 'https://media.product.which.co.uk/prod/images/original/ecd7ac09aedf-flight-tool-b.jpg',
    },
    selectData: [
      {
        id: 'select-1',
        options: [
          {
            label: 'Please select an option',
            value: '',
          },
          {
            label: 'Less than 1 hour',
            value: 'less-than-1-hour-earlier-leaving',
          },
          {
            label: 'More than 1 hour',
            value: 'more-than-1-hour-earlier-leaving',
          },
        ],
      },
    ],
  },
  scenarioC: {
    question:
      'How much earlier did you leave than your scheduled departure time and how late were you arriving at your destination airport?',
    imgObj: {
      alt: 'Two people laying around waiting with their luggage',
      src: 'https://media.product.which.co.uk/prod/images/original/55d5a5084e7f-flight-tool-c.jpg',
    },
    selectData: [
      {
        id: 'select-1',
        selectLabel: 'Earlier departure',
        options: [
          {
            label: 'Please select an option',
            value: '',
          },
          {
            label: 'Less than 1 hour',
            value: 'less-than-1-hour-earlier-leaving',
          },
          {
            label: 'More than 1 hour',
            value: 'more-than-1-hour-earlier-leaving',
          },
        ],
      },
      {
        id: 'select-2',
        selectLabel: 'Later arrival',
        options: [
          {
            label: 'Please select an option',
            value: '',
          },
          {
            label: '1 hour',
            value: '1-hour-late-arriving',
          },
          {
            label: '2 hours',
            value: '2-hours-late-arriving',
          },
          {
            label: '3 hours',
            value: '3-hours-late-arriving',
          },
          {
            label: '4 or more hours',
            value: '4-or-more-hours-late-arriving',
          },
        ],
      },
    ],
  },
  scenarioD: {
    question: 'How much earlier did you leave than your scheduled departure time?',
    imgObj: {
      alt: 'A woman standing next to her luggage',
      src: 'https://media.product.which.co.uk/prod/images/original/ecd7ac09aedf-flight-tool-b.jpg',
    },
    selectData: [
      {
        id: 'select-1',
        options: [
          {
            label: 'Please select an option',
            value: '',
          },
          {
            label: 'Less than 2 hours',
            value: 'less-than-2-hours-earlier-leaving',
          },
          {
            label: '2 or more hours',
            value: '2-or-more-hours-earlier-leaving',
          },
        ],
      },
    ],
  },
  scenarioE: {
    question:
      'How much earlier did you leave than your scheduled departure time and how late were you arriving at your destination airport?',
    imgObj: {
      alt: 'Two people laying around waiting with their luggage',
      src: 'https://media.product.which.co.uk/prod/images/original/55d5a5084e7f-flight-tool-c.jpg',
    },
    selectData: [
      {
        id: 'select-1',
        selectLabel: 'Earlier departure',
        options: [
          {
            label: 'Please select an option',
            value: '',
          },
          {
            label: 'Less than 2 hours',
            value: 'less-than-2-hours-earlier-leaving',
          },
          {
            label: '2 or more hours',
            value: '2-or-more-hours-earlier-leaving',
          },
        ],
      },
      {
        id: 'select-2',
        selectLabel: 'Later arrival',
        options: [
          {
            label: 'Please select an option',
            value: '',
          },
          {
            label: '1 hour',
            value: '1-hour-late-arriving',
          },
          {
            label: '2 hours',
            value: '2-hours-late-arriving',
          },
          {
            label: '3 hours',
            value: '3-hours-late-arriving',
          },
          {
            label: '4 or more hours',
            value: '4-or-more-hours-late-arriving',
          },
        ],
      },
    ],
  },
}
