export const limitedOptionsData = {
  title:
    "Unfortunately if you haven't paid a fee for your bank account, it's unlikely you've had a packaged bank account, or have been mis-sold one.",
  standFirst: '',
  bodyCopy: [
    "Packaged bank accounts are accounts packaged with extra features, such as travel insurance. They often have names that feature words like 'premium' or 'gold'. And there's also usually a monthly fee.",
    "If you haven't been mis-sold a packaged bank account, but are still unhappy with your account, see what bank accounts we've rated as the best.",
  ],
  link: {
    href: 'https://www.which.co.uk/money/banking/bank-accounts/best-bank-accounts/best-bank-accounts-if-you-always-stay-in-credit-adq9q6k42qbw',
    headline:
      "Not sure which current account would suit you best? We've made it easier for you by selecting the best high-interest current accounts",
    text: 'Best high interest bank accounts',
  },
  ctaData: {
    image: {
      src: 'https://media.product.which.co.uk/prod/images/original/gm-8b0968cf-b7d0-49e7-b5fd-ff38b3887a0d-limitedoptionsfaultygoods.png',
      alt: 'Two people looking at their phones facing away from each other.',
    },
    author: { name: 'Which?', jobTitle: 'Editorial team' },
  },
}
