import { constructDate } from '../../../../shared/components/DatePicker/formatDate/constructDate'
import type { UserPropsWithAddress } from '../../../../shared/types/toolTypes'
import type { flightDelayCancellationState } from '../../state/flightDelayCancellationState'

export const composeEmailBody = ({
  flight,
  claim,
  user,
  autoEmailer,
}: {
  flight: typeof flightDelayCancellationState.flight
  claim: typeof flightDelayCancellationState.claim
  user: UserPropsWithAddress
  autoEmailer?: boolean
}) => {
  const { airline, distance, delayedCancelled, date, airports, scheduledTimes, flightNumber } =
    flight

  const {
    reasonGiven,
    amountEntitled,
    hoursDifference,
    earlierLaterOriginalFlight,
    numPeopleClaimingFor,
  } = claim

  const { firstName, lastName, address } = user

  const departureHours = scheduledTimes.departureTime.hours.replace('HH:', '')
  const departureMins = scheduledTimes.departureTime.minutes.replace('MM:', '')
  const arrivalHours = scheduledTimes.arrivalTime.hours.replace('HH:', '')
  const arrivalMins = scheduledTimes.arrivalTime.minutes.replace('MM:', '')
  const issueText = delayedCancelled ? delayedCancelled.replace('flight-', '') : ''

  const indexOfDeprtureComma = airports.departureAirport.name.indexOf(',')
  const friendlyDepartureName =
    indexOfDeprtureComma > -1
      ? airports.departureAirport.name.substring(0, indexOfDeprtureComma)
      : airports.departureAirport.name

  const indexOfArrivalComma = airports.arrivalAirport.name.indexOf(',')
  const friendlyArrivalName =
    indexOfArrivalComma > -1
      ? airports.arrivalAirport.name.substring(0, indexOfArrivalComma)
      : airports.arrivalAirport.name

  let hoursVal1Text: string
  let hoursVal2Text: string

  switch (hoursDifference.value1) {
    case 'less-than-1-hour-earlier-leaving':
      hoursVal1Text = '1 hour earlier'
      break
    case 'more-than-1-hour-earlier-leaving':
      hoursVal1Text = 'over 1 hour earlier'
      break
    case 'less-than-2-hours-earlier-leaving':
      hoursVal1Text = '2 hours earlier'
      break
    case '2-hours-late-arriving':
      hoursVal1Text = '2 hours later'
      break
    case '3-hours-late-arriving':
      hoursVal1Text = '3 hours later'
      break
    case '4-or-more-hours-late-arriving':
      hoursVal1Text = 'more than 4 hours later'
      break
    default:
      hoursVal1Text = ''
  }

  switch (hoursDifference.value2) {
    case '1-hour-late-arriving':
      hoursVal2Text = '1 hour later'
      break
    case '2-hours-late-arriving':
      hoursVal2Text = '2 hours later'
      break
    case '3-hours-late-arriving':
      hoursVal2Text = '3 hours later'
      break
    case '4-or-more-hours-late-arriving':
      hoursVal2Text = 'more than 4 hours later'
      break
    default:
      hoursVal2Text = ''
  }

  const getEarlierLaterOriginalFlightText = () => {
    switch (true) {
      case earlierLaterOriginalFlight === 'earlier':
        return `I left my original departure airport ${hoursVal1Text} than scheduled.`
      case earlierLaterOriginalFlight === 'later':
        return `I arrived at my arrival airport ${hoursVal1Text} than scheduled.`
      case earlierLaterOriginalFlight === 'earlier-arrived-later':
        return `I left my original departure airport ${hoursVal1Text} than originally scheduled and I arrived at my arrival airport ${hoursVal2Text} than scheduled.`
      default:
        return ''
    }
  }

  const getIssue = () => {
    switch (true) {
      case delayedCancelled === 'flight-cancelled':
        return `The flight was supposed to depart from ${friendlyDepartureName} at ${departureHours}:${departureMins} and arrive at ${friendlyArrivalName} at ${arrivalHours}:${arrivalMins}, but it was cancelled and I was not offered a replacement flight.`
      case delayedCancelled === 'flight-rerouted':
        return `The flight was supposed to depart from ${friendlyDepartureName} at ${departureHours}:${departureMins} and arrive at ${friendlyArrivalName} at ${arrivalHours}:${arrivalMins}, but it was ${issueText} and ${earlierLaterOriginalFlightText}`
      case delayedCancelled === 'flight-delayed':
        return `The flight was supposed to depart from ${friendlyDepartureName} at ${departureHours}:${departureMins} and arrive at ${friendlyArrivalName} at ${arrivalHours}:${arrivalMins}, but it was ${issueText} and I arrived at ${friendlyArrivalName} ${hoursVal1Text} than scheduled.`
      default:
        return ''
    }
  }

  const earlierLaterOriginalFlightText = getEarlierLaterOriginalFlightText()
  const issue = getIssue()

  const numPeople = numPeopleClaimingFor ? numPeopleClaimingFor : 0

  const consumerActTextExtended =
    numPeople && numPeople > 1
      ? `I am claiming compensation on behalf of the ${numPeople} of passengers who were part of my booking.`
      : ''
  const consumerAct = `In accordance with your obligations under the EU Denied Boarding Regulation 261/2004 and subsequent rulings by European Court of Justice, I am entitled to the sum of ${amountEntitled} in compensation per passenger, based on a flight distance of ${distance}km. ${consumerActTextExtended}`

  const reasonGivenByAirline = reasonGiven ? `${reasonGiven} <br />\r\n` : ''

  let addressArr = [
    { text: `${address?.addressLine1}` },
    {
      text: `${address?.addressLine2 ? `${address.addressLine2}` : ''}`,
    },
    { text: `${address?.townCity}` },
    { text: `${address?.county ? `${address.county}` : ''}` },
    { text: `${address?.postcode}<br />\r\n` },
  ]

  let body = [
    { text: 'Dear Sir/Madam,<br />\r\n' },
    {
      text: `Reference: ${airline} / ${flightNumber} / ${constructDate(new Date(date))} <br />\r\n`,
    },
    {
      text: 'I am writing to you in connection with the above flight.<br />\r\n',
    },
    { text: `${issue}<br />\r\n` },
    { text: `${reasonGivenByAirline}` },
    { text: `${consumerAct}<br />\r\n` },
    {
      text: 'I look forward to receiving the compensation within the next 21 days from the date of this letter.<br />\r\n',
    },
    { text: 'I include a copy of my tickets.<br />\r\n' },
    { text: 'Yours faithfully,<br />\r\n' },
    { text: `${firstName} ${lastName}` },
  ]

  addressArr = addressArr.filter(String)
  body = autoEmailer ? addressArr.concat(body) : body
  body = body.filter(String)

  return {
    label: 'Preview of complaint email',
    address: addressArr,
    body: body,
  }
}
