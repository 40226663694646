import React, { createContext, useReducer } from 'react'

import { initialToolState } from './initialToolState'
import type { StateContextType, toolProviderProps } from './toolStateTypes'

export const ToolContext = createContext<StateContextType>({
  state: initialToolState,
  dispatch: (x) => x,
})

export const ToolProvider = ({ children, toolState, toolRootReducer }: toolProviderProps) => {
  const combinedInitialState = { ...initialToolState, ...toolState }

  const [state, dispatch] = useReducer(toolRootReducer, combinedInitialState)
  const storeMemo = React.useMemo(() => [state, dispatch], [state])
  const store = { state: storeMemo[0], dispatch: storeMemo[1] } as StateContextType

  return <ToolContext.Provider value={store}>{children}</ToolContext.Provider>
}
