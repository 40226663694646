import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { FormEntryPage } from '../../../../shared/pages/FormEntryPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryInputRef, SingleInputState } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { ClaimValueFlow, WhatHappenedFlow, WherePurchasedFlow } from '../../pageFlowData'
import { data } from './data'

export const WhichRetailer: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { journeyFlow, entryToolName },
    retailer: { name },
    product: { type },
  } = state

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })
  const whichRetailerInput = useRef<FormEntryInputRef>(null)

  const getValue = () => {
    return cleanText(whichRetailerInput?.current?.formEntryInput?.value || '')
  }

  const handleStepChoice = () => {
    if (journeyFlow.branch === 's75') {
      return { progress: ClaimValueFlow.PROGRESS_VALUE, step: ClaimValueFlow.STEP }
    }

    return { progress: WhatHappenedFlow.PROGRESS_VALUE, step: WhatHappenedFlow.STEP }
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${WherePurchasedFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    const { progress, step } = handleStepChoice()
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_RETAILER_NAME', data: value })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleSubmit = () => {
    const value = getValue()
    const whichRetailerHasError = !isNotEmpty(value)

    setInputData({
      renderError: whichRetailerHasError,
      value: value,
    })

    if (!whichRetailerHasError) {
      handleTracking(value)
      handleDispatch(value)
    }
  }

  const { productPlaceholder, placeholder, serviceImage, image, imgAlt, title } = data
  const placeholderValue = type === 'product' ? productPlaceholder : placeholder
  const { renderError, value: errorValue } = inputData

  return (
    <FormEntryPage
      buttonText="Next"
      defaultValue={name}
      fieldData={{
        renderError: renderError,
        value: errorValue,
      }}
      handleSubmit={handleSubmit}
      id="which-retailer"
      imgObj={{
        alt: imgAlt,
        src: type === 'service' ? serviceImage : image,
      }}
      isFocused={true}
      placeholderText={placeholderValue}
      ref={whichRetailerInput}
      testId="which-retailer"
      title={title}
    />
  )
}
