import type { UserPropsWithAddress } from './../../../shared/types/toolTypes'

export const pageData = (state: {
  emailData: { body: { text: string }[] }
  issue: {
    cardProvider: string
    claimValue: string
    description: string
    desiredResolution: string
    sellerContact: string
  }
  optIns: {
    singleConsentOptIn: boolean
    caseStudy: boolean
  }
  product: {
    description: string
    purchaseDate: string
    purchaseMethod: string
    price: string
    type: string
  }
  retailer: {
    name: string
    repeatPerformance: boolean
  }
  user: UserPropsWithAddress
}) => {
  const { cardProvider, claimValue, desiredResolution, sellerContact } = state.issue
  const { description, price, purchaseDate, purchaseMethod, type } = state.product
  const { name, repeatPerformance } = state.retailer
  const { address, emailAddress, firstName, lastName } = state.user
  const { singleConsentOptIn, caseStudy } = state.optIns

  const { body } = state.emailData
  let emailBodyBuilder = ''
  body.forEach((bodyData) => {
    if (bodyData.text) {
      emailBodyBuilder = emailBodyBuilder + `${bodyData.text}<br />`
    }
  })

  const questions: any = {}
  // Questions for both product and service path
  questions['question.1095562'] = type // TF - What did you buy?
  questions['question.1163234'] = purchaseMethod // TF - How did you make your purchase?
  questions['question.1045605'] = price // TF - How much did you pay for it?
  questions['question.1163235'] = purchaseDate // TF - What date did you buy it?
  questions['question.1163237'] = cardProvider // TF - Who is your card provider?
  questions['question.1045608'] = description // TF - What is the item you bought?
  questions['question.1095766'] = name // TF - Which retailer did you buy the item from?
  questions['question.1163238'] = claimValue // TF - How much are you claiming?
  questions['question.1045610'] = state.issue.description // TF - Tell us more about the problem
  questions['question.1163239'] = sellerContact // TF - Seller Contact Description
  questions['question.1163244'] = emailBodyBuilder // TF - Composed Letter
  questions['question.1184531'] = 'Y' // TF - Impact survey opt-in
  questions['question.1592773'] = caseStudy // TF - S75 - case study opt-in

  // product only questions
  if (type === 'product') {
    questions['question.1045622'] = desiredResolution // TF - What do you want the seller to do?
  } else {
    // service only questions
    questions['question.1163236'] = repeatPerformance // TF - Asked repeat performance
  }

  questions['question.1598463'] = singleConsentOptIn ? 'Y' : 'N' // TF - Single Consent opt-in

  // return supporter
  return {
    supporter: {
      Address: address?.addressLine1,
      'Address 2': address?.addressLine2,
      County: address?.county,
      Email: emailAddress,
      'First name': firstName,
      'Last name': lastName,
      Postcode: address?.postcode,
      'Town or City': address?.townCity,
      questions,
    },
  }
}
