import type { FunctionComponent, ReactElement } from 'react'
import React from 'react'
import { Typography } from '@which/seatbelt'

import { ImageWithQuestion } from '../../components/ImageWithQuestion'
import { NextButton } from '../../components/NextButton'
import { SingleRadioGroup } from '../../components/SingleRadioGroup'
import type { RadioGroupPageProps } from '../../types/toolTypes'
import styles from './RadioGroupPage.module.scss'

export const RadioGroupPage: FunctionComponent<RadioGroupPageProps> = ({
  buttons,
  buttonText,
  handleChange,
  handleSubmit,
  imgObj,
  isInputValid,
  stateValue,
  title,
  standFirst,
  fullWidthContainer = false,
}) => {
  const renderStandfirst = (): null | ReactElement => {
    if (!standFirst || standFirst === '') {
      return null
    }

    return (
      <Typography className={styles.radioGroupPageStandFirst} tag="p" textStyle="body">
        {standFirst}
      </Typography>
    )
  }

  return (
    <div className={styles.radioGroupPage} data-testid="radio-group-page">
      <ImageWithQuestion image={imgObj} title={title} />
      {renderStandfirst()}
      <SingleRadioGroup
        buttons={buttons}
        fullWidth={fullWidthContainer}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        isSubmitValid={isInputValid}
        stateValue={stateValue}
      />
      <NextButton buttonText={buttonText} onClick={handleSubmit} />
    </div>
  )
}
