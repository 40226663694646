export const data = {
  buttons: [
    {
      label: 'Yes',
      id: 'yes',
    },
    {
      label: 'No',
      id: 'no',
    },
  ],
  imgObj: {
    alt: 'A car next to a parking ticket machine',
    src: 'https://media.product.which.co.uk/prod/images/original/gm-f26e104d-fc6a-44b4-a73e-26457218e7ce-parking-tool-step-4.jpg',
  },
  title: 'Did you pay for parking?',
}
