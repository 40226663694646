import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { FormEntryPage } from '../../../../shared/pages/FormEntryPage'
import { TextAreaPage } from '../../../../shared/pages/TextAreaPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryTextAreaRef, SingleInputState } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { validateCharCount } from '../../../../shared/utils/validateCharCount'
import {
  BeenAffectedFlow,
  NameOfScammerFlow,
  TypeOfScammerFlow,
  WhatHappenedWithSocialFlow,
} from '../../pageFlowData'
import { scammerData } from './data'

export const TypeOfScammer: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    scam: { comeAcrossScam, typeOfScammer },
  } = state

  const [TypeOfScammerTextAreaValid, setTypeOfScammerTextAreaValid] = useState<boolean>(true)

  const displayInput =
    comeAcrossScam === 'social-media' || comeAcrossScam === 'came-to-house' ? true : false

  const [inputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })

  const TypeOfScammerTextAreaRef = useRef<FormEntryTextAreaRef>(null)

  const handleTypeOfScammerChange = () => {
    const value = getCleanValue(TypeOfScammerTextAreaRef.current?.formEntryTextarea?.value)
    dispatch({ type: 'UPDATE_TYPE_OF_SCAMMER', data: value })

    const isValid: boolean = validateCharCount(value.length, 2500)
    const fieldNotEmpty: boolean = isNotEmpty(value)
    if (fieldNotEmpty && isValid) {
      setTypeOfScammerTextAreaValid(true)
    }
  }

  const handleStepChoice = () => {
    if (comeAcrossScam === 'social-media') {
      return {
        progress: WhatHappenedWithSocialFlow.PROGRESS_VALUE,
        step: WhatHappenedWithSocialFlow.STEP,
      }
    } else if (comeAcrossScam === 'came-to-house') {
      return { progress: BeenAffectedFlow.PROGRESS_VALUE, step: BeenAffectedFlow.STEP }
    }

    return { progress: NameOfScammerFlow.PROGRESS_VALUE, step: NameOfScammerFlow.STEP }
  }

  const handleDispatch = () => {
    const { progress, step } = handleStepChoice()

    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${TypeOfScammerFlow.VIEW_NAME} | ${typeOfScammer}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = () => {
    const value: string = getCleanValue(TypeOfScammerTextAreaRef.current?.formEntryTextarea?.value)
    const isValid: boolean = validateCharCount(value.length, 2500)
    const fieldNotEmpty: boolean = isNotEmpty(value)

    if (fieldNotEmpty && !isValid) {
      setTypeOfScammerTextAreaValid(false)
      return
    }

    setTypeOfScammerTextAreaValid(true)
    handleTracking()
    handleDispatch()
  }

  const { telephone, online, house, social } = scammerData
  const chooseTitle = () => {
    switch (comeAcrossScam) {
      case 'telephone':
        return telephone
      case 'online':
        return online
      case 'came-to-house':
        return house
      case 'social-media':
        return social
      default:
        return telephone
    }
  }

  const { imgObj, title } = chooseTitle()
  const { renderError, value: errorValue } = inputData

  return (
    <div data-testid="type-of-scammer">
      {!displayInput && (
        <TextAreaPage
          buttonText="Next"
          defaultValue={typeOfScammer}
          handleChange={handleTypeOfScammerChange}
          handleSubmit={handleSubmit}
          imgObj={imgObj}
          isFocused
          isInputValid={TypeOfScammerTextAreaValid}
          maxChars={2500}
          optional
          ref={TypeOfScammerTextAreaRef}
          title={title}
        />
      )}

      {displayInput && (
        <FormEntryPage
          buttonText="Next"
          defaultValue={typeOfScammer}
          fieldData={{
            renderError: renderError,
            value: errorValue,
          }}
          handleSubmit={handleSubmit}
          id="type-of-scammer"
          imgObj={imgObj}
          isFocused
          optional={true}
          placeholderText=""
          ref={TypeOfScammerTextAreaRef}
          testId="type-of-scammer"
          title={title}
        />
      )}
    </div>
  )
}
