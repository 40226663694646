import { environment } from '../fetchEnv/env'
import { getBlaize } from '../getBlaize'

export type Entitlements = {
  tech: boolean
  money: boolean
  legal: {
    buying: boolean
    home: boolean
    holiday: boolean
    cars: boolean
    employment: boolean
    wills: boolean
    dataProtection: boolean
    medical: boolean
  }
}

const noEntitlements: Entitlements = {
  tech: false,
  money: false,
  legal: {
    buying: false,
    home: false,
    holiday: false,
    cars: false,
    employment: false,
    wills: false,
    dataProtection: false,
    medical: false,
  },
}

export const entitlementChecks = async (): Promise<Entitlements> => {
  const sessionId = getBlaize()

  try {
    const response = await fetch(
      `https://${environment()}-appointment-booking-entitlements-validator.api.product.which.co.uk/user-entitlements?sessionId=${sessionId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    if (!response.ok) {
      return noEntitlements
    }

    return response.json()
  } catch (error) {
    return noEntitlements
  }
}
