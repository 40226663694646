import type { Reducer } from 'react'

import { initialSliceReducer } from '../../../shared/state/reducers'
import type { Action, State } from '../../../shared/state/toolStateTypes'
import { combineReducers } from '../../../shared/utils/combineReducers'

export const energySliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  if (type === 'UPDATE_HOW_MUCH') {
    return {
      ...state,
      howMuch: data,
    }
  }

  if (type === 'UPDATE_MULTIPLIER') {
    return {
      ...state,
      multiplier: data,
    }
  }

  return state
}

export const energyCostCalculatorReducer = combineReducers({
  initial: initialSliceReducer,
  energy: energySliceReducer,
})
