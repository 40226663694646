export const flightDelayCancellationState = {
  flight: {
    ukOrEuAirline: null, // Was your flight with a UK or EU-based airline?
    arriveDepartUK: null, // Did your flight arrive or depart from a UK airport?
    airports: {
      // Which airports did you use?
      departureAirport: {
        name: '',
        geolocation: {
          lat: 0,
          lng: 0,
        },
      }, // Your departure airport
      arrivalAirport: {
        name: '',
        geolocation: {
          lat: 0,
          lng: 0,
        },
      }, // Your arrival airport
    },
    distance: null, // Distance of the flight ****
    delayedCancelled: '', // Was the flight delayed of cancelled - flight-delayed, flight-rerouted, flight-cancelled
    flightNumber: null, // What was your flight number?
    date: '', // What was your flight date?
    airline: null,
    scheduledTimes: {
      departureTime: { hours: '', minutes: '' }, // What was the flight's scheduled departure and arrival time?
      arrivalTime: { hours: '', minutes: '' }, // What was the flight's scheduled departure and arrival time?
    },
  },
  claim: {
    timeBeforeCancelled: null, // How long before your flight was due to depart were you told it was cancelled?
    earlierLaterOriginalFlight: null,
    hoursDifference: { value1: '', value2: '' }, // step 7 - multiple variants
    numPeopleClaimingFor: null, // How many people are your claiming on behalf of (including children)?
    reasonGiven: null, // What reason were you given for the cancellation/delay? (Optional)
    amountEntitled: null, // Amount of compensation entitled to
  },
}
