export const s75ToolState = {
  issue: {
    cardProvider: null, // Who is your card provider?
    claimValue: null, // Do you know how much you want to claim?
    description: '', // Tell us more about the problem. This will be used in your letter
    desiredResolution: null,
    sellerContact: '', // Please describe any contact you have had with the seller to try to resolve things
  },
  product: {
    claimValue: '',
    description: '', // What is the item you bought?
    price: null, // How much did you pay?
    purchaseMethod: '', // How did you make your purchase?
    type: null, // Tool CTA Entry Product/Service
    purchasedDate: '',
  },
  retailer: {
    name: null, // Which retailer did you buy the item from?
    whenBought: null, // What date did you buy it?
    repeatPerformance: null, // Have you asked the provider of the service to offer a repeat performance?
  },
}
