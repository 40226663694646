import type { MMHCResultsEmailType } from '../../../../shared/types/toolTypes'

export const composeEmailBody = ({
  billsSectionTitle,
  billsSectionLinks,
  financeSectionTitle,
  financeSectionLinks,
}: MMHCResultsEmailType) => {
  const billsLinks = Object.values(billsSectionLinks)

  const billsTextLinks: { text: string }[] = []

  const buildUrl = (linkHref: string) => {
    const url = 'https://www.which.co.uk'
    let linkUrl: string

    if (linkHref.indexOf(url) === -1) {
      linkUrl = `${url}${linkHref}`
    } else {
      linkUrl = linkHref
    }

    return linkUrl
  }

  billsLinks.forEach(
    (billLink: { blockTitle: string; links: { text: string; href: string }[] }) => {
      if (billLink.blockTitle !== '') {
        billsTextLinks.push({
          text: `<tr><td><h3 style="font-size: 18px; font-weight: 600; margin: 12px 0 0 0">${billLink.blockTitle}</h3></td></tr><tr><td>`,
        })

        const { links } = billLink
        links.forEach((link: { text: string; href: string }) => {
          const fullBillLink = buildUrl(link.href)

          billsTextLinks.push({
            text: `<p><a style="color: #0050b3; display: inline-block; padding: 8px 0" href="${fullBillLink}" target="_blank">${link.text}</a></p>`,
          })
        })
        billsTextLinks.push({
          text: '</td></tr>',
        })
      }
    }
  )

  const financeLinks = Object.values(financeSectionLinks)
  const financeTextLinks: { text: string }[] = []

  financeLinks.forEach(
    (financeLink: { blockTitle: string; links: { text: string; href: string }[] }) => {
      if (financeLink.blockTitle !== '') {
        financeTextLinks.push({
          text: `<tr><td><h3 style="font-size: 18px; font-weight: 600; margin: 12px 0 0 0">${financeLink.blockTitle}</h3></td></tr><tr><td>`,
        })

        const { links } = financeLink
        links.forEach((link: { text: string; href: string }) => {
          const fullFinanceLink = buildUrl(link.href)
          financeTextLinks.push({
            text: `<p><a style="color: #0050b3; display: inline-block; padding: 8px 0" href="${fullFinanceLink}" target="_blank">${link.text}</a></p>`,
          })
        })

        financeTextLinks.push({ text: '</td></tr>' })
      }
    }
  )

  let body = {
    billsLinksCopy: [
      {
        text: `<table width="100%"><tbody><tr><td><h2 style="font-size: 25px; margin: 16px 0 0 0">${billsSectionTitle}</h2></td></tr>`,
      },
      ...billsTextLinks,
      { text: '</tbody></table>' },
    ],
    financeLinksCopy: [
      {
        text: `<table width="100%"><tbody><tr><td><h2 style="font-size: 25px; margin: 16px 0 0 0">${financeSectionTitle}</h2></td></tr>`,
      },
      ...financeTextLinks,
      { text: '</tbody></table>' },
    ],
  }

  const filteredBillsLinksCopy = body.billsLinksCopy.filter(String)
  const filteredFinanceLinksCopy = body.financeLinksCopy.filter(String)

  body = {
    billsLinksCopy: filteredBillsLinksCopy,
    financeLinksCopy: filteredFinanceLinksCopy,
  }

  return {
    label: 'Money Health Check links',
    body: body,
  }
}
