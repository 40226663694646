import React, { forwardRef, useEffect, useState } from 'react'
import { Label, Typography } from '@which/seatbelt'

import classnames from 'classnames'

import globalStyles from '../../styles/GlobalStyles.module.scss'
import type { CurrencyInputProps } from '../../types'
import { cleanText } from '../../utils/cleanText'
import { handleKeyPress } from '../../utils/keyDownHandler/handleKeyPress'
import { ErrorMessage } from '../ErrorMessage'
import styles from './CurrencyInput.module.scss'

export const CurrencyInput = forwardRef<HTMLInputElement, CurrencyInputProps>(
  (
    {
      currencySymbol,
      displayText,
      optional,
      handleSubmit,
      handleFocus,
      id,
      isFocused = false,
      isSubmitValid = true,
      srLabel,
      stateValue = '',
      bottomMargin = globalStyles.spacingBottom40,
      errorText = 'Please fill out this field to continue',
    }: CurrencyInputProps,
    ref
  ) => {
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('false')

    useEffect(() => {
      if (!isSubmitValid) {
        setError(true)
        setErrorMessage(errorText)
      }
    }, [isSubmitValid, errorText])

    return (
      <div
        className={classnames(styles.currencyInput, bottomMargin, {
          [styles.currencyInputContainerError]: error,
        })}
      >
        {srLabel && <Label className={'sr-only'} htmlFor={`${id}-input`} labelText={srLabel} />}
        {displayText && (
          <Typography
            className={classnames(styles.currencyInputText)}
            tag="p"
            textStyle="body-intro"
          >
            {displayText}
            {optional && (
              <span className={classnames(styles.currencyInputOptional)}> (optional)</span>
            )}
          </Typography>
        )}
        <div className={styles.currencyInputContainer}>
          {currencySymbol && (
            <p className={styles.currencyInputContainerCurrency} data-testid="typography-body">
              {currencySymbol}
            </p>
          )}

          <input
            autoFocus={isFocused}
            className={classnames(styles.currencyInputContainerEntry, {
              [styles.currencyInputError]: error,
              [styles.currencyInputContainerEntryNoSymbol]: !currencySymbol,
            })}
            data-testid={`${id}-input`}
            defaultValue={stateValue}
            id={`${id}-input`}
            inputMode="decimal"
            maxLength={19}
            min="0"
            name={`${id}-input`}
            onBlur={(e) =>
              (e.target.value = parseFloat(cleanText(e.target.value).trim()).toFixed(2))
            }
            onChange={(e) => {
              const decimalIndex = e.target.value.indexOf('.')
              const maxCount = decimalIndex !== -1 ? 13 : 10
              setError(false)
              if (e.target.value.length > maxCount) {
                const adjustedValue = e.target.value.slice(0, maxCount)
                return (e.target.value = parseFloat(adjustedValue).toFixed(2))
              }
            }}
            onFocus={handleFocus}
            onKeyDown={(e) => {
              handleSubmit && handleKeyPress(e, handleSubmit)
            }}
            placeholder="0.00"
            ref={ref}
            step="0.01"
            type="number"
          />
        </div>
        {error && (
          <ErrorMessage
            errorText={errorMessage}
            styleClasses={classnames(styles.currencyInputErrorMessage)}
          />
        )}
      </div>
    )
  }
)
