import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../../shared/state/appContext'
import { trackAppointmentBookingSelection } from '../../../../../shared/utils/tracking/tracking'
import {
  ContactDetailsFlow,
  DescribeCaseFlow,
  LimitedOptionsFlow,
  WillsEstateFlow,
} from '../../../pageFlowData'
import { willsEstateData } from './data'

export const WillsEstate: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    wills: { willsEstate, willsQuery },
    triage: { type },
  } = state

  const handleTracking = (id: string, index: number) => {
    trackAppointmentBookingSelection(type, WillsEstateFlow.VIEW_NAME, { id, index })
  }

  const chooseStep = (id: string) => {
    if (id === 'some-elses-estate') {
      return LimitedOptionsFlow
    } else if (id === 'somebody-else') {
      return ContactDetailsFlow
    }
    return DescribeCaseFlow
  }

  const handleDispatch = (id: string) => {
    const step = chooseStep(id)
    dispatch({ type: 'UPDATE_WILLS_ESTATE', data: id })
    dispatch({ type: 'UPDATE_STEP', data: step.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: step.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string, index: number) => {
    handleTracking(id, index)
    handleDispatch(id)
  }

  const chooseButtons = () => {
    if (willsQuery === 'powers-of-attorney') {
      return willsEstateData.option2
    }
    return willsEstateData.option1
  }

  const { imgObj, title, buttons } = chooseButtons()
  return (
    <div>
      <PillButtonPage
        buttons={buttons}
        defaultValue={willsEstate}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        title={title}
      />
    </div>
  )
}
