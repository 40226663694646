export const earlierLaterOriginalFlightData = {
  earlierLaterOriginalFlightButtons: [
    {
      label: 'I departed earlier',
      id: 'earlier',
    },
    {
      label: 'I arrived later',
      id: 'later',
    },
    {
      label: 'I departed earlier and arrived later',
      id: 'earlier-arrived-later',
    },
  ],
  title: 'Did you depart earlier or arrive later than your original scheduled flight?',
  image: {
    imgSrc:
      'https://media.product.which.co.uk/prod/images/original/d1c3a90daa95-flight-delay-step-6.jpg',
    imgAlt: 'Two people running with suitcases',
  },
}
