import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { Typography } from '@which/seatbelt'

import classNames from 'classnames'

import { CurrencyInput } from '../../components/CurrencyInput'
import { ImageWithQuestion } from '../../components/ImageWithQuestion'
import { NextButton } from '../../components/NextButton'
import globalStyles from '../../styles/GlobalStyles.module.scss'
import type { EnergyCurrencyPageProps, FormEntryInputRef } from '../../types/toolTypes'
import { RenderInfoBox } from '../../utils/renderInfoBox'
import styles from './EnergyCurrencyPage.module.scss'

export const EnergyCurrencyPage = forwardRef<FormEntryInputRef, EnergyCurrencyPageProps>(
  (
    {
      buttonText,
      currencySymbol,
      defaultValue,
      handleSubmit,
      handleFocus,
      id,
      imgObj,
      isFocused,
      isSubmitValid,
      infoBoxData,
      pageData,
    }: EnergyCurrencyPageProps,
    ref
  ) => {
    const currencyInputRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(ref, () => ({
      formEntryInput: currencyInputRef.current,
    }))

    const { standfirst, question, title } = pageData

    const renderStandfirst = () =>
      standfirst.map((paragraph) => <Typography key={paragraph}>{paragraph}</Typography>)

    return (
      <div className={styles.energyCurrencyPage} data-testid="energy-currency-page">
        <ImageWithQuestion
          bottomMargin={globalStyles.spacing20}
          image={imgObj}
          pictureClass={styles.image}
          title={title}
          titleTag="h2"
        />
        {renderStandfirst()}
        <p className={styles.energyCurrencyPageQuestion} data-testid="typography-body">
          {question}
        </p>
        <CurrencyInput
          bottomMargin={globalStyles.spacingBottom35}
          currencySymbol={currencySymbol}
          handleFocus={handleFocus}
          handleSubmit={handleSubmit}
          id={id}
          isFocused={isFocused}
          isSubmitValid={isSubmitValid}
          ref={currencyInputRef}
          srLabel={question}
          stateValue={defaultValue}
        />
        <div
          className={classNames(
            globalStyles.buttonWrapper,
            styles.energyCurrencyPageNextButtonWrapper
          )}
        >
          <NextButton buttonText={buttonText} onClick={handleSubmit} />
        </div>
        {infoBoxData && <RenderInfoBox data={infoBoxData} />}
      </div>
    )
  }
)
