import type { InfoBoxProps } from '../../../../../../shared/types/toolTypes'

export const solicitorData = {
  buttons: [
    {
      label: 'Yes I have',
      id: 'yes',
    },
    {
      label: "No I haven't",
      id: 'no',
    },
  ],
  title:
    'Before we check if we can help you with your legal issue, we have to ask whether you have already instructed a solicitor or other legal professional to advise you in this matter.',
  imgObj: {
    alt: 'two people holding documents',
    src: 'https://media.product.which.co.uk/prod/images/original/8f95d1a7f0dc-cp-step9.jpg',
  },
  infoBox: {
    heading: 'Why do we need this?',
    borderColour: 'blue',
  } as InfoBoxProps,
  infoBoxParagraphs: [
    {
      text: "For regulatory reasons we can't advise if you have formally instructed a a legal professional in this matter. If you have had a free appointment with a professional or an organisation such as Citizen's Advice Bureau, click 'no'.",
    },
  ],
}
