import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { Typography } from '@which/seatbelt'

import classNames from 'classnames'

import { ImageWithQuestion } from '../../components/ImageWithQuestion'
import { NextButton } from '../../components/NextButton'
import { TextArea } from '../../components/TextArea'
import globalStyles from '../../styles/GlobalStyles.module.scss'
import type { TextAreaPageProps } from '../../types/toolTypes'
import styles from './TextAreaPage.module.scss'

export const TextAreaPage = forwardRef(
  (
    {
      buttonText,
      defaultValue,
      handleChange,
      handleSubmit,
      isFocused,
      isInputValid,
      imgObj,
      maxChars,
      title,
      descriptiveText,
      placeholderText,
      noteTop,
      noteBottom,
      optional,
      maxWords,
    }: TextAreaPageProps,
    ref
  ) => {
    const TextAreaInputRef = useRef<HTMLTextAreaElement>(null)

    useImperativeHandle(ref, () => ({
      formEntryTextarea: TextAreaInputRef.current,
    }))

    const renderDescriptiveText = () => {
      if (!descriptiveText?.length) {
        return
      }

      return (
        <div className={classNames(styles.textAreaPageDescriptive, globalStyles.spacing40)}>
          {descriptiveText.map((textString: string, i: number) => (
            <Typography
              dangerouslySetInnerHTML={{ __html: textString }}
              key={`body-copy-${i}`}
              tag="p"
              textStyle="body"
            />
          ))}
        </div>
      )
    }

    const renderNoteTop = () => {
      if (!noteTop?.length) {
        return
      }

      return noteTop.map((paragraph) => (
        <Typography
          className={classNames(styles.textAreaPageDescriptive, styles.textAreaPageNote)}
          key={paragraph}
          textStyle="body"
        >
          {paragraph}
        </Typography>
      ))
    }

    return (
      <div data-testid="text-area-page">
        <ImageWithQuestion image={imgObj} optionalTag={optional} title={title} />
        {renderNoteTop()}
        {renderDescriptiveText()}
        <TextArea
          defaultValue={defaultValue}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          isFocused={isFocused}
          isSubmitValid={isInputValid}
          maxChars={maxChars}
          maxWords={maxWords}
          optional={optional}
          placeholderText={placeholderText}
          ref={TextAreaInputRef}
          srLabel={title}
        />
        {noteBottom && (
          <Typography
            className={classNames(styles.textAreaPageDescriptive, styles.textAreaPageNote)}
            textStyle="body"
          >
            {noteBottom}
          </Typography>
        )}
        <NextButton buttonText={buttonText} onClick={handleSubmit} />
      </div>
    )
  }
)
