import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'
import { Link, Typography } from '@which/seatbelt'

import { PreviewLetter } from '../../../../shared/components/PreviewLetter'
import { SubmitSuccessfulPage } from '../../../../shared/pages/SubmitSuccessfulPage'
import { ToolContext } from '../../../../shared/state/appContext'
import globalStyles from '../../../../shared/styles/GlobalStyles.module.scss'
import { ToolFeedbackFlow, ToolFeedbackThankYouFlow } from '../../pageFlowData'
import { composeEmailBody } from './composeEmailBody'
import { feedbackData } from './data'
import styles from './SubmitSuccessful.module.scss'

export const SubmitSuccessful: FunctionComponent = () => {
  const toolContext = useContext(ToolContext)
  const {
    state: {
      autoEmailer,
      flight,
      claim,
      initial: { emailSent, user },
      toolFeedback: { showFeedback },
    },
    dispatch,
  } = toolContext

  const { airline } = flight
  const { body, imgObj, title } = feedbackData

  const data = composeEmailBody({ flight, claim, user, autoEmailer })

  const standFirstCopy = emailSent
    ? `A copy of your claim letter has been sent to ${user.emailAddress}`
    : 'Due to an error, we were unable to send your email. However, you can get a copy of your complaint letter below'

  const handleStepChoice = (id: string) => {
    if (id === 'feedback-yes') {
      return { step: ToolFeedbackFlow.STEP }
    }

    return { step: ToolFeedbackThankYouFlow.STEP }
  }

  const handleDispatch = (id: string) => {
    const { step } = handleStepChoice(id)
    dispatch({ type: 'UPDATE_STEP', data: step })
  }

  const handleSubmit = (id: string) => {
    handleDispatch(id)
  }

  return (
    <div data-testid="submit-successful">
      <SubmitSuccessfulPage
        feedbackBody={body}
        feedbackImgObj={imgObj}
        feedbackTitle={title}
        handleSubmit={handleSubmit}
        letterBody={data.body}
        letterTitle="Your complaint email"
        list={[
          `Send the letter to ${airline} by post or email. <br /> \r\n To find the correct email address, please check the ${airline}'s website. They should have dedicated guidance on how to submit a claim.`,
          'Include a copy of your tickets. If you are unable to do so, proof of purchase such as credit card statement should be enough.',
          `Give ${airline} 14 days to respond.`,
          `If ${airline} refuses your claim, or fails to respond, you can escalate your claim. <br /> \r\n If you have an unresolved complaint about an airline, it is required to inform you about an alternative dispute resolution (ADR) scheme that you can use.`,
        ]}
        listAppearance="bullet"
        showFeedback={showFeedback}
        standFirst={standFirstCopy}
        title="Your next steps"
      >
        <Typography className={styles.spacing20}>
          The Civil Aviation Authority (CAA) hold a list of{' '}
          <Link href="https://www.caa.co.uk/Passengers/Resolving-travel-problems/How-the-CAA-can-help/Alternative-dispute-resolution/">
            approved providers of ADR schemes and the airlines they cover.
          </Link>
          <br />
          Please note the Civil Aviation Authority can only help you if the flight was cancelled or
          delayed within the UK, or was on a UK based airline. If your flight was cancelled or
          delayed outside the UK, you will need to complain to the airline regulator in the country
          the delay occurred in.
        </Typography>

        <Typography className={globalStyles.spacing40}>
          Regardless of whether you choose to use a CAA approved ADR scheme, you still have the
          right to take an airline to the{' '}
          <Link href="http://www.which.co.uk/consumer-rights/advice/how-to-use-the-small-claims-court">
            small claims court
          </Link>{' '}
          if you feel it is unfairly refusing your compensation. But it's a good idea to seek legal
          advice before taking this step.
        </Typography>
        <PreviewLetter data={data} />
      </SubmitSuccessfulPage>
    </div>
  )
}
