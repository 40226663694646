import type { FunctionComponent } from 'react'
import React from 'react'

import classNames from 'classnames'

import styles from './ToolWrapper.module.scss'

export const ToolWrapper: FunctionComponent<Props> = ({ children, width, noBottomPadding }) => {
  return (
    <div
      className={classNames(
        styles.toolWrapper,
        { [styles.toolWrapperArticleEmbed]: width === 'article' },
        { [styles.toolWrapperNarrow]: width === 'narrow' },
        { [styles.toolWrapperFullWidth]: width === 'full' },
        { [styles.toolWrapperNoBottomPadding]: noBottomPadding }
      )}
      data-testid="tool-wrapper"
    >
      {children}
    </div>
  )
}

type Props = {
  children: React.ReactNode
  width?: string
  noBottomPadding?: boolean
}
