import type { Dispatch, SetStateAction } from 'react'

const googleApiKey = process.env.GOOGLE_MAPS_API_KEY
const googleMapsApiStr = 'https://maps.googleapis.com/maps/api/js'
const mapsParamsStr = `?key=${googleApiKey} &libraries=places,geometry`

let autoComplete: any
let autoCompleteArr: any
let departureAirport: string
let arrivalAirport: string
let distance: number

//loading google script tag
/* istanbul ignore next */
export const googleMapsScript = (callback: any) => {
  const script = document.createElement('script')

  script.src = `${googleMapsApiStr}${mapsParamsStr}`
  script.async = true
  /* istanbul ignore next */
  script.onload = () => callback()
  script.id = 'googlePlaces'

  const scriptTag = document.getElementById('googlePlaces')
  /* istanbul ignore next */
  if (!scriptTag) {
    document.body.appendChild(script)
  }
}

//autocomplete callback function
//for one search box
export const handleAutocomplete = (updateQuery: any, autoCompleteRef: any, types = '(regions)') => {
  autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef, {
    types: [types],
  })
  autoComplete.setFields(['address_components', 'formatted_address'])
  autoComplete.addListener('place_changed', () => handlePlaceSelect(updateQuery))
}

//for more than 1 search box
export const handleAutocomplete_v2 = (
  updateQuery: Dispatch<SetStateAction<any>>,
  autoCompleteRef: (HTMLInputElement | null)[],
  types = '(regions)'
) => {
  autoCompleteArr = []
  if (autoCompleteRef.length) {
    for (let i = 0; i < autoCompleteRef.length; i++) {
      if (autoCompleteRef[i]) {
        autoCompleteArr.splice(
          i,
          0,
          new google.maps.places.Autocomplete(autoCompleteRef[i] as HTMLInputElement, {
            types: [types],
          })
        )
        autoCompleteArr[i].inputId = autoCompleteRef[i]?.id
        if (types === 'airport') {
          autoCompleteArr[i].addListener('place_changed', () => {
            handlePlaceSelectAirports(updateQuery, autoCompleteRef[i])
          })
        } else {
          autoComplete.addListener('place_changed', () => handlePlaceSelect(updateQuery))
        }
      }
    }
  }
}

export const handleAutocomplete_airports_only = (
  updateQuery: Dispatch<SetStateAction<any>>,
  autoCompleteRef: (HTMLInputElement | null)[],
  ukAirportsOnly = ''
) => {
  const types = 'airport'
  autoCompleteArr = []
  if (autoCompleteRef.length) {
    for (let i = 0; i < autoCompleteRef.length; i++) {
      if (autoCompleteRef[i]) {
        if (ukAirportsOnly && autoCompleteRef[i]?.id === ukAirportsOnly) {
          autoCompleteArr.splice(
            i,
            0,
            new google.maps.places.Autocomplete(autoCompleteRef[i] as HTMLInputElement, {
              types: [types],
              componentRestrictions: { country: 'uk' },
            })
          )
        } else {
          autoCompleteArr.splice(
            i,
            0,
            new google.maps.places.Autocomplete(autoCompleteRef[i] as HTMLInputElement, {
              types: [types],
            })
          )
        }

        autoCompleteArr[i].inputId = autoCompleteRef[i]?.id
        autoCompleteArr[i].addListener('place_changed', () => {
          handlePlaceSelectAirports(updateQuery, autoCompleteRef[i])
        })
      }
    }
  }
}

//listener function for when a place is selected
const handlePlaceSelect = async (updateQuery: Dispatch<SetStateAction<string>>) => {
  const addressObject = autoComplete.getPlace()
  const query = addressObject
  updateQuery(query)
}

const handlePlaceSelectAirports = async (updateQuery: Dispatch<SetStateAction<any>>, ref: any) => {
  let addressObject

  if (autoCompleteArr.length) {
    if (ref.id === 'departure-airport-input') {
      addressObject = autoCompleteArr[1].getPlace()
      addressObject.airportType = 'departure'
      departureAirport = ref.value
    }

    if (ref.id === 'arrival-airport-input') {
      addressObject = autoCompleteArr[0].getPlace()
      addressObject.airportType = 'arrival'
      arrivalAirport = ref.value
    }
  }

  const query = addressObject
  updateQuery(query)
}

export const compareAirports = (arrivalApt: string, departureApt: string) => {
  const isValid = {
    arrivalAirport: arrivalApt === arrivalAirport,
    departureAirport: departureApt === departureAirport,
    isValid: arrivalApt === arrivalAirport && departureApt === departureAirport,
  }

  return isValid
}

export const calculateDistance = async (
  startingGeolocation: { lat: number; lng: number },
  endingGeolocation: { lat: number; lng: number }
) => {
  distance = await window.google.maps.geometry.spherical.computeDistanceBetween(
    startingGeolocation,
    endingGeolocation
  )
  // Return distance in kms
  return distance / 1000
}
