import React from 'react'

import { ToolFeedbackAudiencePage } from '../../../shared/pages/ToolFeedbackAudiencePage'
import { toolFeedbackAudienceData } from '../../../shared/pages/ToolFeedbackAudiencePage/data'
import { ToolFeedbackPage } from '../../../shared/pages/ToolFeedbackPage'
import { toolFeedbackData } from '../../../shared/pages/ToolFeedbackPage/data/carProblemData'
import { ToolFeedbackThankYouPage } from '../../../shared/pages/ToolFeedbackThankYouPage'
import { ContactDetails } from '../PageComponents/ContactDetails'
import { CorrectCar } from '../PageComponents/CorrectCar'
import { DealerOfferedSolution } from '../PageComponents/DealerOfferedSolution/DealerOfferedSolution'
import { HowMuch } from '../PageComponents/HowMuch'
import { LikeToDo } from '../PageComponents/LikeToDo'
import { LimitedOptions } from '../PageComponents/LimitedOptions'
import { OnFinance } from '../PageComponents/OnFinance'
import { RegNumber } from '../PageComponents/RegNumber'
import { SubmitSuccessful } from '../PageComponents/SubmitSuccessful'
import { WhatsWrong } from '../PageComponents/WhatsWrong'
import { WhenPurchased } from '../PageComponents/WhenPurchased'
import { WhereBought } from '../PageComponents/WhereBought'
import { WhichDealer } from '../PageComponents/WhichDealer'
import {
  ContactDetailsFlow,
  CorrectCarFlow,
  DealerOfferedSolutionFlow,
  HowMuchFlow,
  LikeToDoFlow,
  LimitedOptionsFlow,
  OnFinanceFlow,
  RegNumberFlow,
  SubmitSuccessfulFlow,
  ToolFeedbackAudienceFlow,
  ToolFeedbackFlow,
  ToolFeedbackThankYouFlow,
  WhatsWrongFlow,
  WhenPurchasedFlow,
  WhereBoughtFlow,
  WhichDealerFlow,
} from '../pageFlowData'

export const useRenderStep = (step: number) => {
  switch (step) {
    case RegNumberFlow.STEP:
      return <RegNumber />

    case CorrectCarFlow.STEP:
      return <CorrectCar />

    case WhereBoughtFlow.STEP:
      return <WhereBought />

    case WhenPurchasedFlow.STEP:
      return <WhenPurchased />

    case OnFinanceFlow.STEP:
      return <OnFinance />

    case DealerOfferedSolutionFlow.STEP:
      return <DealerOfferedSolution />

    case WhichDealerFlow.STEP:
      return <WhichDealer />

    case HowMuchFlow.STEP:
      return <HowMuch />

    case WhatsWrongFlow.STEP:
      return <WhatsWrong />

    case LikeToDoFlow.STEP:
      return <LikeToDo />

    case ContactDetailsFlow.STEP:
      return <ContactDetails />

    case LimitedOptionsFlow.STEP:
      return <LimitedOptions />

    case SubmitSuccessfulFlow.STEP:
      return <SubmitSuccessful />
    case ToolFeedbackFlow.STEP:
      return (
        <ToolFeedbackPage
          pageData={toolFeedbackData}
          toolFeedbackAudienceFlowData={ToolFeedbackAudienceFlow}
          toolFeedbackFlowData={ToolFeedbackFlow}
          toolName="CarProblem"
        />
      )
    case ToolFeedbackThankYouFlow.STEP:
      return <ToolFeedbackThankYouPage toolFeedbackThankYouFlowData={ToolFeedbackThankYouFlow} />
    case ToolFeedbackAudienceFlow.STEP:
      return (
        <ToolFeedbackAudiencePage
          pageData={toolFeedbackAudienceData}
          toolFeedbackAudienceFlowData={ToolFeedbackAudienceFlow}
          toolFeedbackThankYouFlowData={ToolFeedbackThankYouFlow}
          toolName="CarProblem"
        />
      )
    default:
      return <div>CarProblem Tool</div>
  }
}
