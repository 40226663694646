export const scammerReferenceData = {
  telephone: {
    title: 'Please enter the number',
    imgObj: {
      alt: 'Person looking at number key pad on a smartphone',
      src: 'https://media.product.which.co.uk/prod/images/original/793c2bc7708e-please-enter-the-number-optional.jpg',
    },
  },
  online: {
    title: 'Please enter the link to the website',
    imgObj: {
      alt: 'Person typing on a PC',
      src: 'https://media.product.which.co.uk/prod/images/original/e8c406eca0c4-please-enter-the-link-to-the-website-optional.jpg',
    },
  },
  email: {
    title: 'Please enter the email address the email was sent from',
    imgObj: {
      alt: 'Person looking at email app on a smartphone',
      src: 'https://media.product.which.co.uk/prod/images/original/f607458d1114-please-enter-the-email-addressthe-email-was-sent-from-optional.jpg',
    },
  },
  textMessage: {
    title: 'Please enter the number the text came from',
    imgObj: {
      alt: 'Person looking at number key pad on a smartphone',
      src: 'https://media.product.which.co.uk/prod/images/original/ed660e4c52eb-please-enter-the-number-the-text-came-from-optional.jpg',
    },
  },
  whatsapp: {
    title: 'Please enter the number the WhatsApp came from',
    imgObj: {
      alt: 'Person looking at number key pad on a smartphone',
      src: 'https://media.product.which.co.uk/prod/images/original/ed660e4c52eb-please-enter-the-number-the-text-came-from-optional.jpg',
    },
  },
  social: {
    title: 'Please enter the website address',
    imgObj: {
      alt: 'Person typing on a PC',
      src: 'https://media.product.which.co.uk/prod/images/original/eaa436b0bb46-please-enter-the-website-address-optional.jpg',
    },
  },
}
