import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { FormEntryPage } from '../../../../shared/pages/FormEntryPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryInputRef, SingleInputState } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { BeenAffectedFlow, HaveContactFlow, NameOfScammerFlow } from '../../pageFlowData'
import { nameOfScammerData } from './data'

export const NameOfScammer: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    scam: { nameOfScammer, comeAcrossScam },
  } = state

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })
  const NameOfScammerInput = useRef<FormEntryInputRef>(null)

  const getValue = () => {
    return cleanText(NameOfScammerInput?.current?.formEntryInput?.value || '')
  }

  const handleStepChoice = () => {
    if (comeAcrossScam === 'letter') {
      return { progress: BeenAffectedFlow.PROGRESS_VALUE, step: BeenAffectedFlow.STEP }
    }

    return { progress: HaveContactFlow.PROGRESS_VALUE, step: HaveContactFlow.STEP }
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${NameOfScammerFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    const { progress, step } = handleStepChoice()

    dispatch({ type: 'UPDATE_NAME_OF_SCAMMER', data: value })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleSubmit = () => {
    const value = getValue()
    setInputData({
      renderError: false,
      value: value,
    })

    handleTracking(value)
    handleDispatch(value)
  }

  const { telephone, online, email, letter, textMessage, social } = nameOfScammerData

  const chooseTitle = () => {
    switch (comeAcrossScam) {
      case 'telephone':
        return telephone
      case 'online':
        return online
      case 'email':
        return email
      case 'letter':
        return letter
      case 'whatsapp':
      case 'text-message':
        return textMessage
      case 'social-media':
        return social
      default:
        return telephone
    }
  }

  const { title, imgObj } = chooseTitle()
  const { renderError, value: errorValue } = inputData

  return (
    <FormEntryPage
      buttonText="Next"
      defaultValue={nameOfScammer}
      fieldData={{
        renderError: renderError,
        value: errorValue,
      }}
      handleSubmit={handleSubmit}
      id="who-was-scammer"
      imgObj={imgObj}
      isFocused
      optional={true}
      placeholderText=""
      ref={NameOfScammerInput}
      testId="who-was-scammer"
      title={title}
    />
  )
}
