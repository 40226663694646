import type { FunctionComponent } from 'react'
import { useState } from 'react'
import React, { useContext } from 'react'

import { SelectBoxPage } from '../../../../shared/pages/SelectBoxPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import {
  HoursDifferenceFlow,
  LimitedOptionsFlow,
  NumPeopleClaimingForFlow,
} from '../../pageFlowData'
import { hoursDifferenceData } from './data'

export const HoursDifference: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    claim: { hoursDifference, timeBeforeCancelled, earlierLaterOriginalFlight },
    flight: { delayedCancelled },
  } = state

  const [isInputValid, setIsInputValid] = useState<boolean>(true)
  const [select1Value, setSelect1Value] = useState(hoursDifference.value1 || '')
  const [select2Value, setSelect2Value] = useState(hoursDifference.value2 || '')

  const handleStepChoice = ({ value1, value2 }) => {
    switch (true) {
      case (delayedCancelled === 'flight-delayed' && value1 === '1-hour-late-arriving') ||
        (delayedCancelled === 'flight-delayed' && value1 === '2-hours-late-arriving'):
      case delayedCancelled === 'flight-rerouted' &&
        timeBeforeCancelled === 'fewer-than-7-days' &&
        earlierLaterOriginalFlight === 'earlier' &&
        value1 === 'less-than-1-hour-earlier-leaving':
      case delayedCancelled === 'flight-rerouted' &&
        timeBeforeCancelled === 'fewer-than-7-days' &&
        earlierLaterOriginalFlight === 'later' &&
        value1 === '1-hour-late-arriving':
      case delayedCancelled === 'flight-rerouted' &&
        timeBeforeCancelled === 'fewer-than-7-days' &&
        earlierLaterOriginalFlight === 'earlier-arrived-later' &&
        value1 === 'less-than-1-hour-earlier-leaving' &&
        value2 === '1-hour-late-arriving':
      case delayedCancelled === 'flight-rerouted' &&
        timeBeforeCancelled === 'between-7-and-14-days' &&
        earlierLaterOriginalFlight === 'earlier' &&
        value1 === 'less-than-2-hours-earlier-leaving':
      case delayedCancelled === 'flight-rerouted' &&
        timeBeforeCancelled === 'between-7-and-14-days' &&
        earlierLaterOriginalFlight === 'later' &&
        value1 !== '4-or-more-hours-late-arriving':
      case delayedCancelled === 'flight-rerouted' &&
        timeBeforeCancelled === 'between-7-and-14-days' &&
        earlierLaterOriginalFlight === 'earlier-arrived-later' &&
        value1 === 'less-than-2-hours-earlier-leaving' &&
        value2 !== '4-or-more-hours-late-arriving':
        return { progress: LimitedOptionsFlow.PROGRESS_VALUE, step: LimitedOptionsFlow.STEP }
      default:
        return {
          progress: NumPeopleClaimingForFlow.PROGRESS_VALUE,
          step: NumPeopleClaimingForFlow.STEP,
        }
    }
  }

  const handleDispatch = (value: { value1: string; value2: string }) => {
    const { progress, step } = handleStepChoice(value)
    dispatch({ type: 'UPDATE_HOURS_DIFFERENCE', data: value })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleTracking = ({ value1, value2 }) => {
    const trackingAnswer = `question | ${HoursDifferenceFlow.VIEW_NAME} | ${value1} ${value2}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const getValue = () => ({
    value1: select1Value,
    value2: select2Value,
  })

  const handleSubmit = () => {
    const value = getValue()

    if (!value.value1 || (selectData.length > 1 && !value.value2)) {
      setIsInputValid(false)
      return
    }

    setIsInputValid(true)
    handleDispatch(value)
    handleTracking(value)
  }

  const handleSelect1Change = (selectedOption: string) => {
    setSelect1Value(selectedOption)
  }

  const handleSelect2Change = (selectedOption: string) => {
    setSelect2Value(selectedOption)
  }

  const { scenarioA, scenarioB, scenarioC, scenarioD, scenarioE } = hoursDifferenceData

  const getScenarioData = () => {
    switch (true) {
      case timeBeforeCancelled === 'fewer-than-7-days' && earlierLaterOriginalFlight === 'earlier':
        return scenarioB
      case timeBeforeCancelled === 'fewer-than-7-days' &&
        earlierLaterOriginalFlight === 'earlier-arrived-later':
        return scenarioC
      case timeBeforeCancelled === 'between-7-and-14-days' &&
        earlierLaterOriginalFlight === 'earlier':
        return scenarioD
      case timeBeforeCancelled === 'between-7-and-14-days' &&
        earlierLaterOriginalFlight === 'earlier-arrived-later':
        return scenarioE
      default:
        return scenarioA
    }
  }

  const { question, imgObj, selectData } = getScenarioData()

  const selectBoxData = selectData.map((select) => {
    let selectCallback = handleSelect1Change
    if (select.id === 'select-2') {
      selectCallback = handleSelect2Change
    }
    return {
      ...select,
      onChangeCallback: selectCallback,
    }
  })

  return (
    <div data-testid="hours-difference">
      <SelectBoxPage
        buttonText="Next"
        defaultValue={hoursDifference}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        isInputValid={isInputValid}
        multiple={selectData.length > 1}
        selectBoxes={selectBoxData}
        title={question}
      />
    </div>
  )
}
