export const financeSituationData = {
  buttons: [
    {
      label: "I'm really struggling financially and need support",
      id: 'red',
    },
    {
      label: 'I will have issues financially as the cost of living gets worse',
      id: 'amber',
    },
    {
      label: "I'm comfortable but would like to start cutting back and saving money",
      id: 'green',
    },
  ],
  title: 'How would you describe your current financial situation?',
  imgObj: {
    alt: 'A family sitting down staring at a piece of paper',
    src: 'https://media.product.which.co.uk/prod/images/original/b9bbdf697c29-financial.jpg',
  },
}
