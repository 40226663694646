import type { FunctionComponent } from 'react'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Picture, Typography } from '@which/seatbelt'

import classNames from 'classnames'

import { FinanceSituationFlow } from '../../../tools/MyMoneyHealthCheck/pageFlowData'
import { ImageWithQuestion } from '../../components/ImageWithQuestion'
import { NextButton } from '../../components/NextButton'
import { ToolContext } from '../../state/appContext'
import type { CRToolEntryPointProps, variantDataProps } from '../../types/toolTypes'
import { parseJson } from '../../utils/parseJson/parseJson'
import { generalDataLayer } from '../../utils/tracking'
import { validEntryOptions } from '../../utils/validEntryOptions/validEntryOptions'
import styles from './CRToolEntryPoint.module.scss'
import { CRToolEntryData } from './data'

export const CRToolEntryPoint: FunctionComponent<Props> = ({
  optionsJson,
  toolName,
  buttonText,
  questionText,
  standfirst,
  title,
  toolUrl,
}) => {
  const { state, dispatch } = useContext(ToolContext)
  const [selection, setSelection] = useState('product')
  const [external, setExternal] = useState(false)

  const history = useHistory()

  useEffect(() => {
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: 0 })
  }, [dispatch])

  if (
    ((optionsJson && !validEntryOptions(parseJson(optionsJson))) ||
      !Array.isArray(parseJson(standfirst))) &&
    toolName !== 'MyMoneyHealthCheck'
  ) {
    return null
  }

  let href = toolUrl
  const options: CRToolEntryPointProps = optionsJson ? parseJson(optionsJson) : []

  if (optionsJson && options.length > 0) {
    const chosenOption = options.filter(({ value }) => value === selection)
    if (chosenOption[0].external) {
      href = chosenOption[0].link
      if (!external) {
        setExternal(true)
      }
    } else {
      href = `?type=${chosenOption[0].link}`
      if (external) {
        setExternal(false)
      }
    }
  }

  const standfirstArray: string[] = parseJson(standfirst)

  const handleClick = () => {
    if (!external) {
      if (toolName === 'MyMoneyHealthCheck') {
        dispatch({ type: 'UPDATE_LANDING_PAGE_COMPLETED', data: true })
        dispatch({ type: 'UPDATE_STEP', data: FinanceSituationFlow.STEP })
        dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: FinanceSituationFlow.PROGRESS_VALUE })
        return
      }

      history.push(href, { ...state })
      const trackingAnswer = `question | product-type | ${selection}`
      generalDataLayer(toolName, trackingAnswer, false)
      dispatch({ type: 'UPDATE_PRODUCT_TYPE', data: selection })
    } else {
      history.push(href, null)
      location.reload()
    }
  }

  const renderDropdown = () => {
    if (options.length > 0) {
      return (
        <select
          className={classNames(styles.CRToolEntryPointDropdown, {
            [styles.CRToolEntryPointDropdownVariant]:
              toolName === 'faulty goods' || toolName === 'MyMoneyHealthCheck',
          })}
          data-testid="cr-tool-datalist-items"
          id="tool-choice"
          name="tool-choice"
          onChange={(e) => {
            setSelection(e.target.value)
          }}
        >
          {options.map((option) => {
            const { label, value } = option
            return (
              <option key={value} value={value}>
                {label}
              </option>
            )
          })}
        </select>
      )
    }
  }

  const renderStartComplaint = () => {
    return (
      <div className={styles.CRToolEntryPointButton}>
        <NextButton
          buttonText={buttonText}
          data-testid="cr-tool-link"
          moneySize={toolName === 'MyMoneyHealthCheck'}
          onClick={handleClick}
        />
      </div>
    )
  }

  const renderCRToolEntryPoint = () => {
    if (toolName === 'faulty goods' || toolName === 'MyMoneyHealthCheck') {
      return renderVariant()
    }
    return renderOriginal()
  }

  const renderOriginal = (variant = false) => {
    if (optionsJson) {
      return (
        <div
          className={classNames({
            [styles.CRToolEntryPoint]: !variant,
          })}
        >
          <Typography className={styles.CRToolEntryPointTitle} textStyle="title-400">
            {title}
          </Typography>
          {standfirstArray?.map((standfirstItem) => {
            return (
              <Typography key={standfirstItem} textStyle="body">
                {standfirstItem}
              </Typography>
            )
          })}

          <Typography className={styles.CRToolEntryPointQuestion} textStyle="body-intro">
            {questionText}
          </Typography>
          {renderDropdown()}
          {renderStartComplaint()}
        </div>
      )
    }
  }

  const renderSteps = (steps: { strapline: string; image: { src: string; alt: string } }[]) => {
    return steps.map((step, index) => {
      const { strapline, image } = step

      return (
        <div className={styles.CRToolEntryPointStep} key={`step-${index}`}>
          <Picture
            className={classNames(styles.CRToolEntryPointStepImage, {
              [styles.CRToolEntryPointStepImageMoney]: toolName === 'MyMoneyHealthCheck',
            })}
            lazyLoad={false}
            {...image}
          />
          <p className={styles.CRToolEntryPointStepText} data-testid="typography-body">
            {strapline}
          </p>
        </div>
      )
    })
  }

  const renderStrapLine = ({ text, money }: { text: string; money?: boolean }) => (
    <Typography
      className={classNames(styles.CRToolEntryPointStrapline, {
        [styles.CRToolEntryPointMoneyStrapline]: money,
      })}
      dangerouslySetInnerHTML={{ __html: text }}
      key={text}
      textStyle="body"
    />
  )

  const renderVariant = () => {
    const variantData: variantDataProps =
      toolName === 'faulty goods' ? CRToolEntryData.faultyGoods : CRToolEntryData.myMoneyHealthCheck
    return (
      <div
        className={classNames(styles.CRToolEntryPointVariant, {
          [styles.CRToolEntryPointMoneyWrapper]: toolName === 'MyMoneyHealthCheck',
        })}
      >
        <Typography
          className={classNames(styles.CRToolEntryPointTitle, styles.CRToolEntryPointTitleVariant)}
          textStyle="title-650"
        >
          {variantData.title}
        </Typography>
        {variantData.straplines.map((strapline) => renderStrapLine({ ...strapline }))}
        <div className={styles.CRToolEntryPointSteps}>{renderSteps(variantData.calloutSteps)}</div>
        {variantData?.image && <ImageWithQuestion {...variantData.image} />}
        {renderDropdown()}
        {renderStartComplaint()}
      </div>
    )
  }

  return <div data-testid="cr-tool-entry-point">{renderCRToolEntryPoint()}</div>
}

type Props = {
  optionsJson: string
  toolName: string
  buttonText: string
  questionText: string
  standfirst: string
  title: string
  toolUrl: string
}
