const standardQuestion = () => {
  return [
    {
      title: 'What will you do now?',
      buttons: [
        {
          label: "I have accepted the company's decision",
          id: 'accepted-decision',
          isFocused: false,
        },
        {
          label: "I will challenge the company's decision",
          id: 'challenge-decision',
          isFocused: false,
        },
        {
          label: 'Not yet decided / not sure',
          id: 'not-sure',
          isFocused: false,
        },
      ],
      isSubmitValid: true,
      id: 'what-now',
    },
  ]
}

export const faultyGoodsData = {
  radioQuestions: [
    {
      title: 'What reason, if any, were you given for your claim being unsuccessful?',
      buttons: [
        {
          label: 'No reason given',
          id: 'no-reason',
          isFocused: false,
        },
        {
          label: 'Said there was no problem / fault',
          id: 'no-problem',
          isFocused: false,
        },
        {
          label: 'Said problem was not their responsibility',
          id: 'not-responsible',
          isFocused: false,
        },
        {
          label: 'Said my claim was too late',
          id: 'too-late',
          isFocused: false,
        },
        {
          label: 'Other',
          id: 'other',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: false,
            name: 'text',
            displayText: 'Please specify',
          },
        },
      ],
      isSubmitValid: true,
      id: 'reason-unsuccessful',
    },
    ...standardQuestion(),
  ],
}

export const section75Data = {
  radioQuestions: [
    {
      title: 'What reason, if any, were you given for your claim being unsuccessful?',
      buttons: [
        {
          label: 'No reason given',
          id: 'no-reason',
          isFocused: false,
        },
        {
          label: "Said I hadn't provided sufficient evidence",
          id: 'insufficient-evidence',
          isFocused: false,
        },
        {
          label: "Said I hadn't contacted the seller before making my claim",
          id: 'no-seller-contact',
          isFocused: false,
        },
        {
          label: 'Said my claim was outside the scope of the scheme',
          id: 'outside-scope',
          isFocused: false,
        },
        {
          label: 'Said my claim was too late',
          id: 'too-late',
          isFocused: false,
        },
        {
          label: 'Other',
          id: 'other',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: false,
            name: 'text',
            displayText: 'Please specify',
          },
        },
      ],
      isSubmitValid: true,
      id: 'reason-unsuccessful',
    },
    ...standardQuestion(),
  ],
}

export const holidayData = {
  radioQuestions: [
    {
      title: 'What reason, if any, were you given for your claim being unsuccessful?',
      buttons: [
        {
          label: 'No reason given',
          id: 'no-reason',
          isFocused: false,
        },
        {
          label: "Said I hadn't provided sufficient evidence",
          id: 'insufficient-evidence',
          isFocused: false,
        },
        {
          label: 'Said there was no problem / fault',
          id: 'no-problem',
          isFocused: false,
        },
        {
          label: 'Said problem was not their responsibility',
          id: 'not-responsible',
          isFocused: false,
        },
        {
          label: 'Said my claim was too late',
          id: 'too-late',
          isFocused: false,
        },
        {
          label: 'Other',
          id: 'other',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: false,
            name: 'text',
            displayText: 'Please specify',
          },
        },
      ],
      isSubmitValid: true,
      id: 'reason-unsuccessful',
    },
    ...standardQuestion(),
  ],
}

export const parkingData = {
  radioQuestions: [
    {
      title: 'What reason, if any, were you given for your appeal being unsuccessful?',
      buttons: [
        {
          label: 'No reason given',
          id: 'no-reason',
          isFocused: false,
        },
        {
          label: "Said I hadn't provided sufficient evidence",
          id: 'insufficient-evidence',
          isFocused: false,
        },
        {
          label: 'Insisted I was at fault / did not accept my argument',
          id: 'appeal-not-accepted',
          isFocused: false,
        },
        {
          label: 'Said my appeal was too late',
          id: 'too-late',
          isFocused: false,
        },
        {
          label: 'Other',
          id: 'other',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: false,
            name: 'text',
            displayText: 'Please specify',
          },
        },
      ],
      isSubmitValid: true,
      id: 'reason-unsuccessful',
    },
    ...standardQuestion(),
  ],
}

export const flightData = {
  radioQuestions: [
    {
      title: 'What reason, if any, were you given for your claim being unsuccessful?',
      buttons: [
        {
          label: 'No reason given',
          id: 'no-reason',
          isFocused: false,
        },
        {
          label: 'Airline disputed length of the delay',
          id: 'disputed-delay-length',
          isFocused: false,
        },
        {
          label: 'Airline disputed distance of the flight',
          id: 'disputed-distance',
          isFocused: false,
        },
        {
          label: 'Extraordinary circumstances',
          id: 'extraordinary-circumstances',
          isFocused: false,
        },
        {
          label: 'Other',
          id: 'other',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: false,
            name: 'text',
            displayText: 'Please specify',
          },
        },
      ],
      isSubmitValid: true,
      id: 'reason-unsuccessful',
    },
    ...standardQuestion(),
  ],
}

export const deliveryData = {
  radioQuestions: [
    {
      title: 'What reason, if any, were you given for your complaint / claim being unsuccessful?',
      buttons: [
        {
          label: 'No reason given',
          id: 'no-reason',
          isFocused: false,
        },
        {
          label: "Said I hadn't provided sufficient evidence",
          id: 'insufficient-evidence',
          isFocused: false,
        },
        {
          label: 'Said there was no problem',
          id: 'no-problem',
          isFocused: false,
        },
        {
          label: 'Said problem was not their responsibility',
          id: 'not-responsible',
          isFocused: false,
        },
        {
          label: 'Said my complaint was too late',
          id: 'too-late',
          isFocused: false,
        },
        {
          label: 'Other',
          id: 'other',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: false,
            name: 'text',
            displayText: 'Please specify',
          },
        },
      ],
      isSubmitValid: true,
      id: 'reason-unsuccessful',
    },
    ...standardQuestion(),
  ],
}

export const packageBankAccountData = {
  radioQuestions: [
    {
      title: 'What reason, if any, were you given for your complaint/claim being unsuccessful?',
      buttons: [
        {
          label: 'No reason given',
          id: 'no-reason',
          isFocused: false,
        },
        {
          label: 'Bank disputed that they had mis-sold the account',
          id: 'disputed-mis-sold',
          isFocused: false,
        },
        {
          label: 'Other',
          id: 'other',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: false,
            name: 'text',
            displayText: 'Please specify',
          },
        },
      ],
      isSubmitValid: true,
      id: 'reason-unsuccessful',
    },
    ...standardQuestion(),
  ],
}

export const broadbandData = {
  radioQuestions: [
    {
      title: 'What reason, if any, were you given for your complaint being unsuccessful?',
      buttons: [
        {
          label: 'No reason given',
          id: 'no-reason',
          isFocused: false,
        },
        {
          label: "Said I hadn't provided sufficient evidence",
          id: 'insufficient-evidence',
          isFocused: false,
        },
        {
          label: 'Said there was no problem with the speed of my service',
          id: 'no-problem',
          isFocused: false,
        },
        {
          label: 'Said the problem was not their responsibility',
          id: 'not-their-responsibility',
          isFocused: false,
        },
        {
          label: 'Other',
          id: 'other',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: false,
            name: 'text',
            displayText: 'Please specify',
          },
        },
      ],
      isSubmitValid: true,
      id: 'reason-unsuccessful',
    },
    ...standardQuestion(),
  ],
}

export const homeImprovementData = {
  radioQuestions: [
    {
      title: 'What reason, if any, were you given for your complaint being unsuccessful?',
      buttons: [
        {
          label: 'No reason given',
          id: 'no-reason',
          isFocused: false,
        },
        {
          label: "Said I hadn't provided sufficient evidence",
          id: 'insufficient-evidence',
          isFocused: false,
        },
        {
          label: 'Said the problem was not their responsibility',
          id: 'not-their-responsibility',
          isFocused: false,
        },
        {
          label: 'Other',
          id: 'other',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: false,
            name: 'text',
            displayText: 'Please specify',
          },
        },
      ],
      isSubmitValid: true,
      id: 'reason-unsuccessful',
    },
    ...standardQuestion(),
  ],
}
