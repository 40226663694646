import type { PageFlowProps } from '../../../shared/types/toolTypes'

export const RegardingProblemFlow: PageFlowProps = {
  MILESTONE: 'milestone | start',
  PROGRESS_VALUE: 0,
  REDUCERS: ['UPDATE_REGARDING_PROBLEM'],
  STATE_VALUE: {
    UPDATE_REGARDING_PROBLEM: [],
  },
  STEP: 1,
  VIEW_NAME: 'regarding-problem',
}

export const WasSuccessfulFlow: PageFlowProps = {
  MILESTONE: '',
  PROGRESS_VALUE: 20,
  REDUCERS: ['UPDATE_WAS_SUCCESSFUL'],
  STATE_VALUE: {
    UPDATE_WAS_SUCCESSFUL: [],
  },
  STEP: 2,
  VIEW_NAME: 'was-successful',
}

export const WhatWasOutcomeFlow: PageFlowProps = {
  MILESTONE: '',
  PROGRESS_VALUE: 40,
  REDUCERS: ['UPDATE_WHAT_WAS_OUTCOME', 'UPDATE_OUTCOME_AMOUNT', 'UPDATE_LETTER_SUCCESS'],
  STATE_VALUE: {
    UPDATE_WHAT_WAS_OUTCOME: [],
    UPDATE_OUTCOME_AMOUNT: '',
    UPDATE_LETTER_SUCCESS: [],
  },
  STEP: 3,
  VIEW_NAME: 'what-was-outcome',
}

export const ReasonUnsuccessfulFlow: PageFlowProps = {
  MILESTONE: '',
  PROGRESS_VALUE: 40,
  REDUCERS: ['UPDATE_REASON_UNSUCCESSFUL', 'UPDATE_WHAT_NOW'],
  STATE_VALUE: {
    UPDATE_REASON_UNSUCCESSFUL: [],
    UPDATE_WHAT_NOW: [],
  },
  STEP: 4,
  VIEW_NAME: 'reason-unsuccessful',
}

export const NoWhichToolFlow: PageFlowProps = {
  MILESTONE: '',
  PROGRESS_VALUE: 60,
  REDUCERS: ['UPDATE_NO_WHICH_TOOL', 'UPDATE_TOOL_EASIER', 'UPDATE_SAVED_TIME'],
  STATE_VALUE: {
    UPDATE_NO_WHICH_TOOL: [],
    UPDATE_TOOL_EASIER: '',
    UPDATE_SAVED_TIME: '',
  },
  STEP: 5,
  VIEW_NAME: 'no-which-tool',
}

export const HowMuchTimeSavedFlow: PageFlowProps = {
  MILESTONE: '',
  PROGRESS_VALUE: 80,
  REDUCERS: ['UPDATE_HOW_MUCH_TIME_SAVED', 'UPDATE_REDUCED_STRESS', 'UPDATE_MORE_ABOUT_EXPERIENCE'],
  STATE_VALUE: {
    UPDATE_HOW_MUCH_TIME_SAVED: [],
    UPDATE_REDUCED_STRESS: [],
    UPDATE_MORE_ABOUT_EXPERIENCE: [],
  },
  STEP: 6,
  VIEW_NAME: 'how-much-time-saved',
}

export const ReducedStressFlow: PageFlowProps = {
  MILESTONE: '',
  PROGRESS_VALUE: 80,
  REDUCERS: [''],
  STEP: 7,
  VIEW_NAME: 'reduced-stress',
}

export const FollowUpFlow: PageFlowProps = {
  MILESTONE: '',
  PROGRESS_VALUE: 95,
  REDUCERS: ['UPDATE_FOLLOW_UP'],
  STATE_VALUE: { UPDATE_FOLLOW_UP: [] },
  STEP: 8,
  VIEW_NAME: 'follow-up',
}

export const SubmitSuccessfulFlow: PageFlowProps = {
  MILESTONE: 'milestone | end | journey complete',
  PROGRESS_VALUE: 100,
  REDUCERS: [''],
  STEP: 9,
  VIEW_NAME: 'journey-complete',
}

export const ThankYouFlow: PageFlowProps = {
  MILESTONE: 'milestone | thank you',
  PROGRESS_VALUE: 100,
  REDUCERS: [''],
  STEP: 10,
  VIEW_NAME: 'thank-you',
}

export const ReasonNotFlow: PageFlowProps = {
  MILESTONE: '',
  PROGRESS_VALUE: 80,
  REDUCERS: ['UPDATE_REASON_NOT'],
  STATE_VALUE: {
    UPDATE_REASON_NOT: [],
  },
  STEP: 11,
  VIEW_NAME: 'reason-not',
}

export const AllFlows = [
  FollowUpFlow,
  HowMuchTimeSavedFlow,
  NoWhichToolFlow,
  ReasonNotFlow,
  ReasonUnsuccessfulFlow,
  ReducedStressFlow,
  RegardingProblemFlow,
  SubmitSuccessfulFlow,
  ThankYouFlow,
  WasSuccessfulFlow,
  WhatWasOutcomeFlow,
]
