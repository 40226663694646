export const companyNameData = {
  title: "What's the name of the trading company you booked your service with?",
  imgObj: {
    alt: 'Two people holding a paint brush',
    src: 'https://media.product.which.co.uk/prod/images/original/9eb54ba0df0d-step7.jpg',
  },
  placeholderText: '',
  infoTitle:
    "Based on the information you've provided you can ask the trader to correct any issues with the service",
  bodyCopy: [
    "If you're not satisfied with a service, you have the right to ask the trader to make things right. They should redo the service or perform it again at no extra cost to you. If this isn't possible, you can ask for a price reduction.",
    "This is because you're protected under the Consumer Rights Act 2015 (in force from 1st October 2015).",
  ],
  bodyCopyBefore: [
    'The Supply of Goods & Service Act only applies to contracts entered into before 1 October 2015.',
    'You may be entitled to a fix of substandard work if you paid for a service form a dealer less than 6 years ago (or 5 years in Scotland). You also might be able to negotiate a reduction in price or partial refund if any repairs are unsuccessful.',
  ],
  standFirst:
    "We'll use this information to draft your complaint letter and to collect data on dealerships that have treated customers unfairly.",
}
