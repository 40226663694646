import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'

import { IssueDescription } from '../../components/IssueDescription'
import { OrderNumber } from '../../components/OrderNumber'
import { PageFade } from '../../components/PageFade'
import { PurchasePrice } from '../../components/PurchasePrice'
import { WhichRetailer } from '../../components/WhichRetailer'
import type {
  IssueDescriptionRef,
  OrderNumberRef,
  PurchasePriceRef,
  WhichRetailerRef,
  WriteLetterObj,
} from '../../types/toolTypes'

export const WriteLetterModule = forwardRef(
  (
    {
      writeLetterObj: {
        writeLetterModuleStep,
        whichRetailer,
        purchasePrice,
        issueDescription,
        orderNumber,
        toolProgress,
        toolName,
      },
    }: WriteLetterObj,
    ref
  ) => {
    const [isForward, setIsForward] = useState(true)
    const whichRetailerRef = useRef<WhichRetailerRef>(null)
    const purchasePriceRef = useRef<PurchasePriceRef>(null)
    const issueDescriptionRef = useRef<IssueDescriptionRef>(null)
    const orderNumberRef = useRef<OrderNumberRef>(null)

    useImperativeHandle(ref, () => ({
      whichRetailerRef: whichRetailerRef.current,
      purchasePriceRef: purchasePriceRef.current,
      issueDescriptionRef: issueDescriptionRef.current,
      orderNumberRef: orderNumberRef.current,
    }))

    const renderWriteLetterStep = () => {
      switch (writeLetterModuleStep) {
        case 0:
          return <WhichRetailer props={whichRetailer.props} ref={whichRetailerRef} />
        case 1:
          return <PurchasePrice props={purchasePrice.props} ref={purchasePriceRef} />
        case 2:
          return <IssueDescription props={issueDescription.props} ref={issueDescriptionRef} />
        case 3:
          return <OrderNumber props={orderNumber.props} ref={orderNumberRef} />
        default:
          return null
      }
    }

    return (
      <PageFade
        isForward={isForward}
        moduleProps={{ whichRetailer, purchasePrice, issueDescription, orderNumber }}
        setIsForward={setIsForward}
        stateProgress={toolProgress}
        step={writeLetterModuleStep}
        toolName={toolName}
      >
        {renderWriteLetterStep()}
      </PageFade>
    )
  }
)
