export const techQueryData = {
  title: 'Which of the following best matches your query?',
  imgObj: {
    alt: 'Two people looking stressed at a computer',
    src: 'https://media.product.which.co.uk/prod/images/original/1fea9f96da2f-have-you-been-affected-by-the-scam.jpg',
  },
  option1: {
    buttons: [
      { label: 'All-in-one Computers', id: 'all-in-one' },
      { label: 'Anti Virus Software', id: 'anti-virus' },
      { label: 'Audio Equipment', id: 'audio-equipment' },
      { label: 'Broadband', id: 'broadband' },
      { label: 'Cameras', id: 'cameras' },
      { label: 'Cloud Storage', id: 'cloud-storage' },
      { label: 'Cordless Phones', id: 'cordless-phones' },
      { label: 'Desktop', id: 'desktop' },
      { label: 'E-Book Readers', id: 'ebook-readers' },
      { label: 'Games Consoles', id: 'games-console' },
      { label: 'Laptops', id: 'laptops' },
      { label: 'Mobile', id: 'mobile' },
      { label: 'Networking Equipment (domestic)', id: 'networking' },
      {
        label: 'Peripherals',
        id: 'peripherals',
        tooltip: 'Examples: mouse, keyboard, webcam, speakers, Bluetooth device or headphones.',
      },
      { label: 'Printers & Scanners', id: 'printers-scanners' },
      {
        label: 'Smart Tech',
        id: 'smart-tech',
        tooltip:
          'We can help with common smart tech home additions such as doorbells, light switches etc',
      },
      { label: 'Smart TVs', id: 'smart-tv' },
      { label: 'Storage Devices', id: 'storage-device' },
      { label: 'Tablet', id: 'tablet' },
    ],
  },
  option2: {
    buttons: [
      { label: "Can't access email account", id: 'cant-access-email' },
      { label: "Can't Send or receive emails", id: 'cant-send-email' },
      { label: 'Contacts and Calendars', id: 'contact-or-calendar' },
      { label: 'Email Account', id: 'email-account' },
      { label: 'Possible email account breach', id: 'possible-email-breach' },
      { label: 'Problems with email program', id: 'problems-with-email' },
      { label: 'Reset password (for email account)', id: 'reset-password' },
      { label: 'Spam', id: 'spam' },
      { label: 'Sync Issues', id: 'sync-issues' },
    ],
  },
  option3: {
    buttons: [
      { label: 'Backing up to external drive', id: 'backing-up-external-drive' },
      { label: 'Cloud Storage', id: 'cloud-storage' },
      { label: 'Data loss', id: 'data-loss' },
      { label: 'Data transfer', id: 'data-transfer' },
      { label: 'Recover data', id: 'recover-data' },
      { label: 'Removing files', id: 'removing-files' },
      { label: 'Storage issues/freeing up space', id: 'storage-issues' },
      { label: 'Upgrade drive', id: 'upgrade-drive' },
      {
        label: 'Working with files',
        id: 'working-with-files',
        tooltip:
          'We can show you how to organise files but we cannot help using Office/Word/Excel etc',
      },
    ],
  },
  option4: {
    buttons: [
      { label: 'Accessibility help', id: 'accessibility' },
      { label: 'Connection problems', id: 'connection-problems' },
      { label: 'Device not powering on or booting up', id: 'device-not-powering' },
      { label: 'External drive connection issues', id: 'external-drive' },
      { label: 'Help setting up new device', id: 'help-new-device' },
      { label: 'Internal drive connection issues', id: 'internal-drive' },
      { label: 'Keyboard, mouse or trackpad issues', id: 'keyboard-mouse' },
      { label: 'Locked out of PC', id: 'locked-out' },
      { label: 'Microphone or Webcam issues', id: 'microphone' },
      { label: 'No Sound', id: 'no-sound' },
      { label: 'Performance issues', id: 'performance-issues' },
      { label: 'Physical damage to the device', id: 'physical-damage' },
      { label: 'Screen & Display issues', id: 'screen-display' },
      {
        label: 'Slow computers',
        id: 'slow-computers',
        tooltip:
          '<a class="sb-link-primary" target="_blank" href="https://www.which.co.uk/reviews/laptops/article/how-to-speed-up-a-slow-computer-aKcRy8S1oufr">How to speed up a laptop or computer</a>',
        tooltipTitle: 'Link',
      },
      { label: 'USB stick connection issues', id: 'usb-stick' },
    ],
  },
  option5: {
    buttons: [
      { label: 'Audio Equipment Battery Issues', id: 'audio' },
      { label: 'Cameras', id: 'cameras' },
      { label: 'Cordless Phones', id: 'cordless-phones' },
      { label: "Desktop or All-In-One PC's", id: 'desktops' },
      { label: 'DVD/Video Recorders', id: 'dvd-recorders' },
      { label: 'E-Book Readers', id: 'e-book' },
      { label: 'Games Consoles', id: 'games-consoles' },
      { label: 'Hardware faults', id: 'hardware-faults' },
      { label: 'Laptop issues', id: 'laptop-issues' },
      { label: 'Motor Vehicle Software', id: 'motor-vehicle-software' },
      { label: 'Printer issues', id: 'printer-issues' },
      { label: 'Smartphones', id: 'smartphones' },
      { label: 'Smart Tech', id: 'smart-tech' },
      { label: 'Smart TV issues', id: 'smart-tv-issues' },
      { label: 'Tablet issues', id: 'tablet-issues' },
      { label: 'TV Boxes', id: 'tv-boxes' },
    ],
  },
  option6: {
    buttons: [
      {
        label: 'Choosing broadband',
        id: 'choosing-broadband',
        tooltip:
          '<a class="sb-link-primary" target="_blank" href="https://www.which.co.uk/reviews/broadband/article/broadband-provider-reviews/best-broadband-providers-aIIx34f51krz">Best and worst broadband providers</a>',
        tooltipTitle: 'Link',
      },
      { label: 'Connection problems', id: 'connection-problems' },
      { label: 'Extending wifi signal', id: 'extending-wifi' },
      { label: 'Home networking', id: 'home-networking' },
      { label: 'Network configuration', id: 'network-configuration' },
      { label: 'Router / Access point setup', id: 'router-setup' },
      { label: 'Router security', id: 'router-security' },
      {
        label: 'Slow internet',
        id: 'slow-internet',
        tooltip: 'You are best to speak to your internet service provider',
        tooltipTitle: 'Advice',
      },
      { label: 'VPN issues', id: 'vpn-issues' },
    ],
  },
  option7: {
    buttons: [
      { label: 'Cartridge / ink problems', id: 'cartridge-problems' },
      { label: 'Error messages', id: 'error-messages' },
      { label: 'Misaligned or incorrect print-outs', id: 'misaligned-incorrect-printouts' },
      { label: 'Paper Jam', id: 'paper-jam' },
      { label: 'Printer apps', id: 'printer-apps' },
      { label: 'Printer drivers', id: 'printer-drivers' },
      { label: 'Printers (over 10 yrs old)', id: 'printers-10' },
      { label: 'Setting up a printer', id: 'setting-up-printer' },
      { label: 'Software Issues', id: 'software-issues' },
      { label: 'Unable to connect printer to network/wi-fi', id: 'unable-to-connect' },
      { label: 'Unable to print or scan', id: 'unable-to-print' },
    ],
  },
  option8: {
    buttons: [
      { label: 'Advice or help with manufacture security updates', id: 'advice-or-help' },
      { label: 'Antivirus and security software', id: 'antivirus' },
      { label: 'Online Privacy', id: 'online-privacy' },
      { label: 'Pop-ups or adverts', id: 'pop-ups' },
      {
        label: 'Potential hack',
        id: 'potential-hack',
        tooltip:
          'If you think your device has been hacked, please disconnect it from the internet immediately.',
      },
      { label: 'Potential Virus', id: 'potential-virus' },
      { label: 'Scams, Incl. Phishing & scam emails', id: 'scams' },
      { label: 'Security best practices', id: 'Security' },
      {
        label: 'Web browser has been hijacked',
        id: 'web-browser-hijacked',
        tooltip:
          'If you think your device has been hacked, please disconnect it from the internet immediately.',
      },
    ],
  },
  option9: {
    buttons: [
      {
        label: 'Advanced programming',
        id: 'advanced-programming',
      },
      {
        label: 'Apps',
        id: 'apps',
        tooltip:
          "We can't help with you how to use a specific program or app or teach you how a certain app works but we can help with the installation and/or removal.",
      },
      { label: 'Drivers', id: 'drivers' },
      {
        label: 'Microsoft Office 2016 above',
        id: 'microsoft-office-2016-above',
        tooltip:
          'We can show you how to organise files but we cannot help using Office/Word/Excel etc',
      },
      { label: 'Microsoft Office below 2016', id: 'microsoft-office-2016-below' },
      { label: 'Operating system problems', id: 'operating-system' },
      {
        label: 'Password managers',
        id: 'password-managers',
        tooltip:
          "We can't help with you how to use a specific program or app or teach you how a certain app works but we can help with the installation and/or removal.",
      },
      { label: 'Software installation', id: 'software-installation' },
      { label: 'Software removal', id: 'software-removal' },
      { label: 'Updating operating system', id: 'updating-operating-system' },
      {
        label: 'Virtualisation',
        id: 'virtualisation',
        tooltip:
          "We can't help with you how to use a specific program or app or teach you how a certain app works but we can help with the installation and/or removal.",
      },
      { label: 'Web browser Issues', id: 'web-browser-issues' },
    ],
  },
}
