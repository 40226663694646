import type { FunctionComponent } from 'react'
import React, { useContext, useState } from 'react'

import { ImpactSurveyPage } from '../../../../shared/pages/ImpactSurveyPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { RadiosSelected } from '../../../../shared/types/toolTypes'
import { FollowUpFlow } from '../../pageFlowData'
import { getStepData } from '../../utils/getStepData'
import { reducedStressData } from './data'

export const ReducedStress: FunctionComponent = () => {
  const { dispatch, state } = useContext(ToolContext)
  const {
    answers: { reducedStress, moreAboutExperience },
    user: { toolID },
  } = state

  const [radiosSelected, setRadiosSelected] = useState<RadiosSelected>(reducedStress)
  const [textareaValue, setTextareaValue] = useState(moreAboutExperience)
  const { radioData, textareaData } = reducedStressData
  const { toolName, radioExperience, textareaExperience } = getStepData(toolID)

  const radioQuestion = `Do you feel that using the Which? ${toolName} tool reduced any worry or stress you may have been experiencing in making ${radioExperience}?`
  const radioQuestionData = [{ ...radioData, title: radioQuestion }]
  const textareaQuestion = `You can use this space if you'd like to tell us more about your experience of ${textareaExperience} and how our tool helped or did not help you`
  const textareaQuestionData = [
    { ...textareaData, title: textareaQuestion, defaultValue: textareaValue[0]?.userInput || '' },
  ]

  const handleDispatch = () => {
    dispatch({ type: 'UPDATE_REDUCED_STRESS', data: radiosSelected })
    dispatch({ type: 'UPDATE_MORE_ABOUT_EXPERIENCE', data: textareaValue })
    dispatch({ type: 'UPDATE_STEP', data: FollowUpFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: FollowUpFlow.PROGRESS_VALUE })
  }

  const handleSubmit = (validationPassed: boolean) => {
    if (validationPassed) {
      handleDispatch()
    }
  }

  return (
    <ImpactSurveyPage
      handleSubmit={handleSubmit}
      nextButtonText="Next"
      radioQuestions={radioQuestionData}
      radiosSelected={radiosSelected || []}
      setRadiosSelected={setRadiosSelected}
      setTextareaData={setTextareaValue}
      textareaData={textareaValue}
      textareaQuestions={textareaQuestionData}
    />
  )
}
