import type { InfoBoxProps } from '../../../../../shared/types/toolTypes'
import styles from '../../../../../shared/utils/renderInfoBox/renderInfoBox.module.scss'

export const data = {
  buttons: [
    {
      label: 'Supermarkets',
      id: 'supermarkets',
    },
    {
      label: 'Convenience stores',
      id: 'convenience-stores',
    },
  ],
  title: 'Do you do most of your food shop at supermarkets or convenience stores?',
  infoBox: {
    heading: 'Why are we asking you about convenience stores?',
    borderColour: 'blue',
  } as InfoBoxProps,
  infoBoxParagraphs: [
    {
      classname: `${styles.renderInfoBoxParagraphs}`,
      text: 'Our research shows prices are often higher in convenience stores (small stores that open for longer hours and offer a small variety of goods e.g Londis, Budgens, Tesco Express).',
    },
  ],
  imgObj: {
    alt: 'A family putting groceries into a shopping trolley',
    src: 'https://media.product.which.co.uk/prod/images/original/36e044322653-convenience.jpg',
  },
}
