import { constructDate } from '../../../../shared/components/DatePicker/formatDate/constructDate'
import type { UserPropsWithAddress } from '../../../../shared/types/toolTypes'
import type { homeImprovementToolState } from '../../state/homeImprovementToolState'
import styles from './SubmitSuccessful.module.scss'

export const composeEmailBody = ({
  work,
  user,
  autoEmailer = false,
}: {
  work: typeof homeImprovementToolState.work
  user: UserPropsWithAddress
  autoEmailer?: boolean
}) => {
  const { firstName, lastName, address } = user
  const { date, name, number, problem } = work

  let addressArr = [
    { text: `${address?.addressLine1}` },
    {
      text: `${address?.addressLine2 ? `${address.addressLine2}` : ''}`,
    },
    { text: `${address?.townCity}` },
    { text: `${address?.county ? `${address.county}` : ''}` },
    { text: `${address?.postcode}<br />\r\n` },
  ]

  const whatAct = () => {
    if (date && new Date(date) < new Date('2015-10-01')) {
      return 'Supply of Goods and Services act'
    }
    return 'consumer rights act'
  }

  let body = [
    { text: `Dear ${name},<br />\r\n` },
    { text: number ? `Reference: ${number}<br />\r\n` : '' },
    {
      text: `On ${
        date && constructDate(new Date(date))
      } you carried out the following work at the above property: ${problem}<br />\r\n`,
    },
    {
      text: `The ${whatAct()} states that the contract I have with you contains implied terms that;<br />\r\n`,
    },
    {
      text: 'The work has to be carried out using reasonable skill and care<br />\r\n',
      className: `${styles.submitSuccessfulBullet}`,
    },
    {
      text: 'Any goods supplied, or materials used must be of satisfactory quality, fit for their purpose and as described<br />\r\n',
      className: `${styles.submitSuccessfulBullet}`,
    },
    {
      text: 'For the reasons detailed above, you are in breach of contract and accordingly I would request that you contact me to make arrangements to carry out the necessary remedial work.\r\n',
    },
    { text: 'I look forward to hearing from you in the next seven days.<br />' },
    { text: '<br />\r\n' },
    { text: 'Yours faithfully,<br />\r\n' },
    { text: `${firstName} ${lastName}` },
  ]

  addressArr = addressArr.filter(String)
  body = autoEmailer ? addressArr.concat(body) : body
  body = body.filter(String)

  return {
    label: 'Preview of complaint email',
    address: addressArr,
    body,
  }
}
