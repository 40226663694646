import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { JobTypeFlow, WhichTraderFlow, WorkTraderFlow } from '../../pageFlowData'
import { workTraderData } from './data'

export const WorkTrader: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    work: { trader },
  } = state

  const handleStepChoice = (id: string) => {
    if (id === 'yes') {
      return { progress: WhichTraderFlow.PROGRESS_VALUE, step: WhichTraderFlow.STEP }
    }
    return { progress: JobTypeFlow.PROGRESS_VALUE, step: JobTypeFlow.STEP }
  }

  const handleTracking = (id: string) => {
    const trackingAnswer = `question | ${WorkTraderFlow.VIEW_NAME} | ${id}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (id: string) => {
    const { progress, step } = handleStepChoice(id)

    dispatch({ type: 'UPDATE_TRADER', data: id })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleSubmit = (id: string) => {
    handleTracking(id)
    handleDispatch(id)
  }

  const { buttons, imgObj, title } = workTraderData

  return (
    <div>
      <PillButtonPage
        buttons={buttons}
        defaultValue={trader ? trader : ''}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        title={title}
      />
    </div>
  )
}
