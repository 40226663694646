import React from 'react'

import { ToolFeedbackAudiencePage } from '../../../shared/pages/ToolFeedbackAudiencePage'
import { toolFeedbackAudienceData } from '../../../shared/pages/ToolFeedbackAudiencePage/data'
import { ToolFeedbackPage } from '../../../shared/pages/ToolFeedbackPage'
import { toolFeedbackData } from '../../../shared/pages/ToolFeedbackPage/data/parkingTicketData'
import { ToolFeedbackThankYouPage } from '../../../shared/pages/ToolFeedbackThankYouPage'
import { CompanyName } from '../PageComponents/CompanyName'
import { ContactDetails } from '../PageComponents/ContactDetails'
import { LimitedOptions } from '../PageComponents/LimitedOptions'
import { PurchaseMethod } from '../PageComponents/PurchaseMethod'
import { SubmitSuccessful } from '../PageComponents/SubmitSuccessful'
import { SupportingEvidence } from '../PageComponents/SupportingEvidence'
import { TicketDate } from '../PageComponents/TicketDate'
import { TicketDiscount } from '../PageComponents/TicketDiscount'
import { TicketLocation } from '../PageComponents/TicketLocation'
import { TicketNumber } from '../PageComponents/TicketNumber'
import { TicketPurchased } from '../PageComponents/TicketPurchased'
import { TicketReason } from '../PageComponents/TicketReason'
import { VehicleReg } from '../PageComponents/VehicleReg'
import { WhatHappened } from '../PageComponents/WhatHappened'
import { WhoIssued } from '../PageComponents/WhoIssued'
import {
  CompanyNameFlow,
  ContactDetailsFlow,
  IssueDescriptionFlow,
  LimitedOptionsFlow,
  PurchaseMethodFlow,
  SubmitSuccessfulFlow,
  SupportingEvidenceFlow,
  TicketDateFlow,
  TicketDiscountFlow,
  TicketLocationFlow,
  TicketNumberFlow,
  TicketPurchasedFlow,
  TicketReasonFlow,
  ToolFeedbackAudienceFlow,
  ToolFeedbackFlow,
  ToolFeedbackThankYouFlow,
  VehicleRegFlow,
  WhoIssuedFlow,
} from '../pageFlowData'

export const useRenderStep = (step: number, CRFooter: React.ReactNode) => {
  switch (step) {
    case WhoIssuedFlow.STEP:
      return <WhoIssued />
    case CompanyNameFlow.STEP:
      return <CompanyName />
    case VehicleRegFlow.STEP:
      return <VehicleReg />
    case TicketPurchasedFlow.STEP:
      return <TicketPurchased />
    case PurchaseMethodFlow.STEP:
      return <PurchaseMethod />
    case TicketLocationFlow.STEP:
      return <TicketLocation />
    case TicketDiscountFlow.STEP:
      return <TicketDiscount />
    case SupportingEvidenceFlow.STEP:
      return <SupportingEvidence />
    case TicketDateFlow.STEP:
      return <TicketDate />
    case IssueDescriptionFlow.STEP:
      return <WhatHappened />
    case TicketReasonFlow.STEP:
      return <TicketReason />
    case TicketNumberFlow.STEP:
      return <TicketNumber />
    case ContactDetailsFlow.STEP:
      return <ContactDetails />
    case LimitedOptionsFlow.STEP:
      return <LimitedOptions />
    case SubmitSuccessfulFlow.STEP:
      return <SubmitSuccessful CRFooter={CRFooter} />
    case ToolFeedbackFlow.STEP:
      return (
        <ToolFeedbackPage
          pageData={toolFeedbackData}
          toolFeedbackAudienceFlowData={ToolFeedbackAudienceFlow}
          toolFeedbackFlowData={ToolFeedbackFlow}
          toolName="ParkingTicket"
        />
      )
    case ToolFeedbackThankYouFlow.STEP:
      return <ToolFeedbackThankYouPage toolFeedbackThankYouFlowData={ToolFeedbackThankYouFlow} />
    case ToolFeedbackAudienceFlow.STEP:
      return (
        <ToolFeedbackAudiencePage
          pageData={toolFeedbackAudienceData}
          toolFeedbackAudienceFlowData={ToolFeedbackAudienceFlow}
          toolFeedbackThankYouFlowData={ToolFeedbackThankYouFlow}
          toolName="ParkingTicket"
        />
      )
    default:
      return <WhoIssued />
  }
}
