import type { FunctionComponent } from 'react'
import React from 'react'
import { MultiLinkBlock, Picture, Typography } from '@which/seatbelt'

import { formatToolName } from '../../utils/formatToolName'
import { generalDataLayer } from '../../utils/tracking'
import styles from './MultiLinkCard.module.scss'

export const MultiLinkCard: FunctionComponent<Props> = ({
  className,
  image,
  imageSources,
  imageAlt = '',
  links,
  title,
  toolName,
  savingsText = '',
  ...rest
}) => {
  const linkClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.target as Element
    const linkEl = target.closest('a')
    if (!linkEl) {
      return
    }

    const { href, textContent } = linkEl
    generalDataLayer(formatToolName(toolName), `onward journey | ${textContent} | ${href}`)
  }

  return (
    <article className={className} data-testid="multi-link-card-wrapper" {...rest}>
      {image && (
        <Picture
          alt={imageAlt}
          aspectRatioMain="two-to-one"
          lazyLoad={false}
          sources={imageSources}
          src={image}
        />
      )}

      <div className={styles.multiLinkCardSavings}>
        <Typography tag="h2" textStyle="title-400">
          {title}
        </Typography>

        <Typography
          className={styles.multiLinkCardSavingsText}
          dangerouslySetInnerHTML={{ __html: savingsText }}
          tag="p"
          textStyle="body"
        />
      </div>

      <MultiLinkBlock
        className={styles.multiLinkCardBlock}
        links={links}
        onClick={linkClickHandler}
        title={''}
      />
    </article>
  )
}

export type Props = {
  className?: string
  image?: string
  imageSources?: ImageSource[]
  imageAlt?: string
  links: Links
  title: string
  toolName: string
  savingsText?: string
}

export type Links = {
  text: string
  href: string
}[]

export type ImageSource = {
  srcset?: string
  media?: string
  sizes?: string
  type?: string
}
