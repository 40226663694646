import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { Typography } from '@which/seatbelt'

import classNames from 'classnames'

import { FormEntryItem } from '../../components/FormEntryItem'
import { ImageWithQuestion } from '../../components/ImageWithQuestion'
import { NextButton } from '../../components/NextButton'
import globalStyles from '../../styles/GlobalStyles.module.scss'
import type { FormEntryPageProps } from '../../types/toolTypes'
import styles from './FormEntryPage.module.scss'

export const FormEntryPage = forwardRef(
  (
    {
      buttonText,
      defaultValue,
      displayText = '',
      fieldData,
      handleSubmit,
      id,
      imgObj,
      isFocused,
      isFullWidth,
      placeholderText,
      testId,
      title,
      optional,
      type = 'text',
      handleOnChange,
      standFirst,
    }: FormEntryPageProps,
    ref
  ) => {
    const FormEntryInputRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(ref, () => ({
      formEntryInput: FormEntryInputRef.current,
    }))

    return (
      <div data-testid={testId}>
        <ImageWithQuestion
          bottomMargin={standFirst && globalStyles.spacing25}
          image={imgObj}
          optionalTag={optional}
          title={title}
        />
        {standFirst && (
          <Typography
            className={classNames(
              globalStyles.maxWidth,
              globalStyles.spacing25,
              styles.formEntryPageStandFirst
            )}
            dangerouslySetInnerHTML={{ __html: standFirst }}
            textStyle="body"
          />
        )}
        <FormEntryItem
          displayText={displayText}
          enterKeyHandler={handleSubmit}
          fieldData={fieldData}
          handleOnChange={handleOnChange}
          id={id}
          isFocused={isFocused}
          isFullWidth={isFullWidth}
          optional={optional}
          placeholderText={placeholderText}
          ref={FormEntryInputRef}
          srLabel={title}
          stateValue={defaultValue}
          type={type}
        />
        <NextButton buttonText={buttonText} onClick={handleSubmit} />
      </div>
    )
  }
)
