import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../../shared/state/appContext'
import globalStyles from '../../../../../shared/styles/GlobalStyles.module.scss'
import { trackAppointmentBookingSelection } from '../../../../../shared/utils/tracking/tracking'
import {
  BuyingQueryFlow,
  BuyingTopicFlow,
  ContractChoiceFlow,
  DescribeCaseFlow,
  HolidayBookingFlow,
  HomeQueryFlow,
  LimitedOptionsFlow,
} from '../../../pageFlowData'
import { buyingTopicData } from './data'

export const BuyingTopic: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    buying: { buyingTopic },
    triage: { type },
  } = state

  const handleTracking = (id: string, index: number) => {
    trackAppointmentBookingSelection(type, BuyingTopicFlow.VIEW_NAME, { id, index })
  }

  const chooseStep = (id: string) => {
    switch (id) {
      case 'small-claims':
      case 'professional-negligence':
      case 'pre-contract-questions':
      case 'vehicle':
      case 'holiday-accommodation':
      case 'dispute-with-trader':
      case 'issues-with-events':
        return ContractChoiceFlow
      case 'subscriptions':
      case 'new-build-issues':
        return DescribeCaseFlow
      case 'local-council':
      case 'discrimination':
      case 'high-court':
      case 'financial-matters':
        return LimitedOptionsFlow
      case 'utilities':
        return HomeQueryFlow
      case 'pre-contract-advice':
        return HolidayBookingFlow
      default:
        return BuyingQueryFlow
    }
  }

  const handleWhatDispatch = (id: string) => {
    switch (id) {
      case 'dispute-with-trader':
      case 'new-build-issues':
      case 'utilities':
        dispatch({ type: 'UPDATE_HOME_TOPIC', data: id })
        dispatch({ type: 'UPDATE_LEGAL_TOPIC', data: 'home' })
        break
      case 'holiday-accommodation':
      case 'pre-contract-advice':
        dispatch({ type: 'UPDATE_HOLIDAY_TOPIC', data: id })
        dispatch({ type: 'UPDATE_LEGAL_TOPIC', data: 'holiday' })
        break
      case 'vehicle':
        dispatch({ type: 'UPDATE_CARS_TOPIC', data: id })
        dispatch({ type: 'UPDATE_LEGAL_TOPIC', data: 'cars' })
        break
      default:
        dispatch({ type: 'UPDATE_BUYING_TOPIC', data: id })
        dispatch({ type: 'UPDATE_LEGAL_TOPIC', data: 'buying' })
    }
  }

  const handleDispatch = (id: string) => {
    handleWhatDispatch(id)
    const step = chooseStep(id)
    dispatch({ type: 'UPDATE_STEP', data: step.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: step.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string, index: number) => {
    handleTracking(id, index)
    handleDispatch(id)
  }

  const { buttons, imgObj, title } = buyingTopicData

  return (
    <div className={globalStyles.fullWidthButton}>
      <PillButtonPage
        buttons={buttons}
        defaultValue={buyingTopic}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        title={title}
      />
    </div>
  )
}
