import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { constructDate } from '../../../../shared/components/DatePicker/formatDate/constructDate'
import { DatePickerPage } from '../../../../shared/pages/DatePickerPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { DatePickerInputRef } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getDatePickerValidationErrorMessage } from '../../../../shared/utils/getDatePickerValidationErrorMessage/getDatePickerValidationErrorMessage'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { GuaranteedSpeedFlow, WhenPurchasedFlow } from '../../pageFlowData'
import { whenPurchasedData } from './data'

export const WhenPurchased: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    issue: { whenPurchased },
  } = state

  const WhenPurchasedInput = useRef<DatePickerInputRef>(null)

  const [datePickerError, setDatePickerError] = useState('')

  /* istanbul ignore next */
  const getValue = () => {
    return cleanText(WhenPurchasedInput?.current?.datePickerInput?.value || whenPurchased)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_WHEN_PURCHASED', data: value })
    dispatch({ type: 'UPDATE_STEP', data: GuaranteedSpeedFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: GuaranteedSpeedFlow.PROGRESS_VALUE })
  }

  /* istanbul ignore next */
  const handleInputChange = (value: string) => {
    /* istanbul ignore next */
    dispatch({ type: 'UPDATE_WHEN_PURCHASED', data: value })
  }

  const handleSubmit = () => {
    setDatePickerError('')
    const dateInputValue: string = getValue()
    const errorMessage = getDatePickerValidationErrorMessage(dateInputValue)
    /* istanbul ignore next */
    if (errorMessage) {
      return setDatePickerError(errorMessage)
    }
    const trackingAnswer = `question | ${WhenPurchasedFlow.VIEW_NAME} | ${dateInputValue}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
    handleDispatch(dateInputValue)
  }

  const { title, imgObj } = whenPurchasedData

  return (
    <DatePickerPage
      buttonText="Next"
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      imgObj={imgObj}
      isFocused
      max={constructDate(new Date(), false)}
      newErrorMessage={datePickerError}
      purchasedDate={whenPurchased}
      ref={WhenPurchasedInput}
      renderError={datePickerError !== ''}
      testId="when-purchased"
      title={title}
    />
  )
}
