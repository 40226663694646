import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { TextAreaPage } from '../../../../shared/pages/TextAreaPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryTextAreaRef } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { validateCharCount } from '../../../../shared/utils/validateCharCount'
import { BookingReferenceFlow, CompanyContactFlow, HasExpensesFlow } from '../../pageFlowData'
import { companyContactData } from './data'

export const CompanyContact: FunctionComponent = () => {
  const {
    imgObj,
    nonPackageTitle,
    packageTitle,
    placeholderText,
    packageDescriptiveText,
    nonPackageDescriptiveText,
    note,
    packageNote,
  } = companyContactData

  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    holiday: { type },
  } = state

  const [CompanyContactTextAreaValid, setCompanyContactTextAreaValid] = useState<boolean>(true)

  const isPackage = type === 'package' ? true : false

  const CompanyContactTextAreaRef = useRef<FormEntryTextAreaRef>(null)

  const handleCompanyContactChange = () => {
    const value = getCleanValue(CompanyContactTextAreaRef.current?.formEntryTextarea?.value)
    dispatch({ type: 'UPDATE_COMPANY_CONTACT', data: value })

    const isValid: boolean = validateCharCount(value.length, 2500)
    const fieldNotEmpty: boolean = isNotEmpty(value)
    if (fieldNotEmpty && isValid) {
      setCompanyContactTextAreaValid(true)
    }
  }

  const handleStepChoice = () => {
    if (isPackage) {
      return { progress: HasExpensesFlow.PROGRESS_VALUE, step: HasExpensesFlow.STEP }
    }

    return { progress: BookingReferenceFlow.PROGRESS_VALUE, step: BookingReferenceFlow.STEP }
  }

  const handleDispatch = () => {
    const { progress, step } = handleStepChoice()

    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${CompanyContactFlow.VIEW_NAME} | PII`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleCompanyContactSubmit = () => {
    const value: string = getCleanValue(CompanyContactTextAreaRef.current?.formEntryTextarea?.value)
    const isValid: boolean = validateCharCount(value.length, 2500)
    const fieldNotEmpty: boolean = isNotEmpty(value)

    if (fieldNotEmpty && !isValid) {
      setCompanyContactTextAreaValid(false)
      return
    }

    setCompanyContactTextAreaValid(true)
    handleTracking()
    handleDispatch()
  }

  return (
    <div data-testid="company-contact">
      <TextAreaPage
        buttonText="Next"
        defaultValue=""
        descriptiveText={isPackage ? packageDescriptiveText : nonPackageDescriptiveText}
        handleChange={handleCompanyContactChange}
        handleSubmit={handleCompanyContactSubmit}
        imgObj={imgObj}
        isFocused
        isInputValid={CompanyContactTextAreaValid}
        maxChars={2500}
        noteBottom={isPackage ? packageNote : ''}
        noteTop={note}
        optional
        placeholderText={placeholderText}
        ref={CompanyContactTextAreaRef}
        title={isPackage ? packageTitle : nonPackageTitle}
      />
    </div>
  )
}
