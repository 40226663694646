export const workTraderData = {
  buttons: [
    {
      label: 'Yes',
      id: 'yes',
    },
    {
      label: 'No',
      id: 'no',
    },
  ],
  title: 'Was the work in question carried out by a Which? trusted trader?',
  imgObj: {
    alt: 'Two builders standing side by side',
    src: 'https://media.product.which.co.uk/prod/images/original/08a885af41fb-step2.jpg',
  },
}
