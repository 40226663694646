import type { PageFlowProps } from '../../../shared/types/toolTypes'

export const RegNumberFlow: PageFlowProps = {
  PROGRESS_VALUE: 0,
  STEP: 1,
  VIEW_NAME: 'reg-number',
  REDUCERS: [''],
  MILESTONE: 'milestone | start',
}

export const CorrectCarFlow: PageFlowProps = {
  PROGRESS_VALUE: 20,
  STEP: 2,
  VIEW_NAME: 'correct-car',
  REDUCERS: ['UPDATE_CORRECT_CAR'],
  MILESTONE: '',
}

export const WhereBoughtFlow: PageFlowProps = {
  PROGRESS_VALUE: 30,
  STEP: 3,
  VIEW_NAME: 'where-bought',
  REDUCERS: ['UPDATE_WHERE_BOUGHT'],
  MILESTONE: '',
}

export const WhenPurchasedFlow: PageFlowProps = {
  PROGRESS_VALUE: 40,
  STEP: 4,
  VIEW_NAME: 'when-purchased',
  REDUCERS: ['UPDATE_WHEN_PURCHASED'],
  MILESTONE: '',
}

export const OnFinanceFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 5,
  VIEW_NAME: 'on-finance',
  REDUCERS: ['UPDATE_ON_FINANCE'],
  MILESTONE: '',
}

export const DealerOfferedSolutionFlow: PageFlowProps = {
  PROGRESS_VALUE: 55,
  STEP: 6,
  VIEW_NAME: 'dealer-offered-solution',
  REDUCERS: ['UPDATE_DEALER_OFFERED_SOLUTION'],
  MILESTONE: '',
}

export const WhichDealerFlow: PageFlowProps = {
  PROGRESS_VALUE: 60,
  STEP: 7,
  VIEW_NAME: 'which-dealer',
  REDUCERS: ['UPDATE_WHICH_DEALER'],
  MILESTONE: '',
}

export const HowMuchFlow: PageFlowProps = {
  PROGRESS_VALUE: 70,
  STEP: 8,
  VIEW_NAME: 'how-much',
  REDUCERS: ['UPDATE_HOW_MUCH'],
  MILESTONE: '',
}

export const WhatsWrongFlow: PageFlowProps = {
  PROGRESS_VALUE: 80,
  STEP: 9,
  VIEW_NAME: 'whats-wrong',
  REDUCERS: ['UPDATE_WHATS_WRONG'],
  MILESTONE: '',
}

export const LikeToDoFlow: PageFlowProps = {
  PROGRESS_VALUE: 90,
  STEP: 10,
  VIEW_NAME: 'like-to-do',
  REDUCERS: ['UPDATE_LIKE_TO_DO'],
  MILESTONE: '',
}

export const ContactDetailsFlow: PageFlowProps = {
  PROGRESS_VALUE: 95,
  STEP: 11,
  VIEW_NAME: 'contact-details',
  REDUCERS: [''],
  MILESTONE: '',
}

export const LimitedOptionsFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 12,
  VIEW_NAME: 'limited-options',
  REDUCERS: [''],
  MILESTONE: '',
}

export const SubmitSuccessfulFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 13,
  VIEW_NAME: 'journey-complete',
  REDUCERS: ['UPDATE_EMAIL_SENT'],
  MILESTONE: 'milestone | end | letter sent',
}

export const ToolFeedbackFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 14,
  VIEW_NAME: 'feedback-form',
  REDUCERS: [''],
  MILESTONE: 'milestone | feedback start',
}

export const ToolFeedbackAudienceFlow: PageFlowProps = {
  PROGRESS_VALUE: 90,
  STEP: 15,
  VIEW_NAME: 'feedback-audience-form',
  REDUCERS: [''],
  MILESTONE: '',
}

export const ToolFeedbackThankYouFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 16,
  VIEW_NAME: 'feedback-form-thankyou',
  REDUCERS: [''],
  MILESTONE: '',
}

export const AllFlows = [
  RegNumberFlow,
  CorrectCarFlow,
  WhereBoughtFlow,
  WhenPurchasedFlow,
  OnFinanceFlow,
  WhichDealerFlow,
  HowMuchFlow,
  WhatsWrongFlow,
  LikeToDoFlow,
  ContactDetailsFlow,
  LimitedOptionsFlow,
  SubmitSuccessfulFlow,
  ToolFeedbackFlow,
  ToolFeedbackAudienceFlow,
  ToolFeedbackThankYouFlow,
]
