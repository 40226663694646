import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'
import { Button, ChevronLeftIcon } from '@which/seatbelt'

import classnames from 'classnames'

import { ToolContext } from '../../state/appContext'
import type { BackButtonType } from '../../types/toolTypes'
import { checkSessionStorageExists } from '../../utils/checkSessionStorageExists'
import { formatToolName } from '../../utils/formatToolName'
import { getSessionStorageItem } from '../../utils/getSessionStorageItem'
import { storeToolState } from '../../utils/storeToolState'
import { generalDataLayer } from '../../utils/tracking'
import styles from './BackButton.module.scss'

export const BackButton: FunctionComponent<BackButtonType> = (props) => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { userJourney, journeyFlow, step },
  } = state
  // primary refers to Seatbelt's Primary button style
  const {
    allFlows,
    className,
    text = 'Back',
    primary = false,
    secondary = false,
    setIsForward,
    toolName,
  } = props

  const chooseStep = (position: number, writeLetter?: boolean) => {
    if (writeLetter) {
      const newUserJourney = journeyFlow.writeLetter.userJourney
      return newUserJourney[newUserJourney.length - position]
    }
    const previousStep = userJourney[userJourney.length - position]
    return previousStep
  }

  const setBackProgress = () => {
    if (checkSessionStorageExists(`${toolName}Progress`)) {
      const sessionProgress = getSessionStorageItem(`${toolName}Progress`)
      const valueToRemove = sessionProgress.pop()
      const sessionProgressLength = sessionProgress.length - 1
      storeToolState(`${toolName}Progress`, sessionProgress)
      storeToolState(`${toolName}BackProgress`, valueToRemove)

      return { progress: sessionProgress[sessionProgressLength] }
    }

    return { progress: 0 }
  }

  const handleClick = () => {
    if (toolName === 'MyMoneyHealthCheck') {
      window.history.back()
      return
    }

    const pageData = allFlows.find((flowData) => flowData.STEP === chooseStep(1))
    // No need to dispatch anything if it's the first step
    if (pageData?.STEP !== 0) {
      generalDataLayer(formatToolName(toolName), 'navigation | back', false)

      const stateToCleanse = pageData?.REDUCERS
      stateToCleanse?.map((cleanseState) => {
        const defaultStateValue = pageData?.STATE_VALUE ? pageData?.STATE_VALUE[cleanseState] : null
        cleanseState && dispatch({ type: cleanseState, data: defaultStateValue })
      })

      dispatch({ type: 'UPDATE_ISFORWARD', data: false })

      const { progress } = setBackProgress()

      // checks for the WriteLetter modules journeyFlow. *The WriteLetter module must be step 100*
      if (
        journeyFlow?.writeLetter?.userJourney &&
        journeyFlow.writeLetter.userJourney.length > 1 &&
        step === 100
      ) {
        dispatch({ type: 'UPDATE_WRITELETTER_STEP', data: chooseStep(2, true) })
        dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
        setIsForward?.(false)
        return
      }

      dispatch({ type: 'UPDATE_STEP', data: chooseStep(2) })
      dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
      setIsForward?.(false)
    }
  }

  return (
    <>
      {primary ? (
        <Button data-testid="tool-back-button" onClick={handleClick}>
          {text}
        </Button>
      ) : secondary ? (
        <Button appearance="secondary" data-testid="tool-back-button" onClick={handleClick}>
          {text}
        </Button>
      ) : (
        <button
          className={classnames(styles.backButton, className)}
          data-testid="tool-back-button"
          onClick={handleClick}
        >
          <ChevronLeftIcon className={styles.backButtonIcon} />
          <span className={styles.hoverUnderlineAnimation} style={{ marginLeft: '5px' }}>
            {text}
          </span>
        </button>
      )}
    </>
  )
}
