import type { FunctionComponent } from 'react'
import React from 'react'
import { ButtonLink, ChevronRightIcon, Grid, GridItem, Picture, Typography } from '@which/seatbelt'

import type { MoneyHelplineProps } from '../../types/toolTypes'
import styles from './MoneyHelpline.module.scss'

export const MoneyHelpline: FunctionComponent<MoneyHelplineProps> = ({
  title,
  standfirst,
  buttonText,
  buttonLink,
  bottomText,
  image,
  backgroundColour,
}) => {
  return (
    <Grid className={styles.moneyHelpline} data-testid="money-helpline">
      <GridItem
        className={styles.moneyHelplineContent}
        columnStart={{ medium: 2, large: 2 }}
        span={{ medium: 7, large: 7 }}
        style={{ backgroundColor: backgroundColour }}
      >
        <Typography className={styles.moneyHelplineTitle} tag="h2" textStyle="title-600">
          {title}
        </Typography>
        <Typography
          className={styles.moneyHelplineStandfirst}
          dangerouslySetInnerHTML={{ __html: standfirst }}
          textStyle="body"
        />
        <ButtonLink appearance="secondary" href={buttonLink}>
          <ChevronRightIcon />
          {buttonText}
        </ButtonLink>
        <Typography
          className={styles.moneyHelplineSmallPrint}
          dangerouslySetInnerHTML={{ __html: bottomText }}
          tag="p"
          textStyle="very-small-print"
        />
      </GridItem>
      <GridItem
        className={styles.moneyHelplineImage}
        columnStart={{ medium: 9, large: 9 }}
        span={{ medium: 3, large: 3 }}
        style={{ backgroundColor: backgroundColour }}
      >
        <Picture
          alt={image.alt}
          aspectRatioMain="four-to-three"
          className={styles.moneyHelplinePicture}
          lazyLoad={false}
          src={image.src}
        />
      </GridItem>
    </Grid>
  )
}
