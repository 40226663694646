import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import globalStyles from '../../../../shared/styles/GlobalStyles.module.scss'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { RenderInfoBox } from '../../../../shared/utils/renderInfoBox'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import {
  LedYouToPackagedBankAccountFlow,
  LimitedOptionsFlow,
  MonthlyFeeFlow,
} from '../../pageFlowData'
import { data } from './data'

export const MonthlyFee: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    account: { monthlyFee },
  } = state

  const handleStepChoice = (value: string) => {
    if (value === 'no') {
      return { progress: LimitedOptionsFlow.PROGRESS_VALUE, step: LimitedOptionsFlow.STEP }
    }
    return {
      progress: LedYouToPackagedBankAccountFlow.PROGRESS_VALUE,
      step: LedYouToPackagedBankAccountFlow.STEP,
    }
  }

  const handleDispatch = (value: string) => {
    const { progress, step } = handleStepChoice(value)

    dispatch({ type: 'UPDATE_MONTHLY_FEE', data: value })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${MonthlyFeeFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = (id: string) => {
    handleDispatch(id)
    handleTracking(id)
  }

  const { buttons, imgObj, infoBoxData, title } = data

  return (
    <>
      <div data-testid="monthly-fee">
        <PillButtonPage
          buttons={buttons}
          defaultValue={monthlyFee}
          handleSubmit={handleSubmit}
          imgObj={imgObj}
          title={title}
        />
      </div>
      <div className={globalStyles.spacing40Top}>
        <RenderInfoBox data={infoBoxData} />
      </div>
    </>
  )
}
