import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { FormEntryPage } from '../../../../shared/pages/FormEntryPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryInputRef, SingleInputState } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { WhatProductFlow, WherePurchasedFlow } from '../../pageFlowData'
import { whatProductData } from './data'

export const WhatProduct: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    product: { type, description },
  } = state

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })
  const whatProductInput = useRef<FormEntryInputRef>(null)

  const getValue = () => {
    return cleanText(whatProductInput?.current?.formEntryInput?.value || '')
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${WhatProductFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_PRODUCT_DESCRIPTION', data: value })
    dispatch({ type: 'UPDATE_STEP', data: WherePurchasedFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: WherePurchasedFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    const value = getValue()
    const whatProductHasError = !isNotEmpty(value)

    setInputData({
      renderError: whatProductHasError,
      value: value,
    })

    if (!whatProductHasError) {
      handleTracking(value)
      handleDispatch(value)
    }
  }

  const {
    productData: { placeHolder: productPlaceholder, title: productTitle, imgObj: productImgObj },
    serviceData: { placeHolder: servicePlaceholder, title: serviceTitle, imgObj: serviceImgObj },
  } = whatProductData

  const placeHolderText = type === 'product' ? productPlaceholder : servicePlaceholder
  const title = type === 'product' ? productTitle : serviceTitle
  const imgObj = type === 'product' ? productImgObj : serviceImgObj
  const { renderError, value: errorValue } = inputData

  return (
    <FormEntryPage
      buttonText="Next"
      defaultValue={description}
      fieldData={{
        renderError: renderError,
        value: errorValue,
      }}
      handleSubmit={handleSubmit}
      id="what-product"
      imgObj={imgObj}
      isFocused
      placeholderText={placeHolderText}
      ref={whatProductInput}
      testId="what-product"
      title={title}
    />
  )
}
