import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { DeliveryPermissionFlow, LimitedOptionsFlow, WhichRetailerFlow } from '../../pageFlowData'
import { deliveryPermissionData } from './data'

export const DeliveryPermission: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    order: { deliveryPermission },
  } = state

  const handleStepChoice = (value: string) => {
    if (value === 'no') {
      return { step: WhichRetailerFlow.STEP, progress: WhichRetailerFlow.PROGRESS_VALUE }
    }
    return { step: LimitedOptionsFlow.STEP, progress: LimitedOptionsFlow.PROGRESS_VALUE }
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${DeliveryPermissionFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    const { step, progress } = handleStepChoice(value)

    dispatch({ type: 'UPDATE_DELIVERY_PERMISSION', data: value })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleSubmit = (value: string) => {
    handleTracking(value)
    handleDispatch(value)
  }

  const {
    deliveryPermissionButtons,
    image: { imgSrc, imgAlt },
    title,
  } = deliveryPermissionData

  return (
    <PillButtonPage
      buttons={deliveryPermissionButtons}
      defaultValue={deliveryPermission}
      handleSubmit={handleSubmit}
      imgObj={{
        src: imgSrc,
        alt: imgAlt,
      }}
      title={title}
    />
  )
}
