import type { FunctionComponent } from 'react'
import React, { useContext, useEffect, useState } from 'react'

import { CheckBoxGroupPage } from '../../../../shared/pages/CheckBoxGroupPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { AgeFlow, OutcomeFlow, ReportedToFlow, WhoReportedToFlow } from '../../pageFlowData'
import { reportedToData } from './data'

export const ReportedTo: FunctionComponent = () => {
  const [isInputValid, setInputValid] = useState(true)
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    victim: { reportedTo, lostMoney },
  } = state

  useEffect(() => {
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: ReportedToFlow.PROGRESS_VALUE })
  }, [dispatch])

  useEffect(() => {
    dispatch({ type: 'UPDATE_REPORTED_TO', data: reportedTo })
  }, [dispatch, reportedTo])

  const handleCheckBoxChange = ({ id }: { id?: string }) => {
    const checkboxChange = reportedTo || []
    if (!checkboxChange.includes(id)) {
      checkboxChange.push(id)
    } else {
      checkboxChange.splice(checkboxChange.indexOf(id), 1)
    }
    dispatch({ type: 'UPDATE_REPORTED_TO', data: checkboxChange })
  }

  const handleStepChoice = () => {
    if (reportedTo.includes('other')) {
      return { progress: WhoReportedToFlow.PROGRESS_VALUE, step: WhoReportedToFlow.STEP }
    } else if (
      lostMoney === 'yes' &&
      reportedTo.some(
        (checkboxItem: string) =>
          ['my-bank', 'bank-received-money', 'paypal'].indexOf(checkboxItem) >= 0
      )
    ) {
      return { progress: OutcomeFlow.PROGRESS_VALUE, step: OutcomeFlow.STEP }
    } else {
      return { progress: AgeFlow.PROGRESS_VALUE, step: AgeFlow.STEP }
    }
  }

  const handleAnswerValue = () => reportedTo.join(' | ')

  const handleDispatch = () => {
    const { progress, step } = handleStepChoice()

    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${ReportedToFlow.VIEW_NAME} | ${handleAnswerValue()}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = () => {
    if (reportedTo && reportedTo.length > 0) {
      setInputValid(true)
      handleTracking()
      handleDispatch()
    }
    setInputValid(false)
  }

  const { lostMoneyButtons, notLostMoneyButtons } = reportedToData

  const chooseButtonData = () => {
    return lostMoney === 'yes' ? lostMoneyButtons : notLostMoneyButtons
  }

  const { title, imgObj, optionalText } = reportedToData

  return (
    <CheckBoxGroupPage
      buttonText="Next"
      buttons={chooseButtonData()}
      handleChange={handleCheckBoxChange}
      handleSubmit={handleSubmit}
      imgObj={imgObj}
      isInputValid={isInputValid}
      optionalText={optionalText}
      stateValue={reportedTo}
      title={title}
    />
  )
}
