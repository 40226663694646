import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { AnimationWrapper, Button, Dropdown, Typography } from '@which/seatbelt'

import classnames from 'classnames'

import type { ContactFormProps, FormEntryProps } from '../../types'
import { clearSearch, findAddress } from '../../utils/findAddress'
import { retrieveAddress } from '../../utils/findAddress/retrieveAddress'
import { getDatePickerValidationErrorMessage } from '../../utils/getDatePickerValidationErrorMessage/getDatePickerValidationErrorMessage'
import { renderOptinList } from '../../utils/renderOptinList'
import { DatePicker } from '../DatePicker'
import { constructDate } from '../DatePicker/formatDate/constructDate'
import { ErrorMessage } from '../ErrorMessage'
import { FormEntryItem } from '../FormEntryItem'
import styles from './ContactForm.module.scss'

export const ContactForm = forwardRef(
  (
    {
      optins,
      formItems,
      submitValid,
      stateValue = {
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: '',
        address: {
          postcode: '',
          addressLine1: '',
          addressLine2: '',
          townCity: '',
          county: '',
        },
        date: '',
      },
      addressItems,
      hasSubmittedOnce,
      handleDropdownChange,
      showTerms = false,
    }: ContactFormProps,
    ref
  ) => {
    // Ref setup
    const firstNameRef = useRef<HTMLInputElement>(null)
    const lastNameRef = useRef<HTMLInputElement>(null)
    const emailAddressRef = useRef<HTMLInputElement>(null)
    const phoneNumberRef = useRef<HTMLInputElement>(null)
    const postcodeRef = useRef<HTMLInputElement>(null)
    const addressLine1Ref = useRef<HTMLInputElement>(null)
    const addressLine2Ref = useRef<HTMLInputElement>(null)
    const townCityRef = useRef<HTMLInputElement>(null)
    const countyRef = useRef<HTMLInputElement>(null)
    const addressSearch = useRef<HTMLInputElement>(null)
    const dateRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(ref, () => ({
      firstName: firstNameRef.current,
      lastName: lastNameRef.current,
      emailAddress: emailAddressRef.current,
      phoneNumber: phoneNumberRef.current,
      postcode: postcodeRef.current,
      addressLine1: addressLine1Ref.current,
      addressLine2: addressLine2Ref.current,
      townCity: townCityRef.current,
      county: countyRef.current,
      date: dateRef.current,
    }))

    const [showEmailTerms, setShowEmailTerms] = useState<boolean>(false)
    const [hasAddress, setHasAddress] = useState(false)
    const [showAddressFields, setShowAddressFields] = useState(false)
    const [showAddressSearchFields, setShowAddressSearchFields] = useState(true)
    const [addressSearchError, setAddressSearchError] = useState('')
    const [hasSearched, setHasSearched] = useState(false)
    const [displayError, setDisplayError] = useState(true)
    const [manualEntry, setManualEntry] = useState(false)
    const [hasSubmitted, setHasSubmitted] = useState(hasSubmittedOnce)
    const [dropDownOptions, setDropDownOptions] = useState([{ label: '', value: '', type: '' }])
    const [errorMessage, setErrorMessage] = useState(
      'Please press find address & select from dropdown'
    )

    useEffect(() => {
      setHasSubmitted(hasSubmittedOnce)
    }, [hasSubmittedOnce])

    const handleShowTerms = (e) => {
      e.preventDefault()
      setShowEmailTerms(!showEmailTerms)
    }

    const {
      fNameData,
      lNameData,
      emailData,
      phoneData,
      postcodeData,
      addressLine1Data,
      addressLine2Data,
      townCityData,
      countyData,
      dateData,
    } = submitValid

    const getFormData = (id: string) => {
      switch (id) {
        case 'firstName':
          return { data: fNameData, ref: firstNameRef, stateValue: stateValue.firstName }
        case 'lastName':
          return { data: lNameData, ref: lastNameRef, stateValue: stateValue.lastName }
        case 'email':
          return { data: emailData, ref: emailAddressRef, stateValue: stateValue.emailAddress }
        case 'phoneNumber':
          return { data: phoneData, ref: phoneNumberRef, stateValue: stateValue.phoneNumber }
        case 'postcode':
          return { data: postcodeData, ref: postcodeRef, stateValue: stateValue.address?.postcode }
        case 'addressLine1':
          return {
            data: addressLine1Data,
            ref: addressLine1Ref,
            stateValue: stateValue.address?.addressLine1,
          }
        case 'addressLine2':
          return {
            data: addressLine2Data,
            ref: addressLine2Ref,
            stateValue: stateValue.address?.addressLine2,
          }
        case 'townCity':
          return { data: townCityData, ref: townCityRef, stateValue: stateValue.address?.townCity }
        case 'county':
          return { data: countyData, ref: countyRef, stateValue: stateValue.address?.county }
        case 'date':
          return { data: dateData, ref: dateRef, stateValue: stateValue?.date }
      }
    }

    const handleFindAddress = async (secondSelectedAddress = '') => {
      if (addressSearch.current?.value) {
        setHasSearched(true)
        const options = await findAddress(addressSearch.current?.value, secondSelectedAddress, {
          setHasAddress,
          setAddressSearchError,
          setShowAddressFields,
          setShowAddressSearchFields,
        })
        setHasSubmitted(false)
        setDisplayError(true)

        if (typeof options === 'string') {
          handleFindAddress(options)
        } else {
          setDropDownOptions(options)
        }
      } else {
        setHasSearched(false)
        setHasSubmitted(true)
        setDisplayError(false)
        setErrorMessage('Please enter a postcode')
      }
    }

    const selectAddress = (selectedOption: string) => {
      if (dropDownOptions && selectedOption) {
        const obj = dropDownOptions.find((x) => x.value === selectedOption)

        if (obj && obj.type === 'Postcode') {
          handleFindAddress(obj.value)
        } else {
          /* istanbul ignore next */
          const addressId = obj?.value || ''
          retrieveAddress({
            addressId,
            setAddressSearchError,
            setShowAddressFields,
            setShowAddressSearchFields,
          })
        }
      }
    }

    const renderFormItemList = (formListItems: FormEntryProps[]) =>
      formListItems.map((formItem: FormEntryProps) => {
        const { displayText, id, optional, type, enterKeyHandler, isFocused } = formItem
        const inputData = getFormData(id)
        const isRequiredAddressField =
          id === 'addressLine1' || id === 'townCity' || id === 'postcode'
        if (id === 'date') {
          const shouldRenderError = inputData?.data?.renderError || false
          // istanbul ignore next
          const errorMessage =
            getDatePickerValidationErrorMessage(inputData?.data?.value || '') || ''

          return (
            <div
              className={classnames(styles.contactFormItem, styles.contactFormDate)}
              data-testid="form-item"
              key={displayText}
            >
              <Typography
                className={classnames(styles.contactFormDateText)}
                data-testid={id}
                id={id}
                textStyle="body-intro"
              >
                {displayText}
              </Typography>
              <DatePicker
                /* istanbul ignore next */
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                handleInputChange={/* istanbul ignore next */ () => {}}
                /* istanbul ignore next */
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                handleSubmit={/* istanbul ignore next */ () => {}}
                isFocused={isFocused}
                max={constructDate(new Date(), false)}
                /* istanbul ignore next */
                newErrorMessage={errorMessage}
                // @ts-expect-error
                ref={inputData?.ref}
                renderError={shouldRenderError}
                stateValue={inputData?.data?.value || ''}
                testId={`${id}-input`}
              />
            </div>
          )
        } else {
          return (
            <div
              className={classnames(styles.contactFormItem, {
                [styles.contactFormItemPostcode]: addressItems && id === 'postcode',
              })}
              data-testid="form-item"
              key={displayText}
            >
              <FormEntryItem
                displayText={displayText}
                enterKeyHandler={enterKeyHandler}
                fieldData={{
                  renderError:
                    /* istanbul ignore next */
                    (hasSubmitted && isRequiredAddressField && !hasSearched) ||
                    (hasAddress &&
                      hasSearched &&
                      isRequiredAddressField &&
                      !inputData?.data?.renderError)
                      ? false
                      : inputData?.data?.renderError || false,
                  value: inputData?.data?.value || '',
                }}
                id={id}
                isFocused={isFocused}
                manualEntry={manualEntry}
                margin
                optional={optional}
                ref={inputData?.ref}
                srLabel={displayText}
                stateValue={inputData?.stateValue}
                type={type}
              />
            </div>
          )
        }
      })

    return (
      <>
        {formItems && renderFormItemList(formItems)}
        {addressItems && (
          <>
            <div
              className={classnames({
                [styles.contactFormHide]: !showAddressSearchFields,
              })}
            >
              <div className={styles.contactFormSearchContainer}>
                <div className={styles.contactFormSearch}>
                  <FormEntryItem
                    displayText="Postcode"
                    enterKeyHandler={() => {
                      handleFindAddress()
                    }}
                    fieldData={{
                      renderError: hasSearched,
                      value: addressSearch.current?.value || '',
                      errorMessageText: addressSearchError,
                    }}
                    id="addressSearch"
                    isFocused={false}
                    margin
                    optional={false}
                    ref={addressSearch}
                    srLabel="search"
                    stateValue=""
                    type="text"
                  />
                </div>
                <Button
                  appearance="primary"
                  className={styles.contactFormButtons}
                  data-testid="search-address"
                  onClick={() => {
                    handleFindAddress()
                  }}
                >
                  Find address
                </Button>
              </div>
              {!hasSearched && hasSubmitted && (
                // istanbul ignore next
                <span>
                  <ErrorMessage errorText={errorMessage} styleClasses={styles.contactFormError} />
                </span>
              )}
              <div className={styles.contactFormDropdownContainer}>
                {dropDownOptions && hasAddress && (
                  <Dropdown
                    // istanbul ignore next
                    callback={(e) => {
                      selectAddress(e)
                    }}
                    className={classnames(styles.contactFormSelect, {
                      [styles.contactFormShow]: hasAddress,
                    })}
                    data-testid="address-select-options"
                    defaultValue={'Select Address'}
                    id={'mySelect'}
                    options={dropDownOptions}
                    variant="regular"
                    {...(handleDropdownChange && {
                      onChange: (e) => {
                        handleDropdownChange(e)
                        selectAddress(e.target.value)
                      },
                    })}
                  />
                )}
                {hasSearched && hasSubmitted && displayError && (
                  // istanbul ignore next
                  <span>
                    <ErrorMessage
                      errorText="Please select from dropdown"
                      styleClasses={styles.contactFormErrorDropdown}
                    />
                  </span>
                )}
              </div>
              <div className={styles.contactFormButtonHolder}>
                <button
                  className={styles.contactFormLinkButton}
                  data-testid="manual-search"
                  onClick={() => {
                    setShowAddressFields(true)
                    setShowAddressSearchFields(false)
                    setAddressSearchError('')
                    setHasSearched(false)
                    setManualEntry(true)
                  }}
                >
                  <AnimationWrapper>Enter address manually</AnimationWrapper>
                </button>
              </div>
            </div>
            <div
              className={classnames(styles.contactFormAddressContainer, {
                [styles.contactFormShow]: showAddressFields,
              })}
              data-testid="address-items"
            >
              <div className={styles.contactFormDisplayAddress}>
                {renderFormItemList(addressItems)}
                <div
                  className={classnames(
                    styles.contactFormButtonHolder,
                    styles.contactFormSearchAgain
                  )}
                >
                  <button
                    className={styles.contactFormLinkButton}
                    data-testid="search-again"
                    onClick={() => {
                      clearSearch(setHasAddress)
                      setShowAddressFields(false)
                      setShowAddressSearchFields(true)
                      setHasSearched(false)
                      setManualEntry(false)
                    }}
                  >
                    <AnimationWrapper>Search again</AnimationWrapper>
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {optins && renderOptinList({ optins, showTerms, handleShowTerms, showEmailTerms })}
      </>
    )
  }
)
