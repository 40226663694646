import type { UserProps } from './../../../shared/types/toolTypes'

export const pageData = (state: {
  emailData: { body: { text: string }[] }
  ticket: {
    ticketPurchased: string
    ticketNumber: string
    ticketReason: string
    ticketDate: string
    ticketLocation: {
      postcode: string
      addressLine1: string
      addressLine2: string
      townCity: string
      county: string
    }
    ticketDiscount: string
  }
  issue: {
    whoIssued: string
    companyName: string
    vehicleReg: string
    purchaseMethod: string
    supportingEvidence: string
    description: string
  }
  optIns: {
    singleConsentOptIn: boolean
  }
  user: UserProps
}) => {
  const {
    ticketPurchased,
    ticketNumber,
    ticketReason,
    ticketDate,
    ticketDiscount,
    ticketLocation,
  } = state.ticket
  const { whoIssued, companyName, vehicleReg, purchaseMethod, supportingEvidence, description } =
    state.issue
  const { emailAddress, firstName, lastName } = state.user
  const { singleConsentOptIn } = state.optIns

  const { body } = state.emailData
  let emailBodyBuilder = ''
  body.forEach((bodyData) => {
    if (bodyData.text) {
      emailBodyBuilder += `${bodyData.text}<br />`
    }
  })

  let carParkLocation = [
    `${ticketLocation.addressLine1}`,
    `${ticketLocation.addressLine2 ? `${ticketLocation.addressLine2}` : ''}`,
    `${ticketLocation.townCity}`,
    `${ticketLocation.county ? `${ticketLocation.county}` : ''}`,
    `${ticketLocation.postcode}`,
  ]

  carParkLocation = carParkLocation.filter(String)

  const questions: any = {}
  questions['question.1228496'] = whoIssued // TF - Parking - Who issued the parking ticket?
  questions['question.1228497'] = companyName // TF - Parking - What is the name of the local authority or company that issued the ticket?
  questions['question.1228498'] = vehicleReg // TF - Parking - What is your vehicle registration number?
  questions['question.1228499'] = ticketPurchased // TF - Parking - Did you pay for parking?
  questions['question.1229977'] = purchaseMethod // TF - Parking - How did you purchase the parking?
  questions['question.1229984'] = supportingEvidence // TF - Parking - Do you have evidence to support your appeal?
  questions['question.1229985'] = ticketNumber // TF - Parking - What is the notice number on the parking ticket?
  questions['question.1229986'] = ticketReason // TF - Parking - What is the violation code or reason quoted on the ticket?
  questions['question.1229987'] = ticketDate // TF - Parking - On what date was the ticket issued?
  questions['question.1230017'] = carParkLocation.join(', ') // TF - Parking - What is the location quoted on the ticket?
  questions['question.1349129'] = ticketLocation.postcode // TF - Parking - Parking location postcode
  questions['question.1230014'] = ticketDiscount // TF - Parking - Are you within the time limit to receive an early payment discount?
  questions['question.1045610'] = description // TF - Tell us more about the problem
  // Composed Letter question id should be updated in CAS-1422
  questions['question.1194262'] = emailBodyBuilder // TF - Composed Letter

  questions['question.1598463'] = singleConsentOptIn ? 'Y' : 'N'
  questions['question.1184531'] = 'Y' // TF - Impact survey opt-in

  return {
    supporter: {
      Email: emailAddress,
      'First name': firstName,
      'Last name': lastName,
      questions,
    },
  }
}
