import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { TextInfoBoxPage } from '../../../../shared/pages/TextInfoBoxPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking/tracking'
import { limitedOptionsData } from './data'

export const LimitedOptions: FunctionComponent = () => {
  const { state } = useContext(ToolContext)

  const {
    initial: { entryToolName },
  } = state

  const { bodyCopy, standFirst, title, link, infoBox, infoBoxParagraphs } = limitedOptionsData
  const { href, linkText } = link

  const handleTracking = () => {
    generalDataLayer(formatToolName(entryToolName), `onward journey | ${linkText} | ${href}`)
  }

  return (
    <div data-testid="limited-options">
      <TextInfoBoxPage
        bodyCopy={bodyCopy}
        handleSubmit={handleTracking}
        infoBoxData={{ infoBox, infoBoxParagraphs }}
        linkData={link}
        standFirst={standFirst}
        title={title}
      />
    </div>
  )
}
