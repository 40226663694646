export const data = {
  imgAlt: 'People shopping in a store',
  title: 'Who did you buy it from?',
  productPlaceholder: 'e.g. Currys, Amazon',
  placeholder: 'e.g. Carpetright, Anglian',
  serviceImage:
    'https://media.product.which.co.uk/prod/images/original/gm-365ae631-6a8f-436a-b4af-4ebf71cf17de-what-product.jpg',
  image:
    'https://media.product.which.co.uk/prod/images/original/gm-b15eff01-60ab-49c9-a590-280c2d97176e-where-purchased.jpg',
}
