export const formatTextInput = (input: string, capitaliseFirstLetter: boolean) => {
  // Capitalise first letter of sentence
  const capitalised = capitaliseFirstLetter
    ? `${input.charAt(0).toUpperCase()}${input.slice(1)}`
    : input

  // Add full stop if one does not already exist at end of sentence
  const addFullStop =
    capitalised.charAt(capitalised.length - 1) !== '.' ? `${capitalised}.` : `${capitalised}`

  return addFullStop
}
