import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { FormEntryPage } from '../../../../shared/pages/FormEntryPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryInputRef, SingleInputState } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { WhatBrandFlow, WhatProductFlow, WhatsWrongFlow } from '../../pageFlowData'
import { data } from './data'

export const WhatProduct: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    product: { description, type },
  } = state

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })
  const whatProductInput = useRef<FormEntryInputRef>(null)

  const getValue = () => {
    return cleanText(whatProductInput?.current?.formEntryInput?.value || '')
  }

  const handleStepChoice = () => {
    if (type === 'product') {
      return { step: WhatBrandFlow.STEP, progress: WhatBrandFlow.PROGRESS_VALUE }
    }

    return { step: WhatsWrongFlow.STEP, progress: WhatsWrongFlow.PROGRESS_VALUE }
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${WhatProductFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    const { progress, step } = handleStepChoice()

    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PRODUCT_DESCRIPTION', data: value })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleSubmit = () => {
    const value = getValue()
    const whatProductHasError = !isNotEmpty(value)

    setInputData({
      renderError: whatProductHasError,
      value: value,
    })

    if (!whatProductHasError) {
      handleTracking(value)
      handleDispatch(value)
    }
  }

  const { renderError, value: errorValue } = inputData
  const { placeholderTextDigital, placeholderText: productPlaceholderText, imgObj, title } = data
  const placeholderText = type === 'digital' ? placeholderTextDigital : productPlaceholderText

  return (
    <FormEntryPage
      buttonText="Next"
      defaultValue={description}
      fieldData={{
        renderError: renderError,
        value: errorValue,
      }}
      handleSubmit={handleSubmit}
      id="what-product"
      imgObj={imgObj}
      isFocused
      placeholderText={placeholderText}
      ref={whatProductInput}
      testId="what-product"
      title={title}
    />
  )
}
