import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { Typography } from '@which/seatbelt'

import classNames from 'classnames'

import { DatePicker } from '../../components/DatePicker'
import type { RefObject } from '../../components/DatePicker/data/types'
import { ImageWithQuestion } from '../../components/ImageWithQuestion'
import { NextButton } from '../../components/NextButton'
import globalStyles from '../../styles/GlobalStyles.module.scss'
import type { MultiDatePickerPageProps } from '../../types/toolTypes'
import styles from './MultiDatePickerPage.module.scss'

export const MultiDatePickerPage = forwardRef(
  (
    {
      buttonText,
      returnDate,
      departureDate,
      handleSubmit,
      imgObj,
      testId,
      title,
      renderDepartureError,
      errorMessageReturn,
      renderReturnError,
      errorMessageDeparture,
      handleInputChange,
      datePickerLabel1,
      datePickerLabel2,
      departMax,
      returnMin,
    }: MultiDatePickerPageProps,
    ref
  ) => {
    const DatePickerDepartureInputRef = useRef<RefObject>(null)
    const DatePickerReturnInputRef = useRef<RefObject>(null)

    useImperativeHandle(ref, () => ({
      departureDate: DatePickerDepartureInputRef.current,
      returnDate: DatePickerReturnInputRef.current,
    }))

    return (
      <div data-testid={testId}>
        <ImageWithQuestion image={imgObj} title={title} />
        <div className={classNames(styles.multiDatePickerPageContainer)}>
          <div
            className={classNames(styles.multiDatePickerPageDate, styles.multiDatePickerPageFirst)}
          >
            <Typography
              className={styles.multiDatePickerPageText}
              data-testid={testId + '-label1'}
              textStyle="body-intro"
            >
              {datePickerLabel1}
            </Typography>
            <DatePicker
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
              {...(departMax && { max: departMax })}
              isFocused
              newErrorMessage={errorMessageDeparture}
              ref={DatePickerDepartureInputRef}
              renderError={renderDepartureError}
              stateValue={departureDate}
              testId="departure-date-picker"
            />
          </div>
          <div className={styles.multiDatePickerPageDate}>
            <Typography
              className={styles.multiDatePickerPageText}
              data-testid={testId + '-label2'}
              textStyle="body-intro"
            >
              {datePickerLabel2}
            </Typography>
            <DatePicker
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
              {...(returnMin && { min: returnMin })}
              newErrorMessage={errorMessageReturn}
              ref={DatePickerReturnInputRef}
              renderError={renderReturnError}
              stateValue={returnDate}
              testId="return-date-picker"
            />
          </div>
        </div>
        <div className={classNames(globalStyles.buttonWrapper, globalStyles.spacing65)}>
          <NextButton buttonText={buttonText} onClick={handleSubmit} />
        </div>
      </div>
    )
  }
)
