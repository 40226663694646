export const airlineData = [
  '34 Squadron, Royal Australian Air Force',
  '135 Airways',
  'Amadeus IT Group S.A.',
  'Sabre travel network Asia-Pacific (ex-Abacus)',
  'Travelsky',
  'Infini travel information, Inc.',
  'Travelport (Galileo core)',
  'Sirena travel',
  'Axess international network, Inc.',
  'AIRKIOSK',
  'Sirin',
  'Travelport (Worldspan core)',
  'Sabre travel network',
  'Hitit Computer Services',
  'Google/ITA',
  'Timbis Air',
  '2 Sqn No 1 Elementary Flying Training School',
  '213th Flight Unit',
  '223rd Flight Unit',
  '224th Flight Unit',
  '247 Jet Ltd',
  '3D Aviation',
  'Jubba Airways',
  '40-Mile Air',
  '43 Air School',
  'Air Sinai',
  'Star East Airline',
  'Express Air Cargo',
  'Bees Airline',
  'Ryan Air Services',
  '2Excel Aviation',
  'Animawings',
  'Advanced Air',
  'A-Jet Aviation Aircraft Management',
  'A-Jet Aviation Company',
  'A-Safar Air Services',
  'A2 Jet Leasing',
  'AASANA',
  'Air Costa',
  'Abakan Air',
  'Advanced Air Co.',
  'Aero Owen',
  'Aero Sotravia',
  'Aerohelicopteros',
  'Aeroventas de Mexico',
  'Air Antwerp',
  'Airspeed Charter',
  'Algonquin Airlink',
  'Alliance Air Charters',
  'Alpha Jet, S.R.O.',
  'Alpine Airlines',
  'Atlas Ukraine Airlines',
  'Avior Regional',
  'Air Charity Network',
  'Aircharters Worldwide',
  'Attawasol Airlines',
  'Apollo Air Service',
  'InterCaribbean Airways',
  'Air Serbia',
  'Air Kyrgyzstan',
  'Air Corsica',
  'AHS Air International',
  'Aberdair Aviation Ghana',
  'Aero Roa',
  'Aeroforward',
  'Aeronautical Charters',
  'Aerotaxis Pegaso',
  'Aer Lingus',
  'Air Volta',
  'Air-Watania',
  'AirSprint US',
  'Airteam Charter',
  'Alpha Star Charter',
  'Angkasa Super Service',
  'Arrow Aviation',
  'Air Experience Flight, Cranwell',
  'Adro Servicios Aereos',
  'Aero Jet International',
  'Aerosky',
  'Aero4m',
  'Aeroecom',
  'Aerolink Uganda',
  'Aeromonkey',
  'Aeroworld Pakistan',
  'AG Air',
  'Air Ambulance Services',
  'Air Canada Rouge',
  'Air China Inner Mongolia',
  "Air Côte d'Ivoire",
  'Air Walser',
  'Air X Charter',
  'ART Aviation',
  'Austrian Air Force',
  'Aviation Legacy',
  'Aztec Worldwide Airlines',
  'Abacus International',
  'Abelag Aviation',
  'Aero Aviation Centre Ltd.',
  'Aero Servicios Ejecutivos Internacionales',
  'Aero Biniza',
  'Aero Comondu',
  'Aero Albatros',
  'Aigle Azur',
  'Aim Air',
  'Atlantis Airlines (USA)',
  'Asiana Airlines',
  'Askari Aviation',
  'Australia Asia Airlines',
  'Astro Air International',
  'Afriqiyah Airways',
  "Air Brasd'or",
  'Atlantic Flight Training',
  'Air Atlantique',
  'Air Alma',
  'Air Hungaria',
  'Air Hong Kong',
  'Air Viggi San Raffaele',
  'Air Express',
  'Air Aurora',
  'Air Cargo Transportation System',
  'SriLankan Airlines',
  'Air Charter Service',
  'Air Europa',
  'Air Italy',
  'Air Special',
  'Air Macau',
  'Air Seychelles',
  'Air Bravo',
  'Air France',
  'Air Partner',
  'Air Caledonie International',
  'AirClass Airways',
  'Air Nippon Network Co. Ltd.',
  'Air Lincoln',
  'Air Caledonia',
  'AirExplore',
  'Air Guam',
  'Air Wisconsin',
  'Air Luxor STP',
  'Air Sunshine',
  'Air Data',
  'Air Afrique Vacancies',
  'Air Japan',
  'Air North Charter - Canada',
  'Air Nevada',
  'Air New Zealand',
  'Air One Executive',
  'Air Malawi',
  'Air Montenegro',
  'Air Valencia',
  'Air Montreal (Air Holdings Inc.)',
  'Air Specialties Corporation',
  'Air Muskoka',
  'Avcon Jet',
  'Air Jetsul',
  'Air Korea Co. Ltd.',
  'Air BC',
  'Air Fret Senegal',
  'Air Jamahiriya Company',
  'Air Glaciers',
  'Air Cargo America',
  'Air Ambar',
  'Air Tractor',
  'Air Park Aviation Ltd.',
  'Air People International',
  'All Nippon Airways',
  'Air Navigation And Trading Co. Ltd.',
  'Air-Angol',
  'air-taxi Europe',
  'Air Cargo Carriers',
  'Air Samarkand',
  'Air Sandy',
  'Air Integra',
  'Air Baffin',
  'Air Bandama',
  'Air Plan International',
  'Air Xpress, Inc.',
  'Air Tchad',
  'Air Resorts',
  'Air-Spray 1967 Ltd.',
  'Air Star Corporation',
  'Air Routing International Corp.',
  'Air Armenia',
  'ASL Airlines Ireland',
  'Air India Limited',
  'Air Inter Gabon',
  'Air Saint Pierre',
  'Air Traffic GmbH',
  'Air Transport International',
  'Air Transport Schiphol',
  'Air Transport Service',
  'Air Falcon',
  'Air Uganda International Ltd.',
  'Air Vanuatu',
  'Air Atlanta Icelandic',
  'Air Inuit',
  'Air Sureste',
  'Air Tahiti Nui',
  'Air Namibia',
  'Air Intersalonika',
  'Air Saigon',
  'Africa World Airlines',
  'Air Jamaica',
  'Air Niamey',
  'Air West',
  'Air Caraïbes',
  'Air India Express',
  'Air Wings',
  'Air Baltic',
  'Air Atlantic (Nig) Limited',
  'Air Nippon',
  'Air Nostrum',
  'Air Niugini',
  'Air Arabia',
  'Air Canada',
  'AlbaStar',
  'Air Memphis',
  'Air Zermatt AG',
  'Air Zimbabwe',
  'Air Marrakech Service',
  'Air Sahara',
  'Air Transport Association',
  'Air Tanzania',
  'Air Charter World',
  'Air Burkina',
  'Air Travel Corp.',
  'Air Malta',
  'Air and Sea Transport',
  'Air Class, S.A. de C.V.',
  'Air Napier',
  'Air Taxi & Cargo',
  'Air Somalia',
  'AirWest',
  'Allegiant Air',
  'African Business and Transportations',
  'Aban Air',
  'Aerial Oy',
  'Abakan-Avia',
  'ABSA Cargo',
  'Abaeté Linhas Aéreas',
  'APSA Colombia',
  'Aerocenter, Escuela de Formación de Pilotos Privados de Avión',
  'Antrak Air',
  'Airborne Express',
  'ABX Air',
  'Avcard Services',
  'Academy Airlines',
  'Astral Aviation',
  'Atlas Cargo Airlines',
  'Airservices Australia',
  'Advance Leasing Company',
  'Air Tindi',
  'Antonov Airlines',
  'Aerea Flying Training Organization',
  'Audeli Air',
  'Aero Dynamics',
  'Aero-Dienst',
  'Aerodiplomatic',
  'Air Arabia Abu Dhabi',
  'Avion Taxi',
  'Aviones de Sonora',
  'Airdeal Oy',
  'Anderson Aviation',
  'Aegean Airlines',
  'Aerogal',
  'Aerocon',
  'Aero Madrid',
  'Aeroservicios Ejecutivos Del Occidente',
  'Aerotec Escuela de Pilotos',
  'Alaska Central Express',
  'Aloha Air Cargo',
  'Alaska Island Air',
  'American Airlines',
  'AmericanConnection',
  'Army Air Corps',
  'Aeroventas',
  'Airway Express',
  'Aerial Transit',
  'Alfa Air',
  'Alliance Airlines',
  'Auvia Air',
  'African West Air',
  'Airfast Indonesia',
  'Ariana Afghan Airlines',
  'Africaone',
  'Alliance Air',
  'Africa Air Links',
  'Aeroflot Russian Airlines',
  'Aero Empresa Mexicana',
  'Alba Servizi Aerotrasporti',
  'Airfreight Express',
  'Africa Chartered Services',
  'Africa Freight Services',
  'Aeronaves Del Centro',
  'Arab Agricultural Aviation Company',
  'Aeroméxico Connect',
  'Algoma Airways',
  'Altagna',
  'Angola Air Charter',
  'AERFI Group',
  'Amadeus IT Group',
  'Angara Airlines',
  'Aero Barloz',
  'Aero Banobras',
  'Aero Flight Service',
  'Agrolet-Mci',
  'Air Alpha Greenland',
  'Azal Avia Cargo',
  'Airport Helicopter Basel, Muller & Co.',
  'Aeroservices Corporate',
  'Aspen Helicopters',
  'Airplanes Holdings',
  'Aerochiapas',
  'ABC Air Hungary',
  'Azerbaijan Airlines',
  'Avies',
  'Airbus Industrie',
  'Air Incheon',
  'Air Seoul',
  'ABC Aerolíneas',
  'African Airlines International Limited',
  'African International Airways',
  'Alpine Air Express',
  'Alicante Internacional Airlines',
  'ABS Jets',
  'Airblue',
  'Airmark Aviation',
  'Airest',
  'Airvias S/A Linhas Aéreas',
  'Aero Services Executive',
  'Atlantic Island Airways',
  'Aircruising Australia',
  'Aircrew Check and Training Australia',
  'Arkia Israel Airlines',
  'Afghan Jet International Airlines',
  'Aero JBR',
  'Avia Consult Flugbetriebs',
  'Ameristar Jet Charter',
  'Allied Air',
  'Aero Jets Corporativos',
  'Aeroejecutivos Colombia',
  'Amerijet International',
  'ANA & JP Express',
  'Alpha Jet International',
  'Aktjubavia',
  'Arca Aerovías Colombianas Ltda.',
  'Anikay Air Company',
  'Akhal',
  'Aeromilenio',
  'Aklak Air',
  'Air Kiribati',
  'Alkan Air',
  'AK Navigator LLC',
  'Albion Aviation',
  'Allied Command Europe (Mobile Force)',
  'Aerotaxis Albatros',
  'American Flyers',
  'Aero Coach Aviation',
  'Atlantic Southeast Airlines',
  'ACM Air Charter',
  'Air Logistics',
  'Aerovallarta',
  'America West Airlines',
  'Aerotransporte de Carga Union',
  'Aero Taxis Cessna',
  'Arizona Express Airlines',
  'Allegheny Commuter Airlines',
  'Allpoints Jet',
  'Alpliner AG',
  'Altair Aviation (1986)',
  'Aeropostal Alas de Venezuela',
  'Alas Nacionales, S.A.',
  'Alyeska Air Service',
  'Alta Flights (Charters) Ltd.',
  'ATMA',
  'Ameriflight',
  'Alan Mann Helicopters Ltd.',
  'Aviation Amos',
  'Amerer Air',
  'Aeroputul International Marculesti',
  'Aero Transporte S.A. (ATSA)',
  'Aeromedicare Ltd.',
  'Aircraft Management and Consulting',
  'AMC Airlines',
  'Aeroméxico',
  'Amiya Airline',
  'AeroBratsk',
  'Alajnihah for Air Transport',
  'Aerotransportacion de Norteamerica',
  'Antares Airtransport, Maintenance & Service GmbH',
  'Airnorth',
  'Aerolínea de Antioquia',
  'Andes Líneas Aéreas',
  'Avia Jaynar',
  'Aero Servicios Empresariales',
  'Alcon Servicios Aéreos, S.A. de C.V.',
  'AVCOM',
  'Aero Vodochody',
  'Atair Pty Ltd.',
  'Aeromundo Ejecutivo',
  'Aerolínea Muri',
  'Aeronord-Grup',
  'Aero Entreprise',
  'As, Opened Joint Stock Company',
  'Aeropiloto',
  'Aeroenlaces Nacionales',
  'Aerovis Airlines',
  'AvJet Routing',
  'Aerovista Gulf Express',
  'Almaver',
  'Afro International Ent. Limited',
  'Alitalia Express',
  'Asia Overnight Express',
  'Aero Vision',
  'Aerotaxi Del Valle',
  'Airpac Airlines, Inc.',
  'Aeroservicios Monterrey',
  'Amapola Flyg AB',
  'Alpha Aviation, Inc.',
  'ASA Pesada, Lda.',
  'Peach Aviation',
  'Aerotransporte Petrolero',
  'ACM Aviation',
  'Aircompany Barcol',
  'All Charter Limited',
  'Appalachian Flying Service, Inc.',
  'Airpac, Inc.',
  'Aerolíneas Pacífico Atlántico, S.A. (Apair)',
  'Aspen Aviation',
  'Aeropuma, S.A.',
  'Apex Air Cargo',
  'APA Internacional',
  'Aeroatlas, S.A.',
  'Aquila Air Ltd.',
  'Aluminum Company Of America',
  'Aviones de Renta de Quintana Roo, S.A. de C.V.',
  'Aerodyne Charter Company',
  'Arik Air',
  'Avia Air N.V.',
  'Albawings',
  'Aires, Aerovías de Integración Regional, S.A.',
  'Aerolíneas Argentinas',
  'Arrowhead Airways',
  'Aero Vics',
  'Aerojet de Costa Rica, S.A.',
  'Antillana De Navegación Aérea',
  'Aeroservicios De San Luis',
  'Aerosuper',
  'Aero Link Air Services S.L.',
  'Airlec - Air Aquitaine Transport',
  'Air Klaipėda',
  'Armstrong Air, Inc.',
  'Aeromet Servicios',
  'Aerotal Aerolíneas Territoriales de Colombia Ltda.',
  'Aravco Ltd.',
  'Aria',
  'Airline Alania',
  'Aerolíneas de Techuacán',
  'Aerotransportes Huitzilin',
  'Aero Transportes Del Humaya',
  'Argosy Airways',
  'Alaska Airlines, Inc.',
  'Air Schefferville, Inc.',
  'African Star Airways (PTY) Ltd.',
  'Aerosun International, Inc.',
  'Awesome Flight Services (PTY) Ltd.',
  'Aero Slovakia',
  'Airsprint',
  'All Star Airlines, Inc.',
  'Aerolíneas Del Oeste',
  'Aviones Are',
  'Arrow Panama',
  'Astravia-Bissau Air Transports Ltd.',
  'Atlantair Ltd.',
  'Aerotours Dominicana',
  'Atlantis Transportation Services, Ltd.',
  'Aerotranscargo',
  'Airlines of Tasmania',
  'Air Corporate',
  'ASTRAL Colombia - Aerotransportes Especiales Ltda.',
  'Aeroferinco',
  'Aero Taxis Y Servicios Alfe',
  'Avialesookhrana',
  'Africair Service',
  'Atlas Airlines',
  'Atlant Aerobatics Ltd.',
  'Avanti Air',
  'Austrian Airlines',
  'Aviastar-Tu',
  'AirBridge Cargo',
  'ATUR',
  'ATESA Aerotaxis Ecuatorianos',
  'Audi Air, Inc.',
  'Augusta Air Luftfahrtunternehmen',
  'Abu Dhabi Amiri Flight',
  'Air Atlantic Uruguay',
  'Aviones Unidos',
  'Avia Business Group',
  'Aero Servicios Expecializados',
  'Aurigny Air Services',
  'Austral Líneas Aéreas',
  'Aurora Aviation, Inc.',
  'Aerolíneas Uruguayas, S.A.',
  'Australian Airlines',
  'Avianca',
  'Avianca Argentina',
  'Avianca Brasil',
  'Aviation Beauport',
  'Aviair Aviation Ltd.',
  'AV8 Helicopters',
  'Avia Traffic Company',
  'Aviación Ejecutiva Mexicana, S.A.',
  'Aviation at Work',
  'Avcorp Registrations',
  'Aviacion Corporativa de Peubla',
  'Alfa Aerospace',
  'Atlantic Airfreight Aviation',
  'Aviation Services, Inc.',
  'Active Aero Charter, Inc.',
  'Avialsa T-35',
  'Avia Sud Aérotaxi',
  'Airvantage Incorporated',
  'Aviator Airways',
  'ASL (Air Service Liege)',
  'Airlink Zambia',
  'Agence Nationale des Aerodromes et de la Meteorologie',
  'Airbus Transport International',
  'Aviodetachment-28',
  'Aero BG',
  'Aerotaxis De La Bahia',
  'Aviaservice',
  'Aerovaradero, S.A.',
  'Airways International, Inc.',
  'Airwork',
  'Australian Wetleasing',
  'Awood Air Ltd.',
  'Arctic Wings And Rotors Ltd.',
  'Auo Airclub AIST-M',
  'Arab Wings',
  'Airwave Transport, Inc.',
  'Aeroway, S.L.',
  'Asian Express Airlines',
  'Aeromexhaga',
  'Aeron International Airlines, Inc.',
  'African Express Airways',
  'AirAsia',
  'AirAsia X',
  'AirAsia Japan',
  'AirAsia India',
  'Aeromax',
  'Action Airlines (Action Air Charter)',
  'Alberni Airways',
  'Aerolíneas Bonanza',
  'Aerobona',
  'Aboitiz Air',
  'Axel Rent, S.A.',
  'Altus Airlines',
  'Aviaxess',
  'Aykavia Aircompany',
  'Airman, S.L.',
  'West Atlantic UK',
  'Atlantic Airlines',
  'Atlantic Airlines, S.A.',
  'Awsaj Aviation Services',
  'Ayeet Aviation & Tourism',
  'Alitalia',
  'Arcus-Air Logistic',
  'Astra Airlines',
  'Azalhelikopter',
  'Aerocozumel',
  'Arizona Pacific Airways',
  'Aviacon Zitotrans Air Company',
  'Azimut, S.A.',
  'Aero Jomacha',
  'Arizona Airways, Inc.',
  'Azza Transport',
  'Air Continental Inc',
  'Antanik-Air',
  'Air Newark',
  'Aircraft Support and Services',
  'Aerobanana',
  'Amako Airlines',
  'Aserca Airlines',
  'Afrique CArgo Service Senegal',
  'Angoservice',
  'Angel Flight America',
  'Aero Nova',
  'Amira Air',
  'Aero Express Intercontinental',
  'Advance Aviation Services',
  'Air Libya Tibesti',
  'Aerovic',
  'Airventure',
  'Aero Servicios',
  'Airvallee',
  'Aeromover',
  'Aerovías Ejecutivas',
  'Aero Servicio Pity',
  'Aero Copter',
  'Air Pal',
  'Air Pullmantur',
  'Aviones Para Servirle',
  'Avio Sluzba',
  'Air Scorpio',
  'Air Spirit',
  'Alatau Airlines',
  'Aviateca',
  'Aroostook Aviation',
  'Aeropycsa',
  'Association of Private Pilots of Kazakhstan',
  'Aero Services',
  'Ababeel Aviation',
  'Ambulance Air Africa',
  'American Eagle Airlines',
  'Aeropuelche',
  'Airshop',
  'African Transport Trading and Investment Company',
  'AD Aviation',
  'Aero Costa Taxi Aéreo',
  'Aerovitro',
  'Aerotaxi Villa Rica',
  'Aerovega',
  'Aerovilla',
  'Aerolíneas Villaverde',
  'Aero Air',
  'Aero Ejecutivos',
  'Aero Industries Inc',
  'Aero Servicios Vanguardia',
  'Aero Taxi Los Valles',
  'Aero Vilamoura',
  'Aero Virel',
  'Aeronautical Radio Inc',
  'Aerovuelox',
  'Aeronaves TSM',
  'Air Botswana',
  'Air-Rep',
  'Air Excel',
  'Air Evans',
  'Air Sorel',
  'Air Net Private Charter',
  'Air Executive Charter',
  'Air Midwest (Nigeria)',
  'Air Tahiti',
  'Air Urga',
  'Air Vardar',
  'Air VIA',
  'Aircompany Rosavia',
  'Aircraft Performance Group',
  'Airwaves Airlink',
  'Airways Corporation of New Zealand',
  'Airways',
  'Airwings oy',
  'Airkenya',
  'Air-Lift Associates',
  'Airtrans Ltd',
  'ARP 410 Airlines',
  'Auckland Regional Rescue Helicopter Trust',
  'Austro Aéreo',
  'Aviation Partners',
  'Avialift Vladivostok',
  'Aviación Comercial de América',
  'Aviation North',
  'Africa West',
  'Avient Air Zambia',
  'Aviazur',
  'Avirex',
  'Aviaexpress Aircompany',
  'AMR Services Corporation',
  'Airline Operations Services',
  'Airlines 400',
  'ATRAN Cargo Airlines',
  'Ameravia',
  'Angkor Air',
  'AVB-2004 Ltd',
  'ASECNA',
  'AT and T Aviation Division',
  'Avalair',
  'Averitt Air Charter',
  'Avstar Aviation',
  'Aviaprom Enterprises',
  'Avia Trans Air Transport',
  'Aviostart AS',
  'Aviação Transportes Aéreos e Cargas',
  "Assistance Aeroportuaire de L'Aeroport de Paris",
  'American Flight Service Systems',
  'Australian airExpress',
  'AMS Group',
  'Air Caraibes Atlantique',
  'Air China Cargo',
  'Aerovías Caribe',
  'Aerotaxi del Cabo',
  'Air Columbus',
  'Aereo Cabo',
  'Air China',
  'Aerocardal',
  'Aero Condor Peru',
  'Aerotrans',
  'Airline Skol',
  'Aerofan',
  'ACEF',
  'Aero Calafia',
  'Air Cargo Belize',
  'Aero Clube Do Algarve',
  'Aircompany Chaika',
  'Air Charter Services',
  'Air Charter Professionals',
  'Arctic Circle Air Service',
  'Aviation Charter Services',
  'Aerovías Castillo',
  'Aero Club De Portugal',
  'Air Care Alliance',
  'Air Toronto',
  'Aquila Air',
  'Air Consul',
  'Air Central',
  'Air Cruzal',
  'Aerotransportes Corporativos',
  'Air Creebec',
  'Aero Charter and Transport',
  'Air Tenglong',
  'Aerolíneas Centauro',
  'Aerocuahonte',
  'Air Chathams',
  'Air Marshall Islands',
  'Australian Customs Service',
  'Air One Cityliner',
  'Air Transport',
  'Aerocheyenne',
  'Air Algérie',
  'Aerovías DAP',
  'Air Alpha',
  'Air Niagara Express',
  'Aviation Defense Service',
  'Air Service Groningen',
  'Archer Aviation',
  'Aeromedica',
  'Aerodin',
  'Air Dolomiti',
  'Aero Modelo',
  'Aerodinamica de Monterrey',
  'Aeroservicios Dinamicos',
  'Aerotaxis Dosmil',
  'Aerodespachos de El Salvador',
  'Aerodynamics Málaga',
  'Aerodynamics Incorporated',
  'Airways Flight Training',
  'Aeronaves Del Noreste',
  'Addis Air Cargo Services',
  'Aero Algarve',
  'Aex Air',
  'Aero Davinci International',
  'Aeroservicios Ecuatorianos',
  'Aero-Pyrenees',
  'Aero Ejecutivo De Baja California',
  'Air City',
  'Aero Ejecutivos RCG',
  'Aeronáutica Castellana',
  'Aerolíneas Comerciales',
  'Air Este',
  'Air Mana',
  'Aeroservicios Ejecutivos Corporativos',
  'Arrendadora y Transportadora Aérea',
  'Aeronaves Del Noroeste',
  'Airailes',
  'Aero Ermes',
  'Aero Transportes Empresariales',
  'Aero Empresarial',
  'Aero Servicios Regiomontanos',
  'Aerosec',
  'Aeromaan',
  'Aereosaba',
  'Avitat',
  'Aerolíneas Ejecutivas Del Sureste',
  'Aeronáutica La Esperanza',
  'Aero Siete',
  'Air Evex',
  'Aeronautical Academy of Europe',
  'Eurowings Europe',
  'Air Exchange',
  'Atlantic Helicopters',
  'Argentine Air Force',
  'AF-Air International',
  'Aviation Data Systems',
  'Air Carriers',
  'Aerofrisco',
  'Alfa 4',
  'Fuji Dream Airlines',
  'African Medical and Research Foundation',
  'Aero Freight',
  'Aerosafin',
  'Air Finland',
  'Aerodata Flight Inspection',
  'Airfix Aviation',
  'Fiji Airways',
  'Atlantic Airways',
  'Aero Leasing',
  'Air Fret De Mauritanie',
  'Avio Nord',
  'Aeroflota Del Noroeste',
  'Aero Fenix',
  'African Company Airlines',
  'Afrijet Airlines',
  'Afrika Aviation Handlers',
  "Afrique Chart'air",
  'Aerofreight Airlines',
  'Aeros Limited',
  'Air Affaires Tchad',
  'ABC Bedarfsflug',
  'Air Iceland',
  'Air Philippines',
  'Aerogaucho',
  'Aero Business Charter',
  'Aeronor',
  'Aerovías del Golfo',
  'Aeronáutica',
  'Air Georgian',
  'Aviance UK',
  'Air Ghana',
  'African International Transport',
  'Air Guinee Express',
  'Africa Airlines',
  'Air Gemini',
  'Aero Charter',
  'Aguilas Mayas Internacional',
  'Aerotaxis Guamuchil',
  'Aeroservicios Gama',
  'Amber Air',
  'Alberta Government',
  'Air Scotland',
  'Air Georgia',
  'Air Cargo Center',
  'Air Greenland',
  'Agroar - Trabalhos Aéreos',
  'Aircompany Grodno',
  'Airlift Alaska',
  'AirSwift',
  'Agrocentr-Avia',
  'Altin Havayolu Tasimaciligi Turizm Ve Ticaret',
  'Atlas Air',
  'Aerotaxi Grupo Tampico',
  'Aerotaxis de Aguascalientes',
  'Air Guyane',
  'Air Victoria Georgia',
  "Air d'Ayiti",
  'Air Comores International',
  'Air Taxi',
  'Aerohein',
  'Atlantic Air Lift',
  'Aviación Ejecutiva De Hildago',
  'Air Haiti',
  'Al Rais Cargo',
  'Air-Invest',
  'Air Tahoma',
  'Air Nova',
  'Aero Homex',
  'Almiron Aviation',
  'Avinor',
  'Airlink Airways',
  'Air Horizon',
  'Air Inter Cameroun',
  'Air Lift',
  'Aero Survey',
  'Aero Airline',
  'Airtime Charters',
  'Aerotaxis Cimarron',
  'Aero Internacional',
  'Aeroservicios Intergrados de Norte',
  'Airpull Aviation',
  'Arvand Airlines',
  'Atlas Aviation Group',
  'Aram Airline',
  'Aria Tour',
  'Aerotaxi S.R.O.',
  'Avita-Servicos Aéreos',
  'Aero Citro',
  'Air Executive',
  'Aviainvest',
  'Air Bagan',
  'Aerojal',
  'Airlink',
  'Ambjek Air Services',
  'Avfinity',
  'Alexandair',
  'Air Jamaica Express',
  'Air Swift Aviation',
  'Aerojobeni',
  'Atyrau Air Ways',
  'Aerosmith Aviation',
  'Arrendamiento de Aviones Jets',
  'Aero Juarez',
  'Air Canada Jazz',
  'Asia Aero Survey and Consulting Engineers',
  'Air Kirovograd',
  'Air Mach',
  'Air Kufra',
  'Arkhabay',
  'Aero Charter Krifka',
  'Air Kraft Mir',
  'Air Concorde',
  'Alexandria Airlines',
  'Afit',
  'Air South',
  'Atlasjet',
  'Air Mali International',
  'Aerokaluz',
  'Air Koryo',
  'Araiavia',
  'AeroSucre',
  'Air Kokshetau',
  'Air Kissari',
  'Aeronavigaciya',
  'Alliance Avia',
  'Av Atlantic',
  'Air Astana',
  'Aerovías De Lagos',
  'Albanian Airlines',
  'Albisa',
  'Albatros Airways',
  'Aerolíneas Aéreas Ejecutivas De Durango',
  'Aerologic',
  'Al-Donas Airlines',
  'Aero Lider',
  'Aleem',
  'Aerolíneas Ejecutivas',
  'Air Alfa',
  'Aerolaguna',
  'Al Ahram Aviation',
  'Alidaunia',
  'Al-Dawood Air',
  'American Aviation',
  'Airlink Solutions',
  'Air Solutions',
  'Air India Regional',
  'Aerolima',
  'Alamia Air',
  'Air Plus Argentina',
  'Almaty Aviation',
  'Aerolíneas Mexicanas J S',
  'Air Almaty',
  'Air Almaty ZK',
  'Aerolane',
  'Aerolíneas Internacionales',
  'Alok Air',
  'Air Saint Louis',
  'Air Leap',
  'Alpine Aviation',
  'Air Alps Aviation',
  'Alrosa-Avia',
  'Al Rida Airways',
  'Aurela',
  'Alsair',
  'Aerotaxis Latinoamericanos',
  'Albinati Aeronautics',
  'Atlantis European Airways',
  'Aliven',
  'Aviavilsa',
  'Air Luxor GB',
  'Air Luxor',
  'Apatas Air',
  'Air Ban',
  'Air Lazur',
  'Aeródromo De La Mancha',
  'Air Mauritius',
  'Avag Air',
  'Air Manas',
  'Airjet Exploracao Aerea de Carga',
  'Air Mercia',
  'Air Medical',
  'Aerolíneas Marcos',
  'Atlantic Aero and Mid-Atlantic Freight',
  'Air Madagascar',
  'Aerosud Charter',
  'Air Meridan',
  'Aero McFly',
  'Asia Pacific Airlines',
  'Aeromagar',
  'Aero Premier De Mexico',
  'Air Moldova',
  'Amal Airlines',
  'Air Madeleine',
  'Aermarche',
  'Air Alsie',
  'Aviation Company Meridian',
  'AMP Incorporated',
  'Airmax',
  'Aerolíneas Amanecer',
  'Aero Mongolia',
  'Air Monarch Cargo',
  'Aeropublicitaria De Angola',
  'Aerolíneas De Morelia',
  'Air Plus Comet',
  'Aeromexpress',
  'Air ITM',
  'Aeromorelos',
  'Aerocharter',
  'Abas',
  'Air Mauritanie',
  'Air Cairo',
  'Air Sport',
  'Aeromas',
  'Aerolíneas Mesoamericanas',
  'Aero-Kamov',
  'Aerotaxis Metropolitanos',
  'Air Metack',
  'Air Montegomery',
  'Aerotaxi Mexicano',
  'Aero Yaqui Mayo',
  'AVC Airlines',
  'Aerovías Montes Azules',
  'Amtrak',
  'Astair',
  'Albarka Air',
  'ACA-Ancargo Air Sociedade de Transporte de Carga Lda',
  'Aero Servicios de Nuevo Laredo',
  'Angoavia',
  'Aeroni',
  'Aeroejecutiva Nieto',
  'Aero Contractors',
  'Aerokuzbass',
  'Atlantic Richfield Company',
  'Aerolíneas Sosa',
  'Aero Norte',
  'Air Inter Ivoire',
  'Aeroservicios De Nuevo Leon',
  'Avial NV Aviation Company',
  'Airwing',
  'Air Next',
  'Arkhangelsk 2 Aviation Division',
  'Aerogisa',
  'Aerolíneas Olve',
  'Aeromega',
  'Air One Nine',
  'Air Ontario',
  'Aerocorp',
  'Action Air',
  'Aerosan',
  'Aviapartner Limited Company',
  'Aliparma',
  'Air Parabet',
  'Air Burundi',
  'Aeropostal Cargo de Mexico',
  'Air Pack Express',
  'Air Palace',
  'Aeropelican Air Services',
  'Peoples Vienna Line',
  'Aerolíneas Chihuahua',
  'Air Cargo Express International',
  'Al Farana Airline',
  'Ave.com',
  'Air South West',
  'Aeroservicios California Pacifico',
  'AST Pakistan Airways',
  'Aero Personal',
  'Aero Servicios Platinum',
  'Apoyo Aéreo',
  'Aerotransportes Privados',
  'Atlantic Coast Jet',
  'Aeroservicio Sipse',
  'Aeroservicios Corporativos De San Luis',
  'Aerotransportes Privados',
  'Aéreo Taxi Paraza',
  'Air Class Líneas Aéreas',
  'Aviation Consultancy Office',
  'Aero Taxi Aviation',
  'Aviation Quebec Labrador',
  'African Safari Airways',
  'Aero Quimmco',
  'Alada',
  'Aerotur Air',
  'Air Center Helicopters',
  'Aur Rum Benin',
  'Aeroserivios Del Bajio',
  'Airbus France',
  'Air Roberval',
  'Aruba Airlines',
  'Air Charters Eelde',
  'Aerocer',
  'Aeroflot-Cargo',
  'Air Mobility Command',
  'Air Cassai',
  'Aero Renta De Coahuila',
  'Aerolíneas Regionales',
  'Atlantic S.L.',
  'Air Service Center',
  'Air Ada',
  'Aer Arann',
  'Aero-Rent',
  'Australian Maritime Safety Authority',
  'Air Austral',
  'Aero-Rey',
  'Aero Africa',
  'Amaszonas Paraguay',
  'Aerotransportes Rafilher',
  'Argo',
  'Airbourne School of Flying',
  'Air Archipels',
  'Aviation Ministry of the Interior of the Russian Federation',
  'Aeris Gestión',
  'Asian Spirit',
  'Aeroservicios Jet',
  'Air Afrique',
  'Airlinair',
  'Air Leone',
  'Aero Lanka',
  'Air Alize',
  'Air Amder',
  'Air Mediterranean',
  'Arm-Aero',
  'Air Max',
  'Air Salone',
  'Aeronem Air Cargo',
  'Air Cargo Masters',
  'Aeroeste',
  'Aero Gen',
  'Avior Airlines',
  'Aeroel Airways',
  'Aerodan',
  'AeroRepública',
  'Aerolíneas Del Pacífico',
  'Aero Roca',
  'Aerotransportes Internacionales De Torreon',
  'Aerolíneas Ejecutivas Tarascas',
  'Aero-Service',
  'AeroSur',
  'Aeronorte',
  'Artis',
  'Arhabaev Tourism Airlines',
  'Air Turquoise',
  'Aerotucan',
  'Air Anastasia',
  'Air Rum',
  'ACT Havayollari',
  'Air VIP',
  'Aircompany Veteran',
  'Alliance Express Rwanda',
  'Arrow Ecuador Arrowec',
  'Aerogroup 98 Limited',
  'Aeroxtra',
  'Air Whitsunday',
  'Aero Zambia',
  'Aero Zano',
  'Anoka Air Charter',
  'Aerosaab',
  'Associated Aviation',
  'American Jet International',
  'Air Santo Domingo',
  'Aero Sudpacifico',
  'Aero Servicios Ejecutivas Del Pacifico',
  'Aero California',
  'Aerosegovia',
  'Airshare Holdings',
  'Aerosiyusa',
  'Aero Silza',
  'Air San Juan',
  'Aero Sami',
  'Avient Aviation',
  'Aerolíneas Sol',
  'Air Soleil',
  'Airspeed Aviation',
  'Air Service',
  'Aeroservicios Ejecutivos Del Pacifico',
  'Asian Aerospace Service',
  'Airworld',
  'Alsaqer Aviation',
  'Air Safaris and Services',
  'Aero Servicio Corporativo',
  'Air Sultan',
  'Airquarius Air Charter',
  'Aeropac',
  'Air St. Thomas',
  'Aerolíneas Del Sureste',
  'Aerial Surveys (1980) Limited',
  'Air Slovakia',
  'Adler Aviation',
  'Aircompany Yakutia',
  'Aerosud Aviation',
  'Air Senegal',
  'Aeroservicios de La Costa',
  'Aeromar',
  'Aerotrebol',
  'Aero Taxi de Los Cabos',
  'Air Turks and Caicos',
  'Aerotranscolombina de Carga',
  'Air Cargo Express',
  'Atlas Helicopters',
  'Air Today',
  'Aero Servicios Azteca',
  'Antair',
  'Atlantique Air Assistance',
  'Aereo Taxi Autlan',
  'Aero Util',
  'Aero Toluca Internactional',
  'Aero Taxi del Centro de Mexico',
  'Aerotropical',
  'Air Tomisko',
  'Airlines PNG',
  'Aero Tonala',
  'Aero Tropical',
  'Air Calédonie',
  'Aero Taxi del Potosi',
  'Aeroturquesa',
  'Air Transat',
  'airtransse',
  'Aerolíneas Turísticas del Caribe',
  'Avcenter',
  'Avialeasing Aviation Company',
  'Aerotaxis del Noroeste',
  'Aerotaxis Alfe',
  'Aereotaxis',
  'Aero Tomza',
  'Air Zambezi',
  'Afra Airlines',
  'Aerostar Airlines',
  'Air Division of the Eastern Kazakhstan Region',
  'Aero-Charter Ukraine',
  'Air Uganda',
  'Air LA',
  'Air Ukraine',
  'Air Umbria',
  'Atuneros Unidos de California',
  'AirNet Express',
  'Alrosa Air Company',
  'Azul Linhas Aéreas Brasileiras',
  'Aviacsa',
  'Avia Express',
  'As-Aero',
  'Amsterdam Airlines',
  'Air Central Asia',
  'Antigua and Barbuda Airways',
  'African Cargo Services',
  'Air Cargo Plus',
  'Avicon',
  'Aloha Airlines',
  'Alfa Airlines',
  'Arabasco Air Services',
  'Asian Air',
  'Air Busan',
  'Ambeo',
  'Air Charter Express',
  'Aryan Cargo Express',
  'Aircraft Sales and Services',
  'Ade, Aviación Deportiva',
  'Arrendaminetos y Transportes Turisticos',
  'Avio Delta',
  'Air Italy Polska',
  'Aero Costa Rica',
  'Aerosur Paraguay',
  'Africa Airways',
  'AEROSPEED FORMATION ET MAINTENANCE',
  'Air Florida',
  'Air Arabia Maroc',
  'Air Do',
  'Air Marine',
  'Air Pink',
  'African International Airlines',
  'Aberdair Aviation',
  'Absolute Flight Services',
  'Ace Air',
  'Acero Taxi',
  'Acropolis Aviation',
  'AD Astra Executive Charter',
  'Addis Airlines',
  'ADI Shuttle Group',
  'Advance Air Luftfahrtgesellschaft',
  'Advance Aviation',
  'Advanced Flight Training',
  'Adventia',
  'AEG Aviation Services',
  'Aeralp',
  'Aerea',
  'Aereo Dorado',
  'Aereo Futuro',
  'Aereo Ruta Maya',
  'Aereo Transportes Los Angeles de America',
  'Aereo WWG',
  'Aero Clinker',
  'Aero Club de Castellon',
  'Aero Corporate',
  'Aero Elite Acapulco',
  'Aero Express',
  'AeroJet',
  'Aero Service Bolivia',
  'Aero Services Mali',
  'Aero Servicio Guerrero',
  'Aero-Beta',
  'Aeroaljarafe',
  'Aerocaribe Coro',
  'Aeroclub Barcelona-Sabadell',
  'Aeroclub de Albacete',
  'Aeroclub de Alicante',
  'Aeroclub de Mallorca',
  'Aeroclub de Vitoria',
  'Aerocorporativos',
  'Aerocredo',
  'Aerofaq',
  'Aeroflot-Plus',
  'Aerolimousine',
  'Aerolínea Principal Chile',
  'Aerolíneas Centrales',
  'Aerolíneas Damojh',
  'Aerolíneas Hidalgo',
  'Aerolíneas Primordiales',
  'Aeronautic de Los Pirineos',
  'AIRDO',
  'Aviation West Charters',
  'Avion Express',
  'The Amiri Flight',
  'Al Masria Universal Airlines',
  'Álamo Aviación, S.L.',
  'AIS Airlines',
  'Air Hamburg',
  'Air Albania',
  'B-Air Charter',
  'BB Airways',
  'Beijing Capital Airlines',
  'Beijing Eofa International Jet',
  'Business Aviators',
  'Bluewest Helicopters-Greenland',
  'Bell Aliant Regional Communications',
  'Beijing City International Jet',
  'Beijing Vistajet Aviation',
  'Blu Halkin',
  'Brixtel Group',
  'Bygone Aviation',
  'Backbone A/S',
  'Batik Air',
  'Blue Air Lines',
  'Blue Jet Charters',
  'Bun Air Corporation',
  'Bearing Supplies Limited',
  'Balkan Agro Aviation',
  'Bahrain Air BSC (Closed)',
  'BAC Leasing Limited',
  'BAE Systems',
  'Belgian Air Force',
  'Baker Aviation',
  'Blackhawk Airways',
  'Belle Air Europe',
  'Business Air Services',
  'British Antarctic Survey',
  'Bradly Air (Charter) Services',
  'Bissau Airlines',
  'Bay Aviation Ltd',
  'British Airways',
  'Best Aero Handling Ltd',
  'Bravo Airways',
  'Bannert Air',
  'Biman Bangladesh Airlines',
  'Bluebird Nordic',
  'Beibars CJSC',
  'Bravo Airlines',
  'Bluebird Aviation',
  'BACH Flugbetriebsges',
  'Blue Islands',
  'British Charter',
  'BCT Aviation',
  'Business Aviation Center',
  'CityJet',
  'Blue Dart Aviation',
  'B&H Airlines',
  'AeroLogic',
  'Bissau Discovery Flying Club',
  'Belgian Army',
  'Badr Airlines',
  'Best Aviation Ltd',
  'Belgorod Aviation Enterprise',
  'Bar Harbor Airlines',
  'Balear Express',
  'Bel Air Helicopters',
  'Berkut Air',
  'BETA - Brazilian Express Transportes Aéreos',
  'Basler Flight Service',
  'Bear Flight',
  'Buffalo Airways',
  'Bombardier',
  'Burkina Airlines',
  'Business Flight Sweden',
  'Bahrain Defence Force',
  'BH Air',
  'British Gulf International',
  'British Gulf International-Fez',
  'Benin Golf Air',
  'Bugulma Air Enterprise',
  'Budget Air Bangladesh',
  'Bergen Air Transport',
  'Buddha Air',
  'Balkh Airlines',
  'Bristow Helicopters',
  'Bristow Helicopters Nigeria',
  'Bhoja Airlines',
  'Belair Airlines',
  'Bighorn Airways',
  'Bahamasair',
  'Bright Air',
  'Bringer Air Cargo Taxi Aéreo',
  'BA Connect',
  'Bosphorus European Airways',
  'Binair',
  'Big Island Air',
  'British International Helicopters',
  'Billund Air Center',
  'Boise Interagency Fire Center',
  'Bioflight A/S',
  'Bird Leasing',
  'Bizjet Ltd',
  'Baja Air',
  'Baltic Jet Aircompany',
  'Business Jet Solutions',
  'Bankair',
  'BF-Lento OY',
  'Blink',
  'Barken International',
  'Bangkok Airways',
  'Bukovyna',
  'Blue Bird Aviation',
  'Bellesavia',
  'Blue Line',
  'Blue1',
  'Belgavia',
  'Blue Horizon Travel Club',
  'Blue Jet',
  'Baltic Airlines',
  'Bali International Air Service',
  'Bearskin Lake Air Service',
  'Baltic Aviation',
  'Bellview Airlines',
  'BMI',
  'BMI Regional',
  'British Medical Charter',
  'Briggs Marine Environmental Services',
  'Bristow Masayu Helicopters',
  'Bmibaby',
  'Bemidji Airlines',
  'Bismillah Airlines',
  'Bowman Aviation',
  'BMW',
  'Banco de Mexico',
  'Bond Offshore Helicopters',
  'Benina Air',
  'BN Group Limited',
  'Air Service Liège (ASL)',
  'Blue Nile Ethiopia Trading',
  'Bonair Aviation',
  'Bancstar - Valley National Corporation',
  'Bentiu Air Transport',
  'Benane Aviation Corporation',
  'British North West Airlines',
  'Boniair',
  'Bond Air Services',
  'Boeing',
  'Bordaire',
  'Bookajet Limited',
  'Bouraq Indonesia Airlines',
  'Blue Panorama Airlines',
  'Berkhut ZK',
  'Bundespolizei-Fliegertruppe',
  'Budapest Aircraft Services/Manx2',
  'Bonus Aviation',
  'British Petroleum Exploration',
  'BRA-Transportes Aéreos',
  'Brock Air Services',
  'Breeze Ltd',
  'Bering Air',
  'Briansk State Air Enterprise',
  'Branson Airlines',
  'BASE Regional Airlines',
  'Brazilian Air Force',
  'British Regional Airlines',
  'Belavia Belarusian Airlines',
  'Bright Aviation Services',
  'Buffalo Express Airlines',
  'Burundayavia',
  'Bistair - Fez',
  'Blue Star Airlines',
  'Brasair Transportes Aéreos',
  'Blue Swan Aviation',
  'Blue Sky Aviation',
  'Bissau Aero Transporte',
  'Best Air',
  'Blue Sky Airways',
  'Big Sky Airlines',
  'BAL Bashkirian Airlines',
  'Baltijas Helicopters',
  'Baltyka',
  'Baltia Air Lines',
  'Botir-Avia',
  'Business Express',
  'BT-Slavuta',
  'Boutique Air',
  'Batavia Air',
  'Bristow U.S. LLC',
  'Bulgarian Air Charter',
  'Blue Airlines',
  'Buryat Airlines Aircompany',
  'Buzz Stansted',
  'Bulgarian Aeronautical Centre',
  'Baron Aviation Services',
  'Berjaya Air',
  'Bellview Airlines, Sierra Leone',
  'Blue Wing Airlines',
  'British World Airlines',
  'Bahrain Executive Air Services',
  'Bar XH Air',
  'Brussels International Airlines',
  'BAX Global',
  'Berry Aviation',
  'Cambodia Bayon Airlines',
  'San Carlos Flight Center',
  'Bylina Joint-Stock Company',
  'Berytos Airlines',
  'Bayu Indonesia Air',
  'Bizair Fluggesellschaft',
  'Brit Air',
  'Butane Buzzard Aviation Corporation',
  'Business Flight Salzburg',
  'BKS Air (Rivaflecha)',
  'Bristol Flying Centre',
  'Barnes Olsen Aeroleasing',
  'Baltimore Air Transport',
  'Boston-Maine Airways',
  'Brussels Airlines',
  'Baltimore Airways',
  'Bond Aviation',
  'Brazilian Army Aviation',
  'Business Express Delivery',
  'Bel Limited',
  'Bangkok Aviation Center',
  'Benair',
  'Binter Canarias',
  'Bonyad Airlines',
  'Burundaiavia',
  'Blue Air',
  'British Mediterranean Airways',
  'Belle Air',
  'Blom Geomatics',
  'Benin Littoral Airways',
  'Bombardier Business Jet Solutions',
  'Bulgaria Air',
  'Brazilian Navy Aviation',
  'Barents AirLink',
  'Belgian Navy',
  'Bakoji Airlines Services',
  'Benders Air',
  'Balmoral Central Contracts',
  'BGB Air',
  'Butte Aviation',
  'Business Airfreight',
  'BAC Express Airlines',
  'Boscombe Down DERA (Formation)',
  'Business Jet Sweden',
  'British Airways Shuttle',
  'British Sky Broadcasting',
  'Bell Helicopter Textron',
  'Buzzaway Limited',
  'Biz Jet Charter',
  'Blue Chip Jet',
  'Baseops International',
  'Bureau Veritas',
  'British Airways Santa',
  'Boskovic Air Charters Limited',
  'Blue Sky Airlines',
  'Businesswings',
  'BA CityFlyer',
  'Boliviana de Aviación',
  'Borajet',
  'Breeze_Airways',
  'Caribbean Airlines',
  'Conquest Air',
  'Continental Airways',
  'Croix Rouge Francais',
  'Corporate Eagle Management Services',
  'Connect Air',
  'Commerce Bank',
  'Civil Aviation Authority of the Czech Republic',
  'Civil Air Patrol South Carolina Wing',
  'Compagnie Nationale Naganagani',
  'Civil Aviation Authority Directorate of Airspace Policy',
  'Civil Aviation Authority Airworthiness Division',
  'CHC Helicopters Nigeria',
  'Corporativo Aereo Principal',
  'Chief of Staff, United States Air Force',
  'Christian Konig - Century Airbirds',
  'Cambridge Recurrent Training',
  'California Department of Forestry and Fire Protection',
  'Careflight Queensland',
  'Pawa Dominicana',
  'Castle Air Charter',
  'Challenges Aviation',
  'Challenges Limited',
  'Cheqair',
  'Clacton Aero Club',
  'Coral Sun Airways',
  'Cowan Group',
  'Carpatair Flight Service',
  'Cambodia Airlines',
  'Cambodia Airways',
  'Caricom Airways',
  'China Southern Airlines Henan',
  'Chongqing Forebase General Aviation',
  'Colt Transportes Aereos',
  'CHC Global Operations International',
  'C and M Aviation',
  'C Air Jet Airlines',
  'C N Air',
  'C&M Airways',
  'C.S.P., Societe',
  'Caliber Jet',
  'Cameroon Airlines Corporation',
  'Common Sky',
  'Cabi',
  'Compagnia Aeronautica Italiana',
  'CAL Cargo Air Lines',
  'CAM Air Management',
  'CATA Línea Aérea',
  'CCF Manager Airline',
  'CEDTA (Compañía Ecuatoriana De Transportes Aéreos)',
  'CHC Denmark',
  'CHC Helicopter',
  'CHC Airways',
  'CHC Helikopter Service',
  'CI-Tours',
  'CKC Services',
  'CM Stair',
  "Centre national d'études des télécommunications - C.N.E.T.",
  'COAPA AIR',
  'COMAV',
  'CRI Helicopters Mexico',
  'CSA Air',
  'CSE Aviation',
  'CTK Network Aviation',
  'Cabair College of Air Training',
  'Cabo Verde Express',
  'Caernarfon Airworld',
  'Caicos Express Airways',
  'Cairo Air Transport Company',
  'Cal Gulf Aviation',
  'Cal-West Aviation',
  'California Air Shuttle',
  'Calima Aviación',
  'Calm Air',
  'Camai Air',
  'Cambodia Angkor Air',
  'Campania Helicopteros De Transporte',
  'CanJet',
  'Canada Jet Charters',
  'Canadian Coast Guard',
  'Canadian Eagle Aviation',
  'Canadian Forces',
  'Canadian Global Air Ambulance',
  'Canadian Helicopters',
  'Canadian Interagency Forest Fire Centre',
  'Canadian National Telecommunications',
  'Canadian North',
  'Canadian Pacific Airlines',
  'Canadian Regional Airlines',
  'Canadian Warplane Heritage Museum',
  'Canadian Western Airlines',
  'Canair',
  'Cancun Air',
  'Cape Air',
  'Cape Air Transport',
  'Cape Central Airways',
  'Cape Smythe Air',
  'Capital Air Service',
  'Capital Airlines',
  'Capital Airlines Limited',
  'Capital Cargo International Airlines',
  'Capital City Air Carriers',
  'Capital Trading Aviation',
  'Capitol Air Express',
  'Capitol Wings Airline',
  'Caravan Air',
  'Cardiff Wales Flying Club',
  'Cardinal/Air Virginia',
  'Cardolaar',
  'Cards Air Services',
  'CareFlight',
  'Carga Aérea Dominicana',
  'Carga Express Internacional',
  'Cargo 360',
  'Cargo Express',
  'Cargo Ivoire',
  'Cargo Link (Caribbean)',
  'Cargo Logic Air',
  'Cargo Three',
  'Cargoitalia',
  'Cargojet Airways',
  'Cargolux',
  'Cargolux Italia',
  'Cargoman',
  'Carib Aviation',
  'Carib Express',
  'CARIBAIR',
  'Caribbean Air Cargo',
  'Caribbean Air Transport',
  'Club Aerocelta de Vuelo Con Motor',
  'Caribbean Airways',
  'Choice Airways',
  'Caribbean Express',
  'Caribbean Star Airlines',
  'Caribintair',
  'Carill Aviation',
  'Carpatair',
  'Carroll Air Service',
  'Casement Aviation',
  'Casino Airline',
  'Casper Air Service',
  'Caspian Airlines',
  'Castle Aviation',
  'Cat Aviation',
  'Catalina Flying Boats',
  'Catex',
  'Cathay Pacific',
  'Caverton Helicopters',
  'Cayman Airways',
  'Cebu Pacific',
  'Cecil Aviation',
  'Cega Aviation',
  'Celtic Airways',
  'Celtic West',
  "Centre d'Essais en Vol",
  'Centennial Airlines',
  'Cobalt Air LLC',
  'Center Vol',
  'Center-South',
  'Centrafrican Airlines',
  'Central Air Express',
  'Central Airlines',
  'Central Airways',
  'Central American Airlines',
  'Central Aviation',
  'Central De Discos De Reynosa',
  'Central European Airlines',
  'Central Flying Service',
  'Central Mongolia Airways',
  'Central Mountain Air',
  'Central Queensland Aviation College',
  'Central Skyport',
  'Centralwings',
  'Centre Airlines',
  'Centre of Applied Geodynamica',
  'Centre-Avia',
  'Centro De Helicopteros Corporativos',
  'Centro de Formación Aeronáutica de Canarias',
  'Centurion Air Cargo',
  'Century Aviation',
  'Century Aviation International',
  'Certified Air Dispatch',
  'Cetraca Aviation Service',
  'Chabahar Airlines',
  'Chalair Aviation',
  "Chalk's International Airlines",
  'Challenge Air Transport',
  'Challenge Aviation',
  'Challenge International Airlines',
  'Challenge Aero',
  'Champagne Airlines',
  'Champion Air',
  'Chanchangi Airlines',
  'Chang An Airlines',
  'Channel Island Aviation',
  'Chantilly Air',
  'Chaparral Airlines',
  'Chari Aviation Services',
  'Charlan Air Charter',
  'Charlie Hammonds Flying Service',
  'Charlotte Air National Guard',
  'Charter Air',
  'Chartright Air',
  'Chautauqua Airlines',
  'Cheboksary Airenterprise JSC',
  'Chemech Aviation',
  'Cherline',
  'Chernomor-Avia',
  'Cherokee Express',
  'Cherry Air',
  'Chesapeake Air Service',
  'Chevron U.S.A',
  'Cheyenne Airways',
  'Chicago Air',
  'Chicago Jet Group',
  'Chief Rat Flight Services',
  'Chilchota Taxi Aéreo',
  'Chilcotin Caribou Aviation',
  'Chilliwack Aviation',
  'Chim-Nir Aviation',
  'China Airlines',
  'China Cargo Airlines',
  'China Eastern Airlines',
  'China Express Airlines',
  'China Flying Dragon Aviation',
  'China General Aviation Corporation',
  'China National Aviation Corporation',
  'China Ocean Helicopter Corporation',
  'China Postal Airlines',
  'China Southern Airlines',
  'China United Airlines',
  'China Xinhua Airlines',
  'Chinguetti Airlines',
  'Chipola Aviation',
  'Chippewa Air Commuter',
  'Chitaavia',
  'Chongqing Airlines',
  'Christman Air System',
  'Christophorus Flugrettungsverein',
  'Chrome Air Services',
  'Church Aircraft',
  'Cielos Airlines',
  'Cimber Sterling',
  'Cinnamon Air',
  'Cirrus Air',
  'Cirrus Middle East',
  'CitationAir',
  'Citibank',
  'Citic General Aviation',
  'City Airline',
  'City of Bangor',
  'Cityair (Chester) Limited',
  'Cityfly',
  'Cityline Hungary',
  'Citylink Airlines',
  'Civair Airways',
  'Civil Air Patrol',
  'Civil Aviation Authority',
  'Civil Aviation Authority of New Zealand',
  'Civil Aviation Inspectorate of the Czech Republic',
  'Claessens International Limited',
  'Clark Aviation',
  'Clasair',
  'Clay Lacy Aviation',
  'Click Airways',
  'Cloud 9 Air Charters',
  'Clowes Estates Limited',
  'Club 328',
  'Club Air',
  'Coast Air',
  'Coastal Air',
  'Coastal Air Transport',
  'Coastal Airways',
  'Coastal Travels',
  'Cohlmia Aviation',
  'Colaéreos',
  'Colemill Enterprises',
  'Colgan Air',
  'Colibri Aviation',
  'Colt International',
  'Columbia Helicopters',
  'Columbus Air Transport',
  'Colvin Aviation',
  'Comair',
  'Comair Flight Services',
  'Comed Group',
  'Comeravia',
  'Comercial Aérea',
  'Comet Airlines',
  'Comfort Air',
  'Comfort Jet Services',
  'Comlux Aviation',
  'Comlux Kazakhstan',
  'Comlux Malta',
  'Comlux Aruba',
  'Commair Aviation',
  'Commandement Du Transport Aerien Militaire Francais',
  'Commander Air Charter',
  'Commander Mexicana',
  'Commercial Aviation',
  'Commodore Aviation',
  'Commonwealth Jet Service',
  'CommutAir',
  'Comores Airlines',
  'Compagnia Generale Ripreseaeree',
  'Compagnie Aérienne du Mali',
  'Compagnie Mauritanienne Des Transports',
  'Compagnie de Bauxites de Guinee',
  'Compañía Aérea de Valencia',
  'Compañía Aerotécnicas Fotográficas',
  'Compañía De Actividades Y Servicios De Aviación',
  'Compania Ejecutiva',
  'Compania Helicopteros Del Sureste',
  'Compania Mexicana De Aeroplanos',
  'Compania Mexicargo',
  'Compañía Transportes Aéreos Del Sur',
  'Compañía de Servicios Aéreos Tavisa',
  'Company Flight',
  'Compass Airlines',
  'Compass International Airways',
  'Compuflight Operations Service',
  'Compuserve Incorporated',
  'Conair Aviation',
  'Concordavia',
  'Condor Aero Services',
  'Condor',
  'Condor Flugdienst',
  'Confort Air',
  'Congo Air',
  'Congressional Air',
  'Conifair Aviation',
  'Connectair Charters',
  'Conquest Airlines',
  'Conroe Aviation Services',
  'Consorcio Helitec',
  'Constanta Airline',
  'Contactair',
  'Contel ASC',
  'Continental Airlines',
  'Continental Express',
  'Continental Micronesia',
  'Continental Oil',
  'Conviasa',
  'Cook Inlet Aviation',
  'Cooper Aerial Surveys',
  'Copa Airlines',
  'Copenhagen Air Taxi',
  'Copper State Air Service',
  'Copterline',
  'Central Charter',
  'Corendon Airlines',
  'Corendon Dutch Airlines',
  'Coronado Aerolíneas',
  'Corpac Canada',
  'Corporación Aéreo Cencor',
  'Corporacion Aeroangeles',
  'Corporacion Paraguaya De Aeronautica',
  'Corporate Air',
  'Corporate Aircraft Company',
  'Corporate Airlink',
  'Corporate Aviation Services',
  'Corporate Flight International',
  'Contour Airlines',
  'Corporate Jets',
  'Corsairfly',
  'Corse Méditerranée',
  'Cosmic Air',
  'Costa Airlines',
  'Cougar Helicopters',
  'Coulson Flying Service',
  'Country Connection Airlines',
  'Courier Services',
  'Court Helicopters',
  'Coval Air',
  'COWI',
  'Coyne Aviation',
  'Cranfield University',
  'Cree Airways',
  'Crelam',
  'Crest Aviation',
  'Crimea Universal Avia',
  'Croatia Airlines',
  'Croatian Air Force',
  'Cross Aviation',
  'Crossair Europe',
  'Crow Executive Air',
  'Crown Air Systems',
  'Crown Airways',
  'Crownair',
  'Cruiser Linhas Aéreas',
  'Cryderman Air Service',
  'Crystal Shamrock Airlines',
  'Cubana de Aviación',
  'Cutter Aviation',
  'Cumberland Airways (Nicholson Air Service)',
  'Custom Air Transport',
  'Cygnus Air',
  'Cyprair Tours',
  'Cypress Airlines',
  'Cyprus Airways',
  'Cyprus Turkish Airlines',
  'Czech Air Force',
  'Czech Air Handling',
  'Czech Airlines',
  'Czech Government Flying Service',
  'Cargo Plus Aviation',
  'CDI Cargo Airlines',
  'CHC Helicopters Netherlands',
  'CemAir',
  'Centro de Servicio Aeronautico',
  'Cobalt',
  'CAVOK Airlines',
  'Clickair',
  'West Air (China)',
  'Carson Air Ltd',
  'Dreamjet',
  'Delta Private Jets',
  'Desert Jet',
  'Dehong South Asian General Aviation',
  'Dniproaviaservis Company',
  'Dream Flyers Training Center',
  'DMCFLY',
  'Danaus Lineas Aereas',
  'DF Aviation',
  'D & D Aviation',
  'D&K Aviation',
  'DanubeWings',
  'DAP Helicopteros',
  'DFS UK Limited',
  'DAS Air Cargo',
  'DAS Airlines',
  'DAT Danish Air Transport',
  'DERA Boscombe Down',
  'DESNA',
  'DETA Air',
  'DGO Jet',
  'DHL Aero Expreso',
  'DHL Air Limited',
  'DHL Aviation',
  'DHL International',
  'DHL de Guatemala',
  'DSWA',
  'Daallo Airlines',
  'Dagestan Airlines',
  'Dahla Airlines',
  'DC Aviation',
  'Daimler-Chrysler',
  'Daka',
  'Dala Air Services',
  'Dalavia',
  'Dallas Express Airlines',
  'Damascene Airways',
  'Danbury Airways',
  'Dancopter',
  'Danish Air Force',
  'Danish Army',
  'Danish Navy',
  'Danu Oro Transportas',
  'Darta',
  'Darwin Airline',
  'Dasab Airlines',
  'Dash Air Charter',
  'Dash Aviation',
  'Dasnair',
  'Dassault Aviation',
  'Dassault Falcon Jet Corporation',
  'Dassault Falcon Service',
  'Data International',
  'Date Transformation Corp',
  'Dauair',
  'David Crawshaw Consultants Limited',
  'Dawn Air',
  'DayJet',
  'Daya Aviation',
  'De Havilland',
  'Deadalos Flugtbetriebs',
  'Decatur Aviation',
  'Deccan Charters',
  'Deere and Company',
  'Delaware Skyways',
  'Delta Aerotaxi',
  'Delta Air Charter',
  'Delta Air Lines',
  'Delta Engineering Aviation',
  'Delta Express International',
  'Delta State University',
  'Denver Express',
  'Denver Jet',
  "Departament d'Agricultura de la Generalitat de Catalunya",
  'Deraya Air Taxi',
  'Des R Cargo Express',
  'Desarrollo Milaz',
  'Destiny Air Services',
  'Deutsche Bahn',
  'Deutsche Rettungsflugwacht',
  'Deutsches Zentrum fur Luft-und Raumfahrt EV',
  'Di Air',
  'Diamond Aviation',
  'Didier Rousset Buy',
  'Digital Equipment Corporation',
  'Diplomatic Freight Services',
  'Dirección General de Aviación Civil y Telecomunicasciones',
  'Direct Air',
  'Direct Air trading as Midway Connection',
  'Direct Flight',
  'Dirgantara Air Service',
  'Discover Air',
  'Discovery Airways',
  'Dispatch Services',
  'Dix Aviation',
  'Dixie Airways',
  'Dniproavia',
  'Dolphin Air',
  'Dolphin Express Airlines',
  'Dome Petroleum',
  'Dominguez Toledo (Grupo Mayoral)',
  'Don Avia',
  'Donair Flying Club',
  'Donavia',
  'DonbassAero',
  'Dorado Air',
  'Dornier',
  'Dornier Aviation Nigeria',
  'Dos Mundos',
  'Dreamcatcher Airways',
  'Druk Air',
  'Drummond Island Air',
  'Dubai Airwing',
  'Dubrovnik Air',
  'Ducair',
  'Duchess of Brittany (Jersey) Limited',
  'UK Royal/HRH Duke of York',
  "Dun'Air",
  'Duncan Aviation',
  'Dunyaya Bakis Hava Tasimaciligi',
  'Duo Airways',
  'Durango Jet',
  'Dutch Antilles Express',
  'Dutch Caribbean Express',
  'Dutchbird',
  'Dwyer Aircraft Services',
  'Dynair Services',
  'Dynamair Aviation',
  'Dynamic Air',
  'Dynamic Airways',
  'Dba',
  'Eagle Express',
  'Echo Airlines',
  'Envoy Air',
  'Everett Aviation',
  'Executive Airlink',
  'Ellinair',
  'Everett Limited',
  'Eleron Aviation Company',
  'Eclair Aviation',
  'Egyptian Leisure Airlines',
  'Endeavor Air',
  'Executive Express Aviation/JA Air Charter',
  'Express Airways',
  'Excel-Aire Service',
  'Executive Flight Services',
  'Ezjet GT',
  'Eagle Express Air Charter',
  'Elite Airways',
  'Europe Jet',
  'Everbread',
  'E H Darby Aviation',
  'Electronic Data Systems',
  'Executive Airlines Services',
  'EFAOS- Agencia De Viagens e Turismo',
  'Eisele Flugdienst',
  'EFS-Flugservice',
  'EIS Aircraft',
  'EPAG',
  'ESI Eliservizi Italiani',
  'EU Airways',
  'EVA Air',
  'Eagle Aero',
  'Eagle Air',
  'Eagle Airways',
  'Eagle Air Company',
  'Eagle Aviation',
  'Eagle Aviation France',
  'Eagle International',
  'Eagle Jet Charter',
  'Eaglemed (Ballard Aviation)',
  'Earth Airlines Services',
  'UTair-Ukraine',
  'East African Safari Air',
  'East African Safari Air Express',
  'East Asia Airlines',
  'East Coast Airways',
  'East Coast Jets',
  'East Hampton Aire',
  'East Kansas City Aviation',
  'East Midlands Helicopters',
  'East Star Airlines',
  'Eastar Jet',
  'Eastern Air',
  'Eastern Air Executive',
  'Eastern Air Lines',
  'Eastern Airways',
  'Eastern Australia Airlines',
  'Eastern Carolina Aviation',
  'Eastern Executive Air Charter',
  'Eastern Express',
  'Eastern Metro Express',
  'Eastern Pacific Aviation',
  'Eastern SkyJets',
  'Eastland Air',
  'Easy Link Aviation Services',
  'Eckles Aircraft',
  'Eclipse Aviation',
  'Eco Air',
  'Ecoair',
  'Ecomex Air Cargo',
  'Ecotour',
  'Ecoturistica de Xcalak',
  'Ecuatoguineana De Aviación (EGA)',
  'Ecuatorial Cargo',
  'Ecuavia',
  'Edelweiss Air',
  'Edgartown Air',
  'Air Charter Scotland',
  'Edwards Jet Center of Montana',
  'Efata Papua Airlines',
  'EFS European Flight Service',
  'Egyptair',
  'Egyptair Cargo',
  'Egyptian Air Force',
  'Egyptian Aviation',
  'Egyptian Aviation Company',
  'Ei Air Exports',
  'Eirjet',
  'El Al Israel Airlines',
  'El Caminante Taxi Aéreo',
  'El Quilada International Aviation',
  'El Sal Air',
  'El Sol De América',
  'El-Buraq Air Transport',
  'Elan Express',
  'Elbe Air Transport',
  'Elbrus-Avia Air Enterprise',
  'Eldinder Aviation',
  'Elicar',
  'Elidolomiti',
  'Elieuro',
  'Elifriulia',
  'Elilario Italia',
  'Elilombarda',
  'Elimediterranea',
  'Elios',
  'Elipiu',
  'Elisra Airlines',
  'Elite Air',
  'Elite Jets',
  'Elitellina',
  'Elliott Aviation',
  'Elmagal Aviation Services',
  'Elrom Aviation and Investments',
  'Embassy Airlines',
  'Embassy Freight Company',
  'Empresa Brasileira De Aeronáutica',
  'Embry-Riddle Aeronautical University',
  'Emerald Airlines',
  'Emerald Airways',
  'Emery Worldwide Airlines',
  'Emetebe',
  'Emirates Airlines',
  'Emoyeni Air Charter',
  'Empire Air Service',
  'Empire Airlines',
  'Empire Aviation Services',
  "Empire Test Pilots' School",
  'Empresa (Aero Uruguay), S.A.',
  'Empresa Aero-Servicios Parrague',
  'Aero Caribbean',
  'Empresa Aviación Interamericana',
  'Empresa Ecuatoriana De Aviación',
  'Empresa Nacional De Servicios Aéreos',
  'Empresa Venezolana',
  'Empresa de Aviación Aerogaviota',
  'Empressa Brasileira de Infra-Estrutura Aeroportuaria-Infraero',
  'Endecots',
  'Enimex',
  'Enkor JSC',
  'Enrique Gleisner Vivanco',
  'Ensenada Vuelos Especiales',
  'Enter Air',
  'Entergy Services',
  'Enterprise World Airways',
  'Eos Airlines',
  'Equaflight Service',
  'Equatair Air Services (Zambia)',
  'Equatorial Airlines',
  'Era Helicopters',
  'Eram Air',
  'Erfoto',
  'Erie Airways',
  'Eritrean Airlines',
  'Escola De Aviacao Aerocondor',
  'Escuela De Pilotos Are Aviación',
  'Espace Aviation Services',
  'Esso Resources Canada',
  'Estafeta Carga Aérea',
  'Estonian Air Force',
  'Estonian Air',
  'Estrellas Del Aire',
  'Ethiopian Airlines',
  'Eti 2000',
  'Etihad Airways',
  'Etram Air Wing',
  'Euraviation',
  'Euro Continental AIE',
  'Euro Exec Express',
  'Euro Sun',
  'Euro-Asia Air',
  'Euro-Asia Air International',
  'EuroAtlantic Airways',
  'EuroJet Aviation',
  'Pan Europeenne Air Service',
  'Euroamerican Air',
  'Euroceltic Airways',
  'Eurocontrol',
  'Eurocopter',
  'Eurocypria Airlines',
  'Eurofly',
  'Eurofly Service',
  'Euroguineana de Aviación',
  'Eurojet Italia',
  'Eurojet Limited',
  'Eurojet Romania',
  'Eurojet Servis',
  'Eurolot',
  'Euromanx Airways',
  'Europe Air Lines',
  'Europe Airpost',
  'European 2000 Airlines',
  'European Air Express',
  'European Air Transport',
  'European Aviation Air Charter',
  'European Business Jets',
  'European Coastal Airlines',
  'European Executive',
  'European Executive Express',
  'Eurosense',
  'Euroskylink',
  'Eurowings',
  'Eurowings Discover',
  'Evelop Airlines',
  'Evergreen International Airlines',
  'Everts Air Alaska/Everts Air Cargo',
  'Ewa Air',
  'Examiner Training Agency',
  'Excel Airways',
  'Excel Charter',
  'Excellent Air',
  'Execair Aviation',
  'Execujet Charter',
  'Execujet Middle East',
  'Execujet Scandinavia',
  'Executive Air',
  'Executive Air Charter',
  'Executive Air Fleet',
  'Executive Aircraft Charter and Charter Services',
  'Executive Aircraft Services',
  'Executive Airlines',
  'Executive Aviation Services',
  'Executive Flight',
  'Executive Flight Operations Ontario Government',
  'Executive Jet Charter',
  'Executive Jet Management',
  'Executive Turbine Aviation',
  'Eximflight',
  'Exin',
  'Expertos En Carga',
  'Express Air',
  'Express International Cargo',
  'Express Line Aircompany',
  'Express Net Airlines',
  'Express Tours',
  'ExpressJet',
  'Exxavia Limited',
  'easyJet UK',
  'easyJet Switzerland',
  'Eagle Airlines Luftverkehrsges',
  'Eagle Aviation Services',
  'ExxAero',
  'easyJet Europe',
  'Evolem Aviation',
  'Florida Aerocharter',
  'Florida Air Cargo',
  'Fly Advance',
  'Fuxion Line Mexico',
  'Fly Rak',
  'FMI Air',
  'Fort Aero',
  'Fast Air',
  'Fly Jetstream Aviation',
  'Fastjet',
  'F Air',
  'ForeFlight',
  'F.S. Air Service',
  'FAI Air Service',
  'FINFO Flight Inspection Aircraft',
  'FLM Aviation Mohrdieck',
  'FLTPLAN (anonymized service)',
  'FLowair Aviation',
  'FMG Verkehrsfliegerschule Flughafen Paderborn-Lippstadt',
  'FR Aviation',
  'FSB Flugservice & Development',
  'FSH Luftfahrtunternehmen',
  'Fab Air',
  'Facts Air',
  'Fair Aviation',
  'Fair Wind Air Charter',
  'Fairoaks Flight Centre',
  'Fairways Corporation',
  'Falcon Air',
  'Falcon Air Express',
  'Falcon Airline',
  'Falcon Aviation',
  'Falcon Aviation Services',
  'Falcon Jet Centre',
  'Falwell Aviation',
  'Far Eastern Air Transport',
  'Royal Flying Doctor Service',
  'Farmingdale State University',
  'Farnair Hungary',
  'ASL Airlines Switzerland',
  'Farnas Aviation Services',
  'Faroecopter',
  'FaroeJet',
  'Farwest Airlines',
  'Faso Airways',
  'Fast Helicopters',
  'Fayban Air Services',
  'Fayetteville Flying Service and Scheduled Skyways System',
  'Federal Air',
  'Federal Airlines',
  'Federal Armed Forces',
  'Federal Armored Service',
  'Federal Aviation Administration',
  'FedEx Express',
  'Feniks Airline',
  'Feria Aviación',
  'Fika Salaama Airlines',
  'Finalair Congo',
  'Financial Airxpress',
  'Fine Airlines',
  "Finist'air",
  'Finnair',
  'Finncomm Airlines',
  'Finnish Air Force',
  'FinnHEMS',
  'Firefly',
  'First Air',
  'First Air Transport',
  'First Cambodia Airlines',
  'First Choice Airways',
  'First City Air',
  'First Flying Squadron',
  'First Line Air',
  'First Sabre',
  'Fischer Air',
  'Fischer Air Polska',
  'FitsAir',
  'Flagship Express Services',
  'Flair Airlines',
  'Flamenco Airways',
  'Flamingo Air',
  'Flamingo Air-Line',
  'Flash Airlines',
  'Fleet Requirements Air Direction Unit',
  'Fleetair',
  'Flexflight',
  'Flight Alaska',
  'FCS Flight Calibration Services',
  'Flight Calibration Services Ltd.',
  'Flight Centre Victoria',
  'Nelson Aviation College Ltd',
  'Flight Dispatch Services',
  'Flight Express, Inc.',
  'Flight Inspection Center of the General Administration of Civil Aviation in',
  'China',
  'Flight Inspections and Systems',
  'Flight International',
  'Flight Line',
  'Flight Ops International',
  'Flight Options',
  'Flight Precision Limited',
  'Flight Safety Limited',
  'Flight Support Sweden',
  'Flight Trac',
  'Flight Training Europe',
  'Flight West Airlines',
  'Flight-Ops International',
  'Flightcraft',
  'Flightexec',
  'Flightline',
  'Flightpass Limited',
  'Flightstar Corporation',
  'Flightworks',
  'Flint Aviation Services',
  'Florida Air',
  'Florida Coastal Airlines',
  'Florida Department of Agriculture',
  'Florida Jet Service',
  'Florida West International Airways',
  'Flugdienst Fehlhaber',
  'Flugschule Basel',
  'Flugschule Eichenberger',
  'Flugwerkzeuge Aviation Software',
  'Fly All Ways',
  'Fly Air',
  'Fly CI Limited',
  'Fly Europa Limited',
  'Fly Excellent',
  'FlyGTA Airlines',
  'Fly International Airways',
  'Fly Jamaica Airways',
  'Fly Line',
  'Fly Me Sweden',
  'Fly Wex',
  'FlyAsianXpress',
  'Flybondi',
  'Flynas',
  'Flybaboo',
  'Flybe',
  'Flycolumbia',
  'Flycom',
  'Flygaktiebolaget Gota Vingar',
  'Flygprestanda',
  'Flygtransporter I Nykoping',
  'Flyguppdraget Backamo',
  'Flyhy Cargo Airlines',
  'Flying Carpet Company',
  'Flying Service',
  'Flying-Research Aerogeophysical Center',
  'Flylink Express',
  'FlyPelican',
  'Flyr',
  'Flyteam Aviation',
  'Focus Air',
  'Fokker',
  'Fonnafly',
  'Ford Motor Company',
  'Formula One Management',
  'Forth and Clyde Helicopter Services',
  'Fortunair Canada',
  'Forward Air International Airlines',
  'Foster Aviation',
  'Foster Yeoman',
  'Fotografia F3',
  'Fly One',
  'Four Star Aviation / Four Star Cargo',
  'Four Winds Aviation',
  'Foxair',
  'France Douanes',
  'Freebird Airlines',
  'Freedom Air',
  'Freedom Air Services',
  'Freedom Airlines',
  'Freedom Airways',
  'Freight Runners Express',
  'Force Aerienne Francaise',
  "Aviation Legere De L'Armee De Terre",
  'France Marine Nationale',
  'Fresh Air',
  'Fresh Air Aviation',
  'Freshaer',
  'Friendship Air Alaska',
  'Friendship Airlines',
  'Froggy Corporate Aviation',
  'Frontier Airlines',
  'Frontier Commuter',
  'Frontier Flying Service',
  'Frontier Guard',
  'Fujairah Aviation Centre',
  'Fujian Airlines',
  'Full Express',
  'Fumigación Aérea Andaluza',
  'Fun Flying Thai Air Service',
  'FundaciÃ Rego',
  'Funtshi Aviation Service',
  'Flydubai',
  'Fly Georgia',
  'Fleet Air International',
  'FlyViking',
  'Fly Pro',
  'FlyEgypt',
  'Gemini Air Group',
  'Gama Aviation Switzerland',
  'Guangxi Beibu Gulf Airlines',
  'Gospa Air',
  'GMJ Air Shuttle',
  'Greenlandcopter',
  'Germania Express',
  'GB Helicopters',
  'Global Air Crew',
  'Global Avia Handling',
  'Golden Myanmar Airlines',
  'G & L Aviation',
  'G5 Executive',
  'GAK/Mitchell Aero',
  'GATSA',
  'GB Airlink',
  'GB Airways',
  'GCS Air Service',
  'GEC Marconi Avionics',
  'GECAS',
  'GENSA',
  'Get High',
  'GETRA',
  'GFW Aviation',
  'Trans Island Airways',
  'GH Stansted Limited',
  'GMG Airlines',
  'GP Express Airlines',
  'GPM Aeroservicio',
  'GR-Avia',
  'GST Aero Aircompany',
  'GTA Air',
  'Ga-Ma Helicoptere',
  'Gabon Express',
  'Gabon-Air-Transport',
  'Gacela Air Cargo',
  'Gail Force Express',
  'Gain Jet Aviation',
  'Galair International',
  'Galaircervis',
  'Galaxy Air',
  'Galaxy Airlines',
  'Galena Air Service',
  'Galileo International',
  'Gama Aviation',
  'Gambia International Airlines',
  'Gambia New Millennium Air',
  'Gamisa Aviación',
  'Gandalf Airlines',
  'Gander Aviation',
  'Garden State Airlines',
  'Garrison Aviation',
  'Garuda Indonesia',
  'Gatari Hutama Air Services',
  'Gauteng Air Cargo',
  'Gavina',
  'Gazpromavia',
  'Geesair',
  'Gemini Air Cargo',
  'Gendall Air',
  'Gendarmerie Belge',
  'National Gendarmerie',
  'General Aerospace',
  'General Airways',
  'General Aviation',
  'General Aviation Flying Services',
  'General Aviation Terminal',
  'General Motors',
  'Genex',
  'Geographic Air Surveys',
  'Georgian Airways',
  'Georgian Aviation Federation',
  'Georgian Cargo Airlines Africa',
  'Georgian National Airlines',
  'Great Barrier Airlines',
  'German Air Force',
  'German Army',
  'German Navy',
  'German Airways',
  'German Sky Airlines',
  'Germania',
  'Germanwings',
  "Gerry's Dnata",
  'Gesellschaft Fur Flugzieldarstellung',
  'APG Airlines',
  'Gestair',
  'Gestar',
  'Gestión Aérea Ejecutiva',
  'Ghadames Air Transport',
  'Globus Airlines',
  'Ghana International Airlines',
  'Gibson Aviation',
  'Global Air Charter',
  'Greybird Pilot Academy',
  'Global Air Services Nigeria',
  'Global Aircargo',
  'Global Airways (Turks and Caicos)',
  'Global Airways (BSP)',
  'Global Airways (GLB)',
  'Global Aviation Operations',
  'Global Aviation and Services Group',
  'Global Georgian Airways',
  'Global Jet Austria',
  'Global Jet Corporation',
  'Global Jet Luxembourg',
  'Global Sky Aircharter',
  'Global Supply Systems',
  'Global System',
  'Global Weather Dynamics',
  'Global Wings',
  'Globe Jet',
  'Go2Sky',
  'GoAir',
  'GoJet Airlines',
  'Gobierno De Guinea Ecuatorial',
  'Gof-Air',
  'Gofir',
  'Gol Transportes Aéreos',
  'GoldAir',
  'Goldeck-Flug',
  'Golden Air',
  'Golden Airlines',
  'Golden Pacific Airlines',
  'Golden Rule Airlines',
  'Golden Star Air Cargo',
  'Goldfields Air Services',
  'Golfe Air Quebec',
  'Goliaf Air',
  'Gomel Airlines',
  'Goodridge (UK) Limited',
  'Gorkha Airlines',
  'Gorlitsa Airlines',
  'Government Flying Service',
  'Government of Zambia Communications Flight',
  'Grampian Flight Centre',
  'Granada Aviación',
  'Grand Aire Express',
  'Grand Airways',
  'Grand Canyon Airlines',
  'Grant Aviation',
  'Grantex Aviation',
  'Guardian Air Asset Management',
  'Great Lakes Airlines',
  'Great Lakes Airways (Uganda)',
  'Great Plains Airlines',
  'Great Wall Airlines',
  'Great Western Air',
  'Hellenic Air Force',
  'Greek Navy',
  'Griffin Aviation',
  'Grixona',
  'Grizodubova Air Company',
  'Grossmann Air Service',
  'Grossmann Jet Service',
  'Ground Handling Service de Mexico',
  'Grup Air-Med',
  'Grupo De Aviación Ejecutiva',
  'Grupo TACA',
  'Grupo Vuelos Mediterraneo',
  'Grupo Aéreo Monterrey',
  'Guard Systems',
  'Guine Bissaur Airlines',
  'Guinea Airways',
  'Guinea Cargo',
  'Guinea Ecuatorial Airlines',
  'Guinee Paramount Airlines',
  'Guizhou Airlines',
  'Guja',
  'Gujarat Airways',
  'Gulf & Caribbean Cargo / Contract Air Cargo',
  'Gulf African Airlines',
  'Gulf Air',
  'Gulf Air Inc',
  'Gulf Central Airlines',
  'Gulf Flite Center',
  'Gulf Pearl Air Lines',
  'Gulfstream Aerospace',
  'Gulfstream Airlines',
  'Gulfstream International Airlines',
  'Gull Air',
  'Gum Air',
  'Guneydogu Havacilik Isletmesi',
  'Guyana Airways 2000',
  'Gwyn Aviation',
  'GlobeAir',
  'Air Guyane Express',
  'Costa Rica Green Airways',
  'Helix-Craft Aviation',
  'Heli-Charter',
  'Hunnu Air',
  'Hamburg Airways',
  'H.S.AVIATION CO., LTD.',
  'Heron Luftfahrt',
  'Hyperion Aviation',
  'Hi Fly Malta',
  'Hop!',
  'HC Airlines',
  'HPM Investments',
  'HT Helikoptertransport',
  'HTA Helicopteros',
  'Hadison Aviation',
  'Hageland Aviation Services',
  'Hagondale Limited',
  'Hahn Air',
  'Hahn Air Systems',
  'Hainan Airlines',
  'Hainan Phoenix Information Systems',
  'Haiti Air Freight',
  'Haiti Ambassador Airlines',
  'Haiti International Air',
  'Haiti International Airline',
  'Haiti National Airlines (HANA)',
  'Haiti North Airline',
  'Haiti Trans Air',
  'Haitian Aviation Line',
  'Hajvairy Airlines',
  'Hak Air',
  'Hala Air',
  'Halcyonair',
  'Hamburg International',
  'Hamlin Jet',
  'Hamra Air',
  'Hand D Aviation',
  'Hangar 8',
  'Hangard Aviation',
  'Hansung Airlines',
  'Hapag-Lloyd Express',
  'Hapagfly',
  'Harbor Airlines',
  'Harmony Airways',
  'Haughey Air',
  'Haverfordwest Air Charter Services',
  'Havilah Air Services',
  'Hawaiian Airlines',
  'Hawk Air',
  'Hawk De Mexico',
  'Hawkaire',
  'Heavylift Cargo Airlines',
  'Heavylift International',
  'Helcopteros De Cataluna',
  'Helenair (Barbados)',
  'Helenair Corporation',
  'Helenia Helicopter Service',
  'Heli Air Services',
  'Heli Ambulance Team',
  'Heli Bernina',
  'Heli France',
  'Heli Hungary',
  'Heli Medwest De Mexico',
  'Heli Securite',
  'Heli Trip',
  'Heli Union Heli Prestations',
  'Heli-Air-Monaco',
  'Heli-Holland',
  'Heli-Iberica',
  'Heli-Iberica Fotogrametria',
  'Heli-Inter Guyane',
  'Heli-Link',
  'Heliamerica De Mexico',
  'Heliavia-Transporte Aéreo',
  'Heliaviation Limited',
  'Helibravo Aviacao',
  'Helicap',
  'Helicentre Coventry',
  'Helicol',
  'Helicopter',
  'Helicopter & Aviation Services',
  'Helicopter Training & Hire',
  'Helicopteros Aero Personal',
  'Helicopteros Agroforestal',
  'Helicopteros Internacionales',
  'Helicópteros Y Vehículos Nacionales Aéreos',
  'Helicsa',
  'Helijet',
  'Helikopterdrift',
  'Helikopterservice Euro Air',
  'Heliocean',
  'Helios Airways',
  'Helipistas',
  'Heliportugal',
  'Heliservicio Campeche',
  'Helisul',
  'Heliswiss',
  'Helitafe',
  'Helitalia',
  'Helitaxi Ofavi',
  'Helitours',
  'Helitrans',
  'Helitrans Air Service',
  'Heliworks',
  'Hellas Jet',
  'Hello',
  'Helog',
  'Helvetic Airways',
  'Hemus Air',
  'Henebury Aviation',
  'Heritage Flight (Valley Air Services)',
  "Herman's Markair Express",
  'Heritage Aviation Developments',
  'Hewa Bora Airways',
  "Hex'Air",
  'Hi-Jet Helicopter Services',
  'Hi Fly',
  'High-Line Airways',
  'Highland Airways',
  'Himalaya Airlines',
  'Hispánica de Aviación',
  'Hispaniola Airways',
  'His Majesty King Maha Vajiralongkorn',
  'Hogan Air',
  'Hokkaido Air System',
  'Hokuriki-Koukuu Company',
  'Hola Airlines',
  'Holding International Group',
  'Holiday Airlines (US Airline)',
  'Holidays Czech Airlines',
  'Holstenair Lubeck',
  'Homac Aviation',
  'Honduras Airlines',
  'Hong Kong Airlines',
  'Hong Kong Air Cargo',
  'Hong Kong Express Airways',
  'Hongtu Airlines',
  'Honiara Cargo Express',
  'Hooters Air',
  'Hop-A-Jet',
  'Hope Air',
  'Horizon Air',
  'Horizon Air Service',
  'Horizon Air for Transport and Training',
  'Horizon Air-Taxi',
  'Horizon Plus',
  'Horizons Unlimited',
  'Horizontes Aéreos',
  'Hoteles Dinamicos',
  'Houston Helicopters',
  'Houston Jet Services',
  'Hozu-Avia',
  'Hub Airlines',
  'Huessler Air Service',
  'Hughes Aircraft Company',
  'Hummingbird Helicopter Service',
  'Hunair Hungarian Airlines',
  'Hungarian Air Force',
  'Hyack Air',
  'Hydro Air Flight Operations',
  'Hydro-Québec',
  'Héli Sécurité Helicopter Airlines',
  'Hawker Beechcraft',
  'H-Bird Aviation Services AB',
  'Interjet Inc.',
  'Inter Island Air Charter',
  'Island Air Express',
  'Island Wings',
  'Ikar',
  'Iconair',
  'Interaviation Charter',
  'Ifly',
  'International Air Carrier Association',
  'IBC Airways',
  'IBL Aviation',
  'IBM Euroflight Operations',
  'ICAO',
  'Independent Carrier (ICAR)',
  'ICC Canada',
  'IDG Technology Air',
  'IFL Group',
  'II Lione Alato Arl',
  'IJM International Jet Management',
  'IKI International Airlines',
  'IKON FTO',
  'IMP Aviation Services',
  'IMP Group Aviation Services',
  'INFINI Travel Information',
  'IPEC Aviation',
  'IPM Europe',
  'IRS Airlines',
  'ISD Avia',
  'ITA Software',
  'IVV Femida',
  'Iberia Airlines',
  'Iberia Express',
  'Ibertour Servicios Aéreos',
  'Ibertrans Aérea',
  'Iberworld',
  'Ibex Airlines',
  'Ibicenca Air',
  'Ibk-Petra',
  'Icar Air',
  'Icare Franche Compte',
  'Norwegian Air International (Norway)',
  'Icaro',
  'Icarus',
  'Icejet',
  'Icelandair',
  'Icelandic Coast Guard',
  'Ikaros DK',
  'Il Ciocco International Travel Service',
  'Il-Avia',
  'Ildefonso Redriguez',
  'Iliamna Air Taxi',
  'Ilpo Aruba Cargo',
  'Ilyich-Avia',
  'Imaer',
  'Imair Airlines',
  'Imperial Airways',
  'Imtrec Aviation',
  'Independent Air Freighters',
  'IndiGo',
  'India International Airways',
  'Indian Air Force',
  'Indian Airlines',
  'Indicator Company',
  'Indigo Airlines',
  'Indonesia Air Transport',
  'Indonesia AirAsia',
  'Indonesian Airlines',
  'Industri Pesawat Terbang Nusantara',
  'Industrias Titan',
  'Infinit Air',
  'Zimex Aviation',
  'Inflite The Jet Centre',
  'Innotech Aviation',
  'Insel Air International',
  'Institut Cartogràfic de Catalunya',
  'Intair',
  'Intal Avia',
  'Intavia Limited',
  'Intensive Air',
  'Inter Air',
  'Inter Express',
  'Inter Island Airways',
  'Inter RCA',
  'Inter Tropic Airlines',
  'Inter-Air',
  'Inter-Canadian',
  'Inter-Island Air',
  'Inter-Mountain Airways',
  'Inter-State Aviation',
  'Interair South Africa',
  'Interaire',
  'Interavia Airlines',
  'Interaviatrans',
  'Intercontinental de Aviación',
  'Intercopters',
  'Interflight',
  'Interflight (Learjet)',
  'Interfly',
  'Interfreight Forwarding',
  'Interguide Air',
  'Interisland Airlines',
  'Interisland Airways Limited',
  'Interjet',
  'Interjet Helicopters',
  'Interlink Airlines',
  'International Air Cargo Corporation',
  'International Air Corporation',
  'T3 Aviation, Inc.',
  'International Air Services',
  'International Business Air',
  'International Business Aircraft',
  'International Charter Services',
  'International Committee of the Red Cross',
  'International Company for Transport, Trade and Public Works',
  'International Flight Training Academy',
  'International Jet Aviation Services',
  'International Jet Charter',
  'International SOS Windhoek',
  'International Stabilisation Assistance Force',
  'International Security Assistance Force',
  'International Trans-Air',
  'Interport Corporation',
  'Intersky Bulgary',
  'Intervuelos',
  'Inversija',
  'Iona National Airways',
  'Iowa Airways',
  'Iran Air',
  'Iran Aseman Airlines',
  'Iranair Tours',
  'Iranian Naft Airlines',
  'Iraqi Airways',
  'Irbis Air',
  'Irish Air Corps',
  'Irish Air Transport',
  'Irish Aviation Authority',
  'Irtysh Air',
  'Irving Oil',
  'Island Air',
  'Island Air Charters',
  'Island Airlines',
  'Island Aviation',
  'Island Aviation Services',
  'Island Aviation and Travel',
  'Island Express',
  'Island Helicopters',
  'ILAS Air',
  'Islandair Jersey',
  'Islands Nationair',
  'Icebird Airline',
  'Islas Airways',
  'Isle Grande Flying School',
  'Islena De Inversiones',
  'Isles of Scilly Skybus',
  'Israel Aircraft Industries',
  'Israeli Air Force',
  'Israir',
  'Istanbul Airlines',
  'Itali Airlines',
  'Italy First',
  'Itek Air',
  'Ivoire Aero Services',
  'Ivoire Airways',
  'Ivoire Jet Express',
  'Iwamoto Crane Co Ltd',
  'Ixair',
  'Izair',
  'Izhavia',
  'Iceland Express',
  'Imperial Cargo Airlines',
  'Jung Sky',
  'Jet Story',
  'Jet Time',
  'Jinggong Jet',
  'Journey Aviation',
  'Justice Air Charter',
  'Jayawijaya Dirgantara',
  'Jet-stream',
  'J C Bamford (Excavators)',
  'J P Hunt Air Carriers',
  'J-Air',
  'JAL Express',
  'JALways',
  'JDAviation',
  'JDP Lux',
  'JHM Cargo Expreso',
  'JM Family Aviation',
  'JMC Airlines',
  'JSC Transport Automated Information Systems',
  'JS Air',
  'JS Aviation',
  'Jackson Air Services',
  'Jade Cargo International',
  'Jamahiriya Airways',
  'Jambo Africa Airlines',
  'Janet',
  'Jet Sky Cargo and Air Charter',
  'Jana-Arka',
  'Janair',
  'Japan Air Commuter',
  'Japan Aircraft Service',
  'Japan Airlines',
  'Japan Transocean Air',
  'JetSMART',
  'Jatayu Airlines',
  'Jazeera Airways',
  'Jeju Air',
  'Jenney Beechcraft',
  'Jeppesen Data Plan',
  'Jeppesen UK',
  'Jet Air Group',
  'Jet Airways',
  'Jet Aspen Air Lines',
  'Jet Aviation',
  'Jet Aviation Business Jets',
  'Jet Aviation Flight Services',
  'Jet Center Flight Training',
  'Jet Charter',
  'Jet Courier Service',
  'Jet East International',
  'Jet Executive International Charter',
  'Jet Fighter Flights',
  'Jet Freighters',
  'Jet G&D Aviation',
  'Jet Line International',
  'Jet Link',
  'Jet Linx Aviation',
  'Jet Norte',
  'Jet Rent',
  'Jetstar Asia Airways',
  'Jet Stream',
  'Jet Trans Aviation',
  'Jet-2000',
  'Jet-Ops',
  'Jet2.com',
  'Jet4You',
  'JetAfrica Swaziland',
  'JetBlue Airways',
  'Jet Asia Airways',
  'Jetairfly',
  'Jetall Holdings',
  'Jetalliance',
  'Jetclub',
  'Jetconnect',
  'Jetcorp',
  'Jetcraft Aviation',
  'Jetex Aviation',
  'Jetflite',
  'Jetfly Airlines',
  'Jetfly Aviation',
  'Jetgo International',
  'Jetlink Express',
  'JetNetherlands',
  'Jetnova de Aviación Ejecutiva',
  'Jetpro',
  'Jetran Air',
  'Jetrider International',
  'Jets Ejecutivos',
  'Jets Personales',
  'Jets Y Servicios Ejecutivos',
  'Jetstar',
  'Jetstar Japan',
  'Jetstar Hong Kong Airways',
  'Jetstream Executive Travel',
  'JetSuite',
  'Jett Paqueteria',
  'Jettime',
  'Jet Test Intl.',
  'Jetways of Iowa',
  'JetX Airlines',
  'Jibair',
  'Jigsaw Project',
  'Jim Hankins Air Service',
  'Jim Ratliff Air Service',
  'Joanas Avialinijos',
  'Job Air',
  'Johnson Air',
  'Johnsons Air',
  'Johnston Airways',
  'Joint Military Commission',
  'Jomartaxi Aereo',
  'Jonsson, H Air Taxi',
  'Jordan Aviation',
  'Jordan International Air Cargo',
  'Jorvik',
  'Jota Aviation',
  'Journey Jet',
  'JSX (airline)',
  'Ju-Air',
  'Juanda Flying School',
  'Juba Cargo Services & Aviation Company',
  'Jubilee Airways',
  'Juneyao Airlines',
  'Justair Scandinavia',
  'Justice Prisoner and Alien Transportation System',
  'KC International Airlines',
  'Kolob Canyons Air Services',
  'Kharkiv Airlines',
  'Kyrgyz Airlines',
  'K D Air Corporation',
  'K S Avia',
  'K-Mile Air',
  'Kalstar Aviation',
  'KD Avia',
  'KLM Cityhopper',
  'KLM Helicopter',
  'KLM',
  'Kabo Air',
  'Kahama Mining Corporation',
  'KaiserAir',
  'Kalitta Air',
  'Kalitta Charters',
  'Kalitta Charters II',
  'Kallat El Saker Air Company',
  'Kam Air',
  'Kampuchea Airlines',
  'Kanaf-Arkia Airlines',
  "Kanfey Ha'emek Aviation",
  'Kansas State University',
  'Karat',
  'Karibu Airways Company',
  'Karthago Airlines',
  'Kartika Airlines',
  'Kata Transportation',
  'Katekavia',
  'Kato Airline',
  'Kavminvodyavia',
  'Kavouras Inc',
  'Kaz Agros Avia',
  'Kaz Air West',
  'Kazan Aviation Production Association',
  'Kazan Helicopters',
  'Kazavia',
  'Kazaviaspas',
  'Keenair Charter -',
  'Kelix Air',
  'Kelner Airways',
  'Kelowna Flightcraft Air Charter',
  'Kenmore Air',
  'Kenn Borek Air',
  'Kent Aviation',
  'Kenya Airways',
  'Kevis',
  'Key Airlines',
  'Key Lime Air',
  'Keystone Aerial Surveys',
  'Keystone Air Service',
  'Khalifa Airways',
  'Kharkov Aircraft Manufacturing Company',
  'Khazar',
  'Khoezestan Photros Air Lines',
  'Khoriv-Avia',
  'Khors Aircompany',
  'Khyber Afghan Airlines',
  'Kiev Aviation Plant',
  'King Aviation',
  'Kingfisher Air Services',
  'Kingfisher Airlines',
  'Knighthawk Air Express',
  'Kingston Air Services',
  'Kinnarps',
  'Kirov Air Enterprise',
  'KISHAIR',
  'Kiwi International Air Lines',
  'Kiwi Regional Airlines',
  'Knight Air',
  'Knighthawk Express',
  'Knights Airlines',
  'Koanda Avacion',
  'Koda International',
  'Kogalymavia Air Company',
  'Kom Activity',
  'Komiaviatrans State Air Enterprise',
  'Komiinteravia',
  'Komsomolsk-on-Amur Air Enterprise',
  'Koob-Corp - 96 KFT',
  'Korean Air',
  'Kosmas Air',
  'Kosmos',
  'Kosova Airlines',
  'Kovar Air',
  'Kremenchuk Flight College',
  'Krimaviamontag',
  'Kroonk Air Agency',
  'Krylo Airlines',
  'Krym',
  'Krystel Air Charter',
  'Kuban Airlines',
  'Kunpeng Airlines',
  'Kurzemes Avio',
  'Kustbevakningen',
  'Kuwait Airways',
  'Kuzu Airlines Cargo',
  'Kvadro Aero',
  'Kwena Air',
  'Kyrgyz Trans Avia',
  'Kyrgyzstan',
  'Kyrgyzstan Airlines',
  'Kyrgyzstan Department of Aviation',
  'Kyrgz General Aviation',
  'Keewatin Air',
  'Livingstone Executive',
  'Lease Fly',
  'Lepl Project Limited (Air Costa)',
  'Liebherr Geschaeftreiseflugzeug',
  'Lloyd Aviation',
  'Logistic Air',
  'Legacy Air',
  'LeTourneau University',
  'Liberty Air',
  'Lowlevel',
  'Laminar Air',
  'Libyan Wings',
  'TAR Aerolineas',
  'Lynden Air Cargo',
  'L A Helicopter',
  'L J Aviation',
  'L R Airlines',
  'L&L Flygbildteknik',
  "L'Express Airlines",
  'L-3 Communications Flight International Aviation',
  'L.A.B. Flying Service',
  'LACSA',
  'LADE - Líneas Aéreas Del Estado',
  'LAI - Línea Aérea IAACA',
  'LATAM Chile',
  'LATAM Argentina',
  'LATAM Cargo Chile',
  'LATAM Colombia',
  'LATAM Paraguay',
  'LAN Dominicana',
  'LATAM Express',
  'LATAM Peru',
  'LANICA',
  'LANSA',
  'LAP Colombia - Líneas Aéreas Petroleras, S.A.',
  'LASTP',
  'LC Busre',
  'Línea Aérea Cuencana',
  'LOT Polish Airlines',
  'LTE International Airways',
  'LTU Austria',
  'LTU International',
  'LTV Jet Fleet Corporation',
  'LUKoil-Avia',
  'La Ronge Aviation Services',
  'La Valenciana Taxi Aéreo',
  'Labcorp',
  'Labrador Airways',
  'Lagun Air',
  'Lake Havasu Air Service',
  'Lakeland Aviation',
  'Laker Airways',
  'Laker Airways (Bahamas)',
  'Lamra',
  'Lanaes Aereas Trans Costa Rica',
  'Landsflug',
  'Langtry Flying Group',
  'Lankair',
  'Lankan Cargo',
  'Lanza Air',
  'Lanzarote Aerocargo',
  'Lao Skyway',
  'Lao Airlines',
  'Lao Capricorn Air',
  'Laoag International Airlines',
  'Laredo Air',
  'Latvian Air Force',
  'Lauda Air',
  'LaudaMotion',
  'Lauda Air Italy',
  'Laughlin Express',
  'Laus',
  'Lawrence Aviation',
  'Layang-Layang Aerospace',
  'Lease-a-Plane International',
  'Lebanese Air Transport',
  'Lebanese Air Transport (charter)',
  'Lebanon Airport Development Corporation',
  'Lebap',
  'Leconte Airlines',
  'Leeward Islands Air Transport',
  'Legend Airlines',
  'Leisure Air',
  'Lentini Aviation',
  'Leo-Air',
  'Leonsa De Aviación',
  'Level',
  'Libyan Airlines',
  'Libyan Arab Airlines',
  'Libyan Arab Air Cargo',
  'Lid Air',
  'LIFT Academy',
  'Lignes Aeriennes Congolaises',
  'Lignes Aeriennes Du Tchad',
  'Lignes Mauritaniennes Air Express',
  'Lignes Nationales Aeriennes - Linacongo',
  'Lincoln Air National Guard',
  'Lincoln Airlines',
  'Lindsay Aviation',
  'Línea Aérea Costa Norte',
  'Línea Aérea Mexicana de Carga',
  'Línea Aérea SAPSA',
  'Línea Aérea de Fumig Aguas Negras',
  'Línea Aérea de Servicio Ejecutivo Regional',
  'Línea De Aeroservicios',
  'Línea Turística Aereotuy',
  'Líneas Aéreas Alaire S.L.',
  'Líneas Aéreas Canedo LAC',
  'Líneas Aéreas Comerciales',
  'Líneas Aéreas Ejectuivas De Durango',
  'Líneas Aéreas Eldorado',
  'Líneas Aéreas Federales',
  'Líneas Aéreas Monarca',
  'Líneas Aéreas San Jose',
  'Líneas Aéreas del Humaya',
  'Linex',
  'Linhas Aéreas Santomenses',
  'Linhas Aéreas de Moçambique',
  'Link Airways of Australia',
  'Volkswagen AirService GmbH',
  'Lion Air',
  'Lions-Air',
  'Lithuanian Air Force',
  'Little Red Air Service',
  'Livingston Energy Flight',
  'Lloyd Aéreo Boliviano',
  'Lnair Air Services',
  'Lockheed Air Terminal',
  'Lockeed Aircraft Corporation',
  'Lockheed DUATS',
  'Lockheed Martin Aeronautics',
  'Lockheed Martin Aeronautics Company',
  'Loganair',
  'Lom Praha Flying School',
  'Lomas Helicopters',
  'London City Airport Jet Centre',
  'London Executive Aviation',
  'London Flight Centre (Stansted)',
  'London Helicopter Centres',
  'Lone Star Airlines',
  'Long Island Airlines',
  'Longtail Aviation',
  'Lorraine Aviation',
  'Los Cedros Aviación',
  'Lotus Air',
  'Luchtvaartmaatschappij Twente',
  'Lucky Air',
  'Luft Carago',
  'Luftfahrt-Vermietungs-Dienst',
  'Lufthansa',
  'Lufthansa Cargo',
  'Lufthansa CityLine',
  'Lufthansa Systems',
  'Lufthansa Technik',
  'Lufttaxi Fluggesellschaft',
  'Lufttransport',
  'Luhansk',
  'Lund University School of Aviation',
  'Luxair',
  'Luxaviation',
  'Luxembourg Air Rescue',
  'Luxflight Executive',
  'Luxor Air',
  'Luzair',
  'Lviv Airlines',
  'Lydd Air',
  'Lynch Flying Service',
  'Luxwing',
  'Lynx Air',
  'Lynx Air International',
  'Lynx Aviation',
  'Línea Aérea Amaszonas',
  'Líneas Aéreas Suramericanas',
  'LongJiang Airlines',
  'Jin Air',
  'Maldivian (airline)',
  'McMahon Helicopter',
  'Mahogany Air Charters',
  'Med Jets',
  'Minsheng International Jet',
  'Millon Express',
  'Maritime Helicopters',
  'MarkAir',
  'Modern Transporte Aereo De Carga',
  'Magnum Air',
  'Malawian Airlines 2014',
  'Mann Yadanarpon Airlines',
  'Memorial Hermann Hospital System',
  'Multiservicios Aereos Del Valle',
  'Macau Jet International',
  'Maximum Flight Advantages',
  'Malindo Airways',
  'Mandarin Air',
  'MBK-S',
  'Midwest Aviation',
  'Milenium Air Servicios Aereos Integrados',
  'Mountain Flyers 80',
  'My Fair Jet',
  'M & N Aviation',
  'MAC Fotografica',
  "MANAG'AIR",
  'MAP-Management and Planung',
  'MAS Airways',
  'MasAir',
  'MASwings',
  'MCC Aviation',
  'MG Aviación',
  'MIA Airlines',
  'MIAT Mongolian Airlines',
  'MIT Airlines',
  'MK Airline',
  'MNG Airlines',
  'MSR Flug-Charter',
  'MTC Aviación',
  'Mac Aviation',
  'Mac Dan Aviation Corporation',
  'MacKnight Airlines',
  'Macair Airlines',
  'Macedonian Airlines',
  'Madina Air',
  'Magic Blue Airlines',
  'Magna Air',
  'Mahalo Air',
  'Mahan Air',
  'Mahfooz Aviation',
  'Maine Aviation',
  'Majestic Airlines',
  'Mak Air',
  'Malagasy Airlines',
  'Malawi Express',
  'Malaya Aviatsia Dona',
  'Malaysia Airlines',
  'Maleth-Aero',
  'Mali Air',
  'Mali Air Express',
  'Mali Airways',
  'Malila Airlift',
  'Mall Airways',
  'Malmoe Air Taxi',
  'Malmö Aviation',
  'Malta Air',
  'Malta Wings',
  'Manaf International Airways',
  'Mandala Airlines',
  'Mandarin Airlines',
  'Mango',
  'Manhattan Air',
  'Manitoulin Air Services',
  'Mann Air',
  'Mannion Air Charter',
  'Mantrust Asahi Airways',
  'Manx Airlines',
  'Maple Air Services',
  'March Helicopters',
  'Marcopolo Airways',
  'Marghi Air',
  'Markair',
  'Markoss Aviation',
  'Mars RK',
  'Marshall Aerospace',
  'Marsland Aviation',
  'Martin Aviation Services',
  'Martin-Baker',
  'Martinair',
  'Martinaire',
  'Martyn Fiddler Associates',
  'Marvin Limited',
  'Maryland State Police',
  'Massachusetts Institute of Technology',
  'Massey University School of Aviation',
  'Master Airways',
  'Master Planner',
  'Masterjet',
  'Mastertop Linhas Aéreas',
  'Mauria',
  'Mauritanienne Aerienne Et Navale',
  'Mauritanienne Air Fret',
  'Mauritanienne Airways',
  'Mauritanienne De Transport Aerien',
  'Maverick Airways',
  'Mavial Magadan Airlines',
  'Max Avia',
  'Max Sea Food',
  'Max-Aviation',
  'Maxair',
  'Maximus Air Cargo',
  'Maxsus-Avia',
  'May Air Xpress',
  'Maya Island Air',
  'Mayair',
  'Mbach Air',
  'McAlpine Helicopters',
  'McCall Aviation',
  'McDonnell Douglas',
  'McNeely Charter Services',
  'Med-Trans of Florida',
  'Medavia',
  'Medical Air Rescue Services',
  'Medical Aviation Services',
  'Mediterranean Air Freight',
  'Mediterranean Airways',
  'Medjet International',
  'Mega',
  'Mega Linhas Aéreas',
  'Menajet',
  'Merchant Express Aviation',
  'Mercury Aircourier Service',
  'Meridian',
  'Meridian Air Cargo',
  'Meridian Airlines',
  'Meridian Aviation',
  'Meridian Limited',
  'Meridiana',
  'Merlin Airways',
  'Merpati Nusantara Airlines',
  'Mesa Airlines',
  'Mesaba Airlines',
  'Meta Linhas Aéreas',
  'Meteorological Research Flight',
  'Methow Aviation',
  'Metro Business Aviation',
  'Metro Express',
  'Metroflight',
  'Metrojet',
  'Metropix UK',
  'Metropolis',
  'Mex Blue',
  'Mex-Jet',
  'Mexicana de Aviación',
  'México Transportes Aéreos',
  'Miami Air Charter',
  'Miami Air International',
  'Miami Valley Aviation',
  'Miapet-Avia',
  'Michelin Air Services',
  'Micromatter Technology Solutions',
  'Mid Airlines',
  'Mid-Pacific Airlines',
  'Midamerica Jet',
  'Middle East Airlines',
  'Midland Airport Services',
  'Midline Air Freight',
  'Midstate Airlines',
  'Midway Express',
  'Midwest Air Freighters',
  'Midwest Airlines',
  'Midwest Airlines (Egypt)',
  'Midwest Aviation Division',
  'Midwest Helicopters De Mexico',
  'Mihin Lanka',
  'Millardair',
  'Millen Corporation',
  'Millennium Air',
  'Millennium Airlines',
  'Miller Flying Services',
  'Million Air',
  'Mimino',
  'Mina Airline Company',
  'Minair',
  'Minebea Technologies',
  'Mines Air Services Zambia',
  'Miniliner',
  'Ministic Air',
  'Ministry of Agriculture, Fisheries and Food',
  'Minsk Aircraft Overhaul Plant',
  'Mint Airways',
  'Miramichi Air Service',
  'Miras',
  'Misr Overseas Airways',
  'Mission Aviation Fellowship',
  'Missionair',
  'Missions Gouvernemtales Francaises',
  'Mississippi State University',
  'Mississippi Valley Airways',
  'Mistral Air',
  'Mobil Oil',
  'Mocambique Expresso',
  'Mofaz Air',
  'Mohawk Airlines',
  'Mokulele Airlines',
  'Moldaeroservice',
  'Moldavian Airlines',
  'Moldova',
  'Mombasa Air Safari',
  'Monarch Airlines',
  'Myway Airlines',
  'Moncton Flying Club',
  'Monde Air Charters',
  'Monerrey Air Taxi',
  'Monky Aerotaxis',
  'Montenegro Airlines',
  'Montserrat Airways',
  'Mooney Aircraft Corporation',
  'Morningstar Air Express',
  'Morris Air Service',
  'Morrison Flying Service',
  'Moskovia Airlines',
  'Mosphil Aero',
  'Motor Sich',
  'Mount Cook Airline',
  'Mountain Air Cargo',
  'Mountain Air Company',
  'Mountain Air Express',
  'Mountain Air Service',
  'Mountain Bird',
  'Mountain High Aviation',
  'Mountain Pacific Air',
  'Mountain Valley Air Service',
  'Mudan Airlines',
  'Mudanjiang General Aviation',
  'Multi Taxi',
  'Multi-Aero',
  'Multiflight',
  'Murmansk Aircompany',
  'Murray Air',
  'Musrata Air Transport',
  'Mustique Airways',
  'MyWay Airlines',
  'Myanma Airways',
  'Myanmar Airways International',
  'Minoan Air',
  'Myflug',
  'Mann Air Ltd',
  'MHS Aviation GmbH',
  'Titan Airways',
  'Middle Tennessee State University',
  "École Nationale de l'Aviation Civile",
  'Nine Star Airways',
  'Northeastern Aviation',
  'National Center for Atmospheric Research',
  'Netjets Business Aviation',
  'NHV Aviation',
  'Northern Helicopter',
  'Namdeb Diamond Corporation',
  'Niger Airlines',
  'Fly Easy',
  'Nomad Aviation',
  'New Japan Aviation',
  'Nor Aviation',
  'NEL Cargo',
  'NHT Linhas Aéreas',
  'NZ Warbirds Association',
  'Nacoia Lda',
  'Nada Air Service',
  'Compangnie Nationale Naganagani',
  'Nahanni Air Services Ltd',
  'Nakheel Aviation',
  'Namibia Commercial Aviation',
  'Namibian Defence Force',
  'Nanjing Airlines',
  'Nantucket Airlines',
  'Nanyah Aviation',
  'Napier Air Service Inc',
  'Nas Air',
  'Nasair',
  'Nashville Jet Charters',
  'Natalco Air Lines',
  'National Air Traffic Controllers Association',
  'National Air Charter',
  'National Air Traffic Services',
  'National Airlines',
  'National Air Cargo dba National Airlines',
  'NAM Air',
  'National Airways Cameroon',
  'National Airways Corporation',
  'National Aviation Company',
  'National Aviation Consultants',
  'National Express',
  'National Grid plc',
  'National Jet Express',
  'National Jet Service',
  'National Jet Systems',
  'National Overseas Airlines Company',
  'Nationale Luchtvaartschool',
  'Nations Air Express Inc',
  'Nationwide Airlines',
  'Nationwide Airlines (Zambia)',
  'Natural Environment Research Council',
  'Natureair',
  'Naturelink Charter',
  'Nav Canada',
  'Nav Flight Planning',
  'Navegacao Aérea De Portugal',
  'Navegación Servicios Aéreos Canarios S.A.',
  'Navid',
  'Naviera Mexicana',
  'Navigator Airlines',
  'Navinc Airlines Services',
  'Navitaire',
  'Navtech System Support',
  'Nayzak Air Transport',
  'State of Nebraska',
  'Necon Air',
  'Nederlandse Kustwacht',
  'Nefteyugansk Aviation Division',
  'Neiltown Air',
  'Nelair Charters',
  'Nelson Aviation College',
  'Nepal Airlines',
  'Neos',
  'Neosiam Airways',
  'Neric',
  'NetJets',
  'Network Aviation Services',
  'New England Air Express',
  'New England Airlines',
  'New Heights 291',
  'New World Jet Corporation',
  'New York Helicopter',
  'New York State Police',
  'Royal New Zealand Air Force',
  'Newair',
  'Newfoundland Labrador Air Transport',
  'NextJet',
  'Nextflight Aviation',
  'Nexus Aviation',
  'Nicaragüense de Aviación',
  'Nicon Airways',
  'Nigeria Airways',
  'Nigerian Air Force',
  'Nigerian Global',
  'Night Express',
  'Niki',
  'Nikolaev-Air',
  'Nile Safaris Aviation',
  'Nile Wings Aviation Services',
  'Nimbus Aviation',
  'Nippon Cargo Airlines',
  'Nizhnevartovskavia',
  'Search and Rescue 202',
  'Search and Rescue 22',
  'No. 32 (The Royal) Squadron',
  'No. 84 Squadron RAF',
  'Nobil Air',
  'Nok Air',
  'NokScoot',
  'Nolinor Aviation',
  'Norcopter',
  'Nord-Flyg',
  'Nordavia',
  'Nordeste Linhas Aéreas Regionais',
  'Nordic Regional',
  'NordStar',
  'Nordstree (Australia)',
  'Nordwind Airlines',
  'Norestair',
  'Norfolk County Flight College',
  'Norlandair',
  'Norontair',
  'Norrlandsflyg',
  'Norse Air Charter',
  'Norse Atlantic Airways',
  'Norsk Flytjeneste',
  'Norsk Helikopter',
  'Norsk Luftambulanse',
  'Nortavia',
  'North Adria Aviation',
  'North American Airlines',
  'North American Charters',
  'North American Jet Charter Group',
  'North Atlantic Air Inc',
  'North Atlantic Cargo',
  'North British Airlines',
  'North Caribou Flying Service Ltd',
  'North Coast Air Services Ltd',
  'North Coast Aviation',
  'North Flying',
  'North Sea Airways',
  'North Star Air Cargo',
  'North Vancouver Airlines',
  'North West Airlines',
  'North West Geomatics',
  'North-East Airlines',
  'North-West Air Transport Company - Vyborg',
  'North-Wright Airways',
  'Northafrican Air Transport',
  'Northaire Freight Lines',
  'Northamptonshire School of Flying',
  'Northcoast Executive Airlines',
  'Northeast Airlines',
  'Northeast Aviation',
  'Northern Air Cargo',
  'Northern Airlines Sanya',
  'Northern Airways',
  'Northern Aviation Service',
  'Northern Dene Airways',
  'Northern Executive Aviation',
  'Northern Illinois Commuter',
  'Northern Jet Management',
  'Northern Thunderbird Air',
  'Northland Aviation',
  'Northstar Aviation',
  'Northumbria Helicopters',
  'Northway Aviation Ltd',
  'Northwest Aero Associates',
  'Northwest Regional Airlines',
  'Northwest Territorial Airways',
  'Northwestern Air',
  'Northwinds Northern',
  'Nortland Air Manitoba',
  'Norwegian Air Shuttle',
  'Norwegian Air UK',
  'Norwegian Air Argentina',
  'Norwegian Air International',
  'Norwegian Long Haul',
  'Norwegian Air Norway',
  'Norwegian Air Sweden',
  'Norwegian Aviation College',
  'Notams International',
  'Nouvel Air Tunisie',
  'Nova Airline',
  'Nova Scotia Department of Lands and Forests',
  'Novair',
  'Novo Air',
  'Novogorod Air Enterprise',
  'Novosibirsk Aircraft Repairing Plant',
  'Novosibirsk Aviaenterprise',
  'Novosibirsk Aviation Production Association',
  'Noy Aviation',
  'Nuevo Continente',
  'Nuevo Horizonte Internacional',
  'Nunasi-Central Airlines',
  'Nurman Avia Indopura',
  'Nyasa Express',
  'NetJets Europe',
  'Orange Air',
  'One Airlines',
  'Old Dominion Freight Lines',
  'O Air',
  'Omni Air Transport',
  'Owenair',
  'OSACOM',
  'Ocean Air',
  'Ocean Airlines',
  'Ocean Sky UK',
  'Ocean Wings Commuter Service',
  'Linear Air',
  'Odessa Airlines',
  "Office Federal De'Aviation Civile",
  'Ogooue Air Cargo',
  'Okada Airlines',
  'Okapi Airways',
  'Okay Airways',
  'Oklahoma Department of Public Safety',
  'Olimex Aerotaxi',
  'Olimp Air',
  'Olympic Air',
  'Olympic Aviation',
  'Oman Air',
  'Oman Royal Flight',
  'SalamAir',
  'Omni - Aviacao e Tecnologia',
  'Laudamotion',
  'Omni Air International',
  'Omniflys',
  'On Air Limited',
  'Oklahoma State University',
  'One Two Go Airlines',
  'Onetime Airlines Zambia',
  'Ontario Ministry of Health',
  'Onur Air',
  'Opal Air',
  'Open Sky Aviation',
  'OpenSkies',
  'Operadora Turistica Aurora',
  'Operadora de Lineas Ejecutivas',
  'Operadora de Transportes Aéreos',
  'Operadora de Veulos Ejectutivos',
  'Operation Enduring Freedom',
  'Operational Aviation Services',
  'Orange Air Services',
  'Orange Air Sierra Leone',
  'Orange Aviation',
  'Orbit Express Airlines',
  'Orca Air',
  'Orebro Aviation',
  'Orel State Air Enterprise',
  'Orenburg Airlines',
  'Organizacion De Transportes Aéreos',
  'Organizacoes Mambra',
  'Orient Air',
  'Orient Airlines',
  'Orient Airways',
  'Orient Thai Airlines',
  'Oriental Air Bridge',
  'Oriental Airlines',
  'Origin Pacific Airways',
  'Orion Air Charter',
  'Orion-x',
  'Orlan-2000',
  'Orange Aircraft Leasing',
  'Orscom Tourist Installations Company',
  'Osh Avia',
  'Ostend Air College',
  'OLT Express Germany',
  'Odyssey International',
  'Oulun Tilauslento',
  'Our Airline',
  'Out Of The Blue Air Safaris',
  'Overland Airways',
  'ONE AIR',
  'Oxaero',
  'Oxford Air Services',
  'Oxley Aviation',
  'OzJet',
  'Ohio State University',
  'Oasis International Airlines / Boliviana de Aviacion (BoA)',
  'Czech Airlines',
  'Pilot Flight Academy',
  'Pursuit Aviation',
  'Palau National Airlines',
  'Panama Aircraft Rental and Sales',
  'Pete Air',
  'Prime Service Italia',
  'Pacific Coast Jet',
  'Phoenix Helicopter Academy',
  'Pel-Air Aviation',
  'Pixair Survey',
  'Prince Aviation',
  'Primero Transportes Aereos',
  'P & P Floss Pick Manufacturers',
  'PAC Air',
  'PAN Air',
  'PB Air',
  'PDQ Air Charter',
  'PHH Aviation System',
  'PLM Dollar Group',
  'PLUNA',
  'PMTair',
  'PRT Aviation',
  'PSA Airlines',
  'PTL Luftfahrtunternehmen',
  'Paccair',
  'Pace Airlines',
  'Pacific Air Boats',
  'Pacific Air Charter',
  'Pacific Air Express',
  'Pacific Air Transport',
  'Pacific Airlines',
  'Pacific Alaska Airlines',
  'Pacific Aviation (Australia)',
  'European Flight Academy / Lufthansa Aviation Training',
  'Pacific Aviation (United States)',
  'Pacific Blue',
  'Pacific Coast Airlines',
  'Pacific Coastal Airlines',
  'Pacific East Asia Cargo Airlines',
  'Pacific Flight Services',
  'Pacific International Airlines',
  'Pacific Island Aviation',
  'Pacific Jet',
  'Pacific Pearl Airways',
  'Pacific Rim Airways',
  'Pacific Wings',
  'Pacificair',
  'Pacificair Airlines',
  'Package Express',
  'Paisajes Españoles',
  'Pak West Airlines',
  'Pakistan International Airlines',
  'Pakker Avio',
  'Pal Aerolíneas',
  'Palau Asia Pacific Airlines',
  'Palau Trans Pacific Airlines',
  'Palestinian Airlines',
  'Palmer Aviation',
  'Pamir Airways',
  'Pan African Air Services',
  'Pan African Airways',
  'Pan Air',
  'Pan Am Weather Systems',
  'PAWA Dominicana',
  'Pan American World Airways',
  'Pan Havacilik Ve Ticaret',
  'Pan Malaysian Air Transport',
  'Pan-Air',
  'Panagra Airways',
  'Panamedia',
  'Panavia',
  'Panh',
  'Pannon Air Service',
  'Panorama',
  'Panorama Air Tour',
  'Panorama Flight Service',
  'Pantanal Linhas Aéreas',
  'Papair Terminal',
  'Paradise Airways',
  'Paradise Island Airways',
  'Paragon Air Express',
  'Paragon Global Flight Support',
  'Paramount Airlines',
  'Paramount Airways',
  'Parcel Express',
  'Pariz Air',
  'Pars Aviation Service',
  'Parsa',
  'Parsons Airways Northern',
  'Pascan Aviation',
  'Passaredo Transportes Aéreos',
  'Patria Cargas Aéreas',
  'Patriot Aviation Limited',
  'Patterson Aviation Company',
  'Pawan Hans',
  'Payam Air',
  'Peach Air',
  'Pearl Air',
  'Pearl Air Services',
  'Pearl Airways',
  'Peau Vavaʻu',
  'Pecotox Air',
  'Pegasus Airlines',
  "People's",
  'Pegasus Hava Tasimaciligi',
  'Pegasus Helicopters',
  'Pelican Air Services',
  'Pelican Express',
  'Pelita Air Service',
  'Pem-Air',
  'Pen-Avia',
  'Peninsula Airways',
  'Peninter Aérea',
  "Penya De L'Aire",
  'Peran',
  'Perforadora Central',
  'Perimeter Aviation',
  'Perm Airlines',
  'Personas Y Pasquetes Por Air',
  'Peruvian Airlines',
  'Peruvian Air Force',
  'Peruvian Navy',
  'Petro Air',
  'Petroleos Mexicanos',
  'Petroleum Helicopters',
  'Petroleum Helicopters de Colombia',
  'Petropavlovsk-Kamchatsk Air Enterprise',
  'Petty Transport',
  'Phenix Aviation',
  'Phetchabun Airline',
  'Philippines AirAsia',
  'Philippine Airlines',
  'Philips Aviation Services',
  'Phillips Air',
  'Phillips Alaska',
  'Phillips Michigan City Flying Service',
  'Phoebus Apollo Aviation',
  'Phoebus Apolloa Zambia',
  'Phoenix Air Group',
  'Phoenix Air Lines',
  'Phoenix Air',
  'Phoenix Air Transport',
  'Phoenix Airline Services',
  'Phoenix Airways',
  'Phoenix Avia',
  'Phoenix Aviation',
  'Phoenix Flight Operations',
  'Phuket Air',
  'Piedmont Airlines',
  'Pilatus Flugzeugwerke',
  'Pilatus PC-12 Center De Mexico',
  'Pimichikamac Air',
  'Pineapple Air',
  'Pinframat',
  'Pinnacle Air Group',
  'Pinnacle Airlines',
  'Pioneer Airlines',
  'Pioneers Limited',
  'Pirinair Express',
  'Planar',
  'Planemaster Services',
  'Planet Airways',
  'Play',
  'Players Air',
  'Polizeihubschrauberstaffel Hamburg',
  'Plymouth School of Flying',
  'Pocono Air Lines',
  'Podilia-Avia',
  'Point Afrique Niger',
  'Point Airlines',
  'Pointair Burkina',
  'Points of Call Airlines',
  'Polar Air Cargo',
  'Polar Airlines de Mexico',
  'Polestar Aviation',
  'Polet Airlines',
  'Police Aux Frontières',
  'Police Aviation Services',
  'Polish Air Force',
  'Polish Navy',
  'Polizeifliegerstaffel Nordrhein-Westfalen',
  'Polizeihubschrauberstaffel Niedersachsen',
  'Polizeihubschrauberstaffel Sachsen-Anhalt',
  'Polizeihubschauberstaffel Rheinland-Pfalz',
  'Polizeihubschrauberstaffel Baden-Württemberg',
  'Polizeihubschrauberstaffel Bayern',
  'Polizeihubschrauberstaffel Brandenburg',
  'Polizeihubschrauberstaffel Hessen',
  'Polizeihubschrauberstaffel Mecklenburg-Vorpommern',
  'Polizeihubschrauberstaffel Sachsen',
  'Polizeihubschrauberstaffel Thüringen',
  'Polo Aviation',
  'Polynesian Air-Ways',
  'Polynesian Airlines',
  'Polynesian Blue',
  'Polyot Sirena',
  'Pond Air Express',
  'Pool Aviation',
  'Port Townsend Airways',
  'Porteadora De Cosola',
  'Porter Airlines',
  'Portugalia',
  'Portuguese Air Force',
  'Portuguese Army',
  'Portuguese Navy',
  'Poste Air Cargo',
  'Potomac Air',
  'Potosina Del Aire',
  'Powell Air',
  'Prairie Flying Service',
  'Pratt and Whitney Canada',
  'Precision Air',
  'Precision Airlines',
  'Premiair',
  'Premiair Aviation Services',
  'Premiair Fliyng Club',
  'Premium Air Shuttle',
  'Premium Aviation',
  'Presidence Du Faso',
  'Presidencia de La Republica de Guinea Ecuatorial',
  'President Airlines',
  'Presidential Aviation',
  'Priester Aviation',
  'Paradigm Air Operators',
  'Primaris Airlines',
  'Primas Courier',
  'Primavia Limited',
  'Prime Airlines',
  'Prime Aviation',
  'Prince Edward Air',
  'Princely Jets',
  'Princess Air',
  'Princeton Aviation Corporation',
  'Priority Air Charter',
  'Priority Air Transport',
  'Priority Aviation Company',
  'Privatair',
  'Private Jet Expeditions',
  'Private Jet Management',
  'Private Wings Flugcharter',
  'Privilege Style Líneas Aéreas',
  'Pro Air',
  'Pro Air Service',
  'Probiz Guinee',
  'Professional Express Courier Service',
  'Professione VOlare',
  'Proflight Zambia',
  'Promotora Industria Totolapa',
  'Propair',
  'Propheter Aviation',
  'Proteus Helicopteres',
  'Providence Airline',
  'Providence Aviation Services',
  'Provincial Airlines',
  'Provincial Express',
  'Pskovavia',
  'Psudiklat Perhubungan Udara/PLP',
  'Ptarmigan Airways',
  'Publiservicios Aéreos',
  'Publivoo',
  'Puerto Rico National Guard',
  'Puerto Vallarta Taxi Aéreo',
  'Pulkovo Aircraft Services',
  'Puma Linhas Aéreas',
  'Puntavia Air Services',
  'Punto Fa',
  'Pyramid Air Lines',
  'Primera Air Scandinavia',
  'Primera Air Nordic',
  'Quikjet Cargo Airlines',
  'Qatar Executive',
  'Qanot Sharq',
  'Qantas',
  'QantasLink',
  'Qatar Air Cargo',
  'Qatar Airways',
  'Qatar Amiri Flight',
  'Qeshm Air',
  'Quantex Environmental',
  'Quebec Government Air Service',
  'Queen Air',
  'Quest Diagnostics',
  'Quick Air Jet Charter',
  'Quick Airways Holland',
  'Quisqueya Airlines',
  'Qurinea Air Service',
  'Qwest Commuter Corporation',
  'Qwestair',
  'Qwila Air',
  'Rabbit-Air',
  'RACSA',
  'Rader Aviation',
  'Radixx',
  'RAF Barkston Heath',
  'RAF Church Fenton',
  'RAF Coltishall',
  'RAF Coningsby',
  'RAF Cottesmore',
  'RAF Cranwell',
  'RAF Kinloss',
  'RAF Leeming',
  'RAF Leuchars',
  'RAF Linton-on-Ouse',
  'RAF Lossiemouth',
  'RAF Marham',
  'RAF Northwood',
  'RAF Scampton',
  'RAF St Athan',
  'RAF St Mawgan Search and Rescue',
  'RAF Topcliffe Flying Training Unit',
  'RAF Valley Flying Training Unit',
  'RAF Valley SAR Training Unit',
  'RAF Waddington',
  'RAF Wittering',
  'RAF-Avia',
  'Rainbow International Airlines',
  'RA Jet Aeroservicios',
  'Raji Airlines',
  'RAK Airways',
  'Raleigh Flying Service',
  'Ram Air Freight',
  'Ram Aircraft Corporation',
  'Ramp 66',
  'Rangemile Limited',
  'Raslan Air Service',
  'Rath Aviation',
  'Ratkhan Air',
  'Raven Air',
  'Ravn Alaska',
  'Ray Aviation',
  'Raya Jet',
  'Raytheon Aircraft Company',
  'Raytheon Corporate Jets',
  'Raytheon Travel Air',
  'Real Aero Club De Baleares',
  'Real Aero Club de Reus-Costa Dorado',
  'Real Aeroclub De Tenerife',
  'Real Aero Club de Vizcaya',
  'Real Aviation',
  'Rebus',
  'Rectimo Air Transports',
  'Rectrix Aviation',
  'Red Aviation',
  'Red Baron Aviation',
  'Red Devils Parachute Display Team',
  'Red Sea Aviation',
  'Red Sky Ventures',
  'Red Star',
  'Redhill Aviation',
  'Virgin America',
  'Reed Aviation',
  'Reef Air',
  'Reem Air',
  'Regal Bahamas International Airways',
  'Regency Airlines',
  'Regent Air',
  'Regent Airways',
  'Regio Air',
  'Region Air',
  'Régional',
  'R1 Airlines, previously Regional 1',
  'Regional Air',
  'Regional Air Express',
  'Regional Air Services',
  'Regional Air Lines',
  'Regional Express',
  'Regional Geodata Air',
  'RegionsAir',
  'Reliance Aviation',
  'Reliant Airlines',
  'Relief Transport Services',
  'Renan',
  'Renown Aviation',
  'Republic Airways',
  'Republic Express Airlines',
  'Republicair',
  'Resort Air',
  'Reut Airways',
  'RG Aviation',
  'Rhoades Aviation',
  'Riau Airlines',
  'Rich International Airways',
  'Richards Aviation',
  "Richardson's Airway",
  'Richland Aviation',
  'Richy Skylark',
  'Rick Lucas Helicopters',
  'Rico Linhas Aéreas',
  'Ridder Avia',
  'Riga Airclub',
  'Rijnmond Air Services',
  'Rikspolisstyrelsen',
  'Rimrock Airlines',
  'Rio Air Express',
  'Rio Airways',
  'Rio Linhas Aéreas',
  'River Ministries Air Charter',
  'River State Government of Nigeria',
  'Rivne Universal Avia',
  'Roadair Lines',
  'Robinton Aero',
  'Vision Airlines',
  'Roblex Aviation',
  'Rockwell Collins Avionics',
  'Rocky Mountain Airlines',
  'Rocky Mountain Airways',
  'Rocky Mountain Holdings',
  'Rodze Air',
  'Rog-Air',
  'Rollright Aviation',
  'Rolls-Royce Limited',
  'Rolls-Royce plc',
  'Romanian Air Force',
  'Romavia',
  'Ronso',
  'Roraima Airways',
  'Rosen Aviation',
  'Rosneft-Baltika',
  'Ross Aviation',
  'Rossair',
  'Rossair Europe',
  'Rossiya',
  'Roswell Airlines',
  'Air Rarotonga',
  'Rotatur',
  'Rotormotion',
  'Rotterdam Jet Center',
  'Rover Airways International',
  'Rovos Air',
  'Royal Air Cargo',
  'Royal Air Force',
  'Royal Air Force of Oman',
  'Royal Air Freight',
  'Royal Air Maroc',
  'Royal Airlines',
  'Royal American Airways',
  'Royal Aruban Airlines',
  'Royal Australian Air Force',
  'Royal Aviation Express',
  'Royal Bahrain Airlines',
  'Royal Brunei Airlines',
  'Royal Daisy Airlines',
  'Royal Ghanaian Airlines',
  'Royal Jet',
  'Royal Jordanian',
  'Royal Jordanian Air Force',
  'Skyview Airways',
  'Royal Khmer Airlines',
  'Royal Malaysian Air Force',
  'Royal Navy',
  'Royal Netherland Navy',
  'Royal Netherlands Air Force',
  'Royal Norwegian Air Force',
  'Royal Oman Police',
  'Royal Phnom Penh Airways',
  'Royal Rwanda Airlines',
  'Royal Saudi Air Force',
  'Buzz (Ryanair)',
  'Royal Swazi National Airways',
  'Royal Tongan Airlines',
  'Royal West Airlines',
  'Rubystar',
  'Ruili Airlines',
  'Rumugu Air & Space Nigeria',
  'Rusaero',
  'Rusair JSAC',
  'Rusich-T',
  'Rusline',
  'Russian Aircraft Corporation-MiG',
  'Russian Federation Air Force',
  'Russian Sky Airlines',
  'Rusuertol',
  'Rutas Aéreas',
  'Rutland Aviation',
  'Rwanda Airlines',
  'Rwanda Airways',
  'Rwandair Express',
  'RWL Luftfahrtgesellschaft',
  'Ryan International Airlines',
  'Ryanair',
  'Ryanair UK',
  'Ryazan State Air Enterprise',
  'Rynes Aviation',
  'RVL Group',
  'UVT Aero',
  'Servicios Aereos Ominia',
  'Servicios de Aviacion Sierra',
  'SGC Aviation',
  'Siamjet Aviation',
  'Sixt Rent A Car',
  'Solenta Aviation Ghana',
  'SR Jet',
  'Spiracha Aviation',
  'Southern Illinois University as "Aviation Flight"',
  'Springfield Air',
  'Seneca College',
  'Sparc Avia',
  'Sino Jet Management',
  'Seychelles Airlines',
  'Servicios de Taxi Aereos',
  'Scoot',
  'Spring Airlines Japan',
  'SIBIA Aircompany Ltd',
  'Smartlynx Airlines',
  'Smartlynx Airlines Estonia',
  'Smartwings',
  'Smartwings Hungary',
  'Smartwings Poland',
  'Smartwings Slovakia',
  'Servicios Aereos Especializados Destina',
  'Slovenian Ministry of Defence',
  'Servicios Aereos Fun Fly',
  'Sabaidee Airways',
  'Safe Air Company',
  'Sanborn Map Company',
  'Siam Airnet',
  'Siavia',
  'Skargardshavets Helikoptertjanst',
  'Sky Bishek',
  'Sky Handling',
  'Sky Lease Cargo',
  'Sky Prim Air',
  'Skybus Jet',
  'Special Aviation Works',
  'Sharp Airlines',
  'Shree Airlines',
  'Sylt Air GmbH',
  'South Asian Airlines',
  'Samoa Air',
  'State Air Company Berkut',
  'SATA International',
  'South African Airways',
  'Sky Messaging',
  'Sky Way Air',
  'Sky Regional Airlines',
  'SASCO Airlines',
  'SOS Flygambulans',
  'Sayakhat Airlines',
  'Shaheen Air',
  'SAM Colombia',
  'Sahel Aviation Service',
  'Secretaria de Marina',
  'Springbank Aviation',
  'Scandinavian Airlines',
  'Samal Air',
  'Sham Wing Airlines',
  'Sabah Air',
  'ScotAirways',
  'Swiss Air-Ambulance',
  'SOL Linhas Aéreas',
  'Sol del Paraguay',
  'Steinman Aviation',
  'SevenAir',
  'Seven Bar Flying Service',
  'Sabre Incorporated',
  'S7 Airlines',
  'Sobel Airlines of Ghana',
  'SkyBahamas',
  'Stabo Air',
  'SmithKline Beecham Clinical Labs',
  'Saber Aviation',
  'Seaborne Airlines',
  'St Barth Commuter',
  'Star Air',
  'Star Air (India)',
  'Starlux Airlines',
  'Scibe Airlift',
  'Spanish Air Force',
  'South Central Air',
  'Seacoast Airlines',
  'SeaPort Airlines',
  'Scenic Airlines',
  'Socofer',
  'Servicios Aéreos San Cristóbal',
  'Sky Cam',
  'Switfair Cargo',
  'Saigon Capital Aircraft Management',
  'South American Airlines',
  'Servicios Aéreos de Chihuahua Aerochisa',
  'Servicios Aéreos de los Andes',
  'Sunbird Airlines',
  'Scorpio Aviation',
  'OSM Aviation Academy',
  'SFS Aviation',
  'Silver Cloud Air',
  'South African Non Scheduled Airways',
  'SAAB-Aircraft',
  'Servicios Aéreos Del Centro',
  'Sun Country Airlines',
  'St. Andrews Airways',
  'Sukhoi Design Bureau Company',
  'Sunrise Airlines',
  'Skymaster Air Taxi',
  'Air Partners Corp.',
  'Sundorph Aeronautical Corporation',
  'Servicio De Helicopteros',
  'SADELCA - Sociedad Aérea Del Caquetá',
  'Skydrift',
  'Spirit of Africa Airlines',
  'Sud Airlines',
  'Servicios Aéreos Del Vaupes',
  'Servicio Tecnico Aero De Mexico',
  'Sudan Pezetel for Aviation',
  'Southeast Air',
  'Servicios Aéreos Luce',
  'Sedona Air Center',
  'Shaheen Air Cargo',
  'Sky Express',
  'Spicejet',
  'Skyjet',
  'Sentel Corporation',
  'Selcon Airlines',
  'Sky Eyes',
  'Servicio Aéreo Saltillo',
  'SAETA',
  'Serair Transworld Press',
  'Shuswap Flight Centre',
  'Sefofane Air Charters',
  'Safewings Aviation Company',
  'Sun Freight Logistics',
  'Star Flyer',
  'Southflight Aviation',
  'Safiran Airlines',
  'Safe Air',
  'Safair',
  'Southern Frontier Air Transport',
  'Skyfreight',
  'Solent Flight',
  'S.K. Logistics',
  'Sky King, Inc.',
  'Southern Right Air Charter',
  'Sky Gate International Aviation',
  'STAC Swiss Government Flights',
  'Servisair',
  'Servicios Aéreos Agrícolas',
  'Skyward Aviation',
  'Sky Aircraft Service',
  'Siam GA',
  'Sagolair Transportes Ejecutivos',
  'Saskatchewan Government Executive Air Service',
  'Skygate',
  'Samgau',
  'Saga Airlines',
  'Skagway Air Service',
  'Shabair',
  'Sky Harbor Air Service',
  'Sahara Airlines',
  'Shell Aircraft',
  'Shoprite Group',
  "Sharjah Ruler's Flight",
  'Shorouk Air',
  'Samson Aviation',
  'Sheltam Aviation',
  'Shaheen Airport Services',
  'Sheremetyevo-Cargo',
  'Service Aerien Francais',
  'Shanghai Airlines Cargo',
  'Shooter Air Courier',
  'Shura Air Transport Services',
  'Sakhalinskie Aviatrassy (SAT)',
  'SATA Air Acores',
  'Shavano Air',
  'Shawnee Airline',
  'Slim Aviation Services',
  'Sky Airlines',
  'Singapore Airlines',
  'Sibaviatrans',
  'Sierra Express',
  'Skynet Airlines',
  'Seco International',
  'Servicios Aeronáuticos Integrales',
  'Sirio',
  'Salair',
  'Saber Airlines',
  'SITA',
  'Slovenian Armed Forces',
  'Sirio Executive',
  'Servicios Aéreos Especiales De Jalisco',
  'Servicios Ejecutivos Continental',
  'Sunair 2001',
  'Spirit Aviation',
  'Servicios Especiales Del Pacifico Jalisco',
  'Swiss Jet',
  'Sriwijaya Air',
  'Sama Airlines',
  'Southern Jersey Airways, Inc.',
  'SPASA',
  'Speed Aviation',
  'Southeast Airmotive',
  'Servicios Privados De Aviación',
  'Speedwings',
  'Service People Gesellschaft für Charter und Service',
  'Slovak National Aeroclub',
  'Singapore Airlines Cargo',
  'Slovak Air Force',
  'Servicos De Alquiler',
  'Sair Aviation',
  'Searca',
  'Siem Reap Airways',
  'Sky Work Airlines',
  'Swedline Express',
  'Servicios Aeronáuticos Aero Personal',
  'Sirair',
  'Servicios Aéreos Ejecutivos Saereo',
  'South East Asian Airlines',
  'Selkirk Remote Sensing',
  'Star Up',
  'Sarit Airlines',
  'Sierra Expressway Airlines',
  'Strato Air Services',
  'Sasair',
  'Southern Seaplane',
  'Star Service International',
  'Servicios Aéreos Sunset',
  'Severstal Air Company',
  'Slovak Government Flying Service',
  'SwedJet Airways',
  'Skystar International',
  'Special Scope Limited',
  'Starspeed',
  'Sunstate Airlines',
  'Sardinian Sky Service',
  'SAESA',
  'Sunwest Airlines',
  'SASCA',
  'Streamline Aviation',
  'Sky Aviation',
  'Specsavers Aviation',
  'Star Aviation',
  'Status-Alpha Airline',
  'Stadium City Limited',
  'Servicios De Aerotransportacion De Aguascalientes',
  'Semitool Europe',
  'SFT-Sudanese Flight',
  'Sedalia, Marshall, Boonville Stage Line',
  'South-Airlines',
  'Sontair',
  'Sella Aviation',
  'Stobart Air',
  'Stapleford Flight Centre',
  'Streamline Ops',
  'Servicios de Transportes Aéreos Fueguinos',
  'Star African Air',
  'Star West Aviation',
  'Saturn Aviation',
  'South West Air Corporation',
  'Stars Away Aviation',
  'Styrian Airways',
  'Silesia Air',
  'Suburban Air Freight',
  'Sudan Airways',
  'Sun Air (Fiji)',
  'Sun Air International',
  'Sunu Air',
  'Sun Light',
  'Swiss Air Force',
  'Superior Aviation Services',
  'State Unitary Air Enterprise',
  'Sun Air',
  'Sun Air of Scandinavia',
  'Surf Air',
  'Sistemas Aeronauuticos 2000',
  'Sundance Air',
  'Saudia',
  'St. Vincent Grenadines Air (1990)',
  'Swedish Armed Forces',
  'Sahel Airlines',
  'Sterling Helicopters',
  'Servicios De Transporte Aéreo',
  'Silver Air',
  'Sevastopol-Avia',
  'Savanair (Angola)',
  'Servicios Aeronáuticos De Oriente',
  'Servicios Aéreos Saar',
  'Seven Four Eight Air Services',
  'Security Aviation',
  'Southwest Airlines',
  'Swissboogie Parapro',
  'South West Air',
  'Southern Winds Airlines',
  'Swedeways',
  'Spurling Aviation',
  'Sunwing Airlines',
  'Sunworld Airlines',
  'StatesWest Airlines',
  'Star Work Sky',
  'Swift Air (Interstate Equipment Leasing)',
  'Swiss International Air Lines',
  'Sundair',
  'Sunwest Aviation (Lindquist Investment)',
  'Swiftair',
  'Swiss Global Air Lines',
  'Swe Fly',
  'Shovkoviy Shlyah',
  'Swazi Express Airways',
  'Swoop',
  'Sky Jet',
  'Servair, Private Charter',
  'Skywise Airline',
  'Southern Cross Aviation',
  'Sky Exec Aviation Services',
  'Southeast Express Airlines',
  'Servicios Aéreos Especializados Mexicanos',
  'SunExpress',
  'Servicios De Taxi Aéreo',
  'Satellite Aero',
  'Safari Express Cargo',
  'Skyways',
  'Systec 2000',
  'Sheba Aviation',
  'Sky One Express Airlines',
  'Synergy Aviation',
  'Sonalysts',
  'Slate Falls Airways',
  'Satsair',
  'Syncrude Canada',
  'Syrian Arab Airlines',
  'Shawbury Flying Training Unit',
  'Special Aviation Systems',
  'Skywalk Airlines',
  'Silk Way Airlines',
  'Silk Way West Airlines',
  'South African Historic Flight',
  'Servicios Aeronáuticos Z',
  'Specavia Air Company',
  'Starair',
  'Air Arabia Egypt',
  'Swedish Civil Aviation Administration',
  'Shandong Airlines',
  'Spectrem Air',
  'Servicios Aéreos Centrales',
  'Swedish Airlines',
  'Seagle Air',
  'Sirius-Aero',
  'Sunwest Home Aviation',
  'SAS Braathens',
  'Spring Airlines',
  'Sichuan Airlines',
  'Shanghai Airlines',
  'Shuangyang General Aviation',
  'Shenzhen Airlines',
  'Shanxi Airlines',
  'Sioux Falls Aviation',
  'Servicios Aéreos Elite',
  'Servicios Aéreos Denim',
  'Swiss Eagle',
  'Skypower Express Airways',
  'Scenic Air',
  "Sun D'Or",
  'Sunshine Express Airlines',
  'South African Express',
  'Stuttgarter Flugdienst',
  'Shalom Air Services',
  'Silverjet',
  'Sky Bus',
  'South Coast Aviation',
  'Servicios Aéreos Gadel',
  'S.P. Aviation',
  'Seba Airlines',
  'Servicios Aéreos Gana',
  'Sky Wings Airlines',
  'Star XL German Airlines',
  'Skyhaul',
  'Starship',
  'Servicios Ejecutivos Gosa',
  'Superior Aviation',
  'Samaritan Air Service',
  'Skyraidybos Mokymo Centras',
  'Sky Europe Airlines',
  'Svenska Direktflyg',
  'Sky Helicopteros',
  'Skytaxi',
  'Silvair',
  'Servicios Aéreos Ilsa',
  'Sincom-Avia',
  'Safat Airlines',
  'Saha Airlines Services',
  'Sunline Express',
  'Secure Air Charter',
  'Sark International Airways',
  'Salem',
  'Servicios Aéreos Copters',
  'Servicios Aéreos Expecializados En Transportes Petroleros',
  'Sky Aeronautical Services',
  'Servicios Aéreos Ejecutivos De La Laguna',
  'Servico Leo Lopex',
  'Servicios Aéreos Estrella',
  'South African Air Force',
  'Sky One Holdings as Privaira',
  'Sansa',
  'Spectrum Aviation Incorporated',
  'SOS Helikoptern Gotland',
  'Sundt Air',
  'Servicios Aéreos Milenio',
  'SAAD (A320) Limited',
  'Servicios Aéreos Moritani',
  'San Juan Airlines',
  'Servico Aéreo Regional',
  'Servicio De Vigilancia Aérea Del Ministerio De Seguridad Pública',
  'Servicios Aéreos MTT',
  'Sabre Pacific',
  'Sabre',
  'Sierra Nevada Airlines',
  'Siren-Travel',
  'Sirena',
  'Snowbird Airlines',
  'Southern Cross Distribution',
  'Sutra',
  'SNCF',
  'Star Equatorial Airlines',
  'Seulawah Nad Air',
  'Servicios Aéreos del Nazas S.A. de C.V.',
  'Simpson Air Ltd',
  'Spirit Airlines',
  'Servicios Aéreos Latinoamericanos',
  'Servicios Aéreos Monarrez',
  'Societe De Transport Aerien De Mauritanie',
  'SATENA',
  'Servicios Aéreos Del Norte',
  'Servicios Integrales De Aviación',
  'Slok Air Gambia',
  'Soko Aviation',
  'Solar Cargo',
  'Soloflight',
  'Sonnig SA',
  'Sosoliso Airlines',
  'Servicios Aéreos Noticiosos',
  'South Airlines',
  'Virgin Australia Regional Airlines',
  'Sokol',
  'South Carolina Aeronautics Commission',
  'Servicios Aéreos Premier',
  'Survey Udara (Penas)',
  'Servicios Aéreos Poblanos',
  'Servicios Aéreos Profesionales',
  'Southeastern Airways',
  'Spurwing Airlines',
  'Sky Trek International Airlines',
  'Shandong Airlines Rainbow Jet',
  'SA Airlink Regional',
  'Servicio Aéreo Regional Regair',
  'Scoala Superioara De Aviatie Civila',
  'Servicios Aéreos Regiomontanos',
  'S-Air',
  'Servicios De Rampa Y Mostrador',
  'SNAS Aviation',
  'Skymaster Airlines',
  'Sky Tours',
  'Skycraft',
  'Skycraft Air Transport',
  'SkyKing Turks and Caicos Airways',
  'Skylink Aviation',
  'Skycharter (Malton)',
  'Skyline Aviation Services',
  'Scottish Airways Flyers',
  'Skyscapes Air Charters',
  'Sky Service',
  'Sky Link Aviation',
  'Santa Barbara Airlines',
  'SkyStar Airways',
  'Sky Airline',
  'SkyWest Airlines',
  'Skyways Express',
  'Skymark Airlines',
  'Skyway Enterprises',
  'Sierra National Airlines',
  'Slok Air',
  'Streamline',
  'Starfly',
  'Saskatchewan Government',
  'Silverhawk Aviation',
  'Servicios Aéreos de Los Ángeles',
  'SilkAir',
  'Slovak Airlines',
  'Surinam Airways',
  'Sloane Aviation',
  'Salpa Aviation',
  'Servicios Aéreos Slainte',
  'Stella Aviation',
  'Salama Airlines Nigeria',
  'Sete Linhas Aéreas',
  'Sky Line for Air Services',
  'Super Luza',
  'SMA Airlines',
  'Sabang Merauke Raya Air Charter',
  'Servicios Aéreos La Marquesa',
  'Servant Air',
  'Semos',
  'Smalandsflyg',
  'Smithair',
  'Semeyavia',
  'Smith Air (1976)',
  'Summit Air',
  'Samar Air',
  'Somon Air',
  'Skyline',
  'Servicios Aéreos Del Sol, S.A. de C.V.',
  'Senator Aviation Charter',
  'Sterling Airlines',
  'Servicios Aéreos De Nicaragua (SANSA)',
  'Shans Air',
  'Senair Services',
  'Savanah Airlines',
  'Solaseed Air',
  'Southeast Airlines (Sun Jet International)',
  'Soonair Lines',
  'Servizi Aerei',
  'Sun Pacific International',
  'Sun Quest Executive Air Charter',
  'Societe Centrafricaine De Transport Aerien',
  'Suncoast Aviation',
  'Snunit Aviation',
  'Sudanese States Aviation',
  'Sun West Airlines',
  'Sun Air Aviation Services',
  'Stabo Freight',
  'Southern Cargo Air Lines',
  'Southern Ohio Aviation Sales',
  'Southern Aviation',
  'Solomon Airlines',
  'Somali Airlines',
  'Sunshine Air Tours',
  'Southern Air',
  'Solinair',
  'Sonair Servico Aéreo',
  'Southeast Correct Craft',
  'Southern Airways',
  'Saratov Airlines Joint Stock Company',
  'White Sparrow GmbH',
  'Solid Air',
  'Sat Airlines',
  'Sierra Pacific Airlines',
  'Springbok Classic Air',
  'Skyworld Airlines',
  'Sprague Electric Company',
  'Space World Airline',
  'Springdale Air Service',
  'Sapphire Executive Air',
  'South Pacific Island Airways',
  'Servicios Corporativos Aéreos De La Laguna',
  'Skorpion Air',
  'Sapphire Aviation',
  'Servicios Aéreos Palenque',
  'Servicios Aéreos Tribasa',
  'Shuttle America',
  'SALTAVIATION',
  'SAAB Nyge Aero',
  'Spark Air',
  'S C Ion Tiriac',
  'Starlite Aviation',
  'Servicios Aéreos Corporativos',
  'Societe Tout Transport Mauritanien',
  'Servicios Aéreos Tamazula',
  'Shar Ink',
  'Second Sverdlovsk Air Enterprise',
  'Servicios Aéreos Universitarios',
  'Skif-Air',
  'Smarkand Aero Servise',
  'Samarkand Airways',
  'Servicios Aéreos Avandaro',
  'Stichting Vliegschool 16Hoven',
  'Silverback Cargo Freighters',
  'Sirvair',
  'Scat Air',
  'Sunset Aviation',
  'Sunset Aviation, LLC',
  'Sport Air Travel',
  'Swift Copters',
  'Skyrover CC',
  'Safarilink Aviation',
  'SENEAM',
  'Southport Air Service',
  'Spectrum Air Service',
  'Stephenville Aviation Services',
  'Servicios Aéreos Textra',
  'Sector Airlines',
  'Skyplan Services',
  'Stewart Aviation Services',
  'Servicios Aéreos Integrales / Flyant',
  'State Flight Academy of Ukraine',
  'Singapore Air Force',
  'Starlink Aviation',
  'SF Airlines',
  'SaxonAir',
  'Superna Airlines',
  'Sunexpress Deutschland',
  'SkyFirst LTD',
  'SkyUp',
  'Air Tanzania Company Limited',
  'AirTanker Services',
  'Cebgo',
  'Eagle Canyon Airlines',
  'Jet2',
  'LATAM Brasil',
  'Open Skies Consultative Commission',
  'TAAG Angola Airlines',
  'TA-Air Airline',
  'TAB Express International',
  'Taban Air Lines',
  'TACA De Honduras',
  'TACV',
  'Tadair',
  'Taespejo Portugal LDA',
  'TAF Helicopters',
  'TAF-Linhas Aéreas',
  'Taftan Airlines',
  'TAG Aviation',
  'TAG Aviation Espana',
  'Tag Aviation UK',
  'TAG Aviation USA',
  'TAG Farnborough Airport',
  'Tailwind Airlines',
  'Taino Tours',
  'Tair Airways',
  'Tajikair',
  'Tajikistan International Airlines',
  'Take Air Line',
  'Tal Air Charters',
  'Talair',
  'Talon Air',
  'TAM Mercosur',
  'TAME',
  'Tamir Airways',
  'TAMPA',
  'Tanana Air Services',
  'Tandem Aero',
  'Tango Bravo',
  'TAP Portugal',
  'TAPC Aviatrans Aircompany',
  'TAPSA Transportes Aéreos Petroleros',
  'Taquan Air Services',
  'Tar Heel Aviation',
  'TAR Interpilot',
  'Tara Air Line',
  'Tarhan Tower Airlines',
  'Tarkim Aviation',
  'Tarom',
  'Tas Aviation',
  'Tashkent Aircraft Production Corporation',
  'Tassili Airlines',
  'Tauranga Aer Club',
  'Tavrey Airlines',
  'Taxair Mexiqienses',
  'Taxi Aéreo Cozatl',
  'Taxi Aéreo de México',
  'Taxi Aéreo Turístico',
  'Taxi Aero Del Norte',
  'Taxi Aero Nacional Del Evora',
  'Taxi Air Fret',
  'Taxi de Veracruz',
  'Taxirey',
  'Taxis Aéreos De Parral',
  'Taxis Aéreos de Sinaloa',
  'Taxis Aéreos del Noroeste',
  'Taxis Aéreos del Pacífico',
  'Taxis Turisticos Marakame',
  'Tayflite',
  'Tayside Aviation',
  'Tbilaviamsheni',
  'Tbilisi Aviation University',
  'Tchad Airlines',
  'TEAM Linhas Aéreas',
  'Teamline Air',
  'Tech-Mont Helicopter Company',
  'Tecnicas Fotograficas',
  'Teebah Airlines',
  'Tehran Airline',
  'Teledyne Continental Motors',
  'Telesis Transair',
  'Telford Aviation',
  'Tellavia / Flight One',
  'Telnic Limited',
  'Tempelhof Airways',
  'Temsco Helicopters',
  'Tengeriyn Ulaach Shine',
  'Tenir Airlines',
  'Tennessee Air National Guard 164th Airlift Group',
  'Tennessee Airways',
  'Tepavia-Trans Airlines',
  'Territorial Airlines',
  'Tesis',
  'Tex Star Air Freight',
  'Texair Charter',
  'Texas Air Charters',
  'Texas Airlines',
  'Texas Airways',
  'Texas National Airlines',
  'TezJet Airlines',
  'TG Aviation',
  'Thai Air Cargo',
  'Thai AirAsia',
  'Thai AirAsia X',
  'Thai Airways International',
  'Thai Aviation Services',
  'Thai Express Air',
  'Thai Flying Helicopter Service',
  'Thai Flying Service',
  'Thai Global Airline',
  'Thai Jet Intergroup',
  'Thai Lion Mentari',
  'Thai Pacific Airlines Business',
  'Thai Sky Airlines',
  'Thai Smile Airways',
  'Thai Star Airlines',
  'Thai Vietjet Air',
  'Thalys',
  'The 955 Preservation Group',
  'The Army Aviation Heritage Foundation',
  'The Lancair Company',
  'Thomas Cook Airlines',
  'Thryluthjonustan',
  'Thunder Airlines',
  'Thunderbird Tours',
  'Thyssen Krupp AG',
  'Tianjin Airlines',
  'Tiara Air',
  'Tibet Airlines',
  'Tic Air',
  'Tien-Shan',
  'Tigerair Australia',
  'Tigerair Singapore',
  'Tigerair Taiwan',
  'Tigerfly',
  'Tikal Jets Airlines',
  'Timberline Air',
  'Time Air',
  'Tiramavia',
  'TJS Malta Ltd.',
  'TJS San Marino S.r.L.',
  'TLC Air',
  'TMC Airlines',
  'TNT Airways',
  'TNT International Aviation',
  'Tobago Express',
  'Tobruk Air',
  'TOJ Airlines',
  'Tol-Air Services',
  'Tomahawk Airways',
  'Top Air',
  'Top Flight Air Service',
  'Top Fly',
  'Top Sky International',
  'Top Speed',
  'Total Linhas Aéreas',
  'Totavia',
  'Toumai Air Tchad',
  'Touraine Helicoptere',
  'Tower Air',
  'Toyota Canada',
  'Trabajos Aéreos',
  'Trabajos Aéreos Murcianos',
  'Trabajos Aéreos Vascongados',
  'Trade Air',
  'Tradewind Aviation',
  'Tradewinds Airlines',
  'Tradewinds Aviation',
  'Trading Air Cargo',
  'TRADO',
  'TRAGSA (Medios Aéreos)',
  'Trail Lake Flying Service',
  'TRAM',
  'Tramon Air',
  'Tramson Limited',
  'Trans Air',
  'Trans Air Charter',
  'Trans Air Welwitchia',
  'Trans Air-Benin',
  'Trans Am Compania',
  'Trans America',
  'Trans America Airlines',
  'Trans American Airlines (Trans Am)',
  'Trans Arabian Air Transport',
  'Trans Asian Airlines',
  'Trans Atlantic Airlines',
  'Trans Atlantis',
  'Trans Continental Airlines',
  'Trans Euro Air',
  'Trans Guyana Airways',
  'Trans Helicoptere Service',
  'Trans International Airlines',
  'Trans International Express Aviation',
  'Trans Island Air',
  'Trans Jet Airways',
  'Trans Mediterranean Airlines',
  'Trans Midwest Airlines',
  'Trans Nation Airways',
  'Trans North Turbo Air',
  'Trans Reco',
  'Trans Sahara Air',
  'Trans Sayegh Airport Services',
  'Trans States Airlin',
  'Wizz Air',
]
