const optInsState = {
  optIns: {
    singleConsentOptIn: false, // Combined opt-in for Which? advice, products and services
    caseStudy: false, // Contact details -> I'm happy for a member of the Which? team to get in touch...
  },
}

const userState = {
  user: {
    firstName: null, // Contact details -> Name
    lastName: null, // Contact details -> Name
    emailAddress: null, // Contact details -> Email
    phoneNumber: null, // Contact details -> PhoneNumber
    address: {
      postcode: null, // Contact details -> postcode
      addressLine1: null, // Contact details -> addressLine1
      addressLine2: null, // Contact details -> addressLine2
      townCity: null, // Contact details -> townCity
      county: null, // Contact details -> county
    },
    date: null,
  },
}

const journeyFlowState = {
  journeyFlow: {
    isForward: true,
    writeLetter: {
      currentStep: 0,
      userJourney: [],
    },
    startLetter: true,
    milestoneHit: [],
    branch: '',
  },
}

const toolFeedbackState = {
  showFeedback: false,
  toolFeedback: null,
  toolFeedbackAudience: null,
  toolFeedbackAudienceComplete: false,
  toolFeedbackSurveyComplete: false,
}

export const initialToolState = {
  initial: {
    entryToolName: '',
    initialised: false, //datalayer has been initialised
    step: 1,
    progressValue: 0,
    userJourney: [0],
    emailSent: false,
    ...optInsState,
    ...userState,
    ...journeyFlowState,
  },
  toolFeedback: { ...toolFeedbackState },
}
