export const pageDataRegardingProblem = (state: {
  answers: {
    regardingProblem: string | undefined
  }
  user: {
    toolID: string
    userEmail: string
  }
}) => {
  const { regardingProblem } = state.answers
  const { toolID, userEmail } = state.user

  const questions: any = {}
  questions['question.1349123'] = toolID // TF - Impact Survey - Tool Name
  questions['question.1349096'] = regardingProblem // TF - Impact Survey - Claim made?

  // return supporter
  return {
    supporter: {
      Email: userEmail,
      questions,
    },
  }
}
