export const complaintTypeData = {
  buttons: [
    {
      label: 'Goods supplied',
      id: 'goods-supplied',
    },
    {
      label: 'Poor workmanship',
      id: 'poor-workmanship',
    },
  ],
  title:
    'Is your complaint specifically about damaged or faulty goods, or the poor workmanship of the trader?',
  imgObj: {
    alt: 'Two people painting a cabinet',
    src: 'https://media.product.which.co.uk/prod/images/original/3e34253d504c-step1.jpg',
  },
}
