import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../../shared/state/appContext'
import globalStyles from '../../../../../shared/styles/GlobalStyles.module.scss'
import { trackAppointmentBookingSelection } from '../../../../../shared/utils/tracking/tracking'
import {
  ContactDetailsFlow,
  ContractChoiceFlow,
  DescribeCaseFlow,
  HomeLocationFlow,
  HomeQueryFlow,
  HomeTopicFlow,
  LimitedOptionsFlow,
} from '../../../pageFlowData'
import { homeTopicData } from './data'

export const HomeTopic: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    home: { homeTopic },
    triage: { type },
  } = state

  const handleTracking = (id: string, index: number) => {
    trackAppointmentBookingSelection(type, HomeTopicFlow.VIEW_NAME, { id, index })
  }

  const handleStep = (id: string) => {
    switch (id) {
      case 'leasehold':
        return HomeLocationFlow
      case 'land-registry':
      case 'landlords-hmo':
      case 'licensing':
        return LimitedOptionsFlow
      case 'new-build-issues':
        return DescribeCaseFlow
      case 'issue-with-tenant':
      case 'neighbour-issue':
        return ContactDetailsFlow
      case 'dispute-with-trader':
        return ContractChoiceFlow
      default:
        return HomeQueryFlow
    }
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_HOME_TOPIC', data: id })
    const step = handleStep(id)
    dispatch({ type: 'UPDATE_STEP', data: step.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: step.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string, index: number) => {
    handleTracking(id, index)
    handleDispatch(id)
  }

  const { buttons, imgObj, title } = homeTopicData

  return (
    <div className={globalStyles.fullWidthButton}>
      <PillButtonPage
        buttons={buttons}
        defaultValue={homeTopic ? homeTopic : ''}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        title={title}
      />
    </div>
  )
}
