import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { TextAreaPage } from '../../../../shared/pages/TextAreaPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryTextAreaRef } from '../../../../shared/types/toolTypes'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { trackPIIAppointmentBookingSubmission } from '../../../../shared/utils/tracking/tracking'
import { validateWordCount } from '../../../../shared/utils/validateWordCount'
import { BestNumberFlow, DescribeCaseFlow } from '../../pageFlowData'
import {
  DescribeCaseBuyingData,
  DescribeCaseCarsData,
  DescribeCaseData,
  DescribeCaseEmploymentData,
  DescribeCaseHomeData,
  DescribeCaseMedicalData,
  DescribeCaseMoneyData,
  DescribeCaseTechData,
  DescribeCaseWillData,
} from './data'

export const DescribeCase: FunctionComponent = () => {
  const textAreaMaxWordCount = 100
  const { state, dispatch } = useContext(ToolContext)
  const {
    triage: { describeCase, type },
    money: { moneyTopic },
    wills: { willsTopic },
    home: { homeTopic },
    medical: { medicalTopic },
    tech: { techTopic, techDevice },
    cars: { carsTopic },
    employment: { employmentTopic },
    buying: { buyingTopic },
    legal: { legalTopic },
  } = state

  const chooseDataSet = () => {
    if (buyingTopic || legalTopic === 'dataProtection') {
      return DescribeCaseBuyingData
    } else if (moneyTopic) {
      return DescribeCaseMoneyData
    } else if (willsTopic) {
      return DescribeCaseWillData
    } else if (techTopic) {
      const data = JSON.parse(JSON.stringify(DescribeCaseTechData))
      if (techDevice === 'printer') {
        data.descriptiveText.unshift('Please include the printers make and model')
      }
      return data
    } else if (homeTopic) {
      return DescribeCaseHomeData
    } else if (medicalTopic) {
      return DescribeCaseMedicalData
    } else if (carsTopic) {
      return DescribeCaseCarsData
    } else if (employmentTopic) {
      return DescribeCaseEmploymentData
    }
    return DescribeCaseData
  }

  const { imgObj, title, placeholderText, descriptiveText, noteTop } = chooseDataSet()
  const [SupportingEvidenceTextAreaValid, setSupportingEvidenceTextAreaValid] =
    useState<boolean>(true)

  const SupportingEvidenceTextAreaRef = useRef<FormEntryTextAreaRef>(null)

  const handleChange = () => {
    const value = getCleanValue(SupportingEvidenceTextAreaRef.current?.formEntryTextarea?.value)
    const isValid: boolean = validateWordCount(value, textAreaMaxWordCount)
    const fieldNotEmpty: boolean = isNotEmpty(value.length)
    if (fieldNotEmpty && isValid) {
      setSupportingEvidenceTextAreaValid(true)
    }
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_DESCRIBE_CASE', data: value })
    dispatch({ type: 'UPDATE_STEP', data: BestNumberFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: BestNumberFlow.PROGRESS_VALUE })
  }

  const handleTracking = () => {
    trackPIIAppointmentBookingSubmission(type, DescribeCaseFlow.VIEW_NAME)
  }

  const handleSubmit = () => {
    const value: string = getCleanValue(
      SupportingEvidenceTextAreaRef.current?.formEntryTextarea?.value
    )
    const isValid: boolean = validateWordCount(value, textAreaMaxWordCount)
    const isEmpty = !isNotEmpty(value)
    if (!isValid || isEmpty) {
      setSupportingEvidenceTextAreaValid(false)
      return
    }
    setSupportingEvidenceTextAreaValid(true)
    handleTracking()
    handleDispatch(value)
  }

  return (
    <div data-testid="describe-case">
      <TextAreaPage
        buttonText="Next"
        defaultValue={describeCase}
        descriptiveText={descriptiveText}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        isFocused
        isInputValid={SupportingEvidenceTextAreaValid}
        maxChars={textAreaMaxWordCount}
        maxWords={textAreaMaxWordCount}
        noteTop={noteTop}
        placeholderText={placeholderText}
        ref={SupportingEvidenceTextAreaRef}
        title={title}
      />
    </div>
  )
}
