import type { FunctionComponent } from 'react'
import React from 'react'
import { Link, Typography } from '@which/seatbelt'
import { InfoBox } from '@which/seatbelt'

import type { InfoBoxComponentProps, InfoBoxParagraphs } from '../../types/toolTypes'
import styles from './renderInfoBox.module.scss'

const renderInfoBoxParagraphs = (paragraphs: InfoBoxParagraphs) => {
  const infoBoxParagraphs = paragraphs?.map(({ text, classname, link }, index) => {
    if (link) {
      return (
        <Link
          className={classname}
          data-testid="info-box-link"
          href={link}
          key={`paragraph-${index}`}
          target="_blank"
        >
          {text}
        </Link>
      )
    }
    return (
      <Typography
        className={classname}
        dangerouslySetInnerHTML={{ __html: text }}
        data-testid="info-box-paragraph"
        key={`paragraph-${index}`}
        tag="p"
        textStyle="body"
      />
    )
  })

  return infoBoxParagraphs
}

export const RenderInfoBox: FunctionComponent<InfoBoxComponentProps> = ({ data }) => {
  return (
    <div className={styles.renderInfoBoxContainer}>
      <InfoBox {...data?.infoBox}>{renderInfoBoxParagraphs(data?.infoBoxParagraphs)}</InfoBox>
    </div>
  )
}
