import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { FormEntryPage } from '../../../../shared/pages/FormEntryPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { SingleInputState } from '../../../../shared/types'
import type { FormEntryInputRef } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { DeliveryDateExplainedFlow, OrderNumberFlow, WhatHappenedFlow } from '../../pageFlowData'
import { data } from './data'

export const OrderNumber: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    order: { orderNumber, deliveryProblem },
    initial: { entryToolName },
  } = state

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })
  const orderNumberInput = useRef<FormEntryInputRef>(null)

  const getValue = () => {
    return cleanText(orderNumberInput?.current?.formEntryInput?.value || '')
  }

  const handleStepChoice = () => {
    if (deliveryProblem === 'lateDelivery') {
      return {
        step: DeliveryDateExplainedFlow.STEP,
        progress: DeliveryDateExplainedFlow.PROGRESS_VALUE,
      }
    }
    return { step: WhatHappenedFlow.STEP, progress: WhatHappenedFlow.PROGRESS_VALUE }
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${OrderNumberFlow.VIEW_NAME} | PII`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    const { progress, step } = handleStepChoice()

    dispatch({ type: 'UPDATE_ORDER_NUMBER', data: value })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleSubmit = () => {
    const value = getValue()

    setInputData({
      renderError: false,
      value: value,
    })

    handleTracking()
    handleDispatch(value)
  }

  const { renderError, value: errorValue } = inputData
  const { imgObj, title } = data

  return (
    <FormEntryPage
      buttonText="Next"
      defaultValue={orderNumber}
      fieldData={{
        renderError: renderError,
        value: errorValue,
      }}
      handleSubmit={handleSubmit}
      id="order-number"
      imgObj={imgObj}
      isFocused
      optional
      placeholderText=""
      ref={orderNumberInput}
      testId="order-number"
      title={title}
    />
  )
}
