export const moneyQueryData = {
  buttons: [
    {
      label: 'Banking, Cash, Currency and Travel Money',
      id: 'cash-money-exchange',
      tooltip:
        'Select this button to get help and information about access to banking and cash, how payment systems work, the types of banks, building societies and payment services providers, and anything to do with banking and payments BUT NOT claims for poor service (select Claims Handling)',
    },
    {
      label: 'Borrowing and Credit Referencing, and an overview of Debt',
      id: 'overview-of-debt',
      tooltip:
        'Select this button to get help and information about  credit cards (BUT NOT claims), credit scoring, personal loans, Buy Now Pay Later, and an overview of debt',
    },
    {
      label: 'Investments',
      id: 'investments',
      tooltip:
        'Select this button to get help and information about the administration of investments, attitude to risk and all types of investments and platforms BUT NOT Savings rates (see below), or claims for poor service (go back and select Claims Handling)',
    },
    {
      label: 'Mortgages',
      id: 'mortgages',
      tooltip:
        'Select this button to get help and information about all aspects of residential mortgages, buy to let etc BUT NOT Equity Release or Later Life lending (see below) or claims for poor service (go back and select Claims Handling)',
    },
    {
      label: 'Pensions',
      id: 'pensions',
      tooltip:
        'Select this button to get help and information about all aspects of  Pensions (except for poor service - go back and select Claims Handling)',
    },
    {
      label: 'Savings',
      id: 'savings',
      tooltip:
        'Select this button to get help and information about saving rates (BUT NOT about Investments - select the button above)',
    },
    {
      label: 'Secured Later Life Lending and Equity Release',
      id: 'equity-release',
      tooltip:
        'Select this button to get help and information about all aspects of Equity Release and Retirement Interest Only mortgages',
    },
    {
      label: 'Tax',
      id: 'tax',
      tooltip:
        'Select this button to get help and information about all aspects of Capital Gains Tax, Inheritance Tax, Tax Reliefs, Tax Allowances, National Insurance, Self Assessment, Stamp Duty and dealing with HMRC BUT NOT Pension Credits (go back and select Social Care, Welfare and Benefits)',
    },
    {
      label: 'Trusts and Estates',
      id: 'trusts-and-estate',
      tooltip:
        'Select this button to get help and information about all aspects of trusts and how to use them, including the duties of trustees, the trust registration service, estate planning and transfer of equity.',
    },
    {
      label: 'Funeral Plans',
      id: 'funeral-plans',
      tooltip:
        'Select this button to get help and information about Funeral Plans BUT NOT complaints (go back and select Claims Handling)',
    },
    {
      label: 'Cost of Living and /or Energy Suppliers',
      id: 'cost-of-living',
      tooltip:
        'Select this button to get help and information about dealing with energy and utility providers and billing and general budgeting',
    },
    {
      label: 'Savings clubs',
      id: 'savings-clubs',
      tooltip: 'Select this button to get help and information about all aspects of Savings Clubs',
    },
    {
      label: 'Is it about something else?',
      id: 'something-else',
    },
  ],
  socialButtons: [
    {
      label: 'Welfare & Benefits',
      id: 'welfare',
      tooltip:
        'Select this button to get help and information about access to payments and allowances from the Welfare and Benefits system including Pension Credit and use of  Appointees.',
    },
    {
      label: 'Social Care',
      id: 'social-care',
      tooltip:
        'Select this button to get help and information about Council Tax and Bandings, local authority support payments, discounts and allowances.',
    },
    {
      label: 'Property & Housing',
      id: 'property',
      tooltip:
        'Select this button to get help and information about Council Tax and Bandings, local authority support payments, discounts and allowances.',
    },
  ],
  claimsButtons: [
    {
      label: 'Financial Ombudsman Service and FSCS criteria',
      id: 'financial-ombudsman',
      tooltip:
        'Select this button for help with understanding the eligibility requirements for Financial Ombudsman Service and  Financial Services Compensation Scheme to adjudicate complaints',
    },
    {
      label: 'Scams and Frauds',
      id: 'scams-fraud',
      tooltip: 'Select this button for help with all frauds and scams',
    },
    {
      label: 'Insurance Claims',
      id: 'insurance-claims',
      tooltip:
        'Select this button for help with all insurance claims whether General Insurance, Life Insurance or Protection Products.',
    },
    {
      label: 'Poor service claims',
      id: 'poor-service',
      tooltip:
        'Select this button for help with poor service claims related to financial financial products and services including misselling, Section 75 credit card and chargeback claims and everything else that is not otherwise categorised on this screen',
    },
    {
      label: 'Claims against Local Authority and NHS decisions',
      id: 'local-authority',
      tooltip:
        'Select this button for help with claims or appeals against Local Authority and NHS decisions',
    },
    {
      label: 'Financial Services Compensation Scheme',
      id: 'financial-services-compensation-scheme',
      tooltip:
        'Select this button for help to understand how the Financial Services Compensation Scheme works',
    },
  ],
  insuranceButtons: [
    {
      label: 'Life Insurance',
      id: 'life-insurance',
      tooltip:
        'We can help you understand the types of life insurance policies that are available, how life insurance works and the differences between whole of life, level term and decreasing term life policies, and policies that are payable on death only.',
    },
    {
      label: 'Protection Products',
      id: 'protection-products',
      tooltip:
        'Select this button to get help with policies of medical and personal injury insurance (sometimes called Critical Illness, Income Protection or Accident, Sickness and Unemployment insurance), and Private Medical Insurance.',
    },
    {
      label: 'General Insurance',
      id: 'general-insurance',
      tooltip:
        'Select this button to get help and information about home, empty house, motor, gap, pet, travel, gadget and other general insurances BUT NOT Life Insurance or Protection Products - use the buttons above for these)',
    },
  ],
  title: 'Please select the topic that best matches your query',
  imgObj: {
    alt: 'A person holding up a bank in one hand and a piggy bank in another',
    src: 'https://media.product.which.co.uk/prod/images/original/e7472f0db7b7-debt.jpg',
  },
}
