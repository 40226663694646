import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { PageWithText } from '../../../../shared/components/PageWithText'
import { FormEntryPage } from '../../../../shared/pages/FormEntryPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryInputRef, SingleInputState } from '../../../../shared/types/toolTypes'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { EntitlementFlow, WhatProductFlow } from '../../pageFlowData'
import { chooseDataSet } from './chooseDataSet'
import styles from './Entitlement.module.scss'

export const Entitlement: FunctionComponent = () => {
  const toolContext = useContext(ToolContext)
  const { state, dispatch } = toolContext

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })
  const CardProviderTextInput = useRef<FormEntryInputRef>(null)

  const {
    product: { type, purchaseMethod, price, purchasedDate },
    retailer: { repeatPerformance },
    issue: { cardProvider },
  } = state

  const data = chooseDataSet({ type, purchaseMethod, repeatPerformance, price, purchasedDate })

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${EntitlementFlow.VIEW_NAME} | ${value}`
    generalDataLayer('s75 and chargeback', trackingAnswer)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_CARD_PROVIDER', data: value })
    dispatch({ type: 'UPDATE_STEP', data: WhatProductFlow.STEP })
    dispatch({ type: 'UPDATE_BRANCH', data: data.journeyFlowBranch })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: WhatProductFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    const value = getCleanValue(CardProviderTextInput?.current?.formEntryInput?.value)
    const cardProviderHasError = !isNotEmpty(value)

    if (!cardProviderHasError) {
      handleTracking(value)
      handleDispatch(value)
      return
    }

    setInputData({
      renderError: cardProviderHasError,
      value: value,
    })
  }

  const { renderError, value: errorValue } = inputData
  const { bodyCopy, standfirst, title, imageURL, imageTitle } = data

  return (
    <div data-testid="entitlement">
      <PageWithText
        bodyCopy={bodyCopy}
        className={styles.entitlementPage}
        standFirst={standfirst}
        title={title}
      >
        <FormEntryPage
          buttonText="Next"
          defaultValue={cardProvider}
          displayText=""
          fieldData={{
            renderError: renderError,
            value: errorValue,
          }}
          handleSubmit={handleSubmit}
          id="card-provider"
          imgObj={{
            alt: 'A person repairing a computer',
            src: imageURL,
          }}
          isFocused
          placeholderText=""
          ref={CardProviderTextInput}
          testId="card-provider"
          title={imageTitle}
          type="text"
        />
      </PageWithText>
    </div>
  )
}
