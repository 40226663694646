import type { FunctionComponent } from 'react'
import React, { useContext, useEffect, useRef, useState } from 'react'

import { WriteLetterModule } from '../../../../shared/modules/WriteLetterModule'
import { ToolContext } from '../../../../shared/state/appContext'
import type { SingleInputState } from '../../../../shared/types'
import type { WriteLetterRefs } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { setWriteLetterUserJourney } from '../../../../shared/utils/setWriteLetterUserJourney'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { validateCharCount } from '../../../../shared/utils/validateCharCount'
import {
  ContactDetailsFlow,
  IssueDescriptionFlow,
  OrderNumberFlow,
  PurchasePriceFlow,
  WhichRetailerFlow,
} from '../../pageFlowData'
import { data } from './data'

export const WriteLetter: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName, journeyFlow, progress },
    issue,
    product,
    retailer,
  } = state

  const { currentStep, userJourney } = journeyFlow.writeLetter

  useEffect(() => {
    setWriteLetterUserJourney(userJourney, currentStep, dispatch)
  }, [userJourney, currentStep, dispatch])

  const [whichRetailerInputData, setWhichRetailerInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })
  const [orderNumberInputData, setOrderNumberInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })

  const [purchasePriceIsInputValid, setPurchasePriceIsInputValid] = useState<boolean>(true)
  const [issueDescriptionIsInputValid, setIssueDescriptionIsInputValid] = useState<boolean>(true)

  const writeLetterRefs = useRef<WriteLetterRefs>(null)
  // *** Start of shared logic ***
  const handleTracking = (value: string, flowViewName: string) => {
    const trackingAnswer = `question | ${flowViewName} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const setStartLetterMilestone = () => {
    dispatch({ type: 'UPDATE_START_LETTER', data: false })
  }
  // *** End of shared logic ***

  // *** Start of WhatRetailer logic ***
  const handleWhichRetailerDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_START_LETTER', data: false })
    dispatch({ type: 'UPDATE_WRITELETTER_STEP', data: 1 })
    dispatch({
      type: 'UPDATE_RETAILER_NAME',
      data: value,
    })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: PurchasePriceFlow.PROGRESS_VALUE })
  }

  const handleWhichRetailerSubmit = () => {
    const value = getCleanValue(
      writeLetterRefs.current?.whichRetailerRef?.whichRetailerRef.formEntryInput?.value
    )
    const whichRetailerHasError = !isNotEmpty(value)

    setWhichRetailerInputData({
      renderError: whichRetailerHasError,
      value,
    })

    if (!whichRetailerHasError) {
      handleTracking(value, WhichRetailerFlow.VIEW_NAME)
      handleWhichRetailerDispatch(value)
      return
    }
  }
  // *** End of WhichRetailer logic ***

  // *** Start of PurchasePrice Logic ***
  const handlePurchasePriceDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_START_LETTER', data: false })
    dispatch({ type: 'UPDATE_PRODUCT_PRICE', data: value })
    dispatch({ type: 'UPDATE_WRITELETTER_STEP', data: 2 })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: IssueDescriptionFlow.PROGRESS_VALUE })
  }

  const handlePurchasePriceSubmit = () => {
    const value = getCleanValue(
      writeLetterRefs.current?.purchasePriceRef?.purchasePriceRef.formEntryInput?.value
    )
    const isValid: boolean = isNotEmpty(value)

    if (isValid) {
      handleTracking(value, PurchasePriceFlow.VIEW_NAME)
      setPurchasePriceIsInputValid(isValid)
      handlePurchasePriceDispatch(value)
      return
    }

    setPurchasePriceIsInputValid(false)
  }

  // *** End of PurchasePrice logic ***

  // *** Start of IssueDescription Logic ***
  const handleIssueDescriptionDispatch = () => {
    dispatch({ type: 'UPDATE_WRITELETTER_STEP', data: 3 })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: OrderNumberFlow.PROGRESS_VALUE })
  }

  const handleIssueDescriptionChange = () => {
    const value = getCleanValue(
      writeLetterRefs.current?.issueDescriptionRef?.issueDescriptionRef.formEntryTextarea?.value
    )
    dispatch({ type: 'UPDATE_ISSUE_DESCRIPTION', data: value })
  }

  const handleIssueDescriptionSubmit = () => {
    const value: string = getCleanValue(
      writeLetterRefs.current?.issueDescriptionRef?.issueDescriptionRef.formEntryTextarea?.value
    )
    const isValid: boolean = isNotEmpty(value) && validateCharCount(value.length, 2500)
    if (!isValid) {
      setIssueDescriptionIsInputValid(false)
      return
    }

    setIssueDescriptionIsInputValid(true)
    handleTracking('PII', IssueDescriptionFlow.VIEW_NAME)
    handleIssueDescriptionDispatch()
  }

  // *** End of IssueDescription logic ***

  // *** Start of OrderNumber logic ***
  const handleOrderNumberDispatch = (value: string) => {
    if (value) {
      dispatch({
        type: 'UPDATE_RETAILER_ORDERNUM',
        data: value,
      })
    }
    dispatch({
      type: 'UPDATE_STEP',
      data: ContactDetailsFlow.STEP,
    })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: ContactDetailsFlow.PROGRESS_VALUE })
  }

  const handleOrderNumberSubmit = () => {
    const value = getCleanValue(
      writeLetterRefs.current?.orderNumberRef?.orderNumberRef.formEntryInput?.value
    )

    setOrderNumberInputData({
      renderError: false,
      value,
    })

    handleTracking('PII', OrderNumberFlow.VIEW_NAME)
    handleOrderNumberDispatch(value)
  }
  // *** End of OrderNumber logic ***

  const writeLetterObject = {
    toolProgress: progress,
    toolName: entryToolName,
    writeLetterModuleStep: journeyFlow.writeLetter.currentStep,
    issueDescription: {
      props: {
        buttonText: 'Next',
        maxChars: 2500,
        defaultValue: issue.description,
        handleChange: handleIssueDescriptionChange,
        handleSubmit: handleIssueDescriptionSubmit,
        imgObj: data.issue.imgObj,
        isFocused: true,
        isInputValid: issueDescriptionIsInputValid,
        title: data.issue.title,
      },
    },
    orderNumber: {
      props: {
        buttonText: 'Next',
        defaultValue: retailer.orderNum,
        fieldData: {
          renderError: orderNumberInputData.renderError,
          value: orderNumberInputData.value,
        },
        handleSubmit: handleOrderNumberSubmit,
        id: 'order-number',
        imgObj: data.orderNumber.imgObj,
        isFocused: true,
        optional: true,
        isFullWidth: false,
        placeholderText: '',
        testId: 'order-number',
        title: data.orderNumber.title,
      },
    },
    purchasePrice: {
      props: {
        buttonText: 'Next',
        currencySymbol: '£',
        defaultValue: product.price,
        entryToolName,
        isSubmitValid: purchasePriceIsInputValid,
        handleSubmit: handlePurchasePriceSubmit,
        id: 'purchase-price',
        imgObj: data.purchasePrice.imgObj,
        isFocused: true,
        milestone: journeyFlow.startLetter,
        placeholderText: '',
        setStartLetterMilestone: setStartLetterMilestone,
        testId: 'purchase-price',
        title: data.purchasePrice.title,
      },
    },
    whichRetailer: {
      props: {
        buttonText: 'Next',
        defaultValue: retailer.name,
        entryToolName,
        fieldData: {
          renderError: whichRetailerInputData.renderError,
          value: whichRetailerInputData.value,
        },
        handleSubmit: handleWhichRetailerSubmit,
        id: 'which-retailer',
        imgObj: data.whichRetailer.imgObj,
        isFocused: true,
        milestone: journeyFlow.startLetter,
        placeholderText: data.whichRetailer.placeholderText,
        setStartLetterMilestone: setStartLetterMilestone,
        testId: 'which-retailer',
        title: data.whichRetailer.title,
      },
    },
  }

  return <WriteLetterModule ref={writeLetterRefs} writeLetterObj={writeLetterObject} />
}
