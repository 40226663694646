export const howMuchMoneyData = {
  title: 'Approximately how much money was taken?',
  imgObj: {
    alt: 'A person talking on the phone in front of a large pile of gold coins',
    src: 'https://media.product.which.co.uk/prod/images/original/73b4c988f66d-approximately-how-much-money-was-taken.jpg',
  },
  id: 'how-much-money',
  options: [
    {
      label: 'Pound Sterling (GBP)',
      value: 'gbp',
    },
    {
      label: 'US Dollar (USD)',
      value: 'usd',
    },
    {
      label: 'Euro (EUR)',
      value: 'eur',
    },
    {
      label: 'Japanese Yen (JPY)',
      value: 'jpy',
    },
    {
      label: 'Australian Dollar (AUD)',
      value: 'aud',
    },
    {
      label: 'Canadian Dollar (CAD)',
      value: 'cad',
    },
    {
      label: 'Swiss Frank (CHF)',
      value: 'chf',
    },
    {
      label: 'Chinese Renminbi (CNH)',
      value: 'cnh',
    },
    {
      label: 'Hong Kong Dollar (HKD)',
      value: 'hkd',
    },
    {
      label: 'New Zealand Dollar (NZD)',
      value: 'nzd',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ],
}
