import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { LimitedOptionsFlow, ProviderContactFlow, WhichProviderFlow } from '../../pageFlowData'
import { providerContactData } from './data'

export const ProviderContact: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
  } = state

  const handleTracking = (id: string) => {
    const trackingAnswer = `question | ${ProviderContactFlow.VIEW_NAME} | ${id}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleStepChoice = (id: string) => {
    if (id === 'yes') {
      return { progress: WhichProviderFlow.PROGRESS_VALUE, step: WhichProviderFlow.STEP }
    }

    return { progress: LimitedOptionsFlow.PROGRESS_VALUE, step: LimitedOptionsFlow.STEP }
  }

  const handleDispatch = (id: string) => {
    const { progress, step } = handleStepChoice(id)
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleSubmit = (id: string) => {
    handleTracking(id)
    handleDispatch(id)
  }

  const { buttons, imgObj, title } = providerContactData

  return (
    <PillButtonPage
      buttons={buttons}
      defaultValue=""
      handleSubmit={handleSubmit}
      imgObj={imgObj}
      title={title}
    />
  )
}
