import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'
import { Typography } from '@which/seatbelt'

import classNames from 'classnames'

import { PageWithText } from '../../../../shared/components/PageWithText'
import { surveyInfo } from '../../../../shared/pages/ImpactSurveyPage/data'
import { ToolContext } from '../../../../shared/state/appContext'
import globalStyles from '../../../../shared/styles/GlobalStyles.module.scss'
import { getStepData } from '../../utils/getStepData'
import { submitSuccessfulData } from './data'
import styles from './SubmitSuccessful.module.scss'

export const SubmitSuccessful: FunctionComponent = () => {
  const { state } = useContext(ToolContext)

  const {
    user: { toolID },
  } = state
  const { toolName } = getStepData(toolID)
  const { standFirst, bodyCopy } = submitSuccessfulData

  const titleWithToolName = standFirst.replace('{toolName}', toolName)

  return (
    <div className={styles.submitSuccessful}>
      <PageWithText
        bodyCopy={bodyCopy}
        title={titleWithToolName}
        titleClassName={styles.submitSuccessfulTitle}
      />
      <Typography
        className={classNames(globalStyles.spacing40Top, styles.submitSuccessfulLink)}
        dangerouslySetInnerHTML={{ __html: surveyInfo }}
        textStyle="body"
      />
    </div>
  )
}
