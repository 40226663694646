import React, { useLayoutEffect, useState } from 'react'

import type { PageFadeProps } from '../../types/toolTypes'
import { checkSessionStorageExists } from '../../utils/checkSessionStorageExists'
import { getSessionStorageItem } from '../../utils/getSessionStorageItem'
import { checkPropsAreEqual } from './checkPropsAreEqual'
import styles from './PageFade.module.scss'

export const PageFade = React.memo<PageFadeProps>(
  ({
    children,
    excludedSteps = [1],
    fullWidth,
    isForward,
    setIsForward = () => null,
    stateProgress,
    step,
    toolName,
  }) => {
    const [animation, setAnimation] = useState('none')

    useLayoutEffect(() => {
      //My Money Health Check
      if (checkSessionStorageExists(`${toolName}Progress`) && toolName === 'MyMoneyHealthCheck') {
        const sessionProgress = getSessionStorageItem(`${toolName}Progress`)
        const previousSessionProgressIndex = sessionProgress.length - 2
        const prevProgress = sessionProgress[previousSessionProgressIndex]

        if (
          !excludedSteps.includes(step) &&
          sessionProgress.length > 1 &&
          prevProgress !== stateProgress
        ) {
          if (stateProgress < prevProgress) {
            setAnimation(styles.fadeInFromLeft)
            return
          }
          setAnimation(styles.fadeInFromRight)
          return
        }
        return
      }

      //All other tools
      if (!excludedSteps.includes(step)) {
        if (isForward) {
          setAnimation(styles.fadeInFromRight)
          setIsForward?.(true)
          return
        }

        setAnimation(styles.fadeInFromLeft)
        setIsForward?.(true)
        return
      }
      if (!isForward) {
        setAnimation(styles.fadeInFromLeft)
      }
      setIsForward?.(true)
      return
    }, [excludedSteps, isForward, setIsForward, stateProgress, step, toolName])

    return (
      <div
        data-testid="page-fade"
        key={step}
        style={{
          animation: `${animation} 0.6s ease`,
          maxWidth: fullWidth ? 'none' : '893px',
          marginLeft: 'auto',
          marginRight: 'auto',
          position: 'relative',
          willChange: 'opacity',
        }}
      >
        {children}
      </div>
    )
  },
  // @ts-expect-error - needs investigation and fix
  checkPropsAreEqual
)
