export const whichTraderData = {
  title:
    'As the work was conducted by a Which? Trusted Trader, we suggest you contact the Trusted Trader support team',
  standFirst: 'How we will handle your complaint:',
  bodyCopy: [
    'We will do all we can to resolve your complaint on the same day or within 3 working days after the day that you contact us',
    'If we can’t resolve your complaint in this time frame, we will write to you within 5 working days to let you know when you can expect a full response',
    'We will also provide you with a reference number and contact details of the team who will be dealing with your complaint',
    'We will keep you regularly informed of our progress by your preferred communication method. Once all the details of your complaint have been investigated, we will issue our response',
    'If our investigations are likely to take more than 8 weeks from when you originally contacted us, we will write to you with an update on our progress and to let you know what next steps you can take.',
  ],
  link: {
    href: 'https://trustedtraders.which.co.uk/about/contact-us/',
    text: 'Contact Trusted Traders',
    headline:
      'At Which? we are committed to providing you with an exceptional standard of service. We want to help put things right and there are two different ways in which you can tell us about your complaint; please choose the right one for you.',
  },
  ctaData: {
    image: {
      src: 'https://media.product.which.co.uk/prod/images/original/gm-8b0968cf-b7d0-49e7-b5fd-ff38b3887a0d-limitedoptionsfaultygoods.png',
      alt: 'Two people looking at their phones facing away from each other.',
    },
    author: { name: 'Which?', jobTitle: 'Editorial team' },
  },
  buttonText: 'Continue to write the complaint letter',
}
