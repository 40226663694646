import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { ArticleCTAPage } from '../../../../shared/pages/ArticleCTAPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { chooseDataSet } from './chooseDataSet'
import { ctaData } from './data'

export const LimitedOptions: FunctionComponent = () => {
  const { state } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    issue,
    product,
    retailer,
  } = state

  const data = chooseDataSet({
    channel: retailer.channel,
    shortDesc: issue.shortDesc,
    type: product.type,
    whenBought: retailer.whenBought,
  })
  const { bodyCopy, standFirst, title, link } = data
  const { headline, href, text } = link

  const handleTracking = () => {
    generalDataLayer(formatToolName(entryToolName), `onward journey | ${text} | ${href}`)
  }

  return (
    <div data-testid="limited-options">
      <ArticleCTAPage
        articleHeading={headline}
        articleLink={link}
        articleTitle={text}
        bodyCopy={bodyCopy}
        ctaData={ctaData}
        handleSubmit={() => handleTracking()}
        standFirst={standFirst}
        title={title}
      />
    </div>
  )
}
