import type { InfoBoxProps } from '../../../../../../shared/types/toolTypes'

const shared = {
  imgObj: {
    alt: 'two people holding documents',
    src: 'https://media.product.which.co.uk/prod/images/original/8f95d1a7f0dc-cp-step9.jpg',
  },
}

export const option1 = {
  ...shared,
  buttons: [
    {
      label: 'Breach of a lease',
      id: 'breach-of-lease',
    },
    {
      label: 'Commercial lease query',
      id: 'commercial-lease-query',
    },
    {
      label: 'Disrepair',
      id: 'disrepair',
      tooltip: 'Disputes or issues relating to repair at the property',
    },
    {
      label: 'A query as a freeholder or part of a management company',
      id: 'freeholder-query-management-company',
    },
    {
      label: 'Leaseholder freehold purchase process',
      id: 'leaseholder-purchase-process',
    },
    {
      label: 'Ground rent',
      id: 'ground-rent',
    },
    {
      label: 'Lease extension',
      id: 'lease-extension',
      tooltip: 'For help with the process of extending leases',
    },
    {
      label: 'Leaseholder vs leaseholder',
      id: 'leaseholder-vs-leaseholder',
      tooltip: 'Where you are in disputes with other leaseholders',
    },
    {
      label: 'Lease restrictions, permissions & fees',
      id: 'lease-restrictions',
    },
    {
      label: 'Right to manage',
      id: 'Right to manage',
      tooltip: 'The process for taking over the management of leasehold properties',
    },
    {
      label: 'Service charges & maintenance',
      id: 'service-charges',
    },
    {
      label: 'Tribunal/court action related to a lease issue',
      id: 'tribunal-court-action',
    },
  ],
  title: 'Which of the following best matches your query?',
}

export const option2 = {
  ...shared,
  buttons: [
    {
      label: "Advice about my neighbour's planning permission (and I'm in England)",
      id: 'neighbour-planning-permission-uk',
    },
    {
      label: "Advice about my neighbour's planning permission (and I'm in Wales)",
      id: 'neighbour-planning-permission-wales',
    },
    {
      label:
        "Advice about my neighbour's planning permission (and I'm in Scotland, Northern Ireland or overseas",
      id: 'neighbour-planning-permission-scotland',
    },
    {
      label: 'Help with my own planning permission',
      id: 'own-planning-permission',
    },
  ],
  title:
    "Does your query relate to your neighbour's planning permission, or your planning permission in relation to your own property?",
  infoBox: {
    heading: 'What does this mean?',
    borderColour: 'blue',
  } as InfoBoxProps,
  infoBoxParagraphs: [
    {
      text: 'The law is not always the same in different parts of the UK. Please select the country which is relevant to your query - this is not necessarily the county you live in. For example, if you live in Scotland but need assistance with a probate matter in England, select England.',
    },
  ],
}
