import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'

import { TextAreaPage } from '../../pages/TextAreaPage'
import type { FormEntryTextAreaRef, IssueDescriptionObj } from '../../types/toolTypes'
import { pageViewDataLayer } from '../../utils/tracking'

export const IssueDescription = forwardRef(
  (
    {
      props: {
        buttonText,
        defaultValue,
        handleChange,
        handleSubmit,
        imgObj,
        isInputValid,
        maxChars,
        title,
      },
    }: IssueDescriptionObj,
    ref
  ) => {
    const issueDescriptionRef = useRef<FormEntryTextAreaRef>(null)

    useImperativeHandle(ref, () => ({
      issueDescriptionRef: issueDescriptionRef.current,
    }))

    useEffect(() => {
      pageViewDataLayer('issue-description')
    }, [])

    return (
      <div data-testid="issue-description">
        <TextAreaPage
          buttonText={buttonText}
          defaultValue={defaultValue}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          imgObj={imgObj}
          isFocused
          isInputValid={isInputValid}
          maxChars={maxChars}
          ref={issueDescriptionRef}
          title={title}
        />
      </div>
    )
  }
)
