import type { FunctionComponent } from 'react'
import React from 'react'
import { Link, Typography } from '@which/seatbelt'

import styles from '../ResultsPage.module.scss'

export const EmailTerms: FunctionComponent = () => (
  <Typography
    className="sb-padding-top-2 sb-padding-bottom-2"
    data-testId="privacy-terms"
    textStyle="very-small-print"
  >
    The <Link href="https://www.which.co.uk/help/general-terms-aYhBS9Y7TTtE">Which? group</Link> is
    the Consumers' Association (a charity) that owns Which? Ltd. It promotes and protects consumer
    interests through information, policy work, campaigns and legal actions. Which? Ltd provides
    most of the group's commercial services, including legal, wills and switching services. Most of
    the content on{' '}
    <Link className={styles.termsLinkSmallPrint} href="https://www.which.co.uk">
      which.co.uk
    </Link>{' '}
    is provided by Which? Ltd, with some content and services provided by Consumers' Association
    including consumer rights pages, and information on its work for consumers.{' '}
    <Link
      className={styles.termsLinkSmallPrint}
      href="https://www.which.co.uk/about-which/who-we-are"
    >
      Read more about the Which? group
    </Link>
    .
  </Typography>
)
