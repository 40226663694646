import type { FunctionComponent, Ref } from 'react'
import React from 'react'
import { Button, Checkbox, Link, Typography } from '@which/seatbelt'

import { ErrorMessage } from '../../../../../shared/components/ErrorMessage'
import { FormEntryItem } from '../../../../../shared/components/FormEntryItem'
import styles from '../ResultsPage.module.scss'
import { EmailTerms } from './EmailTerms'

export const EmailCard: FunctionComponent<Props> = ({
  emailButtonText,
  emailTitle,
  errorCount,
  firstName: {
    ref: firstNameRef,
    errorMessage: firstNameErrorMessage,
    hasError: firstNameHasError,
    value: firstNameValue,
  },
  emailAddress: {
    ref: emailAddressRef,
    errorMessage: emailAddressErrorMessage,
    hasError: emailAddressHasError,
    value: emailAddressValue,
  },
  handleInputValueChange,
  handleSendResults,
  handleShowTerms,
  handlesingleConsentOptIn,
  showEmailTerms,
  unsubText,
  singleConsentOptIn,
}) => (
  <div className={styles.boxedCard}>
    <Typography
      className="sb-padding-bottom-3"
      data-testid="generic-card-title"
      tag="h4"
      textStyle="title-400"
    >
      {emailTitle}
    </Typography>

    <FormEntryItem
      className={styles.sendResultsInput}
      displayText="First name"
      fieldData={{
        errorMessageText: firstNameErrorMessage,
        renderError: firstNameHasError,
        value: firstNameValue,
      }}
      handleOnChange={handleInputValueChange}
      id="firstName"
      isFullWidth={true}
      optional={false}
      ref={firstNameRef}
      srLabel="first name"
      stateValue={firstNameValue}
      type="textbox"
    />

    <FormEntryItem
      className={styles.sendResultsInput}
      displayText="Email address"
      fieldData={{
        errorMessageText: emailAddressErrorMessage,
        renderError: emailAddressHasError,
        value: emailAddressValue,
      }}
      handleOnChange={handleInputValueChange}
      id="emailAddress"
      isFullWidth={true}
      optional={false}
      ref={emailAddressRef}
      srLabel="email address"
      stateValue={emailAddressValue}
      type="textbox"
    />

    <div data-testid="optin-item">
      <Checkbox
        checked={singleConsentOptIn}
        className={styles.sendResultsCheckbox}
        data-testid="which-single-consent-opt-in"
        id="singleConsentOptIn"
        label="Keep me updated about products and services from the Which? Group and the work it does to make life simpler, safer and fairer for UK consumers."
        name="singleConsentOptIn"
        onChangeCallback={handlesingleConsentOptIn}
        value="singleConsentOptIn"
      />
    </div>

    <div className={styles.termsLinkWrapper}>
      <Link
        className={styles.termsSmallPrintToggle}
        data-testid="show-more-link"
        href="#"
        onClick={handleShowTerms}
      >
        {showEmailTerms ? 'Show less' : 'Show more'}
      </Link>

      {showEmailTerms && <EmailTerms />}

      <Typography textStyle="small-print-compact">{unsubText}</Typography>
    </div>

    <Button
      appearance="primary"
      className={styles.downloadButton}
      data-testid="email-results-button"
      onClick={handleSendResults}
    >
      {emailButtonText}
    </Button>

    {errorCount > 0 && (
      <span className="sb-text-align-left">
        <ErrorMessage errorText="Something went wrong, please try again" />
      </span>
    )}
  </div>
)

type Props = {
  emailButtonText: string
  emailTitle: string
  errorCount: number
  handleInputValueChange: ({ target }: { target: any }) => void
  handleSendResults: () => any
  handleShowTerms: (e) => any
  handlesingleConsentOptIn: () => any
  firstName: {
    errorMessage: string
    ref: Ref<HTMLInputElement>
    hasError: boolean
    value: string
  }
  emailAddress: {
    errorMessage: string
    ref: Ref<HTMLInputElement>
    hasError: boolean
    value: string
  }
  singleConsentOptIn: boolean
  showEmailTerms: boolean
  unsubText: string
}
