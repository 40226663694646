export const data = {
  buttons: [
    {
      label: 'The flight was delayed',
      id: 'flight-delayed',
    },
    {
      label: 'The flight was cancelled and I was rerouted',
      id: 'flight-rerouted',
    },
    {
      label: 'The flight was cancelled and I did not fly',
      id: 'flight-cancelled',
    },
  ],
  imgObj: {
    alt: 'Stressed man on phone standing next to frustrated women',
    src: 'https://media.product.which.co.uk/prod/images/original/e918158b93c6-flight-tool-delayed-cancelled.jpg',
  },
  title: 'Was the flight delayed or cancelled?',
}
