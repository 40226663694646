import { useEffect, useState } from 'react'

const defaultOptions = {
  config: { attributes: true, childList: true, subtree: true },
}

export const useMutationObservable = (
  targetEl: Node | null,
  callback: MutationCallback,
  options = defaultOptions
) => {
  const [observer, setObserver] = useState<MutationObserver | null>(null)

  useEffect(() => {
    const obs = new MutationObserver(callback)
    setObserver(obs)
  }, [callback, options, setObserver])

  useEffect(() => {
    if (!observer || !targetEl) {
      return
    }

    const { config } = options
    observer.observe(targetEl, config)

    return () => {
      observer?.disconnect()
    }
  }, [observer, targetEl, options])
}
