import { VEHICLE_DETAILS_API } from './env'

export const fetchVehicleDetails = async (regValue: string) => {
  const reg = regValue.replace(/\s/g, '')
  const url = `${VEHICLE_DETAILS_API()}/${reg}`

  const response = await fetch(url)

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`)
  }

  const vehicleDetails = await response.json()

  return vehicleDetails
}

export const getVehicleDetails = async (vehicleRegValue: string) => {
  const vehicleData = await fetchVehicleDetails(vehicleRegValue)

  if (vehicleData.data.length > 0) {
    return {
      vehicleReg: vehicleData.data[0].registration,
      vehicleModel: vehicleData.data[0].model,
      vehicleMake: vehicleData.data[0].make,
      vehicleYear: vehicleData.data[0].manufactureDate.substring(0, 4), //year
    }
  }
  if (vehicleData.data.httpStatus) {
    return { error: 'Registration not found' }
  } else {
    return { error: 'Something went wrong' }
  }
}
