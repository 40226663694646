import type { PageFlowProps } from '../../../shared/types/toolTypes'

export const EntryPointFlow: PageFlowProps = {
  MILESTONE: '',
  PROGRESS_VALUE: 0,
  REDUCERS: [''],
  STEP: 0,
  VIEW_NAME: 'product-type',
}

export const WhatProductFlow: PageFlowProps = {
  MILESTONE: 'milestone | start',
  PROGRESS_VALUE: 10,
  REDUCERS: ['UPDATE_PRODUCT_DESCRIPTION'],
  STEP: 1,
  VIEW_NAME: 'what-product',
}

export const WhatBrandFlow: PageFlowProps = {
  MILESTONE: '',
  PROGRESS_VALUE: 20,
  REDUCERS: ['UPDATE_PRODUCT_BRAND'],
  STEP: 2,
  VIEW_NAME: 'what-brand',
}

export const WhatsWrongFlow: PageFlowProps = {
  MILESTONE: '',
  PROGRESS_VALUE: 25,
  REDUCERS: ['UPDATE_ISSUE_SHORTDESC'],
  STEP: 3,
  VIEW_NAME: 'issue',
}

export const ProductModelFlow: PageFlowProps = {
  MILESTONE: '',
  PROGRESS_VALUE: 30,
  REDUCERS: ['UPDATE_PRODUCT_MODEL'],
  STEP: 4,
  VIEW_NAME: 'product-model',
}

export const WhereDidYouBuyFlow: PageFlowProps = {
  MILESTONE: '',
  PROGRESS_VALUE: 35,
  REDUCERS: ['UPDATE_RETAILER_CHANNEL'],
  STEP: 5,
  VIEW_NAME: 'purchase-channel',
}

export const WhenPurchasedFlow: PageFlowProps = {
  MILESTONE: '',
  PROGRESS_VALUE: 40,
  REDUCERS: ['UPDATE_RETAILER_WHEN_BOUGHT'],
  STEP: 6,
  VIEW_NAME: 'when-purchased',
}

export const OnFinanceFlow: PageFlowProps = {
  MILESTONE: '',
  PROGRESS_VALUE: 45,
  REDUCERS: [''],
  STEP: 7,
  VIEW_NAME: 'purchase-method',
}

export const AttemptedRepairFlow: PageFlowProps = {
  MILESTONE: '',
  PROGRESS_VALUE: 50,
  REDUCERS: ['UPDATE_RETAILER_ATTEMPTEDRESOLVEITEM'],
  STEP: 8,
  VIEW_NAME: 'seller-attempted-resolution',
}

export const EntitlementFlow: PageFlowProps = {
  MILESTONE: 'milestone | next steps | write letter',
  PROGRESS_VALUE: 60,
  REDUCERS: [''],
  STEP: 9,
  VIEW_NAME: 'display-entitlement',
}

export const WhichRetailerFlow: PageFlowProps = {
  MILESTONE: 'milestone | write letter',
  PROGRESS_VALUE: 70,
  REDUCERS: ['UPDATE_RETAILER_NAME'],
  STEP: 10,
  VIEW_NAME: 'which-retailer',
  WRITE_LETTER_STEP: 0,
}

export const PurchasePriceFlow: PageFlowProps = {
  MILESTONE: 'milestone | write letter',
  PROGRESS_VALUE: 80,
  REDUCERS: ['UPDATE_PRODUCT_PRICE'],
  STEP: 11,
  VIEW_NAME: 'purchase-price',
  WRITE_LETTER_STEP: 1,
}

export const IssueDescriptionFlow: PageFlowProps = {
  MILESTONE: '',
  PROGRESS_VALUE: 85,
  REDUCERS: [''],
  STEP: 12,
  VIEW_NAME: 'issue-description',
  WRITE_LETTER_STEP: 2,
}

export const OrderNumberFlow: PageFlowProps = {
  MILESTONE: '',
  PROGRESS_VALUE: 90,
  REDUCERS: ['UPDATE_RETAILER_ORDERNUM'],
  STATE_VALUE: { UPDATE_RETAILER_ORDERNUM: '' },
  STEP: 13,
  WRITE_LETTER_STEP: 3,
  VIEW_NAME: 'order-number',
}

export const ContactDetailsFlow: PageFlowProps = {
  MILESTONE: 'milestone | personal details',
  PROGRESS_VALUE: 95,
  REDUCERS: [''],
  STEP: 14,
  VIEW_NAME: 'contact-details',
}

export const SubmitSuccessfulFlow: PageFlowProps = {
  MILESTONE: 'milestone | end | letter sent',
  PROGRESS_VALUE: 100,
  REDUCERS: [''],
  STEP: 15,
  VIEW_NAME: 'journey-complete',
}

export const LimitedOptionsFlow: PageFlowProps = {
  MILESTONE: 'milestone | next steps | end',
  MILESTONE_S75_ENTRY: 'milestone | next steps | s75 and chargeback',
  PROGRESS_VALUE: 100,
  REDUCERS: [''],
  STEP: 16,
  VIEW_NAME: 'limited-options',
}

export const WriteLetterFlow: PageFlowProps = {
  MILESTONE: '',
  PROGRESS_VALUE: 70,
  REDUCERS: [''],
  STEP: 100,
  VIEW_NAME: 'write-letter',
}

export const ToolFeedbackFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 17,
  VIEW_NAME: 'feedback-form',
  REDUCERS: [''],
  MILESTONE: 'milestone | feedback start',
}

export const ToolFeedbackThankYouFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 19,
  VIEW_NAME: 'feedback-form',
  REDUCERS: [''],
  MILESTONE: '',
}

export const ToolFeedbackAudienceFlow: PageFlowProps = {
  PROGRESS_VALUE: 90,
  STEP: 18,
  VIEW_NAME: 'feedback-audience-form',
  REDUCERS: [''],
  MILESTONE: '',
}

export const AllFlows = [
  EntryPointFlow,
  WhatProductFlow,
  WhatBrandFlow,
  WhatsWrongFlow,
  ProductModelFlow,
  WhereDidYouBuyFlow,
  WhenPurchasedFlow,
  OnFinanceFlow,
  AttemptedRepairFlow,
  EntitlementFlow,
  WhichRetailerFlow,
  PurchasePriceFlow,
  IssueDescriptionFlow,
  OrderNumberFlow,
  ContactDetailsFlow,
  SubmitSuccessfulFlow,
  LimitedOptionsFlow,
  WriteLetterFlow,
  ToolFeedbackFlow,
  ToolFeedbackThankYouFlow,
  ToolFeedbackAudienceFlow,
]
