import styles from '../../../../../shared/utils/renderInfoBox/renderInfoBox.module.scss'
import type { InfoBoxProps } from './../../../../../shared/types/toolTypes'

export const usedCardData = {
  buttons: [
    {
      label: 'Yes',
      id: 'yes',
    },
    {
      label: 'No',
      id: 'no',
    },
  ],
  title: 'Did you use a credit card, linked credit agreement or debit card to pay for the service?',
  imgObj: {
    alt: 'A persons hand holding a mobile phone',
    src: 'https://media.product.which.co.uk/prod/images/original/ff8f046c1f77-step10.jpg',
  },
  infoBox: {
    heading: 'Why are we asking this question?',
    borderColour: 'blue',
  } as InfoBoxProps,
  infoBoxParagraphs: [
    {
      classname: `${styles.renderInfoBoxParagraphs}`,
      text: 'If you paid for the service using one of the above methods and your initial complaint fails. Financial legislations are in place to help protect consumers like you.',
    },
    {
      classname: `${styles.renderInfoBoxHeadings}`,
      text: 'Section 75:',
    },
    {
      classname: `${styles.renderInfoBoxParagraphs}`,
      text: 'If you paid using your credit card or linked credit agreement and the cost was over £100 but not more than £30,000, you may be eligible for an S75 claim.',
    },
    {
      classname: `${styles.renderInfoBoxHeadings}`,
      text: 'Chargeback:',
    },
    {
      text: "While it's not a law, it is part of the Scheme Rules that participating banks subscribe to. If you paid by debit card, chargeback is available on all goods bought on debit card, but the rules may vary between card issuers. ",
    },
    {
      text: 'If you paid by credit card, chargeback can be useful if the cost of the goods or services was less than £100.',
    },
  ],
}
