// A separate object for the infoBox data is used due to the typing of the borderColour attribute
import type { InfoBoxProps } from '../../../../../shared/types/toolTypes'

export const infoBox: InfoBoxProps = {
  heading: 'What could this include?',
  borderColour: 'blue',
}

export const hasExpensesData = {
  hasExpensesButtons: [
    {
      label: 'Yes',
      id: 'yes',
    },
    {
      label: 'No',
      id: 'no',
    },
  ],
  title: 'Are you claiming for out-of-pocket expenses?',
  image: {
    src: 'https://media.product.which.co.uk/prod/images/original/gm-2d672bc1-3d57-4468-825d-caad3886966f-holiday-complaint-8.jpg',
    alt: 'A person on a phone with a stack of money behind them',
  },
  infoBox,
  infoBoxParagraphs: [
    {
      text: 'For example, if the food that was part of an all-inclusive deal was inedible, you could claim the reasonable cost of buying food elsewhere',
    },
  ],
}
