export const getDatePickerValidationErrorMessage = /* istanbul ignore next */ (
  dateInputValue: string,
  dontAllowFutureDate?: boolean,
  messageForFutureDate?: string
) => {
  if (!dateInputValue) {
    return 'Date field cannot be left blank'
  }
  const DATE_FORMAT = /^(\d{4})\-(\d{1,2})\-(\d{1,2})$/
  const dateObj = new Date(dateInputValue)
  const matches = dateInputValue.match(DATE_FORMAT)
  if (isNaN(dateObj.getTime()) || !matches) {
    return 'Date entered is invalid'
  }
  if (dontAllowFutureDate && dateObj > new Date()) {
    return messageForFutureDate ? messageForFutureDate : 'Please select a valid past date'
  }
  return null
}
