import type { FunctionComponent } from 'react'
import React from 'react'
import { Grid, GridItem } from '@which/seatbelt'

import { MultiCardSectionWrapper } from '../MultiCardSectionWrapper'
import { MultiLinkCard } from '../MultiLinkCard'
import styles from './MultiLinkWrapper.module.scss'

export const MultiLinkWrapper: FunctionComponent<Props> = ({
  backgroundColour,
  linkBlocks,
  standfirst,
  testId,
  title,
  toolName,
  className,
}) => {
  const linkBlockLength = linkBlocks.length

  const renderLinkBlocks = () => {
    return linkBlocks.map((linkBlock, index) => {
      const { blockTitle, image, links, savings } = linkBlock

      return (
        <MultiLinkCard
          className={styles.multiLinkCard}
          image={image.src}
          imageAlt={image.alt}
          key={`${blockTitle}-${index}`}
          links={links}
          savingsText={savings}
          title={blockTitle}
          toolName={toolName}
        />
      )
    })
  }

  return (
    <MultiCardSectionWrapper
      backgroundColour={backgroundColour}
      className={className}
      standfirst={standfirst}
      testId={testId}
      title={title}
    >
      <div className={styles.wrapper}>
        <Grid includeGutters={false}>
          <GridItem
            columnStart={{ medium: 2, large: 2 }}
            key={'linkBlock-card'}
            span={{ medium: 10, large: 10 }}
          >
            <div
              className={`${styles.multiLinkBlocksGrid} ${
                linkBlockLength === 2
                  ? styles.multiLinkBlocksGridTwoCol
                  : styles.multiLinkBlocksGridThreeCol
              }`}
            >
              {renderLinkBlocks()}
            </div>
          </GridItem>
        </Grid>
      </div>
    </MultiCardSectionWrapper>
  )
}

type Props = {
  backgroundColour?: string
  linkBlocks: {
    blockTitle: string
    image: {
      src: string
      alt: string
    }
    links: {
      href: string
      text: string
    }[]
    savings?: string
  }[]
  standfirst: string
  testId: string
  title: string
  toolName: string
  className?: string
}
