import type { FunctionComponent } from 'react'
import React from 'react'

import { ArticleCTAPage } from '../../../../shared/pages/ArticleCTAPage'
import { underConstructionData } from './data'

export const UnderConstruction: FunctionComponent = () => {
  const { title, standFirst, bodyCopy } = underConstructionData
  return (
    <div data-testid="Under-construction">
      <ArticleCTAPage bodyCopy={bodyCopy} standFirst={standFirst} title={title} />
    </div>
  )
}
