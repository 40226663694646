import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PreviewLetter } from '../../../../shared/components/PreviewLetter'
import { SubmitSuccessfulPage } from '../../../../shared/pages/SubmitSuccessfulPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { SubmitSuccessProps } from '../../../../shared/types/toolTypes'
import { ToolFeedbackFlow, ToolFeedbackThankYouFlow } from '../../pageFlowData'
import { composeEmailBody } from './composeEmailBody'
import { feedbackData } from './data'

export const SubmitSuccessful: FunctionComponent<SubmitSuccessProps> = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { emailSent, journeyFlow, user },
    issue,
    product,
    retailer,
    toolFeedback: { showFeedback },
  } = state

  const { body, imgObj, title } = feedbackData

  const data = composeEmailBody({ retailer, product, issue, journeyFlow, user })

  const standFirstCopy = emailSent
    ? `A copy of your claim letter has been sent to ${user.emailAddress}`
    : 'Due to an error, we were unable to send your email. However, you can get a copy of your claim letter below'

  const handleStepChoice = (id: string) => {
    if (id === 'feedback-yes') {
      return { progress: ToolFeedbackFlow.PROGRESS_VALUE, step: ToolFeedbackFlow.STEP }
    }

    return {
      progress: ToolFeedbackThankYouFlow.PROGRESS_VALUE,
      step: ToolFeedbackThankYouFlow.STEP,
    }
  }

  const handleDispatch = (id: string) => {
    const { progress, step } = handleStepChoice(id)

    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleSubmit = (id: string) => {
    handleDispatch(id)
  }

  return (
    <div data-testid="submit-successful">
      <SubmitSuccessfulPage
        feedbackBody={body}
        feedbackImgObj={imgObj}
        feedbackTitle={title}
        handleSubmit={handleSubmit}
        letterBody={data.body}
        letterTitle="Your complaint email"
        list={[
          `Send the letter to ${issue.cardProvider} by post or email.`,
          "There's no set timeframe for your card provider to resolve a Section 75 claim, but if you're unhappy with the outcome of the claim, or how long it's taking, you can submit a complaint to the card provider.",
          `If your credit card company doesn't accept that you have a claim and refuses to pay up, you can ask for a
          <a class='sb-link-primary' href="https://www.which.co.uk/consumer-rights/letter/letter-of-deadlock-request-before-going-to-the-ombudsman">
            <span class='sb-link-animation-wrapper'>letter of deadlock</span>
          </a> so that you can refer your dispute to the
          <a class='sb-link-primary' href="https://www.which.co.uk/consumer-rights/advice/can-i-take-my-financial-complaint-to-the-financial-ombudsman">
            <span class='sb-link-animation-wrapper'>Financial Ombudsman Service (FOS)</span
          </a>.`,
          'If more than eight weeks have passed since you submitted your claim to your credit card provider, you can refer your claim to the FOS straight away without the need for a deadlock letter.',
        ]}
        showFeedback={showFeedback}
        standFirst={standFirstCopy}
        title="Your next steps"
      >
        <PreviewLetter data={data} />
      </SubmitSuccessfulPage>
    </div>
  )
}
