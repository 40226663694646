export const getScamSourceData = (scamSource: string) => {
  switch (scamSource) {
    case 'telephone':
      return telephoneData
    case 'online':
      return onlineData
    case 'email':
      return emailData
    case 'text-message':
      return textData
    case 'whatsapp':
      return whatsappData
    case 'came-to-house':
      return houseData
    case 'letter':
      return letterData
    case 'social-media':
      return socialMediaData
    default:
      return telephoneData
  }
}

const telephoneData = {
  title: 'What was the nature of the call?',
  imgObj: {
    alt: 'Person on phone standing around bags of shopping',
    src: 'https://media.product.which.co.uk/prod/images/original/69d46fbb8d29-what-was-the-nature-of-the-call-.jpg',
  },
  buttonData: [
    { label: 'Someone impersonating a bank', id: 'impersonating-bank' },
    {
      label:
        'Someone impersonating an authority or government service, e.g. the council, the police or HMRC',
      id: 'impersonating-authority',
    },
    {
      label: 'Someone impersonating an internet service provider or telephone company e.g. BT',
      id: 'impersonating-internet-telephone-company',
    },
    {
      label: 'Someone impersonating a retailer e.g. Amazon',
      id: 'impersonating-retailer',
    },
    {
      label: 'A new cryptocurrency or investment',
      id: 'new-cypto-investment',
    },
    {
      label: 'Someone calling me to say they can help me recover money I lost in the past',
      id: 'recover-lost-money',
    },
    { label: 'Someone trying to sell me a product/service', id: 'sell-product-service' },
    { label: 'Timeshare', id: 'timeshare' },
    {
      label: 'Someone offering to repair something on my laptop/computer e.g. slow internet/virus',
      id: 'offering-to-repair-computer',
    },
    { label: 'Other', id: 'other' },
  ],
}

const onlineData = {
  title: 'Can you tell us more about what type of scam this was?',
  imgObj: {
    alt: 'Man sitting at a computer',
    src: 'https://media.product.which.co.uk/prod/images/original/985fe048a8f4-can-you-tell-us-more-about-what-type-of-scam-this-was-.jpg',
  },
  buttonData: [
    {
      label:
        'It advertised a product, service or competition on social media e.g. Facebook, Instagram',
      id: 'advertised-on-social-media',
    },
    { label: 'It was a product I tried to buy online', id: 'buy-product-online' },
    { label: 'I was trying to buy cryptocurrency', id: 'buy-crypto' },
    {
      label: 'It was an investment opportunity',
      id: 'investment-opportunity',
    },
    {
      label:
        'It charged for something that should have been free, e.g. travel visa, tax disc, GHIC card',
      id: 'charged-for-something-free',
    },
    { label: 'Other', id: 'other' },
  ],
}

const emailData = {
  title: 'Who did the email appear to be from?',
  imgObj: {
    alt: 'Person looking at their phone',
    src: 'https://media.product.which.co.uk/prod/images/original/39113827676e-who-did-the-email-appear-to-be-from-.jpg',
  },
  buttonData: [
    { label: 'Telephone company', id: 'telephone-company' },
    { label: 'Bank', id: 'bank' },
    { label: 'Retailer e.g. Apple', id: 'retailer' },
    { label: 'Government service or department e.g. NHS, HMRC, council', id: 'government' },
    { label: 'TV licensing', id: 'tv-licensing' },
    { label: 'TV streaming service e.g. Netflix', id: 'streaming-service' },
    { label: 'Delivery company e.g. Evri, DHL, DPD', id: 'delivery-company' },
    { label: 'Food delivery company', id: 'food-delivery-company' },
    { label: 'Payment service e.g. Paypal', id: 'payment-service' },
    { label: 'Utility company e.g. British Gas', id: 'utility-company' },
    { label: 'A tradesman', id: 'tradesman' },
    { label: 'Solicitor', id: 'solicitor' },
    { label: 'Estate agent', id: 'estate-agent' },
    { label: 'Surveyor', id: 'surveyor' },
    { label: 'Shop offering a voucher', id: 'shop-voucher' },
    { label: 'Other', id: 'other' },
  ],
}

const textButtonData = [
  { label: 'Phone provider', id: 'phone-provider' },
  { label: 'Bank', id: 'bank' },
  { label: 'Government service or department e.g. NHS, HMRC', id: 'government' },
  { label: 'TV streaming service e.g. Netflix', id: 'streaming-service' },
  { label: 'Delivery company', id: 'delivery-company' },
  { label: 'Food delivery company', id: 'food-delivery-company' },
  { label: 'Payment service e.g. Paypal', id: 'payment-service' },
  { label: 'Utility company e.g. British Gas', id: 'utility-company' },
  { label: 'Retailer e.g. Apple', id: 'retailer' },
  { label: 'Other', id: 'other' },
]

const textData = {
  title: 'Who did the text appear to be from?',
  imgObj: {
    alt: 'Lady in a baseball cap looking at her phone',
    src: 'https://media.product.which.co.uk/prod/images/original/463f07026746-who-did-the-text-appear-to-be-from-.jpg',
  },
  buttonData: textButtonData,
}

const whatsappData = {
  title: 'Who did the WhatsApp appear to be from?',
  imgObj: {
    alt: 'Lady in a baseball cap looking at her phone',
    src: 'https://media.product.which.co.uk/prod/images/original/463f07026746-who-did-the-text-appear-to-be-from-.jpg',
  },
  buttonData: textButtonData,
}

const houseData = {
  title: 'Who did the person claim to be?',
  imgObj: {
    alt: 'Two people standing next to each other, one is holding a camera',
    src: 'https://media.product.which.co.uk/prod/images/original/68a3dbf54125-who-did-the-person-claim-to-be-.jpg',
  },
  buttonData: [
    { label: 'A tradesman', id: 'tradesman' },
    { label: 'An authority, e.g. the police, NHS or HMRC', id: 'authority' },
    {
      label:
        'A company representative e.g. pretending to be from a utility company, read a meter etc',
      id: 'company-representative',
    },
    { label: 'A charity collector', id: 'charity-collector' },
    { label: 'A doorstep salesman', id: 'doorstep-salesman' },
    { label: 'Other', id: 'other' },
  ],
}

const letterData = {
  title: 'Who did the letter or flyer appear to be from?',
  imgObj: {
    alt: 'A pair of hands holding a letter',
    src: 'https://media.product.which.co.uk/prod/images/original/cb6d246eea1a-who-did-the-letter-or-flyer-appear-to-be-from-.jpg',
  },
  buttonData: [
    { label: 'Lottery or prize draws', id: 'lottery' },
    { label: 'Someone trying to sell me an investment', id: 'investment' },
    { label: 'Unclaimed inheritance', id: 'unclaimed-inheritance' },
    { label: 'Someone official e.g. DVLA, HMRC', id: 'someone-official' },
    { label: 'Debt collection agency', id: 'debt-collection' },
    { label: 'Insurance company', id: 'insurance-company' },
    { label: 'A compensation service', id: 'compensation-service' },
    { label: 'Other', id: 'other' },
  ],
}

const socialMediaData = {
  title: 'Which social media platform?',
  imgObj: {
    alt: 'Person looking at their phone surrounded by social media messages',
    src: 'https://media.product.which.co.uk/prod/images/original/98865e3b6ba5-which-social-media-platform-.jpg',
  },
  buttonData: [
    { label: 'Facebook', id: 'facebook' },
    { label: 'Instagram', id: 'instagram' },
    { label: 'Twitter', id: 'twitter' },
    { label: 'WhatsApp', id: 'whatsapp' },
    { label: 'Snapchat', id: 'snapchat' },
    { label: 'TikTok', id: 'tiktok' },
    { label: 'Other', id: 'other' },
  ],
}
