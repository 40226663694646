export const willsEstateData = {
  imgObj: {
    alt: 'two people holding documents',
    src: 'https://media.product.which.co.uk/prod/images/original/8f95d1a7f0dc-cp-step9.jpg',
  },
  buttons: [
    {
      label: 'I am an executor/administrator',
      id: 'executor-admin',
    },
    {
      label: 'I am a beneficiary',
      id: 'beneficiary',
    },
    {
      label: 'Neither of the above',
      id: 'neither',
    },
  ],
  title: 'Which of the following best describes your interest in the matter?',
}
