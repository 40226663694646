export const environment = (): string => {
  if (typeof window === 'undefined') {
    return 'prod'
  } else {
    switch (window.location?.hostname) {
      case 'localhost':
      case 'dev-deliver.components.product.which.co.uk':
      case 'dev.internal.which.co.uk':
        return 'dev'
      case 'test-deliver.components.product.which.co.uk':
      case 'test.internal.which.co.uk':
        return 'test'
      case 'qa-deliver.components.product.which.co.uk':
        return 'qa'
      case 'preprod-deliver.components.product.which.co.uk':
      case 'preprod.internal.which.co.uk':
        return 'preprod'

      default:
        return 'prod'
    }
  }
}
