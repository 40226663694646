import type { FunctionComponent } from 'react'
import React, { useContext, useEffect, useState } from 'react'

import { CheckBoxGroupPage } from '../../../../shared/pages/CheckBoxGroupPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import {
  LimitedOptionsFlow,
  ProductModelFlow,
  WhatsWrongFlow,
  WhenPurchasedFlow,
  WhereDidYouBuyFlow,
} from '../../pageFlowData'
import { data } from './data'

export const WhatsWrong: FunctionComponent = () => {
  const [isInputValid, setInputValid] = useState(true)
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    issue: { shortDesc },
    product: { type },
  } = state

  useEffect(() => {
    dispatch({ type: 'UPDATE_ISSUE_SHORTDESC', data: shortDesc })
  }, [dispatch, shortDesc])

  const handleCheckBoxChange = ({ id }: { id?: string }) => {
    const checkboxChange = shortDesc || []
    if (!checkboxChange.includes(id)) {
      checkboxChange.push(id)
    } else {
      checkboxChange.splice(checkboxChange.indexOf(id), 1)
    }
    dispatch({ type: 'UPDATE_ISSUE_SHORTDESC', data: checkboxChange })
  }

  const handleStepChoice = () => {
    // product type path
    if (type === 'product') {
      if (shortDesc.includes('safety')) {
        return { step: ProductModelFlow.STEP, progress: ProductModelFlow.PROGRESS_VALUE }
      }
      return { step: WhereDidYouBuyFlow.STEP, progress: WhereDidYouBuyFlow.PROGRESS_VALUE }
    }
    // digital type path
    if (shortDesc.includes('changedMind') && shortDesc.length <= 1) {
      return { step: LimitedOptionsFlow.STEP, progress: LimitedOptionsFlow.PROGRESS_VALUE }
    }
    return { step: WhenPurchasedFlow.STEP, progress: WhenPurchasedFlow.PROGRESS_VALUE }
  }

  const handleAnswerValue = () => shortDesc.join(' | ')

  const handleDispatch = () => {
    const { progress, step } = handleStepChoice()
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${WhatsWrongFlow.VIEW_NAME} | ${handleAnswerValue()}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = () => {
    if (shortDesc && shortDesc.length > 0) {
      setInputValid(true)
      handleTracking()
      handleDispatch()
    }
    setInputValid(false)
  }

  const { digitalButtons, productButtons, imgObj, title } = data
  const buttons = type === 'digital' ? digitalButtons : productButtons

  return (
    <CheckBoxGroupPage
      buttonText="Next"
      buttons={buttons}
      handleChange={handleCheckBoxChange}
      handleSubmit={handleSubmit}
      imgObj={imgObj}
      isInputValid={isInputValid}
      stateValue={shortDesc}
      title={title}
    />
  )
}
