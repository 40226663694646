import { entitlementData } from './data'

export const chooseDataSet = (chooseDataSetProps: Props) => {
  const { type, onFinance, channel, whenBought, attemptedResolveItem, shortDesc } =
    chooseDataSetProps

  // choose data set to use
  const channelOnline = channel === 'online'
  const channelInStore = channel === 'inStore'
  const onlineOrInStore = channelOnline || channelInStore
  const broughtMoreThan6Months = whenBought === '6MonthsAnd6Years'
  const boughtMoreThan30Days = whenBought === '31DaysAnd6Months'
  const boughtLessThan30Days = whenBought === '30DaysOrLess' || whenBought === 'last14Days'
  const boughtLast14Days = whenBought === 'last14Days'
  // all Product types
  if (type === 'product') {
    if (
      channelOnline &&
      boughtLast14Days &&
      shortDesc.includes('changedMind') &&
      shortDesc.length === 1
    ) {
      return entitlementData.option2 // Scenario 1
    } else if (broughtMoreThan6Months && onFinance) {
      return entitlementData.option3 // Scenario 2
    } else if (onlineOrInStore && onFinance && boughtLessThan30Days) {
      return entitlementData.option4 // Scenario 3
    } else if (onlineOrInStore && onFinance && boughtMoreThan30Days) {
      return entitlementData.option5 // Scenario 4
    } else if (onlineOrInStore && !onFinance && boughtLessThan30Days) {
      return entitlementData.option6 // Scenario 5
    } else if (onlineOrInStore && !onFinance && boughtMoreThan30Days) {
      return !attemptedResolveItem ? entitlementData.option7 : entitlementData.option8 // Scenario 6/7
    }
    return entitlementData.option3 // Scenario 8
    // End of Product types
    // all Digital types
  } else if (type === 'digital') {
    if (shortDesc.includes('damagedDevice')) {
      return attemptedResolveItem ? entitlementData.option9 : entitlementData.option10 // Scenario 9/10
    }
    return attemptedResolveItem ? entitlementData.option11 : entitlementData.option12 // Scenario 11/12
  }
  return entitlementData.option1 // default
}

export type Props = {
  type: string
  onFinance: string
  channel: string
  whenBought: string
  attemptedResolveItem: boolean
  shortDesc: [string]
}
