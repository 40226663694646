import type { FunctionComponent } from 'react'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Grid, GridItem } from '@which/seatbelt'

import { ToolWrapper } from '../../shared'
import { PageFade } from '../../shared/components/PageFade'
import { ProgressBar } from '../../shared/components/ProgressBar'
import { ToolContext } from '../../shared/state/appContext'
import styles from '../../shared/styles/GlobalStyles.module.scss'
import type { MisSoldPackagedBankAccountProps } from '../../shared/types/toolTypes'
import { checkSessionStorageExists } from '../../shared/utils/checkSessionStorageExists'
import { formatToolName } from '../../shared/utils/formatToolName'
import { getFilteredPageDataByStep } from '../../shared/utils/getFilteredPageDataByStep'
import { getSessionStorageItem } from '../../shared/utils/getSessionStorageItem'
import { handleScroll } from '../../shared/utils/handleScroll'
import { removeSessionStorage } from '../../shared/utils/removeSessionStorage'
import { setUserJourney } from '../../shared/utils/setUserJourney'
import { storeToolState } from '../../shared/utils/storeToolState'
import {
  generalDataLayer,
  initaliseDataLayer,
  pageViewDataLayer,
} from '../../shared/utils/tracking'
import { useRenderStep } from './hooks/useRenderStep'
import { AllFlows, MonthlyFeeFlow, SubmitSuccessfulFlow } from './pageFlowData'

export const MisSoldPackagedBankAccount: FunctionComponent<MisSoldPackagedBankAccountProps> = ({
  showFeedback,
  toolName,
  CRFooter,
}) => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: {
      entryToolName,
      initialised,
      journeyFlow: { milestoneHit },
      progressValue,
      step,
      userJourney,
    },
  } = state

  const [isForward, setIsForward] = useState(true)

  if (!initialised) {
    removeSessionStorage({ sessionStorageName: `${toolName}Progress` })
  }

  useEffect(() => {
    if (!initialised) {
      dispatch({ type: 'UPDATE_SHOW_FEEDBACK', data: showFeedback })
    }
  }, [dispatch, initialised, showFeedback])

  useEffect(() => {
    handleScroll()
  }, [step])

  useEffect(() => {
    dispatch({ type: 'UPDATE_INITIALISED', data: true })
  }, [dispatch])

  useEffect(() => {
    if (!entryToolName) {
      dispatch({ type: 'UPDATE_ENTRY_TOOL_NAME', data: toolName })
    }
  }, [dispatch, entryToolName, toolName])

  useEffect(() => {
    setUserJourney(userJourney, step, dispatch)
  }, [step, dispatch, userJourney])

  useEffect(() => {
    const firePageView = () => {
      const pageData = getFilteredPageDataByStep(step, AllFlows, [MonthlyFeeFlow])

      const { milestone, viewName } = pageData
      if (initialised === true) {
        pageViewDataLayer(viewName)

        const milestoneArray = milestoneHit

        if (!milestoneArray.includes(viewName) && milestone !== '') {
          milestoneArray.push(viewName)
          generalDataLayer(formatToolName(toolName), milestone)
          dispatch({ type: 'UPDATE_MILESTONE', data: milestoneArray })
          return
        }
      }
    }

    firePageView()
  }, [dispatch, initialised, milestoneHit, step, toolName])

  useEffect(() => {
    if (entryToolName !== '') {
      if (checkSessionStorageExists(`${toolName}Progress`)) {
        let sessionProgress = getSessionStorageItem(`${toolName}Progress`)
        let sessionProgressLength = sessionProgress.length - 1
        if (sessionProgressLength > 2 && step === 1) {
          sessionProgress = []
          sessionProgressLength = sessionProgress.length - 1
          storeToolState(`${toolName}Progress`, sessionProgress)
        }
        if (sessionProgress[sessionProgressLength] !== progressValue) {
          sessionProgress.push(progressValue)
          storeToolState(`${toolName}Progress`, sessionProgress)
        }
      } else {
        storeToolState(`${entryToolName}Progress`, [progressValue])
      }
    }
  })

  return (
    <>
      <ToolWrapper>
        <Helmet>
          <meta content="noindex" name="robots" />
          <script>{initaliseDataLayer(formatToolName(toolName))}</script>
          <title>Mis-sold Packaged Bank Account - free Which? tool</title>
          <link
            href="https://cdn.jsdelivr.net/npm/@duetds/date-picker@1.4.0/dist/duet/themes/default.css"
            rel="stylesheet"
          />
          <meta content="Mis-sold Packaged Bank Account - free Which? tool" name="title" />
          <meta content="summary" name="twitter:card" />
          <meta
            content="If you think you've been mis-sold your packaged bank account, find out how to claim back your fees from your bank or building society."
            name="description"
          />
          <meta content="website" property="og:type" />
          <meta
            content="https://www.which.co.uk/tool/mis-sold-packaged-bank-account"
            property="og:url"
          />
          <meta content="Mis-sold Packaged Bank Account - free Which? tool" property="og:title" />
          <meta
            content="If you think you've been mis-sold your packaged bank account, find out how to claim back your fees from your bank or building society."
            property="og:description"
          />
          <meta
            content="https://www.which.co.uk/tool/mis-sold-packaged-bank-account"
            property="twitter:url"
          />
          <meta
            content="Mis-sold Packaged Bank Account - free Which? tool"
            property="twitter:title"
          />
          <meta
            content="If you think you've been mis-sold your packaged bank account, find out how to claim back your fees from your bank or building society."
            property="twitter:description"
          />
        </Helmet>
        <ProgressBar
          allFlows={AllFlows}
          endFlowStep={SubmitSuccessfulFlow.STEP}
          setIsForward={setIsForward}
          startFlowStep={1}
          stateWidth={progressValue}
          toolName={toolName}
        />
        <PageFade
          isForward={isForward}
          setIsForward={setIsForward}
          stateProgress={progressValue}
          step={step}
          toolName={toolName}
        >
          {useRenderStep(step, CRFooter)}
        </PageFade>
      </ToolWrapper>
      {step === SubmitSuccessfulFlow.STEP && (
        <Grid className="w-page-wrapper">
          <GridItem>
            <section className={styles.footer}>{CRFooter}</section>
          </GridItem>
        </Grid>
      )}
    </>
  )
}
