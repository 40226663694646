export const triageData = {
  buttons: [
    {
      label: 'Legal service',
      id: 'legal',
    },
    {
      label: 'Money Helpline',
      id: 'money',
    },
    {
      label: 'Tech Support',
      id: 'tech',
    },
  ],
  title:
    'Before we check if we can help you with your issue, could you specify what you need help with',
  imgObj: {
    src: 'https://media.product.which.co.uk/prod/images/original/gm-c13ec987-324f-4da1-93da-64d64e6a743a-holiday-complaint-6.jpg',
    alt: 'Two people stressed around a desk',
  },
}
