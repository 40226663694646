import type { FunctionComponent } from 'react'
import React from 'react'

import classnames from 'classnames'

import styles from './Boxed.module.scss'

export const Boxed: FunctionComponent<BoxedProps> = ({ className, children }) => {
  return <div className={classnames(styles.boxed, className)}>{children}</div>
}

type BoxedProps = {
  className?: string
  children: React.ReactNode
}
