export const holidayQueryData = {
  imgObj: {
    alt: 'Two women sitting on luggage',
    src: 'https://media.product.which.co.uk/prod/images/original/4c1aa7a3cdf8-flight-tool-a.jpg',
  },
  buttons: [
    {
      label: 'After 28th Feb 2011',
      id: 'timeshares-post',
    },
    {
      label: 'Before 28th Feb 2011',
      id: 'timeshares-pre',
    },
  ],
  title: 'When did you enter the timeshare agreement?',
}
