export const getScenarioAText = (
  hoursDifference: { value1: string; value2: string },
  distance: number
) => {
  const [hoursText] = hoursDifference.value1.split('-')
  if (distance < 1500) {
    if (
      hoursDifference.value1 === '3-hours-late-arriving' ||
      hoursDifference.value1 === '4-or-more-hours-late-arriving'
    ) {
      return {
        text: `Your flight was delayed by over ${hoursText} hours and was ${distance} kilometers long. You may be entitled to €250 (£220) compensation.`,
        amount: '€250 (£220)',
      }
    }
  } else if (distance >= 1500 && distance <= 3500) {
    if (
      hoursDifference.value1 === '3-hours-late-arriving' ||
      hoursDifference.value1 === '4-or-more-hours-late-arriving'
    ) {
      return {
        text: `Your flight was delayed by over ${hoursText} hours and was ${distance} kilometers long. You may be entitled to €400 (£350) compensation.`,
        amount: '€400 (£350)',
      }
    }
  } else if (distance > 3500) {
    if (hoursDifference.value1 === '3-hours-late-arriving') {
      return {
        text: `Your flight was delayed by over 3 hours and was ${distance} kilometers long. You may be entitled to €300 (£260) compensation.`,
        amount: '€300 (£260)',
      }
    } else if (hoursDifference.value1 === '4-or-more-hours-late-arriving') {
      return {
        text: `Your flight was delayed by over 4 hours and was ${distance} kilometers long. You may be entitled to €600 (£520) compensation.`,
        amount: '€600 (£520)',
      }
    }
  }
  return { text: '', amount: '' }
}

export const getScenarioBText = (distance: number) => {
  if (distance < 1500) {
    return {
      text: `Your flight was cancelled and your new flight departed more than 1 hour earlier than your original flight and was ${distance} kilometers long. You may be entitled to €125 (£110) compensation.`,
      amount: '€125 (£110)',
    }
  } else if (distance >= 1500 && distance <= 3500) {
    return {
      text: `Your flight was cancelled and your new flight departed more than 1 hour earlier than your original flight and was ${distance} kilometers long. You may be entitled to €200 (£175) compensation.`,
      amount: '€200 (£175)',
    }
  } else if (distance > 3500) {
    return {
      text: `Your flight was cancelled and your new flight departed more than 1 hour earlier than your original flight and was ${distance} kilometers long. You may be entitled to €300 (£260) compensation.`,
      amount: '€300 (£260)',
    }
  }
  return { text: '', amount: '' }
}

export const getScenarioCText = (
  hoursDifference: { value1: string; value2: string },
  distance: number
) => {
  const [hoursText] = hoursDifference.value1.split('-')
  if (distance < 1500) {
    return {
      text: `Your flight was delayed by over ${hoursText} hours and was ${distance} kilometers long. You may be entitled to €250 (£220) compensation.`,
      amount: '€250 (£220)',
    }
  } else if (distance >= 1500 && distance <= 3500) {
    if (
      hoursDifference.value1 === '2-hours-late-arriving' ||
      hoursDifference.value1 === '3-hours-late-arriving'
    ) {
      return {
        text: `Your flight was delayed by over ${hoursText} hours and was ${distance} kilometers long. You may be entitled to €200 (£175) compensation.`,
        amount: '€200 (£175)',
      }
    } else if (hoursDifference.value1 === '4-or-more-hours-late-arriving') {
      return {
        text: `Your flight was delayed by 4 or more hours and was ${distance} kilometers long. You may be entitled to €400 (£350) compensation.`,
        amount: '€400 (£350)',
      }
    }
  } else if (distance > 3500) {
    if (
      hoursDifference.value1 === '2-hours-late-arriving' ||
      hoursDifference.value1 === '3-hours-late-arriving'
    ) {
      return {
        text: `Your flight was delayed by over ${hoursText} hours and was ${distance} kilometers long. You may be entitled to €300 (£260) compensation.`,
        amount: '€300 (£260)',
      }
    } else if (hoursDifference.value1 === '4-or-more-hours-late-arriving') {
      return {
        text: `Your flight was delayed by 4 or more hours and was ${distance} kilometers long. You may be entitled to €600 (£520) compensation.`,
        amount: '€600 (£520)',
      }
    }
  }
  return { text: '', amount: '' }
}

export const getScenarioDText = (
  hoursDifference: { value1: string; value2: string },
  distance: number
) => {
  const [hoursText] = hoursDifference.value2.split('-')
  if (hoursDifference.value1 === 'less-than-1-hour-earlier-leaving') {
    if (distance < 1500) {
      if (
        hoursDifference.value2 === '2-hours-late-arriving' ||
        hoursDifference.value2 === '3-hours-late-arriving' ||
        hoursDifference.value2 === '4-or-more-hours-late-arriving'
      ) {
        return {
          text: `Your flight was delayed by over ${hoursText} hours and was ${distance} kilometers long. You may be entitled to €250 (£220) compensation.`,
          amount: '€250 (£220)',
        }
      }
    }
    if (distance >= 1500 && distance <= 3500) {
      if (
        hoursDifference.value2 === '2-hours-late-arriving' ||
        hoursDifference.value2 === '3-hours-late-arriving'
      ) {
        return {
          text: `Your flight was delayed by over ${hoursText} hours and was ${distance} kilometers long. You may be entitled to €200 (£175) compensation.`,
          amount: '€200 (£175)',
        }
      } else if (hoursDifference.value2 === '4-or-more-hours-late-arriving') {
        return {
          text: `Your flight was delayed by over ${hoursText} hours and was ${distance} kilometers long. You may be entitled to €400 (£350) compensation.`,
          amount: '€400 (£350)',
        }
      }
    }
    if (distance > 3500) {
      if (
        hoursDifference.value2 === '2-hours-late-arriving' ||
        hoursDifference.value2 === '3-hours-late-arriving'
      ) {
        return {
          text: `Your flight was delayed by over ${hoursText} hours and was ${distance} kilometers long. You may be entitled to €300 (£260) compensation.`,
          amount: '€300 (£260)',
        }
      } else if (hoursDifference.value2 === '4-or-more-hours-late-arriving') {
        return {
          text: `Your flight was delayed by over ${hoursText} hours and was ${distance} kilometers long. You may be entitled to €600 (£520) compensation.`,
          amount: '€600 (£520)',
        }
      }
    }
  }

  if (hoursDifference.value1 === 'more-than-1-hour-earlier-leaving') {
    if (distance < 1500) {
      if (
        hoursDifference.value2 === '2-hours-late-arriving' ||
        hoursDifference.value2 === '3-hours-late-arriving' ||
        hoursDifference.value2 === '4-or-more-hours-late-arriving'
      ) {
        return {
          text: `Your flight was delayed by over ${hoursText} hours and was ${distance} kilometers long. You may be entitled to €250 (£220) compensation.`,
          amount: '€250 (£220)',
        }
      } else if (hoursDifference.value2 === '1-hour-late-arriving') {
        return {
          text: `Your flight was cancelled and your new flight departed more than 1 hour earlier than your original flight and was ${distance} kilometers long. You may be entitled to €125 (£110) compensation.`,
          amount: '€125 (£110)',
        }
      }
    }
    if (distance >= 1500 && distance <= 3500) {
      if (
        hoursDifference.value2 === '2-hours-late-arriving' ||
        hoursDifference.value2 === '3-hours-late-arriving'
      ) {
        return {
          text: `Your flight was delayed by over ${hoursText} hours and was ${distance} kilometers long. You may be entitled to €250 (£220) compensation.`,
          amount: '€250 (£220)',
        }
      } else if (hoursDifference.value2 === '4-or-more-hours-late-arriving') {
        return {
          text: `Your flight was delayed by over ${hoursText} hours and was ${distance} kilometers long. You may be entitled to €400 (£350) compensation.`,
          amount: '€400 (£350)',
        }
      } else if (hoursDifference.value2 === '1-hour-late-arriving') {
        return {
          text: `Your flight was cancelled and your new flight departed more than 1 hour earlier than your original flight and was ${distance} kilometers long. You may be entitled to €200 (£175) compensation.`,
          amount: '€200 (£175)',
        }
      }
    }
    if (distance > 3500) {
      if (
        hoursDifference.value2 === '2-hours-late-arriving' ||
        hoursDifference.value2 === '3-hours-late-arriving'
      ) {
        return {
          text: `Your flight was delayed by over ${hoursText} hours and was ${distance} kilometers long. You may be entitled to €300 (£260) compensation.`,
          amount: '€300 (£260)',
        }
      } else if (hoursDifference.value2 === '1-hour-late-arriving') {
        return {
          text: `Your flight was cancelled and your new flight departed more than 1 hour earlier than your original flight and was ${distance} kilometers long. You may be entitled to €125 (£110) compensation.`,
          amount: '€125 (£110)',
        }
      } else if (hoursDifference.value2 === '4-or-more-hours-late-arriving') {
        return {
          text: `Your flight was delayed by over ${hoursText} hours and was ${distance} kilometers long. You may be entitled to €600 (£520) compensation.`,
          amount: '€600 (£520)',
        }
      }
    }
  }
  return { text: '', amount: '' }
}

export const getScenarioEText = (distance: number) => {
  return {
    text: `Your flight was cancelled and your new flight departed 2 or more hours earlier than your original flight and was ${distance} kilometers long. You may be entitled to €125 (£110) compensation.`,
    amount: '€125 (£110)',
  }
}

export const getScenarioFText = (distance: number) => {
  return {
    text: `Your flight was delayed by over 4 hours and was ${distance} kilometers long. You may be entitled to €250 (£220) compensation.`,
    amount: '€250 (£220)',
  }
}

export const getScenarioGText = (
  hoursDifference: { value1: string; value2: string },
  distance: number
) => {
  if (
    hoursDifference.value1 === 'less-than-2-hours-earlier-leaving' &&
    hoursDifference.value2 === '4-or-more-hours-late-arriving'
  ) {
    return {
      text: `Your flight was delayed by over 4 hours and was ${distance} kilometers long. You may be entitled to €600 (£520) compensation.`,
      amount: '€600 (£520)',
    }
  }
  if (distance < 1500) {
    if (hoursDifference.value1 === '2-or-more-hours-earlier-leaving') {
      if (
        hoursDifference.value2 === '2-hours-late-arriving' ||
        hoursDifference.value2 === '3-hours-late-arriving' ||
        hoursDifference.value2 === '4-or-more-hours-late-arriving'
      ) {
        return {
          text: `Your flight was cancelled and your new flight departed 2 or more hours earlier than your original flight and was ${distance} kilometers long. You may be entitled to €250 (£220) compensation.`,
          amount: '€250 (£220)',
        }
      } else if (hoursDifference.value2 === '1-hour-late-arriving') {
        return {
          text: `Your flight was cancelled and your new flight departed 2 or more hours earlier than your original flight and was ${distance} kilometers long. You may be entitled to €125 (£110) compensation.`,
          amount: '€125 (£110)',
        }
      }
    }
  }

  if (distance >= 1500 && distance <= 3500) {
    if (
      hoursDifference.value2 === '1-hour-late-arriving' ||
      hoursDifference.value2 === '2-hours-late-arriving' ||
      hoursDifference.value2 === '3-hours-late-arriving'
    ) {
      return {
        text: `Your flight was cancelled and your new flight departed 2 or more hours earlier than your original flight and was ${distance} kilometers long. You may be entitled to €200 (£175) compensation.`,
        amount: '€200 (£175)',
      }
    } else if (hoursDifference.value2 === '4-or-more-hours-late-arriving') {
      return {
        text: `Your flight was delayed by over 4 hours and was ${distance} kilometers long. You may be entitled to €400 (£350) compensation.`,
        amount: '€400 (£350)',
      }
    }
  }

  if (distance > 3500) {
    if (
      hoursDifference.value2 === '1-hour-late-arriving' ||
      hoursDifference.value2 === '2-hours-late-arriving' ||
      hoursDifference.value2 === '3-hours-late-arriving'
    ) {
      return {
        text: `Your flight was cancelled and your new flight departed 2 or more hours earlier than your original flight and was ${distance} kilometers long. You may be entitled to €300 (£260) compensation.`,
        amount: '€300 (£260)',
      }
    } else if (hoursDifference.value2 === '4-or-more-hours-late-arriving') {
      return {
        text: `Your flight was delayed by over 4 hours and was ${distance} kilometers long. You may be entitled to €600 (£520) compensation.`,
        amount: '€600 (£520)',
      }
    }
  }
  return { text: '', amount: '' }
}

export const getScenarioHText = (distance: number) => {
  return {
    text: `Your flight was cancelled and was ${distance} kilometers long. You may be entitled to €600 (£520) compensation.`,
    amount: '€600 (£520)',
  }
}

export const getEntitlementText = (
  claimScenario: string,
  hoursDifference: { value1: string; value2: string },
  distance: number
) => {
  switch (claimScenario) {
    case 'scenarioA':
      // Delayed flight - late arrival
      return getScenarioAText(hoursDifference, distance)
    case 'scenarioB':
      // Rerouted flight within 7 days, replacement flight departs more than 1 hour earlier than original flight
      return getScenarioBText(distance)
    case 'scenarioC':
      // Rerouted flight within 7 days, replacement flight arrives 2/3/4 or more hours later than original flight
      return getScenarioCText(hoursDifference, distance)
    case 'scenarioD':
      // Two times to consider
      // Rerouted flight within 7 days, replacement flight departs earlier & arrives later than original flight
      return getScenarioDText(hoursDifference, distance)
    case 'scenarioE':
      // Rerouted flight between 7-14 days, replacement flight departs 2 or more hours earlier than original flight
      return getScenarioEText(distance)
    case 'scenarioF':
      // Rerouted flight between 7-14 days, replacement flight arrives 4 or more hours earlier than original flight
      return getScenarioFText(distance)
    case 'scenarioG':
      // Two times to consider
      // Rerouted flight between 7-14 days, replacement flight departs earlier & arrives later than original flight
      return getScenarioGText(hoursDifference, distance)
    case 'scenarioH':
      // Flight cancelled & did not fly - either notified fewer than 7 days or notified between 7-14 days
      return getScenarioHText(distance)
    default:
      return { text: '', amount: '' }
  }
}

export const data = {
  mainTitle:
    'Based on the information provided, you may be eligible to make a claim for compensation',
  imgObj: {
    src: 'https://media.product.which.co.uk/prod/images/original/520b69cf306c-flight-delay-step-9.jpg',
    alt: 'Two people and a child carrying suitcases',
  },
  title: 'How many people are you claiming on behalf of (including children)?',
}
