import { limitedOptionsData } from './data'

export const chooseDataSet = (value: string) => {
  if (value === 'damagedGoods') {
    return { optionsData: limitedOptionsData.damagedData }
  }

  if (value === 'missingDelivery') {
    return { optionsData: limitedOptionsData.legalData }
  }

  return { optionsData: limitedOptionsData.damagedData }
}
