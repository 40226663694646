// A separate object for the infoBox data is used due to the typing of the borderColour attribute
import type { InfoBoxProps } from '../../../../../shared/types/toolTypes'

export const infoBox: InfoBoxProps = {
  heading: 'What should I put?',
  borderColour: 'blue',
}

export const data = {
  infoBox,
  infoBoxParagraphs: [
    {
      text: 'This is the disputed amount that you want to claim back from the seller; the amount of money you believe is owed to you. This might be different from the full amount you paid.',
    },
  ],
  imgObj: {
    alt: 'Person weighing up a piggy bank and a building ',
    src: 'https://media.product.which.co.uk/prod/images/original/gm-ef2e718c-f0bc-4ead-9d68-e14b4d165b08-claim-value.jpg',
  },
  title: 'How much are you claiming back?',
}
