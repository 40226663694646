import type { FunctionComponent } from 'react'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import { Grid, GridItem } from '@which/seatbelt'

import { ToolWrapper } from '../../shared'
import { Loader } from '../../shared/components/Loader'
import { PageFade } from '../../shared/components/PageFade'
import { ProgressBar } from '../../shared/components/ProgressBar'
import { ToolContext } from '../../shared/state/appContext'
import styles from '../../shared/styles/GlobalStyles.module.scss'
import type { BookAppointmentToolProps } from '../../shared/types/toolTypes'
import { checkSessionStorageExists } from '../../shared/utils/checkSessionStorageExists'
import { entitlementChecks } from '../../shared/utils/entitlementChecks/entitlementChecks'
import { formatToolName } from '../../shared/utils/formatToolName'
import { getFilteredPageDataByStep } from '../../shared/utils/getFilteredPageDataByStep'
import { getSessionStorageItem } from '../../shared/utils/getSessionStorageItem'
import { handleScroll } from '../../shared/utils/handleScroll'
import { removeSessionStorage } from '../../shared/utils/removeSessionStorage'
import { setUserJourney } from '../../shared/utils/setUserJourney'
import { storeToolState } from '../../shared/utils/storeToolState'
import {
  generalDataLayer,
  initaliseDataLayer,
  pageViewDataLayer,
} from '../../shared/utils/tracking'
import { usePrevious } from '../../shared/utils/usePrevious'
import { useRenderStep } from './hooks/useRenderStep'
import {
  AllFlows,
  AppointmentChoiceFlow,
  LimitedOptionsFlow,
  MoneyTopicFlow,
  SolicitorFlow,
  SubmitSuccessfulFlow,
  TechTopicFlow,
  UnderConstructionFlow,
} from './pageFlowData'

export const BookAppointment: FunctionComponent<BookAppointmentToolProps> = ({
  showFeedback,
  toolName,
  CRFooter,
}) => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: {
      entryToolName,
      initialised,
      journeyFlow: { milestoneHit },
      progressValue,
      step,
      userJourney,
    },
    triage,
  } = state

  const prevStep = usePrevious(step)
  const [isForward, setIsForward] = useState(true)
  const [isEntitled, setIsEntitled] = useState(false)
  const [checkedEntitlements, setCheckedEntitlements] = useState(false)

  if (!initialised) {
    removeSessionStorage({ sessionStorageName: `${toolName}Progress` })
  }

  const [readyToRender, setReadyToRender] = useState(false)
  let type: string | null | undefined = undefined
  const location = useLocation()
  const useQuery = () => {
    return new URLSearchParams(location?.search)
  }

  const query = useQuery()
  type = query?.get('type') || triage?.type
  useEffect(() => {
    if (type && !checkedEntitlements) {
      const fetchEntitlement = async () => {
        const entitlement = await entitlementChecks()
        dispatch({ type: 'UPDATE_ENTITLEMENT', data: entitlement })
        switch (type) {
          case 'money':
            setIsEntitled(entitlement.money)
            break
          case 'tech':
            setIsEntitled(entitlement.tech)
            break
          case 'legal':
            setIsEntitled(Object.values(entitlement.legal).some(Boolean))
            break
        }
        setCheckedEntitlements(true)
      }
      fetchEntitlement()
    }
  }, [checkedEntitlements, dispatch, type])

  useEffect(() => {
    if (!initialised) {
      dispatch({ type: 'UPDATE_SHOW_FEEDBACK', data: showFeedback })
    }
  }, [dispatch, initialised, showFeedback])

  const RenderStep = ({ renderStep }: { renderStep: number }) => {
    let stepToRender = !type ? 0 : renderStep
    useEffect(() => {
      /* istanbul ignore next */
      if ((type === null || !type) && step !== 0) {
        dispatch({ type: 'UPDATE_STEP', data: 0 })
        dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: 0 })
        // eslint-disable-next-line react-hooks/exhaustive-deps
        stepToRender = 0
      } else if (checkedEntitlements && !isEntitled) {
        stepToRender = LimitedOptionsFlow.STEP
      } else {
        if (userJourney.length === 2) {
          switch (type) {
            case 'legal':
              if (userJourney[userJourney.length - 1] !== SolicitorFlow.STEP) {
                dispatch({ type: 'UPDATE_STEP', data: SolicitorFlow.STEP })
              }
              break
            case 'money':
              if (userJourney[userJourney.length - 1] !== MoneyTopicFlow.STEP) {
                dispatch({ type: 'UPDATE_STEP', data: MoneyTopicFlow.STEP })
              }
              break
            case 'tech':
              if (userJourney[userJourney.length - 1] !== TechTopicFlow.STEP) {
                dispatch({ type: 'UPDATE_STEP', data: TechTopicFlow.STEP })
              }
              break
          }
        }
      }
    }, [dispatch, isEntitled, checkedEntitlements])

    useEffect(() => {
      if (type !== null) {
        switch (step) {
          case SolicitorFlow.STEP:
            dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: SolicitorFlow.PROGRESS_VALUE })
            break
          case MoneyTopicFlow.STEP:
            dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: MoneyTopicFlow.PROGRESS_VALUE })
            break
          case UnderConstructionFlow.STEP:
            dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: UnderConstructionFlow.PROGRESS_VALUE })
            break
          case TechTopicFlow.STEP:
            dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: TechTopicFlow.PROGRESS_VALUE })
            break
          default:
            break
        }
      }
    }, [])

    return useRenderStep(stepToRender)
  }

  useEffect(() => {
    handleScroll()
  }, [step])

  useEffect(() => {
    dispatch({ type: 'UPDATE_INITIALISED', data: true })
  }, [dispatch])

  useEffect(() => {
    if (!entryToolName) {
      dispatch({ type: 'UPDATE_ENTRY_TOOL_NAME', data: toolName })
    }
  }, [dispatch, entryToolName, toolName])

  useEffect(() => {
    const firePageView = () => {
      const getFlow = () => {
        switch (type) {
          case 'legal':
            return SolicitorFlow
          case 'money':
            return MoneyTopicFlow
          case 'tech':
            return TechTopicFlow
        }
      }
      const pageData = getFilteredPageDataByStep(step, AllFlows, [getFlow()])

      const { milestone, viewName } = pageData
      if (initialised === true) {
        if ((type || step === 0) && step !== prevStep) {
          pageViewDataLayer(viewName)
        }

        const milestoneArray = milestoneHit

        if (!milestoneArray.includes(viewName) && milestone !== '') {
          milestoneArray.push(viewName)
          generalDataLayer(formatToolName(toolName), milestone, true, type || '')
          dispatch({ type: 'UPDATE_MILESTONE', data: milestoneArray })
          return
        }
      }
    }

    firePageView()
  }, [dispatch, initialised, milestoneHit, prevStep, step, toolName, type])
  useEffect(() => {
    if (type) {
      dispatch({
        type: 'UPDATE_TRIAGE_TYPE',
        data: type,
      })
    }
  }, [dispatch, type])

  useEffect(() => {
    if (!((type === null || !type) && step !== 0)) {
      setUserJourney(userJourney, step, dispatch)
    }
  }, [userJourney, step, dispatch, type])

  useEffect(() => {
    if ((type && type !== '') || step === 0) {
      setReadyToRender(true)
      return
    } else {
      dispatch({ type: 'UPDATE_STEP', data: 0 })
      dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: 0 })
    }
    return
  }, [dispatch, step, type])

  useEffect(() => {
    if (entryToolName !== '') {
      if (checkSessionStorageExists(`${toolName}Progress`)) {
        let sessionProgress = getSessionStorageItem(`${toolName}Progress`)
        let sessionProgressLength = sessionProgress.length - 1
        if (sessionProgressLength > 2 && step === 1) {
          sessionProgress = [0]
          sessionProgressLength = sessionProgress.length - 1
          storeToolState(`${toolName}Progress`, sessionProgress)
        }
        if (sessionProgress[sessionProgressLength] !== progressValue) {
          sessionProgress.push(progressValue)
          storeToolState(`${toolName}Progress`, sessionProgress)
        }
      } else {
        storeToolState(`${toolName}Progress`, [progressValue])
      }
    }
  })

  return (
    <>
      <ToolWrapper>
        <Helmet>
          <meta content="noindex" name="robots" />
          <script>{initaliseDataLayer(formatToolName(toolName))}</script>

          <title>Book Appointment tool</title>
          <meta content="Online appointment booking" name="title" />
          <meta content="summary" name="twitter:card" />
          <meta content="Book your online appointment booking" name="description" />
          <meta content="website" property="og:type" />
          <meta content="https://www.which.co.uk/tool/Book-Appointment" property="og:url" />
          <meta content="Online appointment booking" property="og:title" />
          <meta content="Book your online appointment booking" property="og:description" />
          <meta content="https://www.which.co.uk/tool/Book-Appointment" property="twitter:url" />
          <meta content="Online appointment booking" property="twitter:title" />
          <meta content="Book your online appointment booking" property="twitter:description" />
        </Helmet>

        {readyToRender &&
          (!checkedEntitlements && type ? (
            <Loader />
          ) : (
            <>
              <ProgressBar
                allFlows={AllFlows}
                endFlowStep={AppointmentChoiceFlow.STEP}
                setIsForward={setIsForward}
                startFlowStep={1}
                startingSteps={[MoneyTopicFlow.STEP, SolicitorFlow.STEP, TechTopicFlow.STEP]}
                stateWidth={progressValue}
                toolName={toolName}
              />
              <PageFade
                isForward={isForward}
                setIsForward={setIsForward}
                stateProgress={progressValue}
                step={step}
                toolName={toolName}
              >
                <RenderStep
                  renderStep={checkedEntitlements && !isEntitled ? LimitedOptionsFlow.STEP : step}
                />
              </PageFade>
            </>
          ))}
      </ToolWrapper>
      {step === SubmitSuccessfulFlow.STEP && (
        <Grid>
          <GridItem>
            <section className={styles.footer}>{CRFooter}</section>
          </GridItem>
        </Grid>
      )}
    </>
  )
}
