import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { IssueDescriptionFlow, TicketDiscountFlow } from '../../pageFlowData'
import { data } from './data'
import styles from './TicketDiscount.module.scss'

export const TicketDiscount: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    ticket: { ticketDiscount },
  } = state

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_TICKET_DISCOUNT', data: value })
    dispatch({ type: 'UPDATE_STEP', data: IssueDescriptionFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: IssueDescriptionFlow.PROGRESS_VALUE })
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${TicketDiscountFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = (id: string) => {
    handleDispatch(id)
    handleTracking(id)
  }

  const { buttons, imgObj, title } = data

  return (
    <div data-testid="ticket-discount">
      <PillButtonPage
        buttons={buttons}
        defaultValue={ticketDiscount}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        infoBoxData={data}
        pillButtonGroupStyle={styles.ticketDiscountPagePillButtons}
        title={title}
      />
    </div>
  )
}
