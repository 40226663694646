import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import type { RefObject } from '../../../../shared/components/DatePicker/data/types'
import { constructDate } from '../../../../shared/components/DatePicker/formatDate/constructDate'
import { MultiDatePickerPage } from '../../../../shared/pages/MultiDatePickerPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getDatePickerValidationErrorMessage } from '../../../../shared/utils/getDatePickerValidationErrorMessage/getDatePickerValidationErrorMessage'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { HolidayDatesFlow, IssueDescriptionFlow } from '../../pageFlowData'
import { holidayDateData } from './data'

export const HolidayDates: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    holiday: {
      dates: { departureDate, returnDate },
    },
  } = state

  const HolidayDatesRef = useRef<{
    departureDate: RefObject
    returnDate: RefObject
  }>(null)

  const [datePickerDepartureError, setDatePickerDepartureError] = useState('')
  const [datePickerReturnError, setDatePickerReturnError] = useState('')
  const [returnMin, setReturnMin] = useState('')

  const handleDispatch = (departureDateValue: string, returnDateValue: string) => {
    dispatch({
      type: 'UPDATE_HOLIDAY_DATES',
      data: { departureDate: departureDateValue, returnDate: returnDateValue },
    })
    dispatch({ type: 'UPDATE_STEP', data: IssueDescriptionFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: IssueDescriptionFlow.PROGRESS_VALUE })
  }

  const handleTracking = (departValue, returnValue) => {
    const trackingAnswer = `question | ${HolidayDatesFlow.VIEW_NAME} | ${departValue} ${returnValue}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  /* istanbul ignore next */
  const handleInputChange = (value: string, id: string | undefined) => {
    setDatePickerReturnError('')
    if (id === 'departure-date-picker') {
      setReturnMin(value)
    }
  }

  const handleSubmit = () => {
    setDatePickerDepartureError('')
    setDatePickerReturnError('')
    /* istanbul ignore next */
    const departureDateInputValue: string = cleanText(
      HolidayDatesRef.current?.departureDate?.value || ''
    )
    /* istanbul ignore next */
    const returnDateInputValue: string = cleanText(HolidayDatesRef.current?.returnDate?.value || '')

    const departureErrorMessage =
      getDatePickerValidationErrorMessage(
        departureDateInputValue,
        true,
        'Departure date must be in the past'
      ) || ''
    const returnErrorMessage = getDatePickerValidationErrorMessage(returnDateInputValue) || ''

    /* istanbul ignore next */
    if (departureErrorMessage || returnErrorMessage) {
      setDatePickerReturnError(returnErrorMessage)
      setDatePickerDepartureError(departureErrorMessage)
      return
    }
    if (new Date(departureDateInputValue) > new Date(returnDateInputValue)) {
      setDatePickerReturnError("Can't be before departure date")
      return
    }
    handleTracking(departureDateInputValue, returnDateInputValue)
    handleDispatch(departureDateInputValue, returnDateInputValue)
  }

  const { image, title } = holidayDateData

  return (
    <MultiDatePickerPage
      buttonText="Next"
      datePickerLabel1="Departed"
      datePickerLabel2="Returned"
      departMax={constructDate(new Date(), false)}
      departureDate={departureDate}
      errorMessageDeparture={datePickerDepartureError}
      errorMessageReturn={datePickerReturnError}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      imgObj={image}
      ref={HolidayDatesRef}
      renderDepartureError={datePickerDepartureError !== ''}
      renderReturnError={datePickerReturnError !== ''}
      returnDate={returnDate}
      returnMin={returnMin || departureDate}
      testId="holiday-date-page"
      title={title}
    />
  )
}
