import React from 'react'
import { Button, List, ListItem, Typography } from '@which/seatbelt'

import { detect } from 'detect-browser'

import { instructions } from '../data/instructions'
import styles from './ResultsPageModal.module.scss'

export const ResultsPageModal = ({ printModalHandler }) => {
  const result = detect()

  const browserInstructions = instructions(result?.name)

  return (
    <div className={styles.modal} data-testid="results-page-modal">
      <section>
        <Typography tag="h2" textStyle="title-400">
          How to save this page as a PDF
        </Typography>
        <div className={styles.infoList}>
          <List appearance="number">
            {browserInstructions?.map((step: string) => (
              <ListItem key={`list-item-${step}`}>{step}</ListItem>
            ))}
          </List>
        </div>
        <Button data-testid="save-to-pdf-button" onClick={printModalHandler}>
          Save as PDF
        </Button>
      </section>
    </div>
  )
}
