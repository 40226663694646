const shared = {
  title: 'Please select which of the following best matches your query',
  imgObj: {
    alt: 'two people holding documents',
    src: 'https://media.product.which.co.uk/prod/images/original/8f95d1a7f0dc-cp-step9.jpg',
  },
}

const sharedButtons = [
  {
    label: 'Estate planning and writing a will',
    id: 'estate-planning',
  },
  {
    label: 'Powers of attorney',
    id: 'powers-of-attorney',
  },
]

const sharedButtonsLower = [
  {
    label: 'Challenging a will or intestacy',
    id: 'challenging-a-will',
    tooltip: 'The grounds on which a will or intestacy can be challenged',
  },
  {
    label: 'Advance Directive',
    id: 'advance-directive',
    tooltip:
      'Your set of instructions about which medical and healthcare treatments should be given or witheld, usually in the context of end-of-life care',
  },
  {
    label: 'Negligence',
    id: 'negligence',
  },
]

export const option1 = {
  buttons: [
    ...sharedButtons,
    {
      label: 'Estate administration, probate issues/dealing with a will or estate',
      id: 'estate-administration-probate',
    },
    ...sharedButtonsLower,
    {
      label: 'Other',
      id: 'other',
    },
  ],
  ...shared,
}

export const option2 = {
  buttons: [
    ...sharedButtons,
    {
      label: 'Estate administration, confirmation issues/dealing with a will or estate',
      id: 'estate-administration-confirmation',
    },
    ...sharedButtonsLower,
  ],
  ...shared,
}
