import { checkSessionStorageExists } from '../../../shared/utils/checkSessionStorageExists'

export const removeSessionStorage = ({ sessionStorageName }) => {
  if (typeof window !== 'undefined') {
    if (checkSessionStorageExists(sessionStorageName)) {
      window.sessionStorage.removeItem(sessionStorageName)
    }
  }

  return null
}
