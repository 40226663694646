import type { FunctionComponent } from 'react'
import React, { useContext, useState } from 'react'

import { RadioGroupPage } from '../../../../shared/pages/RadioGroupPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import {
  BeenAffectedFlow,
  NameOfScammerFlow,
  WhatAboutMessageFlow,
  WhatScamEntryFlow,
} from '../../pageFlowData'
import { messageData, websiteData } from './data'

export const WhatScamEntry: FunctionComponent = () => {
  const [isInputValid, setInputValid] = useState(true)
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    scam: { whatScamEntry, socialWhatHappened },
  } = state
  const [radioSelected, setRadioSelected] = useState(whatScamEntry)

  const handleChange = (id: string) => {
    setRadioSelected(id)
  }

  const handleStepChoice = () => {
    if (socialWhatHappened === 'website') {
      return { progress: NameOfScammerFlow.PROGRESS_VALUE, step: NameOfScammerFlow.STEP }
    }

    if (radioSelected === 'other') {
      return { progress: WhatAboutMessageFlow.PROGRESS_VALUE, step: WhatAboutMessageFlow.STEP }
    }

    return { progress: BeenAffectedFlow.PROGRESS_VALUE, step: BeenAffectedFlow.STEP }
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${WhatScamEntryFlow.VIEW_NAME} | ${radioSelected}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = () => {
    const { progress, step } = handleStepChoice()

    dispatch({ type: 'UPDATE_WHAT_SCAM_ENTRY', data: radioSelected })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleSubmit = () => {
    if (radioSelected) {
      setInputValid(true)
      handleTracking()
      handleDispatch()
    }
    setInputValid(false)
  }

  const { buttons, title, imgObj } = socialWhatHappened === 'website' ? websiteData : messageData

  return (
    <RadioGroupPage
      buttonText="Next"
      buttons={buttons}
      fullWidthContainer
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      imgObj={imgObj}
      isInputValid={isInputValid}
      stateValue={radioSelected}
      title={title}
    />
  )
}
