export const faultyGoodsToolState = {
  issue: {
    description: '', // Tell us more about the problem. This will be used in your letter
    desiredResolution: null, // What do you want the seller to do?
    shortDesc: [], // What is wrong with the item you bought?
  },
  product: {
    brand: null, // What brand is the item?
    description: '', // What is the item you bought?
    model: null, // What is the product name/model number?
    type: null, // Tool CTA Entry Product/Digital
    onFinance: null, // Did you buy the item on finance?
    price: null, // How much did you pay?
    purchaseMethod: null, // How did you make your purchase?
  },
  retailer: {
    attemptedResolveItem: null, // Has the seller already attempted to repair/replace the item?
    channel: null, // Where did you buy the item? Online | Instore | Private seller
    name: null, // Which retailer did you buy the item from?
    whenBought: null, // How long ago did you buy the item?
    orderNum: '', // What is your order number?
  },
}
