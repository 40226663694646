import type { PageFlowProps } from '../../../shared/types/toolTypes'

export const ProviderContactFlow: PageFlowProps = {
  PROGRESS_VALUE: 0,
  STEP: 1,
  VIEW_NAME: 'provider-contact',
  REDUCERS: ['UPDATE_PROVIDER_CONTACT'],
  MILESTONE: 'milestone | start',
}

export const WhichProviderFlow: PageFlowProps = {
  PROGRESS_VALUE: 20,
  STEP: 2,
  VIEW_NAME: 'which-provider',
  REDUCERS: ['UPDATE_WHICH_PROVIDER'],
  MILESTONE: '',
}

export const OtherProviderFlow: PageFlowProps = {
  PROGRESS_VALUE: 20,
  STEP: 3,
  VIEW_NAME: 'other-provider',
  REDUCERS: ['UPDATE_OTHER_PROVIDER'],
  MILESTONE: '',
}

export const PackageNameFlow: PageFlowProps = {
  PROGRESS_VALUE: 30,
  STEP: 4,
  VIEW_NAME: 'package-name',
  REDUCERS: ['UPDATE_PACKAGE_NAME'],
  MILESTONE: '',
}

export const WhenPurchasedFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 5,
  VIEW_NAME: 'when-purchased',
  REDUCERS: ['UPDATE_WHEN_PURCHASED'],
  MILESTONE: '',
}

export const GuaranteedSpeedFlow: PageFlowProps = {
  PROGRESS_VALUE: 60,
  STEP: 6,
  VIEW_NAME: 'guaranteed-speed',
  REDUCERS: ['UPDATE_GUARANTEED_SPEED'],
  MILESTONE: '',
}

export const AchievedSpeedFlow: PageFlowProps = {
  PROGRESS_VALUE: 70,
  STEP: 7,
  VIEW_NAME: 'achieved-speed',
  REDUCERS: ['UPDATE_ACHIEVED_SPEED'],
  MILESTONE: '',
}

export const SupportingEvidenceFlow: PageFlowProps = {
  PROGRESS_VALUE: 80,
  STEP: 8,
  VIEW_NAME: 'supporting-evidence',
  REDUCERS: ['UPDATE_SUPPORTING_EVIDENCE'],
  MILESTONE: '',
}

export const ContactDetailsFlow: PageFlowProps = {
  PROGRESS_VALUE: 90,
  STEP: 9,
  VIEW_NAME: 'contact-details',
  REDUCERS: ['UPDATE_CONTACT_DETAILS'],
  MILESTONE: '',
}

export const LimitedOptionsFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 10,
  VIEW_NAME: 'limited-options',
  REDUCERS: ['UPDATE_LIMITED_OPTIONS'],
  MILESTONE: '',
}

export const SubmitSuccessfulFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 11,
  VIEW_NAME: 'journey-complete',
  REDUCERS: ['UPDATE_EMAIL_SENT'],
  MILESTONE: 'milestone | end | letter sent',
}

export const ToolFeedbackFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 12,
  VIEW_NAME: 'feedback-form',
  REDUCERS: [''],
  MILESTONE: 'milestone | feedback start',
}

export const ToolFeedbackAudienceFlow: PageFlowProps = {
  PROGRESS_VALUE: 90,
  STEP: 13,
  VIEW_NAME: 'feedback-audience-form',
  REDUCERS: [''],
  MILESTONE: '',
}

export const ToolFeedbackThankYouFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 14,
  VIEW_NAME: 'feedback-form-thankyou',
  REDUCERS: [''],
  MILESTONE: '',
}

export const AllFlows = [
  ProviderContactFlow,
  WhichProviderFlow,
  OtherProviderFlow,
  PackageNameFlow,
  WhenPurchasedFlow,
  GuaranteedSpeedFlow,
  AchievedSpeedFlow,
  SupportingEvidenceFlow,
  ContactDetailsFlow,
  LimitedOptionsFlow,
  SubmitSuccessfulFlow,
  ToolFeedbackFlow,
  ToolFeedbackAudienceFlow,
  ToolFeedbackThankYouFlow,
]
