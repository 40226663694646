import type { FunctionComponent } from 'react'
import React from 'react'
import { Typography } from '@which/seatbelt'

import { ArticleCTA } from '../../components/ArticleCTA'
import type { ToolFeedbackThankYouModuleProps } from '../../types/toolTypes'
import styles from './ToolFeedbackThankYouModule.module.scss'

export const ToolFeedbackThankYouModule: FunctionComponent<ToolFeedbackThankYouModuleProps> = ({
  articleCTA,
  title,
}) => {
  const { headline, image, link } = articleCTA
  return (
    <div className={styles.toolFeedbackThankYouModule}>
      <Typography className={styles.toolFeedbackThankYouModuleTitle} tag="h1" textStyle="title-650">
        {title}
      </Typography>
      <ArticleCTA headline={headline} image={image} link={link} title={articleCTA.title} />
    </div>
  )
}
