export const whichDealerData = {
  dealerOfferedSolution: {
    title:
      "Based on the information you've provided you can ask the dealer for a partial refund on your car",
    standFirst: '',
    bodyCopy: [
      "You're entitled to a refund if you bought your car from a dealer less than 6 years ago (or 5 years in Scotland), and a repair or replacement is unsuccessful. This is because you're protected under the Consumer Rights Act 2015 (in force from 1st October 2015), or the Sale of Goods Act if you bought the car before October 1st 2015.",
      "The car dealer may reduce the amount that you are refunded if it's more than 30 days since you bought the car. This is to account for 'fair use' of the car.",
    ],
  },
  dealerDidNotOfferSolution: {
    title:
      "Based on the information you've provided you can ask the dealer to repair or replace your car",
    standFirst: '',
    bodyCopy: [
      "You're entitled to a repair or replacement, free of charge if you bought the car from a dealer less than 6 years ago (or 5 years in Scotland). This is because you're protected under the Consumer Rights Act 2015 (in force from 1st October 2015), or the Sale of Goods Act if you bought the car before October 1st 2015.",
      'In most cases this will be a repair, as providing a replacement car would be a disproportionate cost.',
    ],
  },
  lessThan30Days: {
    title:
      "Based on the information you've provided you can ask the dealer to repair or replace your car, or give you a full refund",
    standFirst: '',
    bodyCopy: [
      "You're entitled to reject the car under the Consumer Rights Act 2015 (in force from 1st October 2015), as long as you have evidence that shows the car has developed a fault within 30 days after you bought it.",
      "You can accept a repair or replacement, but you're also legally entitled to a full refund if that's what you'd prefer.",
    ],
  },
  imgObj: {
    alt: 'Garage mechanic & car salesman stood side by side',
    src: 'https://media.product.which.co.uk/prod/images/original/67ec85bbd21f-step6.jpg',
  },
  standFirst:
    "We'll use this information to draft your complaint letter and to collect data on dealerships that have treated customers unfairly.",
  title: 'Which dealer did you buy your car from?',
}
