import type { FunctionComponent, KeyboardEvent } from 'react'
import React from 'react'
import { Checkbox } from '@which/seatbelt'

import classNames from 'classnames'

import globalStyles from '../../styles/GlobalStyles.module.scss'
import type { CheckboxGroupProps } from '../../types/toolTypes'
import { handleKeyPress } from '../../utils/keyDownHandler/handleKeyPress'
import { ErrorMessage } from '../ErrorMessage'
import styles from './CheckboxGroup.module.scss'

export const CheckboxGroup: FunctionComponent<CheckboxGroupProps> = ({
  buttons,
  handleChange,
  isSubmitValid = true,
  stateValue = [''],
  handleSubmit,
}) => {
  return (
    <div
      className={classNames(styles.checkboxGroup, globalStyles.spacing40, {
        [styles.checkboxGroupContainerError]: !isSubmitValid,
      })}
    >
      <div className={styles.checkboxGroupContent}>
        <div className={styles.checkboxGroupContainer}>
          {buttons.map((button) => {
            const { label, id, isFocused = false, disabled = false } = button
            return (
              <Checkbox
                autoFocus={isFocused}
                checked={stateValue?.includes(id)}
                className={styles.checkboxGroupItem}
                data-testid="tool-checkboxes"
                disabled={disabled}
                id={id}
                key={id}
                label={label}
                name={id}
                onChangeCallback={handleChange}
                onKeyDown={(e: KeyboardEvent<Element>) =>
                  handleKeyPress(e, handleSubmit, stateValue)
                }
                value={id}
              />
            )
          })}
        </div>
        {!isSubmitValid && (
          <ErrorMessage
            errorText="Please select which options apply to continue"
            styleClasses={classNames(styles.checkboxGroupError)}
          />
        )}
      </div>
    </div>
  )
}
