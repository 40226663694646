const shared = {
  title: 'Please select which of the following best matches your query',
  imgObj: {
    alt: 'Two people standing up talking',
    src: 'https://media.product.which.co.uk/prod/images/original/gm-e1fc82ae-b07d-49de-92bf-ba96507ba5f9-two-people-talking.jpg',
  },
}

export const option1 = {
  buttons: [
    {
      label: 'Home',
      id: 'home',
    },
    {
      label: 'Motor',
      id: 'motor',
    },
    {
      label: 'Travel',
      id: 'travel',
    },
    {
      label: 'Wedding',
      id: 'wedding',
    },
    {
      label: 'Other e.g. pet, legal expenses',
      id: 'other',
    },
  ],
  ...shared,
}

export const option2 = {
  buttons: [
    {
      label: 'Delivery issues',
      id: 'delivery-issues',
    },
    {
      label: 'Faulty goods/products & returns',
      id: 'faulty-goods',
    },
    {
      label: 'Online shopping',
      id: 'online-shopping',
    },
    {
      label: 'Private sale',
      id: 'private-sale',
    },
    {
      label: 'Retailers going bust',
      id: 'retailers-going-bust',
    },
  ],
  ...shared,
}
