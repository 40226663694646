import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { TextAreaPage } from '../../../../shared/pages/TextAreaPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryTextAreaRef } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { validateCharCount } from '../../../../shared/utils/validateCharCount'
import { MoreAboutScamFlow, ScammerContactFlow } from '../../pageFlowData'
import { data } from './data'

export const MoreAboutScam: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    scam: { moreAboutScam },
  } = state

  const [moreAboutScamTextAreaValid, setMoreAboutScamTextAreaValid] = useState<boolean>(true)
  const MoreAboutScamTextAreaRef = useRef<FormEntryTextAreaRef>(null)

  const handleMoreAboutScamChange = () => {
    const value = getCleanValue(MoreAboutScamTextAreaRef.current?.formEntryTextarea?.value)
    dispatch({ type: 'UPDATE_MORE_ABOUT_SCAM', data: value })

    const isValid: boolean = validateCharCount(value.length, 2500)
    const fieldNotEmpty: boolean = isNotEmpty(value)
    if (fieldNotEmpty && isValid) {
      setMoreAboutScamTextAreaValid(true)
    }
  }

  const handleDispatch = () => {
    dispatch({ type: 'UPDATE_STEP', data: ScammerContactFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: ScammerContactFlow.PROGRESS_VALUE })
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${MoreAboutScamFlow.VIEW_NAME} | ${moreAboutScam}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleMoreAboutScamSubmit = () => {
    const value: string = getCleanValue(MoreAboutScamTextAreaRef.current?.formEntryTextarea?.value)
    const isValid: boolean = validateCharCount(value.length, 2500)
    const fieldNotEmpty: boolean = isNotEmpty(value)

    if (fieldNotEmpty && !isValid) {
      setMoreAboutScamTextAreaValid(false)
      return
    }

    setMoreAboutScamTextAreaValid(true)
    handleTracking()
    handleDispatch()
  }

  const { imgObj, placeholderText, title } = data

  return (
    <div data-testid="more-about-scam">
      <TextAreaPage
        buttonText="Next"
        defaultValue={moreAboutScam}
        handleChange={handleMoreAboutScamChange}
        handleSubmit={handleMoreAboutScamSubmit}
        imgObj={imgObj}
        isFocused
        isInputValid={moreAboutScamTextAreaValid}
        maxChars={2500}
        optional
        placeholderText={placeholderText}
        ref={MoreAboutScamTextAreaRef}
        title={title}
      />
    </div>
  )
}
