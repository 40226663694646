export const holidayBookingData = {
  buttons: [
    {
      label:
        'I used (or plan to use) a UK company, or a company based outside the UK, but paying with a UK issued credit card',
      id: 'uk-based-company',
    },
    {
      label: 'I used a company outside the UK and did NOT pay with a UK issued credit card',
      id: 'company-outside-uk-not-credit-card',
    },
  ],
  title:
    'We can only advise in relation to UK law, which includes the additional consumer rights you may have when booking or paying on a credit card issued in the UK.',
  imgObj: {
    alt: 'Two women sitting on luggage',
    src: 'https://media.product.which.co.uk/prod/images/original/4c1aa7a3cdf8-flight-tool-a.jpg',
  },
  standFirst: 'Please select which of the following applies:',
}
