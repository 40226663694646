import type { FunctionComponent } from 'react'
import React from 'react'
import { Typography } from '@which/seatbelt'

import classNames from 'classnames'

import globalStyles from '../../styles/GlobalStyles.module.scss'
import styles from './MultiCardSectionWrapper.module.scss'

export const MultiCardSectionWrapper: FunctionComponent<Props> = ({
  backgroundColour = '',
  standfirst,
  testId,
  title,
  children,
  className,
}) => {
  const isPurpleBackground = backgroundColour === '#dadbeb'
  const isGreyBackground = backgroundColour === '#efefef'

  return (
    <section
      className={classNames(styles.multiCardSection, className, {
        [globalStyles.purpleBackground]: isPurpleBackground,
        [globalStyles.greyBackground]: isGreyBackground,
      })}
      data-testid={testId}
    >
      {/* Title here */}
      <Typography
        className={styles.multiCardSectionHeader}
        dangerouslySetInnerHTML={{ __html: title }}
        tag="h1"
        textStyle="title-600"
      />
      {/* Standfirst here */}
      <Typography
        className={styles.multiCardSectionStandFirst}
        dangerouslySetInnerHTML={{ __html: standfirst }}
        tag="p"
        textStyle="body"
      ></Typography>
      {children}
    </section>
  )
}

type Props = {
  backgroundColour?: string
  standfirst: string
  testId: string
  title: string
  className?: string
}
