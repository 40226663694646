import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { FormEntryPage } from '../../../../shared/pages/FormEntryPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryInputRef, SingleInputState } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { CompanyNameFlow, VehicleRegFlow } from '../../pageFlowData'
import { data } from './data'

export const CompanyName: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    issue: { whoIssued, companyName },
  } = state

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })
  const CompanyNameInput = useRef<FormEntryInputRef>(null)

  const getValue = () => {
    return cleanText(CompanyNameInput?.current?.formEntryInput?.value || '')
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${CompanyNameFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_COMPANY_NAME', data: value })
    dispatch({ type: 'UPDATE_STEP', data: VehicleRegFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: VehicleRegFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    const value = getValue()
    const isValid: boolean = isNotEmpty(value)

    setInputData({
      renderError: !isValid,
      value: value,
    })

    if (isValid) {
      handleTracking(value)
      handleDispatch(value)
    }
  }

  const { renderError, value: errorValue } = inputData
  const { imgObj } = data
  const title = `What is the name of the ${
    whoIssued === 'council' ? 'local authority' : 'company'
  } that issued the ticket?`

  return (
    <FormEntryPage
      buttonText="Next"
      defaultValue={companyName}
      fieldData={{
        renderError: renderError,
        value: errorValue,
      }}
      handleSubmit={handleSubmit}
      id="company-name"
      imgObj={imgObj}
      isFocused
      placeholderText=""
      ref={CompanyNameInput}
      testId="company-name"
      title={title}
    />
  )
}
