import type { PageFlowProps } from '../../../shared/types/toolTypes'

export const ComeAcrossScamFlow: PageFlowProps = {
  PROGRESS_VALUE: 0,
  STEP: 1,
  VIEW_NAME: 'come-across-scam',
  REDUCERS: ['UPDATE_COME_ACROSS_SCAM'],
  MILESTONE: 'milestone | start',
}

export const MoreAboutScamFlow: PageFlowProps = {
  PROGRESS_VALUE: 10,
  STEP: 2,
  VIEW_NAME: 'more-about-scam',
  REDUCERS: ['UPDATE_MORE_ABOUT_SCAM'],
  MILESTONE: '',
}

export const ScammerContactFlow: PageFlowProps = {
  PROGRESS_VALUE: 15,
  STEP: 3,
  VIEW_NAME: 'scammer-contact',
  REDUCERS: ['UPDATE_SCAMMER_CONTACT'],
  MILESTONE: '',
}

export const MoreAboutScammerFlow: PageFlowProps = {
  PROGRESS_VALUE: 15,
  STEP: 4,
  VIEW_NAME: 'more-about-scammer',
  REDUCERS: ['UPDATE_MORE_ABOUT_SCAMMER'],
  MILESTONE: '',
}

export const WhatHappenedFlow: PageFlowProps = {
  PROGRESS_VALUE: 20,
  STEP: 5,
  VIEW_NAME: 'what-happened',
  REDUCERS: ['UPDATE_WHAT_HAPPENED'],
  MILESTONE: '',
}

export const WhoWasScammerFlow: PageFlowProps = {
  PROGRESS_VALUE: 25,
  STEP: 6,
  VIEW_NAME: 'who-was-scammer',
  REDUCERS: ['UPDATE_WHO_WAS_SCAMMER'],
  MILESTONE: '',
}

export const TypeOfScammerFlow: PageFlowProps = {
  PROGRESS_VALUE: 25,
  STEP: 7,
  VIEW_NAME: 'type-of-scammer',
  REDUCERS: ['UPDATE_TYPE_OF_SCAMMER'],
  MILESTONE: '',
}

export const NameOfScammerFlow: PageFlowProps = {
  PROGRESS_VALUE: 30,
  STEP: 8,
  VIEW_NAME: 'name-of-scammer',
  REDUCERS: ['UPDATE_NAME_OF_SCAMMER'],
  MILESTONE: '',
}

export const WhatHappenedWithSocialFlow: PageFlowProps = {
  PROGRESS_VALUE: 25,
  STEP: 9,
  VIEW_NAME: 'what-happened-with-social',
  REDUCERS: ['UPDATE_SOCIAL_WHAT_HAPPENED'],
  MILESTONE: '',
}

export const WhatScamEntryFlow: PageFlowProps = {
  PROGRESS_VALUE: 25,
  STEP: 10,
  VIEW_NAME: 'what-scam-entry',
  REDUCERS: ['UPDATE_WHAT_SCAM_ENTRY'],
  MILESTONE: '',
}

export const WhatAboutMessageFlow: PageFlowProps = {
  PROGRESS_VALUE: 25,
  STEP: 11,
  VIEW_NAME: 'what-about-message',
  REDUCERS: ['UPDATE_WHAT_ABOUT_MESSAGE'],
  MILESTONE: '',
}

export const HaveContactFlow: PageFlowProps = {
  PROGRESS_VALUE: 35,
  STEP: 12,
  VIEW_NAME: 'have-contact',
  REDUCERS: ['UPDATE_HAVE_CONTACT'],
  MILESTONE: '',
}

export const ScammerReferenceFlow: PageFlowProps = {
  PROGRESS_VALUE: 35,
  STEP: 13,
  VIEW_NAME: 'scammer-reference',
  REDUCERS: ['UPDATE_SCAMMER_REFERENCE'],
  MILESTONE: '',
}

export const BeenAffectedFlow: PageFlowProps = {
  PROGRESS_VALUE: 40,
  STEP: 14,
  VIEW_NAME: 'been-affected',
  REDUCERS: ['UPDATE_BEEN_AFFECTED'],
  MILESTONE: '',
}

export const LostMoneyFlow: PageFlowProps = {
  PROGRESS_VALUE: 45,
  STEP: 15,
  VIEW_NAME: 'lost-money',
  REDUCERS: ['UPDATE_LOST_MONEY'],
  MILESTONE: '',
}

export const HowMuchMoneyFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 16,
  VIEW_NAME: 'how-much-money',
  REDUCERS: ['UPDATE_HOW_MUCH_MONEY'],
  MILESTONE: '',
}

export const HowDidYouPayFlow: PageFlowProps = {
  PROGRESS_VALUE: 55,
  STEP: 17,
  VIEW_NAME: 'how-did-you-pay',
  REDUCERS: ['UPDATE_HOW_DID_YOU_PAY'],
  MILESTONE: '',
}

export const WhatBankFlow: PageFlowProps = {
  PROGRESS_VALUE: 60,
  STEP: 18,
  VIEW_NAME: 'what-bank',
  REDUCERS: ['UPDATE_WHAT_BANK'],
  MILESTONE: '',
}

export const ReportedToFlow: PageFlowProps = {
  PROGRESS_VALUE: 65,
  STEP: 19,
  VIEW_NAME: 'reported-to',
  REDUCERS: ['UPDATE_REPORTED_TO'],
  MILESTONE: '',
}

export const WhoReportedToFlow: PageFlowProps = {
  PROGRESS_VALUE: 65,
  STEP: 20,
  VIEW_NAME: 'who-reported-to',
  REDUCERS: ['UPDATE_WHO_REPORTED_TO'],
  MILESTONE: '',
}

export const OutcomeFlow: PageFlowProps = {
  PROGRESS_VALUE: 70,
  STEP: 21,
  VIEW_NAME: 'outcome',
  REDUCERS: ['UPDATE_OUTCOME'],
  MILESTONE: '',
}

export const ReportedToOmbudsmanFlow: PageFlowProps = {
  PROGRESS_VALUE: 75,
  STEP: 22,
  VIEW_NAME: 'reported-to-ombudsman',
  REDUCERS: ['UPDATE_REPORTED_TO_OMBUDSMAN'],
  MILESTONE: '',
}

export const AgeFlow: PageFlowProps = {
  PROGRESS_VALUE: 80,
  STEP: 23,
  VIEW_NAME: 'age',
  REDUCERS: ['UPDATE_AGE'],
  MILESTONE: '',
}

export const PostcodeFlow: PageFlowProps = {
  PROGRESS_VALUE: 80,
  STEP: 24,
  VIEW_NAME: 'postcode',
  REDUCERS: ['UPDATE_POSTCODE'],
  MILESTONE: '',
}

export const GenderFlow: PageFlowProps = {
  PROGRESS_VALUE: 85,
  STEP: 25,
  VIEW_NAME: 'gender',
  REDUCERS: ['UPDATE_GENDER'],
  MILESTONE: '',
}

export const GetInTouchFlow: PageFlowProps = {
  PROGRESS_VALUE: 90,
  STEP: 26,
  VIEW_NAME: 'get-in-touch',
  REDUCERS: ['UPDATE_GET_IN_TOUCH'],
  MILESTONE: '',
}

export const ContactDetailsFlow: PageFlowProps = {
  PROGRESS_VALUE: 95,
  STEP: 27,
  VIEW_NAME: 'contact-details',
  REDUCERS: [''],
  MILESTONE: 'milestone | personal details',
}

export const SubmitSuccessfulFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 28,
  VIEW_NAME: 'journey-complete',
  REDUCERS: ['UPDATE_EMAIL_SENT'],
  MILESTONE: 'milestone | end | letter sent',
}

export const ToolFeedbackFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 29,
  VIEW_NAME: 'feedback-form',
  REDUCERS: [''],
  MILESTONE: 'milestone | feedback start',
}

export const ToolFeedbackAudienceFlow: PageFlowProps = {
  PROGRESS_VALUE: 90,
  STEP: 30,
  VIEW_NAME: 'feedback-audience-form',
  REDUCERS: [''],
  MILESTONE: '',
}

export const ToolFeedbackThankYouFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 31,
  VIEW_NAME: 'feedback-form-thankyou',
  REDUCERS: [''],
  MILESTONE: '',
}

export const AllFlows = [
  ComeAcrossScamFlow,
  MoreAboutScamFlow,
  ScammerContactFlow,
  MoreAboutScammerFlow,
  WhatHappenedFlow,
  WhoWasScammerFlow,
  TypeOfScammerFlow,
  NameOfScammerFlow,
  WhatHappenedWithSocialFlow,
  WhatScamEntryFlow,
  WhatAboutMessageFlow,
  HaveContactFlow,
  ScammerReferenceFlow,
  BeenAffectedFlow,
  LostMoneyFlow,
  HowMuchMoneyFlow,
  HowDidYouPayFlow,
  WhatBankFlow,
  ReportedToFlow,
  OutcomeFlow,
  ReportedToOmbudsmanFlow,
  AgeFlow,
  PostcodeFlow,
  GenderFlow,
  GetInTouchFlow,
  ContactDetailsFlow,
  SubmitSuccessfulFlow,
  ToolFeedbackFlow,
  ToolFeedbackAudienceFlow,
  ToolFeedbackThankYouFlow,
  WhoReportedToFlow,
]
