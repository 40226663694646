import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { HolidayProviderFlow, HolidayTypeFlow } from '../../pageFlowData'
import { holidayTypeData } from './data'
import styles from './HolidayType.module.scss'

export const HolidayType: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    holiday: { type },
  } = state

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${HolidayTypeFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_HOLIDAY_TYPE', data: value })
    dispatch({ type: 'UPDATE_STEP', data: HolidayProviderFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: HolidayProviderFlow.PROGRESS_VALUE })
  }

  const handleSubmit = (value: string) => {
    handleTracking(value)
    handleDispatch(value)
  }

  const {
    holidayTypeButtons,
    image: { imgSrc, imgAlt },
    title,
  } = holidayTypeData

  return (
    <PillButtonPage
      buttons={holidayTypeButtons}
      defaultValue={type}
      handleSubmit={handleSubmit}
      imgObj={{
        src: imgSrc,
        alt: imgAlt,
      }}
      infoBoxData={holidayTypeData}
      pillButtonGroupStyle={styles.holidayTypePagePillButtons}
      title={title}
    />
  )
}
