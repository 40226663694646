import type { FunctionComponent } from 'react'
import React from 'react'

import classnames from 'classnames'

import { stripHTML } from '../../utils/stripHTML/stripHTML'
import styles from './PreviewLetter.module.scss'

export const PreviewLetter: FunctionComponent<Props> = ({ data }) => {
  const { body, address } = data

  const bodyItems = body.map((bodyData, index) => {
    if (bodyData.text) {
      return (
        <p
          className={classnames(styles.previewLetterItem, bodyData.className)}
          key={`body${index}`}
        >
          {stripHTML(bodyData.text)}
        </p>
      )
    }
  })

  const addressItems = address?.map((addressData, index) => (
    <p
      className={classnames(styles.previewLetterItem, styles.previewLetterItemAddress)}
      key={`address${index}`}
    >
      {stripHTML(addressData.text)}
    </p>
  ))

  return (
    <>
      <div className={styles.previewLetterContainer} data-testid="preview-letter">
        {addressItems}
        {bodyItems}
      </div>
    </>
  )
}

type Props = {
  data: {
    label: string
    body: { text: string; className?: string; addressText?: boolean }[]
    address?: { text: string }[]
  }
}
