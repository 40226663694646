export const data = {
  buttons: [
    {
      label: 'Fewer than 7 days',
      id: 'fewer-than-7-days',
    },
    {
      label: 'Between 7 and 14 days',
      id: 'between-7-and-14-days',
    },
    {
      label: 'More than 14 days',
      id: 'more-than-14-days',
    },
  ],
  imgObj: {
    alt: 'A couple standing up holding each other looking at a clock',
    src: 'https://media.product.which.co.uk/prod/images/original/da12a7f44d4f-flight-delay-step-5.jpg',
  },
  title: 'How long before your flight was due to depart were you told it was cancelled?',
}
