import type { Reducer } from 'react'

import { initialSliceReducer, toolFeedbackSliceReducer } from '../../../shared/state/reducers'
import type { Action, State } from '../../../shared/state/toolStateTypes'
import { combineReducers } from '../../../shared/utils/combineReducers'

export const holidaySliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_HOLIDAY_COMPLETE':
      return {
        ...state,
        complete: data,
      }
    case 'UPDATE_HOLIDAY_TYPE':
      return {
        ...state,
        type: data,
      }
    case 'UPDATE_HOLIDAY_PROVIDER':
      return {
        ...state,
        provider: data,
      }
    case 'UPDATE_HOLIDAY_DESTINATION':
      return {
        ...state,
        destination: data,
      }
    case 'UPDATE_HOLIDAY_DATES':
      return {
        ...state,
        dates: data,
      }
    case 'UPDATE_BOOKING_REFERENCE':
      return {
        ...state,
        bookingReference: data,
      }
    default:
      return state
  }
}

export const expensesSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_HAS_EXPENSES':
      return {
        ...state,
        hasExpenses: data,
      }
    case 'UPDATE_EXPENSES_VALUE':
      return {
        ...state,
        value: data,
      }
    case 'UPDATE_EXPENSES_DETAILS':
      return {
        ...state,
        description: data,
      }
    default:
      return state
  }
}

export const issueSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_COMPANY_CONTACT':
      return {
        ...state,
        companyContact: data,
      }
    case 'UPDATE_ISSUE_DESCRIPTION':
      return {
        ...state,
        description: data,
      }
    default:
      return state
  }
}

export const holidayComplaintReducer = combineReducers({
  initial: initialSliceReducer,
  holiday: holidaySliceReducer,
  expenses: expensesSliceReducer,
  issue: issueSliceReducer,
  toolFeedback: toolFeedbackSliceReducer,
})
