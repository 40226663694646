export const generateRandomEmailAddress = (domainAddress = '@noaddress.ea') => {
  const theDate = new Date()
  const milliseconds = theDate.getTime()
  const emailAddress = createId() + milliseconds + domainAddress

  return emailAddress
}

const createId = () => {
  let text = ''
  const charsPossible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < 5; i++) {
    text += charsPossible.charAt(Math.floor(Math.random() * charsPossible.length))
  }
  return text
}
