import type { Reducer } from 'react'

import type { Action, State } from '../toolStateTypes'

export const toolFeedbackSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_SHOW_FEEDBACK':
      return {
        ...state,
        showFeedback: data,
      }
    case 'UPDATE_TOOL_FEEDBACK_SURVEY':
      return {
        ...state,
        toolFeedback: data,
      }
    case 'UPDATE_TOOL_FEEDBACK_AUDIENCE_SURVEY':
      return {
        ...state,
        toolFeedbackAudience: data,
      }
    case 'UPDATE_TOOL_FEEDBACK_SURVEY_COMPLETE':
      return {
        ...state,
        toolFeedbackSurveyComplete: data,
      }
    case 'UPDATE_TOOL_FEEDBACK_AUDIENCE_SURVEY_COMPLETE':
      return {
        ...state,
        toolFeedbackAudienceComplete: data,
      }
    default:
      return state
  }
}
