import type { FunctionComponent } from 'react'
import React from 'react'

export const CopiedIcon: FunctionComponent = () => {
  return (
    <svg
      data-testid="copied-svg"
      height="12px"
      key="copied-svg"
      version="1.1"
      viewBox="0 0 17 12"
      width="17px"
      xmlns="https://www.w3.org/2000/svg"
    >
      <g
        fill="none"
        fillRule="evenodd"
        id="New_components"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <g
          id="--Clipboard"
          stroke="#1866C8"
          strokeWidth="2"
          transform="translate(-50.000000, -150.000000)"
        >
          <g id="CTA-Copy" transform="translate(30.000000, 137.000000)">
            <polyline id="®®" points="21 18.616 26.117 23.767 35.977 14"></polyline>
          </g>
        </g>
      </g>
    </svg>
  )
}
