import type { UserPropsWithAddress } from './../../../shared/types/toolTypes'

export const pageData = (state: {
  emailData: { body: { text: string; className?: string }[] }
  account: {
    monthlyFee: string
    accountOpen: string
    nameOfBank: string
    nameOfAccount: string
    dateOpened: string
  }
  claim: {
    howAccountSold: string
    ledToPackagedBankAccount: string[]
    whyMisSold: string
    whatLikeToDo: string
    claimCompensation: string
  }
  optIns: {
    singleConsentOptIn: boolean
  }
  user: UserPropsWithAddress
}) => {
  const { monthlyFee, nameOfBank, accountOpen, dateOpened, nameOfAccount } = state.account
  const { howAccountSold, ledToPackagedBankAccount, whyMisSold, whatLikeToDo, claimCompensation } =
    state.claim
  const { address, emailAddress, firstName, lastName } = state.user
  const { singleConsentOptIn } = state.optIns

  const { body } = state.emailData

  let emailBodyBuilder = ''
  body.forEach((bodyData) => {
    if (bodyData.text) {
      emailBodyBuilder = emailBodyBuilder + `${bodyData.text}<br />`
    }
  })

  const questions: any = {}
  // Questions for Mis sold packaged bank account
  questions['question.1330832'] = monthlyFee // TF - Mis-sold Bank Account - Have you ever paid a monthly fee for your bank account?
  questions['question.1330833'] = ledToPackagedBankAccount // TF - Mis-sold Bank Account - What led you to having a packaged bank account?
  questions['question.1330834'] = whyMisSold // TF - Mis-sold Bank Account - why you think you were mis-sold the account
  questions['question.1330835'] = howAccountSold // TF - Mis-sold Bank Account - How was the account sold to you?
  questions['question.1331016'] = accountOpen // TF - Mis-sold Bank Account - Is the account in question still open?
  questions['question.1331018'] = whatLikeToDo // TF - Mis-sold Bank Account - What would you like to do?
  questions['question.1331019'] = claimCompensation // TF - Mis-sold Bank Account - Do you want to claim compensation for being mis-sold the account?
  questions['question.1331020'] = nameOfBank // TF - Mis-sold Bank Account - name of the bank or building society the account
  questions['question.1331021'] = nameOfAccount // TF - Mis-sold Bank Account - What's the name of the account?
  questions['question.1331022'] = dateOpened // TF - Mis-sold Bank Account - On what date did you open the account?
  questions['question.1331044'] = emailBodyBuilder // TF - Mis-sold Bank Account - Completed Email

  questions['question.1598463'] = singleConsentOptIn ? 'Y' : 'N' // TF - Single consent opt-in
  questions['question.1184531'] = 'Y' // TF - Impact survey opt-in

  // return supporter
  return {
    supporter: {
      Address: address?.addressLine1,
      'Address 2': address?.addressLine2,
      County: address?.county,
      Email: emailAddress,
      'First name': firstName,
      'Last name': lastName,
      Postcode: address?.postcode,
      'Town or City': address?.townCity,
      questions,
    },
  }
}
