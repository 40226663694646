import type { FunctionComponent } from 'react'
import React, { useContext, useEffect } from 'react'

import { ToolFeedbackThankYouModule } from '../../pages/ToolFeedbackThankYouModule'
import { ToolContext } from '../../state/appContext'
import { formatToolName } from '../../utils/formatToolName'
import { articleCTAData } from './data'

export const ToolFeedbackThankYouPage: FunctionComponent<Props> = ({
  toolFeedbackThankYouFlowData,
}) => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    toolFeedback: { toolFeedbackSurveyComplete },
  } = state

  useEffect(() => {
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: toolFeedbackThankYouFlowData.PROGRESS_VALUE })
  }, [dispatch, toolFeedbackThankYouFlowData.PROGRESS_VALUE])
  const title = toolFeedbackSurveyComplete
    ? 'Thank you for sharing those details with us'
    : `We hope you found the ${formatToolName(entryToolName)} tool useful`
  return <ToolFeedbackThankYouModule articleCTA={articleCTAData} title={title} />
}

///////// IMPLEMENTATION /////////

type Props = {
  toolFeedbackThankYouFlowData: {
    PROGRESS_VALUE: number
  }
}
