export const impactSurveyToolState = {
  answers: {
    regardingProblem: [], // Have you made a claim/appeal/complaint regarding your problem?
    wasSuccessful: [], // Was your claim successful?
    whatWasOutcome: [], // What was the outcome of your claim?
    letterSuccess: [], // To what extent, if at all, do you believe using our letter contributed to the success of
    // your claim?
    reasonUnsuccessful: [], //What reason, if any, were you given for your claim being unsuccessful?
    whatNow: [], // What will you do now?
    ifNoWhichTool: [], // If the Which? (insert name) tool had not been available, do you think you would still
    // have made a claim?
    toolEasier: '', // Do you feel that using the Which? (insert name) tool made it easier for you to make a claim
    // than it might have been otherwise?
    savedTime: '', // Do you feel that using the Which? (insert name) tool saved you any time in making your claim?
    howMuchTimeSaved: [], // Could you estimate roughly how much time we might have saved you?
    reducedStress: [], // Do you feel that using the Which? (insert name) tool
    //reduced any worry or stress you may have been experiencing in making a claim?
    moreAboutExperience: [], // More about the experience
    reasonNot: [], // Please tell us the main reason why you have not made a claim
    reasonNotOther: '',
  },
  user: {
    followUp: [], // User follow up opt-in
    userEmail: '', // users email address that initiated the tool
    toolID: '', // FaultyGoods, Section75, HolidayComplaint, DeliveryComplaint, ParkingTicket,
    // FlightDelayCancellationCompensation, MisSoldPackagedBankAccount
  },
}
