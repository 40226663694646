import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../../shared/state/appContext'
import globalStyles from '../../../../../shared/styles/GlobalStyles.module.scss'
import { trackAppointmentBookingSelection } from '../../../../../shared/utils/tracking/tracking'
import { DescribeCaseFlow, HomeDetailFlow, LimitedOptionsFlow } from '../../../pageFlowData'
import { option1, option2 } from './data'
import styles from './HomeDetail.module.scss'

export const HomeDetail: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    home: { homeTopic },
    triage: { type },
  } = state

  const handleTracking = (id: string, index: number) => {
    trackAppointmentBookingSelection(type, HomeDetailFlow.VIEW_NAME, { id, index })
  }

  const chooseStep = (id: string) => {
    if (
      id === 'commercial-lease-query' ||
      id === 'freeholder-query-management-company' ||
      id === 'leaseholder-vs-leaseholder' ||
      id === 'tribunal-court-action' ||
      id === 'commercial-lease-query' ||
      id === 'neighbour-planning-permission-scotland' ||
      id === 'own-planning-permission'
    ) {
      return LimitedOptionsFlow
    }
    return DescribeCaseFlow
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_HOME_DETAIL', data: id })
    const step = chooseStep(id)
    dispatch({ type: 'UPDATE_STEP', data: step.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: step.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string, index: number) => {
    handleTracking(id, index)
    handleDispatch(id)
  }
  const chooseData = () => {
    switch (homeTopic) {
      case 'neighbour-issue':
        return option2
      default:
        return option1
    }
  }

  const data = chooseData()
  const { buttons, imgObj, title } = data

  return (
    <div className={globalStyles.fullWidthButton}>
      <PillButtonPage
        buttons={buttons}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        // @ts-expect-error
        infoBoxData={data?.infoBox && data}
        pillButtonGroupStyle={styles.homeDetailPillButtons}
        title={title}
      />
    </div>
  )
}
