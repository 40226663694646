import type { FunctionComponent } from 'react'
import React, { useContext, useState } from 'react'

import { RadioGroupPage } from '../../../../shared/pages/RadioGroupPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { JobTypeFlow, WhatWorkFlow, WorkBeganFlow } from '../../pageFlowData'
import { jobTypeData } from './data'

export const JobType: FunctionComponent = () => {
  const [isInputValid, setInputValid] = useState(true)
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    work: { job },
  } = state
  const [radioSelected, setRadioSelected] = useState(job)

  const handleChange = (id: string) => {
    setRadioSelected(id)
  }

  const handleStepChoice = () => {
    if (radioSelected === 'specialist') {
      return { progress: WhatWorkFlow.PROGRESS_VALUE, step: WhatWorkFlow.STEP }
    }
    return { progress: WorkBeganFlow.PROGRESS_VALUE, step: WorkBeganFlow.STEP }
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${JobTypeFlow.VIEW_NAME} | ${radioSelected}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = () => {
    const { progress, step } = handleStepChoice()

    dispatch({ type: 'UPDATE_JOB', data: radioSelected })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleSubmit = () => {
    if (radioSelected) {
      setInputValid(true)
      handleTracking()
      handleDispatch()
    }
    setInputValid(false)
  }

  const { buttons, title, imgObj } = jobTypeData

  return (
    <RadioGroupPage
      buttonText="Next"
      buttons={buttons}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      imgObj={imgObj}
      isInputValid={isInputValid}
      stateValue={radioSelected}
      title={title}
    />
  )
}
