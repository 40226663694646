export const holidayComplaintToolState = {
  holiday: {
    complete: null, //Have you already been on holiday
    type: null, //Did you book a package holiday?
    provider: null, //What's the name of the holiday company you booked with?
    destination: '', //What was the destination of your holiday?
    dates: {
      departureDate: '', //What were the departure and return dates of your booking?
      returnDate: '', //What were the departure and return dates of your booking?
    },
    bookingReference: null, //What is your booking reference?
  },
  expenses: {
    hasExpenses: null, //Are you claiming for out-of-pocket expenses?
    value: null, // What's the total value of out-of-pocket expenses you're claiming for?
    description: '', //Describe the out-of-pocket expenses you are claiming for
  },
  issue: {
    description: '', // Tell us more about the problem. This will be used in your letter
    companyContact: '', // Did you contact the holiday provider?, Did you complain about the holiday already?
  },
}
