import type { InfoBoxProps } from '../../../../../../shared/types/toolTypes'

const shared = {
  imgObj: {
    alt: 'two people holding documents',
    src: 'https://media.product.which.co.uk/prod/images/original/8f95d1a7f0dc-cp-step9.jpg',
  },
  buttons: [
    {
      label: 'England',
      id: 'england',
    },
    {
      label: 'Wales',
      id: 'wales',
    },
    {
      label: 'Scotland',
      id: 'scotland',
    },
  ],
  infoBox: {
    heading: 'What does this mean?',
    borderColour: 'blue',
  } as InfoBoxProps,
  infoBoxParagraphs: [
    {
      text: 'The law is sometimes different in the different countries of the UK. We might not always be able to help with all Scottish or Northern Irish issues',
    },
  ],
}

export const option1 = {
  ...shared,
  buttons: [
    ...shared.buttons,
    {
      label: 'Northern Ireland',
      id: 'ireland',
    },
  ],
  title: 'Where is the leasehold property which your query relates to:',
}

export const option2 = {
  ...shared,
  buttons: [
    ...shared.buttons,
    {
      label: 'Northern Ireland or overseas',
      id: 'ireland-or-overseas',
    },
  ],
  title:
    'The law is sometimes varies in the different countries of the UK. We need to know which set of laws is relevant to your situation - this is usually the law of the country where the property is located',
}
