import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { constructDate } from '../../../../shared/components/DatePicker/formatDate/constructDate'
import { DatePickerPage } from '../../../../shared/pages/DatePickerPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { DatePickerInputRef } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getDatePickerValidationErrorMessage } from '../../../../shared/utils/getDatePickerValidationErrorMessage/getDatePickerValidationErrorMessage'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { ContactDetailsFlow, DateOpenedAccountFlow } from '../../pageFlowData'
import { data } from './data'

export const DateOpenedAccount: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    account: { dateOpened },
  } = state

  const DateOpenedAccountInput = useRef<DatePickerInputRef>(null)

  const [datePickerError, setDatePickerError] = useState('')

  /* istanbul ignore next */
  const getValue = () => {
    return cleanText(DateOpenedAccountInput?.current?.datePickerInput?.value || dateOpened)
  }

  const handleDispatch = (value: string) => {
    handleInputChange(value)
    dispatch({ type: 'UPDATE_STEP', data: ContactDetailsFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: ContactDetailsFlow.PROGRESS_VALUE })
  }

  /* istanbul ignore next */
  const handleInputChange = (value: string) => {
    /* istanbul ignore next */
    dispatch({ type: 'UPDATE_DATE_OPENED_ACCOUNT', data: value })
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${DateOpenedAccountFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = () => {
    setDatePickerError('')
    const dateInputValue: string = getValue()
    const errorMessage = getDatePickerValidationErrorMessage(dateInputValue, true)
    /* istanbul ignore next */
    if (errorMessage) {
      return setDatePickerError(errorMessage)
    }
    handleTracking(dateInputValue)
    handleDispatch(dateInputValue)
  }

  const { imgObj, noteTop, title } = data

  return (
    <DatePickerPage
      buttonText="Next"
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      imgObj={imgObj}
      isFocused
      max={constructDate(new Date(), false)}
      newErrorMessage={datePickerError}
      noteTop={noteTop}
      purchasedDate={dateOpened}
      ref={DateOpenedAccountInput}
      renderError={datePickerError !== ''}
      testId="date-opened-account-page"
      title={title}
    />
  )
}
