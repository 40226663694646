import type { FunctionComponent } from 'react'
import React from 'react'

import { TFToolEntryPoint } from '../../../../shared/components/TFToolEntryPoint'
import { ArticleCTAPage } from '../../../../shared/pages/ArticleCTAPage'
import { limitedOptionsData } from './data'

export const LimitedOptions: FunctionComponent = () => {
  const { bodyCopy, standFirst, title } = limitedOptionsData

  return (
    <div data-testid="limited-options">
      <ArticleCTAPage bodyCopy={bodyCopy} standFirst={standFirst} title={title} />
      <TFToolEntryPoint
        button_text="Start my complaint"
        datalayer
        question_text="What did you buy?"
        standfirst="<p>Draft a free complaint letter to send to the retailer. It only takes a few minutes. You're entitled to a refund, replacement or repair if goods you bought were faulty or not as described.</p>"
        title="Complain about faulty goods"
        tool_name="FaultyGoodsTool"
        tool_url="/tool/faulty-goods"
      />
    </div>
  )
}
