export const whatHappenedData = {
  title: 'Please describe what happened',
  imgObj: {
    src: 'https://media.product.which.co.uk/prod/images/original/gm-722c5bd7-f893-44c0-a7fd-c2fa58b4052e-what-happened.jpg',
    alt: 'Three people talking around a desk',
  },
  descriptiveText: [
    'What you write here will appear in the final letter of claim, so please be as clear as possible, including dates.',
    'Please tell us how the retailer/provider of the service did not fulfil what they promised you (and therefore breached their contract with you):',
  ],
  placeholderText:
    "Eg. 'The television the retailer delivered to me was not the one I purchased on their website'",
}
