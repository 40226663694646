import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { FormEntryPage } from '../../../../shared/pages/FormEntryPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryInputRef, SingleInputState } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { RenderInfoBox } from '../../../../shared/utils/renderInfoBox'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { HolidayDestinationFlow, HolidayProviderFlow } from '../../pageFlowData'
import { holidayProviderNotPackageData, holidayProviderPackageData } from './data'
import styles from './HolidayProvider.module.scss'

export const HolidayProvider: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    holiday: { provider, type },
  } = state

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })
  const holidayProviderInput = useRef<FormEntryInputRef>(null)

  const isPackageHoliday = type === 'package' ? true : false

  const getValue = () => {
    return cleanText(holidayProviderInput?.current?.formEntryInput?.value || '')
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${HolidayProviderFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_STEP', data: HolidayDestinationFlow.STEP })
    dispatch({ type: 'UPDATE_HOLIDAY_PROVIDER', data: value })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: HolidayDestinationFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    const value = getValue()
    const holidayProviderHasError = !isNotEmpty(value)

    setInputData({
      renderError: holidayProviderHasError,
      value: value,
    })

    if (!holidayProviderHasError) {
      handleTracking(value)
      handleDispatch(value)
    }
  }

  const { renderError, value: errorValue } = inputData

  return (
    <>
      <FormEntryPage
        buttonText="Next"
        defaultValue={provider}
        fieldData={{
          renderError: renderError,
          value: errorValue,
        }}
        handleSubmit={handleSubmit}
        id="holiday-provider"
        imgObj={{
          alt: 'A couple talking to someone over a counter',
          src: 'https://media.product.which.co.uk/prod/images/original/gm-f92f73c4-3bcf-4b0b-a49a-eb86c670d1f4-holiday-provider.jpg',
        }}
        isFocused
        placeholderText=""
        ref={holidayProviderInput}
        testId="holiday-provider"
        title={
          isPackageHoliday ? holidayProviderPackageData.title : holidayProviderNotPackageData.title
        }
      />
      <div className={styles.spacing50Top}>
        <RenderInfoBox
          data={
            isPackageHoliday
              ? holidayProviderPackageData.infoBoxData
              : holidayProviderNotPackageData.infoBoxData
          }
        />
      </div>
    </>
  )
}
