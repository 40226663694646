import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import globalStyles from '../../../../shared/styles/GlobalStyles.module.scss'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { WhatHappenedWithSocialFlow, WhatScamEntryFlow } from '../../pageFlowData'
import { whatHappenedWithSocialData } from './data'

export const WhatHappenedWithSocial: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    scam: { socialWhatHappened },
  } = state

  const handleTracking = (id: string) => {
    const trackingAnswer = `question | ${WhatHappenedWithSocialFlow.VIEW_NAME} | ${id}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_SOCIAL_WHAT_HAPPENED', data: id })
    dispatch({ type: 'UPDATE_STEP', data: WhatScamEntryFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: WhatScamEntryFlow.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string) => {
    handleTracking(id)
    handleDispatch(id)
  }

  const { buttons, title } = whatHappenedWithSocialData

  return (
    <div className={globalStyles.fullWidthButton}>
      <PillButtonPage
        buttons={buttons}
        defaultValue={socialWhatHappened}
        fitContent
        handleSubmit={handleSubmit}
        imgObj={{
          alt: 'A man looking surprised at his phone.',
          src: 'https://media.product.which.co.uk/prod/images/original/6677b47f495f-what-happened.jpg',
        }}
        title={title}
      />
    </div>
  )
}
