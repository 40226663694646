export const getFilteredPageDataByStep = (step, allFlows, defaultPageData) => {
  let filteredPageData = allFlows.filter((page) => {
    return page.STEP === step
  })

  if (filteredPageData.length === 0) {
    filteredPageData = defaultPageData
  }

  const [values] = filteredPageData
  const { MILESTONE, MILESTONE_S75_ENTRY, VIEW_NAME, PROGRESS_VALUE } = values

  return {
    milestone: MILESTONE,
    milestoneS75: MILESTONE_S75_ENTRY,
    progress: PROGRESS_VALUE,
    viewName: VIEW_NAME,
  }
}
