export const correctCarData = {
  buttons: [
    {
      label: 'Yes',
      id: 'yes',
    },
    {
      label: 'No, search again',
      id: 'no',
    },
  ],
  title: 'Is this your car?',
  imgObj: {
    alt: 'A blue car',
    src: 'https://media.product.which.co.uk/prod/images/original/95a1aad7e16f-carproblemstep2.jpg',
  },
}
