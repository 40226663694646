import type { FunctionComponent } from 'react'
import React from 'react'
import {
  ContractsIcon,
  HomeHeatingIcon,
  ShoppingBasketIcon,
  SubscriptionsIcon,
  Typography,
} from '@which/seatbelt'

import styles from './GenericCard.module.scss'

export const GenericCard: FunctionComponent<Props> = ({ body, icon, title }) => {
  if (!body && !icon && !title) {
    return null
  }

  const renderIcon = () => {
    if (!icon || icon === '') {
      return null
    }

    const GenericCardIcon = iconMapper[icon]

    return (
      <GenericCardIcon
        className={styles.GenericCardIcon}
        data-testid={`generic-card-icon-${icon}`}
        height="50"
        width="50"
      />
    )
  }

  return (
    <div className={styles.GenericCard} data-testid="generic-card">
      {icon !== undefined && renderIcon()}
      {title && (
        <Typography
          className="sb-padding-bottom-3"
          data-testid="generic-card-title"
          tag="h4"
          textStyle="title-400"
        >
          {title}
        </Typography>
      )}
      {body && (
        <div
          className={styles.multiCardSectionBody}
          dangerouslySetInnerHTML={{ __html: body }}
          data-testid="generic-card-body"
        />
      )}
    </div>
  )
}

const iconMapper = {
  homeHeating: HomeHeatingIcon,
  shoppingBasket: ShoppingBasketIcon,
  contracts: ContractsIcon,
  subscriptions: SubscriptionsIcon,
}

type Props = {
  body?: string
  icon?: string
  title?: string
}
