export const EN_API_GATEWAY = (): string => {
  if (typeof window !== 'undefined' && window.location?.hostname === 'localhost') {
    return 'http://localhost:3080'
  }

  return process.env.EN_API_GATEWAY || ''
}

export const getENDomain = (): string => {
  const enGateway = EN_API_GATEWAY()

  if (
    enGateway.includes('localhost') &&
    process?.env?.NODE_ENV &&
    process.env.NODE_ENV === 'production'
  ) {
    return 'http://localhost:3090'
  }

  return 'https://www.e-activist.com'
}
