import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { LimitedOptionsFlow, WhenPurchasedFlow, WhereBoughtFlow } from '../../pageFlowData'
import { whereBoughtData } from './data'

export const WhereBought: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    car: { whereBought },
  } = state

  const handleStepChoice = (id: string) => {
    if (id === 'private') {
      return { progress: LimitedOptionsFlow.PROGRESS_VALUE, step: LimitedOptionsFlow.STEP }
    }

    return { progress: WhenPurchasedFlow.PROGRESS_VALUE, step: WhenPurchasedFlow.STEP }
  }

  const handleTracking = (id: string) => {
    const trackingAnswer = `question | ${WhereBoughtFlow.VIEW_NAME} | ${id}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (id: string) => {
    const { progress, step } = handleStepChoice(id)

    dispatch({ type: 'UPDATE_WHERE_BOUGHT', data: id })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleSubmit = (id: string) => {
    handleTracking(id)
    handleDispatch(id)
  }

  const { buttons, imgObj, title } = whereBoughtData

  return (
    <PillButtonPage
      buttons={buttons}
      defaultValue={whereBought ? whereBought : ''}
      handleSubmit={handleSubmit}
      imgObj={imgObj}
      title={title}
    />
  )
}
