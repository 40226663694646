export const carsTopicData = {
  buttons: [
    {
      label: 'Vehicle purchases, servicing or hire',
      id: 'vehicle',
    },
    {
      label: 'A DVLA query',
      id: 'dvla',
    },
    {
      label: 'Motoring offences',
      id: 'motoring',
    },
    {
      label: 'Parking & Penalty Charge Notices',
      id: 'parking',
    },
    {
      label: 'A road traffic accident',
      id: 'road-traffic',
    },
    {
      label: 'Pothole damage',
      id: 'pothole',
    },
    {
      label: 'Motor Insurance',
      id: 'motor-insurance',
    },
    {
      label: 'Other',
      id: 'other',
    },
  ],
  title: 'Please select which of the following closest matches your query',
  imgObj: {
    alt: 'Person driving a car',
    src: 'https://media.product.which.co.uk/prod/images/original/ccee6d01d803-carproblemstep1.jpg',
  },
}
