import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { ArticleCTAPage } from '../../../../shared/pages/ArticleCTAPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking/tracking'
import { ComplaintTypeFlow, JobTypeFlow } from '../../pageFlowData'
import { whichTraderData } from './data'

export const WhichTrader: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)

  const {
    initial: { entryToolName },
  } = state

  const { bodyCopy, ctaData, standFirst, title, link, buttonText } = whichTraderData
  const { href, headline, text } = link

  const handleLinkTracking = () => {
    generalDataLayer(formatToolName(entryToolName), `onward journey | ${headline} | ${href}`)
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${ComplaintTypeFlow.VIEW_NAME}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = () => {
    dispatch({ type: 'UPDATE_STEP', data: JobTypeFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: JobTypeFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    handleTracking()
    handleDispatch()
  }

  return (
    <div data-testid="which-trader">
      <ArticleCTAPage
        articleHeading={headline}
        articleLink={link}
        articleTitle={text}
        bodyCopy={bodyCopy}
        buttonText={buttonText}
        ctaData={ctaData}
        handleButtonSubmit={handleSubmit}
        handleSubmit={handleLinkTracking}
        standFirst={standFirst}
        title={title}
      />
    </div>
  )
}
