import type { FunctionComponent } from 'react'
import React from 'react'
import { useLocation } from 'react-router-dom'

import {
  BookAppointment,
  bookAppointmentReducer,
  bookAppointmentToolState,
  BroadbandSpeed,
  broadbandSpeedReducer,
  broadbandSpeedToolState,
  CarProblem,
  carProblemReducer,
  carProblemToolState,
  DeliveryComplaint,
  deliveryComplaintReducer,
  deliveryComplaintToolState,
  EnergyCostCalculator,
  energyCostCalculatorReducer,
  energyCostCalculatorToolState,
  FaultyGoods as FaultyGoodsTool,
  faultyGoodsReducer as faultyGoodsToolReducer,
  faultyGoodsToolState as faultyGoodsState,
  FlightDelayCancellation,
  flightDelayCancellationReducer,
  flightDelayCancellationState,
  HolidayComplaint,
  holidayComplaintReducer,
  holidayComplaintToolState,
  HomeImprovement,
  homeImprovementReducer,
  homeImprovementToolState,
  ImpactSurvey,
  impactSurveyReducer,
  impactSurveyToolState,
  MisSoldPackagedBankAccount,
  misSoldPackagedBankAccountReducer,
  misSoldPackagedBankAccountToolState,
  MyMoneyHealthCheck,
  myMoneyHealthCheckReducer,
  myMoneyHealthCheckToolState,
  ParkingTicket,
  parkingTicketReducer,
  parkingTicketToolState,
  PriceRiseCalculator,
  priceRiseCalculatorReducer,
  priceRiseCalculatorToolState,
  S75AndChargeback,
  s75Reducer,
  s75ToolState,
  ScamSharer,
  scamSharerReducer,
  scamSharerToolState,
} from '../../../tools'
import { FollowUpAppointment } from '../../../tools/BookAppointment/PageComponents/FollowUpAppointment'
import { RebookAppointment } from '../../../tools/BookAppointment/PageComponents/RebookAppointment'
import { defaultToolReducer, initialToolState } from '../../state'
import { ToolProvider } from '../../state/appContext'
import { getState } from '../getState'

export const RenderTool: FunctionComponent<ToolProps> = ({
  toolName,
  optionsJson,
  CRFooter,
  showFeedback,
  title,
  questionText,
  standfirst,
  buttonText,
  toolUrl,
  moneyHealthResults,
}) => {
  const location = useLocation()
  const renderTool = () => {
    switch (toolName) {
      case 'FaultyGoodsTool':
        return (
          <FaultyGoodsTool
            CRFooter={CRFooter}
            buttonText={buttonText}
            optionsJson={optionsJson}
            questionText={questionText}
            showFeedback={showFeedback}
            standfirst={standfirst}
            title={title}
            toolName={toolName}
            toolUrl={toolUrl}
          />
        )
      case 'Section75':
        return (
          <S75AndChargeback
            CRFooter={CRFooter}
            buttonText={buttonText}
            optionsJson={optionsJson}
            questionText={questionText}
            showFeedback={showFeedback}
            standfirst={standfirst}
            title={title}
            toolName={toolName}
            toolUrl={toolUrl}
          />
        )
      case 'HolidayComplaint':
        return (
          <HolidayComplaint CRFooter={CRFooter} showFeedback={showFeedback} toolName={toolName} />
        )
      case 'ParkingTicket':
        return <ParkingTicket CRFooter={CRFooter} showFeedback={showFeedback} toolName={toolName} />
      case 'DeliveryComplaint':
        return (
          <DeliveryComplaint CRFooter={CRFooter} showFeedback={showFeedback} toolName={toolName} />
        )
      case 'FlightDelayCancellationCompensation':
        return (
          <FlightDelayCancellation
            CRFooter={CRFooter}
            showFeedback={showFeedback}
            toolName={toolName}
          />
        )
      case 'MisSoldPackagedBankAccount':
        return (
          <MisSoldPackagedBankAccount
            CRFooter={CRFooter}
            showFeedback={showFeedback}
            toolName={toolName}
          />
        )
      case 'ImpactSurvey':
        return <ImpactSurvey CRFooter={CRFooter} toolName={toolName} toolUrl={toolUrl} />
      case 'BookAppointment':
        const Tool = location?.pathname?.endsWith('/tool/change-appointment')
          ? RebookAppointment
          : location?.pathname?.endsWith('/tool/book-follow-up')
            ? FollowUpAppointment
            : BookAppointment
        return <Tool CRFooter={CRFooter} showFeedback={showFeedback} toolName={toolName} />
      case 'HomeImprovement':
        return (
          <HomeImprovement CRFooter={CRFooter} showFeedback={showFeedback} toolName={toolName} />
        )
      case 'MyMoneyHealthCheck':
        return (
          <MyMoneyHealthCheck
            CRFooter={CRFooter}
            buttonText={buttonText}
            moneyHealthResults={moneyHealthResults}
            optionsJson={optionsJson}
            questionText={questionText}
            showFeedback={showFeedback}
            standfirst={standfirst}
            title={title}
            toolName={toolName}
            toolUrl={toolUrl}
          />
        )
      case 'BroadbandSpeed':
        return (
          <BroadbandSpeed CRFooter={CRFooter} showFeedback={showFeedback} toolName={toolName} />
        )
      case 'CarProblem':
        return <CarProblem CRFooter={CRFooter} showFeedback={showFeedback} toolName={toolName} />
      case 'ScamSharer':
        return <ScamSharer CRFooter={CRFooter} showFeedback={showFeedback} toolName={toolName} />
      case 'EnergyCostCalculator':
        return <EnergyCostCalculator optionsJson={optionsJson} toolName={toolName} />
      case 'PriceRiseCalculator':
        return <PriceRiseCalculator optionsJson={optionsJson} toolName={toolName} />
      default:
        return null
    }
  }

  const toolRootReducer = () => {
    switch (toolName) {
      case 'FaultyGoodsTool':
        return faultyGoodsToolReducer
      case 'Section75':
        return s75Reducer
      case 'HolidayComplaint':
        return holidayComplaintReducer
      case 'ParkingTicket':
        return parkingTicketReducer
      case 'DeliveryComplaint':
        return deliveryComplaintReducer
      case 'FlightDelayCancellationCompensation':
        return flightDelayCancellationReducer
      case 'MisSoldPackagedBankAccount':
        return misSoldPackagedBankAccountReducer
      case 'ImpactSurvey':
        return impactSurveyReducer
      case 'BookAppointment':
        return bookAppointmentReducer
      case 'HomeImprovement':
        return homeImprovementReducer
      case 'MyMoneyHealthCheck':
        return myMoneyHealthCheckReducer
      case 'BroadbandSpeed':
        return broadbandSpeedReducer
      case 'CarProblem':
        return carProblemReducer
      case 'ScamSharer':
        return scamSharerReducer
      case 'EnergyCostCalculator':
        return energyCostCalculatorReducer
      case 'PriceRiseCalculator':
        return priceRiseCalculatorReducer
      default:
        return defaultToolReducer
    }
  }

  const toolState = () => {
    switch (toolName) {
      case 'FaultyGoodsTool':
        return faultyGoodsState
      case 'Section75':
        return s75ToolState
      case 'HolidayComplaint':
        return holidayComplaintToolState
      case 'ParkingTicket':
        return parkingTicketToolState
      case 'DeliveryComplaint':
        return deliveryComplaintToolState
      case 'FlightDelayCancellationCompensation':
        return flightDelayCancellationState
      case 'MisSoldPackagedBankAccount':
        return misSoldPackagedBankAccountToolState
      case 'ImpactSurvey':
        return impactSurveyToolState
      case 'BookAppointment':
        return bookAppointmentToolState
      case 'HomeImprovement':
        return homeImprovementToolState
      case 'MyMoneyHealthCheck':
        return getState({
          localStorageName: 'MMHC',
          sessionStorageName: `${toolName}ToolState`,
          defaultState: { ...initialToolState, ...myMoneyHealthCheckToolState },
        })
      case 'BroadbandSpeed':
        return broadbandSpeedToolState
      case 'CarProblem':
        return carProblemToolState
      case 'ScamSharer':
        return scamSharerToolState
      case 'EnergyCostCalculator':
        return energyCostCalculatorToolState
      case 'PriceRiseCalculator':
        return priceRiseCalculatorToolState
      default:
        return initialToolState
    }
  }

  return (
    <ToolProvider toolRootReducer={toolRootReducer()} toolState={toolState()}>
      {renderTool()}
    </ToolProvider>
  )
}

type ToolProps = {
  toolName: string
  optionsJson: string
  CRFooter: React.ReactNode
  showFeedback: boolean
  title: string
  questionText: string
  standfirst: string
  buttonText: string
  toolUrl: string
  moneyHealthResults: {
    contentItems: []
    header: {
      title: string
      amberRouteIntro: string
      greenRouteIntro: string
      redRouteIntro: string
    }
  }
}
