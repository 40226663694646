import type { FunctionComponent } from 'react'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'

import { ContactDetailsPage } from '../../../../shared/pages/ContactDetailsPage/ContactDetails'
import { ToolContext } from '../../../../shared/state/appContext'
import type { ContactRefProps, MultiInputsState } from '../../../../shared/types/toolTypes'
import { pageSubmission } from '../../../../shared/utils/EngagingNetwork/pageSubmission'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { scrollToInvalidInput } from '../../../../shared/utils/scrollToInvalidInput'
import { validateEmail } from '../../../../shared/utils/validateEmail'
import { validatePostcode } from '../../../../shared/utils/validatePostcode'
import { pageData } from '../../pageData'
import { SubmitSuccessfulFlow } from '../../pageFlowData'
import { composeEmailBody } from '../SubmitSuccessful/composeEmailBody'
import { contactDetailsData } from './data'

export const ContactDetails: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: {
      journeyFlow,
      optIns: { singleConsentOptIn, caseStudy },
      user,
    },
    issue,
    product,
    retailer,
  } = state

  const [inputsData, setInputsData] = useState<MultiInputsState>({
    fNameData: { renderError: false, value: '' },
    lNameData: { renderError: false, value: '' },
    emailData: { renderError: false, value: '' },
    postcodeData: { renderError: false, value: '' },
    addressLine1Data: { renderError: false, value: '' },
    addressLine2Data: { renderError: false, value: '' },
    townCityData: { renderError: false, value: '' },
    countyData: { renderError: false, value: '' },
  })
  const [errorCount, setErrorCount] = useState(0)
  const [disableBtn, setDisableBtn] = useState(false)

  const ContactFormRefs = useRef<ContactRefProps>(null)
  const currentInputsList: HTMLInputElement[] = []

  for (const input in ContactFormRefs.current) {
    currentInputsList.push(ContactFormRefs.current[input])
  }

  const handleDispatch = useCallback(
    (emailSent: boolean) => {
      dispatch({ type: 'UPDATE_EMAIL_SENT', data: emailSent })
      dispatch({ type: 'UPDATE_STEP', data: SubmitSuccessfulFlow.STEP })
      dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: SubmitSuccessfulFlow.PROGRESS_VALUE })
    },
    [dispatch]
  )

  useEffect(() => {
    if (errorCount > 1) {
      // If they've gotten two or more errors, go to NextSteps anyway
      handleDispatch(false)
    }
  }, [errorCount, handleDispatch])

  const handleSubmit = async () => {
    const firstNameValue: string = ContactFormRefs.current?.firstName.value || ''
    const firstNameHasError = !isNotEmpty(firstNameValue) || !/^[a-z '-]+$/i.test(firstNameValue)

    const lastNameValue: string = ContactFormRefs.current?.lastName.value || ''
    const lastNameHasError = !isNotEmpty(lastNameValue) || !/^[a-z '-]+$/i.test(lastNameValue)

    const emailValue: string = ContactFormRefs.current?.emailAddress.value || ''
    const emailHasError = !isNotEmpty(emailValue) || !validateEmail(emailValue)

    const postcodeValue: string = ContactFormRefs.current?.postcode?.value || ''
    const postcodeHasError = !isNotEmpty(postcodeValue) || !validatePostcode(postcodeValue)

    const addressLine1Value: string = ContactFormRefs.current?.addressLine1?.value || ''
    const addressLine1HasError = !isNotEmpty(addressLine1Value)

    const addressLine2Value: string = ContactFormRefs.current?.addressLine2?.value || ''
    const addressLine2HasError = !isNotEmpty(addressLine2Value)

    const townCityValue: string = ContactFormRefs.current?.townCity?.value || ''
    const townCityHasError = !isNotEmpty(townCityValue)

    const countyValue: string = ContactFormRefs.current?.county?.value || ''
    const countyHasError = !isNotEmpty(countyValue)

    setDisableBtn(true)

    setInputsData({
      fNameData: { renderError: firstNameHasError, value: firstNameValue },
      lNameData: { renderError: lastNameHasError, value: lastNameValue },
      emailData: { renderError: emailHasError, value: emailValue },
      postcodeData: { renderError: postcodeHasError, value: postcodeValue },
      addressLine1Data: { renderError: addressLine1HasError, value: addressLine1Value },
      addressLine2Data: { renderError: addressLine2HasError, value: addressLine2Value },
      townCityData: { renderError: townCityHasError, value: townCityValue },
      countyData: { renderError: countyHasError, value: countyValue },
    })

    if (
      !firstNameHasError &&
      !lastNameHasError &&
      !emailHasError &&
      !postcodeHasError &&
      !addressLine1HasError &&
      !townCityHasError
    ) {
      const userData = {
        firstName: firstNameValue,
        lastName: lastNameValue,
        emailAddress: emailValue,
        address: {
          postcode: postcodeValue,
          addressLine1: addressLine1Value,
          addressLine2: addressLine2Value,
          townCity: townCityValue,
          county: countyValue,
        },
      }
      dispatch({ type: 'UPDATE_USER', data: userData })

      const emailData = composeEmailBody({
        retailer,
        product,
        issue,
        journeyFlow,
        user: userData,
        autoEmailer: true,
      })

      const pageDataState = {
        emailData,
        issue: {
          cardProvider: issue.cardProvider,
          claimValue: issue.claimValue,
          description: issue.description,
          desiredResolution: issue.desiredResolution,
          sellerContact: issue.sellerContact,
        },
        retailer: {
          name: retailer.name,
          repeatPerformance: retailer.repeatPerformance,
        },
        optIns: { singleConsentOptIn, caseStudy },
        product: {
          description: product.description,
          purchaseDate: product.purchaseDate,
          purchaseMethod: product.purchaseMethod,
          price: product.price,
          type: product.type,
        },
        user: { ...userData },
      }

      const structuredData = pageData(pageDataState)
      const ENResponse = await pageSubmission(structuredData, 96076)

      if (ENResponse && (ENResponse.ok || ENResponse.status === 'SUCCESS')) {
        handleDispatch(true)
      } else {
        setErrorCount(errorCount + 1)
        setDisableBtn(false)
      }
    } else {
      scrollToInvalidInput(currentInputsList)
      setDisableBtn(false)
    }
  }

  const formItems = contactDetailsData.formItems.map((x) => ({
    ...x,
    enterKeyHandler: handleSubmit,
  }))

  const addressItems = contactDetailsData.addressItems.map((x) => ({
    ...x,
    enterKeyHandler: handleSubmit,
  }))

  const handleSingleConsentOptInChange = () => {
    dispatch({ type: 'UPDATE_SINGLE_CONSENT_OPTIN', data: !singleConsentOptIn })
  }

  const optins = [
    {
      checked: singleConsentOptIn,
      ...contactDetailsData.singleConsentOptIn,
      showOptInSpecificTerms: true,
      onChangeCallback: handleSingleConsentOptInChange,
    },
  ]

  const { buttonText, imgObj, title } = contactDetailsData

  return (
    <ContactDetailsPage
      addressItems={addressItems}
      btnDisable={disableBtn}
      buttonText={buttonText}
      errorCount={errorCount}
      formItems={formItems}
      handleSubmit={handleSubmit}
      imgObj={imgObj}
      inputsData={inputsData}
      optins={optins}
      ref={ContactFormRefs}
      testId="contact-details"
      title={title}
      user={user}
    />
  )
}
