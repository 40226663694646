import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'
import { InfoBox, Typography } from '@which/seatbelt'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import globalStyles from '../../../../shared/styles/GlobalStyles.module.scss'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { HowMuchMoneyFlow, LostMoneyFlow, ReportedToFlow } from '../../pageFlowData'
import { lostMoneyData } from './data'
import styles from './LostMoney.module.scss'

export const LostMoney: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    victim: { lostMoney },
  } = state

  const handleStepChoice = (value: string) => {
    if (value === 'yes') {
      return { progress: HowMuchMoneyFlow.PROGRESS_VALUE, step: HowMuchMoneyFlow.STEP }
    }

    return { progress: ReportedToFlow.PROGRESS_VALUE, step: ReportedToFlow.STEP }
  }

  const handleDispatch = (value: string) => {
    const { progress, step } = handleStepChoice(value)

    dispatch({ type: 'UPDATE_LOST_MONEY', data: value })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${LostMoneyFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = (id: string) => {
    handleDispatch(id)
    handleTracking(id)
  }

  const { buttons } = lostMoneyData

  return (
    <div data-testid="lost-money">
      <PillButtonPage
        buttons={buttons}
        defaultValue={lostMoney}
        handleSubmit={handleSubmit}
        imgObj={{
          alt: 'Two people looking stressed while one is talking on the phone',
          src: 'https://media.product.which.co.uk/prod/images/original/db08cf48f9c8-did-you-lose-any-money.jpg',
        }}
        pillButtonGroupStyle={styles.lostMoneyPillButtons}
        title="Did you lose any money?"
      />
      <InfoBox {...lostMoneyData.infoBox} className={globalStyles.maxWidth}>
        <Typography tag="p" textStyle="body">
          We are collecting information relating to your experience as a victim of a scam, you don't
          have to give us any of your personal details unless you choose to.
        </Typography>
      </InfoBox>
    </div>
  )
}
