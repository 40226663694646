import type { FunctionComponent } from 'react'
import React, { useContext, useEffect, useRef, useState } from 'react'

import { FormEntryPage } from '../../../../shared/pages/FormEntryPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryInputRef, SingleInputState } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import {
  googleMapsScript,
  handleAutocomplete,
} from '../../../../shared/utils/googlePlacesApi/googlePlacesAPI'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { HolidayDatesFlow, HolidayDestinationFlow } from '../../pageFlowData'
import { data } from './data'

export const Destination: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    holiday: { destination },
  } = state

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })

  const getValue = () => {
    return cleanText(autoCompleteRef?.current?.formEntryInput?.value || '')
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${HolidayDestinationFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_HOLIDAY_DESTINATION', data: value })
    dispatch({ type: 'UPDATE_STEP', data: HolidayDatesFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: HolidayDatesFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    const value = getValue()
    const destinationHasError = !isNotEmpty(value)

    setInputData({
      renderError: destinationHasError,
      value: value,
    })

    if (!destinationHasError) {
      handleTracking(value)
      handleDispatch(value)
    }
  }

  useEffect(() => {
    /* istanbul ignore next */
    googleMapsScript(() => handleAutocomplete(setQuery, autoCompleteRef.current?.formEntryInput)) //Loading script
    /* istanbul ignore next */
    if (typeof google === 'object' && typeof google.maps === 'object') {
      handleAutocomplete(setQuery, autoCompleteRef.current?.formEntryInput)
    }
  }, [])

  const [query, setQuery] = useState(destination)
  const autoCompleteRef = useRef<FormEntryInputRef>(null)

  const handleOnChange = (e: any) => {
    setQuery(e.target.value)
  }

  const { renderError, value: errorValue } = inputData
  const { imgObj, placeholderText, title } = data

  return (
    <FormEntryPage
      buttonText="Next"
      defaultValue={query}
      fieldData={{
        renderError: renderError,
        value: errorValue,
      }}
      handleOnChange={handleOnChange}
      handleSubmit={handleSubmit}
      id="destination"
      imgObj={imgObj}
      isFocused
      placeholderText={placeholderText}
      ref={autoCompleteRef}
      testId="destination"
      title={title}
    />
  )
}
