import type { FunctionComponent } from 'react'
import { useCallback } from 'react'
import React, { useContext, useEffect, useRef, useState } from 'react'

import { ContactDetailsPage } from '../../../../shared/pages/ContactDetailsPage/ContactDetails'
import { ToolContext } from '../../../../shared/state/appContext'
import type { ContactRefProps, MultiInputsState } from '../../../../shared/types/toolTypes'
import type { SingleInputState } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { pageSubmission } from '../../../../shared/utils/EngagingNetwork/pageSubmission'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { scrollToInvalidInput } from '../../../../shared/utils/scrollToInvalidInput'
import { validateEmail } from '../../../../shared/utils/validateEmail'
import { validatePhoneNumber } from '../../../../shared/utils/validatePhoneNumber'
import { validatePostcode } from '../../../../shared/utils/validatePostcode'
import { pageData } from '../../pageData'
import { SubmitSuccessfulFlow } from '../../pageFlowData'
import { composeEmailBody } from '../SubmitSuccessful/composeEmailBody'
import { contactDetailsData } from './data'

export const ContactDetails: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: {
      optIns: { singleConsentOptIn },
      user,
    },
    claim,
    flight,
  } = state

  const [inputsData, setInputsData] = useState<MultiInputsState>({
    fNameData: { renderError: false, value: '' },
    lNameData: { renderError: false, value: '' },
    emailData: { renderError: false, value: '' },
    postcodeData: { renderError: false, value: '' },
    addressLine1Data: { renderError: false, value: '' },
    addressLine2Data: { renderError: false, value: '' },
    townCityData: { renderError: false, value: '' },
    countyData: { renderError: false, value: '' },
  })

  const PhoneNumberRef = useRef<HTMLInputElement>(null)
  const [phoneNumberData, setPhoneNumberData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })

  const [errorCount, setErrorCount] = useState(0)
  const [disableBtn, setDisableBtn] = useState(false)

  const ContactFormRefs = useRef<ContactRefProps>(null)

  const currentInputsList: HTMLInputElement[] = []

  for (const input in ContactFormRefs.current) {
    currentInputsList.push(ContactFormRefs.current[input])
  }

  const handleDispatch = useCallback(
    (emailSent: boolean) => {
      dispatch({ type: 'UPDATE_EMAIL_SENT', data: emailSent })
      dispatch({ type: 'UPDATE_STEP', data: SubmitSuccessfulFlow.STEP })
      dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: SubmitSuccessfulFlow.PROGRESS_VALUE })
    },
    [dispatch]
  )

  useEffect(() => {
    if (errorCount > 1) {
      // If they've gotten two or more errors, go to NextSteps anyway
      handleDispatch(false)
    }
  }, [errorCount, handleDispatch])

  const getValue = () => {
    return cleanText(PhoneNumberRef?.current?.value || '')
  }

  const validateInputs = () => {
    const firstNameValue: string = ContactFormRefs.current?.firstName.value || ''
    const firstNameHasError = !isNotEmpty(firstNameValue) || !/^[a-z '-]+$/i.test(firstNameValue)

    const lastNameValue: string = ContactFormRefs.current?.lastName.value || ''
    const lastNameHasError = !isNotEmpty(lastNameValue) || !/^[a-z '-]+$/i.test(lastNameValue)

    const emailValue: string = ContactFormRefs.current?.emailAddress.value || ''
    const emailHasError = !isNotEmpty(emailValue) || !validateEmail(emailValue)

    const postcodeValue: string = ContactFormRefs.current?.postcode?.value || ''
    const postcodeHasError = !isNotEmpty(postcodeValue) || !validatePostcode(postcodeValue)

    const addressLine1Value: string = ContactFormRefs.current?.addressLine1?.value || ''
    const addressLine1HasError = !isNotEmpty(addressLine1Value)

    const addressLine2Value: string = ContactFormRefs.current?.addressLine2?.value || ''
    const addressLine2HasError = !isNotEmpty(addressLine2Value)

    const townCityValue: string = ContactFormRefs.current?.townCity?.value || ''
    const townCityHasError = !isNotEmpty(townCityValue)

    const countyValue: string = ContactFormRefs.current?.county?.value || ''
    const countyHasError = !isNotEmpty(countyValue)

    const value = getValue()
    const phoneNumberHasError = isNotEmpty(value) && !validatePhoneNumber(value)

    setPhoneNumberData({
      renderError: phoneNumberHasError,
      value: value,
    })

    const data = {
      fNameData: { renderError: firstNameHasError, value: firstNameValue },
      lNameData: { renderError: lastNameHasError, value: lastNameValue },
      emailData: { renderError: emailHasError, value: emailValue },
      postcodeData: { renderError: postcodeHasError, value: postcodeValue },
      addressLine1Data: { renderError: addressLine1HasError, value: addressLine1Value },
      addressLine2Data: { renderError: addressLine2HasError, value: addressLine2Value },
      townCityData: { renderError: townCityHasError, value: townCityValue },
      countyData: { renderError: countyHasError, value: countyValue },
      pNumberData: { renderError: phoneNumberHasError, value: value },
    }

    setInputsData(data)

    const isValid =
      firstNameHasError ||
      lastNameHasError ||
      postcodeHasError ||
      addressLine1HasError ||
      townCityHasError ||
      emailHasError ||
      phoneNumberHasError
        ? false
        : true

    return { inputData: data, isValid: isValid }
  }

  const buildUserDetails = (data: any) => {
    const {
      fNameData,
      lNameData,
      emailData: emailContent,
      postcodeData,
      addressLine1Data,
      addressLine2Data,
      townCityData,
      countyData,
      pNumberData,
    } = data

    const userData = {
      firstName: fNameData.value,
      lastName: lNameData.value,
      emailAddress: emailContent.value,
      phoneNumber: pNumberData.value,
      address: {
        postcode: postcodeData?.value,
        addressLine1: addressLine1Data?.value,
        addressLine2: addressLine2Data?.value,
        townCity: townCityData?.value,
        county: countyData?.value,
      },
    }

    return userData
  }

  const buildPageDataState = (userData: any) => {
    const emailData = composeEmailBody({
      claim,
      flight,
      user: { ...userData },
      autoEmailer: true,
    })

    const pageDataState = {
      emailData,
      flight: {
        ukOrEuAirline: flight.ukOrEuAirline,
        arriveDepartUK: flight.arriveDepartUK,
        airports: {
          departureAirport: flight.airports.depatureAirport,
          arrivalAirport: flight.airports.arrivalAirport,
        },
        distance: flight.distance,
        delayedCancelled: flight.delayedCancelled,
        flightNumber: flight.flightNumber,
        date: flight.date,
        airline: flight.airline,
        scheduledTimes: {
          departureTimes: flight.scheduledTimes.departureTimes,
          arrivalTimes: flight.scheduledTimes.arrivalTimes,
        },
      },
      claim: {
        timeBeforeCancelled: claim.timeBeforeCancelled,
        earlierLaterOriginalFlight: claim.earlierLaterOriginalFlight,
        hoursDifference: claim.hoursDifference,
        numPeopleClaimingFor: claim.numPeopleClaimingFor,
        reasonGiven: claim.reasonGiven,
        amountEntitled: claim.amountEntitled,
      },
      optIns: { singleConsentOptIn },
      user: { ...userData },
    }

    return pageDataState
  }

  const handleSubmit = async () => {
    const submissionFormId = 108087
    const validationData = validateInputs()

    setDisableBtn(true)

    if (validationData.isValid) {
      const userData = buildUserDetails(validationData.inputData)
      const pageDataState = buildPageDataState(userData)

      dispatch({
        type: 'UPDATE_USER',
        data: userData,
      })

      const structuredData = pageData(pageDataState, contactOptin)
      const ENResponse = await pageSubmission(structuredData, submissionFormId)

      if (ENResponse && (ENResponse.ok || ENResponse.status === 'SUCCESS')) {
        handleDispatch(true)
      } else {
        setErrorCount(errorCount + 1)
        setDisableBtn(false)
      }
    } else {
      scrollToInvalidInput(currentInputsList)
      setDisableBtn(false)
    }
  }

  const formItems = contactDetailsData.formItems.map((x) => ({
    ...x,
    enterKeyHandler: handleSubmit,
  }))

  const addressItems = contactDetailsData.addressItems.map((x) => ({
    ...x,
    enterKeyHandler: handleSubmit,
  }))

  const handleSingleConsentOptInChange = () => {
    dispatch({ type: 'UPDATE_SINGLE_CONSENT_OPTIN', data: !singleConsentOptIn })
  }

  const [contactOptin, setContactOptin] = useState(false)

  const handleContactUpdatesChange = () => {
    setContactOptin(!contactOptin)
  }

  const optins = [
    {
      checked: singleConsentOptIn,
      ...contactDetailsData.singleConsentOptIn,
      showOptInSpecificTerms: true,
      onChangeCallback: handleSingleConsentOptInChange,
    },
    {
      checked: contactOptin,
      ...contactDetailsData.contactUpdates,
      onChangeCallback: handleContactUpdatesChange,
    },
  ]

  const { buttonText, imgObj, title } = contactDetailsData

  return (
    <>
      <ContactDetailsPage
        addressItems={addressItems}
        btnDisable={disableBtn}
        buttonText={buttonText}
        contactOptin={contactOptin}
        errorCount={errorCount}
        formItems={formItems}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        inputsData={inputsData}
        optins={optins}
        phoneNumberData={phoneNumberData}
        phoneNumberRef={PhoneNumberRef}
        ref={ContactFormRefs}
        testId="contact-details"
        title={title}
        user={user}
      />
    </>
  )
}
