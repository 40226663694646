import type { FunctionComponent } from 'react'
import React, { useContext, useEffect, useState } from 'react'

import { ImageWithQuestion } from '../../../../shared/components/ImageWithQuestion'
import { Loader } from '../../../../shared/components/Loader'
import { ToolContext } from '../../../../shared/state/appContext'
import { initialiseAppointmentBookedTracking } from '../../../../shared/utils/tracking/tracking'
import styles from './AppointmentChoice.module.scss'
import {
  appointmentChoiceLegalData,
  appointmentChoiceMoneyData,
  appointmentChoiceTechData,
} from './data'
import { fetchIframe } from './fetchIframe'
import type { salesforceStateType } from './salesforceMapper'
import { salesforceMapper } from './salesforceMapper'

export const AppointmentChoice: FunctionComponent = () => {
  const { state } = useContext(ToolContext)

  const {
    buying,
    legal,
    employment,
    holiday,
    other,
    home,
    triage,
    money,
    cars,
    tech,
    wills: { willsTopic, willsQuery },
    initial: { user },
  } = state
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [iFrameData, setIFrameData]: any = useState(null)

  useEffect(() => {
    const body: any = salesforceMapper(state as salesforceStateType)

    return initialiseAppointmentBookedTracking(body)
  }, [state])

  useEffect(() => {
    if (!hasSubmitted && !isSubmitting) {
      setIsSubmitting(true)
      const createIFrame = async () => {
        const iFrameResponse = await fetchIframe(
          {
            buying,
            legal,
            employment,
            holiday,
            other,
            home,
            money,
            triage,
            cars,
            tech,
            initial: { user },
            wills: { willsTopic, willsQuery },
          },
          triage.type
        )
        setHasSubmitted(true)
        setIFrameData(iFrameResponse)
      }
      createIFrame()
    }
  }, [
    buying,
    cars,
    employment,
    hasSubmitted,
    holiday,
    home,
    isSubmitting,
    legal,
    money,
    other,
    state,
    tech,
    triage,
    user,
    willsQuery,
    willsTopic,
  ])

  const timeFrame = () => {
    if (triage.type === 'tech') {
      return '1'
    } else if (triage.type === 'money') {
      return '3'
    }
    return '2'
  }
  const chooseData = () => {
    if (triage.type === 'tech') {
      return appointmentChoiceTechData
    } else if (triage.type === 'money') {
      return appointmentChoiceMoneyData
    }
    return appointmentChoiceLegalData
  }

  const { imgObj, title, standFirst } = chooseData()

  if (!hasSubmitted) {
    return <Loader />
  } else {
    return (
      <div>
        <ImageWithQuestion image={imgObj} title={title} />
        <p className={styles.appointmentChoiceStandFirst} data-testid="typography-body">
          {standFirst.replaceAll('TIMEFRAME', timeFrame())}
        </p>
        {iFrameData?.error ? (
          <div className={styles.appointmentChoiceError}>
            <p>{iFrameData.error}</p>
            Please call us on{' '}
            <a className="sb-link-primary" href="tel:+02921682264">
              <span className="sb-link-animation-wrapper">0292 168 2264</span>
            </a>{' '}
            to arrange an appointment with one of our Experts.
          </div>
        ) : (
          <iframe
            allow="geolocation"
            className={styles.appointmentChoiceFrame}
            referrerPolicy="no-referrer"
            src={iFrameData?.appointmentUrl}
            title="Appointment Choice"
          ></iframe>
        )}
      </div>
    )
  }
}
