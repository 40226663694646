import type { PageFlowProps } from '../../../shared/types/toolTypes'

export const TriageFlow: PageFlowProps = {
  PROGRESS_VALUE: 0,
  STEP: 0,
  VIEW_NAME: 'triage',
  REDUCERS: [''],
  MILESTONE: 'milestone | start',
}

export const SolicitorFlow: PageFlowProps = {
  PROGRESS_VALUE: 0,
  STEP: 1,
  VIEW_NAME: 'solicitor',
  REDUCERS: ['UPDATE_TRIAGE_SOLICITOR'],
  MILESTONE: '',
}

export const LegalTopicFlow: PageFlowProps = {
  PROGRESS_VALUE: 10,
  STEP: 2,
  VIEW_NAME: 'legal-topic',
  REDUCERS: ['UPDATE_LEGAL_TOPIC'],
  MILESTONE: '',
}

export const CarsTopicFlow: PageFlowProps = {
  PROGRESS_VALUE: 25,
  STEP: 3,
  VIEW_NAME: 'cars-topic',
  REDUCERS: ['UPDATE_CARS_TOPIC'],
  MILESTONE: '',
}

export const CarsQueryFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 4,
  VIEW_NAME: 'cars-query',
  REDUCERS: ['UPDATE_CARS_QUERY'],
  MILESTONE: '',
}

export const CarsLegalFlow: PageFlowProps = {
  PROGRESS_VALUE: 60,
  STEP: 5,
  VIEW_NAME: 'cars-legal',
  REDUCERS: ['UPDATE_CARS_LEGAL'],
  MILESTONE: '',
}

export const SubmitSuccessfulFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 6,
  VIEW_NAME: 'journey-complete',
  REDUCERS: ['UPDATE_EMAIL_SENT'],
  MILESTONE: 'milestone | letter sent',
}

export const AppointmentChoiceFlow: PageFlowProps = {
  PROGRESS_VALUE: 95,
  STEP: 7,
  VIEW_NAME: 'appointment-choice',
  REDUCERS: [''],
  MILESTONE: '',
}

export const EmploymentTopicFlow: PageFlowProps = {
  PROGRESS_VALUE: 25,
  STEP: 8,
  VIEW_NAME: 'employment-topic',
  REDUCERS: ['UPDATE_EMPLOYMENT_TOPIC'],
  MILESTONE: '',
}

export const MedicalTopicFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 10,
  VIEW_NAME: 'medical-topic',
  REDUCERS: ['UPDATE_MEDICAL_TOPIC'],
  MILESTONE: '',
}

export const OtherTopicFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 11,
  VIEW_NAME: 'other-topic',
  REDUCERS: ['UPDATE_OTHER_TOPIC'],
  MILESTONE: '',
}

export const WillsTopicFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 12,
  VIEW_NAME: 'wills-topic',
  REDUCERS: ['UPDATE_WILLS_TOPIC'],
  MILESTONE: '',
}

export const WillsQueryFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 13,
  VIEW_NAME: 'wills-query',
  REDUCERS: ['UPDATE_WILLS_QUERY'],
  MILESTONE: '',
}

export const WillsEstateFlow: PageFlowProps = {
  PROGRESS_VALUE: 40,
  STEP: 14,
  VIEW_NAME: 'wills-estate',
  REDUCERS: ['UPDATE_WILLS_ESTATE'],
  MILESTONE: '',
}

export const WillsDetailFlow: PageFlowProps = {
  PROGRESS_VALUE: 60,
  STEP: 15,
  VIEW_NAME: 'wills-detail',
  REDUCERS: ['UPDATE_WILLS_DETAIL'],
  MILESTONE: '',
}

export const BuyingTopicFlow: PageFlowProps = {
  PROGRESS_VALUE: 25,
  STEP: 16,
  VIEW_NAME: 'buying-topic',
  REDUCERS: [
    'UPDATE_BUYING_TOPIC',
    'UPDATE_HOME_TOPIC',
    'UPDATE_HOLIDAY_TOPIC',
    'UPDATE_CARS_TOPIC',
  ],
  MILESTONE: '',
}

export const BuyingQueryFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 17,
  VIEW_NAME: 'buying-query',
  REDUCERS: ['UPDATE_BUYING_QUERY'],
  MILESTONE: '',
}

export const HolidayQueryFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 19,
  VIEW_NAME: 'holiday-query',
  REDUCERS: ['UPDATE_HOLIDAY_QUERY'],
  MILESTONE: '',
}

export const HolidayTopicFlow: PageFlowProps = {
  PROGRESS_VALUE: 30,
  STEP: 20,
  VIEW_NAME: 'holiday-topic',
  REDUCERS: ['UPDATE_HOLIDAY_TOPIC', 'UPDATE_CARS_TOPIC', 'UPDATE_BUYING_TOPIC'],
  MILESTONE: '',
}

export const HolidayBookingFlow: PageFlowProps = {
  PROGRESS_VALUE: 70,
  STEP: 21,
  VIEW_NAME: 'holiday-booking',
  REDUCERS: ['UPDATE_HOLIDAY_BOOKING'],
  MILESTONE: '',
}

export const HomeTopicFlow: PageFlowProps = {
  PROGRESS_VALUE: 30,
  STEP: 22,
  VIEW_NAME: 'home-topic',
  REDUCERS: ['UPDATE_HOME_TOPIC'],
  MILESTONE: '',
}

export const HomeQueryFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 23,
  VIEW_NAME: 'home-query',
  REDUCERS: ['UPDATE_HOME_QUERY'],
  MILESTONE: '',
}

export const HomeLocationFlow: PageFlowProps = {
  PROGRESS_VALUE: 40,
  STEP: 24,
  VIEW_NAME: 'home-location',
  REDUCERS: ['UPDATE_HOME_LOCATION'],
  MILESTONE: '',
}

export const HomeDetailFlow: PageFlowProps = {
  PROGRESS_VALUE: 60,
  STEP: 25,
  VIEW_NAME: 'home-detail',
  REDUCERS: ['UPDATE_HOME_DETAIL'],
  MILESTONE: '',
}

export const MoneyTopicFlow: PageFlowProps = {
  PROGRESS_VALUE: 0,
  STEP: 26,
  VIEW_NAME: 'money-topic',
  REDUCERS: ['UPDATE_MONEY_TOPIC'],
  MILESTONE: '',
}

export const DescribeCaseFlow: PageFlowProps = {
  PROGRESS_VALUE: 85,
  STEP: 27,
  VIEW_NAME: 'describe-case',
  REDUCERS: ['UPDATE_DESCRIBE_CASE'],
  MILESTONE: '',
}

export const ContractChoiceFlow: PageFlowProps = {
  PROGRESS_VALUE: 75,
  STEP: 28,
  VIEW_NAME: 'contract-choice',
  REDUCERS: ['UPDATE_CONTRACT_CHOICE'],
  MILESTONE: '',
}

export const ContactDetailsFlow: PageFlowProps = {
  PROGRESS_VALUE: 80,
  STEP: 29,
  VIEW_NAME: 'contact-details',
  REDUCERS: [''],
  MILESTONE: '',
}

export const LimitedOptionsFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 30,
  VIEW_NAME: 'limited-options',
  REDUCERS: [''],
  MILESTONE: 'milestone | next steps | end',
}

export const MoneyQueryFlow: PageFlowProps = {
  PROGRESS_VALUE: 80,
  STEP: 31,
  VIEW_NAME: 'money-query',
  REDUCERS: ['UPDATE_MONEY_QUERY'],
  MILESTONE: '',
}

export const TechTopicFlow: PageFlowProps = {
  PROGRESS_VALUE: 0,
  STEP: 32,
  VIEW_NAME: 'tech-topic',
  REDUCERS: ['UPDATE_TECH_TOPIC'],
  MILESTONE: '',
}

export const TechQueryFlow: PageFlowProps = {
  PROGRESS_VALUE: 40,
  STEP: 33,
  VIEW_NAME: 'tech-query',
  REDUCERS: ['UPDATE_TECH_QUERY'],
  MILESTONE: '',
}

export const TechDeviceFlow: PageFlowProps = {
  PROGRESS_VALUE: 60,
  STEP: 34,
  VIEW_NAME: 'tech-device',
  REDUCERS: ['UPDATE_TECH_DEVICE'],
  MILESTONE: '',
}

export const TechSystemFlow: PageFlowProps = {
  PROGRESS_VALUE: 75,
  STEP: 35,
  VIEW_NAME: 'tech-system',
  REDUCERS: ['UPDATE_TECH_SYSTEM'],
  MILESTONE: '',
}

export const BestNumberFlow: PageFlowProps = {
  PROGRESS_VALUE: 90,
  STEP: 36,
  VIEW_NAME: 'best-number',
  REDUCERS: ['UPDATE_TRIAGE_NUMBER'],
  MILESTONE: '',
}

export const RebookAppointmentFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 37,
  VIEW_NAME: 'rebook-appointment',
  REDUCERS: ['UPDATE_CASE_ID'],
  MILESTONE: '',
}

export const FollowUpAppointmentFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 38,
  VIEW_NAME: 'follow-up-appointment',
  REDUCERS: [''],
  MILESTONE: '',
}

export const UnderConstructionFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 999,
  VIEW_NAME: 'under-construction',
  REDUCERS: [''],
  MILESTONE: '',
}

export const AllFlows = [
  SolicitorFlow,
  LegalTopicFlow,
  CarsTopicFlow,
  CarsQueryFlow,
  CarsLegalFlow,
  SubmitSuccessfulFlow,
  LimitedOptionsFlow,
  AppointmentChoiceFlow,
  OtherTopicFlow,
  WillsTopicFlow,
  WillsQueryFlow,
  WillsEstateFlow,
  WillsDetailFlow,
  BuyingTopicFlow,
  BuyingQueryFlow,
  HolidayQueryFlow,
  HolidayTopicFlow,
  HolidayBookingFlow,
  TriageFlow,
  MoneyTopicFlow,
  DescribeCaseFlow,
  ContractChoiceFlow,
  ContactDetailsFlow,
  MoneyQueryFlow,
  TechTopicFlow,
  TechQueryFlow,
  TechDeviceFlow,
  TechSystemFlow,
  UnderConstructionFlow,
  MedicalTopicFlow,
  RebookAppointmentFlow,
  FollowUpAppointmentFlow,
]
