export const data = {
  ticketLocationData: [
    {
      displayText: 'Address line 1',
      id: 'addressLine1',
      type: 'text',
      isFocused: true,
    },
    {
      displayText: 'Address line 2',
      id: 'addressLine2',
      type: 'text',
      isFocused: false,
      optional: true,
    },
    {
      displayText: 'Town/City',
      id: 'townCity',
      type: 'text',
      isFocused: false,
    },
    {
      displayText: 'County',
      id: 'county',
      type: 'text',
      isFocused: false,
      optional: true,
    },
    {
      displayText: 'Postcode',
      id: 'postcode',
      type: 'text',
      isFocused: false,
    },
  ],
  imgObj: {
    src: 'https://media.product.which.co.uk/prod/images/original/gm-8617bfb7-8563-4bdd-805b-ed336ca1aaa7-10.jpg',
    alt: 'A person holding a phone with a pinpoint location showing on a map',
  },
  title: 'What is the location quoted on the ticket?',
}
