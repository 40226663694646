export const buttonData = [
  { label: '12-17 years old', id: '12-17', isFocused: true },
  { label: '18-24 years old', id: '18-24' },
  { label: '25-34 years old', id: '25-34' },
  {
    label: '35-44 years old',
    id: '35-44',
  },
  {
    label: '45-54 years old',
    id: '45-54',
  },
  {
    label: '55-64 years old',
    id: '55-64',
  },
  { label: '65-74 years old', id: '65-74' },
  { label: '75 years or older', id: '75' },
  { label: 'Prefer not to say', id: 'prefer-not-to-say' },
]
