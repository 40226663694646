import type { PageFlowProps } from '../../../shared/types/toolTypes'

export const DeliveryProblemFlow: PageFlowProps = {
  PROGRESS_VALUE: 0,
  STEP: 1,
  VIEW_NAME: 'delivery-problem',
  REDUCERS: ['UPDATE_DELIVERY_PROBLEM'],
  MILESTONE: 'milestone | start',
}

export const DeliveryPermissionFlow: PageFlowProps = {
  PROGRESS_VALUE: 20,
  STEP: 2,
  VIEW_NAME: 'delivery-permission',
  REDUCERS: ['UPDATE_DELIVERY_PERMISSION'],
  MILESTONE: '',
}

export const WhichRetailerFlow: PageFlowProps = {
  PROGRESS_VALUE: 30,
  STEP: 3,
  VIEW_NAME: 'which-retailer',
  REDUCERS: ['UPDATE_RETAILER_NAME'],
  MILESTONE: '',
}

export const ItemOrderedFlow: PageFlowProps = {
  PROGRESS_VALUE: 40,
  STEP: 4,
  VIEW_NAME: 'item-ordered',
  REDUCERS: ['UPDATE_ITEM_ORDERED'],
  MILESTONE: '',
}

export const OrderNumberFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 5,
  VIEW_NAME: 'order-number',
  REDUCERS: ['UPDATE_ORDER_NUMBER'],
  MILESTONE: '',
}

export const DeliveryDateExplainedFlow: PageFlowProps = {
  PROGRESS_VALUE: 75,
  STEP: 6,
  VIEW_NAME: 'delivery-date-explained',
  REDUCERS: ['UPDATE_DELIVERY_DATE_EXPLAINED'],
  MILESTONE: '',
}

export const WhatHappenedFlow: PageFlowProps = {
  PROGRESS_VALUE: 75,
  STEP: 7,
  VIEW_NAME: 'what-happened',
  REDUCERS: ['UPDATE_WHAT_HAPPENED'],
  MILESTONE: '',
}

export const WhatWentWrongFlow: PageFlowProps = {
  PROGRESS_VALUE: 85,
  STEP: 8,
  VIEW_NAME: 'what-went-wrong',
  REDUCERS: ['UPDATE_WHAT_WENT_WRONG'],
  MILESTONE: '',
}

export const ContactDetailsFlow: PageFlowProps = {
  PROGRESS_VALUE: 95,
  STEP: 9,
  VIEW_NAME: 'contact-details',
  REDUCERS: [''],
  MILESTONE: 'milestone | personal details',
}

export const SubmitSuccessfulFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 10,
  VIEW_NAME: 'journey-complete',
  REDUCERS: ['UPDATE_EMAIL_SENT'],
  MILESTONE: 'milestone | end | letter sent',
}

export const ToolFeedbackFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 11,
  VIEW_NAME: 'feedback-form',
  REDUCERS: [''],
  MILESTONE: 'milestone | feedback start',
}

export const ToolFeedbackAudienceFlow: PageFlowProps = {
  PROGRESS_VALUE: 90,
  STEP: 12,
  VIEW_NAME: 'feedback-audience-form',
  REDUCERS: [''],
  MILESTONE: '',
}

export const ToolFeedbackThankYouFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 13,
  VIEW_NAME: 'feedback-form-thankyou',
  REDUCERS: [''],
  MILESTONE: '',
}

export const LimitedOptionsFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 14,
  VIEW_NAME: 'limited-options',
  REDUCERS: [''],
  MILESTONE: 'milestone | next steps | end',
}

export const AllFlows = [
  DeliveryProblemFlow,
  DeliveryPermissionFlow,
  WhichRetailerFlow,
  ItemOrderedFlow,
  OrderNumberFlow,
  DeliveryDateExplainedFlow,
  WhatHappenedFlow,
  WhatWentWrongFlow,
  ContactDetailsFlow,
  SubmitSuccessfulFlow,
  ToolFeedbackFlow,
  ToolFeedbackAudienceFlow,
  ToolFeedbackThankYouFlow,
  LimitedOptionsFlow,
]
