import { entitlementData } from './data'

export const chooseDataSet = (chooseDataSetProps: Props) => {
  const { type, purchaseMethod, repeatPerformance, price, purchasedDate } = chooseDataSetProps
  const priceAmount = parseInt(price)
  const priceBetween0And100 = priceAmount > 0 && priceAmount < 100
  const priceBetween100And30000 = priceAmount > 99.99 && priceAmount <= 30000
  const priceAbove30000 = priceAmount > 30000

  const timestamp120DaysAgo = Date.now() - 120 * 24 * 60 * 60 * 1000
  const parsedPurchasedDate = Date.parse(purchasedDate)
  const purchasedDateLessThan120Days = parsedPurchasedDate > timestamp120DaysAgo

  if (
    priceBetween0And100 ||
    purchaseMethod === 'debitCard' ||
    purchaseMethod === 'chargeCard' ||
    (purchaseMethod === 'creditCard' && priceAbove30000)
  ) {
    return purchasedDateLessThan120Days ? entitlementData.option3 : entitlementData.option4
  }

  if (
    (purchaseMethod === 'creditCard' || purchaseMethod === 'linkedCreditAgreement') &&
    priceBetween100And30000
  ) {
    if (type === 'product') {
      return entitlementData.option1
    }
    /* istanbul ignore else */
    if (type === 'service' && repeatPerformance) {
      return entitlementData.option2
    }
  }

  return entitlementData.option1 // default
}

export type Props = {
  type: string
  purchaseMethod: string
  repeatPerformance: boolean
  price: string
  purchasedDate: string
}
