import type { FunctionComponent } from 'react'
import React, { useContext, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import { FormEntryItem } from '../../../../shared/components/FormEntryItem'
import { ImageWithQuestion } from '../../../../shared/components/ImageWithQuestion'
import { NextButton } from '../../../../shared/components/NextButton'
import { bankData } from '../../../../shared/data/bankData'
import { ToolContext } from '../../../../shared/state/appContext'
import type { SingleInputState } from '../../../../shared/types/toolTypes'
import { autocomplete } from '../../../../shared/utils/autoComplete/autoComplete'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { NameOfAccountFlow, NameOfBankFlow } from '../../pageFlowData'
import { data } from './data'
import styles from './NameOfBank.module.scss'

export const NameOfBank: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    account: { nameOfBank },
  } = state

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const autoCompleteRef = useRef<HTMLInputElement>(null)

  const arrayOfBanks = bankData.map(({ label }) => label)

  useEffect(() => {
    /* istanbul ignore next */
    if (autoCompleteRef && autoCompleteRef.current) {
      autocomplete({
        autoCompleteRef,
        searchItemsList: arrayOfBanks,
        setIsMenuOpen,
        elementId: 'name-of-bank-input',
      })
    }
    return () => {
      /* istanbul ignore next */
      setIsMenuOpen(false) //Fix for potential memory leak
    }
  }, [arrayOfBanks])
  const getValue = () => {
    return cleanText(autoCompleteRef?.current?.value || '')
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${NameOfBankFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_STEP', data: NameOfAccountFlow.STEP })
    dispatch({ type: 'UPDATE_NAME_OF_BANK', data: value })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: NameOfAccountFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    const value = getValue()
    const nameOfBankHasError = !isNotEmpty(value)

    setInputData({
      renderError: nameOfBankHasError,
      value: value,
    })

    if (!nameOfBankHasError) {
      handleTracking(value)
      handleDispatch(value)
    }
  }

  const { renderError, value: errorValue } = inputData
  const { imgObj, title } = data

  return (
    <div
      className={classNames({ [styles.nameOfBankMenuOpen]: isMenuOpen })}
      data-testid="name-of-bank"
    >
      <ImageWithQuestion image={imgObj} title={title} />
      <FormEntryItem
        displayText=""
        enterKeyHandler={handleSubmit}
        fieldData={{
          renderError: renderError,
          value: errorValue,
        }}
        id="name-of-bank"
        isFocused={true}
        placeholderText=""
        ref={autoCompleteRef}
        srLabel={title}
        stateValue={nameOfBank}
        type="text"
      />

      <NextButton buttonText="Next" onClick={handleSubmit} />
    </div>
  )
}
