export const providerContactData = {
  buttons: [
    {
      label: 'Yes',
      id: 'yes',
    },
    {
      label: 'No',
      id: 'no',
    },
  ],
  title: 'Have you already asked your provider to fix your poor speed?',
  imgObj: {
    alt: 'A person sat down at a desk, typing on a keyboard',
    src: 'https://media.product.which.co.uk/prod/images/original/1cbdcd8c9834-step1.jpg',
  },
}
