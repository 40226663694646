import type { FunctionComponent } from 'react'
import React from 'react'
import { Typography } from '@which/seatbelt'

import classNames from 'classnames'

import globalStyles from '../../styles/GlobalStyles.module.scss'
import type { FeedbackEntryPointProps } from '../../types/toolTypes'
import { ImageWithQuestion } from '../ImageWithQuestion'
import { PillButtonGroup } from '../PillButtonGroup'

export const FeedbackEntryPoint: FunctionComponent<FeedbackEntryPointProps> = ({
  body,
  className,
  handleSubmit,
  imgObj,
  title,
}) => {
  return (
    <div className={classNames(className)} data-testid="feedback-entry">
      <ImageWithQuestion
        bottomMargin={globalStyles.spacing18}
        image={imgObj}
        title={title}
        titleStyle="title-500"
      />
      <Typography className={globalStyles.spacing40}>{body}</Typography>
      <PillButtonGroup
        buttons={[
          { label: 'Give feedback', id: 'feedback-yes' },
          { label: "Don't give feedback", id: 'feedback-no' },
        ]}
        handleSubmit={handleSubmit}
      />
    </div>
  )
}
