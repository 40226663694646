export const whyMisSoldData = {
  title: 'In your own words please describe why you think you were mis-sold the account',
  imgObj: {
    src: 'https://media.product.which.co.uk/prod/images/original/d607708f76db-mis-sold-step-3.jpg',
    alt: 'A man talking on the phone with a woman looking concerned by his side',
  },
  note: [
    'What you write here will appear in the final letter of claim, so please be as clear as possible.',
  ],
  descriptiveText: [],
  placeholderText:
    'Eg. My bank encouraged me to open the account but none of the benefits they mentioned were available to me after signing up.',
}
