import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { TextAreaPage } from '../../../../shared/pages/TextAreaPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryTextAreaRef } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { validateCharCount } from '../../../../shared/utils/validateCharCount'
import { BeenAffectedFlow, WhatAboutMessageFlow } from '../../pageFlowData'
import { data } from './data'

export const WhatAboutMessage: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    scam: { whatAboutMessage },
  } = state

  const [WhatAboutMessageTextAreaValid, setWhatAboutMessageTextAreaValid] = useState<boolean>(true)
  const WhatAboutMessageTextAreaRef = useRef<FormEntryTextAreaRef>(null)

  const handleWhatAboutMessageChange = () => {
    const value = getCleanValue(WhatAboutMessageTextAreaRef.current?.formEntryTextarea?.value)
    dispatch({ type: 'UPDATE_WHAT_ABOUT_MESSAGE', data: value })

    const isValid: boolean = validateCharCount(value.length, 2500)
    const fieldNotEmpty: boolean = isNotEmpty(value)
    if (fieldNotEmpty && isValid) {
      setWhatAboutMessageTextAreaValid(true)
    }
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${WhatAboutMessageFlow.VIEW_NAME} | ${whatAboutMessage}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = () => {
    dispatch({ type: 'UPDATE_STEP', data: BeenAffectedFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: BeenAffectedFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    const value: string = getCleanValue(
      WhatAboutMessageTextAreaRef.current?.formEntryTextarea?.value
    )
    const isValid: boolean = validateCharCount(value.length, 2500)
    const fieldNotEmpty: boolean = isNotEmpty(value)

    if (fieldNotEmpty && !isValid) {
      setWhatAboutMessageTextAreaValid(false)
      return
    }

    setWhatAboutMessageTextAreaValid(true)
    handleTracking()
    handleDispatch()
  }

  const { imgObj, title } = data

  return (
    <div data-testid="what-about-message">
      <TextAreaPage
        buttonText="Next"
        defaultValue={whatAboutMessage}
        handleChange={handleWhatAboutMessageChange}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        isFocused
        isInputValid={WhatAboutMessageTextAreaValid}
        maxChars={2500}
        optional
        ref={WhatAboutMessageTextAreaRef}
        title={title}
      />
    </div>
  )
}
