/* eslint-disable react-hooks/exhaustive-deps */
/* istanbul ignore file */

import type { FocusEventHandler } from 'react'
import React, { forwardRef, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'

import { defineCustomElements } from '@duetds/date-picker/dist/loader'
import classNames from 'classnames'

import type { DatePickerProps } from '../../types/toolTypes'
import { cleanText } from '../../utils/cleanText'
import { useMutationObservable } from '../../utils/useMutationObservable'
import { ErrorMessage } from '../ErrorMessage'
import { hasAnError } from '../FormEntryItem/hasAnError'
import { localizationData } from './data'
import type { RefObject } from './data/types'
import styles from './DatePicker.module.scss'
import { formatDate } from './formatDate'
import { constructDate } from './formatDate/constructDate'

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'duet-date-picker': any
    }
  }
}

const useListener = (ref: any, eventName: string, handler: FocusEventHandler<HTMLInputElement>) => {
  useEffect(() => {
    if (ref.current) {
      const element = ref.current
      element.addEventListener(eventName, handler)
      return () => element.removeEventListener(eventName, handler)
    }
  }, [eventName, handler, ref])
}

export const DatePicker = forwardRef<RefObject, DatePickerProps>(
  (props: DatePickerProps, datePickerRef) => {
    const {
      renderError,
      newErrorMessage,
      stateValue,
      handleInputChange,
      handleSubmit,
      max,
      min,
      isFocused = false,
      testId = 'date-picker',
    } = props
    const ref = useRef<RefObject | null>(null)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [dateValue, setDateValue] = useState(
      stateValue ? constructDate(new Date(stateValue), false) : constructDate(new Date(), false)
    )
    const [datePickerReady, setDatePickerReady] = useState(false)

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = event

      const newValue = formatDate(cleanText(value))
      hasAnError({
        value: newValue.trim(),
        id: 'date-picker',
        optional: false,
        setError,
        setErrorMessage,
      })
      if (handleInputChange) {
        handleInputChange(dateValue, ref.current?.dataset.testid)
      }
    }

    const handleClose = () => {
      if (handleInputChange) {
        handleInputChange(dateValue, ref.current?.dataset.testid)
      }
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
      const keyPressed: string = event.key
      if (
        keyPressed === 'Enter' &&
        handleSubmit &&
        document.activeElement?.className === 'duet-date__input'
      ) {
        handleSubmit()
      }
      return
    }

    useListener(ref, 'duetBlur', handleBlur)
    useListener(ref, 'duetClose', handleClose)

    useEffect(() => {
      defineCustomElements(window)
    })

    useLayoutEffect(() => {
      if (renderError) {
        setError(true)
        setErrorMessage(newErrorMessage)
      }
    }, [renderError, newErrorMessage, error])

    useEffect(() => {
      if (ref.current) {
        ref.current.localization = localizationData
        const DATE_FORMAT = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/

        ref.current.dateAdapter = {
          parse(value: string, createDate: (datePt1, datePt2, datePt3) => any) {
            const matches = value.match(DATE_FORMAT)
            if (matches) {
              return createDate(matches[3], matches[2], matches[1])
            } else {
              setDateValue(value)
              return null
            }
          },
          format(date: Date) {
            setDateValue(constructDate(date, false))
            return constructDate(date, true)
          },
        }
      }
      return () => {
        /* istanbul ignore next */
        setDateValue(constructDate(new Date(), false)) //Fix for potential memory leak
      }
    }, [])

    const onDatePickerMutated = useCallback(
      (datePickerEl) => {
        setDatePickerReady(datePickerEl[0].target.children.length > 0)
      },
      [setDatePickerReady]
    )

    useLayoutEffect(() => {
      if (isFocused && ref.current?.setFocus) {
        ref.current?.setFocus()
      }
    }, [datePickerReady])

    useMutationObservable(ref.current, onDatePickerMutated)

    return (
      <div className={classNames(styles.datePicker, { [styles.datePickerDuetError]: error })}>
        <duet-date-picker
          data-testid={testId}
          identifier="date-picker-input"
          {...(min && { min })}
          {...(max && { max })}
          onKeyDown={handleKeyDown}
          ref={(node: any) => {
            ref.current = node
            if (typeof datePickerRef === 'function') {
              datePickerRef(node)
            } else if (datePickerRef) {
              datePickerRef.current = node
            }
          }}
          value={dateValue}
        />
        {error && <ErrorMessage errorText={errorMessage} styleClasses={styles.datePickerError} />}
      </div>
    )
  }
)
