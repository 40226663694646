export const pageLinks = (data: any) => {
  const { emailData, optins, user } = data
  const { body } = emailData
  const { singleConsentOptIn } = optins

  let emailPt1BodyBuilder = ''
  body.billsLinksCopy.forEach((bodyData: { text: string }) => {
    emailPt1BodyBuilder = emailPt1BodyBuilder + `${bodyData?.text}`
  })

  let emailPt2BodyBuilder = ''
  body.financeLinksCopy.forEach((bodyData: { text: string }) => {
    emailPt2BodyBuilder = emailPt2BodyBuilder + `${bodyData?.text}`
  })

  const questions: any = {}
  questions['question.1552530'] = emailPt1BodyBuilder
  questions['question.1592766'] = emailPt2BodyBuilder
  questions['question.1598463'] = singleConsentOptIn ? 'Y' : 'N' // TF - Single Consent opt-in

  // return supporter
  return {
    supporter: {
      Email: user.emailAddress,
      'First name': user.firstName,
      questions,
    },
  }
}
