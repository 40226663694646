export const limitedOptionsData = {
  title:
    "We'd highly recommend seeing if your broadband provider's customer service team can resolve your issue before making a formal complaint.",
  standFirst: '',
  bodyCopy: [
    'This may be through calling the provider or discussing it with a member of their online support team.',
    "If you've tried to resolve the issue and have been unsuccessful, please continue to use our tool to notify your provider of your first formal complaint.",
  ],
  link: {
    href: 'https://www.which.co.uk/reviews/broadband/article/fix-bad-broadband/how-to-contact-your-broadband-provider-aSvnm1L1zgHw',
    headline:
      "Think your broadband is down? Find out how to quickly check and contact your broadband provider when there's a problem.",
    text: 'Broadband down? How to contact your provider',
  },
  ctaData: {
    image: {
      src: 'https://media.product.which.co.uk/prod/images/original/gm-8b0968cf-b7d0-49e7-b5fd-ff38b3887a0d-limitedoptionsfaultygoods.png',
      alt: 'Two people looking at their phones facing away from each other.',
    },
    author: { name: 'Which?', jobTitle: 'Editorial team' },
  },
}
