import type { FunctionComponent } from 'react'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Grid, GridItem } from '@which/seatbelt'

import { ToolWrapper } from '../../shared'
import { PageFade } from '../../shared/components/PageFade'
import { ProgressBar } from '../../shared/components/ProgressBar'
import { ToolContext } from '../../shared/state/appContext'
import styles from '../../shared/styles/GlobalStyles.module.scss'
import type { ImpactSurveyToolProps } from '../../shared/types/toolTypes'
import { checkSessionStorageExists } from '../../shared/utils/checkSessionStorageExists'
import { formatToolName } from '../../shared/utils/formatToolName'
import { getFilteredPageDataByStep } from '../../shared/utils/getFilteredPageDataByStep'
import { getQueryString } from '../../shared/utils/getQueryString'
import { getSessionStorageItem } from '../../shared/utils/getSessionStorageItem'
import { handleScroll } from '../../shared/utils/handleScroll'
import { removeSessionStorage } from '../../shared/utils/removeSessionStorage'
import { setUserJourney } from '../../shared/utils/setUserJourney'
import { storeToolState } from '../../shared/utils/storeToolState'
import {
  generalDataLayer,
  initaliseDataLayer,
  pageViewDataLayer,
} from '../../shared/utils/tracking'
import { useRenderStep } from './hooks/useRenderStep'
import { AllFlows, RegardingProblemFlow, SubmitSuccessfulFlow } from './pageFlowData'

export const ImpactSurvey: FunctionComponent<ImpactSurveyToolProps> = ({
  toolName,
  CRFooter,
  toolUrl,
}) => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: {
      entryToolName,
      initialised,
      journeyFlow: { milestoneHit },
      progressValue,
      step,
      userJourney,
    },
    user: { userEmail, toolID },
  } = state

  const [isForward, setIsForward] = useState(true)

  if (!initialised) {
    removeSessionStorage({ sessionStorageName: `${toolName}Progress` })
  }

  useEffect(() => {
    dispatch({ type: 'UPDATE_INITIALISED', data: true })
  }, [dispatch])

  useEffect(() => {
    handleScroll()
  }, [step])

  useEffect(() => {
    if (!entryToolName) {
      dispatch({ type: 'UPDATE_ENTRY_TOOL_NAME', data: toolName })
    }
  }, [dispatch, entryToolName, toolName])

  useEffect(() => {
    setUserJourney(userJourney, step, dispatch)
  }, [step, dispatch, userJourney])

  useEffect(() => {
    const queryEmail = getQueryString('email')
    const queryToolID = getQueryString('tool')
    if (!queryEmail || !queryToolID) {
      return
    }
    const storeSessionData = { email: queryEmail, toolID: queryToolID }
    storeToolState('impactSurveyData', storeSessionData)
    dispatch({ type: 'UPDATE_USER_EMAIL', data: queryEmail })
    dispatch({ type: 'UPDATE_TOOL_ID', data: queryToolID })
  }, [dispatch, toolID, userEmail])

  useEffect(() => {
    window.history.replaceState(null, '', toolUrl)
    if (!userEmail || !toolID) {
      const surveySessionData = getSessionStorageItem('impactSurveyData')
      if (surveySessionData && surveySessionData.email && surveySessionData.toolID) {
        dispatch({ type: 'UPDATE_USER_EMAIL', data: surveySessionData.email })
        dispatch({ type: 'UPDATE_TOOL_ID', data: surveySessionData.toolID })
      }
    }
  }, [dispatch, toolID, toolUrl, userEmail])

  useEffect(() => {
    const firePageView = () => {
      const pageData = getFilteredPageDataByStep(step, AllFlows, [RegardingProblemFlow])

      const { milestone, viewName } = pageData
      if (initialised === true) {
        pageViewDataLayer(viewName)
        const milestoneArray = milestoneHit

        if (!milestoneArray.includes(viewName) && milestone !== '') {
          milestoneArray.push(viewName)
          generalDataLayer(formatToolName(toolName), milestone)
          dispatch({ type: 'UPDATE_MILESTONE', data: milestoneArray })
          return
        }
      }
    }

    firePageView()
  }, [dispatch, initialised, milestoneHit, step, toolName])

  useEffect(() => {
    if (entryToolName !== '') {
      if (checkSessionStorageExists(`${toolName}Progress`)) {
        let sessionProgress = getSessionStorageItem(`${toolName}Progress`)
        let sessionProgressLength = sessionProgress.length - 1
        if (sessionProgressLength > 2 && step === 1) {
          sessionProgress = []
          sessionProgressLength = sessionProgress.length - 1
          storeToolState(`${toolName}Progress`, sessionProgress)
        }
        if (sessionProgress[sessionProgressLength] !== progressValue) {
          sessionProgress.push(progressValue)
          storeToolState(`${toolName}Progress`, sessionProgress)
        }
      } else {
        storeToolState(`${entryToolName}Progress`, [progressValue])
      }
    }
  }, [entryToolName, progressValue, step, toolName])

  return (
    <>
      <ToolWrapper>
        <Helmet>
          <meta content="noindex" name="robots" />
          <meta content="Start your impact survey" name="description" />
          <script>{initaliseDataLayer(formatToolName(toolName))}</script>
          <title>Impact Survey</title>
        </Helmet>
        <ProgressBar
          allFlows={AllFlows}
          endFlowStep={SubmitSuccessfulFlow.STEP}
          setIsForward={setIsForward}
          startFlowStep={RegardingProblemFlow.STEP}
          stateWidth={progressValue}
          toolName={toolName}
        />
        <PageFade
          isForward={isForward}
          setIsForward={setIsForward}
          stateProgress={progressValue}
          step={step}
          toolName={toolName}
        >
          {useRenderStep(step)}
        </PageFade>
      </ToolWrapper>
      {step === SubmitSuccessfulFlow.STEP && (
        <Grid className="w-page-wrapper">
          <GridItem>
            <section className={styles.footer}>{CRFooter}</section>
          </GridItem>
        </Grid>
      )}
    </>
  )
}
