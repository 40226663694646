import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import {
  AttemptedRepairFlow,
  EntitlementFlow,
  LimitedOptionsFlow,
  OnFinanceFlow,
  WhenPurchasedFlow,
} from '../../pageFlowData'
import { data } from './data'

export const WhenPurchased: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    issue: { shortDesc },
    product: { type },
    retailer: { channel, whenBought },
  } = state

  const isDigital = type === 'digital'

  const handleStepChoice = (id: string) => {
    // product type path
    if (type === 'product') {
      const changedMind = shortDesc.includes('changedMind') && shortDesc.length <= 1
      if (id !== 'moreThan6Years' && !changedMind) {
        return { step: OnFinanceFlow.STEP, progress: OnFinanceFlow.PROGRESS_VALUE }
      }
      if (id === 'last14Days' && changedMind && channel === 'online') {
        return { step: EntitlementFlow.STEP, progress: EntitlementFlow.PROGRESS_VALUE }
      }
      return { step: LimitedOptionsFlow.STEP, progress: LimitedOptionsFlow.PROGRESS_VALUE }
    }
    // digital type path
    if (id === 'lessThan6Years') {
      return { step: AttemptedRepairFlow.STEP, progress: AttemptedRepairFlow.PROGRESS_VALUE }
    }
    return { step: LimitedOptionsFlow.STEP, progress: LimitedOptionsFlow.PROGRESS_VALUE }
  }

  const handleTracking = (id: string) => {
    const trackingAnswer = `question | ${WhenPurchasedFlow.VIEW_NAME} | ${id}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (id: string) => {
    const { step, progress } = handleStepChoice(id)

    dispatch({ type: 'UPDATE_RETAILER_WHEN_BOUGHT', data: id })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleSubmit = (id: string) => {
    handleTracking(id)
    handleDispatch(id)
  }

  const { digitalButtons, productButtons, imgSrc, digitalTitle, title: productTitle } = data
  const title = isDigital ? digitalTitle : productTitle

  return (
    <div data-testid="when-purchased">
      <PillButtonPage
        buttons={isDigital ? digitalButtons : productButtons}
        defaultValue={whenBought}
        handleSubmit={handleSubmit}
        imgObj={{
          alt: title,
          src: imgSrc,
        }}
        title={title}
      />
    </div>
  )
}
