import type { FunctionComponent } from 'react'
import React from 'react'
import type { ColNumbers } from '@which/seatbelt'
import { ArticleCard, Grid, GridItem } from '@which/seatbelt'

import { MultiCardSectionWrapper } from '../MultiCardSectionWrapper'
import styles from './MultiCampaignWrapper.module.scss'

export const MultiCampaignWrapper: FunctionComponent<Props> = ({
  backgroundColour,
  linkBlocks,
  standfirst,
  testId,
  title,
}) => {
  if (!linkBlocks.length) {
    return null
  }

  const renderArticleBlocks = () => {
    return linkBlocks.map((linkblock, i) => {
      const { blockTitle, image, blockStrapline, href } = linkblock

      let startColumn: number
      switch (linkBlocks.length) {
        case 1:
          startColumn = 5
          break
        case 2:
          startColumn = i === 0 ? 3 : 3 + i * 4
          break
        case 3:
          startColumn = i === 0 ? i * 4 : i * 4 + 1
          break
        default:
          startColumn = i === 0 ? i * 4 : i * 4 + 1
      }

      return (
        <GridItem
          className={styles.multiCampaignWrapperWrapper}
          columnStart={{ medium: startColumn as ColNumbers, large: startColumn as ColNumbers }}
          key={`campaignBlock-${i}`}
          span={{ medium: 4, large: 4 }}
        >
          <ArticleCard
            clampStrapline={false}
            className={styles.multiCampaignWrapperArticle}
            href={href}
            image={image.src}
            imageAlt={image.alt}
            strapline={blockStrapline}
            title={blockTitle}
          />
        </GridItem>
      )
    })
  }

  return (
    <MultiCardSectionWrapper
      backgroundColour={backgroundColour}
      standfirst={standfirst}
      testId={testId}
      title={title}
    >
      <Grid>{renderArticleBlocks()}</Grid>
    </MultiCardSectionWrapper>
  )
}

type Props = {
  backgroundColour?: string
  linkBlocks: {
    blockTitle: string
    image: {
      src: string
      alt: string
    }
    href: string
    blockStrapline: string
  }[]
  standfirst: string
  testId: string
  title: string
}
