import type { CheckPropsAreEqualProps } from '../../types/toolTypes'

export const checkPropsAreEqual = (
  prevProps: CheckPropsAreEqualProps,
  nextProps: CheckPropsAreEqualProps
): boolean => {
  if (prevProps.moduleProps) {
    return (
      (prevProps.step === nextProps.step && prevProps.moduleProps === nextProps.moduleProps) ||
      (prevProps.step === nextProps.step && !prevProps.isForward)
    )
  }

  return prevProps.step === nextProps.step
}
