import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../../shared/state/appContext'
import globalStyles from '../../../../../shared/styles/GlobalStyles.module.scss'
import { trackAppointmentBookingSelection } from '../../../../../shared/utils/tracking/tracking'
import {
  ContactDetailsFlow,
  DescribeCaseFlow,
  HomeDetailFlow,
  HomeLocationFlow,
  HomeQueryFlow,
  LimitedOptionsFlow,
} from '../../../pageFlowData'
import { option1, option2 } from './data'
import styles from './HomeLocation.module.scss'

export const HomeLocation: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    home: { homeTopic },
    triage: { type },
  } = state

  const handleTracking = (id: string, index: number) => {
    trackAppointmentBookingSelection(type, HomeLocationFlow.VIEW_NAME, { id, index })
  }

  const chooseStep = (id: string) => {
    if (
      id === 'ireland' ||
      (homeTopic === 'leasehold' && id === 'scotland') ||
      id === 'ireland-or-overseas'
    ) {
      return LimitedOptionsFlow
    } else if (homeTopic === 'neighbour-issue') {
      return HomeQueryFlow
    } else if (homeTopic === 'leasehold') {
      return HomeDetailFlow
    } else if (homeTopic === 'issue-with-landlord') {
      return ContactDetailsFlow
    }
    return DescribeCaseFlow
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_HOME_LOCATION', data: id })
    const step = chooseStep(id)
    dispatch({ type: 'UPDATE_STEP', data: step.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: step.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string, index: number) => {
    handleTracking(id, index)
    handleDispatch(id)
  }
  const chooseData = () => {
    if (homeTopic !== 'leasehold') {
      return option2
    }
    return option1
  }
  const data = chooseData()
  const { buttons, imgObj, title } = data

  return (
    <div className={globalStyles.fullWidthButton}>
      <PillButtonPage
        buttons={buttons}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        infoBoxData={data}
        pillButtonGroupStyle={styles.homeLocationPillButtons}
        title={title}
      />
    </div>
  )
}
