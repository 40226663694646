import type { UserProps } from '../../../../shared/types/toolTypes'
import { formatTextInput } from '../../../../shared/utils/formatInput/formatTextInput'
import type { faultyGoodsToolState } from '../../state/faultyGoodsToolState'
import { data } from '../WhenPurchased/data'

export const composeEmailBody = ({ retailer, product, issue, user }: FaultyGoodsEmailBody) => {
  const { name, whenBought, orderNum } = retailer
  const { brand, model, price, description, type } = product
  const { desiredResolution, description: issueDescription } = issue
  const { firstName, lastName } = user

  const resolution =
    desiredResolution === 'repair'
      ? 'repaired'
      : desiredResolution === 'replacement'
        ? 'replaced'
        : 'refunded' // defaults to refunded

  const timeSinceBought = () => {
    const buttonInfo = data.digitalButtons
      .concat(data.productButtons)
      .find((button) => button.id === whenBought)
    if (buttonInfo) {
      if (whenBought === 'moreThan6Years' || whenBought === '30DaysOrLess') {
        return `for ${buttonInfo.label.toLowerCase()}`
      }
      if (whenBought === 'last14Days') {
        return 'for less than 14 days'
      }
      return buttonInfo.label.toLowerCase()
    }
  }

  const itemDetails =
    type !== 'product'
      ? `${description.toLowerCase()}`
      : model
        ? `${brand} ${model} ${description.toLowerCase()}`
        : `${brand} ${description.toLowerCase()}`
  return {
    label: 'Preview of complaint email',
    body: [
      { text: 'Dear Sir or Madam,<br/>\r\n' },
      { text: `REFERENCE: ${name}${orderNum ? ` ${orderNum}` : ''} - ${itemDetails}<br/>\r\n` },
      {
        text: `I purchased a ${itemDetails} from ${name}. At the point of purchase I paid £${price}.<br/>\r\n`,
      },
      { text: `${formatTextInput(issueDescription, true)}<br/>\r\n` },
      {
        text: `The Consumer Rights Act 2015 makes it an implied term of the contract I have with ${name} that goods be as described, fit for purpose and of satisfactory quality.<br/>\r\n`,
      },
      {
        text: `As you are in breach of contract and I've owned the product ${timeSinceBought()}, I am within my statutory rights to ask for it to be ${resolution}.<br/>\r\n`,
      },
      {
        text: 'I await confirmation that you will provide the remedy set out above within 14 days of the date of this letter.<br/>\r\n',
      },
      { text: 'Yours faithfully,<br/>\r\n' },
      { text: `${firstName} ${lastName}` },
    ],
  }
}

type FaultyGoodsEmailBody = {
  retailer: typeof faultyGoodsToolState.retailer
  product: typeof faultyGoodsToolState.product
  issue: typeof faultyGoodsToolState.issue
  user: UserProps
}
