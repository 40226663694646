import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { FormEntryPage } from '../../../../shared/pages/FormEntryPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryInputRef, SingleInputState } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { ItemOrderedFlow, WhichRetailerFlow } from '../../pageFlowData'
import { data } from './data'

export const WhichRetailer: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    retailer: { name },
  } = state

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })
  const whichRetailerInput = useRef<FormEntryInputRef>(null)

  const getValue = () => {
    return cleanText(whichRetailerInput?.current?.formEntryInput?.value || '')
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${WhichRetailerFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_STEP', data: ItemOrderedFlow.STEP })
    dispatch({ type: 'UPDATE_RETAILER_NAME', data: value })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: ItemOrderedFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    const value = getValue()
    const whichRetailerHasError = !isNotEmpty(value)

    setInputData({
      renderError: whichRetailerHasError,
      value: value,
    })

    if (!whichRetailerHasError) {
      handleTracking(value)
      handleDispatch(value)
    }
  }

  const { renderError, value: errorValue } = inputData
  const { imgObj, placeholderText, title } = data

  return (
    <FormEntryPage
      buttonText="Next"
      defaultValue={name}
      fieldData={{
        renderError: renderError,
        value: errorValue,
      }}
      handleSubmit={handleSubmit}
      id="which-retailer"
      imgObj={imgObj}
      isFocused={true}
      placeholderText={placeholderText}
      ref={whichRetailerInput}
      testId="which-retailer"
      title={title}
    />
  )
}
