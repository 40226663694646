// A separate object for the infoBox data is used due to the typing of the borderColour attribute
import type { InfoBoxProps } from '../../../../../shared/types/toolTypes'

export const infoBox: InfoBoxProps = {
  heading: 'Was the purchase made over 6 years ago?',
  borderColour: 'blue',
}

export const purchasedDateData = {
  infoBoxData: {
    infoBox,
    infoBoxParagraphs: [
      {
        text: 'If you made the purchase over six years ago your claim is less likely to be successful, but it may still be worth submitting one.',
      },
    ],
  },
}
