import type { FunctionComponent } from 'react'
import React, { useState } from 'react'
import { Button, List, ListItem, Typography } from '@which/seatbelt'

import classNames from 'classnames'
import DOMPurify from 'dompurify'

import { ArticleCTA } from '../../components/ArticleCTA'
import { FeedbackEntryPoint } from '../../components/FeedbackEntryPoint'
import { PageWithText } from '../../components/PageWithText'
import globalStyles from '../../styles/GlobalStyles.module.scss'
import type { ArticleCTAProps, SubmitSuccessfulProps } from '../../types/toolTypes'
import { CopiedIcon, CopyIcon } from './assets'
import styles from './SubmitSuccessfulPage.module.scss'

export const SubmitSuccessfulPage: FunctionComponent<SubmitSuccessfulProps> = ({
  articleCTA,
  children,
  feedbackBody,
  feedbackImgObj,
  feedbackTitle,
  handleSubmit,
  letterBody,
  letterTitle,
  list,
  listAppearance = 'bullet',
  standFirst,
  title,
  showFeedback,
}: SubmitSuccessfulProps) => {
  const renderListItems = () =>
    list.map((listItem: string) => (
      <ListItem className={globalStyles.bulletBlack} key={listItem}>
        <Typography dangerouslySetInnerHTML={{ __html: listItem }} textStyle="body" />
      </ListItem>
    ))

  const [copied, setCopied] = useState(false)

  const renderArticleCTA = (articleCTAProps: ArticleCTAProps) => {
    const { headline, image, link, title: articleTitle } = articleCTAProps

    return <ArticleCTA headline={headline} image={image} link={link} title={articleTitle} />
  }

  return (
    <div className={classNames(styles.submitSuccessful, globalStyles.maxWidth)}>
      <PageWithText standFirst={standFirst} title={title} />
      <Typography className={globalStyles.spacing40} tag="h3" textStyle="title-400">
        {letterTitle}
      </Typography>
      {list?.length > 0 && (
        // @ts-expect-error - needs investigation and fix
        <List appearance={listAppearance} className={globalStyles.spacing25}>
          {renderListItems()}
        </List>
      )}
      {children}
      <Button
        appearance="secondary"
        className={classNames(styles.submitSuccessfulButton, {
          [styles.submitSuccessfulButtonCopied]: copied,
        })}
        data-testid="copy-button"
        onClick={() => {
          let letterCopy = ''
          letterBody.forEach((bodyData) => {
            if (bodyData.text) {
              letterCopy += `${bodyData.text}\r\n`
            }
          })
          navigator.clipboard.writeText(
            DOMPurify.sanitize(letterCopy, { FORBID_TAGS: ['br', 'strong'] })
          )
          setCopied(true)
          setTimeout(() => {
            setCopied(false)
          }, 1000)
        }}
      >
        {copied ? <CopiedIcon /> : <CopyIcon />}
        <Typography className={styles.submitSuccessfulButtonText} tag="span">
          {copied ? 'Copied to clipboard' : 'Copy to clipboard'}
        </Typography>
      </Button>
      {showFeedback && (
        <FeedbackEntryPoint
          body={feedbackBody}
          handleSubmit={handleSubmit}
          imgObj={feedbackImgObj}
          title={feedbackTitle}
        />
      )}
      {articleCTA ? renderArticleCTA(articleCTA) : null}
    </div>
  )
}
