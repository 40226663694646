import type { FunctionComponent } from 'react'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { Grid, GridItem, Hr, Modal, Typography } from '@which/seatbelt'

import classNames from 'classnames'

import { CTASection } from '../../../../shared/components/CTASection'
import { GenericCard } from '../../../../shared/components/GenericCard'
import { MoneyHelpline } from '../../../../shared/components/MoneyHelpline'
import { MoneyNewsletterSignUpWithImage } from '../../../../shared/components/MoneyNewsletterSignUpWithImage'
import { MultiCampaignWrapper } from '../../../../shared/components/MultiCampaignWrapper'
import { MultiLinkWrapper } from '../../../../shared/components/MultiLinkWrapper'
import { Podcast } from '../../../../shared/components/Podcast'
import { SectionWrapper } from '../../../../shared/components/SectionWrapper'
import { ToolContext } from '../../../../shared/state/appContext'
import globalStyles from '../../../../shared/styles/GlobalStyles.module.scss'
import type {
  CampaignProps,
  ColProps,
  MMHCResultsEmailType,
  MoneyHealthCheckEmailResultsProps,
  MoneyHealthCheckSectionLinks,
  MoneyHealthResults,
  MoneyHelplineProps,
  PodcastProps,
  QuickTipsProps,
  ResultsSection,
} from '../../../../shared/types/toolTypes'
import { pageSubmission } from '../../../../shared/utils/EngagingNetwork/pageSubmission'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { removeSessionStorage } from '../../../../shared/utils/removeSessionStorage'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { validateEmail } from '../../../../shared/utils/validateEmail'
import { myMoneyHealthCheckStateDefault } from '../../fixtures'
import { pageLinks } from '../../pageData'
import { DownloadCard } from './components/DownloadCard'
import { EmailCard } from './components/EmailCard'
import { EmailSent } from './components/EmailSent'
import { ResultsPageModal } from './components/ResultsPageModal'
import { RetakeButton } from './components/RetakeButton'
import { composeEmailBody } from './composeEmailBody'
import { loadLinks } from './composeLinks'
import {
  houseHoldBillsStandfirst,
  householdFinancesStandfirst,
  moneyNewsLetterImage,
  nextStepsSection,
} from './data'
import styles from './ResultsPage.module.scss'
import printStyles from './ResultsPagePrint.module.scss'

export const ResultsPage: FunctionComponent<Props> = ({ pageData }) => {
  const toolContext = useContext(ToolContext)
  const { state } = toolContext
  const {
    initial,
    initial: {
      entryToolName,
      step,
      journeyFlow: { milestoneHit },
      revisitResults,
    },
    myMoneyHealthCheck: {
      energyPayment,
      broadbandContract,
      financeSituation,
      foodShop,
      payingDebts,
      propertyPayment,
      benefits,
      landingPageCompleted,
    },
    myMoneyHealthCheck,
  } = state

  const [modalOpen, setModalOpen] = useState(false)
  const [colSection, setColSection] = useState<ColProps>({
    backgroundColour: '',
    buttonLink: '',
    buttonText: '',
    imageSrc: '',
    imageAlt: '',
    standfirst: '',
    title: '',
  })
  const [campaignData, setCampaignData] = useState<CampaignProps>()
  const [quickTipsData, setQuickTipsData] = useState<QuickTipsProps>()
  const [podcastData, setPodcastData] = useState<PodcastProps>()
  const [moneyHelplineData, setMoneyHelpLineData] = useState<MoneyHelplineProps>()
  const [billsSectionData, setBillsSectionData] = useState<ResultsSection>()
  const [financeSectionData, setFinanceSectionData] = useState<ResultsSection>()
  const [billsSectionLinks, setBillsSectionLinks] = useState<MoneyHealthCheckSectionLinks>()
  const [financeSectionLinks, setFinanceSectionLinks] = useState<MoneyHealthCheckSectionLinks>()
  const [emailResultsState, setEmailResultsState] = useState<MoneyHealthCheckEmailResultsProps>({
    'firstName-input': { value: '', hasError: false, errorMessage: '' },
    'emailAddress-input': {
      value: '',
      hasError: false,
      errorMessage: '',
    },
    singleConsentOptIn: false,
    emailSent: false,
  })
  const [showEmailTerms, setShowEmailTerms] = useState<boolean>(false)
  const [errorCount, setErrorCount] = useState(0)

  const firstNameRef = useRef<HTMLInputElement>(null)
  const emailAddressRef = useRef<HTMLInputElement>(null)

  const billsSectionTitle = billsSectionData?.customData.data.title || ''
  const billsSectionStandFirst = billsSectionData?.customData.data.standfirst || ''
  const financeSectionTitle = financeSectionData?.customData.data.title || ''
  const financeSectionStandFirst = financeSectionData?.customData.data.standfirst || ''

  const [headerStandfirst, setHeaderStandfirst] = useState('')
  const headerTitle = pageData?.header?.title

  const campaignSectionTitle = campaignData?.customData.data.title || ''
  const campaignSectionStandfirst = campaignData?.customData.data.standfirst || ''
  const campaignBackgroundColour = campaignData?.customData.data.backgroundColour
  const campaigns =
    campaignData?.components.collections.map(
      ({
        data: {
          body,
          img: { alt, src },
          linkUrl,
          title,
        },
      }) => ({
        href: linkUrl,
        image: {
          src: src,
          alt: alt,
        },
        blockTitle: title,
        blockStrapline: body,
      })
    ) || []

  const quickTipsSectionTitle = quickTipsData?.customData.data.title || ''
  const quickTips =
    quickTipsData?.components.collections.map(({ data: { body, icon, title } }) => ({
      cardBody: body,
      cardIcon: icon,
      cardTitle: title,
    })) || []

  const podcastProps = {
    title: podcastData?.title || '',
    iframeContent: podcastData?.podcastUrl
      ? `<div class=${classNames(styles.podcast, styles.podcastWrapper)}><iframe class=${
          styles.podcast
        } frameborder="no" scrolling="no" seamless src="${podcastData?.podcastUrl}"></iframe></div>`
      : '',
    body: podcastData?.standfirst || '',
    button: {
      href: podcastData?.buttonLink || '',
      text: podcastData?.buttonText || '',
    },
  }

  const history = useHistory()

  useEffect(() => {
    if (milestoneHit.includes('journey-complete')) {
      const problemResEvent = window.dataLayer.find(
        ({ eventCategory }) => eventCategory === 'problem resolution tools'
      )

      if (revisitResults && !problemResEvent?.eventLabel?.match('milestone | revisit')) {
        generalDataLayer(formatToolName(entryToolName), `milestone | revisit | ${financeSituation}`)
      }

      const revisitState = { initial: { ...initial, revisitResults: true } }
      localStorage.setItem('MMHC', JSON.stringify({ ...state, ...revisitState }))
      removeSessionStorage({ sessionStorageName: `${entryToolName}ToolState` })
    }
  }, [
    entryToolName,
    financeSituation,
    initial,
    milestoneHit,
    myMoneyHealthCheck,
    revisitResults,
    state,
    step,
  ])

  const openModalHandler = () => {
    setModalOpen(true)
  }

  const closeModalHandler = () => {
    setModalOpen(false)
  }

  const printModalHandler = () => {
    setModalOpen(false)
    /* istanbul ignore next */
    window.print()
  }

  const restartClickHandler = () => {
    localStorage.removeItem('MMHC')
    removeSessionStorage({ sessionStorageName: `${entryToolName}ToolState` })
    history.push('/tool/my-money-health-check', { ...myMoneyHealthCheckStateDefault })
  }

  useEffect(() => {
    if (pageData && pageData.header) {
      const { redRouteIntro, amberRouteIntro, greenRouteIntro } = pageData.header

      switch (financeSituation) {
        case 'red':
          setHeaderStandfirst(redRouteIntro)
          break
        case 'amber':
          setHeaderStandfirst(amberRouteIntro)
          break
        case 'green':
          setHeaderStandfirst(greenRouteIntro)
          break
      }
    }
    if (pageData && pageData.contentItems) {
      pageData.contentItems.map(({ type, data }) => {
        switch (type) {
          case 'COLSection':
            setColSection(data as ColProps)
            break
          case 'MMHC Results - Changes needed':
            setCampaignData(data as CampaignProps)
            break
          case 'MMHC Results - Quick Tips':
            setQuickTipsData(data as QuickTipsProps)
            break
          case 'Podcast':
            setPodcastData(data as PodcastProps)
            break
          case 'MMHC Results - Take action on your bills':
            setBillsSectionData(data as ResultsSection)

            const billsSectionResults = loadLinks(data as ResultsSection, {
              financeSituation,
              propertyPayment,
              benefits,
              energyPayment,
              foodShop,
              broadbandContract,
              payingDebts,
              landingPageCompleted,
            })

            setBillsSectionLinks(billsSectionResults)
            break
          case 'MMHC Results - Take action on your finances':
            setFinanceSectionData(data as ResultsSection)

            const financesSectionResults = loadLinks(data as ResultsSection, {
              financeSituation,
              propertyPayment,
              benefits,
              energyPayment,
              foodShop,
              broadbandContract,
              payingDebts,
              landingPageCompleted,
            })

            setFinanceSectionLinks(financesSectionResults)
            break
          case 'Money Helpline':
            setMoneyHelpLineData(data as MoneyHelplineProps)
            break
        }
      })
    }
  }, [
    pageData,
    financeSituation,
    propertyPayment,
    benefits,
    energyPayment,
    foodShop,
    broadbandContract,
    payingDebts,
    landingPageCompleted,
  ])

  const handleShowTerms = (e) => {
    e.preventDefault()
    setShowEmailTerms(!showEmailTerms)
  }

  const handleInputValueChange = ({ target }) => {
    const { name, value } = target
    setEmailResultsState({
      ...emailResultsState,
      [name]: { ...emailResultsState[name], value: value },
    })
  }

  const handlesingleConsentOptIn = () => {
    setEmailResultsState({
      ...emailResultsState,
      singleConsentOptIn: !emailResultsState.singleConsentOptIn,
    })
  }

  const validateInputs = () => {
    const firstNameValue: string = firstNameRef.current?.value || ''
    const firstNameHasError = !isNotEmpty(firstNameValue) || !/^[a-z '-]+$/i.test(firstNameValue)

    const emailValue: string = emailAddressRef.current?.value || ''
    const emailHasError = !isNotEmpty(emailValue) || !validateEmail(emailValue)

    const data = {
      ...emailResultsState,
      'firstName-input': {
        hasError: firstNameHasError,
        value: firstNameValue,
        errorMessage: 'First name error',
      },
      'emailAddress-input': {
        hasError: emailHasError,
        value: emailValue,
        errorMessage: 'Email error',
      },
    }

    setEmailResultsState(data)

    const isValid = firstNameHasError || emailHasError ? false : true

    return { inputData: data, isValid: isValid }
  }

  const buildUserDetails = (data: UserDataType) => {
    const userData = {
      firstName: data['firstName-input'].value,
      emailAddress: data['emailAddress-input'].value,
    }

    return userData
  }

  const buildLinksData = (userData: { firstName: string; emailAddress: string }) => {
    const emailData = composeEmailBody({
      billsSectionTitle,
      billsSectionLinks,
      financeSectionTitle,
      financeSectionLinks,
    } as MMHCResultsEmailType)

    const { singleConsentOptIn } = emailResultsState

    const emailPageData = {
      emailData,
      optins: { singleConsentOptIn },
      user: { ...userData },
    }

    return emailPageData
  }

  const sendEmail = () => {
    setEmailResultsState({ ...emailResultsState, emailSent: true })
    generalDataLayer(formatToolName(entryToolName), 'milestone | email | sent')
  }

  const handleSendResults = async () => {
    const submissionFormId = 125550
    const validationData = validateInputs()

    if (validationData.isValid) {
      const userData = buildUserDetails(validationData.inputData)
      const linksData = buildLinksData(userData) //compose email here
      const structuredData = pageLinks(linksData)

      const ENResponse = await pageSubmission(structuredData, submissionFormId)

      if (ENResponse && (ENResponse.ok || ENResponse.status === 'SUCCESS')) {
        sendEmail()
      } else {
        setErrorCount(errorCount + 1)
      }
    }
  }

  const {
    title: nextStepsTitle,
    email: { buttonText: emailButtonText, title: emailTitle, unsubText },
    download: {
      buttonText: downloadButtonText,
      image: downloadImage,
      title: downloadTitle,
      standfirst: downloadStandfirst,
    },
    retake: { buttonText: retakeButtonText },
  } = nextStepsSection

  return (
    <div className={printStyles.resultsPage} data-testid="submit-successful">
      {modalOpen && (
        <Modal closeModal={closeModalHandler}>
          <ResultsPageModal printModalHandler={printModalHandler} />
        </Modal>
      )}
      <SectionWrapper>
        <section className={styles.header}>
          <Typography tag="h1" textStyle="title-650">
            {headerTitle}
          </Typography>
          <Typography data-testid="standfirst" tag="h2" textStyle="standfirst">
            {headerStandfirst}
          </Typography>
        </section>
      </SectionWrapper>
      <main>
        {billsSectionLinks && financeSectionLinks && (
          <div className={classNames(printStyles.multiLinkWrapper, styles.linkSectionContainer)}>
            <MultiLinkWrapper
              className={styles.linkSectionContainerLinks}
              key={'household-bills'}
              linkBlocks={[
                billsSectionLinks.energy,
                billsSectionLinks.shopping,
                billsSectionLinks.broadband,
              ]}
              standfirst={houseHoldBillsStandfirst(billsSectionStandFirst, {
                energyPayment,
                broadbandContract,
                foodShop,
              })}
              testId="my-money-health-check-bills-section"
              title={billsSectionTitle}
              toolName={entryToolName}
            />

            <MultiLinkWrapper
              className={styles.linkSectionContainerLinks}
              key={'finance-bills'}
              linkBlocks={[financeSectionLinks.homeOwnership, financeSectionLinks.finance]}
              standfirst={householdFinancesStandfirst(financeSectionStandFirst, {
                propertyPayment,
                payingDebts,
              })}
              testId="my-money-health-check-finance-section"
              title={financeSectionTitle}
              toolName={entryToolName}
            />
          </div>
        )}

        <SectionWrapper className={styles.fullWidth}>
          <Hr className={styles.divider} />
        </SectionWrapper>

        {quickTips.length > 0 && (
          <SectionWrapper className={styles.quickTips}>
            <Typography
              className={classNames(styles.header, styles.headerFullWidth)}
              tag="h3"
              textStyle="title-600"
            >
              {quickTipsSectionTitle}
            </Typography>

            <div className={styles.GenericCardBlock}>
              {quickTips.map(({ cardBody, cardIcon, cardTitle }, i) => {
                return (
                  <GenericCard
                    body={cardBody}
                    icon={cardIcon}
                    key={`quick-tip-${i}`}
                    title={cardTitle}
                  />
                )
              })}
            </div>
          </SectionWrapper>
        )}
        <div className={classNames(styles.nextSteps, globalStyles.noPrint)}>
          <SectionWrapper className={classNames(styles.fullWidth)}>
            <Typography
              className={classNames(styles.header, styles.headerFullWidth)}
              tag="h3"
              textStyle="title-600"
            >
              {nextStepsTitle}
            </Typography>
            <Grid includeGutters={false}>
              <GridItem columnStart={{ medium: 2, large: 2 }} span={{ medium: 5, large: 5 }}>
                {!emailResultsState.emailSent && (
                  <EmailCard
                    emailAddress={{
                      errorMessage: emailResultsState['emailAddress-input'].errorMessage,
                      hasError: emailResultsState['emailAddress-input'].hasError,
                      ref: emailAddressRef,
                      value: emailResultsState['emailAddress-input'].value,
                    }}
                    emailButtonText={emailButtonText}
                    emailTitle={emailTitle}
                    errorCount={errorCount}
                    firstName={{
                      errorMessage: emailResultsState['firstName-input'].errorMessage,
                      hasError: emailResultsState['firstName-input'].hasError,
                      ref: firstNameRef,
                      value: emailResultsState['firstName-input'].value,
                    }}
                    handleInputValueChange={handleInputValueChange}
                    handleSendResults={handleSendResults}
                    handleShowTerms={handleShowTerms}
                    handlesingleConsentOptIn={handlesingleConsentOptIn}
                    showEmailTerms={showEmailTerms}
                    singleConsentOptIn={emailResultsState.singleConsentOptIn}
                    unsubText={unsubText}
                  />
                )}

                {emailResultsState.emailSent && (
                  <EmailSent
                    emailAddress={emailResultsState['emailAddress-input'].value}
                    firstName={emailResultsState['firstName-input'].value}
                  />
                )}
              </GridItem>

              <DownloadCard
                downloadButtonText={downloadButtonText}
                downloadImage={downloadImage}
                downloadStandfirst={downloadStandfirst}
                downloadTitle={downloadTitle}
                openModalHandler={openModalHandler}
              />

              <RetakeButton
                restartClickHandler={restartClickHandler}
                retakeButtonText={retakeButtonText}
              />
            </Grid>
          </SectionWrapper>
        </div>

        <CTASection
          backgroundColour={colSection.backgroundColour}
          buttonLink={colSection.buttonLink}
          buttonText={colSection.buttonText}
          imageAlt={colSection.imageAlt}
          imageSrc={colSection.imageSrc}
          info={colSection.standfirst}
          noBottomMargin
          title={colSection.title}
        />

        <div className={globalStyles.noPrint}>
          {podcastProps.iframeContent && <Podcast {...podcastProps} />}

          {moneyHelplineData && <MoneyHelpline {...moneyHelplineData} />}

          <MultiCampaignWrapper
            backgroundColour={campaignBackgroundColour}
            linkBlocks={campaigns}
            standfirst={campaignSectionStandfirst}
            testId="my-money-health-check-campaigns-section"
            title={campaignSectionTitle}
          />

          <MoneyNewsletterSignUpWithImage image={moneyNewsLetterImage} source={'money'} />
        </div>
      </main>
    </div>
  )
}

type Props = {
  pageData: MoneyHealthResults
}

type UserDataType = {
  'emailAddress-input': {
    errorMessage: string
    hasError: boolean
    value: string
  }
  'firstName-input': {
    errorMessage: string
    hasError: boolean
    value: string
  }
  singleConsentOptIn: boolean
}
