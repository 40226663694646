import type { FunctionComponent } from 'react'
import React from 'react'
import { Button as SBButton } from '@which/seatbelt'

import classNames from 'classnames'

import type { NextButtonProps } from '../../types/toolTypes'
import styles from './NextButton.module.scss'

export const NextButton: FunctionComponent<NextButtonProps> = ({
  buttonText,
  onClick,
  enableSpinner = false,
  disabled = false,
  moneySize = false,
}) => (
  <div className={classNames(styles.nextButton, { [styles.nextButtonMoney]: moneySize })}>
    <SBButton
      data-testid="next-button"
      disabled={disabled}
      enableSpinner={enableSpinner}
      onClick={onClick}
    >
      {buttonText}
    </SBButton>
  </div>
)
