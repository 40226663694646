const shared = {
  imgObj: {
    alt: 'two people holding documents',
    src: 'https://media.product.which.co.uk/prod/images/original/8f95d1a7f0dc-cp-step9.jpg',
  },
}

export const willsEstateData = {
  option1: {
    buttons: [
      {
        label: 'My own estate',
        id: 'own-estate',
      },
      {
        label: "Somebody else's estate",
        id: 'some-elses-estate',
      },
    ],
    title: "Are you contacting us about your will or estate or somebody else's",
    ...shared,
  },
  option2: {
    buttons: [
      {
        label: 'My own',
        id: 'my-own',
      },
      {
        label: "Somebody else's",
        id: 'somebody-else',
      },
    ],
    title: "Are you contacting us about your own power of attorney or somebody else's",
    ...shared,
  },
}
