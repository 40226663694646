export const employmentTopicData = {
  buttons: [
    {
      label: 'Employment contract/rights',
      id: 'employment-contract-rights',
      tooltip:
        'Please select this option for queries relating to leave, part-time workers, flexible working, terms and conditions, recruitment and references etc',
    },
    {
      label: 'Grievances and disciplinaries',
      id: 'grievances-and-disciplinaries',
    },
    {
      label: 'Discrimination',
      id: 'discrimination',
    },
    {
      label: 'Dismissal & Redundancy',
      id: 'dismissal-and-redundancy',
    },
    {
      label: 'Deduction of wages or payment disputes',
      id: 'deduction-of-wages',
    },
    {
      label: 'Employment Tribunal procedure',
      id: 'employment-tribunal',
    },
    {
      label: 'Appeals to the Employment Appeals Tribunal',
      id: 'appeals-to-employment',
    },
    { label: 'Settlement Agreements', id: 'settlement' },
    { label: 'Capability and performance management', id: 'capability' },
    {
      label: 'Employee benefits or pension/tax/shares queries',
      id: 'pension-tax-shares-query',
    },
    {
      label: 'Police Officers/Armed Forces Queries',
      id: 'police-armed',
      tooltip:
        'We cannot advise police offers or members of the armed forces. If you are a civilian employee of either, please select one of the alternative options above based on the nature of your query.',
    },
    {
      label: 'Self-employment or business queries',
      id: 'self-employment-business',
    },
    {
      label: 'Civil court proceedings',
      id: 'civil-court',
    },
  ],
  title: 'Please select which of the following is the closest match to your query?',
  imgObj: {
    alt: 'Two people standing up talking',
    src: 'https://media.product.which.co.uk/prod/images/original/gm-e1fc82ae-b07d-49de-92bf-ba96507ba5f9-two-people-talking.jpg',
  },
}
