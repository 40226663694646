export const buttonData = [
  { label: 'Telephone call', id: 'telephone', isFocused: true },
  { label: 'Online (websites/adverts)', id: 'online' },
  { label: 'Email', id: 'email' },
  {
    label: 'Text message',
    id: 'text-message',
  },
  {
    label: 'WhatsApp',
    id: 'whatsapp',
  },
  {
    label: 'The scammer came to my house',
    id: 'came-to-house',
  },
  { label: 'Letter or flyer', id: 'letter' },
  { label: 'Through social media', id: 'social-media' },
  { label: 'Other', id: 'other' },
]
