import type { FunctionComponent } from 'react'
import React, { useContext, useEffect, useState } from 'react'

import { ArticleCTAPage } from '../../../../shared/pages/ArticleCTAPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { environment } from '../../../../shared/utils/fetchEnv/env'
import { salesforceMapper } from '../AppointmentChoice/salesforceMapper'
import { limitedOptionsData } from './data'

export const LimitedOptions: FunctionComponent = () => {
  const { state } = useContext(ToolContext)
  const {
    cars: { carsQuery, carsTopic },
    employment: { employmentTopic },
    other: { otherTopic },
    wills: { willsEstate, willsTopic, willsQuery, willsDetail },
    buying: { buyingQuery, buyingTopic },
    holiday: { holidayTopic, holidayQuery },
    home: { homeTopic, homeQuery, homeLocation, homeDetail },
    money: { moneyQuery, moneyTopic },
    legal: { legalTopic },
    initial: { user },
    tech: { techTopic, techQuery, techDevice, techSystem },
    entitlement: { check },
    triage,
  } = state

  const [hasSubmitted, setHasSubmitted] = useState(false)
  const { solicitor, type } = triage
  const chooseDataSet = () => {
    if (
      (carsQuery &&
        carsQuery !== 'toll-road' &&
        carsQuery !== 'train-station-fine' &&
        carsQuery !== 'tfl-penalty') ||
      (otherTopic && otherTopic !== 'other') ||
      willsTopic === 'northern-ireland-or-overseas' ||
      (buyingTopic &&
        buyingTopic !== 'financial-matters' &&
        buyingTopic !== 'uk-insurance' &&
        !buyingQuery) ||
      willsDetail === 'neither' ||
      holidayTopic ||
      (homeTopic && homeTopic !== 'leasehold' && homeTopic !== 'landlords-hmo')
    ) {
      return limitedOptionsData.option2
    } else if (
      carsQuery === 'toll-road' ||
      carsQuery === 'train-station-fine' ||
      carsQuery === 'tfl-penalty' ||
      carsTopic === 'dvla' ||
      carsTopic === 'other' ||
      otherTopic === 'other' ||
      (willsEstate && willsEstate !== 'some-elses-estate') ||
      willsQuery === 'other' ||
      moneyQuery ||
      homeTopic === 'landlords-hmo'
    ) {
      return limitedOptionsData.option3
    } else if (
      carsTopic === 'motor-insurance' ||
      employmentTopic === 'pension-tax-shares-query' ||
      buyingTopic === 'financial-matters' ||
      buyingTopic === 'uk-insurance' ||
      buyingQuery
    ) {
      return limitedOptionsData.option4
    } else if (
      homeTopic === 'leasehold' &&
      homeLocation !== 'scotland' &&
      homeLocation !== 'ireland'
    ) {
      return limitedOptionsData.option5
    } else if (homeTopic === 'leasehold' || homeTopic === 'land-registry' || employmentTopic) {
      return limitedOptionsData.option2
    } else if (techTopic) {
      return limitedOptionsData.option6
    } else if (willsEstate === 'some-elses-estate') {
      return limitedOptionsData.option7
    } else if ((!legalTopic && !techTopic && !solicitor) || !check) {
      const getReplacementData = () => {
        switch (type) {
          case 'legal':
            return {
              link: 'https://legalservice.which.co.uk/',
              expert: 'specialist lawyers',
              service: 'Legal service',
            }
          case 'tech':
            return {
              link: 'https://signup.which.co.uk/wlp-tech-support',
              expert: 'experts',
              service: 'Tech Support',
            }
          case 'money':
            return {
              link: 'https://signup.which.co.uk/wlp-money',
              expert: 'experts',
              service: 'Money Helpline',
            }
          default:
            return {
              link: 'https://www.which.co.uk/help/your-which-membership-a8M9A8b753w3',
              expert: 'experts',
              service: '',
            }
        }
      }
      const limitedData = JSON.parse(JSON.stringify(limitedOptionsData.option8))
      const replaceData = getReplacementData()
      limitedData.title = limitedData.title.replaceAll('REPLACESERVICE', replaceData.service)
      limitedData.bodyCopy[0] = limitedData.bodyCopy[0]
        .replaceAll('REPLACETYPE', replaceData.service)
        .replaceAll('REPLACELINK', replaceData.link)
        .replaceAll('REPLACEEXPERT', replaceData.expert)
      return limitedData
    }
    return limitedOptionsData.option1
  }
  const { bodyCopy, standFirst, title } = chooseDataSet()

  useEffect(() => {
    const submitToSalesForce = () => {
      if (!hasSubmitted && techTopic) {
        const body = salesforceMapper({
          cars: { carsQuery, carsTopic },
          employment: { employmentTopic },
          other: { otherTopic },
          wills: { willsTopic, willsQuery },
          buying: { buyingQuery, buyingTopic },
          holiday: { holidayTopic, holidayQuery },
          home: { homeTopic, homeQuery, homeLocation, homeDetail },
          money: { moneyQuery, moneyTopic },
          legal: { legalTopic },
          tech: { techTopic, techQuery, techDevice, techSystem },
          initial: { user },
          triage,
          status: 'Closed',
        })

        const mapUrl = () => {
          switch (type) {
            case 'legal':
              return 'legal-appointment'
            case 'tech':
              return 'tech-support-appointment'
            case 'money':
              return 'money-appointment'
          }
        }

        fetch(
          `https://${environment()}-appointment-booking-case-service.api.product.which.co.uk/case-service/${mapUrl()}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
          }
        ).catch()
        setHasSubmitted(true)
      }
    }
    submitToSalesForce()
  }, [
    buyingQuery,
    buyingTopic,
    carsQuery,
    carsTopic,
    employmentTopic,
    hasSubmitted,
    holidayQuery,
    holidayTopic,
    homeDetail,
    homeLocation,
    homeQuery,
    homeTopic,
    legalTopic,
    moneyQuery,
    moneyTopic,
    otherTopic,
    techDevice,
    techQuery,
    techSystem,
    techTopic,
    triage,
    type,
    user,
    willsQuery,
    willsTopic,
  ])

  return (
    <div data-testid="limited-options">
      <ArticleCTAPage bodyCopy={bodyCopy} standFirst={standFirst} title={title} />
    </div>
  )
}
