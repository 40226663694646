import type { Reducer } from 'react'

import { initialSliceReducer } from '../../../shared/state/reducers'
import type { Action, State } from '../../../shared/state/toolStateTypes'
import { combineReducers } from '../../../shared/utils/combineReducers'

export const myMoneyHealthCheckSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_FINANCE_SITUATION':
      return {
        ...state,
        financeSituation: data,
      }

    case 'UPDATE_PROPERTY_PAYMENT':
      return {
        ...state,
        propertyPayment: data,
      }

    case 'UPDATE_BENEFITS':
      return {
        ...state,
        benefits: data,
      }

    case 'UPDATE_ENERGY_PAYMENT':
      return {
        ...state,
        energyPayment: data,
      }

    case 'UPDATE_FOOD_SHOP':
      return {
        ...state,
        foodShop: data,
      }

    case 'UPDATE_BROADBAND_CONTRACT':
      return {
        ...state,
        broadbandContract: data,
      }

    case 'UPDATE_PAYING_DEBTS':
      return {
        ...state,
        payingDebts: data,
      }

    case 'UPDATE_LANDING_PAGE_COMPLETED':
      return {
        ...state,
        landingPageCompleted: data,
      }

    case 'UPDATE_ALL_ANSWERS':
      return {
        ...data,
      }

    default:
      return state
  }
}

export const myMoneyHealthCheckReducer = combineReducers({
  initial: initialSliceReducer,
  myMoneyHealthCheck: myMoneyHealthCheckSliceReducer,
})
