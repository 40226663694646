import type { FunctionComponent } from 'react'
import React, { useContext, useState } from 'react'

import { RadioGroupPage } from '../../../../shared/pages/RadioGroupPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { scamSourceDataObj } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import {
  BeenAffectedFlow,
  NameOfScammerFlow,
  TypeOfScammerFlow,
  WhatHappenedWithSocialFlow,
  WhoWasScammerFlow,
} from '../../pageFlowData'
import { getScamSourceData } from './data'

export const WhoWasScammer: FunctionComponent = () => {
  const [isInputValid, setInputValid] = useState(true)
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    scam: { comeAcrossScam, whoWasScammer },
  } = state
  const [radioSelected, setRadioSelected] = useState(whoWasScammer)

  const scamSourceData: scamSourceDataObj = getScamSourceData(comeAcrossScam)

  const handleChange = (id: string) => {
    setRadioSelected(id)
  }

  const handleStepChoice = (id: string) => {
    if (
      id === 'other' &&
      comeAcrossScam !== 'email' &&
      comeAcrossScam !== 'text-message' &&
      comeAcrossScam !== 'whatsapp' &&
      comeAcrossScam !== 'letter'
    ) {
      return { progress: TypeOfScammerFlow.PROGRESS_VALUE, step: TypeOfScammerFlow.STEP }
    } else if (
      (id !== 'other' && comeAcrossScam === 'came-to-house') ||
      (id !== 'other' && comeAcrossScam === 'letter')
    ) {
      return { progress: BeenAffectedFlow.PROGRESS_VALUE, step: BeenAffectedFlow.STEP }
    } else if (id !== 'other' && comeAcrossScam === 'social-media') {
      return {
        progress: WhatHappenedWithSocialFlow.PROGRESS_VALUE,
        step: WhatHappenedWithSocialFlow.STEP,
      }
    } else {
      return { progress: NameOfScammerFlow.PROGRESS_VALUE, step: NameOfScammerFlow.STEP }
    }
  }

  const handleDispatch = (id: string) => {
    const { progress, step } = handleStepChoice(id)

    dispatch({ type: 'UPDATE_WHO_WAS_SCAMMER', data: id })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
    dispatch({ type: 'UPDATE_STEP', data: step })
  }

  const handleTracking = (id: string) => {
    const trackingAnswer = `question | ${WhoWasScammerFlow.VIEW_NAME} | ${id}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = () => {
    if (radioSelected) {
      setInputValid(true)
      handleTracking(radioSelected)
      handleDispatch(radioSelected)
    }
    setInputValid(false)
  }

  const { buttonData, imgObj, title } = scamSourceData

  return (
    <RadioGroupPage
      buttonText="Next"
      buttons={buttonData}
      fullWidthContainer={comeAcrossScam !== 'social-media'}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      imgObj={imgObj}
      isInputValid={isInputValid}
      stateValue={radioSelected}
      title={title}
    />
  )
}
