export const data = {
  buttons: [
    {
      label: 'Prepayment meter',
      id: 'prepayment-meter',
    },
    {
      label: 'Direct debit',
      id: 'direct-debit',
    },
    {
      label: 'I pay for what I use monthly or quarterly',
      id: 'pay-monthly-quarterly',
    },
  ],
  title: 'What payment method do you use for your energy bills?',
  imgObj: {
    alt: 'A person using a mobile phone',
    src: 'https://media.product.which.co.uk/prod/images/original/0cc1ff71622b-energy.jpg',
  },
}
