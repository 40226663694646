import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { Button, Typography } from '@which/seatbelt'

import classNames from 'classnames'

import { Boxed } from '../../components/Boxed'
import { ErrorMessage } from '../../components/ErrorMessage'
import { FormEntryItem } from '../../components/FormEntryItem'
import { RadioGroup } from '../../components/RadioGroup'
import type {
  ToolFeedbackAudienceModuleProps,
  ToolFeedbackQuestionProps,
} from '../../types/toolTypes'
import styles from './ToolFeedbackAudienceModule.module.scss'

export const ToolFeedbackAudienceModule = forwardRef(
  (
    {
      buttonText,
      errorCount,
      errorMessage = 'Something went wrong, please try again',
      errorData,
      handleRadioChange,
      handleSkip,
      handleSubmit,
      questions,
      stateValue,
      standFirst,
      title,
    }: ToolFeedbackAudienceModuleProps,
    ref
  ) => {
    const FormEntryInputRef = useRef<HTMLInputElement>(null)

    const renderQuestion = (question: ToolFeedbackQuestionProps) => {
      switch (question.typeName) {
        case 'radio':
          return (
            <Boxed className={styles.toolFeedbackAudienceModuleQuestion} key={question.title}>
              <Typography
                className={styles.toolFeedbackAudienceModuleQuestionTitle}
                tag="h3"
                textStyle="title-400"
              >
                {question.title}
                {question.optional && (
                  <span
                    className={styles.toolFeedbackAudienceModuleOptional}
                    data-testid="radio-optional"
                  >
                    {' '}
                    (optional)
                  </span>
                )}
              </Typography>
              <RadioGroup
                buttons={question.buttons}
                handleChange={handleRadioChange}
                id={question.id}
                isFocused={question.isFocused}
                isSubmitValid={question.isSubmitValid}
                stateValue={stateValue}
                title={question.title}
              />
            </Boxed>
          )
        case 'formEntry':
          return (
            <Boxed className={styles.toolFeedbackAudienceModuleQuestion} key={question.displayText}>
              <div
                className={classNames(styles.toolFeedbackAudienceModuleInputWrapper, {
                  [styles.toolFeedbackAudienceModuleInputWrapperError]: errorData?.renderError,
                })}
              >
                <FormEntryItem
                  alignEntryItemLeft
                  displayText={question.displayText}
                  fieldData={errorData}
                  id={question.id}
                  isFullWidth
                  margin={false}
                  optional={question.optional}
                  ref={FormEntryInputRef}
                  srLabel={question.srLabel}
                  stateValue={question.inputStateValue}
                  type={question.type}
                />
              </div>
            </Boxed>
          )
      }
    }

    useImperativeHandle(ref, () => ({
      formEntryInput: FormEntryInputRef.current,
    }))

    return (
      <div>
        <Typography className="sb-text-align-center" tag="h1" textStyle="title-650">
          {title}
        </Typography>
        <div className={styles.toolFeedbackAudienceModuleWrapper}>
          <Typography
            className={styles.toolFeedbackAudienceModuleStandFirst}
            tag="p"
            textStyle="standfirst"
          >
            {standFirst}
          </Typography>
          {questions.map((question) => renderQuestion(question))}
          <div
            className={classNames(
              styles.toolFeedbackAudienceModuleButtonWrapper,
              'sb-text-align-center'
            )}
          >
            <Button
              className={styles.toolFeedbackAudienceModuleNext}
              data-testid="next-button"
              onClick={handleSubmit}
            >
              {buttonText}
            </Button>
            <Button
              appearance="secondary"
              className={styles.toolFeedbackAudienceModuleSkip}
              data-testid="skip-link"
              onClick={handleSkip}
            >
              Skip questions
            </Button>
          </div>
          {errorCount > 0 && (
            <span className="sb-text-align-center">
              <ErrorMessage errorText={errorMessage} />
            </span>
          )}
        </div>
      </div>
    )
  }
)
