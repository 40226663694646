import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../../shared/state/appContext'
import globalStyles from '../../../../../shared/styles/GlobalStyles.module.scss'
import { trackAppointmentBookingSelection } from '../../../../../shared/utils/tracking/tracking'
import {
  ContactDetailsFlow,
  DescribeCaseFlow,
  LimitedOptionsFlow,
  WillsDetailFlow,
} from '../../../pageFlowData'
import { willsEstateData } from './data'

export const WillsDetail: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    wills: { willsEstate },
    triage: { type },
  } = state

  const handleTracking = (id: string, index: number) => {
    trackAppointmentBookingSelection(type, WillsDetailFlow.VIEW_NAME, { id, index })
  }

  const chooseStep = (id: string) => {
    if (id === 'neither') {
      return LimitedOptionsFlow
    } else if (willsEstate) {
      return DescribeCaseFlow
    }
    return ContactDetailsFlow
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_WILLS_DETAIL', data: id })
    const step = chooseStep(id)
    dispatch({ type: 'UPDATE_STEP', data: step.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: step.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string, index: number) => {
    handleTracking(id, index)
    handleDispatch(id)
  }

  const { buttons, imgObj, title } = willsEstateData

  return (
    <div className={globalStyles.fullWidthButton}>
      <PillButtonPage buttons={buttons} handleSubmit={handleSubmit} imgObj={imgObj} title={title} />
    </div>
  )
}
