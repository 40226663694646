import type { Reducer } from 'react'

import { initialSliceReducer, toolFeedbackSliceReducer } from '../../../shared/state/reducers'
import type { Action, State } from '../../../shared/state/toolStateTypes'
import { combineReducers } from '../../../shared/utils/combineReducers'

export const flightSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_UK_OR_EU_AIRLINE':
      return {
        ...state,
        ukOrEuAirline: data,
      }
    case 'UPDATE_UK_AIRPORT':
      return {
        ...state,
        arriveDepartUK: data,
      }
    case 'UPDATE_AIRPORTS':
      return {
        ...state,
        airports: data,
      }
    case 'UPDATE_DELAYED_CANCELLED':
      return {
        ...state,
        delayedCancelled: data,
      }
    case 'UPDATE_FLIGHT_NUMBER':
      return {
        ...state,
        flightNumber: data,
      }
    case 'UPDATE_FLIGHT_DATE':
      return {
        ...state,
        date: data,
      }
    case 'UPDATE_AIRLINE':
      return {
        ...state,
        airline: data,
      }
    case 'UPDATE_SCHEDULED_FLIGHT_TIMES':
      return {
        ...state,
        scheduledTimes: data,
      }
    case 'UPDATE_FLIGHT_DISTANCE':
      return {
        ...state,
        distance: data,
      }
    default:
      return state
  }
}

export const claimSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_TIME_BEFORE_CANCELLED':
      return {
        ...state,
        timeBeforeCancelled: data,
      }
    case 'UPDATE_EARLIER_LATER_ORIGINAL_FLIGHT':
      return {
        ...state,
        earlierLaterOriginalFlight: data,
      }

    case 'UPDATE_HOURS_DIFFERENCE':
      return {
        ...state,
        hoursDifference: data,
      }
    case 'UPDATE_NUM_PEOPLE_CLAIMING_FOR':
      return {
        ...state,
        numPeopleClaimingFor: data,
      }
    case 'UPDATE_REASON_GIVEN':
      return {
        ...state,
        reasonGiven: data,
      }
    case 'UPDATE_AMOUNT_ENTITLED':
      return {
        ...state,
        amountEntitled: data,
      }
    default:
      return state
  }
}

export const flightDelayCancellationReducer = combineReducers({
  initial: initialSliceReducer,
  flight: flightSliceReducer,
  claim: claimSliceReducer,
  toolFeedback: toolFeedbackSliceReducer,
})
