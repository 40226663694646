import { isNotEmpty } from '../isNotEmpty'
import { validateEmail } from '../validateEmail'
import { validatePostcode } from '../validatePostcode'

export const scrollToInvalidInput = (currentInputsList: HTMLInputElement[]) => {
  const inputsToScroll: {
    label: Element | null | undefined
    hasError: boolean
  }[] = currentInputsList
    .filter((input) => input?.required)
    .map((input) => ({
      label: input.previousElementSibling?.previousElementSibling,
      hasError:
        input.id === 'email-input'
          ? !isNotEmpty(input.value) || !validateEmail(input.value)
          : input.id === 'postcode-input'
            ? !isNotEmpty(input.value) || !validatePostcode(input.value)
            : !isNotEmpty(input.value) || !/^[a-z'-]+$/i.test(input.value),
    }))

  const invalidInputsList: (Element | null | undefined)[] = []

  for (const input of inputsToScroll) {
    if (input.hasError) {
      invalidInputsList.push(input.label)
    }
  }

  if (invalidInputsList.length) {
    invalidInputsList[0]?.scrollIntoView({ behavior: 'smooth' })
  }
}
