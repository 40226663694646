export const contactDetailsData = {
  formItems: [
    {
      displayText: 'First name',
      id: 'firstName',
      type: 'text',
      isFocused: true,
    },
    {
      displayText: 'Last name',
      id: 'lastName',
      type: 'text',
      isFocused: false,
    },
    {
      displayText: 'Email address',
      id: 'email',
      type: 'email',
      isFocused: false,
    },
  ],
  imgObj: {
    alt: 'ID card - contact details',
    src: 'https://media.product.which.co.uk/prod/images/original/gm-25b1c0c8-f2cb-4bf3-ac7c-c05f9486fda0-contactdetails.jpg',
  },
  title: 'Enter your contact details',
  caseStudy: {
    label: "I'm happy for a member of the Which? team to get in touch",
    explanation:
      'Your experience could help inform our advice content, product research or campaigning work. We will be able to identify information provided via previous steps in the tool. Anything you tell us can be anonymised.',
    name: 'acceptedContacting',
  },
  singleConsentOptIn: {
    label:
      'Keep me updated about products and services from the Which? Group and the work it does to make life simpler, safer and fairer for UK consumers.',
    name: 'acceptedSingleConsentOptIn',
  },
}
