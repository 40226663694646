import type { FunctionComponent } from 'react'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'

import { ImpactSurveyPage } from '../../../../shared/pages/ImpactSurveyPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { ImpactSurveyRadioObj, RadiosSelected } from '../../../../shared/types/toolTypes'
import { pageSubmission } from '../../../../shared/utils/EngagingNetwork/pageSubmission'
import { pageDataReasonNot } from '../../pageData/pageDataReasonNot'
import { ThankYouFlow } from '../../pageFlowData'
import { getStepData } from '../../utils/getStepData'
import {
  deliveryData,
  faultyGoodsData,
  flightData,
  holidayData,
  homeImprovementData,
  packageBankAccountData,
  parkingData,
  section75Data,
} from './data'

export const ReasonNot: FunctionComponent = () => {
  const { dispatch, state } = useContext(ToolContext)
  const {
    answers: { reasonNot, regardingProblem },
    user: { toolID, userEmail },
  } = state

  const [radiosSelected, setRadiosSelected] = useState<RadiosSelected>(reasonNot)

  const [errorCount, setErrorCount] = useState(0)

  const textInputRef = useRef<HTMLInputElement>(null)

  const handleDispatch = useCallback(
    (emailSent: boolean) => {
      dispatch({ type: 'UPDATE_EMAIL_SENT', data: emailSent })
      dispatch({ type: 'UPDATE_STEP', data: ThankYouFlow.STEP })
      dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: ThankYouFlow.PROGRESS_VALUE })
    },
    [dispatch]
  )

  useEffect(() => {
    if (errorCount > 1) {
      // If they've gotten two or more errors, go to NextSteps anyway
      handleDispatch(false)
    }
  }, [errorCount, handleDispatch])

  const { radioExperience } = getStepData(toolID)

  const chooseDataset = () => {
    switch (toolID) {
      case 'FaultyGoodsTool':
        return faultyGoodsData.radioQuestions
      case 'Section75':
        return section75Data.radioQuestions
      case 'HolidayComplaint':
        return holidayData.radioQuestions
      case 'ParkingTicket':
        return parkingData.radioQuestions
      case 'DeliveryComplaint':
        return deliveryData.radioQuestions
      case 'FlightDelayCancellationCompensation':
        return flightData.radioQuestions
      case 'MisSoldPackagedBankAccount':
        return packageBankAccountData.radioQuestions
      case 'HomeImprovement':
        return homeImprovementData.radioQuestions
      default:
        return faultyGoodsData.radioQuestions
    }
  }

  const chosenDataSet = chooseDataset()

  const questionTitle = `Please tell us the main reason why you have not made ${radioExperience}`

  const radioQuestions = chosenDataSet.map((question) => {
    const buttons = question.buttons.map((button: any) => {
      if (button.textbox) {
        return {
          ...button,
          textbox: { ...button.textbox, reference: textInputRef },
        }
      }
      return button
    })

    return { ...question, title: questionTitle, buttons: buttons }
  })

  const buildPageDataState = (radios: ImpactSurveyRadioObj) => {
    const pageDataState = {
      answers: {
        reasonNot: radios.id,
        reasonNotOther: radios.textboxAnswer,
        regardingProblem: regardingProblem[0].id,
      },
      user: { toolID, userEmail },
    }

    return pageDataState
  }

  const handleSubmit = async (validationPassed: boolean) => {
    const submissionFormId = 110304

    if (validationPassed) {
      const optionalTextInput = textInputRef.current?.value || ''

      const radios: any = []
      radiosSelected.forEach((radio) => {
        let newRadio = radio
        newRadio = { ...newRadio, textboxAnswer: optionalTextInput }
        radios.push(newRadio)
      })

      dispatch({ type: 'UPDATE_REASON_NOT', data: [radios[0]] })

      const pageDataState = buildPageDataState(radios[0])
      const structuredData = pageDataReasonNot(pageDataState)
      const ENResponse = await pageSubmission(structuredData, submissionFormId)

      if (ENResponse && (ENResponse.ok || ENResponse.status === 'SUCCESS')) {
        handleDispatch(true)
      } else {
        setErrorCount(errorCount + 1)
      }
    }
  }

  return (
    <>
      <ImpactSurveyPage
        errorCount={errorCount}
        handleSubmit={handleSubmit}
        nextButtonText="Submit"
        radioQuestions={radioQuestions}
        radiosSelected={radiosSelected}
        setRadiosSelected={setRadiosSelected}
      />
    </>
  )
}
