import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'
import { Link, List, ListItem, Typography } from '@which/seatbelt'

import classNames from 'classnames'

import { FeedbackEntryPoint } from '../../../../shared/components/FeedbackEntryPoint'
import { PageWithText } from '../../../../shared/components/PageWithText'
import { ToolContext } from '../../../../shared/state/appContext'
import globalStyles from '../../../../shared/styles/GlobalStyles.module.scss'
import { ToolFeedbackFlow, ToolFeedbackThankYouFlow } from '../../pageFlowData'
import { feedbackData } from './data'
import styles from './SubmitSuccessful.module.scss'

export const SubmitSuccessful: FunctionComponent = () => {
  const toolContext = useContext(ToolContext)
  const {
    state: {
      toolFeedback: { showFeedback },
      victim: { lostMoney },
    },
    dispatch,
  } = toolContext

  const { body, imgObj, title } = feedbackData

  const handleStepChoice = (id: string) => {
    if (id === 'feedback-yes') {
      return { progress: ToolFeedbackFlow.PROGRESS_VALUE, step: ToolFeedbackFlow.STEP }
    }

    return {
      progress: ToolFeedbackThankYouFlow.PROGRESS_VALUE,
      step: ToolFeedbackThankYouFlow.STEP,
    }
  }

  const handleSubmit = (id: string) => {
    const { progress, step } = handleStepChoice(id)

    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  return (
    <div data-testid="submit-successful">
      <div className={classNames(styles.submitSuccessful, globalStyles.maxWidth)}>
        <PageWithText
          className={styles.submitSuccessfulPageWithText}
          title="Thank you for sharing your scam experience with us"
        />
        {lostMoney === 'yes' && (
          <>
            <Typography className={globalStyles.spacing40} tag="h3" textStyle="title-400">
              Struggling to get your money back?
            </Typography>
            <List className={globalStyles.spacing25}>
              <ListItem
                className={globalStyles.bulletBlack}
                key="how-to-get-your-money-back-after-a-scam"
              >
                Check out our free advice on{' '}
                <Link href="/consumer-rights/advice/how-to-get-your-money-back-after-a-scam-amyJW6f0D2TJ">
                  how to get your money back from a scam
                </Link>
              </ListItem>
              <ListItem
                className={globalStyles.bulletBlack}
                key="what-to-do-if-your-bank-won-t-refund-you-after-a-scam"
              >
                Use our{' '}
                <Link href="/consumer-rights/advice/what-to-do-if-your-bank-won-t-refund-you-after-a-scam-aJaoI3v7hX02">
                  free step-by-step guide on what to do if a bank won't refund you
                </Link>
              </ListItem>
            </List>
          </>
        )}
        <Typography className={globalStyles.spacing40} tag="h3" textStyle="title-400">
          Protect yourself from scams
        </Typography>
        <List className={globalStyles.spacing25}>
          <ListItem className={globalStyles.bulletBlack} key="how-to-spot-a-scam">
            Get tips and advice for{' '}
            <Link href="/consumer-rights/advice/how-to-spot-a-scam-alFiz5h8mnJ9">
              spotting & protecting yourself from scams
            </Link>
          </ListItem>
          <ListItem className={globalStyles.bulletBlack} key="report-a-scam">
            You should also report scams to the relevant authority{' - '}
            <Link href="/consumer-rights/advice/how-to-report-a-scam-aG3sH5L8tjeP">
              check out our guide on this
            </Link>
          </ListItem>
          <ListItem className={globalStyles.bulletBlack} key="mental-health-scam">
            Being scammed can take a huge toll on your mental health.{' '}
            <Link href="/consumer-rights/advice/how-to-report-a-scam-aG3sH5L8tjeP#emotional-support">
              You might find it helpful to access emotional support
            </Link>
          </ListItem>
          <ListItem className={globalStyles.bulletBlack} key="recover-money-from-scam">
            Find out{' '}
            <Link href="/consumer-rights/advice/how-to-get-your-money-back-after-a-scam-amyJW6f0D2TJ">
              how to try and recover your money following a scam
            </Link>
          </ListItem>
          <ListItem className={globalStyles.bulletBlack} key="money-helpline-scam">
            If you are a Which? member you may have access to our{' '}
            <Link href="/about-which/get-in-touch-aBnhv8z1Uslr#call-us">
              Money Helpline on 029 2267 0001
            </Link>{' '}
            for immediate advice
          </ListItem>
        </List>
        {showFeedback && (
          <FeedbackEntryPoint
            body={body}
            className={globalStyles.spacing40Top}
            handleSubmit={handleSubmit}
            imgObj={imgObj}
            title={title}
          />
        )}
      </div>
    </div>
  )
}
