import React from 'react'

import { CRToolEntryPoint } from '../../../shared/pages/CRToolEntryPoint'
import { ToolFeedbackAudiencePage } from '../../../shared/pages/ToolFeedbackAudiencePage'
import { toolFeedbackAudienceData } from '../../../shared/pages/ToolFeedbackAudiencePage/data'
import { ToolFeedbackPage } from '../../../shared/pages/ToolFeedbackPage'
import { toolFeedbackData } from '../../../shared/pages/ToolFeedbackPage/data/faultyGoodsData'
import { ToolFeedbackThankYouPage } from '../../../shared/pages/ToolFeedbackThankYouPage'
import type { FaultyGoodsUseRenderProps } from '../../../shared/types/toolTypes'
import { AttemptedRepair } from '../PageComponents/AttemptedRepair'
import { ContactDetails } from '../PageComponents/ContactDetails'
import { Entitlement } from '../PageComponents/Entitlement'
import { LimitedOptions } from '../PageComponents/LimitedOptions'
import { OnFinance } from '../PageComponents/OnFinance/OnFinance'
import { ProductModel } from '../PageComponents/ProductModel'
import { SubmitSuccessful } from '../PageComponents/SubmitSuccessful'
import { WhatBrand } from '../PageComponents/WhatBrand'
import { WhatProduct } from '../PageComponents/WhatProduct'
import { WhatsWrong } from '../PageComponents/WhatsWrong'
import { WhenPurchased } from '../PageComponents/WhenPurchased'
import { WhereDidYouBuy } from '../PageComponents/WhereDidYouBuy'
import { WriteLetter } from '../PageComponents/WriteLetter/WriteLetter'
import {
  AttemptedRepairFlow,
  ContactDetailsFlow,
  EntitlementFlow,
  LimitedOptionsFlow,
  OnFinanceFlow,
  ProductModelFlow,
  SubmitSuccessfulFlow,
  ToolFeedbackAudienceFlow,
  ToolFeedbackFlow,
  ToolFeedbackThankYouFlow,
  WhatBrandFlow,
  WhatProductFlow,
  WhatsWrongFlow,
  WhenPurchasedFlow,
  WhereDidYouBuyFlow,
  WriteLetterFlow,
} from '../pageFlowData'

export const useRenderStep = ({
  step,
  optionsJson,
  title,
  questionText,
  standfirst,
  buttonText,
  toolUrl,
  CRFooter,
}: FaultyGoodsUseRenderProps) => {
  const renderEntryPoint = () => (
    <CRToolEntryPoint
      buttonText={buttonText}
      optionsJson={optionsJson}
      questionText={questionText}
      standfirst={standfirst}
      title={title}
      toolName="faulty goods"
      toolUrl={toolUrl}
    />
  )

  switch (step) {
    case WhatProductFlow.STEP:
      return <WhatProduct />
    case WhatBrandFlow.STEP:
      return <WhatBrand />
    case WhatsWrongFlow.STEP:
      return <WhatsWrong />
    case ProductModelFlow.STEP:
      return <ProductModel />
    case WhereDidYouBuyFlow.STEP:
      return <WhereDidYouBuy />
    case WhenPurchasedFlow.STEP:
      return <WhenPurchased />
    case OnFinanceFlow.STEP:
      return <OnFinance />
    case AttemptedRepairFlow.STEP:
      return <AttemptedRepair />
    case EntitlementFlow.STEP:
      return <Entitlement />
    case ContactDetailsFlow.STEP:
      return <ContactDetails />
    case SubmitSuccessfulFlow.STEP:
      return <SubmitSuccessful CRFooter={CRFooter} />
    case LimitedOptionsFlow.STEP:
      return <LimitedOptions />
    case WriteLetterFlow.STEP:
      return <WriteLetter />
    case ToolFeedbackFlow.STEP:
      return (
        <ToolFeedbackPage
          pageData={toolFeedbackData}
          toolFeedbackAudienceFlowData={ToolFeedbackAudienceFlow}
          toolFeedbackFlowData={ToolFeedbackFlow}
          toolName="faulty-goods"
        />
      )
    case ToolFeedbackThankYouFlow.STEP:
      return <ToolFeedbackThankYouPage toolFeedbackThankYouFlowData={ToolFeedbackThankYouFlow} />
    case ToolFeedbackAudienceFlow.STEP:
      return (
        <ToolFeedbackAudiencePage
          pageData={toolFeedbackAudienceData}
          toolFeedbackAudienceFlowData={ToolFeedbackAudienceFlow}
          toolFeedbackThankYouFlowData={ToolFeedbackThankYouFlow}
          toolName="faulty-goods"
        />
      )
    default:
      return renderEntryPoint()
  }
}
