import styles from './autoComplete.module.scss'

export const autocomplete = ({
  autoCompleteRef,
  searchItemsList,
  setIsMenuOpen,
  elementId,
}: {
  autoCompleteRef: React.RefObject<HTMLInputElement>
  searchItemsList: string[]
  setIsMenuOpen: any
  elementId: string
}) => {
  searchItemsList.sort((a, b) => a.localeCompare(b))

  const binarySearch = (haystack: string[], needle: string) => {
    let lo = 0
    let hi = haystack.length

    do {
      const midPoint = Math.floor(lo + (hi - lo) / 2)
      const value = haystack[midPoint]

      let rangeStarts = midPoint
      let rangeEnds = midPoint

      if (value.normalize('NFKD').toLowerCase().startsWith(needle.toLowerCase())) {
        while (
          rangeStarts > -1 &&
          haystack[rangeStarts].normalize('NFKD').toLowerCase().startsWith(needle.toLowerCase())
        ) {
          rangeStarts--
        }

        while (
          rangeEnds < haystack.length &&
          haystack[rangeEnds].normalize('NFKD').toLowerCase().startsWith(needle.toLowerCase())
        ) {
          rangeEnds++
        }

        return haystack.slice(rangeStarts + 1, rangeEnds)
      } else if (value.normalize('NFKD').toLowerCase() > needle.toLowerCase()) {
        hi = midPoint
      } else {
        lo = midPoint + 1
      }
    } while (lo < hi)

    return false
  }

  let currentFocus = -1
  /* istanbul ignore next */
  if (autoCompleteRef && autoCompleteRef.current) {
    autoCompleteRef.current.addEventListener('input', function () {
      setIsMenuOpen(true)
      const val = this.value
      closeAllLists()
      if (!val) {
        /* istanbul ignore next */
        return false
      }

      const createDiv = document.createElement('DIV')
      const fragment = new DocumentFragment()
      createDiv.setAttribute('id', elementId + 'autocomplete-list')
      createDiv.setAttribute('class', styles.autocompleteItems)

      const results = binarySearch(searchItemsList, val)

      if (results && results.length) {
        for (const result of results) {
          const createAirlineDiv = document.createElement('DIV')
          createAirlineDiv.setAttribute('role', 'button')
          createAirlineDiv.setAttribute('aria-label', result)
          createAirlineDiv.setAttribute('data-testid', 'auto-complete-item')
          createAirlineDiv.innerHTML = result
          createAirlineDiv.addEventListener('click', () => {
            /* istanbul ignore next */
            if (autoCompleteRef.current) {
              autoCompleteRef.current.value = result
              closeAllLists()
            }
          })
          fragment.appendChild(createAirlineDiv)
        }
        createDiv.appendChild(fragment)
        this.parentNode?.appendChild(createDiv)
      }
    })

    autoCompleteRef.current.addEventListener('keydown', (inputEvent) => {
      let dropdownItems: any = document.getElementById(elementId + 'autocomplete-list')
      if (dropdownItems) {
        dropdownItems = dropdownItems.getElementsByTagName('div')
      }
      if (inputEvent.key === 'ArrowDown') {
        inputEvent.preventDefault()
        currentFocus++
        addActive(dropdownItems)
      } else if (inputEvent.key === 'ArrowUp') {
        currentFocus--
        addActive(dropdownItems)
      } else if (inputEvent.key === 'Enter') {
        /* istanbul ignore next */
        inputEvent.preventDefault()
        /* istanbul ignore next */
        if (currentFocus > -1) {
          if (dropdownItems) {
            dropdownItems[currentFocus].click()
          }
        }
      }
    })

    const addActive = (elementToAddClass: HTMLElement[]) => {
      if (!elementToAddClass) {
        return false
      }
      removeActive(elementToAddClass)
      if (currentFocus >= elementToAddClass.length) {
        currentFocus = 0
      }
      if (currentFocus < 0) {
        currentFocus = elementToAddClass.length - 1
      }
      elementToAddClass[currentFocus].scrollIntoView()
      elementToAddClass[currentFocus].classList.add(styles.autocompleteActive)
    }

    const removeActive = (elementsToRemoveActive: any) => {
      for (let i = 0; i < elementsToRemoveActive.length; i++) {
        elementsToRemoveActive[i].classList.remove(styles.autocompleteActive)
      }
    }

    const closeAllLists = (elementNotToClose: any = null) => {
      const allFoundResults = document.getElementsByClassName(styles.autocompleteItems)
      for (let i = 0; i < allFoundResults.length; i++) {
        if (elementNotToClose !== allFoundResults[i] && elementNotToClose !== autoCompleteRef) {
          allFoundResults[i].parentNode?.removeChild(allFoundResults[i])
        }
      }
    }

    document.addEventListener('click', function (e) {
      closeAllLists(e.target)
      setIsMenuOpen(false)
    })
  }
}
