export const data = {
  buttons: [
    {
      label: 'Yes',
      id: 'yes',
    },
    {
      label: 'No',
      id: 'no',
    },
  ],
  imgObj: {
    alt: 'Two people sitting at a table using a phone and a laptop',
    src: 'https://media.product.which.co.uk/prod/images/original/ce9ee25bea7b-mis-sold-account-step-5.jpg',
  },
  title: 'Is the account in question still open?',
}
