import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { TextAreaPage } from '../../../../shared/pages/TextAreaPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryTextAreaRef } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { validateCharCount } from '../../../../shared/utils/validateCharCount'
import { ContactDetailsFlow, WhatWentWrongFlow } from '../../pageFlowData'
import { whatWentWrongData } from './data'

export const WhatWentWrong: FunctionComponent = () => {
  const textAreaMaxCharCount = 2500
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    order: { whatWentWrong },
  } = state

  const [whatWentWrongTextAreaValid, setWhatWentWrongTextAreaValid] = useState<boolean>(true)

  const WhatWentWrongTextAreaRef = useRef<FormEntryTextAreaRef>(null)

  const handleChange = () => {
    const value = getCleanValue(WhatWentWrongTextAreaRef.current?.formEntryTextarea?.value)
    dispatch({ type: 'UPDATE_WHAT_WENT_WRONG', data: value })
  }

  const handleDispatch = () => {
    dispatch({ type: 'UPDATE_STEP', data: ContactDetailsFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: ContactDetailsFlow.PROGRESS_VALUE })
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${WhatWentWrongFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = () => {
    const value: string = getCleanValue(WhatWentWrongTextAreaRef.current?.formEntryTextarea?.value)
    const isValid: boolean = validateCharCount(value.length, textAreaMaxCharCount)
    const isEmpty = !isNotEmpty(value)

    if (!isValid || isEmpty) {
      setWhatWentWrongTextAreaValid(false)
      return
    }

    setWhatWentWrongTextAreaValid(true)
    handleTracking(value)
    handleDispatch()
  }

  const { imgObj, title, placeholderText, descriptiveText } = whatWentWrongData

  return (
    <div data-testid="what-went-wrong">
      <TextAreaPage
        buttonText="Next"
        defaultValue={whatWentWrong}
        descriptiveText={descriptiveText}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        isFocused
        isInputValid={whatWentWrongTextAreaValid}
        maxChars={textAreaMaxCharCount}
        placeholderText={placeholderText}
        ref={WhatWentWrongTextAreaRef}
        title={title}
      />
    </div>
  )
}
