import type { InfoBoxProps } from '../../../../../shared/types/toolTypes'
import styles from '../../../../../shared/utils/renderInfoBox/renderInfoBox.module.scss'

export const data = {
  buttons: [
    {
      label: 'I rent',
      id: 'rent',
    },
    {
      label: 'I pay a mortgage',
      id: 'mortgage',
    },
    {
      label: 'I own my home outright',
      id: 'outright',
    },
  ],
  title: 'Do you rent or own your home?',
  infoBox: {
    heading: 'Why are we asking about this?',
    borderColour: 'blue',
  } as InfoBoxProps,
  infoBoxParagraphs: [
    {
      classname: `${styles.renderInfoBoxParagraphs}`,
      text: 'The support available for those that rent their home differs to what is available to those that pay a mortgage.',
    },
  ],
  imgObj: {
    alt: 'A picture of a house and a women in front of it',
    src: 'https://media.product.which.co.uk/prod/images/original/aef95d06e890-home.jpg',
  },
}
