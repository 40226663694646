export const data = {
  productButtons: [
    {
      label: 'Within the last 14 days',
      id: 'last14Days',
    },
    {
      label: '30 days or less',
      id: '30DaysOrLess',
    },
    {
      label: 'Between 31 days and 6 months',
      id: '31DaysAnd6Months',
    },
    {
      label: 'Between 6 months and 6 years',
      id: '6MonthsAnd6Years',
    },
    {
      label: 'More than 6 years',
      id: 'moreThan6Years',
    },
  ],
  digitalButtons: [
    {
      label: 'Less than 6 years ago',
      id: 'lessThan6Years',
    },
    {
      label: 'More than 6 years ago',
      id: 'moreThan6Years',
    },
  ],
  digitalTitle: 'When did you purchase the digital content?',
  title: 'How long ago did you take ownership of it?',
  imgSrc:
    'https://media.product.which.co.uk/prod/images/original/gm-04d97651-c39f-4676-b400-1a574375a8a2-whenpurchased.jpg',
}
