import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { Typography } from '@which/seatbelt'

import { Boxed } from '../../components/Boxed'
import { ErrorMessage } from '../../components/ErrorMessage'
import { NextButton } from '../../components/NextButton'
import { RadioGroup } from '../../components/RadioGroup'
import { TextArea } from '../../components/TextArea'
import type { SurveyModuleProps } from '../../types/toolTypes'
import styles from './SurveyModule.module.scss'

export const SurveyModule = forwardRef(
  (
    {
      radioQuestions,
      handleRadioChange,
      stateValue,
      handleSubmit,
      title,
      standFirst,
      textareaQuestions,
      handleTextareaChange,
      textareaState,
      errorCount,
      errorMessage = 'Something went wrong, please try again',
      nextButtonText = 'Send feedback',
    }: SurveyModuleProps,
    ref
  ) => {
    const placeHolderRef = useRef<HTMLDivElement>(null)

    useImperativeHandle(ref, () => ({
      placeHolderRef: placeHolderRef.current,
    }))

    const showError = () => {
      if (errorCount && errorCount > 0) {
        return (
          <span className="sb-text-align-center">
            <ErrorMessage errorText={errorMessage} />
          </span>
        )
      }
    }

    return (
      <div ref={placeHolderRef}>
        <Typography className="sb-text-align-center" tag="h1" textStyle="title-600">
          {title}
        </Typography>
        <div className={styles.surveyModuleWrapper}>
          <Typography className={styles.surveyModuleStandFirst} tag="p" textStyle="body">
            {standFirst}
          </Typography>
          {radioQuestions.map((radioQuestion) => {
            const { buttons, id, radiosStandFirst, title: radioTitle } = radioQuestion
            const isSubmitValid = stateValue.find((val) => val.title === radioTitle)?.isValid
            return (
              <Boxed className={styles.surveyModuleQuestion} key={radioTitle}>
                <Typography
                  className={styles.surveyModuleQuestionTitle}
                  tag="h3"
                  textStyle="title-400"
                >
                  {radioTitle}
                </Typography>
                {radiosStandFirst && (
                  <Typography className={styles.surveyModuleStandFirst} tag="p" textStyle="body">
                    {radiosStandFirst}
                  </Typography>
                )}
                <RadioGroup
                  buttons={buttons}
                  handleChange={handleRadioChange}
                  id={id}
                  isSubmitValid={isSubmitValid}
                  stateValue={stateValue}
                  title={radioTitle}
                />
              </Boxed>
            )
          })}
          {textareaQuestions &&
            handleTextareaChange &&
            textareaQuestions.map((textareaQuestion) => {
              const {
                title: textAreaTitle,
                defaultValue,
                placeholderText,
                id,
                isOptional,
              } = textareaQuestion
              const isSubmitValid = textareaState?.find((val) => val.id === id)?.isValid
              return (
                <Boxed className={styles.surveyModuleQuestion} key={textAreaTitle}>
                  <Typography
                    className={styles.surveyModuleQuestionTitle}
                    tag="h3"
                    textStyle="title-400"
                  >
                    {textAreaTitle}
                    {isOptional && <span className={styles.surveyModuleOptional}> (optional)</span>}
                  </Typography>
                  <TextArea
                    className={styles.surveyModuleTextarea}
                    defaultValue={defaultValue}
                    handleChange={handleTextareaChange}
                    handleSubmit={handleSubmit}
                    id={id}
                    isFocused={false}
                    isSubmitValid={isSubmitValid}
                    maxChars={2500}
                    optional={isOptional}
                    placeholderText={placeholderText}
                    srLabel={textAreaTitle}
                  />
                </Boxed>
              )
            })}
          <div className={styles.surveyModuleSubmitButton}>
            <NextButton buttonText={nextButtonText} onClick={handleSubmit} />
          </div>
          {showError()}
        </div>
      </div>
    )
  }
)
