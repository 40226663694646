import type { UserProps } from './../../../shared/types/toolTypes'

export const pageData = (state: {
  emailData: { body: { text: string }[] }
  car: {
    regNumber: string
    whereBought: string
    whenPurchased: string
    onFinance: boolean
    whichDealer: string
    howMuch: string
    whatsWrong: string
    likeToDo: string
  }
  optIns: { singleConsentOptIn: boolean }
  user: UserProps
}) => {
  const { emailAddress, firstName, lastName } = state.user
  const {
    regNumber,
    whereBought,
    whenPurchased,
    onFinance,
    whichDealer,
    howMuch,
    whatsWrong,
    likeToDo,
  } = state.car
  const { singleConsentOptIn } = state.optIns
  const questions: any = {}
  const { body } = state.emailData
  let emailBodyBuilder = ''
  body.forEach((bodyData) => {
    /* istanbul ignore next */
    if (bodyData.text) {
      emailBodyBuilder = emailBodyBuilder + `${bodyData.text}<br />`
    }
  })

  questions['question.1373498'] = regNumber // TF - Car problem - What's your car registration number?
  questions['question.1373482'] = whereBought // TF - Car problem - Where did you buy your car from?
  questions['question.1373483'] = whenPurchased // TF - Car problem - When did you buy your car?
  questions['question.1373484'] = onFinance // TF - Car problem - Did you buy it on finance?
  questions['question.1373485'] = whichDealer // TF - Car problem - Which dealer did you buy your car from?
  questions['question.1373503'] = howMuch // TF - Car problem - How much did you pay for it?
  questions['question.1373488'] = whatsWrong // TF - Car problem - What's wrong with your car?
  questions['question.1373496'] = likeToDo // TF - What would you like to do?

  questions['question.1598463'] = singleConsentOptIn ? 'Y' : 'N' // TF - Single Consent opt in
  questions['question.1378705'] = emailBodyBuilder // TF - Car Problem - Completed Email
  questions['question.1184531'] = 'Y' // TF - Impact survey opt-in

  // return supporter
  return {
    supporter: {
      Email: emailAddress,
      'First name': firstName,
      'Last name': lastName,
      questions,
    },
  }
}
