import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { CurrencyPage } from '../../../../shared/pages/CurrencyPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryInputRef } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { HowMuchFlow, WhatsWrongFlow } from '../../pageFlowData'
import { howMuchData } from './data'

export const HowMuch: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    car: { howMuch },
  } = state

  const HowMuchInput = useRef<FormEntryInputRef>(null)
  const [HowMuchInputValid, setHowMuchInputValid] = useState<boolean>(true)
  const [valueInvalidText, setValueInvalidText] = useState<string>(
    'Please fill out this field to continue'
  )

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${HowMuchFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_HOW_MUCH', data: value })
    dispatch({ type: 'UPDATE_STEP', data: WhatsWrongFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: WhatsWrongFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    const value = getCleanValue(HowMuchInput?.current?.formEntryInput?.value)
    const valueAsDecimal = Number(value)
    const valueAsDecimalHasError = valueAsDecimal > 0 ? false : true
    const howMuchHasError = !isNotEmpty(value)

    if (!howMuchHasError && valueAsDecimalHasError) {
      setValueInvalidText('Add a value greater than 0')
    }

    if (!howMuchHasError && !valueAsDecimalHasError) {
      handleTracking(value)
      handleDispatch(value)
      setHowMuchInputValid(true)
      return
    }
    setHowMuchInputValid(false)
  }

  const { title, imgObj } = howMuchData

  return (
    <CurrencyPage
      buttonText="Next"
      currencySymbol="£"
      defaultValue={howMuch}
      errorText={valueInvalidText}
      handleSubmit={handleSubmit}
      id="how-much"
      imgObj={imgObj}
      isFocused
      isSubmitValid={HowMuchInputValid}
      ref={HowMuchInput}
      title={title}
    />
  )
}
