import type { FunctionComponent } from 'react'
import React, { useContext, useEffect } from 'react'

import { ToolWrapper } from '../../shared'
import { PageFade } from '../../shared/components/PageFade'
import { ToolContext } from '../../shared/state/appContext'
import type { PriceRiseCalculatorToolProps } from '../../shared/types/toolTypes'
import { formatToolName } from '../../shared/utils/formatToolName'
import { getFilteredPageDataByStep } from '../../shared/utils/getFilteredPageDataByStep/getFilteredPageDataByStep'
import { parseJson } from '../../shared/utils/parseJson/parseJson'
import { setUserJourney } from '../../shared/utils/setUserJourney'
import { generalDataLayer } from '../../shared/utils/tracking'
import { useRenderStep } from './hooks/useRenderStep'
import { AllFlows, HowMuchFlow } from './pageFlowData'
import styles from './PriceRiseCalculator.module.scss'

export const PriceRiseCalculator: FunctionComponent<PriceRiseCalculatorToolProps> = ({
  toolName,
  optionsJson,
}) => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName, initialised, journeyFlow, step, userJourney },
    priceRise: { cpiAmount, providersList },
  } = state

  const providerData = parseJson(optionsJson)
  const cpi = providerData ? providerData.find((item) => 'cpi' in item)?.cpi : null
  const providers = providerData
    ? providerData.find((item) => 'providers' in item)?.providers
    : null

  useEffect(() => {
    if (cpi && providers && !cpiAmount && !providersList) {
      dispatch({ type: 'UPDATE_CPI', data: cpi })
      dispatch({ type: 'UPDATE_PROVIDERS', data: providers })
    }
  }, [dispatch, providerData, cpi, providers, cpiAmount, providersList])

  useEffect(() => {
    if (!entryToolName) {
      dispatch({ type: 'UPDATE_ENTRY_TOOL_NAME', data: toolName })
    }
  }, [dispatch, entryToolName, toolName])

  useEffect(() => {
    setUserJourney(userJourney, step, dispatch)
  }, [step, dispatch, userJourney])

  useEffect(() => {
    const firePageView = () => {
      if (!initialised) {
        return
      }

      const pageData = getFilteredPageDataByStep(step, AllFlows, [HowMuchFlow])

      const { milestone, viewName } = pageData
      const milestoneArray = journeyFlow.milestoneHit

      if (!milestoneArray.includes(viewName) && milestone !== '') {
        milestoneArray.push(viewName)
        generalDataLayer(formatToolName(toolName), milestone)
        dispatch({ type: 'UPDATE_MILESTONE', data: milestoneArray })
      }
    }

    firePageView()
  }, [dispatch, initialised, journeyFlow.milestoneHit, step, toolName])

  return (
    <section className={styles.container}>
      <ToolWrapper width="narrow">
        <PageFade stateProgress={0} step={step} toolName={toolName}>
          {useRenderStep(step, optionsJson)}
        </PageFade>
      </ToolWrapper>
    </section>
  )
}
