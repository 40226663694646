import type { Reducer } from 'react'

import { initialSliceReducer, toolFeedbackSliceReducer } from '../../../shared/state/reducers'
import type { Action, State } from '../../../shared/state/toolStateTypes'
import { combineReducers } from '../../../shared/utils/combineReducers'

export const issueSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_ISSUE_DESCRIPTION':
      return {
        ...state,
        description: data,
      }
    case 'UPDATE_ISSUE_SHORTDESC':
      return {
        ...state,
        shortDesc: data,
      }
    case 'UPDATE_ISSUE_DESIREDRESOLUTION':
      return {
        ...state,
        desiredResolution: data,
      }
    default:
      return state
  }
}

export const productSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_PRODUCT_TYPE':
      return {
        ...state,
        type: data,
      }
    case 'UPDATE_PRODUCT_PRICE':
      return {
        ...state,
        price: data,
      }
    case 'UPDATE_PRODUCT_BRAND':
      return {
        ...state,
        brand: data,
      }
    case 'UPDATE_PRODUCT_DESCRIPTION':
      return {
        ...state,
        description: data,
      }
    case 'UPDATE_PRODUCT_MODEL':
      return {
        ...state,
        model: data,
      }
    case 'UPDATE_PRODUCT_ONFINANCE':
      return {
        ...state,
        onFinance: data,
      }
    case 'UPDATE_PURCHASE_METHOD':
      return {
        ...state,
        purchaseMethod: data,
      }
    default:
      return state
  }
}

export const retailerSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_RETAILER_ORDERNUM':
      return {
        ...state,
        orderNum: data,
      }
    case 'UPDATE_RETAILER_WHEN_BOUGHT':
      return {
        ...state,
        whenBought: data,
      }
    case 'UPDATE_RETAILER_ATTEMPTEDRESOLVEITEM':
      return {
        ...state,
        attemptedResolveItem: data,
      }
    case 'UPDATE_RETAILER_CHANNEL':
      return {
        ...state,
        channel: data,
      }
    case 'UPDATE_RETAILER_NAME':
      return {
        ...state,
        name: data,
      }
    default:
      return state
  }
}

export const faultyGoodsReducer = combineReducers({
  initial: initialSliceReducer,
  issue: issueSliceReducer,
  product: productSliceReducer,
  retailer: retailerSliceReducer,
  toolFeedback: toolFeedbackSliceReducer,
})
