import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { Typography } from '@which/seatbelt'

import classNames from 'classnames'

import { DatePicker } from '../../components/DatePicker'
import type { RefObject } from '../../components/DatePicker/data/types'
import { ImageWithQuestion } from '../../components/ImageWithQuestion'
import { NextButton } from '../../components/NextButton'
import globalStyles from '../../styles/GlobalStyles.module.scss'
import type { DatePickerPageProps } from '../../types/toolTypes'
import { RenderInfoBox } from '../../utils/renderInfoBox'
import styles from './DatePickerPage.module.scss'

export const DatePickerPage = forwardRef(
  (
    {
      buttonText,
      purchasedDate,
      handleSubmit,
      imgObj,
      testId,
      title,
      renderError,
      newErrorMessage,
      infoBoxData,
      handleInputChange,
      max,
      isFocused,
      noteTop,
    }: DatePickerPageProps,
    ref
  ) => {
    const DatePickerInputRef = useRef<RefObject>(null)

    useImperativeHandle(ref, () => ({
      datePickerInput: DatePickerInputRef.current,
    }))

    return (
      <div data-testid={testId}>
        <ImageWithQuestion image={imgObj} title={title} />
        {noteTop && (
          <Typography
            className={classNames(styles.textAreaPageDescriptive, styles.datePickerPageNote)}
            textStyle="body"
          >
            {noteTop}
          </Typography>
        )}
        <div className={styles.datePickerPageDate}>
          <DatePicker
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            isFocused={isFocused}
            max={max}
            newErrorMessage={newErrorMessage}
            ref={DatePickerInputRef}
            renderError={renderError}
            stateValue={purchasedDate}
            testId={`${testId}-input`}
          />
        </div>
        <div className={classNames(globalStyles.buttonWrapper, globalStyles.spacing65)}>
          <NextButton buttonText={buttonText} onClick={handleSubmit} />
        </div>
        {infoBoxData && <RenderInfoBox data={infoBoxData} />}
      </div>
    )
  }
)
