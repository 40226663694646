export const validRegistration = (registration: string) => {
  const regEx = new RegExp(
    '(^[A-Za-z]{2}[0-9]{2}[ ]{0,1}[A-Za-z]{3}$)|' +
      '(^[A-Za-z][0-9]{1,3}[ ]{0,1}[A-Za-z]{3}$)|' +
      '(^[A-Za-z]{3}[ ]{0,1}[0-9]{1,3}[A-Za-z]$)|' +
      '(^[0-9]{1,4}[ ]{0,1}[A-Za-z]{1,2}$)|' +
      '(^[0-9]{1,3}[ ]{0,1}[A-Za-z]{1,3}$)|' +
      '(^[A-Za-z]{1,2}[ ]{0,1}[0-9]{1,4}$)|' +
      '(^[A-Za-z]{1,3}[ ]{0,1}[0-9]{1,3}$)|' +
      '(^[A-Za-z]{1,3}[ ]{0,1}[0-9]{1,4}$)|' +
      '(^[0-9]{3}[DX]{1}[0-9]{3}$)'
  )

  const isValid = registration.match(regEx) ? true : false
  return isValid
}
