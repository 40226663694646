export const howAccountSoldData = {
  question: 'How was the account sold to you?',
  imgObj: {
    alt: 'Two people talking to a man at a desk',
    src: 'https://media.product.which.co.uk/prod/images/original/8731014b1438-mis-sold-bank-account-step-4.jpg',
  },
  id: 'how-account-sold',
  options: [
    {
      label: 'Please choose',
      value: '',
    },
    {
      label: 'Over the counter',
      value: 'over-the-counter',
    },
    {
      label: 'Via the internet',
      value: 'via-the-internet',
    },
    {
      label: 'Over the phone',
      value: 'over-the-phone',
    },
    {
      label: 'By post',
      value: 'by-post',
    },
    {
      label: 'During a meeting',
      value: 'during-a-meeting',
    },
    {
      label: 'I filled in a leaflet',
      value: 'filled-in-a-leaflet',
    },
    {
      label: "I can't remember",
      value: 'cant-remember',
    },
  ],
}
