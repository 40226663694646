import { surveyModuleSpecificText } from '../../../../../shared/utils/surveyModuleSpecificText/surveyModuleSpecificText'

const standardQuestion = (toolId: string) => {
  const toolSpecificText = surveyModuleSpecificText(toolId)
  return [
    {
      title: `To what extent, if at all, do you believe using our letter contributed to the success of your ${toolSpecificText}?`,
      buttons: [
        {
          label: 'My success was entirely down to the letter',
          id: 'all-letter',
          isFocused: false,
        },
        {
          label: 'My success was mostly down to the letter',
          id: 'mostly-letter',
          isFocused: false,
        },
        {
          label: 'My success was partly down to the letter',
          id: 'partly-letter',
          isFocused: false,
        },
        {
          label: 'The letter was only a minor factor in my success',
          id: 'minor-letter',
          isFocused: false,
        },
        {
          label: `The letter did not contribute at all to the success of my ${toolSpecificText}`,
          id: 'not-letter',
          isFocused: false,
        },
        {
          label: `Not applicable - I did not use the Which? letter to make my ${toolSpecificText}`,
          id: 'not-applicable',
          isFocused: false,
        },
        { label: "Don't know / not sure", id: 'not-sure-letter', isFocused: false },
      ],
      isSubmitValid: true,
      id: 'letter-contribution',
    },
  ]
}

export const faultyGoodsData = {
  radioQuestions: [
    {
      title: 'What was the outcome of your claim?',
      buttons: [
        {
          label: 'Full refund',
          id: 'full-refund',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: true,
            name: 'outcome-currency',
            displayText: 'Please specify the amount',
          },
        },
        {
          label: 'Partial refund',
          id: 'partial-refund',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: true,
            name: 'outcome-currency',
            displayText: 'Please specify the amount',
          },
        },
        { label: 'Repair', id: 'repair', isFocused: false },
        {
          label: 'A replacement product/car worth',
          id: 'replacement',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: true,
            name: 'repair-currency',
            displayText: 'Please specify the amount',
          },
        },
        {
          label: 'Other',
          id: 'other',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: false,
            name: 'text',
            displayText: 'Please specify',
          },
        },
        { label: "Don't know / not sure", id: 'not-sure', isFocused: false },
      ],
      isSubmitValid: true,
      id: 'outcome',
    },
    ...standardQuestion('FaultyGoodsTool'),
  ],
}

export const section75Data = {
  radioQuestions: [
    {
      title: 'What was the outcome of your claim?',
      buttons: [
        {
          label: 'Full refund',
          id: 'full-refund',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: true,
            name: 'outcome-currency',
            displayText: 'Please specify the amount',
          },
        },
        {
          label: 'Partial refund',
          id: 'partial-refund',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: true,
            name: 'outcome-currency',
            displayText: 'Please specify the amount',
          },
        },
        { label: 'Repair', id: 'repair', isFocused: false },
        {
          label: 'A replacement product/service worth',
          id: 'replacement',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: true,
            name: 'outcome-currency',
            displayText: 'Please specify the amount',
          },
        },
        {
          label: 'Other',
          id: 'other',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: false,
            name: 'repair-currency',
            displayText: 'Please specify',
          },
        },
        { label: "Don't know / not sure", id: 'not-sure', isFocused: false },
      ],
      isSubmitValid: true,
      id: 'outcome',
    },
    ...standardQuestion('Section75'),
  ],
}

export const holidayData = {
  radioQuestions: [
    {
      title: 'What was the outcome of your claim?',
      buttons: [
        {
          label: 'Full refund',
          id: 'full-refund',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: true,
            name: 'outcome-currency',
            displayText: 'Please specify the amount',
          },
        },
        {
          label: 'Partial refund',
          id: 'partial-refund',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: true,
            name: 'outcome-currency',
            displayText: 'Please specify the amount',
          },
        },
        { label: 'Repair', id: 'repair', isFocused: false },
        {
          label: 'A replacement holiday/voucher/credit note worth',
          id: 'replacement',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: true,
            name: 'outcome-currency',
            displayText: 'Please specify the amount',
          },
        },
        {
          label: 'Other',
          id: 'other',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: false,
            name: 'repair-currency',
            displayText: 'Please specify',
          },
        },
        { label: "Don't know / not sure", id: 'not-sure', isFocused: false },
      ],
      isSubmitValid: true,
      id: 'outcome',
    },
    ...standardQuestion('HolidayComplaint'),
  ],
}

export const parkingData = {
  radioQuestions: [
    {
      title: 'What was the outcome of your claim?',
      buttons: [
        {
          label: 'I did not have to pay the charge. This saved me paying out',
          id: 'no-charge',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: true,
            name: 'outcome-currency',
            displayText: 'Please specify the amount',
          },
        },
        {
          label: 'I received a refund of money I had paid out',
          id: 'refund',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: true,
            name: 'outcome-currency',
            displayText: 'Please specify the amount',
          },
        },
      ],
      isSubmitValid: true,
      id: 'outcome',
    },
    ...standardQuestion('ParkingTicket'),
  ],
}

export const flightData = {
  radioQuestions: [
    {
      title: 'What was the outcome of your claim?',
      buttons: [
        {
          label: 'Refund / compensation',
          id: 'refund',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: true,
            name: 'outcome-currency',
            displayText: 'Please specify the amount',
          },
        },
        {
          label: 'A voucher / credit note',
          id: 'voucher',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: true,
            name: 'outcome-currency',
            displayText: 'Please specify the amount',
          },
        },
        {
          label: 'Other',
          id: 'other',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: false,
            name: 'repair-currency',
            displayText: 'Please specify',
          },
        },
        { label: "Don't know / not sure", id: 'not-sure', isFocused: false },
      ],
      isSubmitValid: true,
      id: 'outcome',
    },
    ...standardQuestion('FlightDelayCancellationCompensation'),
  ],
}

export const deliveryData = {
  radioQuestions: [
    {
      title: 'What was the outcome of your claim?',
      buttons: [
        {
          label: 'Refund / compensation',
          id: 'refund',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: true,
            name: 'outcome-currency',
            displayText: 'Please specify the amount',
          },
        },
        {
          label: 'A replacement item',
          id: 'replacement',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: true,
            name: 'outcome-currency',
            displayText: 'Please specify the amount',
          },
        },
        {
          label: 'Other',
          id: 'other',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: false,
            name: 'repair-currency',
            displayText: 'Please specify',
          },
        },
        { label: "Don't know / not sure", id: 'not-sure', isFocused: false },
      ],
      isSubmitValid: true,
      id: 'outcome',
    },
    ...standardQuestion('DeliveryComplaint'),
  ],
}

export const packageBankAccountData = {
  radioQuestions: [
    {
      title: 'What was the outcome of your complaint/claim?',
      buttons: [
        {
          label: 'I received a refund of all the fees I had paid',
          id: 'full-refund',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: true,
            name: 'outcome-currency',
            displayText: 'Please specify the amount',
          },
        },
        {
          label: 'I received a refund of some of the fees I had paid',
          id: 'partial-refund',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: true,
            name: 'outcome-currency',
            displayText: 'Please specify the amount',
          },
        },
        {
          label: 'I received compensation',
          id: 'compensation',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: true,
            name: 'outcome-currency',
            displayText: 'Please specify the amount',
          },
        },
        {
          label: 'Other',
          id: 'other',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: false,
            name: 'repair-currency',
            displayText: 'Please specify',
          },
        },
        { label: "Don't know / not sure", id: 'not-sure', isFocused: false },
      ],
      isSubmitValid: true,
      id: 'outcome',
    },
    ...standardQuestion('MisSoldPackagedBankAccount'),
  ],
}

export const broadbandData = {
  radioQuestions: [
    {
      title: 'What was the outcome of your complaint?',
      buttons: [
        {
          label: 'My broadband provider fixed the problem with my speed',
          id: 'resolved-issue',
          isFocused: false,
        },
        {
          label: 'My provider fixed the problem with my speed and gave me refund/compensation',
          id: 'provider-fixed-compensation',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: true,
            name: 'outcome-currency',
            displayText: 'Please specify the amount',
          },
        },
        {
          label: 'My provider offered me a cheaper tariff to reflect slower speeds',
          id: 'cheaper-tariff',
          isFocused: false,
        },
        {
          label: 'My provider said I can exit my contract and go elsewhere without penalty',
          id: 'exit-contract',
          isFocused: false,
        },
        {
          label: 'I received compensation',
          id: 'compensation',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: true,
            name: 'outcome-currency',
            displayText: 'Please specify the amount',
          },
        },
        {
          label: 'Other',
          id: 'other',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: false,
            name: 'text',
            displayText: 'Please specify',
          },
        },
        { label: "Don't know / not sure", id: 'not-sure', isFocused: false },
      ],
      isSubmitValid: true,
      id: 'outcome',
    },
    ...standardQuestion('DeliveryComplaint'),
  ],
}

export const homeImprovementData = {
  radioQuestions: [
    {
      title: 'What was the outcome of your complaint?',
      buttons: [
        {
          label: 'I was fully refunded',
          id: 'fully-refunded',
          isFocused: false,
        },
        {
          label: 'I received a partial refund',
          id: 'partial-refund',
          isFocused: false,
        },
        {
          label:
            'The work was put right free of charge by the company/trader that did the initial job',
          id: 'fixed-issue',
          isFocused: false,
        },
        {
          label: 'The work was put right free of charge by another company/trader',
          id: 'fixed-by-other',
          isFocused: false,
        },
        {
          label: 'I had to pay for some of work to put it right',
          id: 'paid-to-fix',
          isFocused: false,
        },
        {
          label: 'I received compensation',
          id: 'compensation',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: true,
            name: 'outcome-currency',
            displayText: 'Please specify the amount',
          },
        },
        {
          label: "I didn't receive any refund and the work was not put right",
          id: 'no-fix',
          isFocused: false,
        },
        {
          label: 'Other',
          id: 'other',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: false,
            name: 'text',
            displayText: 'Please specify',
          },
        },
        { label: "Don't know / not sure", id: 'not-sure', isFocused: false },
      ],
      isSubmitValid: true,
      id: 'outcome',
    },
    ...standardQuestion('HomeImprovement'),
  ],
}
