export const whatHappenedData = {
  title: 'Please explain why you are contesting the ticket',
  imgObj: {
    src: 'https://media.product.which.co.uk/prod/images/original/gm-8e71aef7-a103-4ba2-98c8-bbe58af070fb-parking-ticket-12.jpg',
    alt: 'A distressed man using a phone and a frustrated woman',
  },
  note: [
    'What you write here will appear in the final letter of the claim, so please be as clear as possible, including dates.',
  ],
  descriptiveText: ['Why was the ticket issued to you unfair or unreasonable?'],
  placeholderText: 'Eg. I paid for a ticket but got a penalty notice.',
}
