export const nameOfScammerData = {
  telephone: {
    title: 'What was the name of the company/organisation the caller said they were calling from?',
    imgObj: {
      alt: 'One person sitting down and two others standing up around a desk',
      src: 'https://media.product.which.co.uk/prod/images/original/c6783ae7f971-scam-sharer-step-5.jpg',
    },
  },
  online: {
    title: 'What was the name of the company or organisation?',
    imgObj: {
      alt: 'One person sitting down and two others standing up around a desk',
      src: 'https://media.product.which.co.uk/prod/images/original/9472858f11f2-scam-sharer-step-5-v2.jpg',
    },
  },
  email: {
    title: 'Name of the company/organisation the email appeared to be from',
    imgObj: {
      alt: 'One person sitting down and two others standing up around a desk',
      src: 'https://media.product.which.co.uk/prod/images/original/754dccf60111-scam-sharer-step-5-v3.jpg',
    },
  },
  letter: {
    title: 'Name of the company/organisation the letter or flyer appeared to be from',
    imgObj: {
      alt: 'One person sitting down and two others standing up around a desk',
      src: 'https://media.product.which.co.uk/prod/images/original/00ffffc3ef2c-scam-sharer-step-5-v5.jpg',
    },
  },
  textMessage: {
    title: 'Name of the company/organisation the text appeared to be from',
    imgObj: {
      alt: 'One person sitting down and two others standing up around a desk',
      src: 'https://media.product.which.co.uk/prod/images/original/52d1c0a54c25-scam-sharer-step-5-v4.jpg',
    },
  },
  social: {
    title: 'What was the name of the company/organisation the website appeared to be',
    imgObj: {
      alt: 'One person sitting down and two others standing up around a desk',
      src: 'https://media.product.which.co.uk/prod/images/original/bac37ae446c1-scam-sharer-step-5-v6.jpg',
    },
  },
}
