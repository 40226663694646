import type { FunctionComponent } from 'react'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import { ImpactSurveyPage } from '../../../../shared/pages/ImpactSurveyPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { ImpactSurveyRadioNoTextObj } from '../../../../shared/types/toolTypes'
import { pageSubmission } from '../../../../shared/utils/EngagingNetwork/pageSubmission'
import { pageDataRegardingProblem } from '../../pageData/pageDataRegardingProblem'
import { ReasonNotFlow, ThankYouFlow, WasSuccessfulFlow } from '../../pageFlowData'
import { getStepData } from '../../utils/getStepData'
import { regardingProblemData } from './data'

export const RegardingProblem: FunctionComponent = () => {
  const { dispatch, state } = useContext(ToolContext)
  const {
    answers: { regardingProblem },
    user: { toolID, userEmail },
  } = state
  const [radiosSelected, setRadiosSelected] =
    useState<{ title: string; id?: string; groupId?: string; isValid: boolean }[]>(regardingProblem)
  const [errorCount, setErrorCount] = useState(0)
  const { toolName } = getStepData(toolID)
  const { standFirst, radioQuestionData, complaintQuestionData } = regardingProblemData
  const [buttonText, setButtonText] = useState('Next')

  const handleSubmitDispatch = useCallback(
    (emailSent: boolean) => {
      dispatch({ type: 'UPDATE_EMAIL_SENT', data: emailSent })
      dispatch({ type: 'UPDATE_STEP', data: ThankYouFlow.STEP })
      dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: ThankYouFlow.PROGRESS_VALUE })
    },
    [dispatch]
  )

  useEffect(() => {
    if (errorCount > 1) {
      // If they've gotten two or more errors, go to NextSteps anyway
      handleSubmitDispatch(false)
    }
  }, [errorCount, handleSubmitDispatch])

  useEffect(() => {
    radiosSelected && radiosSelected[0]?.id === 'no-intend-to'
      ? setButtonText('Submit')
      : setButtonText('Next')
  }, [radiosSelected])

  const chooseTitle = () => {
    switch (toolID) {
      case 'FaultyGoodsTool':
        return 'Have you made a claim in regard to your problem purchase?'
      case 'Section75':
        return 'Have you made a claim in regard to your problem purchase/transaction?'
      case 'HolidayComplaint':
        return 'Have you made a complaint/claim in regard to your problem holiday experience?'
      case 'ParkingTicket':
        return 'Have you made an appeal in regard to your problem parking ticket?'
      case 'DeliveryComplaint':
        return 'Have you made a complaint in regard to your problem delivery?'
      case 'FlightDelayCancellationCompensation':
        return 'Have you made a compensation claim in regard to your problem flight?'
      case 'MisSoldPackagedBankAccount':
        return 'Have you made a complaint/claim in regard to your problem with your bank account?'
      case 'BroadbandSpeed':
        return 'Have you made a complaint in regard to your problem with your broadband speed?'
      case 'HomeImprovement':
        return 'Have you made a complaint in regard to your problem home improvement?'
      default:
        return 'Have you made a claim in regard to your problem purchase?'
    }
  }

  const chooseButtons = () => {
    if (toolID === 'DeliveryComplaint' || toolID === 'BroadbandSpeed') {
      return complaintQuestionData
    }
    return radioQuestionData
  }

  const buttonDataSet = chooseButtons()

  const title = `Thank you for using the Which? ${toolName} tool.`
  const radioQuestion = [{ ...buttonDataSet, title: chooseTitle() }]

  const buildPageDataState = (radios: ImpactSurveyRadioNoTextObj) => {
    const pageDataState = {
      answers: {
        regardingProblem: radios.id,
      },
      user: { toolID, userEmail },
    }

    return pageDataState
  }

  const submitToEN = async (radios: ImpactSurveyRadioNoTextObj) => {
    const submissionFormId = 110304
    const pageDataState = buildPageDataState(radios)
    const structuredData = pageDataRegardingProblem(pageDataState)
    const ENResponse = await pageSubmission(structuredData, submissionFormId)

    if (ENResponse && (ENResponse.ok || ENResponse.status === 'SUCCESS')) {
      handleSubmitDispatch(true)
    } else {
      setErrorCount(errorCount + 1)
    }
  }

  const handleStepChoice = (answerId) => {
    if (answerId === 'no-claim' || answerId === 'no-complaint') {
      return { progress: ReasonNotFlow.PROGRESS_VALUE, step: ReasonNotFlow.STEP }
    } else if (answerId === 'no-intend-to') {
      submitToEN(radiosSelected[0])
      return {}
    } else {
      return { progress: WasSuccessfulFlow.PROGRESS_VALUE, step: WasSuccessfulFlow.STEP }
    }
  }

  const handleDispatch = (answerId: string) => {
    const { progress, step } = handleStepChoice(answerId)

    dispatch({ type: 'UPDATE_REGARDING_PROBLEM', data: radiosSelected })

    if (progress !== undefined && step !== undefined) {
      dispatch({ type: 'UPDATE_STEP', data: step })
      dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
    }
  }

  const handleSubmit = (validationPassed: boolean) => {
    if (validationPassed) {
      let answerId: string | undefined = ''
      radiosSelected.forEach((radio) => {
        const { id } = radio
        answerId = id
      })

      handleDispatch(answerId)
    }
  }

  return (
    <>
      <ImpactSurveyPage
        errorCount={errorCount}
        handleSubmit={handleSubmit}
        nextButtonText={buttonText}
        radioQuestions={radioQuestion}
        radiosSelected={radiosSelected || []}
        setRadiosSelected={setRadiosSelected}
        standFirst={standFirst}
        title={title}
      />
    </>
  )
}
