import type { PageFlowProps } from '../../../shared/types/toolTypes'

export const UkOrEuAirlineFlow: PageFlowProps = {
  PROGRESS_VALUE: 0,
  STEP: 1,
  VIEW_NAME: 'uk-or-eu-airline',
  REDUCERS: ['UPDATE_UK_OR_EU_AIRLINE'],
  MILESTONE: 'milestone | start',
}

export const ArriveDepartUKFlow: PageFlowProps = {
  PROGRESS_VALUE: 10,
  STEP: 2,
  VIEW_NAME: 'arrive-depart-uk',
  REDUCERS: ['UPDATE_UK_AIRPORT'],
  MILESTONE: '',
}

export const WhichAirportsFlow: PageFlowProps = {
  PROGRESS_VALUE: 15,
  STEP: 3,
  VIEW_NAME: 'which-airports',
  REDUCERS: ['UPDATE_AIRPORTS'],
  MILESTONE: '',
}

export const DelayedCancelledFlow: PageFlowProps = {
  PROGRESS_VALUE: 25,
  STEP: 4,
  VIEW_NAME: 'delayed-cancelled',
  REDUCERS: ['UPDATE_DELAYED_CANCELLED'],
  MILESTONE: '',
}

export const TimeBeforeCancelledFlow: PageFlowProps = {
  PROGRESS_VALUE: 30,
  STEP: 5,
  VIEW_NAME: 'time-before-cancelled',
  REDUCERS: ['UPDATE_TIME_BEFORE_CANCELLED'],
  MILESTONE: '',
}

export const EarlierLaterOriginalFlightFlow: PageFlowProps = {
  PROGRESS_VALUE: 35,
  STEP: 6,
  VIEW_NAME: 'earlier-later-original-flight',
  REDUCERS: ['UPDATE_EARLIER_LATER_ORIGINAL_FLIGHT'],
  MILESTONE: '',
}

export const HoursDifferenceFlow: PageFlowProps = {
  PROGRESS_VALUE: 40,
  STEP: 7,
  VIEW_NAME: 'hours-difference',
  REDUCERS: [''],
  MILESTONE: '',
}

export const NumPeopleClaimingForFlow: PageFlowProps = {
  PROGRESS_VALUE: 45,
  STEP: 8,
  VIEW_NAME: 'num-people-claiming-for',
  REDUCERS: ['UPDATE_NUM_PEOPLE_CLAIMING_FOR', 'UPDATE_AMOUNT_ENTITLED', 'UPDATE_FLIGHT_DISTANCE'],
  MILESTONE: '',
}

export const FlightNumberFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 9,
  VIEW_NAME: 'flight-number',
  REDUCERS: ['UPDATE_FLIGHT_NUMBER'],
  MILESTONE: '',
}

export const FlightDateFlow: PageFlowProps = {
  PROGRESS_VALUE: 55,
  STEP: 10,
  VIEW_NAME: 'flight-date',
  REDUCERS: ['UPDATE_FLIGHT_DATE'],
  MILESTONE: '',
}

export const WhichAirlineFlow: PageFlowProps = {
  PROGRESS_VALUE: 60,
  STEP: 11,
  VIEW_NAME: 'which-airline',
  REDUCERS: ['UPDATE_AIRLINE'],
  MILESTONE: '',
}

export const ScheduledFlightTimesFlow: PageFlowProps = {
  PROGRESS_VALUE: 65,
  STEP: 12,
  VIEW_NAME: 'scheduled-flight-times',
  REDUCERS: [''],
  MILESTONE: '',
}

export const ReasonGivenFlow: PageFlowProps = {
  PROGRESS_VALUE: 70,
  STEP: 13,
  VIEW_NAME: 'reason-given',
  REDUCERS: ['UPDATE_REASON_GIVEN'],
  MILESTONE: '',
}

export const ContactDetailsFlow: PageFlowProps = {
  PROGRESS_VALUE: 80,
  STEP: 14,
  VIEW_NAME: 'contact-details',
  REDUCERS: [''],
  MILESTONE: 'milestone | personal details',
}

export const SubmitSuccessfulFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 15,
  VIEW_NAME: 'journey-complete',
  REDUCERS: ['UPDATE_EMAIL_SENT'],
  MILESTONE: 'milestone | end | letter sent',
}

export const ToolFeedbackFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 16,
  VIEW_NAME: 'feedback-form',
  REDUCERS: [''],
  MILESTONE: 'milestone | feedback start',
}

export const ToolFeedbackAudienceFlow: PageFlowProps = {
  PROGRESS_VALUE: 90,
  STEP: 17,
  VIEW_NAME: 'feedback-audience-form',
  REDUCERS: [''],
  MILESTONE: '',
}

export const ToolFeedbackThankYouFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 18,
  VIEW_NAME: 'feedback-form-thankyou',
  REDUCERS: [''],
  MILESTONE: '',
}

export const LimitedOptionsFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 19,
  VIEW_NAME: 'limited-options',
  REDUCERS: [''],
  MILESTONE: 'milestone | next steps | end',
}

export const AllFlows = [
  UkOrEuAirlineFlow,
  ArriveDepartUKFlow,
  WhichAirportsFlow,
  DelayedCancelledFlow,
  TimeBeforeCancelledFlow,
  EarlierLaterOriginalFlightFlow,
  HoursDifferenceFlow,
  NumPeopleClaimingForFlow,
  FlightNumberFlow,
  FlightDateFlow,
  WhichAirlineFlow,
  ScheduledFlightTimesFlow,
  ReasonGivenFlow,
  ContactDetailsFlow,
  SubmitSuccessfulFlow,
  ToolFeedbackFlow,
  ToolFeedbackAudienceFlow,
  ToolFeedbackThankYouFlow,
  LimitedOptionsFlow,
]
