export const formatToolName = (toolName: string) => {
  switch (toolName) {
    case 'FaultyGoodsTool':
      return 'faulty goods'
    case 'Section75':
      return 's75 and chargeback'
    case 'HolidayComplaint':
      return 'holiday complaint'
    case 'ParkingTicket':
      return 'parking ticket'
    case 'DeliveryComplaint':
      return 'delivery complaint'
    case 'FlightDelayCancellationCompensation':
      return 'flight delay and cancellation compensation'
    case 'MisSoldPackagedBankAccount':
      return 'mis-sold packaged bank account'
    case 'ImpactSurvey':
      return 'impact survey'
    case 'BookAppointment':
      return 'book appointment'
    case 'HomeImprovement':
      return 'home improvement'
    case 'MyMoneyHealthCheck':
      return 'my money health check'
    case 'BroadbandSpeed':
      return 'broadband speed'
    case 'CarProblem':
      return 'car problem'
    case 'ScamSharer':
      return 'scam sharer'
    case 'EnergyCostCalculator':
      return 'energy cost calculator'
    case 'PriceRiseCalculator':
      return 'mobile and broadband price rise calculator'
    default:
      return toolName
  }
}
