import type { PageFlowProps } from '../../../shared/types/toolTypes'

export const HowMuchFlow: PageFlowProps = {
  PROGRESS_VALUE: 0,
  STEP: 1,
  VIEW_NAME: 'how-much',
  REDUCERS: [''],
  MILESTONE: 'milestone | start',
}

export const PaymentPredictionFlow: PageFlowProps = {
  PROGRESS_VALUE: 0,
  STEP: 2,
  VIEW_NAME: 'payment-prediction',
  REDUCERS: [''],
  MILESTONE: 'milestone | end | letter sent',
}

export const AllFlows = [HowMuchFlow, PaymentPredictionFlow]
