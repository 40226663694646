import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PreviewLetter } from '../../../../shared/components/PreviewLetter'
import { SubmitSuccessfulPage } from '../../../../shared/pages/SubmitSuccessfulPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { SubmitSuccessProps } from '../../../../shared/types/toolTypes'
import { ToolFeedbackFlow, ToolFeedbackThankYouFlow } from '../../pageFlowData'
import { composeEmailBody } from './composeEmailBody'
import { feedbackData } from './data'

export const SubmitSuccessful: FunctionComponent<SubmitSuccessProps> = () => {
  const toolContext = useContext(ToolContext)
  const {
    state: {
      autoEmailer,
      account,
      toolFeedback: { showFeedback },
      initial: { user, emailSent },
      claim,
    },
    dispatch,
  } = toolContext

  const { nameOfBank } = account
  const { body, imgObj, title } = feedbackData
  const data = composeEmailBody({ account, claim, user, autoEmailer })

  const handleStepChoice = (id: string) => {
    if (id === 'feedback-yes') {
      return { progress: ToolFeedbackFlow.PROGRESS_VALUE, step: ToolFeedbackFlow.STEP }
    }

    return {
      progress: ToolFeedbackThankYouFlow.PROGRESS_VALUE,
      steps: ToolFeedbackThankYouFlow.STEP,
    }
  }

  const handleSubmit = (id: string) => {
    const { progress, step } = handleStepChoice(id)

    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const standFirstCopy = emailSent
    ? `A copy of your claim letter has been sent to ${user.emailAddress}`
    : 'Due to an error, we were unable to send your email. However, you can get a copy of your complaint letter below'

  return (
    <div data-testid="submit-successful">
      <SubmitSuccessfulPage
        feedbackBody={body}
        feedbackImgObj={imgObj}
        feedbackTitle={title}
        handleSubmit={handleSubmit}
        letterBody={data.body}
        letterTitle="Your complaint email"
        list={[
          "Try to include copies of bank statements showing fees you've paid for the account. This supporting evidence could help your claim.",
          `Send the letter to ${nameOfBank} via email or post.`,
          `Give ${nameOfBank} eight weeks to respond.`,
          "If you’re not happy with the answer you get from your bank, or it doesn't reply to you within eight weeks, you can <a class='sb-link-primary' href='https://www.which.co.uk/consumer-rights/advice/can-i-take-my-financial-complaint-to-the-financial-ombudsman-a3Uwd1C1No7J'><span class='sb-link-animation-wrapper'>take your complaint to the Financial Ombudsman Service (FOS)</span></a>. Making a complaint to the FOS is free and you can do it yourself.",
        ]}
        listAppearance="bullet"
        showFeedback={showFeedback}
        standFirst={standFirstCopy}
        title="Your next steps"
      >
        <PreviewLetter data={data} />
      </SubmitSuccessfulPage>
    </div>
  )
}
