import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { FormEntryPage } from '../../../../shared/pages/FormEntryPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryInputRef, SingleInputState } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { TicketPurchasedFlow, VehicleRegFlow } from '../../pageFlowData'
import { data } from './data'

export const VehicleReg: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    issue,
  } = state

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })
  const VehicleRegInput = useRef<FormEntryInputRef>(null)

  const getValue = () => {
    return cleanText(VehicleRegInput?.current?.formEntryInput?.value || '')
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${VehicleRegFlow.VIEW_NAME} | PII`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_VEHICLE_REG', data: value })
    dispatch({ type: 'UPDATE_STEP', data: TicketPurchasedFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: TicketPurchasedFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    const value = getValue()
    const isValid: boolean = isNotEmpty(value) && value.length < 11

    setInputData({
      renderError: !isValid,
      value: value,
    })

    if (isValid) {
      handleTracking()
      handleDispatch(value)
    }
  }

  const { renderError, value: errorValue } = inputData
  const { imgObj, title } = data

  return (
    <FormEntryPage
      buttonText="Next"
      defaultValue={issue?.vehicleReg}
      fieldData={{
        renderError: renderError,
        value: errorValue,
      }}
      handleSubmit={handleSubmit}
      id="vehicle-reg"
      imgObj={imgObj}
      isFocused
      placeholderText=""
      ref={VehicleRegInput}
      testId="vehicle-reg"
      title={title}
    />
  )
}
