import type { InfoBoxProps } from '../../../../../shared/types/toolTypes'

const infoBox: InfoBoxProps = {
  heading: 'What is a packaged bank account?',
  borderColour: 'blue',
}
export const data = {
  buttons: [
    {
      label: 'Yes',
      id: 'yes',
    },
    {
      label: 'No',
      id: 'no',
    },
  ],
  infoBoxData: {
    infoBox: infoBox,
    infoBoxParagraphs: [
      {
        text: "Packaged bank accounts are accounts packaged with extra features, such as travel insurance. They often have names that feature words like ‘premium’ or ‘gold’. And there’s also usually a monthly fee. This doesn't include overdraft fees or interest.",
      },
    ],
  },
  imgObj: {
    alt: 'A finger touching a smartphone screen',
    src: 'https://media.product.which.co.uk/prod/images/original/48970d50e09d-mis-sold-account-step-1.jpg',
  },
  title: 'Have you ever paid a monthly fee for your bank account?',
}
