// A separate object for the infoBox data is used due to the typing of the borderColour attribute
import type { InfoBoxProps } from '../../../../../shared/types/toolTypes'
import styles from '../../../../../shared/utils/renderInfoBox/renderInfoBox.module.scss'

const infoBoxPackage: InfoBoxProps = {
  heading: 'Who did you book with?',
  borderColour: 'blue',
}

const infoBoxNotPackage: InfoBoxProps = {
  heading: 'Who are you complaining to?',
  borderColour: 'blue',
}

export const holidayProviderPackageData = {
  title: "What's the name of the holiday company you booked with?",
  infoBoxData: {
    infoBox: infoBoxPackage,
    infoBoxParagraphs: [
      {
        text: "This is the company you paid for the holiday - it's not necessarily the company who provided the flights, hotel, car hire or other parts of the holiday.",
      },
    ],
  },
}

export const holidayProviderNotPackageData = {
  title: 'What company are you complaining to?',
  infoBoxData: {
    infoBox: infoBoxNotPackage,
    infoBoxParagraphs: [
      {
        classname: `${styles.renderInfoBoxParagraphs}`,
        text: "Because you didn't book a 'package' holiday, there may be multiple companies responsible for your holiday.",
      },
      {
        classname: `${styles.renderInfoBoxParagraphs}`,
        text: 'You will need to identify which you wish to complain to. If, for example, there was a problem with your accommodation, you would complain to the company you paid for accommodation.',
      },
      {
        text: 'If there is multiple problems with your holiday, you may have to submit multiple complaints.',
      },
    ],
  },
}
