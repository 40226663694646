export const getStepData = (toolId: string) => {
  switch (toolId) {
    case 'FaultyGoodsTool':
      return {
        toolName: 'Faulty goods',
        radioExperience: 'a claim',
        radioExperienceSingle: 'claim',
        textareaExperience: 'making a claim',
      }
    case 'Section75':
      return {
        toolName: 'Section 75 & chargeback',
        radioExperience: 'a claim',
        radioExperienceSingle: 'claim',
        textareaExperience: 'making a claim',
      }
    case 'HolidayComplaint':
      return {
        toolName: 'Holiday complaint',
        radioExperience: 'a complaint/claim',
        radioExperienceSingle: 'complaint/claim',
        textareaExperience: 'making a claim',
      }
    case 'ParkingTicket':
      return {
        toolName: 'Parking ticket',
        radioExperience: 'an appeal',
        radioExperienceSingle: 'appeal',
        textareaExperience: 'appealing your parking ticket',
      }
    case 'DeliveryComplaint':
      return {
        toolName: 'Delivery complaint',
        radioExperience: 'a complaint',
        radioExperienceSingle: 'complaint',
        textareaExperience: 'making your delivery complaint',
      }
    case 'FlightDelayCancellationCompensation':
      return {
        toolName: 'Flight delay & cancellation compensation',
        radioExperience: 'a claim',
        radioExperienceSingle: 'claim',
        textareaExperience: 'making a claim',
      }
    case 'MisSoldPackagedBankAccount':
      return {
        toolName: 'Mis-sold packaged bank account',
        radioExperience: 'a complaint/claim',
        radioExperienceSingle: 'complaint/claim',
        textareaExperience: 'making a complaint/claim',
      }
    case 'BroadbandSpeed':
      return {
        toolName: 'Broadband speed',
        radioExperience: 'a complaint',
        radioExperienceSingle: 'complaint',
        textareaExperience: 'making a complaint',
      }
    case 'HomeImprovement':
      return {
        toolName: 'Home improvement',
        radioExperience: 'a complaint',
        radioExperienceSingle: 'complaint',
        textareaExperience: 'making your home improvement',
      }
    default:
      return {
        toolName: '',
        radioExperience: 'a claim',
        radioExperienceSingle: 'claim',
        textareaExperience: 'making a claim',
      }
  }
}
