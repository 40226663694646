import type { FunctionComponent } from 'react'
import React, { useContext, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import { FormEntryItem } from '../../../../shared/components/FormEntryItem'
import { ImageWithQuestion } from '../../../../shared/components/ImageWithQuestion'
import { NextButton } from '../../../../shared/components/NextButton'
import { ToolContext } from '../../../../shared/state/appContext'
import type { SingleInputState } from '../../../../shared/types/toolTypes'
import { autocomplete } from '../../../../shared/utils/autoComplete/autoComplete'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { ScheduledFlightTimesFlow, WhichAirlineFlow } from '../../pageFlowData'
import { data } from './data'
import { airlineData } from './data/airlineData'
import styles from './WhatAirline.module.scss'

export const WhatAirline: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    flight: { airline },
  } = state

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const autoCompleteRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    /* istanbul ignore next */
    if (autoCompleteRef && autoCompleteRef.current) {
      autocomplete({
        autoCompleteRef,
        searchItemsList: airlineData,
        setIsMenuOpen,
        elementId: 'what-airline-input',
      })
    }
    return () => {
      /* istanbul ignore next */
      setIsMenuOpen(false) //Fix for potential memory leak
    }
  }, [])

  const getValue = () => {
    return cleanText(autoCompleteRef?.current?.value || '')
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${WhichAirlineFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_STEP', data: ScheduledFlightTimesFlow.STEP })
    dispatch({ type: 'UPDATE_AIRLINE', data: value })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: ScheduledFlightTimesFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    const value = getValue()
    const airlineHasError = !isNotEmpty(value)

    setInputData({
      renderError: airlineHasError,
      value: value,
    })

    if (!airlineHasError) {
      handleTracking(value)
      handleDispatch(value)
    }
  }

  const { renderError, value: errorValue } = inputData
  const { imgObj, title } = data

  return (
    <div
      className={classNames({ [styles.whatAirlineMenuOpen]: isMenuOpen })}
      data-testid="what-airline-page"
    >
      <ImageWithQuestion image={imgObj} title={title} />
      <FormEntryItem
        displayText=""
        enterKeyHandler={handleSubmit}
        fieldData={{
          renderError: renderError,
          value: errorValue,
        }}
        id="what-airline"
        isFocused={true}
        placeholderText=""
        ref={autoCompleteRef}
        srLabel={title}
        stateValue={airline}
        type="text"
      />

      <NextButton buttonText="Next" onClick={handleSubmit} />
    </div>
  )
}
