import type { FunctionComponent } from 'react'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import { ErrorMessage } from '../../../../shared/components/ErrorMessage'
import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { pageSubmission } from '../../../../shared/utils/EngagingNetwork/pageSubmission'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { pageData } from '../../pageData'
import { ContactDetailsFlow, GetInTouchFlow, SubmitSuccessfulFlow } from '../../pageFlowData'
import { getInTouchData } from './data'
import styles from './GetInTouch.module.scss'

export const GetInTouch: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const [errorCount, setErrorCount] = useState(0)
  const {
    initial: { entryToolName },
    victim,
    scam,
  } = state

  const buildPageDataState = (userData: any) => {
    const victimState = { ...victim, getInTouch: 'N' }

    const pageDataState = {
      scam,
      victim: victimState,
      optIns: { singleConsentOptIn: false },
      user: { ...userData },
    }

    return pageDataState
  }

  const handleENSubmit = async () => {
    const submissionFormId = 111358

    const userData = {
      firstName: 'anonymous',
      lastName: 'anonymous',
      emailAddress: 'anonymous@anonymous.com',
      phoneNumber: 'anonymous',
    }

    const pageDataState = buildPageDataState(userData)

    const structuredData = pageData(pageDataState)
    const ENResponse = await pageSubmission(structuredData, submissionFormId)

    if (ENResponse && (ENResponse.ok || ENResponse.status === 'SUCCESS')) {
      handleDispatch(SubmitSuccessfulFlow.PROGRESS_VALUE, SubmitSuccessfulFlow.STEP)
    } else {
      setErrorCount(errorCount + 1)
    }
  }

  const handleTracking = (id: string) => {
    const trackingAnswer = `question | ${GetInTouchFlow.VIEW_NAME} | ${id}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = (id: string) => {
    dispatch({ type: 'UPDATE_GET_IN_TOUCH', data: id })
    handleTracking(id)

    if (id === 'Y') {
      handleDispatch(ContactDetailsFlow.PROGRESS_VALUE, ContactDetailsFlow.STEP)
    } else {
      handleENSubmit()
    }
  }

  const handleDispatch = useCallback(
    (progress: number, step: number) => {
      dispatch({ type: 'UPDATE_STEP', data: step })
      dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
    },
    [dispatch]
  )

  useEffect(() => {
    if (errorCount > 1) {
      // If they've gotten two or more errors, go to NextSteps anyway
      handleDispatch(SubmitSuccessfulFlow.PROGRESS_VALUE, SubmitSuccessfulFlow.STEP)
    }
  }, [errorCount, handleDispatch])

  const { buttons, standFirst, title } = getInTouchData

  return (
    <>
      <PillButtonPage
        buttons={buttons}
        defaultValue={victim.getInTouch ? victim.getInTouch : ''}
        handleSubmit={handleSubmit}
        imgObj={{
          alt: 'A person talking to someone via a video call',
          src: 'https://media.product.which.co.uk/prod/images/original/b6b3e304c407-team-to-get-in-touch.jpg',
        }}
        standFirst={standFirst}
        title={title}
      />
      {errorCount > 0 && (
        <span className={styles.enError}>
          <ErrorMessage errorText="Something went wrong, please try again" />
        </span>
      )}
    </>
  )
}
