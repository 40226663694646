export const data = {
  buttons: [
    {
      label: 'Yes',
      id: 'yes',
    },
    {
      label: 'No',
      id: 'no',
    },
  ],
  imgObj: {
    alt: 'A family going on holiday',
    src: 'https://media.product.which.co.uk/prod/images/original/gm-b90b537f-1188-4e56-9734-5b1c80d40ebe-holiday-complaint1.jpg',
  },
  title: 'Have you already been on holiday?',
}
