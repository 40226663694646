import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../../shared/state/appContext'
import globalStyles from '../../../../../shared/styles/GlobalStyles.module.scss'
import { trackAppointmentBookingSelection } from '../../../../../shared/utils/tracking/tracking'
import {
  ContactDetailsFlow,
  DescribeCaseFlow,
  HomeDetailFlow,
  HomeLocationFlow,
  HomeQueryFlow,
  LimitedOptionsFlow,
} from '../../../pageFlowData'
import { option1, option2, option3, option4 } from './data'

export const HomeQuery: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    home: { homeTopic, homeQuery },
    triage: { type },
  } = state

  const handleTracking = (id: string, index: number) => {
    trackAppointmentBookingSelection(type, HomeQueryFlow.VIEW_NAME, { id, index })
  }

  const chooseStep = (id: string) => {
    switch (id) {
      case 'queries-regarding-other':
      case 'assistance-with-housing-association-scotland':
      case 'council-tenancies':
      case 'rights-of-access':
      case 'court-tribunal':
      case 'water-supply-ireland':
      case 'wayleave-agreement':
      case 'issue-with-grid':
        return LimitedOptionsFlow
      case 'general-assistance-with-private':
      case 'assistance-with-lodger':
      case 'court-procedure-private':
      case 'court-procedure-landlord':
      case 'issue-with-student-accommodation':
      case 'assistance-with-tenants':
      case 'assistance-with-lodger-landlord':
        return HomeLocationFlow
      case 'a-planning-issue':
        return HomeDetailFlow
      case 'assistance-with-housing-association-uk':
      case 'assistance-with-housing-association-wales':
        return ContactDetailsFlow
      default:
        return DescribeCaseFlow
    }
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_HOME_QUERY', data: id })
    const step = chooseStep(id)
    dispatch({ type: 'UPDATE_STEP', data: step.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: step.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string, index: number) => {
    handleTracking(id, index)
    handleDispatch(id)
  }

  const chooseData = () => {
    switch (homeTopic) {
      case 'utilities':
        return option4
      case 'neighbour-issue':
        return option3
      case 'issue-with-landlord':
        return option2
      default:
        return option1
    }
  }
  const { buttons, imgObj, title } = chooseData()

  return (
    <div className={globalStyles.fullWidthButton}>
      <PillButtonPage
        buttons={buttons}
        defaultValue={homeQuery ? homeQuery : ''}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        title={title}
      />
    </div>
  )
}
