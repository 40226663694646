import type { PageFlowProps } from '../../../shared/types/toolTypes'

export const ComplaintTypeFlow: PageFlowProps = {
  PROGRESS_VALUE: 0,
  STEP: 1,
  VIEW_NAME: 'complaint-type',
  REDUCERS: ['UPDATE_COMPLAINT'],
  MILESTONE: 'milestone | start',
}

export const WorkTraderFlow: PageFlowProps = {
  PROGRESS_VALUE: 10,
  STEP: 2,
  VIEW_NAME: 'work-trader',
  REDUCERS: ['UPDATE_TRADER'],
  MILESTONE: '',
}

export const WhichTraderFlow: PageFlowProps = {
  PROGRESS_VALUE: 15,
  STEP: 3,
  VIEW_NAME: 'which-trader',
  REDUCERS: ['UPDATE_WHICH_TRADER'],
  MILESTONE: '',
}

export const JobTypeFlow: PageFlowProps = {
  PROGRESS_VALUE: 20,
  STEP: 4,
  VIEW_NAME: 'job-type',
  REDUCERS: ['UPDATE_JOB'],
  MILESTONE: '',
}

export const WhatWorkFlow: PageFlowProps = {
  PROGRESS_VALUE: 30,
  STEP: 5,
  VIEW_NAME: 'what-work',
  REDUCERS: ['UPDATE_WORK'],
  MILESTONE: '',
}

export const WorkBeganFlow: PageFlowProps = {
  PROGRESS_VALUE: 40,
  STEP: 6,
  VIEW_NAME: 'work-began',
  REDUCERS: ['UPDATE_DATE'],
  MILESTONE: '',
}

export const CompanyNameFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 7,
  VIEW_NAME: 'company-name',
  REDUCERS: ['UPDATE_NAME'],
  MILESTONE: '',
}

export const ContractNumberFlow: PageFlowProps = {
  PROGRESS_VALUE: 60,
  STEP: 8,
  VIEW_NAME: 'contract-number',
  REDUCERS: ['UPDATE_NUMBER'],
  MILESTONE: '',
}

export const WhatProblemFlow: PageFlowProps = {
  PROGRESS_VALUE: 70,
  STEP: 9,
  VIEW_NAME: 'what-problem',
  REDUCERS: ['UPDATE_PROBLEM'],
  MILESTONE: '',
}

export const UsedCardFlow: PageFlowProps = {
  PROGRESS_VALUE: 80,
  STEP: 10,
  VIEW_NAME: 'used-card',
  REDUCERS: ['UPDATE_CARD'],
  MILESTONE: '',
}

export const ContactDetailsFlow: PageFlowProps = {
  PROGRESS_VALUE: 90,
  STEP: 11,
  VIEW_NAME: 'contact-details',
  REDUCERS: [''],
  MILESTONE: 'milestone | personal details',
}

export const LimitedOptionsFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 12,
  VIEW_NAME: 'limited-options',
  REDUCERS: [''],
  MILESTONE: 'milestone | next steps | end',
}

export const SubmitSuccessfulFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 13,
  VIEW_NAME: 'journey-complete',
  REDUCERS: ['UPDATE_EMAIL_SENT'],
  MILESTONE: 'milestone | letter sent',
}

export const ToolFeedbackFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 14,
  VIEW_NAME: 'feedback-form',
  REDUCERS: [''],
  MILESTONE: 'milestone | feedback start',
}

export const ToolFeedbackAudienceFlow: PageFlowProps = {
  PROGRESS_VALUE: 90,
  STEP: 15,
  VIEW_NAME: 'feedback-audience-form',
  REDUCERS: [''],
  MILESTONE: '',
}

export const ToolFeedbackThankYouFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 16,
  VIEW_NAME: 'feedback-form-thankyou',
  REDUCERS: [''],
  MILESTONE: '',
}

export const AllFlows = [
  ComplaintTypeFlow,
  WorkTraderFlow,
  JobTypeFlow,
  WhatWorkFlow,
  WhichTraderFlow,
  WorkBeganFlow,
  CompanyNameFlow,
  ContractNumberFlow,
  WhatProblemFlow,
  UsedCardFlow,
  SubmitSuccessfulFlow,
  ContactDetailsFlow,
  LimitedOptionsFlow,
  ToolFeedbackFlow,
  ToolFeedbackAudienceFlow,
  ToolFeedbackThankYouFlow,
]
