import React from 'react'

import { ToolFeedbackAudiencePage } from '../../../shared/pages/ToolFeedbackAudiencePage'
import { toolFeedbackAudienceData } from '../../../shared/pages/ToolFeedbackAudiencePage/data'
import { ToolFeedbackPage } from '../../../shared/pages/ToolFeedbackPage'
import { toolFeedbackData } from '../../../shared/pages/ToolFeedbackPage/data/flightDelayCancellationData'
import { ToolFeedbackThankYouPage } from '../../../shared/pages/ToolFeedbackThankYouPage'
import { ArriveDepartUK } from '../PageComponents/ArriveDepartUK'
import { ContactDetails } from '../PageComponents/ContactDetails'
import { DelayedOrCancelled } from '../PageComponents/DelayedOrCancelled'
import { EarlierLaterOriginalFlight } from '../PageComponents/EarlierLaterOriginalFlight'
import { FlightDate } from '../PageComponents/FlightDate'
import { FlightNumber } from '../PageComponents/FlightNumber'
import { HoursDifference } from '../PageComponents/HoursDifference'
import { LimitedOptions } from '../PageComponents/LimitedOptions'
import { NumPeopleClaimingFor } from '../PageComponents/NumPeopleClaimingFor'
import { ReasonGiven } from '../PageComponents/ReasonGiven'
import { ScheduledFlightTimes } from '../PageComponents/ScheduledFlightTimes'
import { SubmitSuccessful } from '../PageComponents/SubmitSuccessful'
import { TimeBeforeCancelled } from '../PageComponents/TimeBeforeCancelled'
import { UkOrEuAirline } from '../PageComponents/UkOrEuAirline'
import { WhatAirline } from '../PageComponents/WhatAirline'
import { WhichAirports } from '../PageComponents/WhichAirports/WhichAirport'
import {
  ArriveDepartUKFlow,
  ContactDetailsFlow,
  DelayedCancelledFlow,
  EarlierLaterOriginalFlightFlow,
  FlightDateFlow,
  FlightNumberFlow,
  HoursDifferenceFlow,
  LimitedOptionsFlow,
  NumPeopleClaimingForFlow,
  ReasonGivenFlow,
  ScheduledFlightTimesFlow,
  SubmitSuccessfulFlow,
  TimeBeforeCancelledFlow,
  ToolFeedbackAudienceFlow,
  ToolFeedbackFlow,
  ToolFeedbackThankYouFlow,
  UkOrEuAirlineFlow,
  WhichAirlineFlow,
  WhichAirportsFlow,
} from '../pageFlowData'

export const useRenderStep = (step: number, CRFooter: React.ReactNode) => {
  switch (step) {
    case UkOrEuAirlineFlow.STEP:
      return <UkOrEuAirline />
    case ArriveDepartUKFlow.STEP:
      return <ArriveDepartUK />
    case WhichAirportsFlow.STEP:
      return <WhichAirports />
    case DelayedCancelledFlow.STEP:
      return <DelayedOrCancelled />
    case TimeBeforeCancelledFlow.STEP:
      return <TimeBeforeCancelled />
    case EarlierLaterOriginalFlightFlow.STEP:
      return <EarlierLaterOriginalFlight />
    case HoursDifferenceFlow.STEP:
      return <HoursDifference />
    case NumPeopleClaimingForFlow.STEP:
      return <NumPeopleClaimingFor />
    case FlightNumberFlow.STEP:
      return <FlightNumber />
    case FlightDateFlow.STEP:
      return <FlightDate />
    case WhichAirlineFlow.STEP:
      return <WhatAirline />
    case ScheduledFlightTimesFlow.STEP:
      return <ScheduledFlightTimes />
    case ReasonGivenFlow.STEP:
      return <ReasonGiven />
    case ContactDetailsFlow.STEP:
      return <ContactDetails />
    case SubmitSuccessfulFlow.STEP:
      return <SubmitSuccessful />
    case ToolFeedbackFlow.STEP:
      return (
        <ToolFeedbackPage
          pageData={toolFeedbackData}
          toolFeedbackAudienceFlowData={ToolFeedbackAudienceFlow}
          toolFeedbackFlowData={ToolFeedbackFlow}
          toolName="FlightDelayCancellationCompensation"
        />
      )
    case ToolFeedbackThankYouFlow.STEP:
      return <ToolFeedbackThankYouPage toolFeedbackThankYouFlowData={ToolFeedbackThankYouFlow} />
    case ToolFeedbackAudienceFlow.STEP:
      return (
        <ToolFeedbackAudiencePage
          pageData={toolFeedbackAudienceData}
          toolFeedbackAudienceFlowData={ToolFeedbackAudienceFlow}
          toolFeedbackThankYouFlowData={ToolFeedbackThankYouFlow}
          toolName="FlightDelayCancellationCompensation"
        />
      )
    case LimitedOptionsFlow.STEP:
      return <LimitedOptions />

    default:
      return <div>FlightDelayCancellation Tool {CRFooter}</div>
  }
}
