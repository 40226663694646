import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { TextAreaPage } from '../../../../shared/pages/TextAreaPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryTextAreaRef } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { validateCharCount } from '../../../../shared/utils/validateCharCount'
import { HowAccountSoldFlow, WhyMisSoldAccountFlow } from '../../pageFlowData'
import { whyMisSoldData } from './data'

export const WhyMisSoldAccount: FunctionComponent = () => {
  const { imgObj, title, placeholderText, descriptiveText, note } = whyMisSoldData
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    claim: { whyMisSold },
  } = state

  const [isInputValid, setIsInputValid] = useState<boolean>(true)
  const WhyMisSoldRef = useRef<FormEntryTextAreaRef>(null)

  const handleFieldChange = () => {
    const value = getCleanValue(WhyMisSoldRef.current?.formEntryTextarea?.value)

    const isValid: boolean = validateCharCount(value.length, 2500)
    const fieldNotEmpty: boolean = isNotEmpty(value)
    if (fieldNotEmpty && isValid) {
      setIsInputValid(true)
    }
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_WHY_MIS_SOLD_ACCOUNT', data: value })
    dispatch({ type: 'UPDATE_STEP', data: HowAccountSoldFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: HowAccountSoldFlow.PROGRESS_VALUE })
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${WhyMisSoldAccountFlow.VIEW_NAME} | PII`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = () => {
    const value: string = getCleanValue(WhyMisSoldRef.current?.formEntryTextarea?.value)
    const isValid: boolean = validateCharCount(value.length, 2500)

    if (!isValid) {
      setIsInputValid(false)
      return
    }

    setIsInputValid(true)
    handleTracking()
    handleDispatch(value)
  }

  return (
    <div data-testid="why-mis-sold">
      <TextAreaPage
        buttonText="Next"
        defaultValue={whyMisSold}
        descriptiveText={descriptiveText}
        handleChange={handleFieldChange}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        isFocused
        isInputValid={isInputValid}
        maxChars={2500}
        noteTop={note}
        optional
        placeholderText={placeholderText}
        ref={WhyMisSoldRef}
        title={title}
      />
    </div>
  )
}
