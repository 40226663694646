import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { CalculationPage } from '../../../../shared/pages/CalculationPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { AllFlows } from '../../pageFlowData'
import styles from './PaymentPrediction.module.scss'

export const PaymentPrediction: FunctionComponent = () => {
  const { state } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    energy: { howMuch, multiplier },
  } = state
  const currentMonthlyPrice = Number(howMuch)
  let prevMonthlyCost = 0
  let runningAnnualCost = 0

  const resultData = multiplier.map((item, index) => {
    const { multiplier: multiplierValue, period, annual } = item

    if (multiplierValue) {
      const cost =
        index === 0
          ? currentMonthlyPrice * parseFloat(multiplierValue)
          : prevMonthlyCost * parseFloat(multiplierValue)

      prevMonthlyCost = cost
      runningAnnualCost += cost

      return { text: period, amount: cost.toFixed(2) }
    }

    const { endDate, startDate } = annual
    const annualText =
      endDate !== undefined && startDate !== undefined
        ? `Total bill ${startDate} to ${endDate}`
        : 'Total bill over a 12 month period'
    return {
      text: annualText,
      amount: (runningAnnualCost * 3).toFixed(2),
      total: true,
    }
  })

  const RenderTerms = () => (
    <>
      Predictions based on forecasting by Cornwall Insight for Ofgem’s price cap on variable tariff
      rates for a dual fuel, direct debit, medium usage customer. These forecasts may change over
      time. Your bills will vary based on the rates set by your energy supplier and your usage for
      each fuel. If you are a low energy user your bills will be slightly lower than predicted and
      if you are a high energy user they will be slightly higher. If you are struggling to afford
      your energy bills, visit{' '}
      <a
        className="sb-link-primary"
        href="https://www.which.co.uk/reviews/cutting-your-energy-bills/article/how-to-save-on-your-energy-bill/help-if-you-re-struggling-to-pay-your-energy-bill-aXsId8V8dMv4"
        target="_blank"
      >
        <span className="sb-link-animation-wrapper">our guide</span>
      </a>{' '}
      to see what help is available.
    </>
  )

  return (
    <div className={styles.paymentPrediction} data-testid="payment-prediction-page">
      <CalculationPage
        backButtonData={{
          toolName: entryToolName,
          allFlows: AllFlows,
          text: 'Recalculate',
        }}
        imgObj={{
          alt: '',
          src: 'https://media.product.which.co.uk/prod/images/original/06c9193d24b3-energy380x200.jpg',
        }}
        resultData={resultData}
        standfirst={`Based on your current payments of £${currentMonthlyPrice.toFixed(
          2
        )} per month, it's likely that you will pay the following over the next 12 months:`}
        terms={RenderTerms()}
        title="Energy Cost Calculator Results"
      />
    </div>
  )
}
