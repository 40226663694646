import type { ChangeEvent, FunctionComponent, KeyboardEvent } from 'react'
import React from 'react'
import { Radio } from '@which/seatbelt'

import classNames from 'classnames'

import globalStyles from '../../styles/GlobalStyles.module.scss'
import type { SingleRadioGroupProps } from '../../types/toolTypes'
import { handleKeyPress } from '../../utils/keyDownHandler/handleKeyPress'
import { ErrorMessage } from '../ErrorMessage'
import styles from './SingleRadioGroup.module.scss'

export const SingleRadioGroup: FunctionComponent<SingleRadioGroupProps> = ({
  buttons,
  handleChange,
  isSubmitValid = true,
  stateValue = '',
  handleSubmit,
  fullWidth = false,
}) => {
  return (
    <div
      className={classNames(styles.singleRadioGroup, globalStyles.spacing40, {
        [styles.singleRadioGroupContainerError]: !isSubmitValid,
      })}
    >
      <div className={styles.singleRadioGroupContent} data-testid="single-radio-group">
        <div
          className={classNames(styles.singleRadioGroupContainer, {
            [styles.singleRadioGroupContainerFullWidth]: fullWidth,
          })}
        >
          {buttons.map((button) => {
            const { label, id, isFocused = false } = button
            return (
              <Radio
                autoFocus={isFocused}
                checked={stateValue === id}
                className={styles.singleRadioGroupItem}
                data-testid="tool-radios"
                id={id}
                key={id}
                label={label}
                name={id}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const target = e.target as HTMLInputElement
                  handleChange && handleChange(target.id)
                }}
                onKeyDown={(e: KeyboardEvent<Element>) => handleKeyPress(e, handleSubmit)}
                value={id}
              />
            )
          })}
        </div>
        {!isSubmitValid && (
          <div className={styles.singleRadioGroupErrorContainer}>
            <ErrorMessage
              errorText="Please select which options apply to continue"
              styleClasses={classNames(styles.singleRadioGroupError)}
            />
          </div>
        )}
      </div>
    </div>
  )
}
