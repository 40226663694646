// A separate object for the infoBox data is used due to the typing of the borderColour attribute
import type { InfoBoxProps } from '../../../../../shared/types/toolTypes'

export const infoBox: InfoBoxProps = {
  heading: 'What is a package holiday?',
  borderColour: 'blue',
}

export const holidayTypeData = {
  holidayTypeButtons: [
    {
      label: 'Yes',
      id: 'package',
    },
    {
      label: 'No',
      id: 'non-package',
    },
  ],
  title: 'Did you book a package holiday?',
  image: {
    imgSrc:
      'https://media.product.which.co.uk/prod/images/original/gm-eac380d9-5c15-4d27-9d7a-aa66c9a9984c-holiday-complaint-2.jpg',
    imgAlt: '3 friends on a ski lift',
  },
  infoBox,
  infoBoxParagraphs: [
    {
      text: 'If you booked more than one part of your holiday through the same company, it counts as a package holiday. What could this include? Transport (i.e. flights, ferry or other), accommodation, car hire, and/or another significant part of your trip.',
    },
  ],
}
