import type { InfoBoxProps } from '../../../../../shared/types/toolTypes'

export const data = {
  buttons: [
    {
      label: 'Yes',
      id: 'yes',
    },
    {
      label: 'No',
      id: 'no',
    },
  ],
  title: 'Are you in a fixed-term broadband contract?',
  infoBox: {
    heading: 'What is a fixed-term broadband contract?',

    borderColour: 'blue',
  } as InfoBoxProps,
  infoBoxParagraphs: [
    {
      text: "This is the initial contract period you signed up for when you took out a broadband package - typically 18 or 24 months. Once this period is over, you move to a rolling contract with the provider, unless you switch or recontract. When on a rolling contract, you won't face any early exit fees for switching, so you can hunt around for a better deal.",
    },
  ],
  imgObj: {
    alt: 'A person staring at a computer on a desk',
    src: 'https://media.product.which.co.uk/prod/images/original/bb7cfa4dc817-broadband.jpg',
  },
}
