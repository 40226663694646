import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { BeenOnHolidayFlow, HolidayTypeFlow, LimitedOptionsFlow } from '../../pageFlowData'
import { data } from './data'

export const BeenOnHoliday: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    holiday: { complete },
  } = state

  const handleStepChoice = (value: string) => {
    if (value === 'yes') {
      return { progress: HolidayTypeFlow.PROGRESS_VALUE, step: HolidayTypeFlow.STEP }
    }

    return { progress: LimitedOptionsFlow.PROGRESS_VALUE, step: LimitedOptionsFlow.STEP }
  }

  const handleDispatch = (value: string) => {
    const { progress, step } = handleStepChoice(value)

    dispatch({ type: 'UPDATE_HOLIDAY_COMPLETE', data: value })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${BeenOnHolidayFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = (id: string) => {
    handleDispatch(id)
    handleTracking(id)
  }

  const { buttons, imgObj, title } = data

  return (
    <div data-testid="holiday-complete">
      <PillButtonPage
        buttons={buttons}
        defaultValue={complete}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        title={title}
      />
    </div>
  )
}
