export const supportingEvidenceData = {
  title: 'Have you any further evidence to support your case?',
  imgObj: {
    alt: 'A pair of hands typing at a computer',
    src: 'https://media.product.which.co.uk/prod/images/original/3fa843af602f-step8.jpg',
  },
  note: [
    'What you write here will appear in the final letter of claim, so please be as clear as possible.',
  ],
  descriptiveText: [],
  placeholderText:
    'Eg. I performed a range of speed tests and contacted the provider over the phone to try to resolve the issue.',
}
