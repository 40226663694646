export const DescribeCaseData = {
  title:
    'In no more than 100 words, please provide a very brief summary of what your issue or query is, and what advice you need.',
  imgObj: {
    alt: 'A person talking to someone via a video call',
    src: 'https://media.product.which.co.uk/prod/images/original/b6b3e304c407-team-to-get-in-touch.jpg',
  },
  noteTop: [
    'Examples of the level of detail that is helpful for our Experts in preparing for your appointment:',
  ],
  descriptiveText: [
    '"My flight to Amsterdam was delayed by three hours and I missed my connecting flight. My luggage was also lost and the airline won\'t offer any compensation"',
    '"I booked a cottage for a week but the place was very dirty and did not have all the features that were advertised"',
    '"We booked a package holiday but the holiday company changed the hotel at the last minute and it didn\'t have a pool like the original hotel"',
    '"We have a timeshare property and are concerned about how the costs of maintenance keep going up "',
    '<br>',
    '<span style="color:red;font-weight:bold;">Please only enter general details here and not personal details of others such as name, address or contact details</span>',
  ],
  placeholderText: '',
}

export const DescribeCaseMoneyData = {
  ...DescribeCaseData,
  descriptiveText: [
    '"I have lost some money to a scammer and want help in getting it back"',
    '"I want help understanding whether I am entitled to any benefits or help to pay with my husband\'s care"',
    '"I want some guidance around whether to move my pension"',
    '"I need help with my tax self-assessment"',
    '<br>',
    '<span style="color:red;font-weight:bold;">Please only enter general details here and not personal details of others such as name, address or contact details</span>',
  ],
}

export const DescribeCaseBuyingData = {
  ...DescribeCaseData,
  noteTop: [
    'Here are some examples of the level of detail that is helpful for our Experts in preparing for your appointment:',
  ],
  descriptiveText: [
    '"I purchased a laptop and after 3 months it has stopped working. The retailer has said that I will have to pay to ship it to them to diagnose the fault which I don\'t think is fair"',
    '"I hired a builder to convert my loft but he has been taking too long to get the work finished and I am not happy with the standard of the work. When I asked about this he left the site and hasn\'t returned. I want to know if I can make him finish the work to a good standard by a certain date."',
    '"My broadband provider has increased my monthly charges and the service is slow and intermittent. I want to know if I can get out of the contract"',
    '"The surveyor who did the pre-purchase report on my house failed to notice subsidence at the property and I now face hefty costs to remedy the issue. I want to know if I have a claim for professional negligence".',
    '<br>',
    '<span style="color:red;font-weight:bold;">Please only enter general details here and not personal details of others such as name, address or contact details</span>',
  ],
}

export const DescribeCaseWillData = {
  ...DescribeCaseData,
  noteTop: [
    'Here are some examples of the level of detail that is helpful for our Experts in preparing for your appointment:',
  ],
  descriptiveText: [
    '"I am an attorney for my husband and need to understand what my obligations are"',
    '"I am the executor of my brother\'s estate and would like some guidance on what process I need to follow"',
    '"I am concerned that the solicitors who drafted my late wife\'s will made a mistake"',
    '"I want to understand the best way to organise my estate so my children all get an equal share but I am not sure who to appoint as a beneficiary"',
    '<br>',
    '<span style="color:red;font-weight:bold;">Please only enter general details here and not personal details of others such as name, address or contact details</span>',
  ],
}

export const DescribeCaseTechData = {
  ...DescribeCaseData,
  noteTop: [
    'This will be recorded on our system and will help our Expert prepare for your appointment.',
  ],
  descriptiveText: [
    '<span style="color:red;font-weight:bold;">Please only enter general details here and not personal details of others such as name, address or contact details</span>',
  ],
}

export const DescribeCaseHomeData = {
  ...DescribeCaseData,
  descriptiveText: [
    '"I own a leasehold property and the management company are wanting me to pay for repairs which aren\'t my responsibility"',
    '"My landlord has served a notice to evict me from my flat and I want to know what my rights are"',
    '"My neighbour was working on his house and caused a water leak which damaged my property. I want to know if I can claim the cost of repairs "',
    '"I let out out my old flat and the tenant has stopped paying rent, and has caused some damage to the property - what can I do?"',
    '<br>',
    '<span style="color:red;font-weight:bold;">Please only enter general details here and not personal details of others such as name, address or contact details</span>',
  ],
}

export const DescribeCaseMedicalData = {
  ...DescribeCaseData,
  descriptiveText: [
    'Here are some examples of the level of detail that is helpful for our Experts in preparing for your appointment:',
    '"I was knocked off my bike by a motorist and want to claim compensation"',
    '"I paid privately for an eye operation and I am having ongoing problems"',
    '"I slipped on some spilled liquid in a supermarket and broke my arm"',
    '"I had some treatment at my local hospital and am suffering ongoing problems"',
    '<br>',
    '<span style="color:red;font-weight:bold;">Please only enter general details here and not personal details of others such as name, address or contact details</span>',
  ],
}

export const DescribeCaseCarsData = {
  ...DescribeCaseData,
  noteTop: [
    'Here are some examples of the level of detail that will help our Experts prepare for your appointment to discuss your issue:',
  ],
  descriptiveText: [
    '"I bought a car 6 months ago, there is an issue with the steering and the garage refuse to fix it"',
    '"I have been issued with a parking ticket but there were no clear signs stating how long I could park for"',
    '"I have been fined for speeding but I am sure I was driving within the limit"',
    '"I\'ve been involved in a road accident and I am not sure what to do now and who to contact"',
    '<br>',
    '<span style="color:red;font-weight:bold;">Please only enter general details here and not personal details of others such as name, address or contact details</span>',
  ],
}

export const DescribeCaseEmploymentData = {
  ...DescribeCaseData,
  noteTop: [
    'Here are some examples of the level of detail that is helpful for our Experts in preparing for your appointment:',
  ],
  descriptiveText: [
    '"My employer has told me that my role is at risk of redundancy but I don\'t think they have followed a fair process."',
    '"I have asked for a change to my working hours and my employer has refused it, and I don\'t think this is fair"',
    '"I have been told that I need to attend a disciplinary hearing over my absence at work but they haven\'t taken into account my underlying conditions"',
    '"My employer is changing my terms and conditions and saying I need to work from a different office which is much further away. How can I challenge this?"',
    '<br>',
    '<span style="color:red;font-weight:bold;">Please only enter general details here and not personal details of others such as name, address or contact details</span>',
  ],
}
