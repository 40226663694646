import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import classNames from 'classnames'

import { FormEntryPage } from '../../../../shared/pages/FormEntryPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryInputRef, SingleInputState } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { validateHalfPostcode } from '../../../../shared/utils/validateHalfPostcode'
import { GenderFlow, PostcodeFlow } from '../../pageFlowData'
import { data } from './data'
import styles from './Postcode.module.scss'

export const Postcode: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    victim: { postcode },
  } = state

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })
  const PostcodeInput = useRef<FormEntryInputRef>(null)

  const getValue = () => {
    return cleanText(PostcodeInput?.current?.formEntryInput?.value || '')
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${PostcodeFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_POSTCODE', data: value })
    dispatch({ type: 'UPDATE_STEP', data: GenderFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: GenderFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    const value = getValue()

    if (isNotEmpty(value) && !validateHalfPostcode(value)) {
      setInputData({
        renderError: true,
        value: value,
      })
      return
    }

    setInputData({
      renderError: false,
      value: value,
    })

    handleTracking(value)
    handleDispatch(value)
  }

  const { renderError, value: errorValue } = inputData
  const { imgObj, title } = data

  return (
    <div className={classNames({ [styles.postcode]: inputData.renderError })}>
      <FormEntryPage
        buttonText="Next"
        defaultValue={postcode}
        fieldData={{
          renderError: renderError,
          value: errorValue,
        }}
        handleSubmit={handleSubmit}
        id="postcode-half"
        imgObj={imgObj}
        isFocused
        optional={true}
        placeholderText=""
        ref={PostcodeInput}
        testId="postcode"
        title={title}
      />
    </div>
  )
}
