import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { TextAreaPage } from '../../../../shared/pages/TextAreaPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryTextAreaRef } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { validateCharCount } from '../../../../shared/utils/validateCharCount'
import { LikeToDoFlow, WhatsWrongFlow } from '../../pageFlowData'
import { whatsWrongData } from './data'

export const WhatsWrong: FunctionComponent = () => {
  const { imgObj, title, placeholderText, descriptiveText, note } = whatsWrongData
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    car: { whatsWrong },
  } = state

  const [WhatsWrongTextAreaValid, setWhatsWrongTextAreaValid] = useState<boolean>(true)

  const WhatsWrongTextAreaRef = useRef<FormEntryTextAreaRef>(null)

  const handleChange = () => {
    const value = getCleanValue(WhatsWrongTextAreaRef.current?.formEntryTextarea?.value)

    const isValid: boolean = validateCharCount(value.length, 2500)
    const fieldNotEmpty: boolean = isNotEmpty(value)
    if (fieldNotEmpty && isValid) {
      setWhatsWrongTextAreaValid(true)
    }
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_WHATS_WRONG', data: value })
    dispatch({ type: 'UPDATE_STEP', data: LikeToDoFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: LikeToDoFlow.PROGRESS_VALUE })
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${WhatsWrongFlow.VIEW_NAME} | PII`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = () => {
    const value: string = getCleanValue(WhatsWrongTextAreaRef.current?.formEntryTextarea?.value)
    const isValid: boolean = validateCharCount(value.length, 2500)
    const isEmpty = !isNotEmpty(value)

    if (!isValid || isEmpty) {
      setWhatsWrongTextAreaValid(false)
      return
    }

    setWhatsWrongTextAreaValid(true)

    handleTracking()
    handleDispatch(value)
  }

  return (
    <div data-testid="Whats-Wrong">
      <TextAreaPage
        buttonText="Next"
        defaultValue={whatsWrong}
        descriptiveText={descriptiveText}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        isFocused
        isInputValid={WhatsWrongTextAreaValid}
        maxChars={2500}
        noteTop={note}
        optional={false}
        placeholderText={placeholderText}
        ref={WhatsWrongTextAreaRef}
        title={title}
      />
    </div>
  )
}
