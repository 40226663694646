import type { FunctionComponent } from 'react'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'

import { ContactDetailsPage } from '../../../../shared/pages/ContactDetailsPage/ContactDetails'
import { ToolContext } from '../../../../shared/state/appContext'
import type { ContactRefProps, MultiInputsState } from '../../../../shared/types/toolTypes'
import { pageSubmission } from '../../../../shared/utils/EngagingNetwork/pageSubmission'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { scrollToInvalidInput } from '../../../../shared/utils/scrollToInvalidInput'
import { validateEmail } from '../../../../shared/utils/validateEmail'
import { pageData } from '../../pageData'
import { SubmitSuccessfulFlow } from '../../pageFlowData'
import { composeEmailBody } from '../SubmitSuccessful/composeEmailBody'
import { contactDetailsData } from './data'

export const ContactDetails: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: {
      optIns: { singleConsentOptIn, caseStudy },
      user,
    },
    issue,
    product,
    retailer,
  } = state

  const [inputsData, setInputsData] = useState<MultiInputsState>({
    fNameData: { renderError: false, value: '' },
    lNameData: { renderError: false, value: '' },
    emailData: { renderError: false, value: '' },
  })

  const [errorCount, setErrorCount] = useState(0)
  const [disableBtn, setDisableBtn] = useState(false)

  const ContactFormRefs = useRef<ContactRefProps>(null)

  const currentInputsList: HTMLInputElement[] = []

  for (const input in ContactFormRefs.current) {
    currentInputsList.push(ContactFormRefs.current[input])
  }

  const handleDispatch = useCallback(
    (emailSent: boolean) => {
      dispatch({ type: 'UPDATE_EMAIL_SENT', data: emailSent })
      dispatch({ type: 'UPDATE_STEP', data: SubmitSuccessfulFlow.STEP })
      dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: SubmitSuccessfulFlow.PROGRESS_VALUE })
    },
    [dispatch]
  )

  useEffect(() => {
    if (errorCount > 1) {
      // If they've gotten two or more errors, go to NextSteps anyway
      handleDispatch(false)
    }
  }, [errorCount, handleDispatch])

  const handleSubmit = async () => {
    const firstNameValue: string = ContactFormRefs.current?.firstName.value || ''
    const firstNameHasError = !isNotEmpty(firstNameValue) || !/^[a-z '-]+$/i.test(firstNameValue)

    const lastNameValue: string = ContactFormRefs.current?.lastName.value || ''
    const lastNameHasError = !isNotEmpty(lastNameValue) || !/^[a-z '-]+$/i.test(lastNameValue)

    const emailValue: string = ContactFormRefs.current?.emailAddress.value || ''
    const emailHasError = !isNotEmpty(emailValue) || !validateEmail(emailValue)

    setDisableBtn(true)

    setInputsData({
      fNameData: { renderError: firstNameHasError, value: firstNameValue },
      lNameData: { renderError: lastNameHasError, value: lastNameValue },
      emailData: { renderError: emailHasError, value: emailValue },
    })

    if (!firstNameHasError && !lastNameHasError && !emailHasError) {
      const userData = {
        firstName: firstNameValue,
        lastName: lastNameValue,
        emailAddress: emailValue,
      }
      dispatch({
        type: 'UPDATE_USER',
        data: userData,
      })
      const emailData = composeEmailBody({ retailer, product, issue, user: userData })
      const pageDataState = {
        emailData,
        issue,
        retailer,
        optIns: { singleConsentOptIn, caseStudy },
        product,
        user: { ...userData },
      }
      const structuredData = pageData(pageDataState)
      const ENResponse = await pageSubmission(structuredData, 88032)

      if (ENResponse && (ENResponse.ok || ENResponse.status === 'SUCCESS')) {
        handleDispatch(true)
      } else {
        setErrorCount(errorCount + 1)
        setDisableBtn(false)
      }
    } else {
      scrollToInvalidInput(currentInputsList)
      setDisableBtn(false)
    }
  }

  const formItems = contactDetailsData.formItems.map((x) => ({
    ...x,
    enterKeyHandler: handleSubmit,
  }))

  const handleContactMeChange = () => {
    dispatch({ type: 'UPDATE_CASESTUDY_OPTIN', data: !caseStudy })
  }

  const handleSingleConsentOptInChange = () => {
    dispatch({ type: 'UPDATE_SINGLE_CONSENT_OPTIN', data: !singleConsentOptIn })
  }

  const optins = [
    {
      checked: caseStudy,
      ...contactDetailsData.caseStudy,
      onChangeCallback: handleContactMeChange,
    },
    {
      checked: singleConsentOptIn,
      ...contactDetailsData.singleConsentOptIn,
      showOptInSpecificTerms: true,
      onChangeCallback: handleSingleConsentOptInChange,
    },
  ]

  const { imgObj, title } = contactDetailsData

  return (
    <ContactDetailsPage
      btnDisable={disableBtn}
      buttonText="Send complaint email to me"
      errorCount={errorCount}
      formItems={formItems}
      handleSubmit={handleSubmit}
      imgObj={imgObj}
      inputsData={inputsData}
      optins={optins}
      ref={ContactFormRefs}
      testId="contact-details"
      title={title}
      user={user}
    />
  )
}
