import { validatePostcode } from '../validatePostcode'
import { ADDRESS_API } from './env'

export const clearSearch = (setHasAddress: (hasAddressBool) => any) => {
  const address = document.getElementById('addressSearch-input') as HTMLInputElement
  const results = document.getElementById('mySelect')
  const addressLine1 = document.getElementById('addressLine1-input') as HTMLInputElement
  const addressLine2 = document.getElementById('addressLine2-input') as HTMLInputElement
  const townCity = document.getElementById('townCity-input') as HTMLInputElement
  const county = document.getElementById('county-input') as HTMLInputElement
  const postcode = document.getElementById('postcode-input') as HTMLInputElement
  if (address && results) {
    address.value = ''
    results.innerHTML = ''
    addressLine1.value = ''
    addressLine2.value = ''
    townCity.value = ''
    county.value = ''
    postcode.value = ''
    setHasAddress(false)
  }
}

export const findAddress = async (
  text: string,
  secondSelectedAddress: string,
  { setHasAddress, setAddressSearchError, setShowAddressFields }: addressType
) => {
  const postcode = text.replace(/\s/g, '')
  const url = encodeURI(`${ADDRESS_API()}/Find/${postcode}/${secondSelectedAddress}`)

  if (text === '') {
    setAddressSearchError('Please enter a postcode')
    setShowAddressFields(false)
    return
  }

  if (!validatePostcode(postcode)) {
    setAddressSearchError('Please enter a valid postcode')
    setShowAddressFields(false)
    return
  }

  const response = await fetch(url)

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`)
  }

  const addressDetails = await response.json()
  const addressDetailsData = addressDetails.data
  if (
    addressDetailsData &&
    addressDetailsData.Items.length === 1 &&
    typeof addressDetailsData.Items[0].Error != 'undefined'
  ) {
    setAddressSearchError('Something went wrong')
    setShowAddressFields(false)
  } else {
    if (addressDetailsData && addressDetailsData.Items.length === 0) {
      setAddressSearchError('Sorry, there were no results')
      setShowAddressFields(false)
    } else {
      setAddressSearchError('')
      setHasAddress(true)
      if (
        addressDetailsData.Items.length === 1 &&
        addressDetailsData.Items[0].Type === 'Postcode'
      ) {
        return addressDetailsData.Items[0].Id
      }

      const options = [{ label: 'Select Address', value: '0', type: '' }]

      for (let i = 0; i < addressDetailsData.Items.length; i++) {
        const obj = {} as dropdownOption
        obj.label = addressDetailsData.Items[i].Text + ' ' + addressDetailsData.Items[i].Description
        obj.value = addressDetailsData.Items[i].Id
        obj.type = addressDetailsData.Items[i].Type

        options.push(obj)
      }

      return options
    }
  }
}

type dropdownOption = {
  label: string
  value: string
  type: string
}

type addressType = {
  setHasAddress: (hasAddressBool: boolean) => any
  setAddressSearchError: (addressError: string) => any
  setShowAddressFields: (showAddressFieldsBool: boolean) => any
  setShowAddressSearchFields: (showAddressSearchBool: boolean) => any
  secondSelectedAddress?: string
}
