export const onFinanceData = {
  buttons: [
    {
      label: 'Yes',
      id: 'yes',
    },
    {
      label: 'No',
      id: 'no',
    },
  ],
  title: 'Did you buy it on finance?',
  imgObj: {
    alt: 'A person holding a small building in one hand and a piggy bank in the other',
    src: 'https://media.product.which.co.uk/prod/images/original/a2e4201434ec-cp-step5.jpg',
  },
  standFirst:
    'This includes buying with a credit card, personal loan, hire   purchase or personal contract purchase (PCP).',
}
