import type { InfoBoxProps } from '../../../../../shared/types/toolTypes'

// A separate object for the infoBox data is used due to the typing of the borderColour attribute
export const entitlementInfoBox: InfoBoxProps = {
  heading: 'More about your rights',
  borderColour: 'blue',
}

export const entitlementData = {
  infoBox: entitlementInfoBox,
  paragraphOne:
    "You're entitled to a repair or replacement, free of charge if you bought the <<PRODUCT>> from the dealer less than 6 years ago (or 5 years in Scotland). This is because you're protected under the Consumer Rights Act 2015 (in force from 1st October 2015), or the Sale of Goods Act if you bought the <<PRODUCT>> before October 1st 2015.",
  paragraphTwo:
    'In most cases this will be a repair, as providing a replacement <<PRODUCT>> would be a disproportionate cost.',
  option1: {
    // default (not associated with any Scenario)
    title: 'Great news! You are entitled to a repair or replacement.',
    standfirst: 'Your next step is to start to create your complaint email.',
    bodyCopy: [''],
    imageTitle: 'What do you want the seller to do?',
    imageURL:
      'https://media.product.which.co.uk/prod/images/original/gm-fd858caf-64e6-4a2a-8598-fb7320607ee6-resolution.jpg',
    textField: '',
    buttons: [
      {
        label: 'Repair',
        id: 'repair',
      },
      {
        label: 'Replacement',
        id: 'replacement',
      },
      {
        label: 'Refund',
        id: 'refund',
      },
    ],
  },
  option2: {
    title: 'You can ask the seller for a refund.',
    standfirst: "You're entitled to reject the item under the Consumer Contracts Regulation.",
    bodyCopy: [
      'Online retailers have to give customers a minimum of 14 days to reject any goods sold to them, but some retailers will give customers longer.',
    ],
    imageTitle: 'Which retailer did you buy the item from?',
    imageURL:
      'https://media.product.which.co.uk/prod/images/original/gm-f87650eb-dfa4-410b-bdc4-d910d100e7a2-whichretailer.jpg',
    buttons: [],
    textField: 'e.g. Currys, Amazon',
  },
  option3: {
    title:
      'You may be entitled to a replacement, repair or refund, however be aware that you will be asked to provide more evidence.',
    standfirst:
      'You have six years to take a claim to the small claims court for faulty goods in England, Wales and Northern Ireland, and five years in Scotland.',
    bodyCopy: [
      'However, if you bought your item more than six months ago, the burden is on you to prove the fault was present at the time you purchased your item.',
      "The law does not explain how you can prove a fault was present at the point of purchase, which can make it difficult when you're asked to do so.",
      'You can also contact your finance provider, who may offer you extra protection under Section 75 of the Consumer Credit Act, or another protection scheme.',
    ],
    imageTitle: 'What do you want the seller to do?',
    imageURL:
      'https://media.product.which.co.uk/prod/images/original/gm-fd858caf-64e6-4a2a-8598-fb7320607ee6-resolution.jpg',
    buttons: [
      {
        label: 'Repair',
        id: 'repair',
      },
      {
        label: 'Replacement',
        id: 'replacement',
      },
      {
        label: 'Refund',
        id: 'refund',
      },
    ],
    textField: '',
  },
  option4: {
    title: 'You can ask the seller for a repair, replacement or refund.',
    standfirst:
      "You're entitled to reject the item within 30 days of making the purchase under the Consumer Rights Act 2015.",
    bodyCopy: [
      "Though you can accept a repair or replacement, you're legally entitled to a full refund.",
      'You can also contact your finance provider, who may offer you extra protection under Section 75 of the Consumer Credit Act, or another protection scheme.',
    ],
    imageTitle: 'What do you want the seller to do?',
    imageURL:
      'https://media.product.which.co.uk/prod/images/original/gm-fd858caf-64e6-4a2a-8598-fb7320607ee6-resolution.jpg',
    buttons: [
      {
        label: 'Repair',
        id: 'repair',
      },
      {
        label: 'Replacement',
        id: 'replacement',
      },
      {
        label: 'Refund',
        id: 'refund',
      },
    ],
    textField: '',
  },
  option5: {
    title: 'You can ask the seller for a repair, replacement or partial refund.',
    standfirst:
      "You're entitled to a repair or replacement if you find a fault within the first six months of buying your item.",
    bodyCopy: [
      "If that's unsuccessful, you're entitled to a refund.",
      'You can also contact your finance provider, who may offer you extra protection under Section 75 of the Consumer Credit Act, or another protection scheme.',
    ],
    imageTitle: 'What do you want the seller to do?',
    imageURL:
      'https://media.product.which.co.uk/prod/images/original/gm-fd858caf-64e6-4a2a-8598-fb7320607ee6-resolution.jpg',
    buttons: [
      {
        label: 'Repair',
        id: 'repair',
      },
      {
        label: 'Replacement',
        id: 'replacement',
      },
      {
        label: 'Refund',
        id: 'refund',
      },
    ],
    textField: '',
  },
  option6: {
    title: 'You can ask the seller for a repair, replacement or refund.',
    standfirst:
      "You're entitled to reject the item within 30 days of making the purchase under the Consumer Rights Act 2015.",
    bodyCopy: [
      "You can accept a repair or replacement, but you're legally entitled to a full refund.",
      'If you have a warranty with a manufacturer or a third-party, you may be able to use this to get a replacement.',
    ],
    imageTitle: 'What do you want the seller to do?',
    imageURL:
      'https://media.product.which.co.uk/prod/images/original/gm-fd858caf-64e6-4a2a-8598-fb7320607ee6-resolution.jpg',
    buttons: [
      {
        label: 'Repair',
        id: 'repair',
      },
      {
        label: 'Replacement',
        id: 'replacement',
      },
      {
        label: 'Refund',
        id: 'refund',
      },
    ],
    textField: '',
  },
  option7: {
    title: 'The seller should be given an opportunity to repair or replace the item.',
    standfirst:
      "You're entitled to a repair or replacement if you find a fault within the first six months of buying your item.",
    bodyCopy: ["If that's unsuccessful, you're entitled to a refund."],
    imageTitle: 'What do you want the seller to do?',
    imageURL:
      'https://media.product.which.co.uk/prod/images/original/gm-fd858caf-64e6-4a2a-8598-fb7320607ee6-resolution.jpg',
    buttons: [
      {
        label: 'Repair',
        id: 'repair',
      },
      {
        label: 'Replacement',
        id: 'replacement',
      },
    ],
    textField: '',
  },
  option8: {
    title: 'You can ask the seller for a refund.',
    standfirst:
      "If you're not happy with the repair or replacement you have the option for a refund.",
    bodyCopy: [''],
    imageTitle: 'Which retailer did you buy the item from?',
    imageURL:
      'https://media.product.which.co.uk/prod/images/original/gm-f87650eb-dfa4-410b-bdc4-d910d100e7a2-whichretailer.jpg',
    buttons: [],
    textField: 'e.g. Currys, Amazon',
  },
  option9: {
    title:
      'You are entitled to a refund of up to 100% of price paid for digital content & the cost of device if damaged.',
    standfirst:
      'The seller must be given one opportunity to repair or replace the digital content & device.',
    bodyCopy: [
      'If you are unhappy with their attempt to do this, you are then entitled to a refund of up to 100% of the price paid.',
    ],
    imageTitle: 'Which retailer did you buy the item from?',
    imageURL:
      'https://media.product.which.co.uk/prod/images/original/gm-f87650eb-dfa4-410b-bdc4-d910d100e7a2-whichretailer.jpg',
    buttons: [],
    textField: 'e.g. Currys, Amazon',
  },
  option10: {
    title:
      'The seller must be given one opportunity to either repair or replace the damaged content & device.',
    standfirst:
      'If you are unhappy with their attempt to do this, you are then entitled to a refund of up to 100% of the price paid.',
    bodyCopy: [''],
    imageTitle: 'What do you want the seller to do?',
    imageURL:
      'https://media.product.which.co.uk/prod/images/original/gm-fd858caf-64e6-4a2a-8598-fb7320607ee6-resolution.jpg',
    buttons: [
      {
        label: 'Repair',
        id: 'repair',
      },
      {
        label: 'Replacement',
        id: 'replacement',
      },
    ],
    textField: '',
  },
  option11: {
    title: 'You are entitled to a refund of up to 100% of price paid for digital content.',
    standfirst:
      'The seller must be given one opportunity to repair or replace the digital content before you are given a refund.',
    bodyCopy: [''],
    imageTitle: 'Which retailer did you buy the item from?',
    imageURL:
      'https://media.product.which.co.uk/prod/images/original/gm-f87650eb-dfa4-410b-bdc4-d910d100e7a2-whichretailer.jpg',
    buttons: [],
    textField: 'e.g. Currys, Amazon',
  },
  option12: {
    title:
      'The seller must be given one opportunity to either repair or replace the damaged content.',
    standfirst:
      'If you are unhappy with their attempt to do this, you are then entitled to a refund of up to 100% of the price paid.',
    bodyCopy: [''],
    imageTitle: 'What do you want the seller to do?',
    imageURL:
      'https://media.product.which.co.uk/prod/images/original/gm-fd858caf-64e6-4a2a-8598-fb7320607ee6-resolution.jpg',
    buttons: [
      {
        label: 'Repair',
        id: 'repair',
      },
      {
        label: 'Replacement',
        id: 'replacement',
      },
    ],
    textField: '',
  },
}
