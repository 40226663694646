export const whatHappenedData = {
  buttons: [
    {
      label: 'It was left with a neighbour who claims not to have it',
      id: 'withNeighbour',
    },
    {
      label: 'It was left somewhere and is now missing',
      id: 'leftSomewhere',
    },
    {
      label: 'Something else',
      id: 'somethingElse',
    },
  ],
  title: 'What happened to your item?',
  imgObj: {
    alt: 'A couple talking to someone behind a counter.',
    src: 'https://media.product.which.co.uk/prod/images/original/gm-67837f5d-967f-4c9d-aea1-fc13914e9fac-delivery-complaint-step-7.jpg',
  },
}
