import type { FunctionComponent } from 'react'
import React, { useContext, useEffect, useState } from 'react'

import { CheckBoxGroupPage } from '../../../../shared/pages/CheckBoxGroupPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { LedYouToPackagedBankAccountFlow, WhyMisSoldAccountFlow } from '../../pageFlowData'
import { data } from './data'
import styles from './LedToPackagedBankAccount.module.scss'

export const LedToPackagedBankAccount: FunctionComponent = () => {
  const [isInputValid, setInputValid] = useState(true)
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    claim: { ledToPackagedBankAccount },
  } = state

  useEffect(() => {
    dispatch({ type: 'UPDATE_LED_YOU_TO_PACKAGED_BANK_ACCOUNT', data: ledToPackagedBankAccount })
  }, [dispatch, ledToPackagedBankAccount])

  const handleCheckBoxChange = ({ id }: { id?: string }) => {
    const checkboxChange = ledToPackagedBankAccount || []
    if (!checkboxChange.includes(id)) {
      checkboxChange.push(id)
    } else {
      checkboxChange.splice(checkboxChange.indexOf(id), 1)
    }
    dispatch({ type: 'UPDATE_LED_YOU_TO_PACKAGED_BANK_ACCOUNT', data: checkboxChange })
  }

  const handleAnswerValue = () => ledToPackagedBankAccount.join(' | ')

  const handleDispatch = () => {
    dispatch({ type: 'UPDATE_STEP', data: WhyMisSoldAccountFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: WhyMisSoldAccountFlow.PROGRESS_VALUE })
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${
      LedYouToPackagedBankAccountFlow.VIEW_NAME
    } | ${handleAnswerValue()}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = () => {
    if (ledToPackagedBankAccount && ledToPackagedBankAccount.length > 0) {
      setInputValid(true)
      handleDispatch()
      handleTracking()
    }
    setInputValid(false)
  }

  const { buttons, imgObj, title } = data

  return (
    <div className={styles.ledToPackageBankAccount}>
      <CheckBoxGroupPage
        buttonText="Next"
        buttons={buttons}
        handleChange={handleCheckBoxChange}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        isInputValid={isInputValid}
        stateValue={ledToPackagedBankAccount}
        title={title}
      />
    </div>
  )
}
