import type { FunctionComponent } from 'react'
import React from 'react'
import { Dropdown, Typography } from '@which/seatbelt'

import classNames from 'classnames'

import type { DropdownGroupProps } from '../../types/toolTypes'
import styles from './DropdownGroup.module.scss'

export const DropdownGroup: FunctionComponent<DropdownGroupProps> = ({
  firstDropdownOptions,
  secondDropdownOptions,
  handleChange,
  label,
}) => (
  <div className={styles.dropdownGroup}>
    <Typography className={styles.dropdownGroupText} tag="p" textStyle="body-intro">
      {label}
    </Typography>
    <div className={styles.dropdownGroupContainer}>
      <Dropdown
        callback={handleChange}
        className={classNames(styles.dropdownGroupDropdown, {
          [styles.dropdownGroupDropdownError]: firstDropdownOptions.error,
        })}
        data-testid="dropdown-group-1"
        defaultValue={firstDropdownOptions.defaultValue}
        options={[firstDropdownOptions.prompt, ...firstDropdownOptions.options]}
      />
      <Dropdown
        callback={handleChange}
        className={classNames(styles.dropdownGroupDropdown, {
          [styles.dropdownGroupDropdownError]: secondDropdownOptions.error,
        })}
        data-testid="dropdown-group-2"
        defaultValue={secondDropdownOptions.defaultValue}
        options={[secondDropdownOptions.prompt, ...secondDropdownOptions.options]}
      />
    </div>
  </div>
)
