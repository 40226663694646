export const homeImprovementToolState = {
  work: {
    complaint: null,
    trader: null,
    job: null,
    work: null,
    date: null,
    name: null,
    number: null,
    problem: null,
    card: null,
  },
}
