import type { PageFlowProps } from '../../../shared/types/toolTypes'

export const WhoIssuedFlow: PageFlowProps = {
  PROGRESS_VALUE: 0,
  STEP: 1,
  VIEW_NAME: 'who-issued',
  REDUCERS: ['UPDATE_WHO_ISSUED'],
  MILESTONE: 'milestone | start',
}

export const CompanyNameFlow: PageFlowProps = {
  PROGRESS_VALUE: 10,
  STEP: 2,
  VIEW_NAME: 'company-name',
  REDUCERS: ['UPDATE_COMPANY_NAME'],
  MILESTONE: '',
}

export const VehicleRegFlow: PageFlowProps = {
  PROGRESS_VALUE: 20,
  STEP: 3,
  VIEW_NAME: 'vehicle-registration',
  REDUCERS: ['UPDATE_VEHICLE_REG'],
  MILESTONE: '',
}

export const TicketPurchasedFlow: PageFlowProps = {
  PROGRESS_VALUE: 30,
  STEP: 4,
  VIEW_NAME: 'ticket-purchased',
  REDUCERS: ['UPDATE_TICKET_PURCHASED'],
  MILESTONE: '',
}

export const PurchaseMethodFlow: PageFlowProps = {
  PROGRESS_VALUE: 40,
  STEP: 5,
  VIEW_NAME: 'purchase-method',
  REDUCERS: ['UPDATE_PURCHASE_METHOD'],
  MILESTONE: '',
}

export const SupportingEvidenceFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 6,
  VIEW_NAME: 'supporting-evidence',
  REDUCERS: ['UPDATE_SUPPORTING_EVIDENCE'],
  MILESTONE: '',
}

export const TicketNumberFlow: PageFlowProps = {
  PROGRESS_VALUE: 60,
  STEP: 7,
  VIEW_NAME: 'ticket-number',
  REDUCERS: ['UPDATE_TICKET_NUMBER'],
  MILESTONE: '',
}

export const TicketReasonFlow: PageFlowProps = {
  PROGRESS_VALUE: 70,
  STEP: 8,
  VIEW_NAME: 'ticket-reason',
  REDUCERS: ['UPDATE_TICKET_REASON'],
  MILESTONE: '',
}

export const TicketDateFlow: PageFlowProps = {
  PROGRESS_VALUE: 75,
  STEP: 9,
  VIEW_NAME: 'ticket-date',
  REDUCERS: ['UPDATE_TICKET_DATE'],
  MILESTONE: '',
}

export const TicketLocationFlow: PageFlowProps = {
  PROGRESS_VALUE: 80,
  STEP: 10,
  VIEW_NAME: 'ticket-location',
  REDUCERS: [''],
  MILESTONE: '',
}

export const TicketDiscountFlow: PageFlowProps = {
  PROGRESS_VALUE: 85,
  STEP: 11,
  VIEW_NAME: 'ticket-discount',
  REDUCERS: ['UPDATE_TICKET_DISCOUNT'],
  MILESTONE: '',
}

export const IssueDescriptionFlow: PageFlowProps = {
  PROGRESS_VALUE: 90,
  STEP: 12,
  VIEW_NAME: 'what-happened',
  REDUCERS: ['UPDATE_ISSUE_DESCRIPTION'],
  MILESTONE: '',
}

export const ContactDetailsFlow: PageFlowProps = {
  PROGRESS_VALUE: 95,
  STEP: 13,
  VIEW_NAME: 'contact-details',
  REDUCERS: [''],
  MILESTONE: 'milestone | personal details',
}

export const SubmitSuccessfulFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 14,
  VIEW_NAME: 'journey-complete',
  REDUCERS: ['UPDATE_EMAIL_SENT'],
  MILESTONE: 'milestone | end | letter sent',
}

export const ToolFeedbackFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 15,
  VIEW_NAME: 'feedback-form',
  REDUCERS: [''],
  MILESTONE: 'milestone | feedback start',
}

export const ToolFeedbackAudienceFlow: PageFlowProps = {
  PROGRESS_VALUE: 90,
  STEP: 16,
  VIEW_NAME: 'feedback-audience-form',
  REDUCERS: [''],
  MILESTONE: '',
}

export const ToolFeedbackThankYouFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 17,
  VIEW_NAME: 'feedback-form-thankyou',
  REDUCERS: [''],
  MILESTONE: '',
}

export const LimitedOptionsFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 18,
  VIEW_NAME: 'limited-options',
  REDUCERS: [''],
  MILESTONE: 'milestone | next steps | end',
}

export const AllFlows = [
  WhoIssuedFlow,
  CompanyNameFlow,
  VehicleRegFlow,
  TicketPurchasedFlow,
  PurchaseMethodFlow,
  SupportingEvidenceFlow,
  TicketNumberFlow,
  TicketReasonFlow,
  TicketDateFlow,
  TicketLocationFlow,
  TicketDiscountFlow,
  IssueDescriptionFlow,
  ContactDetailsFlow,
  SubmitSuccessfulFlow,
  ToolFeedbackFlow,
  ToolFeedbackAudienceFlow,
  ToolFeedbackThankYouFlow,
  LimitedOptionsFlow,
]
