import type { UserPropsWithAddress } from './../../../shared/types/toolTypes'

export const pageData = (
  state: {
    emailData: { body: { text: string; className?: string }[] }
    flight: {
      ukOrEuAirline: string
      arriveDepartUK: string
      airports: {
        departureAirport: string
        arrivalAirport: string
      }
      distance: string
      delayedCancelled: string
      flightNumber: string
      date: string
      airline: string
      scheduledTimes: {
        departureTimes: string
        arrivalTimes: string
      }
    }
    claim: {
      timeBeforeCancelled: string
      earlierLaterOriginalFlight: string
      hoursDifference: string
      numPeopleClaimingFor: string
      reasonGiven: string
      amountEntitled: string
    }
    optIns: {
      singleConsentOptIn: boolean
    }
    user: UserPropsWithAddress
  },
  contactOptin: boolean
) => {
  const {
    ukOrEuAirline,
    arriveDepartUK,
    airports,
    distance,
    delayedCancelled,
    flightNumber,
    date,
    airline,
    scheduledTimes,
  } = state.flight
  const {
    timeBeforeCancelled,
    earlierLaterOriginalFlight,
    hoursDifference,
    numPeopleClaimingFor,
    reasonGiven,
    amountEntitled,
  } = state.claim
  const { address, emailAddress, firstName, lastName, phoneNumber } = state.user
  const { singleConsentOptIn } = state.optIns

  const { body } = state.emailData

  let emailBodyBuilder = ''
  body.forEach((bodyData) => {
    if (bodyData.text) {
      emailBodyBuilder = emailBodyBuilder + `${bodyData.text}<br />`
    }
  })

  const questions: any = {}
  // Questions for flight and claim
  questions['question.1319512'] = ukOrEuAirline // TF - Flight Delay - Was your flight with a UK or EU-based airline?
  questions['question.1319575'] = arriveDepartUK // TF - Flight Delay - Did your flight arrive or depart from a UK airport?
  questions['question.1319731'] = airports.departureAirport // TF - Flight Delay - Which departure airport did you use? (Departure)
  questions['question.1319732'] = airports.arrivalAirport // TF - Flight Delay - Which departure airport did you use? (Arrival)
  questions['question.1320653'] = distance // TF - Flight Delay - Flight distance
  questions['question.1319733'] = delayedCancelled // TF - Flight Delay - Was the flight delayed or cancelled?
  questions['question.1320654'] = flightNumber // TF - Flight Delay - What was your flight number?
  questions['question.1320655'] = date // TF - Flight Delay - What was your flight date?
  questions['question.1320656'] = airline // TF - Flight Delay - Which airline were you flying with?
  questions['question.1320710'] = scheduledTimes.departureTimes // TF - Flight Delay - What was the flight's scheduled departure time?
  questions['question.1320711'] = scheduledTimes.arrivalTimes // TF - Flight Delay - What was the flight's scheduled arrival time?

  questions['question.1320715'] = timeBeforeCancelled // TF - Flight Delay - How long before your flight was due to depart were you told it was cancelled?
  questions['question.1319794'] = earlierLaterOriginalFlight // TF - Flight Delay - Did you depart earlier or arrive later than your original scheduled flight?
  // does hoursDifference need an extra question in EN?
  questions['question.1319973'] = hoursDifference // TF - Flight Delay - How late were you arriving at your destination airport?
  questions['question.1320077'] = numPeopleClaimingFor // TF - Flight Delay - How many people are your claiming on behalf of (including children)?
  questions['question.1320713'] = reasonGiven // TF - Flight Delay - What reason were you given for the cancellation/delay?
  questions['question.1320652'] = amountEntitled // TF - Flight Delay - Compensation you may be entitled to
  questions['question.1194262'] = emailBodyBuilder // TF - Composed Letter

  questions['question.1598463'] = singleConsentOptIn ? 'Y' : 'N' // TF - Single Consent opt-in
  questions['question.1184531'] = 'Y' // TF - Impact survey opt-in
  questions['question.1618583'] = contactOptin ? 'Y' : 'N' // TF - Flight Delay - Discuss your experience in a bit more detail?

  // return supporter
  return {
    supporter: {
      Address: address?.addressLine1,
      'Address 2': address?.addressLine2,
      County: address?.county,
      Email: emailAddress,
      'First name': firstName,
      'Last name': lastName,
      Postcode: address?.postcode,
      'Town or City': address?.townCity,
      questions,
      Phone: phoneNumber,
    },
  }
}
