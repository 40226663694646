import { getStepData } from '../../../utils/getStepData'

const standardQuestion = (toolId: string) => {
  const { radioExperienceSingle } = getStepData(toolId)
  return [
    {
      buttons: [
        {
          label: `I realised I wasn't eligible to ${radioExperienceSingle}`,
          id: 'wasnt-eligible',
          isFocused: false,
        },
        {
          label: `I couldn't find who to send my ${radioExperienceSingle} to`,
          id: 'couldnt-find-where-to-send',
          isFocused: false,
        },
        {
          label: "I didn't think it would be worth the effort",
          id: 'not-worth-effort',
          isFocused: false,
        },
        {
          label: "I didn't have the time",
          id: 'no-time',
          isFocused: false,
        },
        {
          label: 'Other',
          id: 'other',
          isFocused: false,
          textbox: {
            placeholder: '',
            currency: false,
            name: 'other-text',
            displayText: 'Please specify',
          },
        },
      ],
      isSubmitValid: true,
      id: 'reason-not',
    },
  ]
}

export const faultyGoodsData = {
  radioQuestions: [...standardQuestion('FaultyGoodsTool')],
}

export const section75Data = {
  radioQuestions: [...standardQuestion('Section75')],
}

export const holidayData = {
  radioQuestions: [...standardQuestion('HolidayComplaint')],
}

export const parkingData = {
  radioQuestions: [...standardQuestion('ParkingTicket')],
}

export const flightData = {
  radioQuestions: [...standardQuestion('FlightDelayCancellationCompensation')],
}

export const deliveryData = {
  radioQuestions: [...standardQuestion('DeliveryComplaint')],
}

export const packageBankAccountData = {
  radioQuestions: [...standardQuestion('MisSoldPackagedBankAccount')],
}

export const homeImprovementData = {
  radioQuestions: [...standardQuestion('HomeImprovement')],
}
