import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { ArticleCTAPage } from '../../../../shared/pages/ArticleCTAPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking/tracking'
import { limitedOptionsData } from './data'

export const LimitedOptions: FunctionComponent = () => {
  const { state } = useContext(ToolContext)

  const {
    initial: { entryToolName },
    car: { whereBought, whenPurchased },
  } = state

  const chooseData = () => {
    const dateSixYearsAgo = new Date().getTime() - 2191 * 24 * 60 * 60 * 1000
    const overSixYearsAgo = new Date(whenPurchased).getTime() < dateSixYearsAgo

    if (whereBought === 'private') {
      return limitedOptionsData.private
    }

    if (overSixYearsAgo) {
      return limitedOptionsData.overSixYears
    }

    return limitedOptionsData.finance
  }

  const { ctaData } = limitedOptionsData
  const { bodyCopy, standFirst, title, link } = chooseData()
  const { href, headline, text } = link

  const handleTracking = () => {
    generalDataLayer(formatToolName(entryToolName), `onward journey | ${headline} | ${href}`)
  }

  return (
    <div data-testid="limited-options">
      <ArticleCTAPage
        articleHeading={headline}
        articleLink={link}
        articleTitle={text}
        bodyCopy={bodyCopy}
        ctaData={ctaData}
        handleSubmit={handleTracking}
        standFirst={standFirst}
        title={title}
      />
    </div>
  )
}
