import { chromeInfo, defaultInfo, edgeInfo, safariInfo } from '../fixtures'

export const instructions = (browser: string | undefined) => {
  switch (browser) {
    case 'chrome':
    case 'firefox':
    case 'opera':
      return chromeInfo
    case 'edge':
    case 'edge-chromium':
      return edgeInfo
    case 'safari':
      return safariInfo
    case '':
    default:
      return defaultInfo
  }
}
