import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import globalStyles from '../../../../shared/styles/GlobalStyles.module.scss'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import {
  DeliveryPermissionFlow,
  DeliveryProblemFlow,
  LimitedOptionsFlow,
  WhichRetailerFlow,
} from '../../pageFlowData'
import { deliveryProblemsData } from './data'

export const DeliveryProblem: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    order: { deliveryProblem },
  } = state

  const handleStepChoice = (value: string) => {
    switch (value) {
      case 'lateDelivery':
        return { step: WhichRetailerFlow.STEP, progress: WhichRetailerFlow.PROGRESS_VALUE }
      case 'damagedGoods':
        return { step: LimitedOptionsFlow.STEP, progress: LimitedOptionsFlow.PROGRESS_VALUE }
      case 'missingDelivery':
        return {
          step: DeliveryPermissionFlow.STEP,
          progress: DeliveryPermissionFlow.PROGRESS_VALUE,
        }
      default:
        return { step: WhichRetailerFlow.STEP, progress: WhichRetailerFlow.PROGRESS_VALUE }
    }
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${DeliveryProblemFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    const { progress, step } = handleStepChoice(value)

    dispatch({ type: 'UPDATE_DELIVERY_PROBLEM', data: value })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleSubmit = (id: string) => {
    handleTracking(id)
    handleDispatch(id)
  }

  const { buttons, imgObj, title } = deliveryProblemsData

  return (
    <div className={globalStyles.fullWidthButton} data-testid="delivery-problem">
      <PillButtonPage
        buttons={buttons}
        defaultValue={deliveryProblem}
        fitContent
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        title={title}
      />
    </div>
  )
}
