import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import {
  EarlierLaterOriginalFlightFlow,
  LimitedOptionsFlow,
  NumPeopleClaimingForFlow,
  TimeBeforeCancelledFlow,
} from '../../pageFlowData'
import { data } from './data'

export const TimeBeforeCancelled: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    claim: { timeBeforeCancelled },
    flight: { delayedCancelled },
  } = state

  const handleStepChoice = (value: string) => {
    if (value === 'more-than-14-days') {
      return {
        progress: LimitedOptionsFlow.PROGRESS_VALUE,
        step: LimitedOptionsFlow.STEP,
      }
    } else if (delayedCancelled === 'flight-rerouted') {
      return {
        progress: EarlierLaterOriginalFlightFlow.PROGRESS_VALUE,
        step: EarlierLaterOriginalFlightFlow.STEP,
      }
    } else {
      return {
        progress: NumPeopleClaimingForFlow.PROGRESS_VALUE,
        step: NumPeopleClaimingForFlow.STEP,
      }
    }
  }

  const handleDispatch = (value: string) => {
    const { progress, step } = handleStepChoice(value)

    dispatch({ type: 'UPDATE_TIME_BEFORE_CANCELLED', data: value })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${TimeBeforeCancelledFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = (id: string) => {
    handleDispatch(id)
    handleTracking(id)
  }

  const { buttons, imgObj, title } = data

  return (
    <div data-testid="time-before-cancelled">
      <PillButtonPage
        buttons={buttons}
        defaultValue={timeBeforeCancelled}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        title={title}
      />
    </div>
  )
}
