import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { TextAreaPage } from '../../../../shared/pages/TextAreaPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryTextAreaRef } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { validateCharCount } from '../../../../shared/utils/validateCharCount'
import { BookingReferenceFlow, ExpensesDetailsFlow } from '../../pageFlowData'
import { expensesDescriptionData } from './data'

export const ExpensesDescription: FunctionComponent = () => {
  const textAreaMaxCharCount = 2500
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
  } = state

  const [expensesDescriptionTextAreaValid, setExpensesDescriptionTextAreaValid] =
    useState<boolean>(true)
  const ExpenseDescriptionTextAreaRef = useRef<FormEntryTextAreaRef>(null)

  const handleChange = () => {
    const value = getCleanValue(ExpenseDescriptionTextAreaRef.current?.formEntryTextarea?.value)
    dispatch({ type: 'UPDATE_EXPENSES_DETAILS', data: value })
  }

  const handleDispatch = () => {
    dispatch({ type: 'UPDATE_STEP', data: BookingReferenceFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: BookingReferenceFlow.PROGRESS_VALUE })
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${ExpensesDetailsFlow.VIEW_NAME} | PII`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = () => {
    const value: string = getCleanValue(
      ExpenseDescriptionTextAreaRef.current?.formEntryTextarea?.value
    )
    const isValid: boolean = validateCharCount(value.length, textAreaMaxCharCount)
    const isEmpty = !isNotEmpty(value)

    if (!isValid || isEmpty) {
      setExpensesDescriptionTextAreaValid(false)
      return
    }

    setExpensesDescriptionTextAreaValid(true)
    handleTracking()
    handleDispatch()
  }

  const { imgObj, title, placeholderText, descriptiveText } = expensesDescriptionData

  return (
    <div data-testid="expenses-description">
      <TextAreaPage
        buttonText="Next"
        defaultValue=""
        descriptiveText={descriptiveText}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        isFocused
        isInputValid={expensesDescriptionTextAreaValid}
        maxChars={textAreaMaxCharCount}
        placeholderText={placeholderText}
        ref={ExpenseDescriptionTextAreaRef}
        title={title}
      />
    </div>
  )
}
