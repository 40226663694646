export const reportedToOmbudsmanData = {
  buttons: [
    {
      label: 'Yes, and I am waiting for a response',
      id: 'waiting',
    },
    {
      label: 'Yes, and it has been turned down',
      id: 'turned-down',
    },
    {
      label: 'No, I have not',
      id: 'no',
    },
    {
      label: 'Yes, and I am getting/have got my money back',
      id: 'money-back',
    },
  ],
  title: 'Have you reported to the Financial Ombudsman Service (FOS)?',
  imgObj: {
    alt: 'Two people shaking hands',
    src: 'https://media.product.which.co.uk/prod/images/original/9068a29e30bc-have-you-reported-to-the-financial.jpg',
  },
}
