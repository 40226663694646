import type { ImpactSurveyPageDataStateObj } from '../../../shared/types/toolTypes'

export const pageData = (state: ImpactSurveyPageDataStateObj) => {
  const {
    regardingProblem,
    wasSuccessful,
    whatWasOutcome,
    outcomeAmount,
    letterSuccess,
    reasonUnsuccessful,
    whatNow,
    ifNoWhichTool,
    toolEasier,
    savedTime,
    howMuchTimeSaved,
    reducedStress,
    moreAboutExperience,
  } = state.answers
  const { followUp, toolID, userEmail } = state.user

  const questions: any = {}
  questions['question.1349123'] = toolID // TF - Impact Survey - Tool Name
  questions['question.1349096'] = regardingProblem // TF - Impact Survey - Claim made?
  questions['question.1349097'] = wasSuccessful // TF - Impact Survey - Claim Successful?
  questions['question.1349098'] = whatWasOutcome // TF - Impact Survey - Outcome of claim?
  questions['question.1349127'] = outcomeAmount // TF - Impact Survey - Outcome Value?
  questions['question.1349106'] = letterSuccess // TF - Impact Survey - Letter success contribution
  questions['question.1349110'] = reasonUnsuccessful // TF - Impact Survey - Unsuccessful reason
  questions['question.1349118'] = whatNow // TF - Impact Survey - Unsuccessful what next?
  questions['question.1349119'] = ifNoWhichTool // TF - Impact Survey - Claimed without tool?
  questions['question.1349120'] = toolEasier // TF - Impact Survey - Tool made claim easier?
  questions['question.1349121'] = savedTime // TF - Impact Survey - Tool saved time?
  questions['question.1349122'] = howMuchTimeSaved // TF - Impact Survey - Tool estimated time saved?
  questions['question.1349124'] = reducedStress // TF - Impact Survey - Tool reduced worry or stress?
  questions['question.1349125'] = moreAboutExperience // TF - Impact Survey - More on your claim experience
  questions['question.1184531'] = followUp // TF - Impact survey opt-in

  // return supporter
  return {
    supporter: {
      Email: userEmail,
      questions,
    },
  }
}
