import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'

import { FormEntryPage } from '../../pages/FormEntryPage'
import type { FormEntryInputRef, WhichRetailerObj } from '../../types/toolTypes'
import { formatToolName } from '../../utils/formatToolName'
import { generalDataLayer, pageViewDataLayer } from '../../utils/tracking'

export const WhichRetailer = forwardRef(
  (
    {
      props: {
        buttonText,
        defaultValue,
        entryToolName,
        fieldData,
        handleSubmit,
        id,
        imgObj,
        isFocused,
        milestone,
        placeholderText,
        setStartLetterMilestone,
        testId,
        title,
      },
    }: WhichRetailerObj,
    ref
  ) => {
    const whichRetailerRef = useRef<FormEntryInputRef>(null)

    useImperativeHandle(ref, () => ({
      whichRetailerRef: whichRetailerRef.current,
    }))

    useEffect(() => {
      pageViewDataLayer('which-retailer')
    }, [])

    useEffect(() => {
      if (milestone) {
        generalDataLayer(formatToolName(entryToolName), 'milestone | write letter')
        setStartLetterMilestone()
      }
    }, [milestone, setStartLetterMilestone, entryToolName])

    return (
      <FormEntryPage
        buttonText={buttonText}
        defaultValue={defaultValue}
        fieldData={fieldData}
        handleSubmit={handleSubmit}
        id={id}
        imgObj={imgObj}
        isFocused={isFocused}
        placeholderText={placeholderText}
        ref={whichRetailerRef}
        testId={testId}
        title={title}
      />
    )
  }
)
