export const setUserJourney = (
  userJourney: Array<number>,
  step: number,
  dispatch: (dispatchArgs) => any
) => {
  const newUserJourney: Array<number> = userJourney
  // going back in flow
  if (userJourney[userJourney.length - 2] === step) {
    newUserJourney.pop()
  } else {
    // going forward in flow & don't add same step into journey
    userJourney[userJourney.length - 1] !== step && newUserJourney.push(step)
  }
  dispatch({
    type: 'UPDATE_USER_JOURNEY',
    data: newUserJourney,
  })
}
