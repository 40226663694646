import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../../shared/state/appContext'
import globalStyles from '../../../../../shared/styles/GlobalStyles.module.scss'
import { trackAppointmentBookingSelection } from '../../../../../shared/utils/tracking/tracking'
import {
  BuyingQueryFlow,
  ContractChoiceFlow,
  DescribeCaseFlow,
  LimitedOptionsFlow,
} from '../../../pageFlowData'
import { option1, option2 } from './data'

export const BuyingQuery: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    buying: { buyingTopic },
    triage: { type },
  } = state

  const handleTracking = (id: string, index: number) => {
    trackAppointmentBookingSelection(type, BuyingQueryFlow.VIEW_NAME, { id, index })
  }

  const chooseStep = (id: string) => {
    switch (id) {
      case 'travel':
        return DescribeCaseFlow
      case 'private-sale':
      case 'delivery-issues':
      case 'faulty-goods':
      case 'online-shopping':
      case 'retailers-going-bust':
        return ContractChoiceFlow
      default:
        return LimitedOptionsFlow
    }
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_BUYING_QUERY', data: id })
    const step = chooseStep(id)
    dispatch({ type: 'UPDATE_STEP', data: step.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: step.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string, index: number) => {
    handleTracking(id, index)
    handleDispatch(id)
  }

  const chooseData = () => {
    switch (buyingTopic) {
      case 'store-purchases':
        return option2
      case 'uk-insurance':
        return option1
      default:
        return option1
    }
  }
  const { buttons, imgObj, title } = chooseData()

  return (
    <div className={globalStyles.fullWidthButton}>
      <PillButtonPage buttons={buttons} handleSubmit={handleSubmit} imgObj={imgObj} title={title} />
    </div>
  )
}
