export const CRToolEntryData = {
  faultyGoods: {
    title: 'Make a faulty goods complaint',
    image: {
      image: {
        alt: 'Man carrying products with dog',
        src: 'https://s3-eu-west-1.amazonaws.com/prod-media-which/prod/images/original/8ad26a48af59-380x200.jpg',
      },
      title: 'What did you buy?',
    },
    calloutSteps: [
      {
        strapline:
          "Answer some simple questions about your product and we'll let you know what your consumer rights are.",
        image: {
          alt: 'evaluating consumer rights',
          src: 'https://media.product.which.co.uk/prod/images/original/5ce032d69f37-faultygoodsstep1.jpg',
        },
      },
      {
        strapline:
          "The process should take 5 minutes and we'll use your answers to draft a free complaint.",
        image: {
          alt: 'Draft your complaint letter',
          src: 'https://media.product.which.co.uk/prod/images/original/e52d96a06e70-faultygoodsstep2.jpg',
        },
      },
      {
        strapline:
          'You can then send this complaint to the retailer and escalate your problem further, if needed.',
        image: {
          src: 'https://media.product.which.co.uk/prod/images/original/88a7b181b973-faultygoodsstep3.jpg',
          alt: 'Send your letter and escalate further if needed',
        },
      },
    ],
    straplines: [
      {
        text: `If your product has developed an unexpected fault or you have been misled by the seller, you
          could be entitled to a refund, replacement or repair.`,
      },
      {
        text: `In a few simple steps, we can help you understand your rights and draft a free complaint
          email for you to send to the retailer.`,
      },
    ],
  },
  myMoneyHealthCheck: {
    title: 'My Money Health Check',
    calloutSteps: [
      {
        strapline: 'Answer seven questions on your living costs and spending ',
        image: {
          alt: '',
          src: 'https://media.product.which.co.uk/prod/images/original/7ce697d53884-mmhc-1.jpg',
        },
      },
      {
        strapline:
          'Our free tool will bring together the latest guidance that we think could help you ',
        image: {
          alt: '',
          src: 'https://media.product.which.co.uk/prod/images/original/cca92aa8c52a-mmhc-2.jpg',
        },
      },
      {
        strapline: 'See whether you could cut your costs',
        image: {
          alt: '',
          src: 'https://media.product.which.co.uk/prod/images/original/830cd8b5ff1e-mmhc-3.jpg',
        },
      },
    ],
    straplines: [
      {
        text: 'Are you worried about the cost of living? My Money Health Check is a free tool for anybody looking for ways that may help them save money during the cost of living crisis.',
        money: true,
      },
      {
        text: 'It takes <span style="font-weight: bold">less than 5 minutes</span> to complete and your <span style="font-weight: bold">answers will be anonymous.</span> It’s as easy as three steps:',
        money: true,
      },
    ],
  },
}
