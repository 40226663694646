import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'

import { CurrencyPage } from '../../pages/CurrencyPage'
import type { FormEntryInputRef, PurchasePriceObj } from '../../types/toolTypes'
import { formatToolName } from '../../utils/formatToolName'
import { generalDataLayer, pageViewDataLayer } from '../../utils/tracking'

export const PurchasePrice = forwardRef(
  (
    {
      props: {
        buttonText,
        currencySymbol,
        entryToolName,
        defaultValue,
        handleSubmit,
        id,
        imgObj,
        isFocused,
        isSubmitValid,
        milestone,
        setStartLetterMilestone,
        title,
      },
    }: PurchasePriceObj,
    ref
  ) => {
    const PurchasePriceRef = useRef<FormEntryInputRef>(null)

    useImperativeHandle(ref, () => ({
      purchasePriceRef: PurchasePriceRef.current,
    }))

    useEffect(() => {
      pageViewDataLayer('purchase-price')
    }, [])

    useEffect(() => {
      if (milestone) {
        generalDataLayer(formatToolName(entryToolName), 'milestone | write letter')
        setStartLetterMilestone()
      }
    }, [milestone, setStartLetterMilestone, entryToolName])

    return (
      <CurrencyPage
        buttonText={buttonText}
        currencySymbol={currencySymbol}
        defaultValue={defaultValue}
        handleSubmit={handleSubmit}
        id={id}
        imgObj={imgObj}
        isFocused={isFocused}
        isSubmitValid={isSubmitValid}
        ref={PurchasePriceRef}
        title={title}
      />
    )
  }
)
