import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { ContactDetailsPage } from '../../../../../shared/pages/ContactDetailsPage/ContactDetails'
import { ToolContext } from '../../../../../shared/state/appContext'
import type { ContactRefProps, MultiInputsState } from '../../../../../shared/types/toolTypes'
import { isNotEmpty } from '../../../../../shared/utils/isNotEmpty'
import { scrollToInvalidInput } from '../../../../../shared/utils/scrollToInvalidInput'
import { validatePostcode } from '../../../../../shared/utils/validatePostcode'
import { DescribeCaseFlow, HomeLocationFlow, HomeQueryFlow } from '../../../pageFlowData'
import {
  buyingCompanyData,
  buyingIndividualData,
  carsData,
  employmentData,
  landlordData,
  medicalCompanyData,
  medicalIndividualData,
  neighbourData,
  tenantData,
  willsCompanyData,
  willsData,
  willsEstateData,
} from './data'

export const ContactDetails: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { user },
    triage: { contractChoice },
    home: { homeTopic },
    cars: { carsTopic },
    employment: { employmentTopic },
    wills: { willsTopic, willsEstate, willsQuery },
    legal: { legalTopic },
    medical: { medicalTopic },
  } = state

  const [inputsData, setInputsData] = useState<MultiInputsState>({
    fNameData: { renderError: false, value: '' },
    lNameData: { renderError: false, value: '' },
    dateData: { renderError: false, value: '' },
    postcodeData: { renderError: false, value: '' },
    addressLine1Data: { renderError: false, value: '' },
    addressLine2Data: { renderError: false, value: '' },
    townCityData: { renderError: false, value: '' },
    countyData: { renderError: false, value: '' },
  })

  const [disableBtn, setDisableBtn] = useState(false)

  const ContactFormRefs = useRef<ContactRefProps>(null)

  const currentInputsList: HTMLInputElement[] = []

  for (const input in ContactFormRefs.current) {
    currentInputsList.push(ContactFormRefs.current[input])
  }

  const handleStepChoice = () => {
    if (homeTopic === 'neighbour-issue') {
      return HomeLocationFlow
    } else if (homeTopic && homeTopic !== 'issue-with-landlord') {
      return HomeQueryFlow
    }
    return DescribeCaseFlow
  }

  const handleSubmit = async () => {
    const firstNameValue: string = ContactFormRefs.current?.firstName.value || ''
    const firstNameHasError =
      !isNotEmpty(firstNameValue) ||
      (contractChoice !== 'limited-company' && !/^[a-z '-]+$/i.test(firstNameValue))

    const lastNameNeeded = contractChoice !== 'limited-company' && medicalTopic !== 'other-company'
    const lastNameValue: string = lastNameNeeded
      ? ContactFormRefs.current?.lastName.value || ''
      : ''
    const lastNameHasError = lastNameNeeded
      ? !isNotEmpty(lastNameValue) || !/^[a-z '-]+$/i.test(lastNameValue)
      : false

    const dateValue: string = ContactFormRefs.current?.date?.value || ''
    const dateHasError =
      willsEstate === 'somebody-else'
        ? !isNotEmpty(dateValue) || !/^[a-z '-]+$/i.test(firstNameValue)
        : false

    const postcodeValue: string = ContactFormRefs.current?.postcode?.value || ''
    const postcodeHasError = !isNotEmpty(postcodeValue) || !validatePostcode(postcodeValue)

    const addressLine1Value: string = ContactFormRefs.current?.addressLine1?.value || ''
    const addressLine1HasError = !isNotEmpty(addressLine1Value)

    const addressLine2Value: string = ContactFormRefs.current?.addressLine2?.value || ''
    const addressLine2HasError = !isNotEmpty(addressLine2Value)

    const townCityValue: string = ContactFormRefs.current?.townCity?.value || ''
    const townCityHasError = !isNotEmpty(townCityValue)

    const countyValue: string = ContactFormRefs.current?.county?.value || ''
    const countyHasError = !isNotEmpty(countyValue)

    setDisableBtn(true)

    setInputsData({
      fNameData: { renderError: firstNameHasError, value: firstNameValue },
      lNameData: { renderError: lastNameHasError, value: lastNameValue },
      dateData: { renderError: dateHasError, value: dateValue },
      postcodeData: { renderError: postcodeHasError, value: postcodeValue },
      addressLine1Data: { renderError: addressLine1HasError, value: addressLine1Value },
      addressLine2Data: { renderError: addressLine2HasError, value: addressLine2Value },
      townCityData: { renderError: townCityHasError, value: townCityValue },
      countyData: { renderError: countyHasError, value: countyValue },
    })

    if (
      !firstNameHasError &&
      !lastNameHasError &&
      !dateHasError &&
      !postcodeHasError &&
      !addressLine1HasError &&
      !townCityHasError
    ) {
      const userData = {
        firstName: firstNameValue,
        lastName: lastNameValue,
        address: {
          postcode: postcodeValue,
          addressLine1: addressLine1Value,
          addressLine2: addressLine2Value,
          townCity: townCityValue,
          county: countyValue,
        },
        date: dateValue,
      }

      dispatch({ type: 'UPDATE_USER', data: userData })
      const step = handleStepChoice()
      dispatch({ type: 'UPDATE_STEP', data: step.STEP })
      dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: step.PROGRESS_VALUE })
    } else {
      scrollToInvalidInput(currentInputsList)
      setDisableBtn(false)
    }
  }

  const chooseDataSet = () => {
    switch (contractChoice) {
      case 'individual':
        return buyingIndividualData
      case 'limited-company':
        if (legalTopic === 'wills') {
          return willsCompanyData
        }
        return buyingCompanyData
    }
    switch (homeTopic) {
      case 'issue-with-tenant':
        return tenantData
      case 'neighbour-issue':
        return neighbourData
      case 'issue-with-landlord':
        return landlordData
    }
    if (medicalTopic === 'individual') {
      return medicalIndividualData
    } else if (medicalTopic === 'other-company') {
      return medicalCompanyData
    } else if (carsTopic) {
      return carsData
    } else if (employmentTopic) {
      return employmentData
    } else if (willsTopic && willsEstate !== 'somebody-else') {
      return willsData
    } else if (willsEstate === 'somebody-else') {
      return willsEstateData
    } else if (willsQuery === 'negligence') {
      return willsData
    }
    return buyingIndividualData
  }

  const data = chooseDataSet()

  const { imgObj, title, formItems, addressItems } = data

  const formItemsMapped = formItems.map((x) => ({
    ...x,
    enterKeyHandler: handleSubmit,
  }))

  const addressItemsMapped = addressItems.map((x) => ({
    ...x,
    enterKeyHandler: handleSubmit,
  }))

  return (
    <ContactDetailsPage
      addressItems={addressItemsMapped}
      btnDisable={disableBtn}
      buttonText="Next"
      formItems={formItemsMapped}
      handleSubmit={handleSubmit}
      imgObj={imgObj}
      infoBoxData={data}
      inputsData={inputsData}
      ref={ContactFormRefs}
      testId="contact-details"
      title={title}
      user={user}
    />
  )
}
