import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../../shared/state/appContext'
import { trackAppointmentBookingSelection } from '../../../../../shared/utils/tracking/tracking'
import {
  CarsLegalFlow,
  ContactDetailsFlow,
  DescribeCaseFlow,
  LimitedOptionsFlow,
} from '../../../pageFlowData'
import { carsLegalData } from './data'

export const CarsLegal: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    cars: { carsTopic },
    triage: { type },
  } = state

  const handleTracking = (id: string, index: number) => {
    trackAppointmentBookingSelection(type, CarsLegalFlow.VIEW_NAME, { id, index })
  }

  const chooseStep = (id: string) => {
    if (id === 'yes') {
      return LimitedOptionsFlow
    } else if (carsTopic === 'road-traffic') {
      return ContactDetailsFlow
    }
    return DescribeCaseFlow
  }

  const handleDispatch = (id: string) => {
    const step = chooseStep(id)
    dispatch({ type: 'UPDATE_STEP', data: step.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: step.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string, index: number) => {
    handleTracking(id, index)
    handleDispatch(id)
  }

  const { buttons, imgObj, title } = carsLegalData

  return (
    <div>
      <PillButtonPage buttons={buttons} handleSubmit={handleSubmit} imgObj={imgObj} title={title} />
    </div>
  )
}
