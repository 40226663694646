import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { ArticleCTAPage } from '../../../../shared/pages/ArticleCTAPage/ArticleCTAPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { generalDataLayer } from '../../../../shared/utils/tracking/tracking'
import { chooseDataSet } from './chooseDataSet'

export const LimitedOptions: FunctionComponent = () => {
  const toolContext = useContext(ToolContext)

  const { state } = toolContext
  const { product, retailer } = state

  const { articleData, ctaData } = chooseDataSet(product.price, retailer.repeatPerformance)
  const { bodyCopy, standFirst, title, link } = articleData
  const { headline, href, text } = link

  const handleTracking = () => {
    generalDataLayer('s75 and chargeback', `onward journey | ${text} | ${href}`)
  }

  return (
    <div data-testid="limited-options">
      <ArticleCTAPage
        articleHeading={text}
        articleLink={link}
        articleTitle={headline}
        bodyCopy={bodyCopy}
        ctaData={ctaData}
        handleSubmit={() => handleTracking()}
        standFirst={standFirst}
        title={title}
      />
    </div>
  )
}
