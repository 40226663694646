import type { scamSharerToolState } from '../state/scamSharerToolState'
import type { UserPropsWithAddress } from './../../../shared/types/toolTypes'

export const pageData = (state: {
  scam: typeof scamSharerToolState.scam
  victim: typeof scamSharerToolState.victim
  optIns: {
    singleConsentOptIn: boolean
  }
  user: UserPropsWithAddress
}) => {
  const { emailAddress, firstName, lastName, phoneNumber } = state.user
  const { singleConsentOptIn } = state.optIns

  const {
    comeAcrossScam,
    moreAboutScam,
    scammerContact,
    moreAboutScammer,
    whatHappened,
    whoWasScammer,
    typeOfScammer,
    nameOfScammer,
    socialWhatHappened,
    whatScamEntry,
    whatAboutMessage,
    haveContact,
    scammerReference,
  } = state.scam

  const {
    beenAffected,
    lostMoney,
    howMuchMoney,
    howDidYouPay,
    whatBank,
    reportedTo,
    outcome,
    reportedToOmbudsman,
    age,
    postcode,
    gender,
    getInTouch,
    whoReportedTo,
  } = state.victim

  const questions: any = {}

  switch (comeAcrossScam) {
    case 'telephone':
      questions['question.869598'] = whoWasScammer // What was the nature of the call?
      questions['question.869858'] = typeOfScammer // What was the nature of the call? (Optional)
      questions['question.883004'] = nameOfScammer // What was the name of the company/organisation the caller said they were calling from? (Optional)
      questions['question.872088'] = haveContact // What number did they call from?
      questions['question.882972'] = scammerReference // Please enter the number (Optional)
      break
    case 'online':
      questions['question.869616'] = whoWasScammer // Can you tell us more about what type of scam this was?
      questions['question.888257'] = typeOfScammer // Can you tell us more about what type of scam this was? (Optional)
      questions['question.883034'] = nameOfScammer // What was the name of the company or organisation? (Optional)
      questions['question.872089'] = haveContact // Do you have the link to the website or social media page?
      questions['question.872090'] = scammerReference // Please enter the link to the website (Optional)
      break
    case 'email':
      questions['question.869801'] = whoWasScammer // Who did the email appear to be from?
      questions['question.872112'] = nameOfScammer // Name of the company/organisation the email appeared to be from (Optional)
      questions['question.872113'] = haveContact // Do you have the email address that the email was sent from?
      questions['question.883667'] = scammerReference // Please enter the email address the email was sent from (Optional)
      break
    case 'whatsapp':
    case 'text-message':
      questions['question.869802'] = whoWasScammer // Who did the text appear to be from?
      questions['question.872114'] = nameOfScammer // Name of the company/organisation the text appeared to be from (Optional)
      questions['question.872115'] = haveContact // Do you have the number the text came from?
      questions['question.869861'] = scammerReference // Please enter the number the text came from (Optional)
      break
    case 'came-to-house':
      questions['question.869803'] = whoWasScammer // Who did the person claim to be?
      questions['question.869862'] = typeOfScammer // Who did the person claim to be? (Optional)
      break
    case 'letter':
      questions['question.869804'] = whoWasScammer // Who did the letter or flyer appear to be from?
      questions['question.872116'] = nameOfScammer // Name of the company/organisation the letter or flyer appeared to be from (Optional)
      break
    case 'social-media':
      questions['question.869814'] = whoWasScammer // Which social media platform?
      questions['question.869864'] = typeOfScammer // Which social media platform? (Optional)
      questions['question.872117'] = socialWhatHappened // What happened?
      if (socialWhatHappened === 'website') {
        questions['question.872118'] = whatScamEntry // What type of website was it?
      } else {
        questions['question.872127'] = whatScamEntry // What was the message about?
      }
      questions['question.872128'] = whatAboutMessage // What was the message about? (Optional)
      questions['question.872121'] = nameOfScammer // What was the name of the company/organisation the website appeared to be (Optional)
      questions['question.872122'] = haveContact // Do you have the website address?
      questions['question.872120'] = scammerReference // Please enter the website address (Optional)
      break

    default:
      break
  }

  // Questions for scam sharer

  questions['question.869534'] = comeAcrossScam // How did you come across the scam?
  questions['question.869859'] = moreAboutScam // Can you tell us more about the scam? E.g. How you came across the scam and what type of scam it was? (Optional)
  questions['question.945018'] = scammerContact // Did the scammer contact you in more than one way? E.g Telephone call & Email
  questions['question.945019'] = moreAboutScammer // Can you tell us more about the different ways the scammer contacted you? (Optional)
  questions['question.972457'] = whatHappened // Can you share a short description of what happened?  (Optional)

  questions['question.869532'] = beenAffected // Have you been affected by the scam?
  questions['question.870923'] = lostMoney // Did you lose any money?
  questions['question.984856'] = howMuchMoney // Approximately how much money was taken?
  questions['question.872172'] = howDidYouPay // How did you pay?
  questions['question.872230'] = whatBank // Which bank/credit card provider was the money taken from?

  if (lostMoney === 'yes') {
    questions['question.872390'] = reportedTo // Who have you reported the scam to? (Check all that apply) (LOST MONEY)
  } else {
    questions['question.872226'] = reportedTo // Who have you reported the scam to? (Check all that apply) (DID NOT LOSE MONEY)
  }
  questions['question.872227'] = whoReportedTo // Who have you reported the scam to? (Optional)
  questions['question.872392'] = outcome // What was the outcome?
  questions['question.872437'] = reportedToOmbudsman // Have you reported to the Financial Ombudsman Service (FOS)?
  questions['question.872454'] = age // What is your age?
  questions['question.872455'] = postcode // What is the first half of your postcode? (Optional)
  questions['question.883831'] = gender // What is your gender?
  questions['question.877696'] = getInTouch // Would you be happy for a member of the Which? team to get in touch?

  // optins

  questions['question.1598463'] = singleConsentOptIn ? 'Y' : 'N' // TF - Single Consent Opt In
  questions['question.1184531'] = 'Y' // TF - Impact survey opt-in

  // return supporter
  return {
    supporter: {
      Email: emailAddress,
      'First name': firstName,
      'Last name': lastName,
      Phone: phoneNumber,
      questions,
    },
  }
}
