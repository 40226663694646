// A separate object for the infoBox data is used due to the typing of the borderColour attribute
import type { InfoBoxProps } from '../../../../../shared/types/toolTypes'
import styles from '../../../../../shared/utils/renderInfoBox/renderInfoBox.module.scss'

export const infoBox: InfoBoxProps = {
  heading: 'What evidence do I need to make an appeal?',
  borderColour: 'blue',
}

export const data = {
  buttons: [
    {
      label: 'Yes I do',
      id: 'yes',
    },
    {
      label: "No but I'd like to continue",
      id: 'no',
    },
  ],
  infoBox,
  infoBoxParagraphs: [
    {
      classname: `${styles.renderInfoBoxParagraphs}`,
      text: 'Try to provide any supporting evidence you can, including:',
    },
    {
      text: '- Photographs of any particular road signs or road markings that are unclear.',
    },
    {
      text: '- Photographs of the ticket meter, if it was broken.',
    },
    {
      text: '- Witness statements.',
    },
    {
      text: '- Any evidence of mitigating circumstances',
    },
    {
      text: '- The crime reference number if your car was stolen',
    },
    {
      classname: `${styles.renderInfoBoxParagraphs}`,
      text: 'Send this evidence with the appeal letter that will be generated once you complete this tool.',
    },
    {
      text: "You can appeal without any evidence but you're less likely to succeed.",
    },
  ],
  imgObj: {
    alt: 'A women sitting at a desk typing on a laptop',
    src: 'https://media.product.which.co.uk/prod/images/original/gm-8129e301-0352-4df5-ad15-fa9d39955687-supporting-evidence.jpg',
  },
  title: 'Do you have evidence to support your appeal?',
}
