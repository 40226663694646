import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'
import { Typography } from '@which/seatbelt'

import classNames from 'classnames'

import { surveyInfo } from '../../../../shared/pages/ImpactSurveyPage/data'
import { ToolContext } from '../../../../shared/state/appContext'
import globalStyles from '../../../../shared/styles/GlobalStyles.module.scss'
import { surveyModuleSpecificText } from '../../../../shared/utils/surveyModuleSpecificText/surveyModuleSpecificText'
import styles from './ThankYou.module.scss'

export const ThankYou: FunctionComponent = () => {
  const { state } = useContext(ToolContext)

  const {
    user: { toolID },
    answers: { reasonNot },
  } = state
  const toolSpecificText = surveyModuleSpecificText(toolID)

  const title = 'Thank you for your time'
  const text =
    reasonNot.length > 0
      ? 'We hope our consumer rights tools and advice will be more helpful for you in the future.'
      : `We hope your ${toolSpecificText} is successful once you do submit it.`

  return (
    <div className={styles.thankYou}>
      <Typography tag="h1" textStyle="title-600">
        {title}
      </Typography>
      <Typography className={styles.thankYouStandFirst} tag="p" textStyle="body">
        {text}
      </Typography>
      <Typography
        className={classNames(globalStyles.spacing40Top, styles.thankYouLink)}
        dangerouslySetInnerHTML={{ __html: surveyInfo }}
        textStyle="body"
      />
    </div>
  )
}
