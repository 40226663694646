import React from 'react'

import { parseJson } from '../../../shared/utils/parseJson/parseJson'
import { HowMuch } from '../PageComponents/HowMuch'
import { PaymentPrediction } from '../PageComponents/PaymentPrediction'
import { HowMuchFlow, PaymentPredictionFlow } from '../pageFlowData'

export const useRenderStep = (step: number, optionsJson) => {
  if (optionsJson && !parseJson(optionsJson)) {
    return null
  }

  const parsedJson = parseJson(optionsJson)

  const validateMultiplierOptions = () => {
    if (!Array.isArray(parsedJson) || parsedJson.length === 0) {
      return false
    }

    const isValidEntry = (entry) => {
      if (entry.cpi && typeof entry.cpi === 'string') {
        return true
      }

      if (entry.providers?.length) {
        return entry.providers.every(isValidEntry)
      }

      if (
        entry.provider &&
        typeof entry.provider === 'string' &&
        entry.multiplier &&
        typeof entry.multiplier === 'string'
      ) {
        return true
      }

      return false
    }

    return parsedJson.every(isValidEntry)
  }

  const isValidMultiplierData = validateMultiplierOptions()

  if (!isValidMultiplierData) {
    return null
  }

  switch (step) {
    case HowMuchFlow.STEP:
      return <HowMuch />
    case PaymentPredictionFlow.STEP:
      return <PaymentPrediction />

    default:
      return <HowMuch />
  }
}
