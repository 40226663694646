import type { Reducer } from 'react'

import type { Action, State } from '../toolStateTypes'

export const defaultToolReducer: Reducer<State, Action> = (state, payload) => {
  switch (payload.type) {
    case 'UPDATE_INITIALISED': {
      return {
        ...state,
        initialised: payload.data,
      }
    }
    case 'UPDATE_STEP': {
      return {
        ...state,
        step: payload.data,
      }
    }
    case 'UPDATE_PROGRESS_VALUE': {
      return {
        ...state,
        progressValue: payload.data,
      }
    }
    case 'UPDATE_USER_JOURNEY': {
      return {
        ...state,
        userJourney: payload.data,
      }
    }
    case 'UPDATE_ALL_STATE': {
      return {
        ...payload.data,
      }
    }
    default:
      throw new Error(`Unhandled action type: ${payload.type}`)
  }
}
