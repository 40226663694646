import { getCookie } from '@which/shared'

export const getBlaize = (): string => {
  if (typeof window === 'undefined') {
    return ''
  } else {
    switch (window.location?.hostname) {
      case 'localhost':
        return process.env.DEV_BLAIZE_SESSION || ''
      default:
        return getCookie(document.cookie, 'blaize_session')
    }
  }
}
