import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'
import { Typography } from '@which/seatbelt'

import { ImageWithQuestion } from '../../../../shared/components/ImageWithQuestion'
import { PillButtonGroup } from '../../../../shared/components/PillButtonGroup'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { CorrectCarFlow, RegNumberFlow, WhereBoughtFlow } from '../../pageFlowData'
import styles from './CorrectCar.module.scss'
import { correctCarData } from './data'

export const CorrectCar: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    car: { vehicleDetails },
  } = state

  const handleStepChoice = (id: string) => {
    if (id === 'yes') {
      return { progress: WhereBoughtFlow.PROGRESS_VALUE, step: WhereBoughtFlow.STEP, forward: true }
    }

    return { progress: RegNumberFlow.PROGRESS_VALUE, step: RegNumberFlow.STEP, forward: false }
  }

  const handleTracking = (id: string) => {
    const trackingAnswer = `question | ${CorrectCarFlow.VIEW_NAME} | ${id}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (id: string) => {
    const { forward, progress, step } = handleStepChoice(id)

    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
    if (!forward) {
      dispatch({ type: 'UPDATE_ISFORWARD', data: false })
    }
  }

  const handleSubmit = (id: string) => {
    handleTracking(id)
    handleDispatch(id)
  }

  const { vehicleReg, vehicleMake, vehicleModel, vehicleYear } = vehicleDetails
  const { buttons, imgObj, title } = correctCarData

  return (
    <div className={styles.correctCar} data-testid="correct-car-page">
      <ImageWithQuestion image={imgObj} title={title} />
      <div className={styles.correctCarInfoWrapper} data-testid="correct-car">
        <div className={styles.correctCarColumn}>
          <p className={styles.correctCarColumnTitle} data-testid="typography-body">
            Registration
          </p>
          <Typography>{vehicleReg}</Typography>
        </div>
        <div className={styles.correctCarColumn}>
          <p className={styles.correctCarColumnTitle} data-testid="typography-body">
            Make
          </p>
          <Typography className={styles.capitalise}>{vehicleMake}</Typography>
        </div>
        <div className={styles.correctCarColumn}>
          <p className={styles.correctCarColumnTitle} data-testid="typography-body">
            Model
          </p>
          <Typography className={styles.capitalise}>
            {vehicleModel} ({vehicleYear})
          </Typography>
        </div>
      </div>
      <PillButtonGroup buttons={buttons} defaultValue={''} handleSubmit={handleSubmit} />
    </div>
  )
}
