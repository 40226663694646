export const feedbackData = {
  imgObj: {
    src: 'https://media.product.which.co.uk/prod/images/original/gm-df96b9c5-d989-4ce3-bf8f-af0de38dc7e9-feedbackentry.jpg',
    alt: 'A man and a woman holding a large gold star',
  },
  feedbackTitle: 'Please could you spare two minutes to share your thoughts on using this tool?',
  body: 'Your feedback will help us improve the experience for others.',
  list: [
    'Check that the information in the letter below is correct.',
    'Gather copies of your booking reservation and any extra receipts, including receipts for out-of-pocket expenses.',
    'Send the letter along with the supporting evidence to the holiday company you booked with by post or email.',
    "If you don't hear back, you can complain to a travel industry association or take the dispute to a small claims court. <a class='sb-link-primary' href='https://www.which.co.uk/consumer-rights/advice/how-to-complain-about-your-holiday-booking-ay4Pa7Z0vix2#take-your-case-to-the-small-claims-court'><span class='sb-link-animation-wrapper'>Learn more here</span></a>.",
    'If you have remaining questions about your legal rights in regards to your holiday, Which? has a <a class="sb-link-primary" href="https://legalservice.which.co.uk/"><span class="sb-link-animation-wrapper">dedicated legal service who can advise you</span></a>.',
  ],
  letterTitle: 'Your complaint email',
  title: 'Your next steps',
}
