export const wasSuccessfulData = {
  radioQuestionData: {
    buttons: [
      {
        label: 'Yes',
        id: 'yes',
        isFocused: false,
      },
      {
        label: 'No',
        id: 'no',
        isFocused: false,
      },
      {
        label: "Don't know - have not heard anything back yet",
        id: 'not-heard-anything',
        isFocused: false,
      },
      {
        label: "Don't know - still being processed",
        id: 'still-being-processed',
        isFocused: false,
      },
    ],
    isSubmitValid: true,
    id: 'was-successful',
  },
}
