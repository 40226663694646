import type { FunctionComponent } from 'react'
import React from 'react'

import { ImageWithQuestion } from '../../components/ImageWithQuestion'
import { PillButtonGroup } from '../../components/PillButtonGroup'
import type { PillButtonPageProps } from '../../types/toolTypes'
import { RenderInfoBox } from '../../utils/renderInfoBox'
import styles from './PillButtonPage.module.scss'

export const PillButtonPage: FunctionComponent<PillButtonPageProps> = ({
  buttons,
  defaultValue,
  handleSubmit,
  imgObj,
  pillButtonGroupStyle,
  title,
  infoBoxData,
  fitContent = false,
  standFirst,
}) => {
  return (
    <div data-testid="pill-button-page">
      <ImageWithQuestion image={imgObj} title={title} />
      {standFirst && (
        <p className={styles.pillButtonPageStandFirst} data-testid="typography-body">
          {standFirst}
        </p>
      )}
      <PillButtonGroup
        buttons={buttons}
        className={pillButtonGroupStyle}
        defaultValue={defaultValue}
        fitContent={fitContent}
        handleSubmit={handleSubmit}
      />
      {infoBoxData && <RenderInfoBox data={infoBoxData} />}
    </div>
  )
}
