import type { Reducer } from 'react'

import { initialSliceReducer, toolFeedbackSliceReducer } from '../../../shared/state/reducers'
import type { Action, State } from '../../../shared/state/toolStateTypes'
import { combineReducers } from '../../../shared/utils/combineReducers'

export const scamReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_COME_ACROSS_SCAM':
      return {
        ...state,
        comeAcrossScam: data,
      }

    case 'UPDATE_MORE_ABOUT_SCAM':
      return {
        ...state,
        moreAboutScam: data,
      }

    case 'UPDATE_SCAMMER_CONTACT':
      return {
        ...state,
        scammerContact: data,
      }

    case 'UPDATE_MORE_ABOUT_SCAMMER':
      return {
        ...state,
        moreAboutScammer: data,
      }

    case 'UPDATE_WHAT_HAPPENED':
      return {
        ...state,
        whatHappened: data,
      }

    case 'UPDATE_WHO_WAS_SCAMMER':
      return {
        ...state,
        whoWasScammer: data,
      }

    case 'UPDATE_TYPE_OF_SCAMMER':
      return {
        ...state,
        typeOfScammer: data,
      }

    case 'UPDATE_NAME_OF_SCAMMER':
      return {
        ...state,
        nameOfScammer: data,
      }

    case 'UPDATE_SOCIAL_WHAT_HAPPENED':
      return {
        ...state,
        socialWhatHappened: data,
      }

    case 'UPDATE_WHAT_SCAM_ENTRY':
      return {
        ...state,
        whatScamEntry: data,
      }

    case 'UPDATE_WHAT_ABOUT_MESSAGE':
      return {
        ...state,
        whatAboutMessage: data,
      }

    case 'UPDATE_HAVE_CONTACT':
      return {
        ...state,
        haveContact: data,
      }

    case 'UPDATE_SCAMMER_REFERENCE':
      return {
        ...state,
        scammerReference: data,
      }

    default:
      return state
  }
}

export const victimReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  switch (type) {
    case 'UPDATE_BEEN_AFFECTED':
      return {
        ...state,
        beenAffected: data,
      }

    case 'UPDATE_LOST_MONEY':
      return {
        ...state,
        lostMoney: data,
      }

    case 'UPDATE_HOW_MUCH_MONEY':
      return {
        ...state,
        howMuchMoney: data,
      }

    case 'UPDATE_HOW_DID_YOU_PAY':
      return {
        ...state,
        howDidYouPay: data,
      }

    case 'UPDATE_WHAT_BANK':
      return {
        ...state,
        whatBank: data,
      }

    case 'UPDATE_REPORTED_TO':
      return {
        ...state,
        reportedTo: data,
      }

    case 'UPDATE_WHO_REPORTED_TO':
      return {
        ...state,
        whoReportedTo: data,
      }

    case 'UPDATE_OUTCOME':
      return {
        ...state,
        outcome: data,
      }

    case 'UPDATE_REPORTED_TO_OMBUDSMAN':
      return {
        ...state,
        reportedToOmbudsman: data,
      }

    case 'UPDATE_AGE':
      return {
        ...state,
        age: data,
      }

    case 'UPDATE_POSTCODE':
      return {
        ...state,
        postcode: data,
      }

    case 'UPDATE_GENDER':
      return {
        ...state,
        gender: data,
      }

    case 'UPDATE_GET_IN_TOUCH':
      return {
        ...state,
        getInTouch: data,
      }

    default:
      return state
  }
}

export const scamSharerReducer = combineReducers({
  initial: initialSliceReducer,
  scam: scamReducer,
  victim: victimReducer,
  toolFeedback: toolFeedbackSliceReducer,
})
