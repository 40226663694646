import type { FunctionComponent } from 'react'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'

import { ContactDetailsPage } from '../../../../shared/pages/ContactDetailsPage/ContactDetails'
import { ToolContext } from '../../../../shared/state/appContext'
import type { ContactRefProps, MultiInputsState } from '../../../../shared/types/toolTypes'
import { pageSubmission } from '../../../../shared/utils/EngagingNetwork/pageSubmission'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { scrollToInvalidInput } from '../../../../shared/utils/scrollToInvalidInput'
import { validateEmail } from '../../../../shared/utils/validateEmail'
import { validatePhoneNumber } from '../../../../shared/utils/validatePhoneNumber'
import { pageData } from '../../pageData'
import { SubmitSuccessfulFlow } from '../../pageFlowData'
import { contactDetailsData } from './data'

export const ContactDetails: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: {
      optIns: { singleConsentOptIn },
      user,
    },
    victim,
    scam,
  } = state

  const [inputsData, setInputsData] = useState<MultiInputsState>({
    fNameData: { renderError: false, value: '' },
    lNameData: { renderError: false, value: '' },
    emailData: { renderError: false, value: '' },
    phoneData: { renderError: false, value: '' },
  })

  const [errorCount, setErrorCount] = useState(0)
  const [disableBtn, setDisableBtn] = useState(false)

  const ContactFormRefs = useRef<ContactRefProps>(null)

  const currentInputsList: HTMLInputElement[] = []

  for (const input in ContactFormRefs.current) {
    currentInputsList.push(ContactFormRefs.current[input])
  }

  const handleDispatch = useCallback(
    (emailSent: boolean) => {
      dispatch({ type: 'UPDATE_EMAIL_SENT', data: emailSent })
      dispatch({ type: 'UPDATE_STEP', data: SubmitSuccessfulFlow.STEP })
      dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: SubmitSuccessfulFlow.PROGRESS_VALUE })
    },
    [dispatch]
  )

  useEffect(() => {
    if (errorCount > 1) {
      // If they've gotten two or more errors, go to NextSteps anyway
      handleDispatch(false)
    }
  }, [errorCount, handleDispatch])

  const validateInputs = () => {
    const firstNameValue: string = ContactFormRefs.current?.firstName.value || ''
    const firstNameHasError = !isNotEmpty(firstNameValue) || !/^[a-z '-]+$/i.test(firstNameValue)

    const lastNameValue: string = ContactFormRefs.current?.lastName.value || ''
    const lastNameHasError = !isNotEmpty(lastNameValue) || !/^[a-z '-]+$/i.test(lastNameValue)

    const emailValue: string = ContactFormRefs.current?.emailAddress.value || ''
    const emailHasError = !isNotEmpty(emailValue) || !validateEmail(emailValue)

    const phoneNumberValue: string = ContactFormRefs.current?.phoneNumber?.value || ''
    const phoneNumberHasError =
      !isNotEmpty(phoneNumberValue) || !validatePhoneNumber(phoneNumberValue)

    const data = {
      fNameData: { renderError: firstNameHasError, value: firstNameValue },
      lNameData: { renderError: lastNameHasError, value: lastNameValue },
      emailData: { renderError: emailHasError, value: emailValue },
      phoneData: { renderError: phoneNumberHasError, value: phoneNumberValue },
    }

    setInputsData(data)

    const isValid =
      firstNameHasError || lastNameHasError || phoneNumberHasError || emailHasError ? false : true

    return { inputData: data, isValid: isValid }
  }

  const buildUserDetails = (data: any) => {
    const { fNameData, lNameData, emailData: emailContent, phoneData } = data

    const userData = {
      firstName: fNameData.value,
      lastName: lNameData.value,
      emailAddress: emailContent.value,
      phoneNumber: phoneData.value,
    }

    return userData
  }

  const buildPageDataState = (userData: any) => {
    const pageDataState = {
      scam,
      victim,
      optIns: { singleConsentOptIn },
      user: { ...userData },
    }

    return pageDataState
  }

  const handleSubmit = async () => {
    const submissionFormId = 111358
    const validationData = validateInputs()

    setDisableBtn(true)

    if (validationData.isValid) {
      const userData = buildUserDetails(validationData.inputData)

      const pageDataState = buildPageDataState(userData)

      dispatch({
        type: 'UPDATE_USER',
        data: userData,
      })

      const structuredData = pageData(pageDataState)
      const ENResponse = await pageSubmission(structuredData, submissionFormId)

      if (ENResponse && (ENResponse.ok || ENResponse.status === 'SUCCESS')) {
        handleDispatch(true)
      } else {
        setErrorCount(errorCount + 1)
        setDisableBtn(false)
      }
    } else {
      scrollToInvalidInput(currentInputsList)
      setDisableBtn(false)
    }
  }

  const formItems = contactDetailsData.formItems.map((x) => ({
    ...x,
    enterKeyHandler: handleSubmit,
  }))

  const handleSingleConsentOptInChange = () => {
    dispatch({ type: 'UPDATE_SINGLE_CONSENT_OPTIN', data: !singleConsentOptIn })
  }

  const optins = [
    {
      checked: singleConsentOptIn,
      label:
        'Keep me updated about products and services from the Which? Group and the work it does to make life simpler, safer and fairer for UK consumers.',
      name: 'acceptedSingleConsentOptIn',
      onChangeCallback: handleSingleConsentOptInChange,
    },
  ]

  return (
    <ContactDetailsPage
      btnDisable={disableBtn}
      buttonText="Send complaint email to me"
      errorCount={errorCount}
      formItems={formItems}
      handleSubmit={handleSubmit}
      imgObj={{
        alt: 'A woman working on a laptop',
        src: 'https://media.product.which.co.uk/prod/images/original/97bfb27352c5-entercontactdetails.jpg',
      }}
      inputsData={inputsData}
      optins={optins}
      ref={ContactFormRefs}
      showTerms
      testId="contact-details"
      title="Enter your contact details"
      user={user}
    />
  )
}
