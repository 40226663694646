export const howMuchData = {
  imgObj: {
    alt: '',
    src: 'https://media.product.which.co.uk/prod/images/original/132cc7879a64-price-rise-calc-tool-img.jpg',
  },
  pageData: {
    title: 'Mobile and broadband price rise calculator',
    standfirst: [
      "Use our free tool to find out how much your broadband or mobile bill could increase in April, as a result of the confusing inflation-linked price hikes we're campaigning against.",
    ],
    providerQuestion: 'Who is your mobile or broadband provider?',
    paymentQuestion: 'What is your current monthly payment for this service?',
    paymentNote:
      '*If your mobile bill shows a separate cost for your airtime (data, minutes and messages), use this figure.',
    rpiMessage:
      'Virgin Media and O2 use RPI, which will be announced on February 14. Check back in February to see how your monthly bill might change.',
    adhocMessage:
      "Your provider doesn't use inflation-based price rises, but may implement ad-hoc price rises instead. Look out for communications from your provider to confirm any increases to your monthly bills.",
    fixedPriceMessage:
      "Great news - your provider has a fixed price commitment, so your bill isn't subject to unfair price rises and shouldn't change if you're within your contract period.",
    fixedPrice2024Message:
      'Great news - your provider has agreed not to increase prices for new and existing customers in 2024',
  },
}
