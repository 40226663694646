import type { FunctionComponent } from 'react'
import React, { useContext, useEffect, useRef, useState } from 'react'

import { FormEntryItem } from '../../../../shared/components/FormEntryItem'
import { ImageWithQuestion } from '../../../../shared/components/ImageWithQuestion'
import { NextButton } from '../../../../shared/components/NextButton'
import { ToolContext } from '../../../../shared/state/appContext'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import {
  compareAirports,
  googleMapsScript,
  handleAutocomplete_airports_only,
} from '../../../../shared/utils/googlePlacesApi/googlePlacesAPI'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { DelayedCancelledFlow, WhichAirportsFlow } from '../../pageFlowData'
import { data } from './data'
import styles from './WhichAirport.module.scss'

export const WhichAirports: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    flight: { airports, arriveDepartUK },
  } = state

  const ukAirportsOnlyStr =
    arriveDepartUK === 'flight-departed-uk' ? 'departure-airport-input' : 'arrival-airport-input'

  const departureAirportInput = useRef<HTMLInputElement | null>(null)
  const arrivalAirportInput = useRef<HTMLInputElement | null>(null)

  const [isDepartureValid, setIsDepartureValid] = useState<boolean>(true)
  const [isArrivalValid, setIsArrivalValid] = useState<boolean>(true)
  const [arrivalErrorText, setArrivalErrorText] = useState<string>(
    'Please fill out this field to continue'
  )
  const [departureErrorText, setDepartureErrorText] = useState<string>(
    'Please fill out this field to continue'
  )

  const handleDispatch = () => {
    dispatch({ type: 'UPDATE_STEP', data: DelayedCancelledFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: DelayedCancelledFlow.PROGRESS_VALUE })
    dispatch({
      type: 'UPDATE_AIRPORTS',
      data: {
        departureAirport: {
          name: departureAirportInput?.current?.value,
          geolocation: {
            lat: departureAirportData?.geometry?.location?.lat(),
            lng: departureAirportData?.geometry?.location?.lng(),
          },
        },
        arrivalAirport: {
          name: arrivalAirportInput?.current?.value,
          geolocation: {
            lat: arrivalAirportData?.geometry?.location?.lat(),
            lng: arrivalAirportData?.geometry?.location?.lng(),
          },
        },
      },
    })
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${WhichAirportsFlow.VIEW_NAME} | Departure Airport: ${departureAirportInput.current?.value} - Arrival Airport: ${arrivalAirportInput.current?.value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = async () => {
    const departureApt = cleanText(departureAirportInput.current?.value || '')
    const arrivalApt = cleanText(arrivalAirportInput.current?.value || '')
    const isValid: boolean = validateInputs(departureApt, arrivalApt)

    if (isValid) {
      handleTracking()
      handleDispatch()
    }
  }

  const validateInputs = (departureAirport: string, arrivalAirport: string) => {
    const airportsMatch = compareAirports(arrivalAirport, departureAirport)
    const departureAirportValid = isNotEmpty(departureAirport)
    const arrivalAirportValid = isNotEmpty(arrivalAirport)
    const airportsSelectedAreDiffernet = departureAirport === arrivalAirport ? false : true

    const arrivalTextError = !arrivalAirportValid
      ? 'Please fill out this field to continue'
      : !airportsSelectedAreDiffernet
        ? 'Please select a different arrival airport from your departure airport'
        : !airportsMatch.arrivalAirport
          ? 'Please select an airport from the list supplied'
          : ''

    const departureTextError = !departureAirportValid
      ? 'Please fill out this field to continue'
      : !airportsMatch.departureAirport
        ? 'Please select an airport from the list supplied'
        : ''

    setIsDepartureValid(departureAirportValid && airportsMatch.departureAirport)
    setIsArrivalValid(
      arrivalAirportValid && airportsSelectedAreDiffernet && airportsMatch.arrivalAirport
    )

    const isValid =
      airportsMatch.isValid &&
      departureAirportValid &&
      arrivalAirportValid &&
      airportsSelectedAreDiffernet
        ? true
        : false

    if (!arrivalAirportValid || !airportsSelectedAreDiffernet || !airportsMatch.isValid) {
      setArrivalErrorText(arrivalTextError)
      setDepartureErrorText(departureTextError)
      setIsArrivalValid(false)
    }

    return isValid
  }

  useEffect(() => {
    const autoCompleteCallbackRefs = [arrivalAirportInput.current, departureAirportInput.current]
    /* istanbul ignore next */
    googleMapsScript(() =>
      handleAutocomplete_airports_only(setQuery, autoCompleteCallbackRefs, ukAirportsOnlyStr)
    ) //Loading script
    /* istanbul ignore next */
    if (typeof google === 'object' && typeof google.maps === 'object') {
      handleAutocomplete_airports_only(setQuery, autoCompleteCallbackRefs, ukAirportsOnlyStr)
    }
  }, [ukAirportsOnlyStr])

  const [query, setQuery] = useState({} as any)
  const [departureAirportData, setDepartureAirportData] = useState({
    geometry: {
      location: {
        lat: () => airports?.departureAirport?.geolocation?.lat,
        lng: () => airports?.departureAirport?.geolocation?.lng,
      },
    },
  })
  const [arrivalAirportData, setArrivalAirportData] = useState({
    geometry: {
      location: {
        lat: () => airports?.arrivalAirport?.geolocation?.lat,
        lng: () => airports?.arrivalAirport?.geolocation?.lng,
      },
    },
  })

  useEffect(() => {
    /* istanbul ignore next */
    if (query?.airportType === 'departure') {
      setIsDepartureValid(true)
      setDepartureAirportData(query)
    }
    /* istanbul ignore next */
    if (query?.airportType === 'arrival') {
      setIsArrivalValid(true)
      setArrivalAirportData(query)
    }
  }, [query, departureAirportData, arrivalAirportData])

  const handleDepartureChange = (e: any) => {
    setIsDepartureValid(true)
    setQuery(e.target.value)
  }

  const handleArrivalChange = (e: any) => {
    setIsArrivalValid(true)
    setQuery(e.target.value)
  }

  const getPlaceHolderText = () => {
    const departedUK = arriveDepartUK === 'flight-departed-uk'
    const placeHolderObj = {
      departure: departedUK ? 'e.g. London Heathrow Airport (LHR)' : 'e.g. Amsterdam (AMS)',
      arrival: departedUK ? 'e.g. Amsterdam (AMS)' : 'e.g. London Heathrow Airport (LHR)',
    }

    return placeHolderObj
  }

  const { departure, arrival } = getPlaceHolderText()

  const { arrivalTitle, departureTitle, imgObj, title } = data

  return (
    <div>
      <ImageWithQuestion image={imgObj} title={title} />
      <div className={styles.whichAirports}>
        <div data-testid="which-airport-departure">
          <FormEntryItem
            displayText={data.departureTitle}
            fieldData={{
              renderError: !isDepartureValid,
              value: departureAirportInput?.current?.value || '',
              errorMessageText: departureErrorText,
            }}
            handleOnChange={handleDepartureChange}
            id="departure-airport"
            isFocused={false}
            margin
            placeholderText={departure}
            ref={departureAirportInput}
            srLabel={departureTitle}
            stateValue={airports?.departureAirport?.name}
            type={'text'}
          />
        </div>

        <div className={styles.whichAirportsArrival} data-testid="which-airport-arrival">
          <FormEntryItem
            displayText={arrivalTitle}
            fieldData={{
              renderError: !isArrivalValid,
              value: arrivalAirportInput?.current?.value || '',
              errorMessageText: arrivalErrorText,
            }}
            handleOnChange={handleArrivalChange}
            id="arrival-airport"
            isFocused={false}
            margin
            placeholderText={arrival}
            ref={arrivalAirportInput}
            srLabel={arrivalTitle}
            stateValue={airports?.arrivalAirport?.name}
            type={'text'}
          />
        </div>
      </div>
      <div className={styles.nextButton}>
        <NextButton buttonText={'Next'} onClick={handleSubmit} />
      </div>
    </div>
  )
}
