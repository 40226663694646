import React from 'react'
import { Checkbox, Link, Typography } from '@which/seatbelt'

import classNames from 'classnames'

import globalStyles from '../../../shared/styles/GlobalStyles.module.scss'
import type { optinProps } from '../../types/toolTypes'
import styles from './renderOptinList.module.scss'

export const renderOptinList = ({
  optins,
  showTerms,
  handleShowTerms,
  showEmailTerms,
  className,
}: {
  optins: optinProps[]
  showTerms: boolean
  handleShowTerms: (e: any) => any
  showEmailTerms: boolean
  className?: string
}) => (
  <div className={classNames(globalStyles.spacing40, className)}>
    {optins.map(
      ({ checked, explanation, label, name, showOptInSpecificTerms, onChangeCallback }) => (
        <div className={styles.optinList} data-testid="optin-list-item" key={name}>
          <Checkbox
            checked={checked}
            className={styles.optinListCheckbox}
            id={name}
            label=""
            labelChildren={<span dangerouslySetInnerHTML={{ __html: label }} />}
            name={name}
            onChangeCallback={onChangeCallback}
            value={name}
          />
          {explanation && (
            <Typography className={styles.optinListCheckboxText} textStyle="small-print-regular">
              <span dangerouslySetInnerHTML={{ __html: explanation }} />
            </Typography>
          )}
          {(showTerms || showOptInSpecificTerms) && (
            <div>
              <Link
                className={styles.optinListToggle}
                data-testid="show-more-link"
                href="#"
                onClick={handleShowTerms}
              >
                {showEmailTerms ? 'Show less' : 'Show more'}
              </Link>
              {showEmailTerms && (
                <Typography
                  className="sb-padding-top-2 sb-padding-bottom-2"
                  data-testid="privacy-terms"
                  textStyle="very-small-print"
                >
                  The{' '}
                  <Link href="https://www.which.co.uk/help/general-terms-aYhBS9Y7TTtE">
                    Which? group
                  </Link>{' '}
                  is the Consumers' Association (a charity) that owns Which? Ltd. It promotes and
                  protects consumer interests through information, policy work, campaigns and legal
                  actions. Which? Ltd provides most of the group's commercial services, including
                  legal, wills and switching services. Most of the content on{' '}
                  <Link href="https://www.which.co.uk">which.co.uk</Link> is provided by Which? Ltd,
                  with some content and services provided by Consumers' Association including
                  consumer rights pages, and information on its work for consumers.{' '}
                  <Link href="https://www.which.co.uk/about-which/who-we-are">
                    Read more about the Which? group
                  </Link>
                  .
                </Typography>
              )}
            </div>
          )}
        </div>
      )
    )}
  </div>
)
