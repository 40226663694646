export const data = {
  productButtons: [
    { label: 'It was damaged or faulty', id: 'faulty', isFocused: true },
    { label: 'Not of satisfactory quality or as described', id: 'badQuality' },
    { label: 'There is a safety issue', id: 'safety' },
    { label: 'I was sent the wrong product', id: 'wrongProduct' },
    { label: "I don't want it any more", id: 'changedMind' },
  ],
  digitalButtons: [
    { label: 'It’s not as described', id: 'notAsDescribed', isFocused: true },
    { label: 'It’s not of satisfactory quality', id: 'badQuality' },
    { label: 'It’s not fit for purpose', id: 'notFitForPurpose' },
    { label: 'Performance reduced over time', id: 'performanceReduced' },
    { label: 'It damaged a device I downloaded/installed it on', id: 'damagedDevice' },
    { label: "I don't want it any more", id: 'changedMind' },
  ],
  imgObj: {
    alt: 'Person wearing a blue coat getting offered multiple letters',
    src: 'https://media.product.which.co.uk/prod/images/original/gm-8911e4a0-e3ca-4b0c-b341-6e1caad39c38-whatswrong.jpg',
  },
  title: 'What is wrong with the item you bought?',
}
