import type { InfoBoxProps } from '../../../../../shared/types/toolTypes'

export const howMuchData = {
  infoBox: {
    heading: "'Current monthly energy cost'?",
    borderColour: 'blue',
  } as InfoBoxProps,
  infoBoxParagraphs: [
    {
      text: 'This is the combined total you are currently spending each month on your gas and electricity bills.',
    },
  ],
  imgObj: {
    alt: '',
    src: 'https://media.product.which.co.uk/prod/images/original/06c9193d24b3-energy380x200.jpg',
  },
  pageData: {
    question: 'What are your current monthly direct debit payments?',
    standfirst: [
      'Use our simple tool to predict your energy payments for the next 12 months if you stay on a variable tariff and pay by monthly direct debit.',
    ],
    title: 'Energy Cost Calculator',
  },
}
