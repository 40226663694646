export const contactDetailsData = {
  formItems: [
    {
      displayText: 'First name',
      id: 'firstName',
      type: 'text',
      isFocused: true,
    },
    {
      displayText: 'Last name',
      id: 'lastName',
      type: 'text',
      isFocused: false,
    },
    {
      displayText: 'Email address',
      id: 'email',
      type: 'email',
      isFocused: false,
    },
  ],
  addressItems: [
    {
      displayText: 'Address line 1',
      id: 'addressLine1',
      type: 'text',
      isFocused: false,
    },
    {
      displayText: 'Address line 2',
      id: 'addressLine2',
      type: 'text',
      isFocused: false,
      optional: true,
    },
    {
      displayText: 'Town/City',
      id: 'townCity',
      type: 'text',
      isFocused: false,
    },
    {
      displayText: 'County',
      id: 'county',
      type: 'text',
      isFocused: false,
      optional: true,
    },
    {
      displayText: 'Postcode',
      id: 'postcode',
      type: 'text',
      isFocused: false,
    },
  ],
  imgObj: {
    alt: 'A woman working on a laptop',
    src: 'https://media.product.which.co.uk/prod/images/original/gm-189cd772-7d88-48b1-95ba-fe270befdcdf-holiday-complaint-12.jpg',
  },
  title: 'Enter your contact details',
  buttonText: 'Send complaint email to me',
  singleConsentOptIn: {
    label:
      'Keep me updated about products and services from the Which? Group and the work it does to make life simpler, safer and fairer for UK consumers.',
    name: 'acceptedSingleConsentOptIn',
  },
}
