import type { FunctionComponent } from 'react'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import { ImpactSurveyPage } from '../../../../shared/pages/ImpactSurveyPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { RadiosSelected } from '../../../../shared/types/toolTypes'
import { pageSubmission } from '../../../../shared/utils/EngagingNetwork/pageSubmission'
import { surveyModuleSpecificText } from '../../../../shared/utils/surveyModuleSpecificText/surveyModuleSpecificText'
import { pageData } from '../../pageData'
import { SubmitSuccessfulFlow } from '../../pageFlowData'
import { getStepData } from '../../utils/getStepData'
import { followUpOptInData } from './data'

export const FollowUpOptIn: FunctionComponent = () => {
  const { dispatch, state } = useContext(ToolContext)
  const {
    answers: {
      regardingProblem,
      wasSuccessful,
      whatWasOutcome,
      letterSuccess,
      reasonUnsuccessful,
      whatNow,
      ifNoWhichTool,
      toolEasier,
      savedTime,
      howMuchTimeSaved,
      reducedStress,
      moreAboutExperience,
    },
    user: { followUp, toolID, userEmail },
  } = state

  const [errorCount, setErrorCount] = useState(0)
  const [radiosSelected, setRadiosSelected] = useState<RadiosSelected>(followUp)

  const { radioQuestionData } = followUpOptInData

  const handleDispatch = useCallback(
    (emailSent: boolean) => {
      dispatch({ type: 'UPDATE_EMAIL_SENT', data: emailSent })
      dispatch({ type: 'UPDATE_STEP', data: SubmitSuccessfulFlow.STEP })
      dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: SubmitSuccessfulFlow.PROGRESS_VALUE })
    },
    [dispatch]
  )

  useEffect(() => {
    if (errorCount > 1) {
      // If they've gotten two or more errors, go to NextSteps anyway
      handleDispatch(false)
    }
  }, [errorCount, handleDispatch])

  const { toolName } = getStepData(toolID)
  const toolSpecificText = surveyModuleSpecificText(toolID)

  const title = `Thank you for letting us know your thoughts on using our ${toolName} tool.`
  const standFirstText = `Sometimes we like to follow-up on ${toolSpecificText}s to help consumers. Would you be willing for a member of the Which? Consumer Rights team to contact you about your ${toolSpecificText} and experience of using the tool?`

  const buildPageDataState = (radioId: string | undefined) => {
    const userData = {
      followUp: radioId,
      toolID,
      userEmail,
    }

    const pageDataState = {
      answers: {
        regardingProblem: regardingProblem[0]?.id,
        wasSuccessful: wasSuccessful[0]?.id,
        whatWasOutcome: whatWasOutcome[0]?.id,
        outcomeAmount: whatWasOutcome[0]?.textboxAnswer,
        letterSuccess: letterSuccess[0]?.id,
        reasonUnsuccessful: reasonUnsuccessful[0]?.id,
        whatNow: whatNow[0]?.id,
        ifNoWhichTool: ifNoWhichTool[0]?.id,
        toolEasier: toolEasier[0]?.id,
        savedTime: savedTime[0]?.id,
        howMuchTimeSaved: howMuchTimeSaved[0]?.id,
        reducedStress: reducedStress[0]?.id,
        moreAboutExperience: moreAboutExperience[0]?.userInput,
      },
      user: { ...userData },
    }

    return pageDataState
  }

  const radioQuestion = [
    {
      ...radioQuestionData,
      title: title,
      radiosStandFirst: standFirstText,
    },
  ]

  const handleSubmit = async (validationPassed: boolean) => {
    const submissionFormId = 110304

    if (validationPassed) {
      dispatch({ type: 'UPDATE_FOLLOW_UP', data: radiosSelected })

      const pageDataState = buildPageDataState(radiosSelected[0].id)
      const structuredData = pageData(pageDataState)
      const ENResponse = await pageSubmission(structuredData, submissionFormId)

      if (ENResponse && (ENResponse.ok || ENResponse.status === 'SUCCESS')) {
        handleDispatch(true)
      } else {
        setErrorCount(errorCount + 1)
      }
    }
  }

  return (
    <ImpactSurveyPage
      errorCount={errorCount}
      handleSubmit={handleSubmit}
      nextButtonText="Next"
      radioQuestions={radioQuestion}
      radiosSelected={radiosSelected}
      setRadiosSelected={setRadiosSelected}
    />
  )
}
