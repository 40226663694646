export const homeTopicData = {
  buttons: [
    {
      label: 'Dispute with traders in relation to building works or home improvements',
      id: 'dispute-with-trader',
    },
    {
      label: 'An issue with your landlord',
      id: 'issue-with-landlord',
    },
    {
      label: 'An issue with your tenant (you are the landlord)',
      id: 'issue-with-tenant',
    },
    {
      label: 'A neighbour issue inc planning',
      id: 'neighbour-issue',
    },
    {
      label: 'Leasehold',
      id: 'leasehold',
    },
    {
      label: 'New build issues',
      id: 'new-build-issues',
    },
    {
      label: 'Landlords HMO obligations',
      id: 'landlords-hmo',
    },
    {
      label: 'Land registry and title deeds queries',
      id: 'land-registry',
    },
    {
      label: 'Utilities',
      id: 'utilities',
    },
    {
      label: 'Licensing',
      id: 'licensing',
    },
  ],
  title: 'Please select which of the following best matches your query',
  imgObj: {
    alt: 'two people holding documents',
    src: 'https://media.product.which.co.uk/prod/images/original/8f95d1a7f0dc-cp-step9.jpg',
  },
}
