import { constructDate } from '../../../../shared/components/DatePicker/formatDate/constructDate'
import type { UserPropsWithAddress } from '../../../../shared/types/toolTypes'
import { formatTextInput } from '../../../../shared/utils/formatInput/formatTextInput'
import type { holidayComplaintToolState } from '../../state/holidayComplaintToolState'

export const composeEmailBody = ({
  holiday,
  expenses,
  issue,
  user,
  autoEmailer,
}: {
  holiday: typeof holidayComplaintToolState.holiday
  expenses: typeof holidayComplaintToolState.expenses
  issue: typeof holidayComplaintToolState.issue
  user: UserPropsWithAddress
  autoEmailer?: boolean
}) => {
  const {
    provider,
    destination,
    dates: { departureDate, returnDate },
    bookingReference,
    type,
  } = holiday
  const { hasExpenses, value, description } = expenses
  const { companyContact, description: issueDescription } = issue
  const { firstName, lastName, address } = user

  let addressArr = [
    { text: `${address?.addressLine1}` },
    {
      text: `${address?.addressLine2 ? `${address.addressLine2}` : ''}`,
    },
    { text: `${address?.townCity}` },
    { text: `${address?.county ? `${address.county}` : ''}` },
    { text: `${address?.postcode}<br />\r\n` },
  ]

  const holidayIntro =
    type === 'package' ? 'I made a booking to stay with you from' : 'My holiday was from'

  const partialPayment =
    type !== 'package' ? 'I bought part of my holiday through your company.' : ''

  const bookingRef = bookingReference ? `My booking reference is ${bookingReference}.` : ''

  const expensesDetails =
    hasExpenses && hasExpenses !== 'no'
      ? `I am claiming out of pocket expenses to the value of £${value}. ${formatTextInput(
          description,
          true
        )}<br/>\r\n`
      : ''

  const companyContactDetails = companyContact
    ? `${formatTextInput(companyContact, true)}<br/>\r\n`
    : ''

  const regulationDetails =
    type === 'package'
      ? "Under The Package Travel and Linked Travel Arrangements Regulations 2018, I am due compensation as the holiday fell short of the description I was provided when I booked the holiday, and it wasn't up to reasonable standards.<br />\r\n"
      : "Under The Consumer Rights Act 2015, I am due compensation as the holiday fell short of the description I was provided when I booked the holiday, and it wasn't up to reasonable standards.<br />\r\n"

  let body = [
    { text: `Dear ${provider},<br />\r\n` },
    {
      text: `${holidayIntro} ${constructDate(new Date(departureDate))} to ${constructDate(
        new Date(returnDate)
      )} in ${formatTextInput(
        destination,
        true
      )} ${partialPayment} ${bookingRef} However, I have found my booking unsatisfactory. This was not rectified, even after I asked for a resolution while I was staying there.<br />\r\n`,
    },
    { text: `${formatTextInput(issueDescription, true)}<br />\r\n` },
    { text: `${companyContactDetails}` },
    { text: `${expensesDetails}` },
    { text: `${regulationDetails}` },
    { text: 'I would be grateful if you could respond within 14 days.<br />\r\n' },
    { text: 'Yours faithfully,<br />\r\n' },
    { text: `${firstName} ${lastName}` },
  ]

  addressArr = addressArr.filter(String)
  body = autoEmailer ? addressArr.concat(body) : body
  body = body.filter(String)

  return {
    label: 'Preview of complaint email',
    address: addressArr,
    body: body,
  }
}
