import { constructDate } from '../../../../shared/components/DatePicker/formatDate/constructDate'
import type { UserPropsWithAddress } from '../../../../shared/types/toolTypes'
import type { broadbandSpeedToolState } from '../../state/broadbandSpeedToolState'

export const composeEmailBody = ({
  issue,
  broadband,
  user,
}: {
  issue: typeof broadbandSpeedToolState.issue
  broadband: typeof broadbandSpeedToolState.broadband
  user: UserPropsWithAddress
}) => {
  const { whichProvider, whenPurchased, supportingEvidence } = issue
  const { packageName, guaranteedSpeed, achievedSpeed } = broadband

  const { firstName, lastName, address } = user

  const specialProviders = [
    'bt',
    'ee',
    'plusnet',
    'utility warehouse',
    'virgin media',
    'zen internet',
  ].includes(whichProvider.toLowerCase())

  const notify = `I am formally notifying you of this poor service and expect you to resolve it within ${
    specialProviders ? '30' : '56'
  } days.
  
  
  If you are unable to do so, I consider you in breach of contract and will be writing to request I leave my contract immediately and without penalty${
    !specialProviders ? ', and may refer you to the relevant ADR scheme' : ''
  }.`

  const addressArr = [
    { text: `${address?.addressLine1}` },
    {
      text: `${address?.addressLine2 ? `${address.addressLine2}` : ''}`,
    },
    { text: `${address?.townCity}` },
    { text: `${address?.county ? `${address.county}` : ''}` },
    { text: `${address?.postcode}<br />\r\n` },
  ]

  let body = [
    { text: `Dear ${whichProvider},<br />\r\n` },
    {
      text: `On ${constructDate(
        new Date(whenPurchased)
      )} I contracted with you for ${packageName}.<br />\r\n`,
    },
    {
      text: `I entered into the contract on the basis of being provided with a minimum guaranteed speed of ${guaranteedSpeed} Mbps. In practice I'm only achieving speeds of ${achievedSpeed} Mbps.<br />\r\n`,
    },
    { text: supportingEvidence ? `${supportingEvidence}<br />\r\n` : '' },
    { text: `${notify}<br />\r\n` },
    {
      text: !specialProviders
        ? 'Please reply to explain the steps you will be taking to resolve the issue and/or your complaints management policy.<br />\r\n'
        : '',
    },
    { text: 'Yours sincerely,<br />\r\n' },
    { text: `${firstName} ${lastName}` },
  ]

  body = body.filter(String)

  return {
    label: 'Preview of complaint email',
    address: addressArr,
    body,
  }
}
