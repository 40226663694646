export const limitedOptionsData = {
  title: 'If the goods supplied or purchased were faulty, we suggest you use our faulty goods tool',
  standFirst:
    'The Consumer Rights Act 2015 requires any products you buy must be of satisfactory quality, fit for purpose and as described.',
  bodyCopy: [
    "Satisfactory quality Goods shouldn't be faulty or damaged when you receive them. You should ask yourself what a reasonable person would consider satisfactory for the goods in question. For example, bargain-bucket products won't be held to as high standards as luxury goods.",
    'Fit for purpose The goods should be fit for the purpose they are supplied for, as well as any specific purpose you made known to the retailer before you agreed to buy the goods.',
    'As described The goods supplied must match any description given to you, or any models or samples shown to you at the time of purchase.',
  ],
}
