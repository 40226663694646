import type { FunctionComponent } from 'react'
import React, { useEffect, useRef } from 'react'
import { ButtonLink, CampaignsIcon, ChevronRightIcon, Typography } from '@which/seatbelt'

import classNames from 'classnames'

import { BackButton } from '../../components/BackButton'
import { ImageWithQuestion } from '../../components/ImageWithQuestion'
import globalStyles from '../../styles/GlobalStyles.module.scss'
import type { CalculationPageProps } from '../../types/toolTypes'
import styles from './PriceRiseCalculationPage.module.scss'

export const PriceRiseCalculationPage: FunctionComponent<CalculationPageProps> = ({
  imgObj,
  title,
  standfirst,
  terms,
  resultData,
  ctaBlockData,
  backButtonData: { allFlows, toolName, text },
}) => {
  const titleRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    titleRef?.current?.focus()
  }, [])

  return (
    <div className={styles.calculationPage} data-testid="calculation-page">
      <ImageWithQuestion
        className={styles.calculationPageImageWithQuestion}
        image={imgObj}
        title={title}
        titleRef={titleRef}
        titleTag="h2"
      />
      <Typography>{standfirst}</Typography>
      <div className={styles.calculationPageResultGrid}>
        {resultData.map((result) => (
          <div
            className={styles.calculationPageResult}
            data-testid={'result-box'}
            key={result.text}
          >
            <Typography>{result.text}</Typography>
            <span
              className={styles.calculationPageResultAmountWrapper}
              data-testid="typography-body"
            >
              <span className={styles.calculationPageResultAmountWrapperAmount}>
                £{result.amount}
              </span>
            </span>
          </div>
        ))}
      </div>
      <div className={classNames(globalStyles.buttonWrapper, styles.calculationPageBackButton)}>
        <BackButton allFlows={allFlows} secondary text={text} toolName={toolName} />
      </div>
      {ctaBlockData && (
        <div className={styles.calculationPageSwitchCtaBlock}>
          <Typography className={styles.calculationPageSwitchCtaBlockTitle} textStyle="title-600">
            {ctaBlockData.title}
          </Typography>
          <Typography
            className={styles.calculationPageSwitchCtaBlockStandfirst}
            textStyle="small-print-regular"
          >
            {ctaBlockData.standfirst}
          </Typography>
          <div className={styles.calculationPageSwitchCtaBlockCtaWrapper}>
            <ButtonLink href={ctaBlockData.cta1.url} target="_blank">
              <ChevronRightIcon />
              {ctaBlockData.cta1.label}
            </ButtonLink>
            <ButtonLink href={ctaBlockData.cta2.url} target="_blank">
              <ChevronRightIcon />
              {ctaBlockData.cta2.label}
            </ButtonLink>
          </div>
          <div className={styles.calculationPageSwitchCtaBlockCampaignPromo}>
            <CampaignsIcon />
            <Typography
              dangerouslySetInnerHTML={{ __html: ctaBlockData.campaignPromo }}
              textStyle="small-print-regular"
            />
          </div>
        </div>
      )}
      <Typography
        className={styles.calculationPageTerms}
        data-testid="terms"
        textStyle="very-small-print"
      >
        {terms}
      </Typography>
    </div>
  )
}
