import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { FormEntryPage } from '../../../../shared/pages/FormEntryPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryInputRef, SingleInputState } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { AchievedSpeedFlow, GuaranteedSpeedFlow } from '../../pageFlowData'
import { data } from './data'

export const GuaranteedSpeed: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    broadband: { guaranteedSpeed },
  } = state

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })
  const GuaranteedSpeedInput = useRef<FormEntryInputRef>(null)

  const getValue = () => {
    return cleanText(GuaranteedSpeedInput?.current?.formEntryInput?.value || '')
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${GuaranteedSpeedFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_GUARANTEED_SPEED', data: value })
    dispatch({ type: 'UPDATE_STEP', data: AchievedSpeedFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: AchievedSpeedFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    const value = getValue()
    const isValid: boolean = isNotEmpty(value)

    setInputData({
      renderError: !isValid,
      value: value,
    })

    if (isValid) {
      handleTracking(value)
      handleDispatch(value)
    }
  }

  const { renderError, value: errorValue } = inputData
  const { imgObj, placeholderText, standFirst, title } = data

  return (
    <FormEntryPage
      buttonText="Next"
      defaultValue={guaranteedSpeed}
      fieldData={{
        renderError: renderError,
        value: errorValue,
      }}
      handleSubmit={handleSubmit}
      id="number"
      imgObj={imgObj}
      isFocused
      optional={false}
      placeholderText={placeholderText}
      ref={GuaranteedSpeedInput}
      standFirst={standFirst}
      testId="guaranteed-speed"
      title={title}
      type="number"
    />
  )
}
