import type { CRToolEntryPointProps } from '../../types/toolTypes'

export const validEntryOptions = (options: CRToolEntryPointProps) => {
  if (!options) {
    return false
  }
  for (const option of options) {
    if (
      !option.hasOwnProperty('label') ||
      !option.hasOwnProperty('value') ||
      !option.hasOwnProperty('link')
    ) {
      return false
    }
  }
  return true
}
