import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { TextAreaPage } from '../../../../shared/pages/TextAreaPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryTextAreaRef } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { validateCharCount } from '../../../../shared/utils/validateCharCount'
import { ContactDetailsFlow, DeliveryDateExplainedFlow } from '../../pageFlowData'
import { deliveryDateExplainedData } from './data'

export const DeliveryDateExplained: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    order: { deliveryDateExplained },
  } = state

  // eslint-disable-next-line prettier/prettier
  const [DeliveryDateExplainedTextAreaValid, setDeliveryDateExplainedTextAreaValid] =
    useState<boolean>(true)

  const DeliveryDateExplainedTextAreaRef = useRef<FormEntryTextAreaRef>(null)

  const handleDeliveryDateExplainedChange = () => {
    const value = getCleanValue(DeliveryDateExplainedTextAreaRef.current?.formEntryTextarea?.value)

    const isValid: boolean = validateCharCount(value.length, 2500)
    const fieldNotEmpty: boolean = isNotEmpty(value)
    if (fieldNotEmpty && isValid) {
      setDeliveryDateExplainedTextAreaValid(true)
    }
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_DELIVERY_DATE_EXPLAINED', data: value })
    dispatch({ type: 'UPDATE_STEP', data: ContactDetailsFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: ContactDetailsFlow.PROGRESS_VALUE })
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${DeliveryDateExplainedFlow.VIEW_NAME} | PII`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDeliveryDateExplainedSubmit = () => {
    const value: string = getCleanValue(
      DeliveryDateExplainedTextAreaRef.current?.formEntryTextarea?.value
    )
    const isValid: boolean = validateCharCount(value.length, 2500)
    const isEmpty = !isNotEmpty(value)

    if (!isValid || isEmpty) {
      setDeliveryDateExplainedTextAreaValid(false)
      return
    }

    setDeliveryDateExplainedTextAreaValid(true)

    handleTracking()
    handleDispatch(value)
  }

  const { imgObj, title, placeholderText, descriptiveText, note } = deliveryDateExplainedData

  return (
    <div data-testid="delivery-date-explained">
      <TextAreaPage
        buttonText="Next"
        defaultValue={deliveryDateExplained}
        descriptiveText={descriptiveText}
        handleChange={handleDeliveryDateExplainedChange}
        handleSubmit={handleDeliveryDateExplainedSubmit}
        imgObj={imgObj}
        isFocused
        isInputValid={DeliveryDateExplainedTextAreaValid}
        maxChars={2500}
        noteTop={note}
        placeholderText={placeholderText}
        ref={DeliveryDateExplainedTextAreaRef}
        title={title}
      />
    </div>
  )
}
