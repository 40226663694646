export const parseJson = (json: string) => {
  try {
    if (!json || typeof json !== 'string') {
      return false
    }
    const obj = JSON.parse(json)
    return obj
  } catch (err) {}
  return false
}
