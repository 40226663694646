export const getLocalStorageItem = (localItem: string) => {
  if (typeof window === 'undefined') {
    return null
  }
  const sessionData = localStorage.getItem(localItem)
  if (sessionData) {
    return JSON.parse(`${localStorage.getItem(localItem)}`)
  }
  return null
}
