export const data = {
  buttons: [
    {
      label: 'Yes',
      id: 'yes',
    },
    {
      label: 'No',
      id: 'no',
    },
  ],
  imgObj: {
    alt: 'A women holding up a bank and a piggy bank',
    src: 'https://media.product.which.co.uk/prod/images/original/d03340221f1c-mis-sold-step-7.jpg',
  },
  title: 'Do you want to claim compensation for being mis-sold the account?',
}
