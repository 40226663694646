import type { InfoBoxProps } from '../../../../../../shared/types/toolTypes'

export const contractChoiceData = {
  buttons: [
    {
      label: 'An individual',
      id: 'individual',
    },
    {
      label: 'A limited company',
      id: 'limited-company',
    },
  ],
  title:
    'Please tell us whether you entered into a contract with an individual or a limited company.',
  standFirst:
    "If you aren't sure, please check any documents, paperwork or emails you have. On the next screen we'll ask for some more details",
  imgObj: {
    alt: 'two people holding documents',
    src: 'https://media.product.which.co.uk/prod/images/original/8f95d1a7f0dc-cp-step9.jpg',
  },
  infoBox: {
    heading: 'Information',
    borderColour: 'blue',
  } as InfoBoxProps,
  infoBoxParagraphs: [
    {
      text: 'If you are in dispute with more than one individual or company, please call us on <a class="sb-link-primary" href="tel:+02922670000"><span class="sb-link-animation-wrapper">029 2267 0000</span></a> to supply details of everyone you need advice against. We may not be able to give you full advice if you only enter only one name here and ask for advice against others when we call you, potentially resulting in your appointment having to be rescheduled. Opening times are 8.30am to 6pm on Monday to Friday and from 9am to 1pm on Saturday',
    },
    {
      text: 'If you do not know the name of the individual or company you need advice against, please call us as we may still be able to assist you,',
    },
  ],
}

export const willsData = {
  ...contractChoiceData,
  title:
    'Please tell us whether you need advice against an individual, or a professional firm, such as a firm of solicitors',
}

export const buyingEmploymentData = {
  ...contractChoiceData,
  title: 'Please tell us whether you need advice regarding an individual or a company',
}
