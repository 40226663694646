import type { FunctionComponent } from 'react'
import React from 'react'
import { ButtonLink, ChevronRightIcon, Grid, GridItem, Typography } from '@which/seatbelt'

import type { MoneyPodcastProps } from '../../types/toolTypes'
import { IframeEmbed } from '../IframeEmbed'
import styles from './Podcast.module.scss'

export const Podcast: FunctionComponent<MoneyPodcastProps> = ({
  title,
  body,
  button,
  iframeContent,
}) => {
  return (
    <Grid className={styles.moneyPodcastWrapper} data-testid="money-podcast">
      <GridItem columnStart={{ medium: 2, large: 2 }} span={{ medium: 10, large: 5 }}>
        <div className={styles.moneyPodcastPlayer}>
          <IframeEmbed content={iframeContent} />
        </div>
      </GridItem>
      <GridItem
        className={styles.moneyPodcastContent}
        columnStart={{ medium: 2, large: 8 }}
        span={{ medium: 10, large: 4 }}
      >
        <Typography className={styles.moneyPodcastTitle} tag="h1" textStyle="title-500">
          {title}
        </Typography>
        <Typography textStyle="body">{body}</Typography>
        <ButtonLink appearance="secondary" className={styles.moneyPodcastCta} href={button.href}>
          <ChevronRightIcon />
          {button.text}
        </ButtonLink>
      </GridItem>
    </Grid>
  )
}
