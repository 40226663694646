import React from 'react'

import { ToolFeedbackAudiencePage } from '../../../shared/pages/ToolFeedbackAudiencePage'
import { toolFeedbackAudienceData } from '../../../shared/pages/ToolFeedbackAudiencePage/data'
import { ToolFeedbackPage } from '../../../shared/pages/ToolFeedbackPage'
import { toolFeedbackData } from '../../../shared/pages/ToolFeedbackPage/data/homeImprovementData'
import { ToolFeedbackThankYouPage } from '../../../shared/pages/ToolFeedbackThankYouPage'
import { CompanyName } from '../PageComponents/CompanyName'
import { ComplaintType } from '../PageComponents/ComplaintType'
import { ContactDetails } from '../PageComponents/ContactDetails'
import { ContractNumber } from '../PageComponents/ContractNumber'
import { JobType } from '../PageComponents/JobType'
import { LimitedOptions } from '../PageComponents/LimitedOptions'
import { SubmitSuccessful } from '../PageComponents/SubmitSuccessful'
import { UsedCard } from '../PageComponents/UsedCard'
import { WhatProblem } from '../PageComponents/WhatProblem'
import { WhatWork } from '../PageComponents/WhatWork'
import { WhichTrader } from '../PageComponents/WhichTrader'
import { WorkBegan } from '../PageComponents/WorkBegan'
import { WorkTrader } from '../PageComponents/WorkTrader'
import {
  CompanyNameFlow,
  ComplaintTypeFlow,
  ContactDetailsFlow,
  ContractNumberFlow,
  JobTypeFlow,
  LimitedOptionsFlow,
  SubmitSuccessfulFlow,
  ToolFeedbackAudienceFlow,
  ToolFeedbackFlow,
  ToolFeedbackThankYouFlow,
  UsedCardFlow,
  WhatProblemFlow,
  WhatWorkFlow,
  WhichTraderFlow,
  WorkBeganFlow,
  WorkTraderFlow,
} from '../pageFlowData'

export const useRenderStep = (step: number) => {
  switch (step) {
    case ComplaintTypeFlow.STEP:
      return <ComplaintType />

    case WorkTraderFlow.STEP:
      return <WorkTrader />

    case WhichTraderFlow.STEP:
      return <WhichTrader />

    case JobTypeFlow.STEP:
      return <JobType />

    case WhatWorkFlow.STEP:
      return <WhatWork />

    case WorkBeganFlow.STEP:
      return <WorkBegan />

    case CompanyNameFlow.STEP:
      return <CompanyName />

    case ContractNumberFlow.STEP:
      return <ContractNumber />

    case WhatProblemFlow.STEP:
      return <WhatProblem />

    case UsedCardFlow.STEP:
      return <UsedCard />

    case LimitedOptionsFlow.STEP:
      return <LimitedOptions />

    case ContactDetailsFlow.STEP:
      return <ContactDetails />

    case SubmitSuccessfulFlow.STEP:
      return <SubmitSuccessful />

    case ToolFeedbackFlow.STEP:
      return (
        <ToolFeedbackPage
          pageData={toolFeedbackData}
          toolFeedbackAudienceFlowData={ToolFeedbackAudienceFlow}
          toolFeedbackFlowData={ToolFeedbackFlow}
          toolName="HomeImprovement"
        />
      )
    case ToolFeedbackThankYouFlow.STEP:
      return <ToolFeedbackThankYouPage toolFeedbackThankYouFlowData={ToolFeedbackThankYouFlow} />
    case ToolFeedbackAudienceFlow.STEP:
      return (
        <ToolFeedbackAudiencePage
          pageData={toolFeedbackAudienceData}
          toolFeedbackAudienceFlowData={ToolFeedbackAudienceFlow}
          toolFeedbackThankYouFlowData={ToolFeedbackThankYouFlow}
          toolName="HomeImprovement"
        />
      )
    default:
      return <div>HomeImprovement Tool</div>
  }
}
