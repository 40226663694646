export const carProblemToolState = {
  car: {
    vehicleDetails: {
      vehicleReg: '',
      vehicleMake: '',
      vehicleModel: '',
      vehicleYear: '',
    }, // What's your car registration number?
    whereBought: null, // Where did you buy your car from?
    whenPurchased: '', // When did you buy your car?
    onFinance: null, // Did you buy it on finance?
    whichDealer: null, // Which dealer did you buy your car from?
    howMuch: null, // How much did you pay for it?
    whatsWrong: null, // What's wrong with your car?
    likeToDo: null, // What would you like to do?
    dealerOfferedSolution: null, // Has the dealer offered to repair or replace the car?
  },
}
