export const buyingTopicData = {
  buttons: [
    {
      label: 'Pre-contract questions',
      id: 'pre-contract-questions',
      tooltip:
        'For help before you enter a contract - what the clauses mean and how to avoid pitfalls and problems down the line',
    },
    {
      label: 'Store purchases, online shopping & delivery issues',
      id: 'store-purchases',
    },
    {
      label: 'Dispute with traders in relation to building works or home improvements',
      id: 'dispute-with-trader',
    },
    {
      label: 'Issues with events & leisure activities',
      id: 'issues-with-events',
    },
    {
      label: 'Subscriptions, payment & billing issues',
      id: 'subscriptions',
    },
    {
      label: 'Vehicle purchases, servicing or hire',
      id: 'vehicle',
    },
    {
      label: 'Small claims court procedure & enforcement',
      id: 'small-claims',
      tooltip:
        'Assistance with court procedure and process, court hearings and dealing with matters after the Judge has made their decision',
    },
    {
      label: 'Utilities',
      id: 'utilities',
    },
    {
      label: 'New build issues',
      id: 'new-build-issues',
    },
    {
      label: 'Professional negligence',
      id: 'professional-negligence',
      tooltip: 'Disputes with professionals such as solicitors, accountants and architects',
    },
    {
      label: 'Financial matters',
      id: 'financial-matters',
      tooltip:
        'Please note that we cannot give debt advice or tax advice as we are not regulated to do so',
    },
    {
      label: 'High Court proceedings e.g. judicial review',
      id: 'high-court',
      tooltip: 'When you are seeking a review of a decision of a Government department',
    },
    {
      label: 'A UK insurance policy',
      id: 'uk-insurance',
    },
    {
      label: 'Local council issues',
      id: 'local-council',
      tooltip: 'Issues such as council tax, refuse collection, permits, licensing etc',
    },
    {
      label: 'Discrimination issues (not related to your employment)',
      id: 'discrimination',
    },
    {
      label: 'Holidays only - pre-contract advice',
      id: 'pre-contract-advice',
    },
    {
      label: 'Holiday accommodation',
      id: 'holiday-accommodation',
    },
  ],
  title: 'Please select which of the following best matches your query',
  imgObj: {
    alt: 'Two people standing up talking',
    src: 'https://media.product.which.co.uk/prod/images/original/gm-e1fc82ae-b07d-49de-92bf-ba96507ba5f9-two-people-talking.jpg',
  },
}
