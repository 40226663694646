import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { TextAreaPage } from '../../../../shared/pages/TextAreaPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryTextAreaRef } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { validateCharCount } from '../../../../shared/utils/validateCharCount'
import { ContactDetailsFlow, ReasonGivenFlow } from '../../pageFlowData'
import { reasonGivenData } from './data'

export const ReasonGiven: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    claim: { reasonGiven },
    flight: { delayedCancelled },
  } = state

  const [ReasonGivenTextAreaValid, setReasonGivenTextAreaValid] = useState<boolean>(true)
  const ReasonGivenTextAreaRef = useRef<FormEntryTextAreaRef>(null)

  const handleReasonGivenChange = () => {
    const value = getCleanValue(ReasonGivenTextAreaRef.current?.formEntryTextarea?.value)
    dispatch({ type: 'UPDATE_REASON_GIVEN', data: value })

    const isValid: boolean = validateCharCount(value.length, 2500)
    const fieldNotEmpty: boolean = isNotEmpty(value)
    if (fieldNotEmpty && isValid) {
      setReasonGivenTextAreaValid(true)
    }
  }

  const handleDispatch = () => {
    dispatch({ type: 'UPDATE_STEP', data: ContactDetailsFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: ContactDetailsFlow.PROGRESS_VALUE })
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${ReasonGivenFlow.VIEW_NAME} | reason`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleReasonGivenSubmit = () => {
    const value: string = getCleanValue(ReasonGivenTextAreaRef.current?.formEntryTextarea?.value)
    const isValid: boolean = validateCharCount(value.length, 2500)
    const fieldNotEmpty: boolean = isNotEmpty(value)

    if (fieldNotEmpty && !isValid) {
      setReasonGivenTextAreaValid(false)
      return
    }

    setReasonGivenTextAreaValid(true)
    handleTracking()
    handleDispatch()
  }

  const { imgObj, cancelledTitle, delayedTitle, placeholderText, note } = reasonGivenData

  return (
    <div data-testid="reason-given">
      <TextAreaPage
        buttonText="Next"
        defaultValue={reasonGiven}
        handleChange={handleReasonGivenChange}
        handleSubmit={handleReasonGivenSubmit}
        imgObj={imgObj}
        isFocused
        isInputValid={ReasonGivenTextAreaValid}
        maxChars={2500}
        noteTop={note}
        optional
        placeholderText={placeholderText}
        ref={ReasonGivenTextAreaRef}
        title={delayedCancelled === 'flight-delayed' ? delayedTitle : cancelledTitle}
      />
    </div>
  )
}
