import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PreviewLetter } from '../../../../shared/components/PreviewLetter'
import { SubmitSuccessfulPage } from '../../../../shared/pages/SubmitSuccessfulPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { SubmitSuccessProps } from '../../../../shared/types/toolTypes'
import { ToolFeedbackFlow, ToolFeedbackThankYouFlow } from '../../pageFlowData'
import { composeEmailBody } from './composeEmailBody'
import { feedbackData } from './data'

export const SubmitSuccessful: FunctionComponent<SubmitSuccessProps> = () => {
  const toolContext = useContext(ToolContext)
  const {
    state: {
      autoEmailer,
      order,
      retailer,
      initial: { emailSent, user },
      toolFeedback: { showFeedback },
    },
    dispatch,
  } = toolContext
  const { name } = retailer

  const { body, imgObj, title } = feedbackData

  const data = composeEmailBody({ order, retailer, user, autoEmailer })

  const standFirstCopy = emailSent
    ? `A copy of your claim letter has been sent to ${user.emailAddress}`
    : 'Due to an error, we were unable to send your email. However, you can get a copy of your complaint letter below'

  const handleStepChoice = (id: string) => {
    if (id === 'feedback-yes') {
      return { step: ToolFeedbackFlow.STEP }
    }

    return { step: ToolFeedbackThankYouFlow.STEP }
  }

  const handleDispatch = (id: string) => {
    const { step } = handleStepChoice(id)

    dispatch({ type: 'UPDATE_STEP', data: step })
  }

  const handleSubmit = (id: string) => {
    handleDispatch(id)
  }

  return (
    <div data-testid="submit-successful">
      <SubmitSuccessfulPage
        feedbackBody={body}
        feedbackImgObj={imgObj}
        feedbackTitle={title}
        handleSubmit={handleSubmit}
        letterBody={data.body}
        letterTitle="Your complaint email"
        list={[
          'Check all the information in the letter below is correct.',
          `Copy and paste the letter below and send it to ${name}. We recommend doing this by email as it will be quicker and you'll have a clear record of any correspondence.`,
          `Don't be fobbed off! Under the Consumer Rights Act, it is the responsibility of ${name} to deliver your order. Don't let them tell you they can't help or put the blame on the courier.`,
        ]}
        listAppearance="bullet"
        showFeedback={showFeedback}
        standFirst={standFirstCopy}
        title="Your next steps"
      >
        <PreviewLetter data={data} />
      </SubmitSuccessfulPage>
    </div>
  )
}
