export const bookAppointmentToolState = {
  legal: {
    legalTopic: null,
  },
  cars: {
    carsTopic: null,
    carsQuery: null,
  },
  employment: {
    employmentTopic: null,
  },
  other: {
    otherTopic: null,
  },
  wills: {
    willsTopic: null,
    willsQuery: null,
    willsEstate: null,
    willsDetail: null,
  },
  buying: {
    buyingTopic: null,
    buyingQuery: null,
  },
  holiday: {
    holidayTopic: null,
    holidayQuery: null,
    holidayBooking: null,
  },
  home: {
    homeTopic: null,
    homeQuery: null,
    homeLocation: null,
    homeDetail: null,
  },
  money: { moneyTopic: null, moneyQuery: null },
  triage: { type: null, describeCase: null, contractChoice: null, solicitor: null, number: null },
  tech: {
    techTopic: null,
    techQuery: null,
    techDevice: null,
    techSystem: null,
  },
  medical: { medicalTopic: null },
  entitlement: {
    tech: null,
    money: null,
    legal: {
      buying: null,
      home: null,
      holiday: null,
      cars: null,
      employment: null,
      wills: null,
      dataProtection: null,
      medical: null,
    },
    check: true,
  },
}
