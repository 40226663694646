export const websiteData = {
  title: 'What type of website was it?',
  buttons: [
    { label: 'A shopping website', id: 'shopping', isFocused: true },
    { label: 'A website selling cryptocurrency', id: 'cryptocurrency' },
    { label: 'An investment/savings account website', id: 'investment' },
    {
      label: 'A website selling insurance e.g. car insurance',
      id: 'insurance',
    },
    {
      label: 'An online form asking for my details',
      id: 'online-form',
    },
    { label: 'Other', id: 'other' },
  ],
  imgObj: {
    alt: 'A women siting down at a desk typing looking at a computer',
    src: 'https://media.product.which.co.uk/prod/images/original/15335af4e73b-what-type-of-website-was-it.jpg',
  },
}

export const messageData = {
  title: 'What was the message about?',
  buttons: [
    {
      label: "My friend's account was hacked and the hacker was asking me to send them money",
      id: 'hacked-account-send-money',
      isFocused: true,
    },
    {
      label: "My friend's account was hacked and the hacker was asking me to send them vouchers",
      id: 'hacked-account-send-vouchers',
    },
    { label: 'Advertising a product or investment service', id: 'advertising' },
    {
      label: 'Offering me a vaccine',
      id: 'offering-vaccine',
    },
    { label: 'Other', id: 'other' },
  ],
  imgObj: {
    alt: 'Two people looking into a phone',
    src: 'https://media.product.which.co.uk/prod/images/original/fb1aabca87f4-what-was-the-message-about.jpg',
  },
}
