import type { PageFlowProps } from '../../../shared/types/toolTypes'

export const BeenOnHolidayFlow: PageFlowProps = {
  PROGRESS_VALUE: 0,
  STEP: 1,
  VIEW_NAME: 'holiday-complete',
  REDUCERS: ['UPDATE_HOLIDAY_COMPLETE'],
  MILESTONE: 'milestone | start',
}

export const HolidayTypeFlow: PageFlowProps = {
  PROGRESS_VALUE: 10,
  STEP: 2,
  VIEW_NAME: 'holiday-type',
  REDUCERS: ['UPDATE_HOLIDAY_TYPE'],
  MILESTONE: 'milestone | next steps | write letter',
}

export const HolidayProviderFlow: PageFlowProps = {
  PROGRESS_VALUE: 20,
  STEP: 3,
  VIEW_NAME: 'holiday-provider',
  REDUCERS: ['UPDATE_HOLIDAY_PROVIDER'],
  MILESTONE: 'milestone | write letter',
}

export const HolidayDestinationFlow: PageFlowProps = {
  PROGRESS_VALUE: 30,
  STEP: 4,
  VIEW_NAME: 'holiday-destination',
  REDUCERS: ['UPDATE_HOLIDAY_DESTINATION'],
  MILESTONE: '',
}

export const HolidayDatesFlow: PageFlowProps = {
  PROGRESS_VALUE: 40,
  STEP: 5,
  VIEW_NAME: 'holiday-dates',
  REDUCERS: [''],
  MILESTONE: '',
}

export const IssueDescriptionFlow: PageFlowProps = {
  PROGRESS_VALUE: 50,
  STEP: 6,
  VIEW_NAME: 'what-happened',
  REDUCERS: ['UPDATE_ISSUE_DESCRIPTION'],
  MILESTONE: '',
}

export const CompanyContactFlow: PageFlowProps = {
  PROGRESS_VALUE: 60,
  STEP: 7,
  VIEW_NAME: 'company-contact',
  REDUCERS: ['UPDATE_COMPANY_CONTACT'],
  MILESTONE: '',
}

export const HasExpensesFlow: PageFlowProps = {
  PROGRESS_VALUE: 70,
  STEP: 8,
  VIEW_NAME: 'has-expenses',
  REDUCERS: ['UPDATE_HAS_EXPENSES'],
  MILESTONE: '',
}

export const ExpenseValueFlow: PageFlowProps = {
  PROGRESS_VALUE: 80,
  STEP: 9,
  VIEW_NAME: 'expenses-value',
  REDUCERS: ['UPDATE_EXPENSES_VALUE'],
  MILESTONE: '',
}

export const ExpensesDetailsFlow: PageFlowProps = {
  PROGRESS_VALUE: 85,
  STEP: 10,
  VIEW_NAME: 'expenses-details',
  REDUCERS: ['UPDATE_EXPENSES_DETAILS'],
  MILESTONE: '',
}

export const BookingReferenceFlow: PageFlowProps = {
  PROGRESS_VALUE: 90,
  STEP: 11,
  VIEW_NAME: 'booking-reference',
  REDUCERS: ['UPDATE_BOOKING_REFERENCE'],
  MILESTONE: '',
}

export const ContactDetailsFlow: PageFlowProps = {
  PROGRESS_VALUE: 95,
  STEP: 12,
  VIEW_NAME: 'contact-details',
  REDUCERS: [''],
  MILESTONE: 'milestone | personal details',
}

export const SubmitSuccessfulFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 13,
  VIEW_NAME: 'journey-complete',
  REDUCERS: ['UPDATE_EMAIL_SENT'],
  MILESTONE: 'milestone | end | letter sent',
}

export const LimitedOptionsFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 14,
  VIEW_NAME: 'limited-options',
  REDUCERS: [''],
  MILESTONE: 'milestone | next steps | end',
}

export const ToolFeedbackFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 15,
  VIEW_NAME: 'feedback-form',
  REDUCERS: [''],
  MILESTONE: 'milestone | feedback start',
}

export const ToolFeedbackThankYouFlow: PageFlowProps = {
  PROGRESS_VALUE: 100,
  STEP: 17,
  VIEW_NAME: 'feedback-form',
  REDUCERS: [''],
  MILESTONE: '',
}

export const ToolFeedbackAudienceFlow: PageFlowProps = {
  PROGRESS_VALUE: 90,
  STEP: 16,
  VIEW_NAME: 'feedback-audience-form',
  REDUCERS: [''],
  MILESTONE: '',
}

export const AllFlows = [
  BeenOnHolidayFlow,
  HolidayTypeFlow,
  HolidayProviderFlow,
  HolidayDestinationFlow,
  HolidayDatesFlow,
  IssueDescriptionFlow,
  CompanyContactFlow,
  HasExpensesFlow,
  ExpenseValueFlow,
  ExpensesDetailsFlow,
  BookingReferenceFlow,
  ContactDetailsFlow,
  SubmitSuccessfulFlow,
  LimitedOptionsFlow,
  ToolFeedbackFlow,
]
