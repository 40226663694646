export const howDidYouPayData = {
  buttons: [
    {
      label: 'Debit card',
      id: 'debit',
    },
    {
      label: 'Bank transfer',
      id: 'bank-transfer',
    },
    {
      label: "I didn't pay, it was taken without me knowing",
      id: 'taken-without-knowing',
    },
    {
      label: 'Credit card',
      id: 'credit-card',
    },
    {
      label: 'Paypal',
      id: 'paypal',
    },
    {
      label: 'Cash',
      id: 'cash',
    },
    {
      label: 'Money transfer wire service',
      id: 'wire-service',
    },
    {
      label: 'Cryptocurrency',
      id: 'crypto',
    },
  ],
  title: 'How did you pay?',
  imgObj: {
    alt: 'A pair of hands holding a mobile phone',
    src: 'https://media.product.which.co.uk/prod/images/original/c2473cfc1fba-how-did-you-pay.jpg',
  },
}
