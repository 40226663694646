export const data = {
  buttons: [
    {
      label: 'I would like to be switched to a standard account',
      id: 'switch-account',
    },
    {
      label: 'I would like to close my account',
      id: 'close-account',
    },
    {
      label: 'I do not want to close or switch my account',
      id: 'nothing',
    },
  ],
  imgObj: {
    alt: 'A man and woman talking',
    src: 'https://media.product.which.co.uk/prod/images/original/d40928f957aa-mis-sold-account-step-6.jpg',
  },
  title: 'What would you like to do?',
}
