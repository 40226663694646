export const whatsWrongData = {
  title: "What's wrong with your car?",
  imgObj: {
    src: 'https://media.product.which.co.uk/prod/images/original/c6e49ab5e4ed-cp-step8.jpg',
    alt: 'A mechanic repairing a car',
  },
  note: ['Insert details on the issue into the sentence below.', 'The vehicle has...'],
  descriptiveText: [],
  placeholderText: 'Eg. developed a fault with the engine.',
}
