export const data = {
  buttons: [
    { label: 'I was signed up without being told.', id: 'withoutConsent', isFocused: true },
    { label: "I wasn't told I could have a free bank account instead.", id: 'notTold' },
    { label: 'I was told having one would improve my credit score.', id: 'improveCredit' },
    {
      label:
        'I was told I would get deals on other financial products or services by taking out this account.',
      id: 'getDeals',
    },
    {
      label:
        'I was told I had to get a packaged account in order to access other products such as a loan or mortgage.',
      id: 'hadToGet',
    },
    {
      label: 'I signed up to an account but was unable to claim on one or more of the benefits.',
      id: 'couldNotClaimBenefit',
    },
    { label: 'Something else.', id: 'somethingElse' },
  ],
  imgObj: {
    alt: 'A women siting down at a desk typing on a laptop.',
    src: 'https://media.product.which.co.uk/prod/images/original/49db00579496-mis-sold-step-2.jpg',
  },
  title: 'What led you to having a packaged bank account?',
}
