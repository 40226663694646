import type { InfoBoxProps } from '../../../../../shared/types/toolTypes'

const infoBox: InfoBoxProps = {
  heading: 'What is an EU-based airline?',
  borderColour: 'blue',
}
export const data = {
  buttons: [
    {
      label: 'Yes',
      id: 'yes',
    },
    {
      label: 'No',
      id: 'no',
    },
  ],
  infoBoxData: {
    infoBox: infoBox,
    infoBoxParagraphs: [
      {
        text: 'EU means the 27 EU countries, including Guadeloupe, French Guiana, Martinique, Réunion Island, Mayotte, Saint-Martin (French Antilles), the Azores, Madeira and the Canary Islands (but not the Faeroe Islands). EU rules also apply to flights to and from Iceland, Norway and Switzerland.',
      },
    ],
  },
  imgObj: {
    alt: 'Air hostess passing glass of wine to passenger',
    src: 'https://media.product.which.co.uk/prod/images/original/4556561dc840-flight-delay-cancellation-step-1.jpg',
  },
  title: 'Was your flight with a UK or EU-based airline?',
}
