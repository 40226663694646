export const data = {
  buttons: [
    {
      label: 'Ticket machine',
      id: 'ticket-machine',
    },
    {
      label: 'Mobile app',
      id: 'mobile-app',
    },
    {
      label: 'Telephone',
      id: 'telephone',
    },
  ],
  imgObj: {
    alt: 'A person holding a mobile phone',
    src: 'https://media.product.which.co.uk/prod/images/original/gm-a027f0bc-1ad9-4421-be08-5d47cfac673a-how-did-you-pay-for-parking.jpg',
  },
  title: 'How did you pay for the parking?',
}
