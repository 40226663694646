export const medicalTopicData = {
  buttons: [
    {
      label: 'An individual',
      id: 'individual',
    },
    {
      label: 'The NHS, a healthcare provider or company',
      id: 'other-company',
    },
  ],
  title:
    'Please tell us whether you are looking to claim against an individual, or a company, organisation or healthcare provider (including the NHS)',
  imgObj: {
    alt: 'Two people talking to a man at a desk',
    src: 'https://media.product.which.co.uk/prod/images/original/8731014b1438-mis-sold-bank-account-step-4.jpg',
  },
}
