import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { FormEntryPage } from '../../../../shared/pages/FormEntryPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryInputRef, SingleInputState } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { AgeFlow, WhoReportedToFlow } from '../../pageFlowData'
import { data } from './data'

export const WhoReportedTo: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    victim: { whoReportedTo },
  } = state

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })
  const WhoReportedToInput = useRef<FormEntryInputRef>(null)

  const getValue = () => {
    return cleanText(WhoReportedToInput?.current?.formEntryInput?.value || '')
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${WhoReportedToFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_WHO_REPORTED_TO', data: value })
    dispatch({ type: 'UPDATE_STEP', data: AgeFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: AgeFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    const value = getValue()
    setInputData({
      renderError: false,
      value: value,
    })

    handleTracking(value)
    handleDispatch(value)
  }

  const { imgObj, title } = data
  const { renderError, value: errorValue } = inputData

  return (
    <FormEntryPage
      buttonText="Next"
      defaultValue={whoReportedTo}
      fieldData={{
        renderError: renderError,
        value: errorValue,
      }}
      handleSubmit={handleSubmit}
      id="who-reported-to"
      imgObj={imgObj}
      isFocused
      optional={true}
      placeholderText=""
      ref={WhoReportedToInput}
      testId="who-reported-to"
      title={title}
    />
  )
}
