import type { FunctionComponent } from 'react'
import React, { useContext, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'

import { FormEntryItem } from '../../../../shared/components/FormEntryItem'
import { ImageWithQuestion } from '../../../../shared/components/ImageWithQuestion'
import { NextButton } from '../../../../shared/components/NextButton'
import { ToolContext } from '../../../../shared/state/appContext'
import type { SingleInputState } from '../../../../shared/types/toolTypes'
import { autocomplete } from '../../../../shared/utils/autoComplete/autoComplete'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { PackageNameFlow, WhichProviderFlow } from '../../pageFlowData'
import { whichProviderData } from './data'
import { broadbandProviders } from './data/broadbandProviders'
import styles from './WhichProvider.module.scss'

export const WhichProvider: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    issue: { whichProvider },
  } = state

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const autoCompleteRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    /* istanbul ignore next */
    if (autoCompleteRef && autoCompleteRef.current) {
      autocomplete({
        autoCompleteRef,
        searchItemsList: broadbandProviders,
        setIsMenuOpen,
        elementId: 'which-provider-input',
      })
    }
    return () => {
      /* istanbul ignore next */
      setIsMenuOpen(false) //Fix for potential memory leak
    }
  }, [autoCompleteRef])

  const getValue = () => {
    return cleanText(autoCompleteRef?.current?.value || '')
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${WhichProviderFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_STEP', data: PackageNameFlow.STEP })
    dispatch({ type: 'UPDATE_WHICH_PROVIDER', data: value })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: PackageNameFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    const value = getValue()
    const whichProviderHasError = !isNotEmpty(value)

    setInputData({
      renderError: whichProviderHasError,
      value: value,
    })

    if (!whichProviderHasError) {
      handleTracking(value)
      handleDispatch(value)
    }
  }

  const { title, imgObj } = whichProviderData

  return (
    <div
      className={classNames({ [styles.whichProviderMenuOpen]: isMenuOpen })}
      data-testid="which-provider"
    >
      <ImageWithQuestion image={imgObj} title={title} />
      <FormEntryItem
        displayText=""
        enterKeyHandler={handleSubmit}
        fieldData={{
          renderError: inputData.renderError,
          value: inputData.value,
        }}
        id="which-provider"
        isFocused={true}
        placeholderText=""
        ref={autoCompleteRef}
        srLabel={title}
        stateValue={whichProvider}
        type="text"
      />

      <NextButton buttonText="Next" onClick={handleSubmit} />
    </div>
  )
}
