import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { FormEntryPage } from '../../../../shared/pages/FormEntryPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { SingleInputState } from '../../../../shared/types'
import type { FormEntryInputRef } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { BookingReferenceFlow, ContactDetailsFlow } from '../../pageFlowData'
import { data } from './data'

export const BookingReference: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    holiday: { bookingReference },
    initial: { entryToolName },
  } = state

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })
  const bookingReferenceInput = useRef<FormEntryInputRef>(null)

  const getValue = () => {
    return cleanText(bookingReferenceInput?.current?.formEntryInput?.value || '')
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${BookingReferenceFlow.VIEW_NAME} | PII`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_BOOKING_REFERENCE', data: value })
    dispatch({ type: 'UPDATE_STEP', data: ContactDetailsFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: ContactDetailsFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    const value = getValue()

    setInputData({
      renderError: false,
      value: value,
    })

    handleTracking()
    handleDispatch(value)
  }

  const { renderError, value: errorValue } = inputData
  const { imgObj, title } = data

  return (
    <FormEntryPage
      buttonText="Next"
      defaultValue={bookingReference}
      fieldData={{
        renderError: renderError,
        value: errorValue,
      }}
      handleSubmit={handleSubmit}
      id="booking-reference"
      imgObj={imgObj}
      isFocused
      optional
      placeholderText=""
      ref={bookingReferenceInput}
      testId="booking-reference"
      title={title}
    />
  )
}
