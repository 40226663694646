import type { Reducer } from 'react'

import { initialSliceReducer } from '../../../shared/state/reducers'
import type { Action, State } from '../../../shared/state/toolStateTypes'
import { combineReducers } from '../../../shared/utils/combineReducers'

export const priceRiseSliceReducer: Reducer<State, Action> = (state, payload) => {
  const { type, data } = payload

  if (type === 'UPDATE_HOW_MUCH') {
    return {
      ...state,
      howMuch: data,
    }
  }

  if (type === 'UPDATE_CPI') {
    return {
      ...state,
      cpiAmount: data,
    }
  }

  if (type === 'UPDATE_PROVIDERS') {
    return {
      ...state,
      providersList: data,
    }
  }

  if (type === 'UPDATE_PROVIDER_SELECTED') {
    return {
      ...state,
      providerSelected: data,
    }
  }

  return state
}

export const priceRiseCalculatorReducer = combineReducers({
  initial: initialSliceReducer,
  priceRise: priceRiseSliceReducer,
})
