import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { Dropdown, Label, Typography } from '@which/seatbelt'

import classNames from 'classnames'

import { CurrencyInput } from '../../components/CurrencyInput'
import { ErrorMessage } from '../../components/ErrorMessage'
import { ImageWithQuestion } from '../../components/ImageWithQuestion'
import { NextButton } from '../../components/NextButton'
import globalStyles from '../../styles/GlobalStyles.module.scss'
import type { FormEntryInputRef, PriceRiseProviderPageProps } from '../../types/toolTypes'
import styles from './PriceRiseProviderPage.module.scss'

export const PriceRiseProviderPage = forwardRef<FormEntryInputRef, PriceRiseProviderPageProps>(
  (
    {
      buttonText,
      currencySymbol,
      defaultValue,
      defaultDropdownValue,
      handleProviderChange,
      handleFocus,
      handleSubmit,
      howMuchId,
      providerId,
      imgObj,
      isFocused,
      isProviderValid,
      isHowMuchValid,
      pageData,
      providerOptions,
      message,
    }: PriceRiseProviderPageProps,
    ref
  ) => {
    const currencyInputRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(ref, () => ({
      formEntryInput: currencyInputRef.current,
    }))

    const { standfirst, providerQuestion, paymentQuestion, paymentNote, title } = pageData

    const renderStandfirst = () =>
      standfirst.map((paragraph) => <Typography key={paragraph}>{paragraph}</Typography>)

    return (
      <div className={styles.priceRiseProviderPage} data-testid="price-rise-provider-page">
        <ImageWithQuestion
          bottomMargin={globalStyles.spacing20}
          className={styles.priceRiseProviderPageImageWithQuestion}
          image={imgObj}
          pictureClass={styles.image}
          title={title}
          titleTag="h2"
        />
        {renderStandfirst()}
        <p
          className={classNames(
            styles.priceRiseProviderPageQuestion,
            styles.priceRiseProviderPageQuestionProvider
          )}
          data-testid="typography-body"
        >
          {providerQuestion}
        </p>
        <Label className={'sr-only'} htmlFor={`${providerId}-input`} labelText={providerQuestion} />
        <div className={styles.priceRiseProviderPageDropdownWrapper}>
          <Dropdown
            callback={handleProviderChange}
            className={classNames(styles.priceRiseProviderPageDropdown, {
              [styles.priceRiseProviderPageInputError]: !isProviderValid,
            })}
            data-testid={`${providerId}-input`}
            defaultValue={defaultDropdownValue}
            id={`${providerId}-input`}
            onFocus={handleFocus}
            options={providerOptions}
            {...(!defaultDropdownValue && {
              placeholderOption: {
                label: 'Select an option',
                value: '0',
              },
            })}
            variant="regular"
          />
          {!isProviderValid && (
            <ErrorMessage
              errorText="Please fill out this field to continue"
              styleClasses={styles.priceRiseProviderPageInputErrorMessage}
            />
          )}
        </div>
        {message ? (
          <div className={styles.priceRiseProviderPageMessageBox} data-testid="message-box">
            <Typography textStyle="very-small-print">{message}</Typography>
          </div>
        ) : (
          <>
            <p
              className={classNames(
                styles.priceRiseProviderPageQuestion,
                styles.priceRiseProviderPageQuestionHowMuch
              )}
              data-testid="typography-body"
            >
              {paymentQuestion}
            </p>
            <Typography
              className={styles.priceRiseProviderPageQuestionNote}
              data-testid="typography-body"
              textStyle="very-small-print"
            >
              {paymentNote}
            </Typography>
            <CurrencyInput
              bottomMargin={globalStyles.spacingBottom32}
              currencySymbol={currencySymbol}
              handleFocus={handleFocus}
              handleSubmit={handleSubmit}
              id={howMuchId}
              isFocused={isFocused}
              isSubmitValid={isHowMuchValid}
              ref={currencyInputRef}
              srLabel={paymentQuestion}
              stateValue={defaultValue}
            />
            <div className={globalStyles.buttonWrapper}>
              <NextButton buttonText={buttonText} onClick={handleSubmit} />
            </div>
          </>
        )}
      </div>
    )
  }
)
