import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import globalStyles from '../../../../shared/styles/GlobalStyles.module.scss'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { EarlierLaterOriginalFlightFlow, HoursDifferenceFlow } from '../../pageFlowData'
import { earlierLaterOriginalFlightData } from './data'

export const EarlierLaterOriginalFlight: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    claim: { earlierLaterOriginalFlight },
  } = state

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${EarlierLaterOriginalFlightFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_EARLIER_LATER_ORIGINAL_FLIGHT', data: value })
    dispatch({ type: 'UPDATE_STEP', data: HoursDifferenceFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: HoursDifferenceFlow.PROGRESS_VALUE })
  }

  const handleSubmit = (value: string) => {
    handleTracking(value)
    handleDispatch(value)
  }

  const {
    earlierLaterOriginalFlightButtons,
    image: { imgSrc, imgAlt },
    title,
  } = earlierLaterOriginalFlightData

  return (
    <div className={globalStyles.fullWidthButton} data-testid="earlier-later-original-flight">
      <PillButtonPage
        buttons={earlierLaterOriginalFlightButtons}
        defaultValue={earlierLaterOriginalFlight}
        fitContent
        handleSubmit={handleSubmit}
        imgObj={{
          src: imgSrc,
          alt: imgAlt,
        }}
        title={title}
      />
    </div>
  )
}
