export const data = {
  buttons: [
    { label: 'Online (from a company)', id: 'online' },
    { label: 'In store', id: 'inStore' },
    { label: 'Private seller', id: 'privateSeller' },
  ],
  imgObj: {
    alt: 'Person in blue on their phone standing next to a women in a blouse taking a bag from a cashier.',
    src: 'https://media.product.which.co.uk/prod/images/original/gm-33782936-1220-4801-8f10-b1f422f9a6b9-whichpurchasechannel.jpg',
  },
  title: 'Where did you buy the item?',
}
