import type { FunctionComponent } from 'react'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import { PageWithText } from '../../../../shared/components/PageWithText'
import { SelectBoxPage } from '../../../../shared/pages/SelectBoxPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { calculateDistance } from '../../../../shared/utils/googlePlacesApi/googlePlacesAPI'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { FlightNumberFlow, NumPeopleClaimingForFlow } from '../../pageFlowData'
import { data, getEntitlementText } from './data'
import styles from './NumPeopleClaimingFor.module.scss'

export const NumPeopleClaimingFor: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    claim: {
      numPeopleClaimingFor,
      hoursDifference,
      timeBeforeCancelled,
      earlierLaterOriginalFlight,
    },
    flight: { distance, delayedCancelled, airports },
  } = state

  const [isInputValid, setIsInputValid] = useState<boolean>(true)
  const [selectValue, setSelectValue] = useState<number>(numPeopleClaimingFor)
  const [flightDistance, setFlightDistance] = useState(distance)

  useEffect(() => {
    let isCancelled = false
    const fetchDistance = async () => {
      const calculatedDistance = await calculateDistance(
        airports?.departureAirport?.geolocation,
        airports?.arrivalAirport?.geolocation
      )
      return calculatedDistance
    }

    fetchDistance()
      .then((data) => {
        const formattedDistance = Math.round(data)
        /* istanbul ignore else */
        if (!isCancelled) {
          setFlightDistance(formattedDistance)
          dispatch({
            type: 'UPDATE_FLIGHT_DISTANCE',
            data: formattedDistance,
          })
        }
      })
      .catch(
        /* istanbul ignore next */ (e: any) => {
          if (!isCancelled) {
            console.error(e)
          }
        }
      )

    return () => {
      isCancelled = true
    }
  }, [airports, dispatch, distance])

  const handleDispatch = (value: number) => {
    dispatch({ type: 'UPDATE_NUM_PEOPLE_CLAIMING_FOR', data: value })
    dispatch({ type: 'UPDATE_AMOUNT_ENTITLED', data: amountEntitled.amount })
    dispatch({ type: 'UPDATE_STEP', data: FlightNumberFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: FlightNumberFlow.PROGRESS_VALUE })
  }

  const handleTracking = (value: number) => {
    const trackingAnswer = `question | ${NumPeopleClaimingForFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = () => {
    const value = selectValue
    if (!value) {
      setIsInputValid(false)
      return
    }
    setIsInputValid(true)
    handleDispatch(value)
    handleTracking(value)
  }

  const createdOptions: any = []
  createdOptions.push({ label: 'Please select an option', value: '' })
  for (let i = 1; i <= 50; i++) {
    createdOptions.push({ label: `${i}`, value: i })
  }
  const selectBoxData = [
    {
      id: 'select-1',
      options: createdOptions,
      onChangeCallback: (value) => setSelectValue(value),
    },
  ]

  const getClaimType = useCallback(() => {
    switch (true) {
      case timeBeforeCancelled === 'fewer-than-7-days' && earlierLaterOriginalFlight === 'earlier':
        return 'scenarioB'
      case timeBeforeCancelled === 'fewer-than-7-days' && earlierLaterOriginalFlight === 'later':
        return 'scenarioC'
      case timeBeforeCancelled === 'fewer-than-7-days' &&
        earlierLaterOriginalFlight === 'earlier-arrived-later':
        return 'scenarioD'
      case timeBeforeCancelled === 'between-7-and-14-days' &&
        earlierLaterOriginalFlight === 'earlier':
        return 'scenarioE'
      case timeBeforeCancelled === 'between-7-and-14-days' &&
        earlierLaterOriginalFlight === 'later':
        return 'scenarioF'
      case timeBeforeCancelled === 'between-7-and-14-days' &&
        earlierLaterOriginalFlight === 'earlier-arrived-later':
        return 'scenarioG'
      case delayedCancelled === 'flight-cancelled':
        return 'scenarioH'
      default:
        return 'scenarioA'
    }
  }, [delayedCancelled, earlierLaterOriginalFlight, timeBeforeCancelled])

  const [amountEntitled, setAmountEntitled] = useState(
    getEntitlementText(getClaimType(), hoursDifference, flightDistance)
  )

  useEffect(() => {
    setAmountEntitled(getEntitlementText(getClaimType(), hoursDifference, flightDistance))
  }, [getClaimType, hoursDifference, flightDistance])

  const { imgObj, mainTitle, title } = data

  return (
    <div data-testid="num-people-claiming-for">
      <PageWithText bodyCopy={[amountEntitled.text]} standFirst="" title={mainTitle}>
        <div className={styles.numPeopleClaimingForPage}>
          <SelectBoxPage
            buttonText="Next"
            defaultValue={{ value1: selectValue?.toString() || '' }}
            extendedText={{
              selectSubheading: 'Enter one for just yourself',
            }}
            handleSubmit={handleSubmit}
            imgObj={imgObj}
            isInputValid={isInputValid}
            multiple={false}
            selectBoxes={selectBoxData}
            title={title}
          />
        </div>
      </PageWithText>
    </div>
  )
}
