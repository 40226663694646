import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { TextAreaPage } from '../../../../shared/pages/TextAreaPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryTextAreaRef } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { validateCharCount } from '../../../../shared/utils/validateCharCount'
import { ContactDetailsFlow, SupportingEvidenceFlow } from '../../pageFlowData'
import { supportingEvidenceData } from './data'

export const SupportingEvidence: FunctionComponent = () => {
  const { imgObj, title, placeholderText, descriptiveText, note } = supportingEvidenceData
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    issue: { supportingEvidence },
  } = state

  const [SupportingEvidenceTextAreaValid, setSupportingEvidenceTextAreaValid] =
    useState<boolean>(true)

  const SupportingEvidenceTextAreaRef = useRef<FormEntryTextAreaRef>(null)

  const handleChange = () => {
    const value = getCleanValue(SupportingEvidenceTextAreaRef.current?.formEntryTextarea?.value)

    const isValid: boolean = validateCharCount(value.length, 2500)
    const fieldNotEmpty: boolean = isNotEmpty(value)
    if (fieldNotEmpty && isValid) {
      setSupportingEvidenceTextAreaValid(true)
    }
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_SUPPORTING_EVIDENCE', data: value })
    dispatch({ type: 'UPDATE_STEP', data: ContactDetailsFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: ContactDetailsFlow.PROGRESS_VALUE })
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${SupportingEvidenceFlow.VIEW_NAME} | PII`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = () => {
    const value: string = getCleanValue(
      SupportingEvidenceTextAreaRef.current?.formEntryTextarea?.value
    )
    const isValid: boolean = validateCharCount(value.length, 2500)
    const fieldNotEmpty: boolean = isNotEmpty(value)

    if (fieldNotEmpty && !isValid) {
      setSupportingEvidenceTextAreaValid(false)
      return
    }

    setSupportingEvidenceTextAreaValid(true)
    handleTracking()
    handleDispatch(value)
  }

  return (
    <div data-testid="Supporting-Evidence">
      <TextAreaPage
        buttonText="Next"
        defaultValue={supportingEvidence}
        descriptiveText={descriptiveText}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        isFocused
        isInputValid={SupportingEvidenceTextAreaValid}
        maxChars={2500}
        noteTop={note}
        optional={true}
        placeholderText={placeholderText}
        ref={SupportingEvidenceTextAreaRef}
        title={title}
      />
    </div>
  )
}
