import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../../shared/state/appContext'
import globalStyles from '../../../../../shared/styles/GlobalStyles.module.scss'
import { trackAppointmentBookingSelection } from '../../../../../shared/utils/tracking/tracking'
import {
  ContactDetailsFlow,
  DescribeCaseFlow,
  LimitedOptionsFlow,
  WillsDetailFlow,
  WillsEstateFlow,
  WillsQueryFlow,
} from '../../../pageFlowData'
import { option1, option2 } from './data'

export const WillsQuery: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    wills: { willsTopic },
    triage: { type },
  } = state

  const handleTracking = (id: string, index: number) => {
    trackAppointmentBookingSelection(type, WillsQueryFlow.VIEW_NAME, { id, index })
  }

  const chooseStep = (id: string) => {
    switch (id) {
      case 'powers-of-attorney':
      case 'estate-planning':
        return WillsEstateFlow
      case 'estate-administration-probate':
      case 'estate-administration-confirmation':
        return WillsDetailFlow
      case 'other':
        return LimitedOptionsFlow
      case 'negligence':
      case 'challenging-a-will':
        return ContactDetailsFlow
      default:
        return DescribeCaseFlow
    }
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_WILLS_QUERY', data: id })
    const step = chooseStep(id)
    dispatch({ type: 'UPDATE_STEP', data: step.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: step.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string, index: number) => {
    handleTracking(id, index)
    handleDispatch(id)
  }

  const chooseData = () => {
    if (willsTopic === 'scotland') {
      return option2
    }
    return option1
  }
  const { buttons, imgObj, title } = chooseData()

  return (
    <div className={globalStyles.fullWidthButton}>
      <PillButtonPage buttons={buttons} handleSubmit={handleSubmit} imgObj={imgObj} title={title} />
    </div>
  )
}
