import type { KeyboardEvent } from 'react'

export const handleKeyPress = (
  event: KeyboardEvent,
  callback: CallableFunction,
  stateValue?: [string] | undefined
) => {
  const keyPressed = event.key
  const target = event.target as HTMLInputElement
  if (keyPressed !== 'Enter') {
    return false
  }
  if (target.type === 'checkbox' && !stateValue?.length) {
    return false
  }
  callback()
  return true
}
