export const toolAvailableData = {
  radioQuestionData: {
    buttons: [
      {
        label: 'Yes',
        id: 'yes-if-no-tool-available',
        isFocused: false,
      },
      {
        label: 'No',
        id: 'no-if-no-tool-available',
        isFocused: false,
      },
      { label: "Don't know / not sure", id: 'not-sure-if-no-tool-available', isFocused: false },
    ],
    isSubmitValid: true,
    id: 'which-tool-available',
  },
}

export const claimEaseOfUse = {
  radioQuestionData: {
    buttons: [
      {
        label: 'Yes',
        id: 'yes-made-it-easier',
        isFocused: false,
      },
      {
        label: 'No',
        id: 'no-made-it-easier',
        isFocused: false,
      },
      { label: "Don't know / not sure", id: 'not-sure-made-it-easier', isFocused: false },
    ],
    isSubmitValid: true,
    id: 'claim-ease-of-use',
  },
}

export const timeSaved = {
  radioQuestionData: {
    buttons: [
      {
        label: 'Yes, a little',
        id: 'yes-a-little',
        isFocused: false,
      },
      {
        label: 'Yes, some',
        id: 'yes-some',
        isFocused: false,
      },
      { label: 'Yes, a lot', id: 'yes-a-lot', isFocused: false },
      {
        label: 'No',
        id: 'no-time-saved',
        isFocused: false,
      },
      { label: "Don't know / not sure", id: 'not-sure-time-saved', isFocused: false },
    ],
    isSubmitValid: true,
    id: 'time-saved',
  },
}
