import type { InfoBoxProps } from '../../../../../shared/types/toolTypes'
import styles from '../../../../../shared/utils/renderInfoBox/renderInfoBox.module.scss'

export const limitedOptionsData = {
  title: 'You should contact the company you booked with to cancel a holiday in advance.',
  standFirst: '',
  bodyCopy: [
    'Cancelling a holiday in advance and receiving a refund will often depend on the terms and conditions of the booking',
    "However there are a few protections for travellers wishing to cancel trips. For example, If the Foreign and Commonwealth Office (FCO) says it's unsafe to travel to your destination, you are entitled to cancel the trip and receive a full refund.",
  ],
  link: {
    href: 'https://www.which.co.uk/consumer-rights/advice/i-have-to-cancel-my-package-holiday-what-are-my-rights-adFJY7e7jyjH',
    linkText: 'your protections when cancelling holidays.',
    text: 'Read more information on',
  },
  infoBox: {
    heading: 'Can I get my money back?',
    borderColour: 'blue',
  } as InfoBoxProps,
  infoBoxParagraphs: [
    {
      classname: `${styles.renderInfoBoxParagraphs}`,
      text: "You're entitled to cancel without paying any fees, and get a full refund, if the UK Foreign Office has warned against travelling to your holiday destination.",
    },
    {
      classname: `${styles.renderInfoBoxParagraphs}`,
      text: 'If any part of your package holiday is changed significantly, you can also cancel without paying termination fees.',
    },
    {
      text: "If you cancel a holiday in advance due to personal reasons you'll usually have to pay a 'termination fee'. But you might be able to claim back the cost of the holiday from your travel insurance provider.",
    },
  ],
}
