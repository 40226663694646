import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { MoreAboutScammerFlow, ScammerContactFlow, WhatHappenedFlow } from '../../pageFlowData'
import { scammerContactData } from './data'

export const ScammerContact: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    scam: { scammerContact },
  } = state

  const handleStepChoice = (value: string) => {
    if (value === 'yes') {
      return { progress: MoreAboutScammerFlow.PROGRESS_VALUE, step: MoreAboutScammerFlow.STEP }
    }

    return { progress: WhatHappenedFlow.PROGRESS_VALUE, step: WhatHappenedFlow.STEP }
  }

  const handleDispatch = (value: string) => {
    const { progress, step } = handleStepChoice(value)

    dispatch({ type: 'UPDATE_SCAMMER_CONTACT', data: value })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${ScammerContactFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleSubmit = (id: string) => {
    handleDispatch(id)
    handleTracking(id)
  }

  const { buttons } = scammerContactData

  return (
    <div data-testid="scammer-contact">
      <PillButtonPage
        buttons={buttons}
        defaultValue={scammerContact}
        handleSubmit={handleSubmit}
        imgObj={{
          alt: 'A woman sitting down using her phone',
          src: 'https://media.product.which.co.uk/prod/images/original/906c264836cb-scam-sharer-step-2.jpg',
        }}
        title="Did the scammer contact you in more than one way? E.g Telephone call & Email"
      />
    </div>
  )
}
