import styles from '../SubmitSuccessful.module.scss'

export const submitSuccessfulData = {
  standFirst: 'Thank you for taking the time to fill out our {toolName} tool survey',
  bodyCopy: [
    'Your feedback will be used to make improvements to the tool and will inform our work on consumer rights.',
    'Have you ever wondered how the results of the surveys are being used?',
    'Which? uses the results of feedback to improve consumer rights tools. The data will also be used to inform our campaigning work, and will sometimes be published, in anonymised form, in the Which? portfolio of magazines and online.',
    'You can read the latest consumer news from Which? <a class="sb-link-primary" href="https://www.which.co.uk/news" target="_blank"><span class="sb-link-animation-wrapper">here.</span></a>',
    'Thank you for your time.',
    `<span class=${styles.submitSuccessfulBold}>Which? Consumer Research team</span>`,
  ],
}
