export const regardingProblemData = {
  standFirst:
    'This survey will only take a few minutes – and your answers will help us improve our tools for consumers with problems.',
  radioQuestionData: {
    buttons: [
      {
        label: 'Yes I used the Which? letter to make a claim',
        id: 'yes-used-letter',
        isFocused: false,
      },
      {
        label: 'Yes, but I did not use the Which? letter to make a claim',
        id: 'yes-no-letter',
        isFocused: false,
      },
      { label: 'No, not yet, but I intend to', id: 'no-intend-to', isFocused: false },
      { label: 'No, I have not claimed', id: 'no-claim', isFocused: false },
    ],
    isSubmitValid: true,
    id: 'regardingProblem',
  },
  complaintQuestionData: {
    buttons: [
      {
        label: 'Yes I used the Which? letter to make a complaint',
        id: 'yes-used-letter',
        isFocused: false,
      },
      {
        label: 'Yes, but I did not use the Which? letter',
        id: 'yes-no-letter',
        isFocused: false,
      },
      { label: 'No, not yet, but I intend to', id: 'no-intend-to', isFocused: false },
      { label: 'No, I have not complained', id: 'no-complaint', isFocused: false },
    ],
    isSubmitValid: true,
    id: 'regardingProblem',
  },
}
