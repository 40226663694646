import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PreviewLetter } from '../../../../shared/components/PreviewLetter'
import { SubmitSuccessfulPage } from '../../../../shared/pages/SubmitSuccessfulPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { SubmitSuccessProps } from '../../../../shared/types/toolTypes'
import { ToolFeedbackFlow, ToolFeedbackThankYouFlow } from '../../pageFlowData'
import { composeEmailBody } from './composeEmailBody'
import { feedbackData } from './data'

export const SubmitSuccessful: FunctionComponent<SubmitSuccessProps> = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { emailSent, user },
    issue,
    ticket,
    toolFeedback: { showFeedback },
  } = state

  const { body, imgObj, title } = feedbackData

  const data = composeEmailBody({ ticket, issue, user })

  const standFirstCopy = emailSent
    ? `A copy of your claim letter has been sent to ${user.emailAddress}`
    : 'Due to an error, we were unable to send your email. However, you can get a copy of your claim letter below'

  const handleStepChoice = (id: string) => {
    if (id === 'feedback-yes') {
      return { progress: ToolFeedbackFlow.PROGRESS_VALUE, step: ToolFeedbackFlow.STEP }
    }

    return {
      progress: ToolFeedbackThankYouFlow.PROGRESS_VALUE,
      step: ToolFeedbackThankYouFlow.STEP,
    }
  }

  const handleDispatch = (id: string) => {
    const { progress, step } = handleStepChoice(id)

    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleSubmit = (id: string) => {
    handleDispatch(id)
  }

  return (
    <div data-testid="submit-successful">
      <SubmitSuccessfulPage
        feedbackBody={body}
        feedbackImgObj={imgObj}
        feedbackTitle={title}
        handleSubmit={handleSubmit}
        letterBody={data.body}
        letterTitle="Your complaint email"
        list={[
          'You should now send this appeal letter to the council or local authority that issued it. The address you need to send it to can be found on the parking ticket you were issued.',
          'Include any evidence you have to support your appeal. This could be photographs, witness statements, a crime number etc.',
          'Make sure you follow the ticket issuers appeals process.',
          `If your appeal is unsuccessful then go to the Which? Consumer Rights website
          <a class="sb-link-primary" href="https://www.which.co.uk/consumer-rights/advice/how-to-appeal-a-parking-ticket-asBzj3e9xtIj">
            <span class="sb-link-animation-wrapper">guide to appealing a parking ticket</span>
          </a> for further advice`,
        ]}
        showFeedback={showFeedback}
        standFirst={standFirstCopy}
        title="Your next steps"
      >
        <PreviewLetter data={data} />
      </SubmitSuccessfulPage>
    </div>
  )
}
