export const getInTouchData = {
  buttons: [
    {
      label: 'Yes',
      id: 'Y',
    },
    {
      label: 'No',
      id: 'N',
    },
  ],
  title: 'Would you be happy for a member of the Which? team to get in touch?',
  standFirst:
    "Your experience could help inform our advice content, scams research or campaigning work. Selecting 'Yes' below will allow us to identify information provided via previous steps in the tool. Anything you tell us can be anonymised.",
  image: {
    alt: 'A person talking to someone via a video call',
    src: 'https://media.product.which.co.uk/prod/images/original/b6b3e304c407-team-to-get-in-touch.jpg',
  },
}
