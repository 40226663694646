import type { FunctionComponent } from 'react'
import React, { useContext, useState } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { RadioGroupPage } from '../../../../shared/pages/RadioGroupPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { BeenAffectedFlow, HaveContactFlow, ScammerReferenceFlow } from '../../pageFlowData'
import { haveContactPillButtonData, radioButtonData } from './data'

export const HaveContact: FunctionComponent = () => {
  const [isInputValid, setInputValid] = useState(true)
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    scam: { haveContact, comeAcrossScam },
  } = state
  const [radioSelected, setRadioSelected] = useState(haveContact)
  const isTelephoneScam = comeAcrossScam === 'telephone'

  const handleRadioChange = (id: string) => {
    setRadioSelected(id)
  }

  const handleStepChoice = (value: string) => {
    if (
      radioSelected === 'impersonated-number' ||
      radioSelected === 'unrecognised-number' ||
      value === 'yes'
    ) {
      return { progress: ScammerReferenceFlow.PROGRESS_VALUE, step: ScammerReferenceFlow.STEP }
    }

    return { progress: BeenAffectedFlow.PROGRESS_VALUE, step: BeenAffectedFlow.STEP }
  }

  const handleTracking = (value: string) => {
    if (value !== '') {
      const trackingAnswer = `question | ${HaveContactFlow.VIEW_NAME} | ${value}`
      generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
    } else {
      const trackingAnswer = `question | ${HaveContactFlow.VIEW_NAME} | ${radioSelected}`
      generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
    }
  }

  const handleDispatch = (value: string) => {
    const { progress, step } = handleStepChoice(value)
    dispatch({ type: 'UPDATE_HAVE_CONTACT', data: radioSelected })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleRadioSubmit = () => {
    if (radioSelected) {
      setInputValid(true)
      handleTracking('')
      handleDispatch('')
    }
    setInputValid(false)
  }

  const handlePillButtonSubmit = (value: string) => {
    handleTracking(value)
    handleDispatch(value)
  }

  const chooseTitle = () => {
    switch (comeAcrossScam) {
      case 'online':
        return haveContactPillButtonData.online
      case 'email':
        return haveContactPillButtonData.email
      case 'text-message':
        return haveContactPillButtonData.textMessage
      case 'whatsapp':
        return haveContactPillButtonData.whatsapp
      case 'social-media':
        return haveContactPillButtonData.social
      default:
        return haveContactPillButtonData.online
    }
  }

  if (isTelephoneScam) {
    return (
      <RadioGroupPage
        buttonText="Next"
        buttons={radioButtonData}
        handleChange={handleRadioChange}
        handleSubmit={handleRadioSubmit}
        imgObj={{
          alt: 'Smartphone in a persons hand',
          src: 'https://media.product.which.co.uk/prod/images/original/43b212655622-what-number-did-they-call-from-.jpg',
        }}
        isInputValid={isInputValid}
        stateValue={radioSelected}
        title="What number did they call from?"
      />
    )
  } else {
    const { imgObj, title } = chooseTitle()
    return (
      <PillButtonPage
        buttons={[
          {
            label: 'Yes',
            id: 'yes',
          },
          {
            label: 'No',
            id: 'no',
          },
        ]}
        defaultValue={haveContact}
        handleSubmit={handlePillButtonSubmit}
        imgObj={imgObj}
        title={title}
      />
    )
  }
}
