import type { ChangeEvent, FunctionComponent } from 'react'
import React from 'react'
import { Radio } from '@which/seatbelt'

import classNames from 'classnames'

import type { RadioGroupProps, TextboxProps } from '../../types/toolTypes'
import { CurrencyInput } from '../CurrencyInput'
import { ErrorMessage } from '../ErrorMessage'
import { FormEntryItem } from '../FormEntryItem'
import styles from './RadioGroup.module.scss'

export const RadioGroup: FunctionComponent<RadioGroupProps> = ({
  buttons,
  handleChange,
  isSubmitValid = true,
  stateValue = [{ title: '', id: '', textboxAnswer: '' }],
  title,
  id,
}) => {
  const decideTextbox = (buttonId: string, textbox: TextboxProps, textboxValue: string) => {
    if (textbox.currency) {
      return (
        <CurrencyInput
          currencySymbol="£"
          displayText={textbox.displayText}
          id="money"
          isFocused={false}
          isSubmitValid={isSubmitValid}
          key={`${buttonId}${textbox.name}`}
          optional={true}
          ref={textbox.reference}
          srLabel={textbox.displayText}
          stateValue={textboxValue}
        />
      )
    }

    return (
      <FormEntryItem
        displayText={textbox.displayText}
        fieldData={{
          renderError: false,
          value: '',
        }}
        id="text"
        key={`${buttonId}${textbox.name}`}
        name={textbox.name}
        optional
        ref={textbox.reference}
        stateValue={textboxValue}
        type="text"
      />
    )
  }
  return (
    <>
      <div>
        {!isSubmitValid && (
          <ErrorMessage
            errorText="Please select which option applies to continue"
            styleClasses={classNames(styles.radioGroupError)}
          />
        )}
      </div>
      <div>
        {buttons.map(({ label, id: buttonId, isFocused = false, textbox }) => {
          let isChecked = false
          let textboxValue = ''
          stateValue.forEach((state) => {
            const { title: stateTitle, id: stateId, textboxAnswer } = state
            if (stateTitle === title && stateId === buttonId) {
              isChecked = true
              textboxValue = textboxAnswer || ''
            }
          })
          return (
            <React.Fragment key={buttonId}>
              <Radio
                autoFocus={isFocused}
                checked={isChecked}
                className={styles.radioGroupRadio}
                data-testid="tool-radio-button"
                id={buttonId}
                key={buttonId}
                label={label}
                name={title}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const target = e.target as HTMLInputElement
                  handleChange && handleChange(target.id, target.name, id)
                }}
                value={label}
              />
              {textbox && isChecked && (
                <div className={styles.radioGroupFormItem}>
                  {decideTextbox(buttonId, textbox, textboxValue)}
                </div>
              )}
            </React.Fragment>
          )
        })}
      </div>
    </>
  )
}
