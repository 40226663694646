export const carsLegalData = {
  buttons: [
    {
      label: 'No',
      id: 'no',
      tooltip: 'Select this option if you have only received the notice of any penalty',
    },
    {
      label: 'Yes',
      id: 'yes',
      tooltip:
        'Select this option if you have received notification from the Court that legal proceedings have been issued against you',
    },
  ],
  title: 'Have legal/Court proceedings been issued?',
  imgObj: {
    alt: 'Person driving a car',
    src: 'https://media.product.which.co.uk/prod/images/original/ccee6d01d803-carproblemstep1.jpg',
  },
}
