import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { LimitedOptionsFlow, WhenPurchasedFlow, WhereDidYouBuyFlow } from '../../pageFlowData'
import { data } from './data'

export const WhereDidYouBuy: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    retailer: { channel },
  } = state

  const handleStepChoice = (id: string) => {
    if (id === 'privateSeller') {
      return { step: LimitedOptionsFlow.STEP, progress: LimitedOptionsFlow.PROGRESS_VALUE }
    }
    return { step: WhenPurchasedFlow.STEP, progress: WhenPurchasedFlow.PROGRESS_VALUE }
  }

  const handleTracking = (id) => {
    const trackingAnswer = `question | ${WhereDidYouBuyFlow.VIEW_NAME} | ${id}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (id) => {
    const { step, progress } = handleStepChoice(id)
    dispatch({ type: 'UPDATE_RETAILER_CHANNEL', data: id })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleSubmit = (id: string) => {
    handleTracking(id)
    handleDispatch(id)
  }

  return (
    <div data-testid="where-did-you-buy">
      <PillButtonPage
        buttons={data.buttons}
        defaultValue={channel}
        handleSubmit={handleSubmit}
        imgObj={data.imgObj}
        title={data.title}
      />
    </div>
  )
}
