import type { UserPropsWithAddress } from './../../../shared/types/toolTypes'

export const pageData = (state: {
  emailData: { body: { text: string; className?: string }[] }
  holiday: {
    complete: string
    type: string
    provider: string
    destination: string
    dates: {
      departureDate: string
      returnDate: string
    }
    bookingReference: string
  }
  expenses: {
    hasExpenses: string
    value: string
    description: string
  }
  issue: {
    description: string
    companyContact: string
  }
  optIns: {
    singleConsentOptIn: boolean
  }
  user: UserPropsWithAddress
}) => {
  const { description, companyContact } = state.issue
  const { complete, type, provider, destination, dates, bookingReference } = state.holiday
  const { hasExpenses, value, description: expensesDescription } = state.expenses
  const { address, emailAddress, firstName, lastName } = state.user
  const { singleConsentOptIn } = state.optIns

  const { body } = state.emailData

  let emailBodyBuilder = ''
  body.forEach((bodyData) => {
    if (bodyData.text) {
      emailBodyBuilder = emailBodyBuilder + `${bodyData.text}<br />`
    }
  })

  const questions: any = {}
  // Questions for holiday and expense
  questions['question.1193909'] = complete // TF - Holiday - Have you already been on holiday?
  questions['question.1193910'] = type // TF - Holiday - Did you book a package holiday?
  questions['question.1193911'] = provider // TF - Holiday - Whats the name of the company?
  questions['question.1193912'] = destination // TF - Holiday - Destination
  questions['question.1193913'] = dates.departureDate // TF - Holiday - Departure date
  questions['question.1193914'] = dates.returnDate // TF - Holiday - Return date
  questions['question.1193915'] = description // TF - Holiday - What problems did you experience?
  questions['question.1193918'] = companyContact // TF - Holiday - Contact with provider
  questions['question.1193920'] = hasExpenses // TF - Holiday - Claiming out-of-pocket expenses
  questions['question.1193921'] = value // TF - Holiday - Expenses value
  questions['question.1193922'] = expensesDescription // TF - Holiday - Expenses description
  questions['question.1193923'] = bookingReference // TF - Holiday - Booking reference
  questions['question.1194262'] = emailBodyBuilder // TF - Composed Letter

  questions['question.1598463'] = singleConsentOptIn ? 'Y' : 'N' // TF - Single Consent opt-in
  questions['question.1184531'] = 'Y' // TF - Impact survey opt-in

  // return supporter
  return {
    supporter: {
      Address: address?.addressLine1,
      'Address 2': address?.addressLine2,
      County: address?.county,
      Email: emailAddress,
      'First name': firstName,
      'Last name': lastName,
      Postcode: address?.postcode,
      'Town or City': address?.townCity,
      questions,
    },
  }
}
