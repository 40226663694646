import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import classNames from 'classnames'

import { PillButtonPage } from '../../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../../shared/state/appContext'
import globalStyles from '../../../../../shared/styles/GlobalStyles.module.scss'
import { trackAppointmentBookingSelection } from '../../../../../shared/utils/tracking/tracking'
import {
  ContractChoiceFlow,
  DescribeCaseFlow,
  HolidayBookingFlow,
  LimitedOptionsFlow,
} from '../../../pageFlowData'
import { holidayBookingData } from './data'

export const HolidayBooking: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    holiday: { holidayBooking },
    triage: { type },
    cars: { carTopic },
  } = state

  const handleTracking = (id: string, index: number) => {
    trackAppointmentBookingSelection(type, HolidayBookingFlow.VIEW_NAME, { id, index })
  }

  const handleStep = (id: string) => {
    if (carTopic === 'vehicle') {
      return ContractChoiceFlow
    }
    switch (id) {
      case 'company-outside-uk-not-credit-card':
        return LimitedOptionsFlow
      default:
        return DescribeCaseFlow
    }
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_HOLIDAY_BOOKING', data: id })
    const step = handleStep(id)
    dispatch({ type: 'UPDATE_STEP', data: step.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: step.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string, index: number) => {
    handleTracking(id, index)
    handleDispatch(id)
  }

  const { buttons, imgObj, title, standFirst } = holidayBookingData

  return (
    <div className={classNames(globalStyles.fullWidthButton, globalStyles.fullHeightButton)}>
      <PillButtonPage
        buttons={buttons}
        defaultValue={holidayBooking ? holidayBooking : ''}
        fitContent
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        standFirst={standFirst}
        title={title}
      />
    </div>
  )
}
