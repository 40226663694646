export const outcomeData = {
  buttons: [
    {
      label: "I'm waiting for a decision",
      id: 'waiting',
    },
    {
      label: 'I have been told by my bank I will receive all of my money back',
      id: 'all-money-back',
    },
    {
      label: 'I have been told by my bank I will receive some of my money back',
      id: 'some-money-back',
    },
    {
      label: 'I have been told by my bank I will not receive my money back',
      id: 'no-money-back',
    },
  ],
  title: 'What was the outcome?',
  imgObj: {
    alt: 'Two people talking to someone over a counter',
    src: 'https://media.product.which.co.uk/prod/images/original/db02cab8926f-what-was-the-outcome.jpg',
  },
}
