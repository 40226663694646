import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { AttemptedRepairFlow, EntitlementFlow, OnFinanceFlow } from '../../pageFlowData'
import { onFinanceData } from './data'
import styles from './OnFinance.module.scss'

export const OnFinance: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    retailer,
  } = state

  const handleStepChoice = (id: string) => {
    if (!isOnFinance(id) && retailer.whenBought === '31DaysAnd6Months') {
      return { step: AttemptedRepairFlow.STEP, progress: AttemptedRepairFlow.PROGRESS_VALUE }
    }

    return { step: EntitlementFlow.STEP, progress: EntitlementFlow.PROGRESS_VALUE }
  }

  const handleTracking = (id: string) => {
    const trackingAnswer = `question | ${OnFinanceFlow.VIEW_NAME} | ${id}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const isOnFinance = (id: string) => {
    if (id === 'creditCard' || id === 'linkedCreditAgreement') {
      return true
    }
    return false
  }

  const handleDispatch = (id: string) => {
    const { progress, step } = handleStepChoice(id)
    dispatch({ type: 'UPDATE_PURCHASE_METHOD', data: id })
    dispatch({ type: 'UPDATE_PRODUCT_ONFINANCE', data: isOnFinance(id) })
    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleSubmit = (id: string) => {
    handleTracking(id)
    handleDispatch(id)
  }

  const { onFinanceButtons, imgObj, title } = onFinanceData

  return (
    <PillButtonPage
      buttons={onFinanceButtons}
      handleSubmit={handleSubmit}
      imgObj={imgObj}
      infoBoxData={onFinanceData}
      pillButtonGroupStyle={styles.onFinancePagePillButtons}
      title={title}
    />
  )
}
