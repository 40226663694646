import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../../shared/state/appContext'
import globalStyles from '../../../../../shared/styles/GlobalStyles.module.scss'
import { trackAppointmentBookingSelection } from '../../../../../shared/utils/tracking/tracking'
import {
  CarsLegalFlow,
  CarsQueryFlow,
  DescribeCaseFlow,
  LimitedOptionsFlow,
} from '../../../pageFlowData'
import { offencesData, ticketsData } from './data'

export const CarsQuery: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    cars: { carsQuery, carsTopic },
    triage: { type },
  } = state

  const handleTracking = (id: string, index: number) => {
    trackAppointmentBookingSelection(type, CarsQueryFlow.VIEW_NAME, { id, index })
  }

  const handleStep = (id: string) => {
    switch (id) {
      case 'bus-taxi-lane':
      case 'highway-code':
      case 'drink-drug':
      case 'no-licence':
      case 'train-station-fine':
      case 'other-traffic':
      case 'breach-street-parking':
      case 'toll-road':
      case 'tfl-penalty':
        return LimitedOptionsFlow
      case 'council-ticket':
      case 'clean-air':
        return CarsLegalFlow
      default:
        return DescribeCaseFlow
    }
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_CARS_QUERY', data: id })
    const step = handleStep(id)
    dispatch({ type: 'UPDATE_STEP', data: step.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: step.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string, index: number) => {
    handleTracking(id, index)
    handleDispatch(id)
  }

  const chooseDataSet = () => {
    switch (carsTopic) {
      case 'motoring':
        return offencesData
      case 'parking':
        return ticketsData
      default:
        return ticketsData
    }
  }

  const { buttons, imgObj, title } = chooseDataSet()

  return (
    <div className={globalStyles.fullWidthButton}>
      <PillButtonPage
        buttons={buttons}
        defaultValue={carsQuery ? carsQuery : ''}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        title={title}
      />
    </div>
  )
}
