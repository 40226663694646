import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { CurrencyPage } from '../../../../shared/pages/CurrencyPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryInputRef } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { LimitedOptionsFlow, PurchasedDateFlow, PurchasePriceFlow } from '../../pageFlowData'
import { data } from './data'

export const PurchasePrice: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    product: { purchaseMethod, price, type },
  } = state

  const purchasePriceInput = useRef<FormEntryInputRef>(null)
  const [purchasePriceInputValid, setPurchasePriceInputValid] = useState<boolean>(true)

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${PurchasePriceFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleStepChoice = (value: string) => {
    if (purchaseMethod === 'linkedCreditAgreement' && parseInt(value) > 30000) {
      return { progress: LimitedOptionsFlow.PROGRESS_VALUE, step: LimitedOptionsFlow.STEP }
    } else {
      return { progress: PurchasedDateFlow.PROGRESS_VALUE, step: PurchasedDateFlow.STEP }
    }
  }

  const handleDispatch = (value: string) => {
    const { progress, step } = handleStepChoice(value)

    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PRODUCT_PRICE', data: value })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleSubmit = () => {
    const value = getCleanValue(purchasePriceInput?.current?.formEntryInput?.value)
    const purchasePriceHasError = !isNotEmpty(value)

    if (!purchasePriceHasError) {
      handleTracking(value)
      handleDispatch(value)
      setPurchasePriceInputValid(true)
      return
    }
    setPurchasePriceInputValid(false)
  }

  const { imgObj, title } = data

  return (
    <CurrencyPage
      buttonText="Next"
      currencySymbol="£"
      defaultValue={price}
      handleSubmit={handleSubmit}
      id="purchase-price"
      imgObj={imgObj}
      isFocused
      isSubmitValid={purchasePriceInputValid}
      ref={purchasePriceInput}
      title={`${title} ${type === 'service' ? 'service' : 'product'}`}
    />
  )
}
