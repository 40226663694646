import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { PurchaseMethodFlow, PurchasePriceFlow } from '../../pageFlowData'
import { purchaseMethodData } from './data'
import styles from './PurchaseMethod.module.scss'

export const PurchaseMethod: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    product: { purchaseMethod },
  } = state

  // This should be moved to a data file and imported
  const title = 'How did you make your purchase?'
  const imgSrc =
    'https://media.product.which.co.uk/prod/images/original/gm-a76013d5-f08d-443c-adf1-b6a930ec1eba-purchase-method.jpg'
  const imgAlt = 'Elderly couple using a phone trying to buy an item'

  const handleTracking = (id: string) => {
    const trackingAnswer = `question | ${PurchaseMethodFlow.VIEW_NAME} | ${id}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_PURCHASE_METHOD', data: id })
    dispatch({ type: 'UPDATE_STEP', data: PurchasePriceFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: PurchasePriceFlow.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string) => {
    handleTracking(id)
    handleDispatch(id)
  }

  const { purchaseMethodButtons } = purchaseMethodData

  return (
    <>
      <PillButtonPage
        buttons={purchaseMethodButtons}
        defaultValue={purchaseMethod}
        handleSubmit={handleSubmit}
        imgObj={{
          src: imgSrc,
          alt: imgAlt,
        }}
        infoBoxData={purchaseMethodData}
        pillButtonGroupStyle={styles.purchaseMethodPagePillButtons}
        title={title}
      />
    </>
  )
}
