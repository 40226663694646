const shared = {
  imgObj: {
    alt: 'two people holding documents',
    src: 'https://media.product.which.co.uk/prod/images/original/8f95d1a7f0dc-cp-step9.jpg',
  },
}

export const option1 = {
  buttons: [
    {
      label: 'Assistance with tenants',
      id: 'assistance-with-tenants',
    },
    {
      label: 'Assistance with a lodger',
      id: 'assistance-with-lodger-landlord',
    },
    {
      label: 'Court procedure',
      id: 'court-procedure-landlord',
      tooltip:
        'For disputes at an advanced stage where you need advice on Court process relating to tenancies',
    },
    {
      label: 'Queries regarding other non-standard tenancies',
      id: 'queries-regarding-other',
      tooltip:
        'Where you have a tenant on a protected tenancy, Rent Act tenancy and other forms of tenancy',
    },
  ],
  title: 'Which of the following best describes your query',
  ...shared,
}

export const option2 = {
  buttons: [
    {
      label: 'General assistance with an Assured Shorthold Tenancy',
      id: 'general-assistance-with-private',
      tooltip:
        'If you do not have an Assured Shorthold Tenancy, please select "Queries related to non-standard tenancies" below',
    },
    {
      label: 'Assistance with a lodger',
      id: 'assistance-with-lodger',
    },
    {
      label: 'Assistance with a Housing Association Tenancy (England)',
      id: 'assistance-with-housing-association-uk',
    },
    {
      label: 'Assistance with a Housing Association Tenancy (Wales)',
      id: 'assistance-with-housing-association-wales',
    },
    {
      label: 'Assistance with a Housing Association Tenancy (Scotland or Northern Ireland)',
      id: 'assistance-with-housing-association-scotland',
    },
    {
      label: 'Court procedure related to a private tenancy',
      id: 'court-procedure-private',
      tooltip:
        'For disputes at an advanced stage where you need advice on Court process relating to tenancies',
    },
    {
      label: 'An issue with student accommodation',
      id: 'issue-with-student-accommodation',
    },
    {
      label: 'Council tenancies',
      id: 'council-tenancies',
    },
    {
      label: 'Queries related to non-standard tenancies',
      id: 'queries-regarding-other',
    },
  ],
  title: 'Which of the following best describes your query',
  ...shared,
}

export const option3 = {
  buttons: [
    {
      label: 'Adverse possession',
      id: 'adverse-possession',
      tooltip: "Where somebody acqures rights over somebody else's land through ongoing usage.",
    },
    {
      label: 'A boundary issue or dispute',
      id: 'boundary-issue',
      tooltip: 'A dispute over the position of the boundary, or maintenance of fences, hedges etc',
    },
    {
      label: 'CCTV',
      id: 'cctv',
      tooltip: 'Concerns over CCTV recordings and infringements of privacy',
    },
    {
      label: 'High hedge/trees',
      id: 'high-hedge',
      tooltip:
        'Disputes or concerns regarding who is responsible for keeping high hedges and trees maintained',
    },
    {
      label: 'Nuisance',
      id: 'nuisance',
      tooltip:
        'Interferences with the enjoyment of property, such as building works, noise or smells',
    },
    {
      label: 'Party walls',
      id: 'party-walls',
      tooltip:
        'Queries related to party walls with shared ownership - who is responsible for repair and fixing any damage',
    },
    {
      label: "A neighbour's planning issue",
      id: 'a-planning-issue',
      tooltip: 'Concerns regarding neighbouring planning activity which could affect your home',
    },
    {
      label: 'Damage to your property',
      id: 'damage-to-property',
      tooltip: 'Damage caused by neighbours, contractors or thrd parties such as delivery drivers',
    },
    {
      label: 'Right to light',
      id: 'right-to-light',
      tooltip:
        'Concern that neighbouring building works or other obstructions will impact the light in your property',
    },
    {
      label: 'Trespass',
      id: 'trespass',
      tooltip: 'Neighbours or other people using your land, including inappropriate parking',
    },
    {
      label: 'Rights of access/shared driveways and restrictive covenants',
      id: 'rights-of-access',
    },
    {
      label: 'Court or Tribunal proceedings relating to any of the above',
      id: 'court-tribunal',
    },
  ],
  title: 'Please select which of the following best matches your query',
  ...shared,
}

export const option4 = {
  buttons: [
    {
      label: 'Broadband & phone',
      id: 'broadband-phone',
    },
    {
      label: 'Gas & electric',
      id: 'gas-electric',
    },
    {
      label: 'TV Licensing',
      id: 'tv-licensing',
    },
    {
      label: 'Water Supply/Sewerage -England, Scotland or Wales',
      id: 'water-supply-uk',
    },
    {
      label: 'Water Supply/Sewerage - Northern Ireland',
      id: 'water-supply-ireland',
    },
    {
      label: 'Wayleave agreements',
      id: 'wayleave-agreement',
    },
    {
      label: 'Issues with National Grid & other distributors',
      id: 'issue-with-grid',
    },
  ],
  title: 'Please select which of the following best matches your query',
  ...shared,
}
