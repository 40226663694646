import { constructDate } from '../../../../shared/components/DatePicker/formatDate/constructDate'
import type { UserPropsWithAddress } from '../../../../shared/types/toolTypes'
import { formatTextInput } from '../../../../shared/utils/formatInput/formatTextInput'
import type { parkingTicketToolState } from '../../state/parkingTicketToolState'
import styles from './SubmitSuccessful.module.scss'

export const composeEmailBody = ({ ticket, issue, user }: emailBodyProps) => {
  const { firstName, lastName } = user

  const { companyName, vehicleReg, purchaseMethod, supportingEvidence, description } = issue
  const { ticketNumber, ticketDate, ticketLocation, ticketPurchased, ticketReason } = ticket

  let carParkLocation = [
    `${ticketLocation.addressLine1}`,
    `${ticketLocation.addressLine2 ? `${ticketLocation.addressLine2}` : ''}`,
    `${ticketLocation.townCity}`,
    `${ticketLocation.county ? `${ticketLocation.county}` : ''}`,
    `${ticketLocation.postcode}`,
  ]

  carParkLocation = carParkLocation.filter(String)

  const formattedPurchaseMethod = () => {
    switch (purchaseMethod) {
      case 'ticket-machine':
        return 'at a machine'
      case 'mobile-app':
        return 'on an app'
      case 'telephone':
        return 'by telephone'
    }
  }

  const paidForParking =
    ticketPurchased === 'yes'
      ? `I paid for parking and I bought the parking ticket ${formattedPurchaseMethod()}.<br/>\r\n`
      : ''

  const formattedDate = constructDate(new Date(ticketDate))

  const constructSupportingEvidence =
    supportingEvidence === 'yes' ? 'Please find enclosed evidence to this effect.<br/>\r\n' : ''

  let body = [
    { text: `Dear ${companyName},<br/>\r\n` },
    {
      text: 'I am writing to formally challenge the following parking charge notice (PCN).<br/>\r\n',
    },
    {
      text: `<strong>Ticket no: ${ticketNumber}</strong>\r\n`,
      className: `${styles.submitSuccessfulEmailEmphasis} ${styles.submitSuccessfulEmailEmphasisFirst}`,
    },
    {
      text: `<strong>Issued on: ${formattedDate}</strong>\r\n`,
      className: `${styles.submitSuccessfulEmailEmphasis}`,
    },
    {
      text: `<strong>Vehicle reg: ${vehicleReg}</strong>\r\n`,
      className: `${styles.submitSuccessfulEmailEmphasis}`,
    },
    {
      text: `<strong>Car park location: ${carParkLocation.join(' ')}</strong><br/>\r\n`,
      className: `${styles.submitSuccessfulEmailEmphasis}`,
    },
    { text: `${paidForParking}` },
    {
      text: `On ${formattedDate} my vehicle was issued with the parking ticket above for the following reason: ${ticketReason}<br/>\r\n`,
    },
    {
      text: `I am challenging this parking ticket because ${formatTextInput(
        description,
        false
      )}<br/>\r\n`,
    },
    { text: `${constructSupportingEvidence}` },
    {
      text: 'For this reason, I look forward to receiving notification within 28 days that the PCN has been cancelled.<br/>\r\n',
    },
    { text: 'Yours faithfully,<br/>\r\n' },
    { text: `${firstName} ${lastName}` },
  ]

  body = body.filter(String)
  return {
    label: 'Preview of complaint email',
    address: [],
    body: body,
  }
}

type emailBodyProps = {
  ticket: typeof parkingTicketToolState.ticket
  issue: typeof parkingTicketToolState.issue
  user: UserPropsWithAddress
}
