import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { TextAreaPage } from '../../../../shared/pages/TextAreaPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryTextAreaRef } from '../../../../shared/types/toolTypes'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { getCleanValue } from '../../../../shared/utils/getCleanValue'
import { isNotEmpty } from '../../../../shared/utils/isNotEmpty'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { validateCharCount } from '../../../../shared/utils/validateCharCount'
import { BeenAffectedFlow, WhatHappenedFlow, WhoWasScammerFlow } from '../../pageFlowData'
import { data } from './data'

export const WhatHappened: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    scam: { comeAcrossScam, whatHappened },
  } = state

  const [whatHappenedTextAreaValid, setWhatHappenedTextAreaValid] = useState<boolean>(true)
  const WhatHappenedTextAreaRef = useRef<FormEntryTextAreaRef>(null)

  const handleWhatHappenedChange = () => {
    const value = getCleanValue(WhatHappenedTextAreaRef.current?.formEntryTextarea?.value)
    dispatch({ type: 'UPDATE_WHAT_HAPPENED', data: value })

    const isValid: boolean = validateCharCount(value.length, 2500)
    const fieldNotEmpty: boolean = isNotEmpty(value)
    if (fieldNotEmpty && isValid) {
      setWhatHappenedTextAreaValid(true)
    }
  }

  const handleStepChoice = () => {
    if (comeAcrossScam === 'other') {
      return { progress: BeenAffectedFlow.PROGRESS_VALUE, step: BeenAffectedFlow.STEP }
    }

    return { progress: WhoWasScammerFlow.PROGRESS_VALUE, step: WhoWasScammerFlow.STEP }
  }

  const handleDispatch = () => {
    const { progress, step } = handleStepChoice()

    dispatch({ type: 'UPDATE_STEP', data: step })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: progress })
  }

  const handleTracking = () => {
    const trackingAnswer = `question | ${WhatHappenedFlow.VIEW_NAME} | ${whatHappened}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleWhatHappenedSubmit = () => {
    const value: string = getCleanValue(WhatHappenedTextAreaRef.current?.formEntryTextarea?.value)
    const isValid: boolean = validateCharCount(value.length, 2500)
    const fieldNotEmpty: boolean = isNotEmpty(value)

    if (fieldNotEmpty && !isValid) {
      setWhatHappenedTextAreaValid(false)
      return
    }

    setWhatHappenedTextAreaValid(true)
    handleTracking()
    handleDispatch()
  }

  const { imgObj, placeholderText, title } = data

  return (
    <div data-testid="what-happened">
      <TextAreaPage
        buttonText="Next"
        defaultValue={whatHappened}
        handleChange={handleWhatHappenedChange}
        handleSubmit={handleWhatHappenedSubmit}
        imgObj={imgObj}
        isFocused
        isInputValid={whatHappenedTextAreaValid}
        maxChars={2500}
        optional
        placeholderText={placeholderText}
        ref={WhatHappenedTextAreaRef}
        title={title}
      />
    </div>
  )
}
