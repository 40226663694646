export const data = {
  issue: {
    imgObj: {
      alt: 'Tell us more about the issue with your product.',
      src: 'https://media.product.which.co.uk/prod/images/original/gm-962e8542-847c-4025-a3fc-b394cae8ea5f-moreinfo.jpg',
    },
    title: 'Tell us more about the problem.<br>This will be used in your letter',
  },
  orderNumber: {
    imgObj: {
      alt: 'Salesman presenting new TV to a couple',
      src: 'https://media.product.which.co.uk/prod/images/original/gm-c562769b-a918-4a4f-927f-25e070d9d382-ordernumber.jpg',
    },
    title: 'What is your order number?',
  },
  purchasePrice: {
    imgObj: {
      alt: 'Card machine making payment',
      src: 'https://media.product.which.co.uk/prod/images/original/gm-c3e3f272-a113-4151-9e26-fc4e18d59232-purchaseprice.jpg',
    },
    title: 'How much did you pay for it?',
  },
  whichRetailer: {
    imgObj: {
      alt: 'Person looking in a shop window.',
      src: 'https://media.product.which.co.uk/prod/images/original/gm-f87650eb-dfa4-410b-bdc4-d910d100e7a2-whichretailer.jpg',
    },
    title: 'Which retailer did you buy the item from?',
    placeholderText: 'e.g. Currys, Amazon',
  },
}
