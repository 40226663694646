import React from 'react'

import { CRToolEntryPoint } from '../../../shared/pages/CRToolEntryPoint'
import { ToolFeedbackAudiencePage } from '../../../shared/pages/ToolFeedbackAudiencePage'
import { toolFeedbackAudienceData } from '../../../shared/pages/ToolFeedbackAudiencePage/data'
import { ToolFeedbackPage } from '../../../shared/pages/ToolFeedbackPage'
import { toolFeedbackData } from '../../../shared/pages/ToolFeedbackPage/data/s75Data'
import { ToolFeedbackThankYouPage } from '../../../shared/pages/ToolFeedbackThankYouPage'
import type { S75UseRenderProps } from '../../../shared/types/toolTypes'
import { ClaimValue } from '../PageComponents/ClaimValue'
import { ContactDetails } from '../PageComponents/ContactDetails'
import { DesiredResolution } from '../PageComponents/DesiredResolution'
import { Entitlement } from '../PageComponents/Entitlement'
import { GetInTouch } from '../PageComponents/GetInTouch'
import { LimitedOptions } from '../PageComponents/LimitedOptions'
import { PurchasedDate } from '../PageComponents/PurchasedDate'
import { PurchaseMethod } from '../PageComponents/PurchaseMethod'
import { PurchasePrice } from '../PageComponents/PurchasePrice'
import { RepeatPerformance } from '../PageComponents/RepeatPerformance'
import { SellerContact } from '../PageComponents/SellerContact'
import { SubmitSuccessful } from '../PageComponents/SubmitSuccessful'
import { WhatHappened } from '../PageComponents/WhatHappened'
import { WhatProduct } from '../PageComponents/WhatProduct'
import { WhichRetailer } from '../PageComponents/WhichRetailer'
import {
  ClaimValueFlow,
  ContactDetailsFlow,
  DesiredResolutionFlow,
  EntitlementFlow,
  GetInTouchFlow,
  LimitedOptionsFlow,
  PurchasedDateFlow,
  PurchaseMethodFlow,
  PurchasePriceFlow,
  RepeatPerformanceFlow,
  SellerContactFlow,
  SubmitSuccessfulFlow,
  ToolFeedbackAudienceFlow,
  ToolFeedbackFlow,
  ToolFeedbackThankYouFlow,
  WhatHappenedFlow,
  WhatProductFlow,
  WherePurchasedFlow,
} from '../pageFlowData'

export const useRenderStep = ({
  step,
  optionsJson,
  title,
  questionText,
  standfirst,
  buttonText,
  toolUrl,
  CRFooter,
}: S75UseRenderProps) => {
  const renderEntryPoint = () => (
    <CRToolEntryPoint
      buttonText={buttonText}
      optionsJson={optionsJson}
      questionText={questionText}
      standfirst={standfirst}
      title={title}
      toolName="s75 and chargeback"
      toolUrl={toolUrl}
    />
  )

  switch (step) {
    case PurchaseMethodFlow.STEP:
      return <PurchaseMethod />
    case RepeatPerformanceFlow.STEP:
      return <RepeatPerformance />
    case PurchasePriceFlow.STEP:
      return <PurchasePrice />
    case PurchasedDateFlow.STEP:
      return <PurchasedDate />
    case WhatProductFlow.STEP:
      return <WhatProduct />
    case DesiredResolutionFlow.STEP:
      return <DesiredResolution />
    case WherePurchasedFlow.STEP:
      return <WhichRetailer />
    case ClaimValueFlow.STEP:
      return <ClaimValue />
    case WhatHappenedFlow.STEP:
      return <WhatHappened />
    case SellerContactFlow.STEP:
      return <SellerContact />
    case LimitedOptionsFlow.STEP:
      return <LimitedOptions />
    case GetInTouchFlow.STEP:
      return <GetInTouch />
    case ContactDetailsFlow.STEP:
      return <ContactDetails />
    case SubmitSuccessfulFlow.STEP:
      return <SubmitSuccessful CRFooter={CRFooter} />
    case EntitlementFlow.STEP:
      return <Entitlement />
    case ToolFeedbackFlow.STEP:
      return (
        <ToolFeedbackPage
          pageData={toolFeedbackData}
          toolFeedbackAudienceFlowData={ToolFeedbackAudienceFlow}
          toolFeedbackFlowData={ToolFeedbackFlow}
          toolName="s75"
        />
      )
    case ToolFeedbackThankYouFlow.STEP:
      return <ToolFeedbackThankYouPage toolFeedbackThankYouFlowData={ToolFeedbackThankYouFlow} />
    case ToolFeedbackAudienceFlow.STEP:
      return (
        <ToolFeedbackAudiencePage
          pageData={toolFeedbackAudienceData}
          toolFeedbackAudienceFlowData={ToolFeedbackAudienceFlow}
          toolFeedbackThankYouFlowData={ToolFeedbackThankYouFlow}
          toolName="s75"
        />
      )
    default:
      return renderEntryPoint()
  }
}
