import React from 'react'

import { CRToolEntryPoint } from '../../../shared/pages/CRToolEntryPoint'
import type { MyMoneyHealthCheckUseRenderProps } from '../../../shared/types/toolTypes'
import { Benefits } from '../PageComponents/Benefits'
import { BroadbandContract } from '../PageComponents/BroadbandContract'
import { EnergyPayment } from '../PageComponents/EnergyPayment'
import { FinanceSituation } from '../PageComponents/FinanceSituation'
import { FoodShop } from '../PageComponents/FoodShop'
import { PayingDebts } from '../PageComponents/PayingDebts'
import { PropertyPayment } from '../PageComponents/PropertyPayment'
import { ResultsPage } from '../PageComponents/ResultsPage'
import {
  BenefitsFlow,
  BroadbandContractFlow,
  EnergyPaymentFlow,
  FinanceSituationFlow,
  FoodShopFlow,
  PayingDebtsFlow,
  PropertyPaymentFlow,
  ResultsPageFlow,
} from '../pageFlowData'

export const useRenderStep = ({
  step,
  optionsJson,
  title,
  questionText,
  standfirst,
  buttonText,
  toolUrl,
  moneyHealthResults,
}: MyMoneyHealthCheckUseRenderProps) => {
  const renderEntryPoint = () => (
    <CRToolEntryPoint
      buttonText={buttonText}
      optionsJson={optionsJson}
      questionText={questionText}
      standfirst={standfirst}
      title={title}
      toolName="MyMoneyHealthCheck"
      toolUrl={toolUrl}
    />
  )

  switch (step) {
    case FinanceSituationFlow.STEP:
      return <FinanceSituation />

    case PropertyPaymentFlow.STEP:
      return <PropertyPayment />

    case BenefitsFlow.STEP:
      return <Benefits />

    case EnergyPaymentFlow.STEP:
      return <EnergyPayment />

    case FoodShopFlow.STEP:
      return <FoodShop />

    case BroadbandContractFlow.STEP:
      return <BroadbandContract />

    case PayingDebtsFlow.STEP:
      return <PayingDebts />

    case ResultsPageFlow.STEP:
      return <ResultsPage pageData={moneyHealthResults} />
    default:
      return renderEntryPoint()
  }
}
