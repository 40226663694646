export const validateWordCount = (currentCount: string, maxCount?: number) => {
  if (maxCount) {
    return (
      currentCount
        .replace(/\n/g, ' ')
        .split(' ')
        .filter((n) => {
          return n !== ''
        }).length <= maxCount
    )
  }
  return true
}
