import type { FunctionComponent } from 'react'
import React, { useContext, useRef, useState } from 'react'

import { FormEntryPage } from '../../../../shared/pages/FormEntryPage'
import { ToolContext } from '../../../../shared/state/appContext'
import type { FormEntryInputRef, SingleInputState } from '../../../../shared/types/toolTypes'
import { cleanText } from '../../../../shared/utils/cleanText'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { ContractNumberFlow, WhatProblemFlow } from '../../pageFlowData'
import { contractNumberData } from './data'

export const ContractNumber: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    work: { number },
  } = state

  const ContractNumberInput = useRef<FormEntryInputRef>(null)

  const [inputData, setInputData] = useState<SingleInputState>({
    renderError: false,
    value: '',
  })

  const getValue = () => {
    return cleanText(ContractNumberInput?.current?.formEntryInput?.value || '')
  }

  const handleTracking = (value: string) => {
    const trackingAnswer = `question | ${ContractNumberFlow.VIEW_NAME} | ${value}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (value: string) => {
    dispatch({ type: 'UPDATE_NUMBER', data: value })
    dispatch({ type: 'UPDATE_STEP', data: WhatProblemFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: WhatProblemFlow.PROGRESS_VALUE })
  }

  const handleSubmit = () => {
    const value = getValue()
    setInputData({
      renderError: false,
      value: value,
    })

    handleTracking(value)
    handleDispatch(value)
  }

  const { imgObj, title, placeholderText } = contractNumberData

  return (
    <FormEntryPage
      buttonText="Next"
      defaultValue={number}
      fieldData={{
        renderError: inputData.renderError,
        value: inputData.value,
      }}
      handleSubmit={handleSubmit}
      id="contract-number"
      imgObj={imgObj}
      isFocused
      optional={true}
      placeholderText={placeholderText}
      ref={ContractNumberInput}
      testId="contract-number"
      title={title}
    />
  )
}
