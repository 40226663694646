import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'

import { PillButtonPage } from '../../../../shared/pages/PillButtonPage'
import { ToolContext } from '../../../../shared/state/appContext'
import globalStyles from '../../../../shared/styles/GlobalStyles.module.scss'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { generalDataLayer } from '../../../../shared/utils/tracking'
import { EnergyPaymentFlow, FoodShopFlow } from '../../pageFlowData'
import { data } from './data'
import styles from './EnergyPayment.module.scss'

export const EnergyPayment: FunctionComponent = () => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    initial: { entryToolName },
    myMoneyHealthCheck: { energyPayments, financeSituation },
  } = state

  const handleTracking = (id: string) => {
    const trackingAnswer = `question | ${EnergyPaymentFlow.VIEW_NAME} | ${id} | ${financeSituation}`
    generalDataLayer(formatToolName(entryToolName), trackingAnswer, false)
  }

  const handleDispatch = (id: string) => {
    dispatch({ type: 'UPDATE_ENERGY_PAYMENT', data: id })
    dispatch({ type: 'UPDATE_STEP', data: FoodShopFlow.STEP })
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: FoodShopFlow.PROGRESS_VALUE })
  }

  const handleSubmit = (id: string) => {
    handleTracking(id)
    handleDispatch(id)
  }

  const { buttons, imgObj, title } = data

  return (
    <div className={globalStyles.fullWidthButton}>
      <PillButtonPage
        buttons={buttons}
        defaultValue={energyPayments ? energyPayments : ''}
        handleSubmit={handleSubmit}
        imgObj={imgObj}
        pillButtonGroupStyle={styles.energyPayment}
        title={title}
      />
    </div>
  )
}
