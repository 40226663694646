import type { FunctionComponent } from 'react'
import React, { useContext, useEffect } from 'react'
import { ButtonLink, Typography } from '@which/seatbelt'

import { PageWithText } from '../../components/PageWithText'
import { ToolContext } from '../../state/appContext'
import globalStyles from '../../styles/GlobalStyles.module.scss'
import styles from './ScamSharerFeedbackThankYouPage.module.scss'

export const ScamSharerFeedbackThankYouPage: FunctionComponent<Props> = ({
  toolFeedbackThankYouFlowData,
}) => {
  const { state, dispatch } = useContext(ToolContext)
  const {
    toolFeedback: { toolFeedbackSurveyComplete },
  } = state

  useEffect(() => {
    dispatch({ type: 'UPDATE_PROGRESS_VALUE', data: toolFeedbackThankYouFlowData.PROGRESS_VALUE })
  }, [dispatch, toolFeedbackThankYouFlowData.PROGRESS_VALUE])
  const title = toolFeedbackSurveyComplete
    ? 'Thank you for sharing your feedback on the scam sharer tool with us'
    : 'Thanks again'

  return (
    <div data-testid="scam-sharer-feedback-thank-you">
      <div className={styles.scamSharerFeedbackThankYouPage}>
        <PageWithText title={title} />
        <Typography className={globalStyles.spacing40}>
          Any attempt to steal your money or personal information should be reported to the relevant
          authority, visit our guide on
          <a
            className="sb-link-primary"
            href="/consumer-rights/advice/how-to-report-a-scam-aG3sH5L8tjeP"
          >
            {' '}
            <span className="sb-link-animation-wrapper">reporting scams for more information</span>
          </a>
        </Typography>
        <Typography className={globalStyles.spacing40} tag="h3" textStyle="title-400">
          Emotional support
        </Typography>
        <Typography>
          Being scammed can take a huge toll on your mental health, you might find it helpful to
          talk to someone about what you're going through.
        </Typography>
        <Typography className={globalStyles.spacing40}>
          To speak to someone confidentially visit{' '}
          <a className="sb-link-primary" href="https://www.mind.org.uk/">
            <span className="sb-link-animation-wrapper">Mind</span>
          </a>{' '}
          or{' '}
          <a className="sb-link-primary" href="https://www.victimsupport.org.uk/">
            <span className="sb-link-animation-wrapper">Victim support</span>
          </a>
          .
        </Typography>
        <Typography className={globalStyles.spacing40} tag="h3" textStyle="title-400">
          Stay one step ahead of scams
        </Typography>
        <Typography>
          Sign up for free Which? Scam Alert emails delivered directly to your inbox. <br />
          <br />
          Which? cuts through the noise to find the facts. From dodgy coronavirus phishing emails to
          phoney automated HMRC calls, we've heard them all. <br />
          <br />
          Our emails will alert you to scams doing the rounds, and provide practical advice to help
          keep you one step ahead of the fraudsters.
        </Typography>
      </div>
      <div className={styles.scamSharerFeedbackThankYouPageButtonLinkWrapper}>
        <ButtonLink href="https://act.which.co.uk/page/103781/data/1">Sign up for free</ButtonLink>
      </div>
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  toolFeedbackThankYouFlowData: {
    PROGRESS_VALUE: number
  }
}
