export const limitedOptionsData = {
  default: {
    title:
      'Unfortunately we can only help those affected by delays flying in or out of the UK on UK or EU airlines.',
    standFirst: '',
    bodyCopy: [
      "If you're travelling with an airline based in the EU or with a non-EU based airline flying from an EU airport, you're protected by the Denied Boarding Regulation.",
      "If you're travelling with a non-EU based airline flying from a non-EU destination, the airline doesn't have the same duty to look after you. But you can check the airline's Condition of Carriage to see what compensation you are entitled to.",
    ],
    link: {
      href: 'https://www.which.co.uk/consumer-rights/travel/flights',
      headline:
        'When you’re hit by flight cancellations and delays we think airlines need step up and compensate you automatically. Until they agree, you can use our free guides to make your claim.',
      text: 'Your flight rights',
    },
    ctaData: {
      image: {
        src: 'https://media.product.which.co.uk/prod/images/original/gm-8b0968cf-b7d0-49e7-b5fd-ff38b3887a0d-limitedoptionsfaultygoods.png',
        alt: 'Two people looking at their phones facing away from each other.',
      },
      author: { name: 'Which?', jobTitle: 'Editorial team' },
    },
  },
  earlyNotice: {
    title:
      "Unfortunately, based on the information you've provided, you're not entitled to compensation under EU law",
    standFirst: '',
    bodyCopy: [
      'Because you were given more than 14 days notice of the cancellation by your airline, you are not entitled to compensation under the law. Use our guide to find out if you can still get recompense in another way.',
    ],
    link: {
      href: 'https://www.which.co.uk/consumer-rights/advice/i-had-a-flight-cancellation-can-i-claim-compensation-alAuW6y0rZX2',
      headline:
        "If your flight's cancelled or delayed, you may be able to claim compensation and get a refund under the Denied Boarding Regulation.",
      text: 'I had a flight cancellation, can I claim compensation?',
    },
    ctaData: {
      image: {
        src: 'https://media.product.which.co.uk/prod/images/original/gm-8b0968cf-b7d0-49e7-b5fd-ff38b3887a0d-limitedoptionsfaultygoods.png',
        alt: 'Two people looking at their phones facing away from each other.',
      },
      author: { name: 'Which?', jobTitle: 'Editorial team' },
    },
  },
  tooLittleDelay: {
    title:
      "Based on the information you've provided, you may not be entitled to compensation under EU law",
    standFirst: '',
    bodyCopy: [
      "Unfortunately, because you were delayed by less than 3 hours you aren't entitled to make a compensation claim.",
      'To find out more check our guides to your flight rights.',
    ],
    link: {
      href: 'https://www.which.co.uk/consumer-rights/travel/flights',
      headline:
        'When you’re hit by flight cancellations and delays we think airlines need step up and compensate you automatically. Until they agree, you can use our free guides to make your claim.',
      text: 'Your flight rights',
    },
    ctaData: {
      image: {
        src: 'https://media.product.which.co.uk/prod/images/original/gm-8b0968cf-b7d0-49e7-b5fd-ff38b3887a0d-limitedoptionsfaultygoods.png',
        alt: 'Two people looking at their phones facing away from each other.',
      },
      author: { name: 'Which?', jobTitle: 'Editorial team' },
    },
  },
  departureTooClose: {
    title:
      "Based on the information you've provided, you may not be entitled to compensation under EU law",
    standFirst: '',
    bodyCopy: [
      'Unfortunately, your new departure is too close to your original departure time.',
      'To find out more check our guides to your flight rights.',
    ],
    link: {
      href: 'https://www.which.co.uk/consumer-rights/travel/flights',
      headline:
        'When you’re hit by flight cancellations and delays we think airlines need step up and compensate you automatically. Until they agree, you can use our free guides to make your claim.',
      text: 'Your flight rights',
    },
    ctaData: {
      image: {
        src: 'https://media.product.which.co.uk/prod/images/original/gm-8b0968cf-b7d0-49e7-b5fd-ff38b3887a0d-limitedoptionsfaultygoods.png',
        alt: 'Two people looking at their phones facing away from each other.',
      },
      author: { name: 'Which?', jobTitle: 'Editorial team' },
    },
  },
}
