import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

import { ToolWrapper } from '../../../../shared'
import { ImageWithQuestion } from '../../../../shared/components/ImageWithQuestion'
import { Loader } from '../../../../shared/components/Loader'
import { formatToolName } from '../../../../shared/utils/formatToolName'
import { initaliseDataLayer, pageViewDataLayer } from '../../../../shared/utils/tracking'
import { FollowUpAppointmentFlow } from '../../pageFlowData'
import { followUpAppointmentData } from './data'
import styles from './FollowUpAppointment.module.scss'
import { getIFrameData } from './getIFrameData'

type FollowUpAppointmentType = {
  toolName: string
}
export const FollowUpAppointment: FunctionComponent<FollowUpAppointmentType> = ({ toolName }) => {
  useEffect(() => {
    pageViewDataLayer(FollowUpAppointmentFlow.VIEW_NAME)
  }, [])

  const [iFrameData, setIFrameData] = useState<{
    isEntitled?: boolean
    anyExpertAppointmentUrl?: string
    sameExpertAppointmentUrl?: string
    error?: string
  } | null>(null)

  const location = useLocation()
  const useQuery = () => {
    return new URLSearchParams(location?.search)
  }

  const query = useQuery()
  const appointmentId = query?.get('appointmentId')

  useEffect(() => {
    const createIFrame = async () => {
      if (appointmentId) {
        const iFrameResponse = await getIFrameData(appointmentId)
        setIFrameData(iFrameResponse)
      } else {
        setIFrameData({ error: 'Sorry, something went wrong' })
      }
    }
    createIFrame()
  }, [appointmentId])

  const { imgObj, title, standFirst } = followUpAppointmentData

  return (
    <ToolWrapper>
      <Helmet>
        <meta content="noindex" name="robots" />
        <script>{initaliseDataLayer(formatToolName(toolName))}</script>
        <title>Follow up Appointment</title>
        <meta content={'Online appointment bookingOnline appointment booking'} name="title" />
        <meta content="summary" name="twitter:card" />
        <meta content={'Book your follow up online appointment booking'} name="description" />
        <meta content="website" property="og:type" />
        <meta content={'https://www.which.co.uk/tool/book-follow-up'} property="og:url" />
        <meta content={'Online appointment booking'} property="og:title" />
        <meta
          content={'Book your follow up online appointment booking'}
          property="og:description"
        />
        <meta content={'https://www.which.co.uk/tool/book-follow-up'} property="twitter:url" />
        <meta content={'Online appointment booking'} property="twitter:title" />
        <meta
          content={'Book your follow up online appointment booking'}
          property="twitter:description"
        />
      </Helmet>
      {iFrameData === null ? (
        <Loader />
      ) : (
        <>
          <ImageWithQuestion image={imgObj} title={title} />
          <p className={styles.followUpAppointmentStandFirst} data-testid="typography-body">
            {standFirst}
          </p>
          {iFrameData?.error ? (
            <p className={styles.followUpAppointmentStandFirst} data-testid="typography-body">
              {iFrameData?.error}
            </p>
          ) : (
            <iframe
              allow="geolocation"
              className={styles.followUpAppointmentFrame}
              referrerPolicy="no-referrer"
              src={iFrameData?.sameExpertAppointmentUrl}
              title="Appointment Choice"
            ></iframe>
          )}
        </>
      )}
    </ToolWrapper>
  )
}
