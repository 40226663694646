import React from 'react'

import { ToolFeedbackAudiencePage } from '../../../shared/pages/ToolFeedbackAudiencePage'
import { toolFeedbackAudienceData } from '../../../shared/pages/ToolFeedbackAudiencePage/data'
import { ToolFeedbackPage } from '../../../shared/pages/ToolFeedbackPage'
import { toolFeedbackData } from '../../../shared/pages/ToolFeedbackPage/data/holidayComplaintData'
import { ToolFeedbackThankYouPage } from '../../../shared/pages/ToolFeedbackThankYouPage'
import { BeenOnHoliday } from '../PageComponents/BeenOnHoliday'
import { BookingReference } from '../PageComponents/BookingReference'
import { CompanyContact } from '../PageComponents/CompanyContact'
import { ContactDetails } from '../PageComponents/ContactDetails'
import { Destination } from '../PageComponents/Destination'
import { ExpensesDescription } from '../PageComponents/ExpensesDescription'
import { ExpensesValue } from '../PageComponents/ExpensesValue'
import { HasExpenses } from '../PageComponents/HasExpenses'
import { HolidayDates } from '../PageComponents/HolidayDates'
import { HolidayProvider } from '../PageComponents/HolidayProvider'
import { HolidayType } from '../PageComponents/HolidayType'
import { LimitedOptions } from '../PageComponents/LimitedOptions'
import { SubmitSuccessful } from '../PageComponents/SubmitSuccessful'
import { WhatHappened } from '../PageComponents/WhatHappened'
import {
  BeenOnHolidayFlow,
  BookingReferenceFlow,
  CompanyContactFlow,
  ContactDetailsFlow,
  ExpensesDetailsFlow,
  ExpenseValueFlow,
  HasExpensesFlow,
  HolidayDatesFlow,
  HolidayDestinationFlow,
  HolidayProviderFlow,
  HolidayTypeFlow,
  IssueDescriptionFlow,
  LimitedOptionsFlow,
  SubmitSuccessfulFlow,
  ToolFeedbackAudienceFlow,
  ToolFeedbackFlow,
  ToolFeedbackThankYouFlow,
} from '../pageFlowData'

export const useRenderStep = (step: number, CRFooter: React.ReactNode) => {
  switch (step) {
    case BeenOnHolidayFlow.STEP:
      return <BeenOnHoliday />
    case HolidayTypeFlow.STEP:
      return <HolidayType />
    case HolidayProviderFlow.STEP:
      return <HolidayProvider />
    case HolidayDestinationFlow.STEP:
      return <Destination />
    case HolidayDatesFlow.STEP:
      return <HolidayDates />
    case IssueDescriptionFlow.STEP:
      return <WhatHappened />
    case CompanyContactFlow.STEP:
      return <CompanyContact />
    case HasExpensesFlow.STEP:
      return <HasExpenses />
    case ExpenseValueFlow.STEP:
      return <ExpensesValue />
    case ExpensesDetailsFlow.STEP:
      return <ExpensesDescription />
    case BookingReferenceFlow.STEP:
      return <BookingReference />
    case ContactDetailsFlow.STEP:
      return <ContactDetails />
    case SubmitSuccessfulFlow.STEP:
      return <SubmitSuccessful CRFooter={CRFooter} />
    case LimitedOptionsFlow.STEP:
      return <LimitedOptions />
    case ToolFeedbackFlow.STEP:
      return (
        <ToolFeedbackPage
          pageData={toolFeedbackData}
          toolFeedbackAudienceFlowData={ToolFeedbackAudienceFlow}
          toolFeedbackFlowData={ToolFeedbackFlow}
          toolName="HolidayComplaint"
        />
      )
    case ToolFeedbackThankYouFlow.STEP:
      return <ToolFeedbackThankYouPage toolFeedbackThankYouFlowData={ToolFeedbackThankYouFlow} />
    case ToolFeedbackAudienceFlow.STEP:
      return (
        <ToolFeedbackAudiencePage
          pageData={toolFeedbackAudienceData}
          toolFeedbackAudienceFlowData={ToolFeedbackAudienceFlow}
          toolFeedbackThankYouFlowData={ToolFeedbackThankYouFlow}
          toolName="HolidayComplaint"
        />
      )
    default:
      return <BeenOnHoliday />
  }
}
