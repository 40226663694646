import type { UserPropsWithAddress } from './../../../shared/types/toolTypes'

export const pageData = (state: {
  emailData: { body: { text: string }[] }
  work: {
    complaint: string
    trader: string
    job: string
    work: string
    date: string
    name: string
    number: string
    problem: string
    card: string
  }
  optIns: { singleConsentOptIn: boolean }
  user: UserPropsWithAddress
}) => {
  const { address, emailAddress, firstName, lastName } = state.user
  const { complaint, trader, job, work, date, name, number, problem, card } = state.work
  const { singleConsentOptIn } = state.optIns
  const questions: any = {}
  const { body } = state.emailData
  let emailBodyBuilder = ''
  body.forEach((bodyData) => {
    /* istanbul ignore next */
    if (bodyData.text) {
      emailBodyBuilder = emailBodyBuilder + `${bodyData.text}<br />`
    }
  })

  const paidWithCard =
    card === 'yes'
      ? '<li><p class="en-list">If paid with a credit or debit card remember to check your rights under the <a href="https://www.which.co.uk/consumer-rights/regulation/section-75-of-the-consumer-credit-act-aZCUb9i8Kwfa">Section 75</a> and the <a href="https://www.which.co.uk/consumer-rights/advice/how-do-i-use-chargeback-abZ2d4z3nT8q">Chargeback scheme</a>, if you are unsatisfied with the resolution offered by the trader.</p></li>'
      : ''

  questions['question.1616404'] = complaint // TF - Home Imp - Is your complaint specifically
  questions['question.1616405'] = trader // TF - Home Imp - Trusted trader?
  questions['question.1616407'] = job // TF - Home Imp - Type of job was carried out
  questions['question.1616406'] = work // TF - Home Imp - Could you please specify what type of job was carried out
  questions['question.1616408'] = date // TF - Home Imp - What Date?
  questions['question.1616409'] = name // TF - Home Imp - What's the name
  questions['question.1616410'] = number // TF - Home Imp - What is your contract number reference?
  questions['question.1616412'] = problem // TF - Home Imp - What problems did you experience with the work on your property?
  questions['question.1616413'] = card // TF - Home Imp - Did you use a credit card?
  questions['question.1618028'] = paidWithCard // TF - Home Imp - Email copy question

  questions['question.1598463'] = singleConsentOptIn ? 'Y' : 'N' // Single consent opt in
  questions['question.1616424'] = emailBodyBuilder // TF - Home Imp - Completed Email
  questions['question.1184531'] = 'Y' // TF - Impact survey opt-in

  // return supporter
  return {
    supporter: {
      Address: address?.addressLine1,
      'Address 2': address?.addressLine2,
      County: address?.county,
      Email: emailAddress,
      'First name': firstName,
      'Last name': lastName,
      Postcode: address?.postcode,
      'Town or City': address?.townCity,
      questions,
    },
  }
}
