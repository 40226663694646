import { limitedOptionsData } from './data'

export const chooseDataSet = (
  timeBeforeCancelled: string,
  delayedCancelled: string,
  earlierLaterOriginalFlight: string
) => {
  // Enough notice of cancellation
  if (timeBeforeCancelled === 'more-than-14-days') {
    return { optionsData: limitedOptionsData.earlyNotice }
  }

  // Scenario A
  if (
    delayedCancelled === 'flight-delayed' ||
    (delayedCancelled === 'flight-rerouted' &&
      (timeBeforeCancelled === 'fewer-than-7-days' ||
        timeBeforeCancelled === 'between-7-and-14-days') &&
      earlierLaterOriginalFlight === 'later')
  ) {
    return { optionsData: limitedOptionsData.tooLittleDelay }
  }

  // Scenario B, C, D, E
  if (
    delayedCancelled === 'flight-rerouted' &&
    (timeBeforeCancelled === 'fewer-than-7-days' ||
      timeBeforeCancelled === 'between-7-and-14-days') &&
    (earlierLaterOriginalFlight === 'earlier' ||
      earlierLaterOriginalFlight === 'earlier-arrived-later')
  ) {
    return { optionsData: limitedOptionsData.departureTooClose }
  }

  // Flight not from UK
  return { optionsData: limitedOptionsData.default }
}
